import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalAdminService } from 'src/services/global-admin.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
   selector: 'app-pocket-money-percentage',
   templateUrl: './pocket-money-percentage.component.html',
   styleUrls: ['./pocket-money-percentage.component.scss'],
})
export class PocketMoneyPercentageComponent implements OnInit {
   pocketMoneyPercentageForm: FormGroup;
   pocketMoneyPercentageCurrentValues = {
      amount: '',
      validFrom: '01.07.2021',
   };
   maxDate: Date = new Date(2999, 11, 31);
   
   constructor(
      private formBuilder: FormBuilder,
      private globalAdminService: GlobalAdminService,
      private toastr: ToastrImplService,
   ) {}

   ngOnInit(): void {
      this.pocketMoneyPercentageForm = this.formBuilder.group({
         value: ['', []],
         validFrom: ['', []],
      });
      this.getPocketMoneyPercentage();
   }

   getPocketMoneyPercentage() {
      this.globalAdminService.getCurrentPocketMoneyPercentageCodebook().subscribe(res => {
         this.pocketMoneyPercentageCurrentValues = res;
      });
   }

   submitPocketMoneyPercentageForm(data: any) {
      data.value.validFrom.setDate(data.value.validFrom.getDate() + 1);
      const newObject = {
         amount: Number(data.value.value),
         validFrom: data.value.validFrom.toISOString().split('T')[0],
      };
      // If successful show snackbar
      this.globalAdminService.addPocketMoneyPercentageCodebook(newObject).subscribe(res => {
         this.toastr.success('SNACKBAR.CHANGE_SAVED');
         this.getPocketMoneyPercentage();
      });
   }
}
