<h4 class="modal__title">
  {{
  (isEdit ? labelTitleEdit : labelTitle)
  }}
</h4>
<form [formGroup]="manageCipherForm" class="form">
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{ labelValue }}</mat-label>
      <input matInput type="text" formControlName="value" autocomplete="off" required />
    </mat-form-field>
  </field-holder>
  <field-holder *ngIf="isVisibleFormat">
    <mat-form-field appearance="outline ">
      <mat-label>
        {{ labelFormat }}
      </mat-label>
      <mat-select formControlName="format" required>
        <mat-option *ngFor="let f of formatCodebook" [value]="f?.id">
          {{ f.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </field-holder>
  <field-holder *ngIf="data.kind === 'bank' && data.type === 'new'">
    <mat-form-field appearance="outline">
      <mat-label>{{ cipherLabelValue }}</mat-label>
      <input matInput type="text" formControlName="cipherValue" autocomplete="off" onlyNumber required
        (keyup)="checkIfCipherValueExists($event.target.value)" 
        [class.error]="manageCipherForm.get('cipherValue').invalid"/>
    </mat-form-field>
      <div style="font-size: 13px; margin-left: 7px; margin-top: -15px;"> 
      <mat-error *ngIf="manageCipherForm.get('cipherValue').hasError('alreadyExist')">
        {{ 'GLOBAL_ADMIN.CIPHERS.MANAGE.BANK_CIPHER_ERROR' | translate }}
      </mat-error>
    </div>
  </field-holder>
  <div
    *ngIf="isEdit && 
  (data.kind === 'rightsCodebook' || data.kind === 'services' || data.kind === 'legalProcedure' || data.kind === 'professionalProcedure')">
    <mat-checkbox color="primary" class="form__checkbox" formControlName="active" [disabled]="inUse || inForm">
      {{ 'GLOBAL_ADMIN.CIPHERS.MANAGE.ACTIVE' | translate }}
    </mat-checkbox>
  </div>
  <form-footer>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ 'DIALOG.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" type="button" (click)="handleUpdate()"
      class="form-footer__button form-footer__button--primary" [disabled]="!manageCipherForm.valid || valueExists">
      {{ 'DIALOG.CONFIRM' | translate }}
    </button>
    <button
      *ngIf="isEdit  && forDelete && !inForm &&
    (data.kind === 'rightsCodebook' || data.kind === 'services' || data.kind === 'legalProcedure' || data.kind === 'professionalProcedure')"
      mat-flat-button color="warn" class="modal-footer__button modal__delete-button" (click)="handleDelete()">
      {{ 'DIALOG.DELETE' | translate }}
    </button>
  </form-footer>
</form>