/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamRequestService {

  constructor(private httpClient: HttpClient, private app: AppService) { }

  createTeamRequest(teamRequest: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.saveTeamRequestUrl, teamRequest, { headers }) as Observable<any>;
  }

  findAll() {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.findAllTeamRequests, { headers }) as Observable<any>;
  }

  caseHaveNotProcessedTeamRequest(caseId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() +
      environment.caseHaveNotProcessedTeamRequest + '/' + caseId, { headers }) as Observable<any>;
  }

}
