<mat-card class="card">
  <card-header title="{{ 'SUBJECT_PROBLEMS.TITLE' | translate }}">
    <span matTooltip="{{'NEW_SUBJECT_PROBLEM.DISABLED_BUTTON' | translate }}" [matTooltipDisabled]="!status">
      <button mat-flat-button (click)="addNewProblem()" color="primary" [disabled]="status"
        *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni && !isAdmin">
        {{'NEW_SUBJECT_PROBLEM.TITLE' | translate}}
      </button>
    </span>
  </card-header>
  <div class="table-responsive-wrapper" *ngIf="page.content.length > 0">
    <table mat-table [dataSource]="page.content" #mytable class="problems-table">
      <ng-container matColumnDef="case">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_PROBLEMS.TABLE.CASE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.caseNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="problemId">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_RECORDS.TABLE.PROBLEM_ID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.problemId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_PROBLEMS.TABLE.DESCRIPTION' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="problems-table__cell problems-table__cell--description">
          {{ element.codebookTitle }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_PROBLEMS.TABLE.START_DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fromDate | date: 'dd.MM.yyyy.' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_PROBLEMS.TABLE.END_DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.toDate | date: 'dd.MM.yyyy.'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_PROBLEMS.TABLE.DURATION' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.duration }}
        </td>
      </ng-container>
      <ng-container matColumnDef="levelOfSeverity">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_PROBLEMS.TABLE.LEVEL_OF_SEVERITY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.intensity }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
          <button mat-icon-button class="button-square table__row-button" color="primary"
            matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
            <mat-icon class="button-square__icon">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="filter-menu">
            <button mat-menu-item (click)="handleOpenDialog(element)" [disabled]="status">
              <mat-icon class="button-square__icon">edit</mat-icon>
              {{ 'SUBJECT_PROBLEMS.TABLE.EDIT_PROBLEM' | translate }}
            </button>
            <button mat-menu-item (click)="handleDeleteDialog(element)" [disabled]="status">
              <mat-icon class="button-square__icon">delete</mat-icon>
              {{ 'SUBJECT_PROBLEMS.TABLE.DELETE_PROBLEM' | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"
        class="problems-table__row"></tr>
    </table>
  </div>
  <div class="pagination" *ngIf="page.content.length > 0">
    <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
    </app-custom-pagination>
  </div>
  <p class="problems-empty" *ngIf="page.content.length === 0">
    <mat-icon class="problems-empty__icon">info</mat-icon>
    {{ 'SUBJECT_PROBLEMS.NO_RESULTS' | translate }}
  </p>
</mat-card>