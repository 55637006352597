<mat-card class="step__card" [ngClass]="{
    step__card: true,
    'step__card--initial': stepStatus === 'INITIAL',
    'step__card--started': stepStatus === 'STARTED',
    'step__card--finished': stepStatus === 'FINISHED'
  }">
    <header class="step__header">
        <main>
            <h4 class="step__title">{{ 'VS.TARGETED_ASSESSMENT_PLAN.TITLE' | translate }}</h4>
            <label-step-status [status]="stepStatus"></label-step-status>
        </main>
        <aside class="step__action" *ngIf="isEditMode !== true && stepStatus !== 'FINISHED'">
            <span [matTooltip]="!editEnabled ? acceptCaseMissingPermitionsTooltip : ''">
                <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}"
                    [matTooltipDisabled]="editEnabled===false||noRegistrationId===false">

                    <button mat-stroked-button color="primary" (click)="handleInitStep()"
                        *ngIf="stepStatus === 'INITIAL'"
                        [disabled]="!editEnabled || waitingForApproval|| noRegistrationId">
                        {{ 'VS.TARGETED_ASSESSMENT_PLAN.CREATE' | translate }}
                    </button>
                </span>
            </span>
            <button mat-stroked-button color="primary" (click)="toggleEdit()" *ngIf="stepStatus === 'STARTED'"
                [disabled]="waitingForApproval" class="step__action">
                {{ 'VS.EDIT' | translate }}
            </button>
        </aside>
    </header>

    <form class="form" [formGroup]="targetedAssesmentPlan" *ngIf="isEditMode === true">
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.TARGETED_ASSESSMENT_PLAN.TITLE' | translate }}
                </mat-label>
                <textarea matInput type="text" formControlName="assesmentText" required autocomplete="off"></textarea>
                <mat-error *ngIf="targetedAssesmentPlan.get('assesmentText').hasError('required')">
                    {{ 'VS.TARGETED_ASSESSMENT_PLAN.FORM_NOT_VALID' | translate }}
                </mat-error>
            </mat-form-field>
        </field-holder>

        <ng-container>
            <div class="worker-details__no-results">
                <no-result-item *ngIf="targetedAssesmentPlans.length === 0">{{
                    'VS.TARGETED_ASSESSMENT_PLAN.NO_DOCS' | translate
                    }}</no-result-item>
            </div>
            <ul class="worker-details__document-list worker-details__document-list--evaluation"
                *ngIf="targetedAssesmentPlans.length > 0">
                <li class="worker-details__document-item" *ngFor="let document of targetedAssesmentPlans">
                    <document-item-evaluation [title]="document.name"
                        [meta]="document.dateOfModification + ' - Аутор ' + document.author"
                        (handleDownload)="openDialog('download', document)"
                        (handlePrint)="openDialog('print', document)" (handleClick)="openDialog('view', document)">
                    </document-item-evaluation>
                </li>
            </ul>
        </ng-container>
        <form-footer>
            <button mat-stroked-button color="primary" type="button" (click)="toggleEdit()"
                class="form-footer__button form-footer__button--secondary">
                {{ 'VS.CANCEL' | translate }}
            </button>
            <button mat-flat-button color="primary" type="button" (click)="handleSaveChanges()"  [disabled]="!targetedAssesmentPlan.valid"
                class="form-footer__button form-footer__button--secondary">
                {{ 'VS.SAVE' | translate }}
            </button>
        </form-footer>
    </form>
</mat-card>