<h2 mat-dialog-title>{{ "NSP_FAMILY_INFO.TITLE" | translate}}</h2>
<div class="button-add-holder">
  <button mat-flat-button color="primary" (click)="importSubjectData()">
    <mat-icon>person_add</mat-icon>
    {{ "ENTRANCE.STEP_02.ADD_SUBJECT" | translate }}
  </button>
</div>
<form-subgroup-divider></form-subgroup-divider>
<mat-dialog-content>
  <form class="form" [formGroup]="firstStep">
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.CSR" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="center" readonly autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.NUMBER" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="nspFamilyRequestIdentity" readonly autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "NSP_FAMILY_INFO.STEP_01.DATE" | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dateOfSubmission" autocomplete="off"
          [max]="maxDate" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="firstStep.get('dateOfSubmission').hasError('required')">
          {{ "NSP_FAMILY_INFO.STEP_01.DATE_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.FIRST_NAME" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="firstName" autocomplete="off" required readonly />
        <mat-error *ngIf="firstStep.get('firstName').hasError('required')">
          {{ "NSP_FAMILY_INFO.STEP_01.FIRST_NAME_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.LAST_NAME" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="lastName" autocomplete="off" required readonly />
        <mat-error *ngIf="firstStep.get('lastName').hasError('required')">
          {{ "NSP_FAMILY_INFO.STEP_01.LAST_NAME_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.PARENT_NAME" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="parentName" autocomplete="off" />
        <mat-error *ngIf="firstStep.get('parentName').hasError('required')">
          {{ "NSP_FAMILY_INFO.STEP_01.PARENT_NAME_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <div class="checbox-holder">
      <mat-checkbox color="primary" formControlName="foreigner">
        {{ 'NEW_SUBJECT.PERSONAL_DETAILS.FOREIGNER' | translate }}
      </mat-checkbox>
    </div>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label *ngIf="firstStep.get('foreigner').value === true">
          {{'NEW_SUBJECT.PERSONAL_DETAILS.EBS' | translate}}
        </mat-label>
        <mat-label *ngIf="firstStep.get('foreigner').value !== true">
          {{ "NEW_SUBJECT.PERSONAL_DETAILS.JMBG" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="jmbg" autocomplete="off" maxlength="13"
          (change)="checkJMBG($event)" />
        <mat-error *ngIf="
            firstStep.get('foreigner').value !== true &&
                (firstStep.get('jmbg').hasError('required') ||
                firstStep.get('jmbg').hasError('minLength') ||
                firstStep.get('jmbg').hasError('maxLength') ||
                firstStep.get('jmbg').hasError('pattern'))
            ">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="firstStep.get('foreigner').value !== true && firstStep.get('jmbg').hasError('controlNumber')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR_VALIDATION' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.GENDER" | translate }}
        </mat-label>
        <mat-select formControlName="gender" [compareWith]="compareGenderObjects"
          (selectionChange)="updateJMBGOnGenderChange($event)">
          <mat-option></mat-option>
          <mat-option *ngFor="let gender of genderOptions" [value]="gender">
            {{ gender.gender }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="firstStep.get('gender').hasError('required') || firstStep.get('gender').hasError('incorrect')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER_ERROR' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.DATE_OF_BIRTH" | translate }}
        </mat-label>
        <input matInput [matDatepicker]="picker2" formControlName="dateOfBirth" (dateChange)="checkJMBG($event)"
          [max]="maxDate" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <mat-error
          *ngIf="firstStep.get('dateOfBirth').hasError('required') || firstStep.get('dateOfBirth').hasError('incorrect')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.PLACE_OF_BIRTH" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          "NSP_FAMILY_INFO.STEP_01.CITIZENSHIP" | translate
          }}</mat-label>
        <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenship"
          (focusout)="autoSelectItem(citizenships, 'citizenship')" />
        <mat-autocomplete #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat" panelWidth="auto">
          <mat-option *ngFor="let option of citizenshipsFiltered | async" [value]="option">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "NSP_FAMILY_INFO.STEP_01.NATIONALITY" | translate }}</mat-label>
        <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationality"
          (focusout)="autoSelectItem(nationalities, 'nationality')" />
        <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="displayCustomFormat" panelWidth="auto">
          <mat-option *ngFor="let option of nationalitiesFiltered | async" [value]="option">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.ADDRESS" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="address" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.ADDRESS_NUMBER" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="addressNumber" autocomplete="off" />
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.PHONE" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="phone" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.MOBILE" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="mobile" autocomplete="off" />
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.RELATIONSHIP" | translate }}
        </mat-label>
        <mat-select formControlName="relationship">
          <mat-option *ngFor="let relStat of relationships" [value]="relStat.title">
            {{ relStat.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.MARITAL_STATUS" | translate }}
        </mat-label>
        <mat-select formControlName="maritalStatus">
          <mat-option *ngFor="let marStat of maritalStatuses" [value]="marStat.title">
            {{ marStat.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="firstStep.get('maritalStatus').hasError('required')">
          {{ "NSP_FAMILY_INFO.STEP_01.MARITAL_STATUS_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.EDUCATION" | translate }}
        </mat-label>
        <mat-select formControlName="education">
          <mat-option *ngFor="let edu of educations" [value]="edu.title">
            {{ edu.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="firstStep.get('education').hasError('required')">
          {{ "NSP_FAMILY_INFO.STEP_01.EDUCATION_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.OCCUPATION" | translate }}
        </mat-label>
        <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupation"
          (focusout)="autoSelectItem(occupations, 'occupation')" />
        <mat-autocomplete #autoOccupation="matAutocomplete" [displayWith]="displayCustomFormat" panelWidth="auto">
          <mat-option *ngFor="let option of occupationsFiltered | async" [value]="option">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.EMPLOYMENT" | translate }}
        </mat-label>
        <mat-select formControlName="employment" (selectionChange)="clearEmploymentFields($event)">
          <mat-option *ngFor="let emp of employments" [value]="emp.title">
            {{ emp.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="firstStep.get('employment').value ==='Остало' ">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.EMPLOYMENT_OTHER" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="employmentOther" autocomplete="off" [required]="
                firstStep.get('employment').value === 'Остало' " />
      </mat-form-field>
      <mat-form-field appearance="outline"
        *ngIf="firstStep.get('employment').value === 'Запослен' || firstStep.get('employment').value === 'Радно ангажован' ">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.EMPLOYER_NAME" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="employerName" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline"
        *ngIf="firstStep.get('employment').value === 'Запослен' || firstStep.get('employment').value === 'Радно ангажован' ">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.INDEPENDENT_AGENCY" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="independentAgency" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline"
        *ngIf="firstStep.get('employment').value === 'Запослен' || firstStep.get('employment').value === 'Радно ангажован' ">
        <mat-label>
          {{ "NSP_FAMILY_INFO.STEP_01.AGRICULTURIST" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="agriculturist" autocomplete="off" />
      </mat-form-field>
    </field-holder>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <footer class="footer-controls">
    <button mat-stroked-button mat-dialog-close color="primary" (click)="closeDialog()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ "NSP_FAMILY_INFO.CANCEL" | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="printPreview()"
      class="form-footer__button form-footer__button--primary">
      {{ "PRINT.PREVIEW" | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="saveNSPFamilyRequest(); submitButton.disabled = true;"
      class="form-footer__button form-footer__button--secondary" #submitButton>
      {{ "NSP_FAMILY_INFO.SAVE" | translate }}
    </button>
  </footer>
</mat-dialog-actions>