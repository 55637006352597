import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserService } from 'src/services/user.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { Page } from 'src/app/page';

@Component({
   selector: 'user-form',
   templateUrl: './user-form.component.html',
   styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

   @Input() userId: any;
   @Input() emitUpdatedUser: any;
   createUserForm: FormGroup;
   fieldTextType = false;
   centers: any = [];
   offices: any = [];
   roles: any = [];
   roleDescriptions = [];
   strucniRadnik = environment.strucni_radnik;
   supervizor = environment.supervizor;
   admin_centra = environment.admin_centra;
   supervisors = [];
   subordinates = [];
   isRoleDescriptionNeeded = false;
   isUserSupervisor = false;
   user;
   isCenterAdminLogged: any;
   loggedInCenter: any;
   roleSelections: any;
   disabledRoles = [];
   page: Page<any>;

   constructor(private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      public userService: UserService,
      private localStorageService: LocalStorageService,
      private router: Router) {

      this.createForm();

      const loggedInCsrId = JSON.parse(this.localStorageService.get('loggedUser'))?.csrId;
      this.codebookService.getCsrCodebook().subscribe(result => {
         if (loggedInCsrId) {
            const csr = result.find((e: any) => e.id == loggedInCsrId);
            this.loggedInCenter = csr;
            this.createUserForm.controls.center.setValue(csr.name);
            this.isCenterAdminLogged = true;
            this.findOffices(null);
         } else {
            this.centers = result;
            this.isCenterAdminLogged = false;
         }
      });

      this.userService.getUserRoles().subscribe((result) => {
         this.roles = result;
         this.disabledRoles = new Array(this.roles.length).fill(false);
      });

      this.userService.getUserRoleDescriptions().subscribe((result) => {
         this.roleDescriptions = result.map(role => role.description);
      });

      this.page = JSON.parse(localStorage.getItem('filterPage'));
   }

   ngOnInit(): void {

   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['userId']) {
         if (this.userId !== undefined) {
            this.patchForm();
         }
      }
   }

   createForm() {
      this.createUserForm = this.formBuilder.group({
         firstName: ['', [Validators.required]],
         lastName: ['', [Validators.required]],
         role: ['', [Validators.required]],
         roleDescription: [],
         username: ['', [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]],
         password: ['', [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]],
         center: [null, [Validators.required]],
         office: [null, []],
         supervisors: [],
         subordinates: [],
         jmbg: ['']
      });
   }

   patchForm() {
      this.createUserForm.controls.username.disable();
      this.createUserForm.controls.password.clearValidators();
      this.userService.findUserData(this.userId).subscribe(
         result => {
            this.user = result;
            this.checkIsRoleChanged(this.user.roles);
            this.createUserForm.patchValue({
               firstName: result.firstName,
               lastName: result.lastName,
               username: result.username,
               center: this.isCenterAdminLogged ? result.csrCodebook.name : result.csrCodebook,
               office: result.officeCodebook,
               role: result.roles,
               roleDescription: result.roleDescription,
               subordinates: result.subordinates,
               supervisors: result.supervisors,
               jmbg: result.jmbg === '' ? null : result.jmbg
            });

            result.roles.some((role: any) => role.role === this.admin_centra) ? this.disableOtherRoles() : this.disableCenterAdmin();

            const event = {
               source: {
                  value: this.user.csrCodebook
               }
            };
            this.findOffices(event);

         }
      );
   }

   closeDialog() {
      this.router.navigate(['/users']);
   }

   toggleFieldTextType() {
      this.fieldTextType = !this.fieldTextType;
   }

   findOffices(event: any) {
      let center = event?.source?.value;
      if (event === null && this.isCenterAdminLogged) {
         center = this.loggedInCenter;
      }
      if (center !== undefined) {
         if (this.userId !== undefined) {
            this.createUserForm.controls.office.setValue(this.user?.officeCodebook);
         } else {
            this.createUserForm.controls.office.setValue(null);
         }
         this.createUserForm.controls.center.setErrors(null);
         this.codebookService.getOfficeCodebookByCsrId(center.id).subscribe(result => {
            this.offices = result;
            this.createUserForm.controls.office.markAsTouched();
         });

         this.checkIsRoleChanged(this.createUserForm.value.role);
      }
   }

   checkCenter(event: any) {
      if (this.createUserForm.value.center === '') {
         this.createUserForm.controls.center.markAsTouched();
      }
   }

   checkUsername() {
      if (this.createUserForm.value.username !== '') {
         this.userService.getIsUsernameTaken(this.createUserForm.value.username).subscribe((res: any) => {
            if (res === false) {
               this.createUserForm.controls.username.setErrors({ taken: true });
            } else if (!/^(?!.*[\u0400-\u04FF])/.test(this.createUserForm.value.username)) {
               this.createUserForm.controls.username.setErrors({ pattern: true });
            } else {
               this.createUserForm.controls.username.setErrors(null);
            }
         });
      }
   }

   createDto() {

      const allRoles = this.createUserForm.value.role;
      let roles = [];
      if (allRoles !== null && allRoles.length !== 0) {
         for (const role of allRoles) {
            roles.push(role.id);

         };
      };
      const newObject = {
         id: null,
         username: btoa(this.createUserForm.value.username),
         roles: roles,
         roleDescription: this.createUserForm.value.roleDescription,
         temporary: false,
         firstName: this.createUserForm.value.firstName,
         lastName: this.createUserForm.value.lastName,
         csr: this.isCenterAdminLogged ? this.loggedInCenter.id : this.createUserForm.value.center.id,
         office: (this.createUserForm.value.office === '' || this.createUserForm.value.office === null || this.createUserForm.value.office === undefined) ?
            null : this.createUserForm.value.office.ouId,
         password: btoa(this.createUserForm.value.password),
         supervisors: this.createUserForm.value.supervisors,
         subordinates: this.createUserForm.value.subordinates,
         jmbg: this.createUserForm.value.jmbg,
      };
      return newObject;

   }

   submitNewUser() {
      const newObject = this.createDto();
      if (this.userId === undefined) {
         this.userService.createUser(newObject).subscribe((savedUsers) => {
            this.editPage(savedUsers[savedUsers.length - 1], null);
            this.router.navigate(['/users']);
         });
      } else {

         newObject.id = this.user.id;
         newObject.username = btoa(this.user.username);
         if (this.createUserForm.value.supervisors.length !== 0 && this.user.supervisors.length !== 0) {
            newObject.supervisors = this.createUserForm.value.supervisors.map((s: any) => s.username !== undefined ? s.username : s);
         }

         if (this.createUserForm.value.subordinates.length > 0 && this.user.subordinates.length !== 0) {
            newObject.subordinates = this.createUserForm.value.subordinates.map((s: any) => s.username !== undefined ? s.username : s);
         }
         this.userService.updateUser(newObject, this.userId).subscribe((savedUser) => {
            this.editPage(savedUser, this.userId);
            this.router.navigate(['/users']);
         });
      }

   }
   editPage(user: any, userId: any) {
      if (userId === null) {
         this.page.content.push(user);
         this.page.totalElements++;
      } else {
         this.page.content = this.page.content.map(u => u.userId !== userId ? u : user);
      }
      this.localStorageService.set('filterPage', JSON.stringify(this.page));
   }

   checkIsRoleChanged(selectedRoles: any) {
      if (selectedRoles.length > 0) {
         if (selectedRoles.some((role: any) => role.role === this.strucniRadnik)) {
            this.isRoleDescriptionNeeded = true;
            this.findAllByRole(this.supervizor);
         } else {
            this.isRoleDescriptionNeeded = false;
            this.createUserForm.controls.supervisors.setValue([]);
         }
         if (selectedRoles.some((role: any) => role.role === this.supervizor)) {
            this.isUserSupervisor = true;
            this.findAllByRole(this.strucniRadnik);
         } else {
            this.isUserSupervisor = false;
            this.createUserForm.controls.subordinates.setValue([]);
         }

         selectedRoles.some((role: any) => role.role === this.admin_centra) ? this.disableOtherRoles() : this.disableCenterAdmin();

      } else {
         this.enableAll();
      }
   }

   disableOtherRoles() {
      const adminIndex = this.roles.length - 1;
      this.createUserForm.controls.role.patchValue([...this.roles.map((item: any) => item.role)]);
      this.createUserForm.controls.role.setValue([this.roles[adminIndex]]);

      this.disabledRoles = this.disabledRoles.map(_ => true);
      this.disabledRoles[adminIndex] = false;
   }

   disableCenterAdmin() {
      const adminIndex = this.roles.length - 1;
      this.disabledRoles = this.disabledRoles.map(_ => false);
      this.disabledRoles[adminIndex] = true;
   }

   enableAll() {
      this.disabledRoles = this.disabledRoles.map(_ => false);
   }

   findAllByRole(role: any) {
      let centerId = null;
      if (this.isCenterAdminLogged) {
         centerId = this.loggedInCenter.id;
      } else if (this.createUserForm.value.center !== null) {
         centerId = this.createUserForm.value.center.id;
      }

      if (centerId !== null) {
         this.userService.findAllByRole(centerId, role).subscribe(
            result => {
               if (role === this.supervizor) {
                  this.supervisors = result;
                  this.supervisors = this.supervisors.filter(sub => sub.username !== this.user.username);
                  if (this.userId !== undefined) {
                     this.createUserForm.patchValue({
                        supervisors: this.user.supervisors.length > 0 ?
                           this.supervisors.filter(sub => this.user.supervisors.includes(sub.username)) : [],
                     });
                     this.createUserForm.controls.supervisors.updateValueAndValidity();
                  }
               } else if (role === this.strucniRadnik) {
                  this.subordinates = result;
                  this.subordinates = this.subordinates.filter(sub => sub.username !== this.user.username);
                  if (this.userId !== undefined) {
                     this.createUserForm.patchValue({
                        subordinates: this.user.subordinates.length > 0 ?
                           this.subordinates.filter(sub => this.user.subordinates.includes(sub.username)) : []
                     });
                     this.createUserForm.controls.subordinates.updateValueAndValidity();
                  }
               }

            }
         );
      }
   }

   compareCenters(object1: any, object2: any) {
      if (object2 !== null) {
         return object1.id === object2.id;
      }
   }

   compareOffices(object1: any, object2: any) {
      if (object2 !== null) {
         return object1.ouId === object2.ouId;
      }
   }

   compareRoles(object1: any, object2: any) {
      if (object2 !== null && object2 !== '') {
         return object1.id === object2.id;
      }
   }

   compareRoleDescription(object1: any, object2: any) {
      if (object2 !== null && object2 !== '') {
         return object1 === object2;
      }
   }

   compareSubordinates(object1: any, object2: any) {
      if (object2 !== null && object2 !== '') {
         return object1 === object2.username;
      }
   }
   checkJMBGRule(): boolean {
      if (this.createUserForm.value.jmbg !== null && this.createUserForm.value.jmbg !== '') {
         if (this.createUserForm.value.jmbg.length < 13) {
            this.createUserForm.get('jmbg').setErrors({ pattern: true });
            return false;
         } else {
            const jmbg = this.createUserForm.value.jmbg;
            const digits = jmbg.split('').map(Number);
            const delimiter = 11;
            let sum = 0;
            let start = 7;
            for (let index = 0; index < digits.length - 1; index++, start--) {
               if (start === 1) {
                  start = 7;
               }
               sum += start * digits[index];
            }
            let controlNumber = sum % delimiter;
            if (controlNumber > 1) {
               controlNumber = delimiter - controlNumber;
            }
            if (controlNumber !== digits[digits.length - 1]) {
               this.createUserForm.get('jmbg').setErrors({ pattern: true });
               this.createUserForm.controls.jmbg.markAsTouched();
               setTimeout(() => {
                  this.createUserForm.get('jmbg').setErrors({ pattern: true });
                  this.createUserForm.controls.jmbg.markAsTouched();
                  return false;
               });
               return false;
            }
            return true;
         }
      }
      return false;
   }
}

