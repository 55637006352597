/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 *
*/
/**
 * @author Goran Divovic goran.divovic@iten.rs
 */
export class FormsSOCostsParticipation {
  fullName: string;
  jmbg: string;
  typeOfParticipation: string;
  participationAmount: number;
  place: string;
  pttNumber: number;
  address: string;
  startDate: Date

  constructor(fullName: string, jmbg: string, typeOfParticipation: string, participationAmount: number,
    place: string, pttNumber: number, address: string, startDate: Date) {
    this.fullName = fullName;
    this.jmbg = jmbg;
    this.typeOfParticipation = typeOfParticipation;
    this.participationAmount = participationAmount;
    this.place = place;
    this.pttNumber = pttNumber;
    this.address = address;
    this.startDate = startDate;
  }
}
