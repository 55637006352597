/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FosterChildService } from 'src/services/foster-child.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { NewFosterChildCloseMembersStepComponent } from './new-foster-child-close-members-step/new-foster-child-close-members-step.component';
import { NewFosterChildCostCarriersStepComponent } from './new-foster-child-cost-carriers-step/new-foster-child-cost-carriers-step.component';
import { NewFosterChildPersonalInformationStepComponent } from './new-foster-child-personal-information-step/new-foster-child-personal-information-step.component';
import { NewFosterChildReasonsForParentalAbsenceStepComponent } from './new-foster-child-reasons-for-parental-absence-step/new-foster-child-reasons-for-parental-absence-step.component';
import { NewFosterChildSuitabilityStepComponent } from './new-foster-child-suitability-step/new-foster-child-suitability-step.component';

@Component({
    selector: 'new-foster-child-record',
    templateUrl: './new-foster-child-record.component.html',
    styleUrls: ['./new-foster-child-record.component.scss'],
})
export class NewFosterChildRecordComponent implements OnInit {
    personalInformationStep: FormGroup;
    parentInformationStep: FormGroup;
    reasonsForParentalAbsenceStep: FormGroup;
    closeMembersStep: FormGroup;
    costCarriersStep: FormGroup;
    suitabilityStep: FormGroup;
    centerStep: FormGroup;
    @ViewChild(NewFosterChildPersonalInformationStepComponent) private personalChild: NewFosterChildPersonalInformationStepComponent;
    @ViewChild(NewFosterChildCloseMembersStepComponent) private familylInformationChild: NewFosterChildCloseMembersStepComponent;
    @ViewChild(NewFosterChildReasonsForParentalAbsenceStepComponent) private reasonsParentalAbsenceStepChild: NewFosterChildReasonsForParentalAbsenceStepComponent;
    @ViewChild(NewFosterChildCloseMembersStepComponent) private closeMembersChild: NewFosterChildCloseMembersStepComponent;
    @ViewChild(NewFosterChildCostCarriersStepComponent) private costCarriersStepChild: NewFosterChildCostCarriersStepComponent;
    @ViewChild(NewFosterChildSuitabilityStepComponent) private suitabilityStepChild: NewFosterChildSuitabilityStepComponent;


    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private fosterChildService: FosterChildService,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private toastr: ToastrImplService) {
        this.personalInformationStep = formBuilder.group({});
        this.parentInformationStep = formBuilder.group({});
        this.reasonsForParentalAbsenceStep = formBuilder.group({});
        this.closeMembersStep = formBuilder.group({});
        this.costCarriersStep = formBuilder.group({});
        this.suitabilityStep = formBuilder.group({});
        this.centerStep = formBuilder.group({});
    }

    ngOnInit() { }

    /**
     * Take information from personal step
     */
    updatePersonalInformationStep(step: FormGroup) {
        this.personalInformationStep = step;
    }

    /**
     * Take information from parent step
     */
    updateParentInformationStep(step: FormGroup) {
        this.parentInformationStep = step;
    }

    /**
     * Take information from reason for parental absence step
     */
    updateReasonsForParentalAbsenceStep(step: FormGroup) {
        this.reasonsForParentalAbsenceStep = step;

    }

    /**
     * Take information from reason for close members step
     */
    updateCloseMembersStep(step: FormGroup) {
        this.closeMembersStep = step;
    }

    /**
     * Take information from reason for cost carriers step
     */
    updateCostCarriersStep(step: FormGroup) {
        this.costCarriersStep = step;
    }

    /**
     * Take information from reason for suitability step
     */
    updateSuitabilityStep(step: FormGroup) {
        // This is the final step where, apart from validating the data from this step
        // we are supposed to submit the new record to dedicated service.
        this.suitabilityStep = step;
    }

    updateCenterStep(step: FormGroup) {
        // This is the final step where, apart from validating the data from this step
        // we are supposed to submit the new record to dedicated service.
        this.centerStep = step;
        this.createNewFosterChild();

    }

    createNewFosterChild() {
        const body = {
            registrationId: this.personalInformationStep.value.registrationId,
            subjectId: this.personalInformationStep.value.subjectId,
            firstName: this.personalInformationStep.value.firstName,
            lastName: this.personalInformationStep.value.lastName,
            gender: this.personalInformationStep.value.gender,
            dateOfBirth: this.datePipe.transform(new Date(this.personalInformationStep.value.dateOfBirth).getTime(), 'dd/MM/yyyy'),
            placeOfBirth: this.personalInformationStep.value.placeOfBirth,
            permanentResidence: this.personalInformationStep.value.placeOfOrigin,
            jmbg: this.personalInformationStep.value.jmbg,
            nationality: this.personalInformationStep.value.nationality !== '' ? this.personalInformationStep.value.nationality : null,
            citizenship: this.personalInformationStep.value.citizenship !== '' ? this.personalInformationStep.value.citizenship : null,
            familyStatus: this.personalInformationStep.value.familyStatus,
            schoolStatus: this.personalInformationStep.value.educationStatus,
            schoolLevel: this.personalInformationStep.value.class,
            guardianship: this.personalInformationStep.value.fosteringStatus.value,
            guardianshipDate: this.personalInformationStep.value.fosteringFrom,
            generalHealthStatusCodebook: this.personalInformationStep.value.generalHealthStatus,
            healthDeviationsItems: this.personalInformationStep.value.healthDeviationItems,
            psychophysicalDevelopmentCodebook: this.personalInformationStep.value.phychophysicalDevelopment,
            phisicalDeviationItems: this.personalInformationStep.value.phisicalDeviationItems,
            psychophysicalPrognosis: this.personalInformationStep.value.psychophysicalPrognosis,
            absenceCareWithParentsCodebook: this.reasonsForParentalAbsenceStep.value.childWithParentalCare === '' ?
                null : this.reasonsForParentalAbsenceStep.value.childWithParentalCare,
            absenceCareWithoutParentsCodebook: this.reasonsForParentalAbsenceStep.value.childWithoutParentalCare === '' ?
                null : this.reasonsForParentalAbsenceStep.value.childWithoutParentalCare,
            motherInformation: this.parentInformationStep.value.motherInformation,
            fatherInformation: this.parentInformationStep.value.fatherInformation,
            professionalOpinion: {
                psychologistDate: this.suitabilityStep.value.reportDates.psychologist,
                pedagogueDate: this.suitabilityStep.value.reportDates.pedagogue,
                lawyerDate: this.suitabilityStep.value.reportDates.lawyer,
                doctorDate: this.suitabilityStep.value.reportDates.doctor,
                socialWorkerDate: this.suitabilityStep.value.reportDates.socialWorker,
                institutionDate: this.suitabilityStep.value.reportDates.otherInstitutions,
                numberOfDecision: this.suitabilityStep.value.decisionNumber,
                decisionDate: this.suitabilityStep.value.decisionDate,
                guardianshipName: this.centerStep.value.guardianshipName,
                guardianshipPhone: this.centerStep.value.phone,
                guardianshipEmail: this.centerStep.value.emailAddress,
                guardianshipAddress: this.centerStep.value.address,
                questionnaireNumber: this.centerStep.value.questionnaireNumber,
                questionnaireDate: this.centerStep.value.questionnaireDate
            },
            brothersAndSisters: this.closeMembersStep.value.closeMembersInfo,
            guardians: this.closeMembersStep.value.guardianInfo,
            acquaintancesInfo: this.closeMembersStep.value.acquaintancesInfo,
            costCarriersParents: this.costCarriersStep.value.parents,
            costCarriersOtherRelatives: this.costCarriersStep.value.otherRelatives,
            costCarriersOwnEarnings: this.costCarriersStep.value.ownIncome
        };

        if (body.motherInformation.dateOfBirth !== null || body.motherInformation.dateOfBirth !== '') {
            body.motherInformation.dateOfBirth = this.datePipe.transform(new Date(body.motherInformation.dateOfBirth).getTime(), 'dd/MM/yyyy');
        } else {
            body.motherInformation.dateOfBirth = null;
        }

        if (body.fatherInformation.dateOfBirth !== null || body.fatherInformation.dateOfBirth !== '') {
            body.fatherInformation.dateOfBirth = this.datePipe.transform(new Date(body.fatherInformation.dateOfBirth).getTime(), 'dd/MM/yyyy');
        } else {
            body.fatherInformation.dateOfBirth = null;
        }
        this.fosterChildService.createFosterChild(body).subscribe((res) => {
            if (res != null) {
                this.toastr.success('SNACKBAR.ADDED_FOSTER_CHILD');
                this.router.navigateByUrl('/foster-child');
            } else {
                this.toastr.error('SNACKBAR.ADD_FOSTER_CHILD_ERROR');
                this.router.navigateByUrl('/foster-child');
            }
        });
    }
    /**
     * Review if all form steps are valid
     */
    checkDocumentValidation(): boolean {
        const isPersonalInformationStepValid =
            this.personalInformationStep && this.personalInformationStep.valid;
        const isParentInformationStepValid =
            this.parentInformationStep && this.parentInformationStep.valid;
        const isReasonsForParentalAbsenceStepValid =
            this.reasonsForParentalAbsenceStep &&
            this.reasonsForParentalAbsenceStep.valid;
        const isCloseMembersStepValid =
            this.closeMembersStep && this.closeMembersStep.valid;
        const isCostCarriersStepValid =
            this.costCarriersStep && this.costCarriersStep.valid;
        const isSuitabilityStepValid =
            this.suitabilityStep && this.suitabilityStep.valid;
        return [
            isPersonalInformationStepValid,
            isParentInformationStepValid,
            isReasonsForParentalAbsenceStepValid,
            isCloseMembersStepValid,
            isCostCarriersStepValid,
            isSuitabilityStepValid
        ].every((val) => val === true);
    }

    public onStepChange(stepper: MatStepper): void {
        if (stepper.selectedIndex === 6) {
            this.personalChild.updatePersonalInformationStep();
            this.familylInformationChild.updateCloseMembersStep();
            this.reasonsParentalAbsenceStepChild.updateReasonsForParentalAbsenceStep();
            this.closeMembersChild.updateCloseMembersStep();
            this.suitabilityStepChild.updateSuitabilityStep();
            if (!this.personalInformationStep.valid) {
                this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_FIRST');
            }
            else if (!this.parentInformationStep.valid) {
                this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_SECOND_STEP');
            }
            else if (!this.reasonsForParentalAbsenceStep.valid) {
                this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_TIRHT_STEP');
            }
            else if (!this.closeMembersStep.valid) {
                this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_FOUT_STEP');
            }
            else if (!this.costCarriersStep.valid) {
                this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_FIVE_STEP');
            } else if (!this.suitabilityStep.valid) {
                this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_SIX_STEP');
            }
        }
    }
}
