/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ModalEventEnum } from 'src/types';
import { TrackingListService } from 'src/services/tracking-list.service';
import { CodebookService } from 'src/services/codebook.service';
import { CaseService } from 'src/services/case.service';
import { Observable } from 'rxjs';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { map, startWith } from 'rxjs/operators';

interface TrackingData {
   trackingData: any;
   action: ModalEventEnum.CANCEL;
   subjectId: any;
}

interface Case {
   value: string;
   viewValue: string;
}

enum AutocompleteFilterEnum {
   ACTIVITY = 'activity',
}

@Component({
   selector: 'manage-tracking',
   templateUrl: './manage-tracking.component.html',
   styleUrls: ['./manage-tracking.component.scss'],
})
export class ManageTrackingComponent implements OnInit {
   action: string;
   data: any;
   isEditing = false;
   isNew = false;
   trackingDocumentForm: FormGroup;
   item: any;
   activities = [];
   filteredActivities: Observable<any[]>;
   subjectCases = [];
   maxDate: Date = new Date(2999, 11, 31);
   isActivitySelected: boolean = false;

   constructor(
      public dialogRef: MatDialogRef<any>,
      @Optional() @Inject(MAT_DIALOG_DATA) public tracking: TrackingData,
      private formBuilder: FormBuilder,
      private trackingListService: TrackingListService,
      private codebookService: CodebookService,
      private caseService: CaseService
   ) {
      codebookService.getActivityCodebook().subscribe(result => {
         this.activities = result;
         this.filteredActivities = this.trackingDocumentForm.controls.activity.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.ACTIVITY) : this.activities.slice()))
         );
      });
      caseService.getCasesBySubjectId(this.tracking.subjectId).subscribe(result => {
         this.subjectCases = result;
      });
   }

   ngOnInit(): void {
      this.data = this.tracking.trackingData;
      this.action = this.tracking.action;

      this.trackingDocumentForm = this.formBuilder.group({
         subjectId: this.data.subjectId,
         caseName: ['', []],
         caseNumber: [],
         activity: ['', [Validators.required]],
         note: ['', [Validators.required]],
         date: ['', [Validators.required]],
      });

      if (this.action === ModalEventEnum.EDIT) {
         this.toggleEdit();
         this.isEditing = true;
      }

      if (this.action === ModalEventEnum.NEW) {
         this.toggleNew();
         this.isNew = true;
      }
   }

   toggleEdit() {
      this.isEditing = !this.isEditing;

      this.trackingDocumentForm.patchValue({
         caseName: this.data.caseName,
         activity: this.data.activity,
         note: this.data.description,
         date: this.data.date,
      });
   }

   toggleNew() {
      this.isNew = true;
      this.trackingDocumentForm.patchValue({
         caseName: this.data.caseName,
      });
   }

   handleDelete() {
      this.action = 'delete';
      this.trackingListService.removeItem(this.tracking.trackingData.id).subscribe(
         (res: any) => {
            this.dialogRef.close({ event: ModalEventEnum.DELETE });
         },
         (err: string) => {
            console.log('Error occured' + err);
         }
      );
   }

   compareActivityObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }

   manageTrackingDocument(trackingDocumentForm: FormGroup) {      
      if (this.action === 'view' || this.action === 'new' || this.action === 'edit') {
         this.item = {
            id: this.tracking.trackingData.id,
            caseId: this.tracking.trackingData.caseId,
            subjectIdentity: {
               csrId: '',
               subjectId: this.tracking.subjectId,
            },
            date: new Date(trackingDocumentForm.get('date').value).setHours(
               new Date(trackingDocumentForm.get('date').value).getHours() - new Date(trackingDocumentForm.get('date').value).getTimezoneOffset() / 60
            ),
            activity: trackingDocumentForm.get('activity').value,
            description: trackingDocumentForm.get('note').value,
         };
         this.trackingListService.addItem(this.item).subscribe(
            (res: any) => {
               this.dialogRef.close({ event: 'SUCCESS' });
            },
            (err: string) => {
               console.log('Error occured' + err);
            }
         );
      }
   }

   closeDialog() {
      this.dialogRef.close({ event: ModalEventEnum.CANCEL });
   }
   displayCustomType(objectValue: any) {
      return objectValue ? objectValue.title : '';
   }
   public _filter(value: string, type: string): any[] {
      const filterValue = value.toLowerCase();

      switch (type) {
         case AutocompleteFilterEnum.ACTIVITY:
            return sortByFirstLetter(
               this.activities.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'title'
            );
      }
   }

   async autoSelectType(){
    autoSelect(this.activities, 'activity', 'title', this.trackingDocumentForm);
    await new Promise(resolve => setTimeout(resolve, 600));

    if(this.trackingDocumentForm.get('activity').value?.id === undefined) {
      this.isActivitySelected = false;
    } else {
      this.isActivitySelected = true;
    }
  }

  onInputChange(event: any) {
   this.isActivitySelected = false;
  }

  onOptionSelected(event: any) {
   this.isActivitySelected = true;
  }

}

/**
 * TODO @gorandivovic @jelenaninkovic @danijelgrabez @oljaandjelovski
 *
 * 1. Improve sending document on "edit/new/delete tracking document" depending on API
 */
