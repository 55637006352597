import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class AccommodationFacilityService {
   constructor(private httpClient: HttpClient, private app: AppService) {}

   addCategoriesToFacilityCodebook(facilityId: any, newCategoriesPricings: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.patch(this.app.getUrl() + environment.addCategoriesToFacilityCodebook + facilityId, newCategoriesPricings, {
         headers,
      }) as Observable<any>;
   }

   removeCategoryFromFacilityCodebook(facilityId: any, categoryId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.removeCategoryFromFacilityCodebook + facilityId + '/' + categoryId, {
         headers,
      }) as Observable<any>;
   }

   editCategoryPricingFromFacilityCodebook(facilityId: any, pricingId: any, newCategoriesPricings: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.patch(this.app.getUrl() + environment.editCategoryFromFacilityCodebook + facilityId + '/' + pricingId, newCategoriesPricings, {
         headers,
      }) as Observable<any>;
   }
   addSupportCategoriesToFacilityCodebook(facilityId: any, newCategoriesPricings: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.addSupportCategoriesToFacilityCodebook + facilityId, newCategoriesPricings, {
         headers,
      }) as Observable<any>;
   }

   removeSupportCategoryFromFacilityCodebook(facilityId: any, categoryId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.removeSupportCategoryFromFacilityCodebook + facilityId + '/' + categoryId, {
         headers,
      }) as Observable<any>;
   }

   editSupportCategoryPricingFromFacilityCodebook(facilityId: any, newCategoriesPricings: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.patch(this.app.getUrl() + environment.editSupportCategoryFromFacilityCodebook + facilityId, newCategoriesPricings, {
         headers,
      }) as Observable<any>;
   }
}
