<div class="widget-subjects-wrapper">
  <card-header title="{{ 'SUBJECTS.CARD_TITLE' | translate }}">
    <div class="aside">
      <mat-form-field appearance="outline" class="search" *ngIf="isMainBelgradeCenter || isUserDrugostepeni">
        <mat-label>
          {{'GLOBAL.CENTER' | translate}}
        </mat-label>
        <input type="text" matInput [formControl]="subcenter" [matAutocomplete]="autoSubcenter" />
        <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete" [displayWith]="displayCustomFormat"
                          (optionSelected)="reloadSubjects($event.option.value)">
          <mat-option *ngFor="let cent of filteredSubcenters | async" [value]="cent">
            {{ cent.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline" class="search">
        <mat-label>{{
          'GLOBAL.SEARCH' | translate
        }}</mat-label>
        <input matInput type="text" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)" placeholder="Pretraga">
      </mat-form-field>
      <button mat-icon-button class="button-square button-square--large" color="primary"
        matTooltip="{{ 'SUBJECTS.NEW_SUBJECT' | translate }}" (click)="newSubjectInNewTab()"
        *ngIf="!isMainBelgradeCenter && !isUserDrugostepeni">
        <mat-icon class="button-square__icon">person_add</mat-icon>
      </button>
    </div>
  </card-header>
  <mat-chip-list #chipList class="chip-list" [ngStyle]="{ 'display': searchItems.length > 0 ? 'block' : 'none' }">
    <mat-chip *ngFor="let item of searchItems" [selectable]="selectable" [removable]="removable"
      (removed)="remove(item)">
      {{item.name}}
      <mat-icon matChipRemove *ngIf="removable">
        cancel
      </mat-icon>
    </mat-chip>
  </mat-chip-list>
  <div *ngIf="areLoaded" >
  <table mat-table id="myTable" class="subjects__table" #myTable [dataSource]="page.content">
    <ng-container matColumnDef="registrationId">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'SUBJECTS.REGID' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.registrationId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{ 'ID' | translate }}</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element.subjectIdentity.subjectId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'SUBJECTS.LASTNAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'SUBJECTS.FIRSTNAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
    </ng-container>
    <ng-container matColumnDef="dateOfBirth">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'SUBJECTS.DATEOFBIRTH' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.dateOfBirth | date:"dd.MM.yyyy." }}
      </td>
    </ng-container>
    <ng-container matColumnDef="jmbg">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'SUBJECTS.JMBG' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.jmbg }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
    <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" class="subjects__row" (click)="clickSubject(element.subjectIdentity.subjectId)"></tr>
  </table>

  <div class="paginator-wrapper">
    <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
    </app-custom-pagination>
  </div>
  </div>
 </div>
<div class="spinner-wrapper" *ngIf="!areLoaded">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
