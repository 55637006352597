<div class="form" >
    <h3 *ngIf="assginedTableData.length > 0">
        {{ 'WORKLOAD_DETAILS.ASSIGNED_CASES_TITLE' | translate }}
    </h3>

    <div class="table-responsive-wrapper" *ngIf="assginedTableData.length > 0" style="overflow-y:auto; max-height:200px; "> 
        <table mat-table id="assginedTable" class="assginedCases__table" #myTable [dataSource]="assginedTableData" >
          
          <ng-container matColumnDef="caseNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.CASE' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element?.caseNumber }} </td>
          </ng-container>
          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef>{{ 'WORKLOAD_DETAILS.PRIORITY' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index"> {{ getPriorityText(element?.priority) | translate }} </td>
          </ng-container>
          <ng-container matColumnDef="workload">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index"> {{ element?.workload }} </td>
          </ng-container>
          <ng-container matColumnDef="sum">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.SUM' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.sumForCase }} </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="assginedTableColumns" class="assginedCases__row--header"></tr>
          <tr mat-row *matRowDef="let element; let row; columns: assginedTableColumns" class="assginedCases__row"></tr>
        </table>
        
    </div>
    <field-holder kind="3-cols" class="workloadFootnote" *ngIf="assginedTableData.length > 0">
        <div> {{ 'WORKLOAD_DETAILS.PRIORITY' | translate }} 1 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 3 </div>
        <div> {{ 'WORKLOAD_DETAILS.PRIORITY' | translate }} 2 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 2 </div>
        <div> {{ 'WORKLOAD_DETAILS.PRIORITY' | translate }} 3 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 1 </div>
    </field-holder>
       
    <h3 *ngIf="teamTableData.length > 0">
        {{ 'WORKLOAD_DETAILS.TEAM_MEMBER_CASES' | translate }}
    </h3>
    
    <div class="table-responsive-wrapper" *ngIf="teamTableData.length > 0" style="overflow-y:auto; max-height:200px; "> 
        <table mat-table id="assginedTable" class="assginedCases__table" #myTable [dataSource]="teamTableData">
          
          <ng-container matColumnDef="caseNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.CASE' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element?.caseNumber }} </td>
          </ng-container>          
          <ng-container matColumnDef="assigned">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.ASSIGNED_WORKER' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element?.caseManager}} </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="teamTableColumns" class="assginedCases__row--header"></tr>
          <tr mat-row *matRowDef="let element; let row; columns: teamTableColumns" class="assginedCases__row"></tr>
        </table>
    </div>
    
    <h3 *ngIf="counclerTableData.length > 0">
        {{ 'WORKLOAD_DETAILS.LEGAL_COUNSELOR_CASES' | translate }}
    </h3>
    
    <div class="table-responsive-wrapper" *ngIf="counclerTableData.length > 0" style="overflow-y:auto; max-height:200px; "> 
        <table mat-table id="counclerTable" class="assginedCases__table" #myTable1 [dataSource]="counclerTableData">
          
          <ng-container matColumnDef="caseNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.CASE' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element?.caseNumber }} </td>
          </ng-container>
          <ng-container matColumnDef="assigned">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.ASSIGNED_WORKER' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element?.caseManager}} </td>
          </ng-container>
          
          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef>{{ 'WORKLOAD_DETAILS.PRIORITY' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index"> {{ getPriorityText(element?.priority) | translate }} </td>
          </ng-container>
          <ng-container matColumnDef="workload">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index"> {{ element?.workload }} </td>
          </ng-container>
          <ng-container matColumnDef="sum">
            <th mat-header-cell *matHeaderCellDef> {{ 'WORKLOAD_DETAILS.SUM' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ calculateSumPerCase(element) }} </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="counclerTableColumns" class="assginedCases__row--header"></tr>
          <tr mat-row *matRowDef="let element; let row; columns: counclerTableColumns" class="assginedCases__row"></tr>
        </table>
    </div>
    
    <field-holder kind="3-cols" class="workloadFootnote" *ngIf="counclerTableData.length > 0 && assginedTableData == 0" >
        <div> {{ 'WORKLOAD_DETAILS.PRIORITY' | translate }} 1 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 3 </div>
        <div> {{ 'WORKLOAD_DETAILS.PRIORITY' | translate }} 2 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 2 </div>
        <div> {{ 'WORKLOAD_DETAILS.PRIORITY' | translate }} 3 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 1 </div>
    </field-holder>
    
    <h3 *ngIf="assginedTableData.length == 0 && teamTableData.length == 0 && counclerTableData.length == 0">
        {{ 'WORKLOAD_DETAILS.NO_ASSIGNED_CASES' | translate }}
    </h3>
    
    <div>
        <field-holder kind="2-cols" class="workloadFootnote">
            <pre *ngIf="assginedTableData.length > 0 || counclerTableData.length > 0">
{{ 'WORKLOAD_DETAILS.ASSIGNED_CASES_NUMBER' | translate }}    1-10 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 1 
{{ 'WORKLOAD_DETAILS.ASSIGNED_CASES_NUMBER' | translate }}  11-20 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 2 
{{ 'WORKLOAD_DETAILS.ASSIGNED_CASES_NUMBER' | translate }}  21-30 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 3
{{ 'WORKLOAD_DETAILS.ASSIGNED_CASES_NUMBER' | translate }}     >31 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 4
            </pre>
            <pre *ngIf="teamTableData.length > 0">
{{ 'WORKLOAD_DETAILS.TEAM_MEMBER_CASES_NUMBER' | translate }}   1-5   : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 1
{{ 'WORKLOAD_DETAILS.TEAM_MEMBER_CASES_NUMBER' | translate }}   5-10 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 2
{{ 'WORKLOAD_DETAILS.TEAM_MEMBER_CASES_NUMBER' | translate }} 11-20 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 3
{{ 'WORKLOAD_DETAILS.TEAM_MEMBER_CASES_NUMBER' | translate }}     >21 : {{ 'WORKLOAD_DETAILS.WORKLOAD' | translate }} = 4
            </pre>
        </field-holder>       
        <div  *ngIf="assginedTableData.length > 0 || counclerTableData.length > 0"> 
              {{ 'WORKLOAD_DETAILS.CASES_WEIGHT_SUM' | translate }} ({{assignedCasesSum}})<br>   
              {{ 'WORKLOAD_DETAILS.ASSIGNED_CASES_POINTS' | translate }} ({{assignedCasesPoints}})
        </div> 
        <div *ngIf="teamTableData.length > 0"> 
              {{ 'WORKLOAD_DETAILS.TEAM_MEMBER_CASES_POINTS' | translate }} ({{teamCasesPoints}})
        </div>
        <hr> 
        <div>  {{ 'WORKLOAD_DETAILS.WEIGHT_FACTOR_SUM' | translate }} = {{worker.workload}}</div>
        <br>
    </div>
    
    <div>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
            class="form-footer__button form-footer__button--secondary">
            {{ 'WORKLOAD_DETAILS.CLOSE' | translate }}
        </button>
    </div>
</div>