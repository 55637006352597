<div class="container">
   <form flex [formGroup]="socialMapPartForm" (ngSubmit)="addNewPart()">
      <field-holder kind="2-cols">
         <mat-form-field appearance="outline">
            <mat-label>{{
          'NEW_SUBJECT.PERSONAL_DETAILS.FIRST_NAME' | translate
        }}</mat-label>
            <input matInput type="text" formControlName="firstName" autocomplete="off" required />
            <mat-error *ngIf="
            socialMapPartForm.get('firstName').hasError('required') ||
            socialMapPartForm.get('firstName').hasError('pattern')
          ">
               {{ 'NEW_SUBJECT.PERSONAL_DETAILS.FIRST_NAME_ERROR' | translate }}
            </mat-error>
         </mat-form-field>
         <mat-form-field appearance="outline">
            <mat-label>{{
          'NEW_SUBJECT.PERSONAL_DETAILS.LAST_NAME' | translate
        }}</mat-label>
            <input matInput type="text" formControlName="lastName" autocomplete="off" required />
            <mat-error *ngIf="
            socialMapPartForm.get('lastName').hasError('required') ||
            socialMapPartForm.get('lastName').hasError('pattern')
          ">
               {{ 'NEW_SUBJECT.PERSONAL_DETAILS.LAST_NAME_ERROR' | translate }}
            </mat-error>
         </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
         <mat-form-field appearance="outline">
            <mat-label>{{
          'NEW_SUBJECT.PERSONAL_DETAILS.JMBG' | translate
        }}</mat-label>
            <input maxlength="13" matInput type="text" formControlName="jmbg" required autocomplete="off"
               (focusout)="checkJMBGRule($event)" />
            <mat-error *ngIf="
            socialMapPartForm.get('jmbg').hasError('required') ||
            socialMapPartForm.get('jmbg').hasError('maxLength') ||
            socialMapPartForm.get('jmbg').hasError('pattern')
          ">
               {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="
            socialMapPartForm.get('jmbg').hasError('controlNumber')
          ">
               {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR_VALIDATION' | translate }}
            </mat-error>
            <mat-error *ngIf="
            socialMapPartForm.get('jmbg').hasError('unique')
          ">
               {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR_UNIQUE' | translate }}
            </mat-error>
         </mat-form-field>
         <mat-form-field appearance="outline">
            <mat-label>{{
            'FOSTER_CHILD.FAMILY_DATA.RELATIONSHIP' | translate
          }}</mat-label>
            <mat-select formControlName="relationship" required>
               <mat-option *ngFor="let status of relationshipOptions" [value]="status">
                  {{ status.viewValue }}
               </mat-option>
            </mat-select>
         </mat-form-field>
      </field-holder>
      <form-footer>
         <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
            class="form-footer__button form-footer__button--secondary">
            {{ 'FOSTER_CHILD.FAMILY_DATA.CANCEL' | translate }}
         </button>
         <button mat-flat-button color="primary" type="submit" [disabled]="!socialMapPartForm.valid"
            class="form-footer__button form-footer__button--primary">
            {{ data | translate }}
         </button>
      </form-footer>
   </form>