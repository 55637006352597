/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FamilyMember, EventEnum } from 'src/app/entrance/types';
import { Subject } from 'src/models/subject.model';
import { FamilyMembersComponent } from 'src/app/entrance/family-members/family-members.component';
import { MatDialog } from '@angular/material/dialog';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { tooManyMembersForBillingWarn } from 'src/app/utils/warningMessage';
import { ToastrImplService } from 'src/services/toastr.service';

interface SelectOptions {
   value: any;
   viewValue: string;
}

enum YesNoEnum {
   JEDNORODITELJSKA = 0,
   NESPOSOBNI = 1
}

@Component({
   selector: 'nsp-family-step',
   templateUrl: './nsp-family-step.component.html',
   styleUrls: ['./nsp-family-step.component.scss'],
   providers: [
      DatePipe,
   ],
})
export class NspFamilyStepComponent implements OnInit {
   @Output() familyMembersStepEmitter = new EventEmitter();
   @Input() familyMembersStep: FormGroup;
   @Input() editable: boolean;
   @Input() customData: any;
   @Input() subjectData: any;
   @Input() sameJmbps: boolean;

   increaseNSP = true;
   oneParent = true;
   allDisabled = true;

   members: Array<FamilyMember> = [];
   isMemberSelected = false;
   selectedMember = null;
   increasedNspReasons: SelectOptions[] = [
      { value: YesNoEnum.JEDNORODITELJSKA, viewValue: 'Једнородитељска породица' },
      { value: YesNoEnum.NESPOSOBNI, viewValue: 'Сви чланови неспособни за рад' },
   ];
   // Family members table
   displayedColumns: string[] = [
      'name',
      'relationship',
      'jmbg',
      'qualificationEmployment',
      'osi',
      'ableToWork',
      'forBilling',
      'edit'
   ];
   membersForBillingCounter = 0;
   adultMembersCount = 0;
   underageMembersCount = 0;
   familyDossierId: any;
   isMainCenterBelgrade = false;
   loggedIn: any;
   isUserDrugostepeni = false;
   localStorageCenterId: any;

   constructor(
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      private translate: TranslateService,
      private toastr: ToastrImplService,
      private datePipe: DatePipe) { }

   ngOnInit(): void {
      this.familyMembersStep = this.formBuilder.group({
         note: ['', []],
         increasedNsp: ['', []],
         increasedNspReason: ['', []]
      });
   }

   ngOnChanges(changes: any) {
      if (changes.customData !== undefined && changes.customData !== null && changes.customData.previousValue !== null
         && changes.customData.previousValue !== undefined && changes.customData.currentValue === null) {
         this.members = [];
         this.membersForBillingCounter = 0;
         this.ngOnInit();
      }
      if (changes.customData !== undefined && changes.customData !== null) {
         if (changes.customData?.currentValue !== null && changes.customData?.currentValue !== undefined) {
            if (Object.keys(changes.customData?.currentValue)?.length !== 0) {
               if (changes.customData && changes.customData?.currentValue) {
                  this.patchValue(changes.customData, false);
               }
            }
         }
      }
   }

   importFamilyMember(): void {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'nsp',
         },
      });
      dialogRef.afterClosed().subscribe((result) => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            const {
               data: {
                  firstName,
                  lastName,
                  jmbg,
                  yearOfBirth,
                  occupation,
                  disabledPerson,
                  ableToWork,
                  subjectIdentity,
                  jmbp,
                  dateOfBirth,
                  registrationId,
                  foreigner
               }
            } = obj;

            const memberData = {
               firstName,
               lastName,
               jmbg,
               yearOfBirth,
               occupation,
               disabledPerson,
               ableToWork,
               subjectIdentity,
               jmbp,
               dateOfBirth,
               registrationId,
               foreigner
            };
            if (memberData.dateOfBirth != null) {
               const dateOfBirthSplited = memberData.dateOfBirth.split('/');
               memberData.dateOfBirth = dateOfBirthSplited[1] + '/' + dateOfBirthSplited[0] + '/' + dateOfBirthSplited[2];
            }
            if (this.checkAddingOneself(memberData)) {
               this.toastr.error('SNACKBAR.USER_SUBMITER');
            } else if (!this.checkIsJmbpEqual(memberData)) {
               this.toastr.error('SNACKBAR.NOT_EQUAL_JMBP');
            } else if (this.checkIfMembersContains(memberData)) {
               let message = '';
               this.translate.get('SNACKBAR.PARTIAL.USER').subscribe((res: string) => {
                  message = res;
               });
               let message2 = '';
               this.translate.get('SNACKBAR.PARTIAL.USER_ADDED').subscribe((res: string) => {
                  message2 = res;
               });
               this.toastr.showError(message + memberData.firstName + ' ' + memberData.lastName + message2);

            } else {
               this.openFamilyMembersDialog(memberData, false);
            }
         }
      });
   }

   /**
    * Open modal to select family members and declare relations with the subject
    */
   openFamilyMembersDialog(data: Partial<Subject>, isEdit: boolean) {
      const tempSubject = { ...this.subjectData };
      const familyData = {
         origin: 'nsp',
         data,
         subject: tempSubject,
         isEdit
      };
      if ((familyData.data.firstName === undefined || familyData.data.lastName === undefined) &&
         familyData.data.name !== undefined && familyData.data.name !== null) {
         familyData.data.firstName = familyData.data.name.split(' ')[0];
         familyData.data.lastName = familyData.data.name.split(' ')[1];
      }

      const dialogRef = this.dialog.open(FamilyMembersComponent, {
         disableClose: true,
         width: '480px',
         data: familyData,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe((result) => {
         const { event, data } = result;
         if (event === EventEnum.SUBMIT) {
            if (!isEdit) {
               if (result.data[0].forBilling.value === true) {
                  this.membersForBillingCounter++;
               }
               this.members = [...this.members, ...result.data];
               this.checkIncrease();

            } else {
               const index = this.members.findIndex(x =>
                  x.subjectIdentity.subjectId === this.selectedMember.subjectIdentity.subjectId);
               let editedMember = result.data[0];
               const nameParts = editedMember.name.split(' ');
               editedMember.editable = true;
               editedMember.firstName = nameParts[0];
               editedMember.lastName = nameParts.slice(1,).join(' ');
               editedMember.dateOfBirth = editedMember.dateOfBirth;
               editedMember = this.checkIsJmbpEqualEdit(editedMember);

               this.members[index] = editedMember;
               this.members = [...this.members];
            }
            this.membersForBillingCounter = this.members.filter(member => member.forBilling === true).length;
            tooManyMembersForBillingWarn(this.membersForBillingCounter, this.toastr);
         }
      });
   }

   familyMembersDialog(data) {
      const subject = {
         subjectIdentity: data.subjectIdentity,
         firstName: data.firstName,
         lastName: data.lastName,
         name: data.name,
         registrationId: data.registrationId,
         yearOfBirth: data.yearOfBirth,
         jmbg: data.jmbg,
         jmbp: data.jmbp,
         occupation: data.qualificationEmployment,
         ableToWork: data.ableToWork,
         disabledPerson: data.disabledPerson,
         relationship: data.relationship,
         dateOfBirth: data.dateOfBirth,
         forBilling: data.forBilling,
         editable: data.editable,
         foreigner: data.foreigner
      };
      this.selectedMember = subject;
      this.openFamilyMembersDialog(subject, true);
   }

   checkIncrease() {
      this.increaseNSP = true;
      this.allDisabled = true;
      this.oneParent = true;
      let nbOfParents = 0;
      let nbOfChildren = 0;
      if (this.subjectData.ableToWork !== false) {
         this.allDisabled = false;
      }
      for (const element of this.members) {
         if ((element.relationship as any).id === 3 || (element.relationship as any).id === 4 || (element.relationship as any).id === 5) {
            if (element.ableToWork !== null && element.ableToWork !== false) {
               this.allDisabled = false;
            }
            if ((element.relationship as any).id === 4) {
               nbOfParents++;
            }
            if ((element.relationship as any).id === 3) {
               nbOfChildren++;
            }
         } else {
            this.oneParent = false;
         }
      }
      if (nbOfParents >= 1 && nbOfChildren >= 1) {
         this.oneParent = false;
      } else if (nbOfParents > 1) {
         this.oneParent = false;
      }
      if (!this.oneParent && !this.allDisabled) {
         this.increaseNSP = false;
      }
   }

   /** Get selected row from table, selected family member */
   onRowSelected(row: any) {
      if (this.selectedMember !== null) {
         this.selectedMember.highlighted = !this.selectedMember.highlighted;
      }
      row.highlighted = !row.highlighted;
      this.selectedMember = row;
      this.isMemberSelected = true;
   }

   /** Remove selected family member from table and refresh table content */
   removeFamilyMember() {
      const index = this.members.findIndex(x => x.subjectIdentity.subjectId === this.selectedMember.subjectIdentity.subjectId);
      if (this.selectedMember.forBilling === true) {
         this.membersForBillingCounter--;
      }
      this.members.splice(index, 1);
      this.members = this.members.slice();
      this.isMemberSelected = false;
      this.checkIncrease();

      let areAllJmbpsSame = true;
      this.members.forEach(member => {
         if (this.subjectData.jmbp !== member.jmbp) {
            areAllJmbpsSame = false;
         }
      });
      this.sameJmbps = areAllJmbpsSame;
   }
   /**
    * Send step information to parent component
    */

   updateFamilyMembersStep() {
      if ((this.members === null || this.members.length === 0) && this.subjectData?.ableToWork?.id === 2) {
         this.increaseNSP = true;
         this.familyMembersStep.controls.increasedNsp.setValue(true);
         this.familyMembersStep.controls.increasedNspReason.setValue(1);
      }
      this.familyMembersStepEmitter.emit({
         step: this.familyMembersStep,
         familyMembers: this.members,
         membersForBillingCounter: this.membersForBillingCounter,
         adultMembersCount: this.adultMembersCount,
         underageMembersCount: this.underageMembersCount
      });
   }

   patchValue(data: any, outsidePatch: any) {
      if (outsidePatch) {
         this.customData = data;
      }
      this.familyMembersStep.patchValue({
         note: this.customData.note,
         increasedNsp: this.customData.increasedNsp === true ? true : false,
         increasedNspReason: this.customData.increasedNspReason // doraditi patch
      });
      this.members = this.customData.familyMembers;
      this.membersForBillingCounter = this.members.filter(member => member.forBilling === true).length;

   }

   checkIfMembersContains(newMember: any) {
      for (const member of this.members) {
         if (member.subjectIdentity.subjectId === newMember.subjectIdentity.subjectId) {
            return true;
         }
      }
      return false;
   }

   // Provjeravanje da li je selektovan nosilac prava (podnosilac zahtjeva) da bude clan porodice
   checkAddingOneself(newMember: any) {
      if (newMember.subjectIdentity.subjectId === this.subjectData.subjectIdentity.subjectId) {
         return true;
      }
      return false;
   }
   // Clanovi porodice moraju imati isti jmbp kao i podnosilac zahtjeva
   checkIsJmbpEqual(newMember: any) {
      if (newMember.jmbp === this.subjectData.jmbp) {
         return true;
      }
      return false;
   }

   checkIsJmbpEqualEdit(editedMember: any) {
      if (this.subjectData.jmbp !== editedMember.jmbp) {
         this.sameJmbps = false;

         const subjectName = this.subjectData.firstName + ' ' + this.subjectData.lastName;
         let messageUser = '';
         let messageJmbp = '';
         this.translate.get('SNACKBAR.PARTIAL.USER').subscribe((res: string) => {
            messageUser = res;
         });
         this.translate.get('SNACKBAR.PARTIAL.NOT_EQUAL_JMBP_NSP').subscribe((res: string) => {
            messageJmbp = res;
         });

         const message = messageUser + editedMember.name + messageJmbp + subjectName;
         editedMember.tooltipText = message;
         editedMember.jmbpWarning = true;
      }

      return editedMember;
   }

   updateIncreasedNspReason(event: any) {
      this.familyMembersStep.get('increasedNspReason').reset();
      this.familyMembersStep.controls.increasedNspReason.setValue(false);
   }

   // Nije dozvoljeno da bude bvise od 5 clanova porodice za obracun
   isValidStep() {
      if (this.membersForBillingCounter > 5) {
         return true;
      }
      if (!this.sameJmbps) {
         return true;
      }
      return false;
   }

}
