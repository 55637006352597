<form [formGroup]="biologicalParentsStep" class="form">
  <form-group title="{{ 'NEW_ADOPTED_CHILDREN.BIOLOGICAL_PARENTS.MOTHER_TITLE' | translate }}">
    <div class="button-add-holder">
      <button mat-flat-button color="primary" type="button" (click)="importSubjectData(true, false)">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>
    <section formGroupName="mother">
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="firstName" autocomplete="off" />
          <mat-error *ngIf="biologicalParentsStep.get('mother.firstName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="lastName" autocomplete="off" />
          <mat-error *ngIf="biologicalParentsStep.get('mother.lastName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate
          }}</mat-label>


          <input matInput [matDatepicker]="dateOfBirthMother" formControlName="dateOfBirth" autocomplete="off"
            placeholder="{{ 'DATE_FORMAT' | translate }}" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dateOfBirthMother"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthMother></mat-datepicker>
          <mat-error *ngIf="biologicalParentsStep.get('mother.dateOfBirth').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="residence" autocomplete="off" />
          <mat-error *ngIf="biologicalParentsStep.get('mother.residence').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate
          }}</mat-label>
          <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenshipMother"
            (focusout)="checkCitizenship('mother')" />
          <mat-autocomplete #autoCitizenshipMother="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredCitizenshipOptionsMother | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="biologicalParentsStep.get('mother').get('citizenship').hasError('notValid')">
            {{ 'COLLECTIONS.CODEBOOK_ERROR'  | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-group title="{{ 'NEW_ADOPTED_CHILDREN.BIOLOGICAL_PARENTS.FATHER_TITLE' | translate }}" isLast="true">
    <div class="button-add-holder">
      <button mat-flat-button color="primary" type="button" (click)="importSubjectData(false, true)">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>
    <section formGroupName="father">
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="firstName" autocomplete="off" />
          <mat-error *ngIf="biologicalParentsStep.get('father.firstName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="lastName" autocomplete="off" />
          <mat-error *ngIf="biologicalParentsStep.get('father.lastName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="dateOfBirthFather" formControlName="dateOfBirth" 
          [max]="maxDate" autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="dateOfBirthFather"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthFather></mat-datepicker>
          <mat-error *ngIf="biologicalParentsStep.get('father.dateOfBirth').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="residence" autocomplete="off" />
          <mat-error *ngIf="biologicalParentsStep.get('father.residence').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
                    'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate
                  }}</mat-label>
          <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenshipFather"
            (focusout)="checkCitizenship('father')" />
          <mat-autocomplete #autoCitizenshipFather="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredCitizenshipOptionsFather | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="biologicalParentsStep.get('father').get('citizenship').hasError('notValid')">
            {{ 'COLLECTIONS.CODEBOOK_ERROR'  | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_ADOPTED_CHILDREN.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-flat-button matStepperNext color="primary" [disabled]="!biologicalParentsStep.valid"
      class="form-footer__button" (click)="updateBiologicalParentsStep()">
      {{ 'NEW_ADOPTED_CHILDREN.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>