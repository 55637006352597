<mat-card class="step__card" [ngClass]="{
    step__card: true,
    'step__card--initial': stepStatus === 'INITIAL',
    'step__card--started': stepStatus === 'STARTED',
    'step__card--finished': stepStatus === 'FINISHED'
  }">
    <header class="step__header">
        <main>
            <h4 class="step__title">{{ 'VS.ACCOMMODATION.TITLE' | translate }}</h4>
            <label-step-status [status]="stepStatus"></label-step-status>
        </main>
        <aside class="step__action" *ngIf="isEditMode !== true && stepStatus !== 'FINISHED'">
            <span [matTooltip]="!editEnabled ? acceptCaseMissingPermitionsTooltip : ''">
                <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}"
                    [matTooltipDisabled]="editEnabled===false||noRegistrationId===false">

                    <button mat-stroked-button color="primary" (click)="handleInitStep()"
                        *ngIf="stepStatus === 'INITIAL'"
                        [disabled]="!editEnabled || waitingForApproval|| noRegistrationId">
                        {{ 'VS.ACCOMMODATION.CREATE' | translate }}
                    </button>
                </span>
            </span>
            <button mat-stroked-button color="primary" (click)="toggleEdit()" *ngIf="stepStatus === 'STARTED'"
                [disabled]="waitingForApproval" class="step__action--edit">
                {{ 'VS.EDIT' | translate }}
            </button>
        </aside>
    </header>

    <app-forms-s-o *ngIf="isEditMode === true" (handleSaveChanges)="handleSaveChanges($event)"
        [isCaseClosed]="isCaseClosed" [caseId]="caseId" [caseData]="caseData" [accommodation]="accommodation"
        [subjectId]="subjectId">
    </app-forms-s-o>

</mat-card>