<form [formGroup]="terminationOfGuardianship" class="form">
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'PROTEGES.TERMINATION.DECISION_MAKER_NAME_DEPRIVATION' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="decisionName" autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'PROTEGES.TERMINATION.DECISION_NUMBER_GUARDIANSHIP_TERMINATION' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="decisionNumber" autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'PROTEGES.TERMINATION.DECISION_DATE_GUARDIANSHIP_TERMINATION' | translate
      }}</mat-label>
      <input matInput [matDatepicker]="decisionDate" [max]="currentDate" formControlName="decisionDate"
        autocomplete="off" />
      <mat-datepicker-toggle matSuffix [for]="decisionDate"></mat-datepicker-toggle>
      <mat-datepicker #decisionDate></mat-datepicker>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.REASON_FOR_GUARDIANSHIP_TERMINATION' | translate
      }}</mat-label>
      <mat-select formControlName="terminationReasonCodebook" [compareWith]="compareObjects">
        <mat-option *ngFor="let termination of terminationOptions" [value]="termination">
          {{ termination?.title || '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </field-holder>
  <form-footer>
      <button
      mat-stroked-button
      color="primary"
      class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()"
      type="button"
    >
      {{ 'PROTEGES.TERMINATION.CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="modal-footer__button modal-footer__button--primary"
      type="submit"
      [disabled]="!terminationOfGuardianship.valid"
      (click)="terminateGuardianship()">
      {{ 'PROTEGES.TERMINATION.SAVE' | translate }}
    </button>
  </form-footer>
</form>