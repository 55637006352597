/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { CodebookService } from 'src/services/codebook.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
export interface ManageCipherData {
  type: 'edit' | 'new';
  kind: 'citinzenship' | 'nationality' | 'legalProcedure' | 'professionalProcedure' | 'services' | 'rightsCodebook' | 'bank';
  data: {
    id: number;
    title: string;
    format: number;
    active: boolean;
    name: string;
  };
  cipherBankData: any[]
}

export const formats = [
  { id: 1, description: '1 - датум од' },
  { id: 2, description: '2 - датум од, датум до' },
  { id: 3, description: '3 - датум од, датум до, пружалац услуге' },
  { id: 4, description: '4 - датум од, датум до, износ' },
  { id: 5, description: '5 - датум од, датум до, износ (оп)' },
  { id: 6, description: '6 - датум од, износ' },
  { id: 7, description: '7 - датум од, пружалац услуге' },
  { id: 8, description: '8 - датум' }
];

@Component({
   selector: 'manage-cipher',
   templateUrl: './manage-cipher.component.html',
   styleUrls: ['./manage-cipher.component.scss'],
})
export class ManageCipherComponent implements OnInit {
   manageCipherForm: FormGroup;
   isEdit: boolean;
   formatCodebook: any;
   isVisibleFormat = false;
   labelTitle: string;
   labelTitleEdit: string;
   labelValue: string;
   cipherLabelValue: string;
   labelFormat: string;
   forDelete = false;
   inUse = true;
   inForm = true;
   valueExists: boolean = false;
   constructor(
      private translate: TranslateService,
      private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<ManageCipherComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: ManageCipherData,
      private codebookService: CodebookService,
      private toastr: ToastrImplService,
      public dialog: MatDialog
   ) {
      this.formatCodebook = formats;

      this.getLabelTitle();
   }

   ngOnInit(): void {
      this.isEdit = this.data.type === 'edit';
      this.manageCipherForm = this.formBuilder.group({
         value: [this.isEdit ? (this.data.kind === 'bank' ? this.data.data.name : this.data.data.title) : '', []],
         cipherValue: [this.isEdit ? this.data.data.id : '', [Validators.required, Validators.pattern('[0-9]+')]],
         format: [this.isEdit ? this.data.data.format : '', []],
         active: [this.isEdit ? this.data.data.active : true, []],
      });

      if (
         this.data.kind === 'legalProcedure' ||
         this.data.kind === 'professionalProcedure' ||
         this.data.kind === 'services' ||
         this.data.kind === 'rightsCodebook'
      ) {
         this.isVisibleFormat = true;
         if (this.isEdit) {
            switch (this.data.kind) {
               case 'rightsCodebook': {
                  this.codebookService.findRightsById(this.data.data.id).subscribe(result => {
                     this.forDelete = result.forDelete;
                     this.inUse = result.inUse;
                     this.inForm = result.inForm;
                     this.inFormInfoMessage('GLOBAL_ADMIN.CIPHERS.MANAGE.RIGHT_IN_FORM', this.inForm, this.inUse);
                  });
                  break;
               }
               case 'services': {
                  this.codebookService.findServicesById(this.data.data.id).subscribe(result => {
                     this.forDelete = result.forDelete;
                     this.inUse = result.inUse;
                     this.inForm = result.inForm;
                     this.inFormInfoMessage('GLOBAL_ADMIN.CIPHERS.MANAGE.SERVICE_IN_FORM', this.inForm, this.inUse);
                  });
                  break;
               }
               case 'legalProcedure': {
                  this.codebookService.findLegalProcedureById(this.data.data.id).subscribe(result => {
                     this.forDelete = result.forDelete;
                     this.inUse = result.inUse;
                     this.inForm = result.inForm;
                     this.inFormInfoMessage('GLOBAL_ADMIN.CIPHERS.MANAGE.LEGAL_PROCEDURE_IN_FORM', this.inForm, this.inUse);
                  });
                  break;
               }
               case 'professionalProcedure': {
                  this.codebookService.findProfessionalProcedureById(this.data.data.id).subscribe(result => {
                     this.forDelete = result.forDelete;
                     this.inUse = result.inUse;
                     this.inForm = result.inForm;
                     this.inFormInfoMessage('GLOBAL_ADMIN.CIPHERS.MANAGE.PROFESSIONAL_PROCEDURE_IN_FORM', this.inForm, this.inUse);
                  });
                  break;
               }
               default: {
                  break;
               }
            }
         }
      }
   }

   closeDialog() {
      this.dialogRef.close({ event: 'cancel' });
   }

   handleUpdate() {
      const event = this.data.type;
      const value = this.manageCipherForm.value.value;
      const cipherValue = this.manageCipherForm.value.cipherValue;
      const format = this.manageCipherForm.value.format;
      const active = this.manageCipherForm.value.active;
      if (event === 'edit') {
         const id = this.data.data.id;

         this.dialogRef.close({ event, kind: this.data.kind, id, value, format, active, cipherValue });
      }

      if (event === 'new') {
         this.dialogRef.close({ event, kind: this.data.kind, value, format, cipherValue });
      }
   }

   handleDelete() {
      const id = this.data.data.id;
      let object = {
         message: '',
         title: '',
      };

      this.translate.get('WARNING_DIALOG.CIPHER_DELETE.MESSAGE').subscribe((res: string) => {
         object.message = res + this.data.data.title + '?';
      });
      this.translate.get('WARNING_DIALOG.CIPHER_DELETE.TITLE').subscribe((res: string) => {
         object.title = res;
      });
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.MINI,
         data: object,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event != ModalEventEnum.CANCEL) {
            this.dialogRef.close({
               event: 'delete',
               kind: this.data.kind,
               id,
            });
         }
      });
   }

   getLabelTitle() {
      if (this.data.kind === 'legalProcedure') {
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_NEW_LEGAL_PROCEDURE').subscribe((res: string) => {
            this.labelTitle = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_EDIT_LEGAL_PROCEDURE').subscribe((res: string) => {
            this.labelTitleEdit = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.VALUE_LEGAL_PROCEDURE').subscribe((res: string) => {
            this.labelValue = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.FORMAT_LEGAL_PROCEDURE').subscribe((res: string) => {
            this.labelFormat = res;
         });
      } else if (this.data.kind === 'professionalProcedure') {
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_NEW_PROFESIONAL_PROCEDURE').subscribe((res: string) => {
            this.labelTitle = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_EDIT_PROFESIONAL_PROCEDURE').subscribe((res: string) => {
            this.labelTitleEdit = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.VALUE_PROFESIONAL_PROCEDURE').subscribe((res: string) => {
            this.labelValue = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.FORMAT_PROFESIONAL_PROCEDURE').subscribe((res: string) => {
            this.labelFormat = res;
         });
      } else if (this.data.kind === 'services') {
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_NEW_SERVICES').subscribe((res: string) => {
            this.labelTitle = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_EDIT_SERVICES').subscribe((res: string) => {
            this.labelTitleEdit = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.VALUE_SERVICES').subscribe((res: string) => {
            this.labelValue = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.FORMAT_SERVICES').subscribe((res: string) => {
            this.labelFormat = res;
         });
      } else if (this.data.kind === 'rightsCodebook') {
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_NEW_RIGHTS_CODEBOOK').subscribe((res: string) => {
            this.labelTitle = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_EDIT_RIGHTS_CODEBOOK').subscribe((res: string) => {
            this.labelTitleEdit = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.VALUE_RIGHTS_CODEBOOK').subscribe((res: string) => {
            this.labelValue = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.FORMAT_RIGHTS_CODEBOOK').subscribe((res: string) => {
            this.labelFormat = res;
         });
      } else {
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_NEW').subscribe((res: string) => {
            this.labelTitle = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.TITLE_EDIT').subscribe((res: string) => {
            this.labelTitleEdit = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.VALUE').subscribe((res: string) => {
            this.labelValue = res;
         });
         this.translate.get('GLOBAL_ADMIN.CIPHERS.MANAGE.BANK_CIPHER').subscribe((res: string) => {
            this.cipherLabelValue = res;
         });
      }
   }

   inFormInfoMessage(msg, inForm, inUse) {
      if (inForm && !inUse) {
         this.toastr.info(msg);
      }
   }

   checkIfCipherValueExists(value: string): boolean {
      this.valueExists = this.data.cipherBankData.some(item => item.id === parseInt(value, 10));
      if (this.valueExists) {
         this.manageCipherForm.controls.cipherValue.setErrors({ alreadyExist: true });
      } else {
         this.manageCipherForm.controls.cipherValue.setErrors(null);
      }
      return this.valueExists;
   }
}
