<container kind="midi">
   <case-page-header (newCaseStatus)="updateStatus($event)" *ngIf="caseData" [caseData]="caseData" [caseId]="caseId"
      [subjectId]="subjectId" [teamMember]="teamMember" [caseName]="caseName" [closingCaseData]="closingCaseData"
      [waitingForApproval]="waitingForApproval" [approved]="approved" (handleCloseCaseData)="handleCloseCase($event)"
      (handleNewTeamRequest)="handleNewTeamRequest()" [hasTeamRequest]="hasTeamRequest"
      (handleComplainAccepted)="acceptedComplain()" (saveEvent)="saveCurrentData()"
      (newProblem)="problemAdded($event)">
   </case-page-header>
   <page-intro-header title="{{title}}"></page-intro-header>
</container>
<container kind="midi" *ngIf="!isLawyer  && caseData?.assignedUserId === loggedIn.id">


   <initial-assessment-plan *ngIf="caseData" (handleSubmitData)="handleStepSubmit($event)" [isCaseClosed]="isCaseClosed"
      [editEnabled]="!caseData?.forms23?.approved  && caseData?.caseStatus === 'ACCEPTED'" [data]="step01data"
      [waitingForApproval]="waitingForApproval" [noRegistrationId]="noRegistrationId"
      [initialAssesmentPlans]="initialAssesmentPlanDocuments">
   </initial-assessment-plan>

   <initial-assessment *ngIf="caseData" (handleSubmitData)="handleStepSubmit($event)" [isCaseClosed]="isCaseClosed"
      [editEnabled]="caseData?.caseStatus === 'ACCEPTED'" [data]="caseData?.forms23"
      [formNumber]="caseData?.socialCaseClassificationCodebook.caseKindViewCodebook.formType" [caseId]="caseId"
      [remainingTime]="remainingTime" [documentSigned]="documentSigned" [waitingForApproval]="waitingForApproval"
      [subject]="subject" [noRegistrationId]="noRegistrationId" (handleNewTeamRequest)="handleNewTeamRequest()"
      [hasTeamRequest]="hasTeamRequest">
   </initial-assessment>
   <targeted-assessment-plan *ngIf="caseData" (handleSubmitData)="handleStepSubmit($event)"
      [isCaseClosed]="isCaseClosed" [editEnabled]="!caseData?.forms23?.approved  && caseData?.caseStatus === 'ACCEPTED'"
      [data]="step01data" [waitingForApproval]="waitingForApproval" [noRegistrationId]="noRegistrationId"
      [targetedAssesmentPlans]="targetedAssesmentPlanDocuments">
   </targeted-assessment-plan>

   <targeted-assessment (handleSubmitData)="handleStepSubmit($event)" [isCaseClosed]="isCaseClosed"
      [editEnabled]=" step04?.stepStatus !== 'FINISHED' && caseData?.caseStatus === 'ACCEPTED'" [caseId]="caseId"
      [waitingForApproval]="waitingForApproval" [subjectId]="subjectId" [noRegistrationId]="noRegistrationId"
      [targetedAssesments]="targetedAssesmentDocuments" [data]="step04">
   </targeted-assessment>

   <measures-and-services (handleSubmitData)="handleStepSubmit($event)" [isCaseClosed]="isCaseClosed" [caseId]="caseId"
      [waitingForApproval]="waitingForApproval" [subjectId]="subjectId" [editEnabled]="
      step05?.stepStatus !== 'FINISHED' && caseData?.caseStatus === 'ACCEPTED'" [noRegistrationId]="noRegistrationId"
      [data]="caseData?.form5">
   </measures-and-services>

   <evaluation-and-reassessment (handleSubmitData)="handleStepSubmit($event)" [isCaseClosed]="isCaseClosed"
      [waitingForApproval]="waitingForApproval" [evaluationsOfPlan]="caseData.evaluationOfPlan"
      [editEnabled]="step06?.stepStatus !== 'FINISHED' && caseData?.caseStatus === 'ACCEPTED'"
      [noRegistrationId]="noRegistrationId" [data]="step06">
   </evaluation-and-reassessment>

   <accommodation
      *ngIf="caseData?.socialCaseClassificationCodebook.oldKindMark==='DS'||caseData?.socialCaseClassificationCodebook.oldKindMark==='PS'"
      [waitingForApproval]="waitingForApproval" (handleSubmitData)="handleStepSubmit($event)"
      [noRegistrationId]="noRegistrationId" [caseId]="caseId" [subjectId]="subjectId" [isCaseClosed]="isCaseClosed"
      (handleSaveData)="updateAccommodation($event)"
      [editEnabled]="step07?.stepStatus !== 'FINISHED' && caseData?.caseStatus === 'ACCEPTED'" [data]="[]"
      [caseData]="caseData" [accommodation]="accomodation">
   </accommodation>

   <violence *ngIf="caseData?.socialCaseClassificationCodebook.caseKindViewCodebook.type === 2" [caseId]="caseId"
      [noRegistrationId]="noRegistrationId" [waitingForApproval]="waitingForApproval"
      [editEnabled]="caseData?.caseStatus === 'ACCEPTED'" [data]="[]" [caseData]="caseData">
   </violence>

</container>


<container kind="midi">
   <mat-card *ngIf="teamMember">
      <team-member [editable]="caseData?.caseStatus === 'ACCEPTED'" [caseId]="caseId" [subjectId]="subjectId">
      </team-member>
   </mat-card>
</container>
<container kind="midi" *ngIf="isLawyer  && caseData?.assignedUserId === loggedIn.id && !(caseData?.socialCaseClassificationCodebook.oldKindMark==='DS'||caseData?.socialCaseClassificationCodebook.oldKindMark==='PS')">
   <app-vs-lawyer [caseData]="caseData" [caseId]="caseId" [title]="title"
      [subjectId]="subjectId" [caseName]="caseName" [hasProblem]="hasProblem" [noRegistrationId]="noRegistrationId">
   </app-vs-lawyer>
</container>
<container kind="midi"
   *ngIf="isLawyer && caseData?.assignedUserId === loggedIn.id && (caseData?.socialCaseClassificationCodebook.oldKindMark==='DS'||caseData?.socialCaseClassificationCodebook.oldKindMark==='PS')">
   <app-accommodation-lawyer [caseId]="caseId" [subjectId]="subjectId" [caseData]="caseData"
      [accommodation]="accomodation" [complain]="caseData?.newComplain" [caseName]="caseName" [isDenial]="isDenial" [hasProblem]="hasProblem" >
   </app-accommodation-lawyer>
</container>
