/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'document-item-supervision',
  templateUrl: './document-item-supervision.component.html',
  styleUrls: ['./document-item-supervision.component.scss']
})
export class DocumentItemSupervisionComponent implements OnInit {
  signatureStatus: string;
  @Input() title: string;
  @Input() status: 'NOT_SIGNED' | 'SIGNED' | 'REJECTED' | 'NONE' | '' = '';

  constructor() { }

  ngOnInit(): void {
    this.signatureStatus = this.status === 'NOT_SIGNED' ? '' :
    this.status === 'SIGNED' ? 'Потписан' : this.status === 'NONE' ? '' : 'Одбачен';

  }
}
