<mat-dialog-content>
   <div class="container">
      <form flex [formGroup]="sendingDocumentsForm">
         <h2>{{ 'SENDING-DOCUMENTS.TITLE' | translate }}</h2>
         <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.RECIPIENT_TYPE' | translate }}</mat-label>
               <mat-select formControlName="recipientType" (selectionChange)="onChangeReceiverType($event)" required>
                  <mat-option *ngFor="let type of shippingMethod" [value]="type">
                     {{ type.title }}
                  </mat-option>
               </mat-select>
               <mat-error *ngIf="sendingDocumentsForm.get('recipientType').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.RECIPIENT_TYPE_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.SENDING_METHOD' | translate }}</mat-label>
               <mat-select formControlName="sendingMethod" (selectionChange)="onChangeSendingMethod($event)" required>
                  <mat-option *ngFor="let method of shippingChannel" [value]="method">
                     {{ method.title }}
                  </mat-option>
               </mat-select>
               <mat-error *ngIf="sendingDocumentsForm.get('sendingMethod').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.SENDING_METHOD_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
         </field-holder>
         <div class="button-add-holder" *ngIf="choosedShippingChannel != '' && choosedShippingMethod == 'INDIVIDUAL'">
            <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
               <mat-icon>person_add</mat-icon>
               {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
            </button>
         </div>
         <br>
         <div class="button-add-holder" *ngIf="choosedShippingChannel != '' && choosedShippingMethod == 'LEGAL'">
            <button mat-flat-button color="primary" type="button" (click)="importLegalSubject()">
               <mat-icon>person_add</mat-icon>
               {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
            </button>
         </div>
         <br>
         <field-holder *ngIf="sendingDocumentsForm.get('name') !== null">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.NAME' | translate }}</mat-label>
               <input matInput type="text" readonly required formControlName="name" autocomplete="off" />
               <mat-error *ngIf="sendingDocumentsForm.get('name').hasError('pattern')">
                  {{ 'SENDING-DOCUMENTS.NAME_PATTERN_ERROR' | translate }}
               </mat-error>
               <mat-error *ngIf="sendingDocumentsForm.get('name').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.NAME_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder *ngIf="sendingDocumentsForm.get('pib') !== null">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.PIB' | translate }}</mat-label>
               <input matInput type="text" readonly required formControlName="pib" autocomplete="off" />
               <mat-error *ngIf="sendingDocumentsForm.get('pib').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.NAME_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder *ngIf="sendingDocumentsForm.get('firstName') !== null" kind="2-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.FIRST_NAME' | translate }}</mat-label>
               <input matInput type="text" readonly required formControlName="firstName" autocomplete="off" />
               <mat-error *ngIf="sendingDocumentsForm.get('firstName').hasError('pattern')">
                  {{ 'SENDING-DOCUMENTS.FIRST_NAME_PATTERN_ERROR' | translate }}
               </mat-error>
               <mat-error *ngIf="sendingDocumentsForm.get('firstName').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.FIRST_NAME_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.LAST_NAME' | translate }}</mat-label>
               <input matInput type="text" readonly required formControlName="lastName" autocomplete="off" />

               <mat-error *ngIf="sendingDocumentsForm.get('lastName').hasError('pattern')">
                  {{ 'SENDING-DOCUMENTS.LAST_NAME_PATTERN_ERROR' | translate }}
               </mat-error>

               <mat-error *ngIf="sendingDocumentsForm.get('lastName').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.LAST_NAME_ERROR' | translate }}
               </mat-error>

            </mat-form-field>
         </field-holder>
         <field-holder *ngIf="sendingDocumentsForm.get('email') !== null">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.EMAIL' | translate }}</mat-label>
               <input matInput type="email" readonly required formControlName="email" autocomplete="off" />

               <mat-error *ngIf="sendingDocumentsForm.get('email').hasError('email')">
                  {{ 'SENDING-DOCUMENTS.EMAIL_PATTERN_ERROR' | translate }}
               </mat-error>
               <mat-error *ngIf="sendingDocumentsForm.get('email').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.EMAIL_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder *ngIf="sendingDocumentsForm.get('street') !== null" kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.PTT' | translate }}</mat-label>
               <input matInput type="text" readonly required formControlName="ptt" autocomplete="off" />
               <mat-error *ngIf="sendingDocumentsForm.get('ptt').hasError('pattern')">
                  {{ 'SENDING-DOCUMENTS.PTT_PATTERN_ERROR' | translate }}
               </mat-error>
               <mat-error *ngIf="sendingDocumentsForm.get('ptt').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.PTT_ERROR' | translate }}
               </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.CITY' | translate }}</mat-label>
               <input matInput type="text" readonly required formControlName="city" autocomplete="off" />

               <mat-error *ngIf="sendingDocumentsForm.get('city').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.CITY_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.STREET' | translate }}</mat-label>
               <input matInput type="text" readonly required formControlName="street" autocomplete="off" />

               <mat-error *ngIf="sendingDocumentsForm.get('street').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.STREET_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
         </field-holder>
         <field-holder *ngIf="sendingDocumentsForm.get('subnumber') !== null" kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.SETTLEMENT' | translate }}</mat-label>
               <input matInput type="text" readonly formControlName="settlement" autocomplete="off" />

            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.NUMBER' | translate }}</mat-label>
               <input matInput type="text" readonly required formControlName="number" autocomplete="off" />

               <mat-error *ngIf="sendingDocumentsForm.get('number').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.NUMBER_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'SENDING-DOCUMENTS.SUBNUMBER' | translate }}</mat-label>
               <input matInput type="text" readonly formControlName="subnumber" autocomplete="off" />

               <mat-error *ngIf="sendingDocumentsForm.get('subnumber').hasError('required')">
                  {{ 'SENDING-DOCUMENTS.SUBNUMBER_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
         </field-holder>
         <button mat-flat-button color="primary" type="button" (click)="addReciverToList()"
            class="form-footer__button form-footer__button--primary"
            [disabled]="!(isSubjectChosen && this.sendingDocumentsForm.valid)">
            {{ 'SENDING-DOCUMENTS.ADD_RECIEVER' | translate }}
         </button>
         <div class="table-responsive-wrapper" *ngIf="receivers.length > 0">
            <br />
            <table mat-table id="myTable" #myTable [dataSource]="dataSource">
               <ng-container matColumnDef="shippingChannel">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SENDING-DOCUMENTS.RECIPIENT_TYPE' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                     {{ element?.shippingMethod.title }}
                  </td>
               </ng-container>
               <ng-container matColumnDef="shippingMethod">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SENDING-DOCUMENTS.SENDING_METHOD' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                     {{ element?.shippingChannel.title }}

                  </td>
               </ng-container>
               <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SENDING-DOCUMENTS.NAME' | translate }}</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                     <span *ngIf="element?.shippingMethod?.id!==1">
                        {{element.name}}
                     </span>
                     <span *ngIf="element?.shippingMethod?.id===1">
                        {{element.firstName}} {{element.lastName}}
                     </span>
                  </td>
               </ng-container>
               <ng-container matColumnDef="pib">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SENDING-DOCUMENTS.PIB' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                     <span *ngIf="element?.shippingMethod?.id!==1">
                        {{ element?.pib }}
                     </span>
                  </td>
               </ng-container>
               <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SENDING-DOCUMENTS.EMAIL' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                     <span *ngIf="element?.shippingChannel?.id!==1">
                        {{element.email}}</span>
                  </td>
               </ng-container>
               <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SENDING-DOCUMENTS.ADDRESS' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                     <span *ngIf="element.shippingChannel.id===1">
                        {{ element?.street }}
                        {{ element?.number }}
                        <ng-container *ngIf="element?.subnumber !== '' && element?.subnumber !== null">
                           / {{ element?.subnumber }}
                        </ng-container>
                        <br />
                        {{ element?.ptt }}
                        {{ element?.city }}
                        <ng-container *ngIf="element?.municipality !== null || element?.settlement !== null">
                           (
                        </ng-container>
                        {{ element?.municipality }}
                        <ng-container *ngIf="element?.settlement !== null">,</ng-container>
                        {{ element?.settlement }}
                        <ng-container *ngIf="element?.municipality !== null ||
            element?.settlement !== null">
                           )
                        </ng-container>
                     </span>
                  </td>
               </ng-container>
               <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index" class="table__cell table__cell--align-right">
                     <button *ngIf="(i!==0 && receivers.length!==1)||receivers.length===1" mat-icon-button
                        class="button-square table__row-button" color="primary" type="button"
                        matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
                        #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                        <mat-icon class="button-square__icon">more_horiz</mat-icon>
                     </button>
                     <mat-menu #menu="matMenu" class="filter-menu">
                        <button mat-menu-item *ngIf="i!==0" (click)="setPrimaryReceiver(i)" type="button">
                           <mat-icon class="button-square__icon">edit</mat-icon>
                           {{ 'SENDING-DOCUMENTS.SET_PRIMARY' | translate }}
                        </button>
                        <button mat-menu-item *ngIf="i!==0||receivers.length===1" (click)="removeReceiver(i)"
                           type="button">
                           <mat-icon class="button-square__icon">delete</mat-icon>
                           {{ 'SENDING-DOCUMENTS.REMOVE_RECIEVER' | translate }}
                        </button>
                     </mat-menu>
                  </td>
               </ng-container>
               <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
               <tr mat-row *matRowDef="let element; let row;let index; columns: displayedColumns" class="subjects__row">
               </tr>
            </table>
         </div>
         <br />
         <form-footer>
            <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
               class="form-footer__button form-footer__button--secondary">
               {{ 'SENDING-DOCUMENTS.CANCEL' | translate }}
            </button>
            <button mat-flat-button color="primary" (click)="sendDocument();sendDissabled=true"
               class="form-footer__button form-footer__button--primary" [disabled]="receivers.length===0||sendDissabled">
               {{ 'SENDING-DOCUMENTS.SUBMIT' | translate }}
            </button>
            <mat-progress-spinner [diameter]="45" *ngIf="isSending" color="primary" mode="indeterminate">
            </mat-progress-spinner>
         </form-footer>
      </form>
      <br />
   </div>
</mat-dialog-content>