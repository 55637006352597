/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { SubjectsService } from 'src/services/subjects.service';
import { CaseService } from 'src/services/case.service';
import { Page } from 'src/app/page';
import { LocalStorageService } from 'src/app/local-storage.service';
import { UserService } from 'src/services/user.service';
import { patchForDecimalNumber } from 'src/app/utils/formatLocalNumber';

@Component({
   selector: 'subject-case',
   templateUrl: './subject-case.component.html',
   styleUrls: ['./subject-case.component.scss'],
})
export class SubjectCaseComponent implements OnInit {
   page: Page<Object> = new Page();
   pageRight: Page<Object> = new Page();
   sourceDataCaseProblems: any = [];
   selectedRow: { id: number };
   subjectJMBG: string;
   dataSource: any = [];
   detailsTitle: string;
   caseName: string;
   caseId: any;
   case: any;
   addButtonDisabled: boolean;
   assignedUser: any = null;
   csrId: any;
   displayedColumns: string[] = ['problemId', 'startDate', 'endDate', 'duration', 'levelOfSeverity', 'description'];
   displayedColumnsRec: string[] = ['problemId', 'startDate', 'endDate', 'reimbursment', 'reimbursmentProvider', 'procedure'];
   loggedIn: any;
   isMainCenterBelgrade = false;
   isUserDrugostepeni = false;
   teamMembersNames = [];
   constructor(
      private route: ActivatedRoute,
      private subjectsService: SubjectsService,
      private caseService: CaseService,
      private paginationService: CustomPaginationService,
      private localStorageService: LocalStorageService,
      private userService: UserService
   ) {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      this.route.params.subscribe(params => {
         this.subjectJMBG = params.subjectId;
         this.csrId = params.csrId;
         this.caseId = params.id;
      });
   }

   ngOnInit(): void {
      this.doFilter(this.caseId);
      this.doFilterRecord(this.caseId);
      this.caseDetails();
      // Tabovi - kad se kreira tab inicijalizuje se tako sto se izfiltrira vrednost poslednjeg taba.
   }

   getNextPage() {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter(this.caseId);
   }

   getPreviousPage() {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter(this.caseId);
   }
   public getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter(this.caseId);
   }

   public getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter(this.caseId);
   }

   getPageInNewSize(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter(this.caseId);
   }

   doFilter(caseId: any) {
      let x = null;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         x = this.subjectsService.getFilteredProblemByCaseIdPage(this.page.pageable, caseId);
      } else {
         x = this.subjectsService.getFilteredProblemByCaseIdPage(this.page.pageable, caseId);
      }
      x.subscribe((page: any) => {
         this.page = page;
         this.page.pageable.pagePerShow = page.number + 1;
      });
   }

   getNextPageRecord() {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilterRecord(this.caseId);
   }

   getPreviousPageRecord() {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilterRecord(this.caseId);
   }
   public getFirstPageRecord(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilterRecord(this.caseId);
   }

   public getLastPageRecord(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilterRecord(this.caseId);
   }

   getPageInNewSizeRecord(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilterRecord(this.caseId);
   }

   doFilterRecord(caseId: any) {
      let x = null;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         x = this.subjectsService.getFilteredRightByCaseIdPage(this.page.pageable, caseId);
      } else {
         x = this.subjectsService.getFilteredRightByCaseIdPage(this.page.pageable, caseId);
      }
      x.subscribe((page: any) => {
         this.pageRight = page;
         this.pageRight.pageable.pagePerShow = page.number + 1;
      });
   }

   caseDetails() {
      this.caseService.getSocialCaseDetails(this.caseId).subscribe(res => {
         if (res.caseStatus === 'ACCEPTED' && (res.assignedUserId === this.loggedIn.id || res.teamMembers.includes(this.loggedIn.id))) {
            this.addButtonDisabled = false;
         } else {
            this.addButtonDisabled = true;
         }
         this.caseName = ' - ' + res.socialCaseClassificationCodebook.title + ' ' + res.caseNumber;
         this.case = res;
         if (this.case.caseStatus === 'ACCEPTED' || this.case.caseStatus === 'ASSIGNED') {
            this.userService.findUserInfo(this.case.assignedUserId).subscribe(userRes => {
               if (userRes != null) {
                  this.assignedUser = userRes.firstName + ' ' + userRes.lastName;
               }
            });
         } else if (this.case.caseStatus === 'CLOSED') {
            this.assignedUser = this.case.closeCaseSignature;
         }
      });
      this.caseService.getTeamMembersForCase(this.caseId).subscribe((res: any) => {
         this.teamMembersNames = res;
      });
   }

   translateStatus(status: any) {
      if (status === 'NOT_ASSIGNED') {
         status = 'Није додељен';
      } else if (status === 'ASSIGNED') {
         status = 'Додељен';
      } else if (status === 'ACCEPTED') {
         status = 'Прихваћен';
      } else if (status === 'CLOSED') {
         status = 'Затворен';
      } else if (status === 'FINISHED_PROCESSING') {
         status = 'Завршена обрада';
      } else if (status === 'COMPLAIN') {
         status = 'Ожалбено';
      } else if (status === 'SECOND_DEGREE') {
         status = 'Код другостепеног на обраду';
      }
      return status;
   }
   formatNumber(num: any) {
    return patchForDecimalNumber(num);
 }
}
