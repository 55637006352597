<form class="form" [formGroup]="newRecord" (ngSubmit)="addNewRecord(newRecord)">
  <form-group title="{{ 'NEW_DEPENDENT.NEW_RECORD' | translate }}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.COURT_NAME' | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="courtName"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="newRecord.get('courtName').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_DEPENDENT.FORM.COURT_NUMBER' | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="courtNumber"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="newRecord.get('courtNumber').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.COURT_DATE' | translate
          }}</mat-label>
        <input
          matInput
          [matDatepicker]="courtDate"
          [max]="currentDate"
          formControlName="courtDate"
          required
          autocomplete="off"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="courtDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #courtDate></mat-datepicker>
        <mat-error *ngIf="newRecord.get('courtDate').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.COURT_VALIDITY_DATE' | translate
          }}</mat-label>
        <input
          matInput
          [matDatepicker]="courtValidityDate"
          formControlName="courtValidityDate"
          required
          autocomplete="off"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="courtValidityDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #courtValidityDate></mat-datepicker>
        <mat-error *ngIf="newRecord.get('courtValidityDate').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>

    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.DATE_OF_VERDICT_DELIVERY' | translate
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="dateOfVerdictDelivery"
          [max]="currentDate"
          formControlName="dateOfVerdictDelivery"
          required
          autocomplete="off"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dateOfVerdictDelivery"
        ></mat-datepicker-toggle>
        <mat-datepicker #dateOfVerdictDelivery></mat-datepicker>
        <mat-error *ngIf="newRecord.get('dateOfVerdictDelivery').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_DEPENDENT.FORM.DATE_OF_COMMENCMENT' | translate
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="dateOfCommencment"
          formControlName="dateOfCommencment"
          required
          autocomplete="off"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dateOfCommencment"
        ></mat-datepicker-toggle>
        <mat-datepicker #dateOfCommencment></mat-datepicker>
        <mat-error *ngIf="newRecord.get('dateOfCommencment').hasError('required')">
          {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button
      mat-stroked-button
      color="primary"
      class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()"
      type="button"
    >
      {{ 'NEW_DEPENDENT.CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="modal-footer__button modal-footer__button--primary"
      type="submit"
      [disabled]="!newRecord.valid"
    >
      {{ 'NEW_DEPENDENT.SAVE' | translate }}
    </button>
  </form-footer>
</form>
