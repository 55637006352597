<div *ngIf="page" class="paginator">
  <mat-form-field appearance="outline" class="paginator__select">
    <mat-label>{{ 'PAGE.ELEMENTSPER' | translate }}</mat-label>
    <mat-select (selectionChange)="updatePageSize()" [(value)]="pageSizeStr">
      <mat-option [value]="10">10</mat-option>
      <mat-option [value]="20">20</mat-option>
      <mat-option [value]="30">30</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="paginator__navigation">
    <span>
      {{ page.number * pageSizeStr + 1 }} – {{  page.number * pageSizeStr + page.numberOfElements }}
      {{ 'PAGE.FROM' | translate }} {{ page.totalElements }}
    </span>
    <button
      mat-icon-button
      aria-label="{ 'PAGE.TOOLTIP_START' | translate }"
      matTooltip="{{ 'PAGE.TOOLTIP_START' | translate }}"
      (click)="firstPage()"
      [disabled]="page.first"
    >
      <mat-icon>first_page</mat-icon>
    </button>
    <button
      mat-icon-button
      aria-label="{ 'PAGE.TOOLTIP_PREVIOUS' | translate }"
      matTooltip="{{ 'PAGE.TOOLTIP_PREVIOUS' | translate }}"
      (click)="previousPage()"
      [disabled]="page.first"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      button
      mat-icon-button
      aria-label="{ 'PAGE.TOOLTIP_NEXT' | translate }"
      matTooltip="{{ 'PAGE.TOOLTIP_NEXT' | translate }}"
      (click)="nextPage()"
      [disabled]="page.last"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
     <button
      button
      mat-icon-button
      aria-label="{ 'PAGE.TOOLTIP_END' | translate }"
      matTooltip="{{ 'PAGE.TOOLTIP_END' | translate }}"
      (click)="lastPage()"
      [disabled]="page.last"
    >
      <mat-icon>last_page</mat-icon>
    </button>
  </div>
</div>
