<li class="d-list-item" [ngClass]="{
  'd-list-item': true,
  'd-list-item--mini': fontSize === 'mini'
}">

<div class="content">
      <header class="title-wrapper">
      <em class="d-list-item__key">{{ key }}</em>
        <span class="d-list-item__value" [ngStyle]="{ 'margin-left': valueOffset }">
            <div>
                <ng-content></ng-content>
            </div>
        </span>
        <span class="meta">{{ meta }}</span>
      </header>
    </div>

</li>
