/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';

@Component({
   selector: 'app-assigne-dossier',
   templateUrl: './assigne-dossier.component.html',
   styleUrls: ['./assigne-dossier.component.scss'],
})
export class AssigneDossierComponent implements OnInit {
   assignDossierForm: FormGroup;
   localData: any;
   workerOptions: [];
   constructor(
      private formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<AssigneDossierComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private subjectService: SubjectsService,
      private toastr: ToastrImplService,
      private userService: UserService
   ) {
      this.localData = { ...data };
      this.getWorkers();
   }

   ngOnInit(): void {
      this.assignDossierForm = this.formBuilder.group({
         assigne: ['', [Validators.required]], // radnik koji ce zaduziti dosije
      });
   }
   closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
   }

   assignDossier() {
      this.subjectService.addDossierOwner(this.localData.subjectIdentity.subjectId, this.assignDossierForm.value.assigne).subscribe(
         result => {
            this.dialogRef.close({ event: 'Create' });
         },
         error => {
            this.toastr.error('SNACKBAR.ERROR_BORROWING_DOSSIER');
         }
      );
   }

   getWorkers() {
      this.userService.getUsersInCenter().subscribe(res => {
         this.workerOptions = res;
      });
   }
}
