/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ProtegePersonService } from 'src/services/protege-person.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { EditProtegeDetailComponent } from './edit-protege-detail/edit-protege-detail.component';
import { EditTerminationOfGuardianshipComponent } from './edit-termination-of-guardianship/edit-termination-of-guardianship.component';

/**
 *
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 *
 */
@Component({
  selector: 'app-protege-detail',
  templateUrl: './protege-detail.component.html',
  styleUrls: ['./protege-detail.component.scss']
})
export class ProtegeDetailComponent implements OnInit {
  year: any;
  protegeId: any;
  protege: any;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private protegePersonService: ProtegePersonService,
    private toastr: ToastrImplService) {
    this.route.params.subscribe((params) => {
      this.protegeId = params.id;
      this.year = params.year;
    });

    this.getProtegePerson();
  }

  ngOnInit(): void {
  }

  editGuardianshipDetails() {
    this.handleEditProtege(false, true);
  }
  editProtegeProperty() { this.handleEditProtege(true, false); }


  handleEditProtege(editProperty: any, editGuardianship: any) {
    const dialogRef = this.dialog.open(EditProtegeDetailComponent, {
      width: ModalSizeEnum.DEFAULT,
      panelClass:'overlay-panel',
      data: {
        editProperty,
        editGuardianship,
        protege: this.protege,
        year: this.year
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        const { event, data } = result;
        if (event === 'Success') {
          this.toastr.success('SNACKBAR.INPUT_SAVED');
          this.getProtegePerson();
        } else if (event === 'Unsuccessful') {
          this.toastr.error('SNACKBAR.INPUT_SAVE_ERROR');
        }
      }

    });
  }

  getProtegePerson() {
    this.protegePersonService.findByIdAndCsrId(this.protegeId, this.year).subscribe((res) => {
      this.protege = res;
      this.protege.dateOfBirth = this.protege.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');

    });
  }

  editTerminationOfGuardianship() {
    const dialogRefa = this.dialog.open(EditTerminationOfGuardianshipComponent, {
        width: ModalSizeEnum.DEFAULT,
        panelClass:'overlay-panel',
        data: {
            protege: this.protege,
            year: this.year
      }
    });
    dialogRefa.afterClosed().subscribe((result: any) => {
    if (result && result.event === ModalEventEnum.SUCCESS) {
      const year = (this.year !== null && this.year !== undefined) ? this.year : new Date().getFullYear();
      const id = this.protege.id;
      this.protegePersonService.terminateGuardianship(id, {...result.data}).subscribe((result) => {
               if (result != null) {
                  this.toastr.success('SNACKBAR.INPUT_ADDED');
                  this.getProtegePerson();
              }
         });
    }
    });
  }

}
