/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as printJS from 'print-js';
import { Observable } from 'rxjs';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { environment } from './../environments/environment';
import { AppService } from './app.service';
import { ToastrImplService } from './toastr.service';

@Injectable({
   providedIn: 'root',
})
export class AssuranceService {
   constructor(private httpClient: HttpClient, private app: AppService, private toastr: ToastrImplService) {}

   saveAssurance(assurance: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.saveAssurance, assurance, { responseType: 'blob', headers }) as Observable<any>;
   }

   printPreview(assurance: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.httpClient.put(this.app.getUrl() + environment.previewAssuranceUrl, assurance, { responseType: 'blob', headers }).subscribe(
         data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         },
         error => {
            manageError(this.toastr, ErrorType.PREVIEW_DOC);
         }
      );
   }
}
