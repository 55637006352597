<mat-dialog-content>
    <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
        (click)="closeDialog()"><span class="material-icons">close</span>
    </button>
        <div class="container">
        <form flex [formGroup]="assignDossierForm" (ngSubmit)="assignDossier()">
            <h2>{{ 'ASSIGN_CASE.DOSSIER' | translate }}</h2>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'ASSIGN_DOSSIER.WORKER' | translate }}</mat-label>
                    <mat-select formControlName="assigne" required>
                        <mat-option *ngFor="let worker of workerOptions" [value]="worker.id">
                        {{ worker.name }} ({{worker.role}})
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="assignDossierForm.get('assigne').hasError('required')">
                    {{ 'ASSIGN_DOSSIER.WORKER_ERROR' | translate}}
                </mat-error>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" 
                    type="button" class="form-footer__button form-footer__button--secondary">
                    {{ 'GLOBAL.CANCEL' | translate }}
                </button>
                <button mat-flat-button color="primary" type="submit"
                    class="form-footer__button form-footer__button--primary" [disabled]="!assignDossierForm.valid">
                    {{ 'GLOBAL.SAVE' | translate }}
                </button>
            </form-footer>
        </form>
    </div>
</mat-dialog-content>