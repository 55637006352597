<container kind="midi">
    <page-intro-header back="/legal_subjects"
        title="{{ 'LEGAL_SUBJECT_DETAILS.TITLE' | translate }} - {{legalSubject?.name}}">

    </page-intro-header>

    <mat-tab-group (selectedTabChange)="selectTab($event)" animationDuration="0">
        <mat-tab label="{{ 'LEGAL_SUBJECT_DETAILS.TAB_ONE' | translate }}" class="tab-content">
            <mat-card class="card card--edit" *ngIf="isEditMode">
                <legal-subject-form isEditMode="true" (emitUpdatedSubject)="patchLegalSubjectAfterEdit($event)">
                </legal-subject-form>
            </mat-card>
            <mat-card class="card" *ngIf="!isEditMode">
                <card-header title="{{ 'LEGAL_SUBJECT_DETAILS.BASIC.TITLE' | translate }}">
                 <span matTooltip="{{'LEGAL_SUBJECTS.CHANGE_DISABLED' | translate }}" [matTooltipDisabled]="isEditable">
                        <button mat-flat-button color="primary" (click)="editLegalSubject()" [disabled]="!isEditable">
                            {{ 'GLOBAL.EDIT' | translate }}
                        </button>
                 </span>
                </card-header>
                <definition-list>
                    <definition-item *ngIf="legalSubject?.id" key="{{ 'LEGAL_SUBJECT_DETAILS.BASIC.ID' | translate }}">
                        {{ legalSubject.id }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.name"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.BASIC.NAME' | translate }}">
                        {{ legalSubject.name }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.institutionType"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.BASIC.INSTITUTION_TYPE' | translate }}">
                        {{ legalSubject.institutionType.name }}
                    </definition-item>
                </definition-list>
                <card-subgroup-divider></card-subgroup-divider>
                <card-header title="{{ 'LEGAL_SUBJECT_DETAILS.ADDRESS.TITLE' | translate }}"></card-header>
                <definition-list>
                    <definition-item *ngIf="legalSubject?.address"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.ADDRESS.ADDRESS' | translate }}">
                        {{ legalSubject?.address?.street }} {{ legalSubject?.address?.number }}/
                        {{ legalSubject?.address?.subnumber }}
                        <br />
                        {{ legalSubject?.address?.region }} {{ legalSubject?.address?.town }} {{ legalSubject?.address?.ptt }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.phone"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.ADDRESS.PHONE' | translate }}">
                        {{ legalSubject.phone }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.email"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.ADDRESS.EMAIL' | translate }}">
                        {{ legalSubject.email }}
                    </definition-item>
                </definition-list>
                <card-subgroup-divider></card-subgroup-divider>
                <card-header title="{{ 'LEGAL_SUBJECT_DETAILS.OTHER.TITLE' | translate }}">
                </card-header>
                <definition-list>
                    <definition-item *ngIf="legalSubject?.accountNumber"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.OTHER.ACCOUNT_NUMBER' | translate }}">
                        {{ legalSubject.accountNumber }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.jbkbs"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.OTHER.JBKBS' | translate }}">
                        {{ legalSubject.jbkbs }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.pib"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.OTHER.PIB' | translate }}">
                        {{ legalSubject.pib }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.modifiedBy"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.OTHER.MODIFIED_BY' | translate }}">
                        {{ legalSubject.modifiedBy }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.dateOfModification"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.OTHER.DATE_OF_MODIFICATION' | translate }}">
                        {{ legalSubject.dateOfModification }}
                    </definition-item>
                    <definition-item *ngIf="legalSubject?.identificationNumber"
                        key="{{ 'LEGAL_SUBJECT_DETAILS.OTHER.IDENTIFICATION_NUMBER' | translate }}">
                        {{ legalSubject.identificationNumber }}
                    </definition-item>
                </definition-list>
            </mat-card>

        </mat-tab>
        <mat-tab label="{{ 'LEGAL_SUBJECT_DETAILS.TAB_TWO' | translate }}" class="tab-content">
            <legal-subject-history *ngIf="isSubjectHistoryRendered"></legal-subject-history>
        </mat-tab>
    </mat-tab-group>
</container>