/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
   selector: 'app-tasks',
   templateUrl: './tasks.component.html',
   styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
   taskForm: FormGroup;
   minFromDate = new Date();
   minToDate = new Date();
   maxDate: Date = new Date(2999, 11, 31);
   isEdit = false;
   constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<TasksComponent>, private formBuilder: FormBuilder) {}

   ngOnInit(): void {
      if (this.data !== null) {
         this.isEdit = true;
         this.patchForm();
      } else {
         this.taskForm = this.formBuilder.group({
            outcome: ['' /*[Validators.required]*/],
            task: ['' /*[Validators.required]*/],
            responsiblePerson: ['' /* [Validators.required]*/],
            fromDate: ['' /* [Validators.required]*/],
            toDate: ['' /*[Validators.required]*/],
         });
      }
      this.taskForm.get('fromDate').valueChanges.subscribe((fromDate: Date) => {
         this.minToDate = fromDate;
      });
   }

   closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
   }

   addNewTask() {
      this.dialogRef.close({ event: 'Create', data: this.taskForm.value });
   }
   editTask() {
      this.dialogRef.close({ event: 'Edit', data: this.taskForm.value });
   }

   checkDate(control: string, event: any) {
      if (event.value === null) {
         this.taskForm.get(control).reset();
      }
   }
   patchForm() {
      this.taskForm = this.formBuilder.group({
         outcome: this.data.outcome,
         task: this.data.task,
         responsiblePerson: this.data.responsiblePerson,
         fromDate: this.data.fromDate,
         toDate: this.data.toDate,
      });
   }
}
