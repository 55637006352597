/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { patchForDecimalNumber } from 'src/app/utils/formatLocalNumber';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { CaseService } from 'src/services/case.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { Page } from '../../../page';
import { SubjectNewRecordComponent } from '../subject-new-record/subject-new-record.component';

/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */
@Component({
   selector: 'subject-record',
   templateUrl: './subject-record.component.html',
   styleUrls: ['./subject-record.component.scss'],
})
export class SubjectRecordComponent implements OnInit {
   page: Page<any> = new Page();
   subjectId: string;
   myInput = '';
   @Input() status: any;
   displayedColumns: string[] = ['caseNumber', 'problemId', 'startDate', 'endDate', 'reimbursment', 'reimbursmentProvider', 'procedure', 'actions'];
   loggedIn: any;
   isMainCenterBelgrade = false;
   isUserDrugostepeni = false;
   object: any = {
      document: '',
      action: '',
      subjectId: '',
      message: '',
      title: '',
   };

   localStorageCenterId: any;
   isAdmin: boolean;
   constructor(
      public router: Router,
      private paginationService: CustomPaginationService,
      private route: ActivatedRoute,
      private subjectService: SubjectsService,
      public dialog: MatDialog,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private caseService: CaseService,
      private toastr: ToastrImplService,
      private userService: UserService
   ) {
      this.isAdmin = this.userService.isGlobalAdmin();
      this.localStorageCenterId = localStorage.getItem('localStorageCenterId');
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      this.route.params.subscribe(params => {
         this.subjectId = params.id;
      });
   }

   ngOnInit(): void {
      this.doFilter();
   }
   formatNumber(num: any) {
      return patchForDecimalNumber(num);
   }

   getNextPage() {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   getPreviousPage() {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }

   getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter();
   }

   getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter();
   }

   getPageInNewSize(pageSize: number) {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter();
   }

   doFilter() {
      let x = null;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni || this.isAdmin) {
         x = this.subjectService.getFilteredRightPage(this.page.pageable, this.subjectId, '', this.localStorageCenterId);
      } else {
         x = this.subjectService.getFilteredRightPage(this.page.pageable, this.subjectId, '', this.loggedIn?.csrId);
      }
      x.subscribe((page: any) => {
         this.page = page;
         this.page.pageable.pagePerShow = page.number + 1;
      });
   }

   addNewRecord() {
      const dialogRef = this.dialog.open(SubjectNewRecordComponent, {
         panelClass: 'overlay-panel',
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         data: {
            subjectId: this.subjectId,
            record: {
               caseId: null,
            },
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.toastr.success('SNACKBAR.ADDED_RECORD');
            const d = data[0];

            const newData = {
               caseNumber: d.caseRights.socialCase?.caseNumber,
               problemId: d.caseRights.problem.problemIdentity.problemId,
               problemTitle: d.caseRights.problem?.problemsCodebook?.title ? d.caseRights.problem?.problemsCodebook?.title : null,
               fromDate: d.fromDate,
               toDate: d.toDate,
               price: d.price,
               serviceProviderTitle: d.serviceProvider?.name ? d.serviceProvider?.name : null,
               caseRightTitle: d.rightsCodebook?.title ? d.rightsCodebook?.title : null,
               servicesTitle: d.services?.title ? d.services?.title : null,
               professionalProceduresTitle: d.professionalProcedures?.title ? d.professionalProcedures?.title : null,
               otherProceduresAcitvitiesTitle: d.otherProceduresActivities?.title ? d.otherProceduresActivities?.title : null,
               legalProceduresTitle: d.legalProcedures?.title ? d.legalProcedures?.title : null,
               servicesId: d.services?.id ? d.services?.id : null,
               serviceProviderId: d.serviceProvider?.id ? d.serviceProvider?.id : null,
               caseRightId: d.rightsCodebook?.id ? d.rightsCodebook?.id : null,
               otherProceduresAcitvitiesId: d.otherProceduresActivities?.id ? d.otherProceduresActivities?.id : null,
               professionalProceduresId: d.professionalProcedures?.id ? d.professionalProcedures?.id : null,
               legalProceduresId: d.legalProcedures?.id ? d.legalProcedures?.id : null,
               caseId: d.id,
            };

            this.page.content = [...this.page.content, newData];
         }
      });
   }

   handleDeleteDialog(element: any) {
      this.translate.get('WARNING_DIALOG.SUBJECT_RECORD_DELETE.MESSAGE').subscribe((res: string) => {
         this.object.message = res;
      });
      this.translate.get('WARNING_DIALOG.SUBJECT_RECORD_DELETE.TITLE').subscribe((res: string) => {
         this.object.title = res;
      });
      this.object.action = ModalEventEnum.DELETE;
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         panelClass: 'overlay-panel',
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: this.object,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.DELETE && data.status) {
            this.caseService.deleteCaseRights(element.caseId).subscribe(res => {
               this.doFilter();
            });
         }
      });
   }

   handleEditDialog(element: any) {
      const dialogRef = this.dialog.open(SubjectNewRecordComponent, {
         panelClass: 'overlay-panel',
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         data: {
            subjectId: this.subjectId,
            record: element,
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.toastr.success('SNACKBAR.ADDED_RECORD');
            this.doFilter();
         }
      });
   }
}
