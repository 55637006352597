/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataSharingService } from 'src/services/data-sharing.service';
import { NotificationService } from 'src/services/notification.service';
import { Router } from '@angular/router';
import { Page } from '../page';
import { CustomPaginationService } from 'src/services/custom-pagination.service';

@Component({
  selector: 'app-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss']
})
export class NotificationContentComponent implements OnInit {
  notifications = [];
  page: Page<any> = new Page();
  constructor(
    public router: Router,
    private dataSharingService: DataSharingService,
    private notificationsService: NotificationService,
    private paginationService: CustomPaginationService,
    private cdr: ChangeDetectorRef) {
    this.getAllNotifications();
  }

  ngOnInit(): void {
  }
  public getNextPage(): void {
    this.page.pageable = this.paginationService.getNextPage(this.page);
    this.getAllNotifications();
}

public getPreviousPage(): void {
    this.page.pageable = this.paginationService.getPreviousPage(this.page);
    this.getAllNotifications();
}

public getFirstPage(): void {
    this.page.pageable = this.paginationService.getFirstPage(this.page);
    this.getAllNotifications();
}

public getLastPage(): void {
    this.page.pageable = this.paginationService.getLastPage(this.page);
    this.getAllNotifications();
}

public getPageInNewSize(pageSize: any): void {
    this.page.pageable = this.paginationService.getPageInNewSize(
        this.page,
        pageSize
    );
    this.getAllNotifications();
}
  getAllNotifications() {
    this.dataSharingService.messages.subscribe(value => {

      this.notificationsService.getAllNotificationsPageable(this.page.pageable.pageNumber, this.page.pageable.pageSize).subscribe(
        res => {
          this.notifications = res.content;
          this.page.pageable.pagePerShow = res.number + 1;
          this.page = res
          this.cdr.detectChanges();
        }
      );
    });
  }
}
