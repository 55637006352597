<page-intro-header title="{{ 'IMPORT_COLLECTIONS.IMPORT_DATA' | translate }}">
</page-intro-header>

<mat-card class="card-import">
  <div>
    <form class="drop-zone drop-zone-slide" [formGroup]="importDataForm" file-drop (files)="dropSuccess($event)">

      <div>
        <mat-form-field appearance="outline" style="display: inherit;">
          <mat-label>{{'IMPORT_COLLECTIONS.CHOOSE_COLLECTION' | translate}}</mat-label>
          <mat-select formControlName="collection" [(value)]="selectedCollection">
            <mat-option [value]="collections.FOSTER_PARENT">{{'IMPORT_COLLECTIONS.LIST.FOSTER_PARENTS' | translate}}
            </mat-option>
            <mat-option [value]="collections.FOSTER_CHILD">{{'IMPORT_COLLECTIONS.LIST.FOSTER_CHILD' | translate}}</mat-option>
            <mat-option [value]="collections.FOSTER_CARE">{{'IMPORT_COLLECTIONS.LIST.ESTABLISHED_FOSTER_CARE' | translate}}
            </mat-option>
            <mat-option [value]="collections.ADOPTED">{{'IMPORT_COLLECTIONS.LIST.ADOPTED' | translate}}</mat-option>
            <mat-option [value]="collections.DEPENDENT_PERSON">{{'IMPORT_COLLECTIONS.LIST.DEPENDENTS' | translate}}
            </mat-option>
            <mat-option [value]="collections.ABUSED_PERSON">{{'IMPORT_COLLECTIONS.LIST.VICTIM' | translate}}</mat-option>
            <mat-option [value]="collections.VIOLENCE_PERSON">{{'IMPORT_COLLECTIONS.LIST.BULLY' | translate}}</mat-option>
            <mat-option [value]="collections.PROTEGE_PERSON_REGISTRY">{{'IMPORT_COLLECTIONS.LIST.GUARDIANSHIP_REGISTRY'
              | translate}}
            </mat-option>
            <mat-option
              [value]="collections.PROTEGE_PERSON_ADULTS">{{'IMPORT_COLLECTIONS.LIST.GUARDIANSHIP_BOOK_OF_ADULTS' | translate}}
            </mat-option>
            <mat-option
              [value]="collections.PROTEGE_PERSON_CHILD">{{'IMPORT_COLLECTIONS.LIST.GUARDIANSHIP_BOOK_OF_MINORS' | translate}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="importDataForm.get('collection').hasError('required')">
            {{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.INPUT_ERROR' | translate}}
          </mat-error>
          <mat-error *ngIf=" importDataForm.get('collection').hasError('incorrect')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="search centar-search">
          <mat-label>
            {{'GLOBAL.CENTER' | translate}}
          </mat-label>
          <input type="text" matInput formControlName="subcenter" [matAutocomplete]="autoSubcenter"
            (click)="resetFileds()" required />
          <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let cent of filteredSubcenters | async" [value]="cent">
              {{ cent.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
        <input id="file" type="file" formControlName="files" (change)="submit($event)">
        <label for="file"><strong>{{ 'IMPORT_COLLECTIONS.CHOOSE_FILE' | translate }}</strong>
          <span class="dragndrop"> {{ 'IMPORT_COLLECTIONS.DRAG_AND_DROP' | translate }}</span>.
        </label>
      </div>
      <br>
      <div>
        <label *ngIf="uploadStatus">{{uploadMessage | translate }}</label>
      </div>
    </form>
    <form-footer>

      <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary"
        (click)="submitImport()" type="submit" [disabled]="!importDataForm.valid || fileToUpload === null"
        #submitButton>
        {{ 'IMPORT_COLLECTIONS.IMPORT' | translate }}
      </button>
      <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary"
        (click)="stopImport()" type="submit" [disabled]="uploadStatus != 'IMPORTING'" #submitButton>
        {{ 'IMPORT_COLLECTIONS.STOP_IMPORT' | translate }}
      </button>
    </form-footer>
  </div>
  <div class="drop-zone"><textarea class="drop-zone" rows="10" cols="50" readonly style="resize: none;"
      [(ngModel)]="traceImportProcess"></textarea></div>

</mat-card>
<mat-chip-list #chipList class="chip-list">
</mat-chip-list>
<div class="table-responsive-wrapper">
  <table mat-table id="myTable" class="subjects__table" #myTable [dataSource]="collectionsImports">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{ 'ID' | translate }}</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ element?.id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="center">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'IMPORT_DATA.IMPORTS.CENTER' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.center?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'IMPORT_DATA.IMPORTS.BEGIN' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.startDate }}</td>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'IMPORT_DATA.IMPORTS.END' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.endDate }}</td>
    </ng-container>
    <ng-container matColumnDef="nameCSV">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'IMPORT_DATA.IMPORTS.CSV_NAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.nameCSV }}</td>
    </ng-container>
    <ng-container matColumnDef="subjectCount">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'IMPORT_DATA.IMPORTS.SUBJECT_COUNT' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.subjectCount }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'IMPORT_DATA.IMPORTS.STATUS' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{
        (element?.status=='FINISHED'?'IMPORT_DATA.IMPORTS.SUCCESS':(element?.status=='STOPPED'?'IMPORT_DATA.IMPORTS.STOP':'IMPORT_DATA.IMPORTS.FAIL'))
        | translate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="collectionName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'IMPORT_DATA.IMPORTS.COLLECTION_NAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ 'IMPORT_COLLECTIONS.LIST.' + element?.collectionName | translate }}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
    <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" (click)="onRowClicked(element)"
      class="subjects__row">
    </tr>
  </table>
</div>