/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  // tslint:disable-next-line: ban-types
  nationality = [];
  // tslint:disable-next-line: ban-types
  relationship: String[] = [];
  // tslint:disable-next-line: ban-types
  nspDocumentTypes = [];

  constructor(private translate: TranslateService) { }

  getRelationships() {
    this.relationship = [];
    this.translate.get('RELATIONSHIP.PARTNER').subscribe((res: string) => {
      this.relationship.push(res);
    });
    this.translate.get('RELATIONSHIP.CHILD').subscribe((res: string) => {
      this.relationship.push(res);
    });
    this.translate.get('RELATIONSHIP.PARENT').subscribe((res: string) => {
      this.relationship.push(res);
    });
    this.translate.get('RELATIONSHIP.SIBLING').subscribe((res: string) => {
      this.relationship.push(res);
    });
    this.translate.get('RELATIONSHIP.GRANDPARENT').subscribe((res: string) => {
      this.relationship.push(res);
    });
    this.translate.get('RELATIONSHIP.GRANDCHILD').subscribe((res: string) => {
      this.relationship.push(res);
    });
    this.translate.get('RELATIONSHIP.SUPPORT').subscribe((res: string) => {
      this.relationship.push(res);
    });
    this.translate.get('RELATIONSHIP.OTHER').subscribe((res: string) => {
      this.relationship.push(res);
    });

    return this.relationship;

  }

  translateNationalities() {
    this.nationality = [];
    this.translate.get('NATIONALITY.SRBIN').subscribe((res: string) => {
      this.nationality.push({id: 'SRBIN', value: res});
    });
    this.translate.get('NATIONALITY.CRNOGORAC').subscribe((res: string) => {
      this.nationality.push({id: 'CRNOGORAC', value: res});
    });
    this.translate.get('NATIONALITY.JUGOSLOVEN').subscribe((res: string) => {
      this.nationality.push({id: 'JUGOSLOVEN', value: res});
    });
    this.translate.get('NATIONALITY.ALBANAC').subscribe((res: string) => {
      this.nationality.push({id: 'ALBANAC', value: res});
    });
    this.translate.get('NATIONALITY.BOSNJAK').subscribe((res: string) => {
      this.nationality.push({id: 'BOSNJAK', value: res});
    });
    this.translate.get('NATIONALITY.BUGARIN').subscribe((res: string) => {
      this.nationality.push({id: 'BUGARIN', value: res});
    });
    this.translate.get('NATIONALITY.BUNJEVAC').subscribe((res: string) => {
      this.nationality.push({id: 'BUNJEVAC', value: res});
    });
    this.translate.get('NATIONALITY.VLAH').subscribe((res: string) => {
      this.nationality.push({id: 'VLAH', value: res});
    });
    this.translate.get('NATIONALITY.GORANAC').subscribe((res: string) => {
      this.nationality.push({id: 'GORANAC', value: res});
    });
    this.translate.get('NATIONALITY.MADJAR').subscribe((res: string) => {
      this.nationality.push({id: 'MADJAR', value: res});
    });
    this.translate.get('NATIONALITY.MUSLIMAN').subscribe((res: string) => {
      this.nationality.push({id: 'MUSLIMAN', value: res});
    });
    this.translate.get('NATIONALITY.NEMAC').subscribe((res: string) => {
      this.nationality.push({id: 'NEMAC', value: res});
    });
    this.translate.get('NATIONALITY.ROM').subscribe((res: string) => {
      this.nationality.push({id: 'ROM', value: res});
    });
    this.translate.get('NATIONALITY.RUMUN').subscribe((res: string) => {
      this.nationality.push({id: 'RUMUN', value: res});
    });
    this.translate.get('NATIONALITY.RUS').subscribe((res: string) => {
      this.nationality.push({id: 'RUS', value: res});
    });
    this.translate.get('NATIONALITY.RUSIN').subscribe((res: string) => {
      this.nationality.push({id: 'RUSIN', value: res});
    });
    this.translate.get('NATIONALITY.SLOVAK').subscribe((res: string) => {
      this.nationality.push({id: 'SLOVAK', value: res});
    });
    this.translate.get('NATIONALITY.SLOVENAC').subscribe((res: string) => {
      this.nationality.push({id: 'SLOVENAC', value: res});
    });
    this.translate.get('NATIONALITY.UKRAJINAC').subscribe((res: string) => {
      this.nationality.push({id: 'UKRAJINAC', value: res});
    });
    this.translate.get('NATIONALITY.HRVAT').subscribe((res: string) => {
      this.nationality.push({id: 'HRVAT', value: res});
    });
    this.translate.get('NATIONALITY.CEH').subscribe((res: string) => {
      this.nationality.push({id: 'CEH', value: res});
    });
    this.translate.get('NATIONALITY.OSTALO').subscribe((res: string) => {
      this.nationality.push({id: 'OSTALO', value: res});
    });
    this.translate.get('NATIONALITY.NEIZJASNJENI').subscribe((res: string) => {
      this.nationality.push({id: 'NEIZJASNJENI', value: res});
    });
    this.translate.get('NATIONALITY.NEOPREDELJENI').subscribe((res: string) => {
      this.nationality.push({id: 'NEOPREDELJENI', value: res});
    });
    this.translate.get('NATIONALITY.NEPOZNATO').subscribe((res: string) => {
      this.nationality.push({id: 'NEPOZNATO', value: res});
    });

    return this.nationality;
  }

}
