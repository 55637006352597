/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'collapsible-workers-groups',
  templateUrl: './collapsible-workers-groups.component.html',
  styleUrls: ['./collapsible-workers-groups.component.scss']
})
export class CollapsibleWorkersGroupsComponent implements OnInit {
  @Input() title: string;
  @Input() groupExpanded: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }
  toggle() {
    this.groupExpanded = !this.groupExpanded
  }
}
