/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/services/app.service';
import { AlfrescoService } from 'src/services/alfresco.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSharingService } from 'src/services/data-sharing.service';
import { VsService } from 'src/services/vs.service';

interface DocumentData {
   document: any;
   action: 'cancel';
   subjectId: any;
}
@Component({
   selector: 'preview-document',
   templateUrl: './preview-document.component.html',
   styleUrls: ['./preview-document.component.scss'],
})
export class PreviewDocumentComponent implements OnInit {
   signDocumentForm: FormGroup;
   data: any;
   docUrl;
   requireSignature = false;
   isPdf = true;
   time: any;

   constructor(
      public dialogRef: MatDialogRef<any>,
      @Optional() @Inject(MAT_DIALOG_DATA) public document: DocumentData & { requireSignature: boolean },
      private appService: AppService,
      private alfrescoService: AlfrescoService,
      private domSanitizer: DomSanitizer,
      private formBuilder: FormBuilder,
      private dataSharingService: DataSharingService,
      private vsService: VsService
   ) {}

   ngOnInit(): void {
      this.data = this.document.document;
      this.time = (this.data.dateOfModification as string).replace(' ', '. ');
      this.requireSignature = this.document.requireSignature;
      this.isPdf = this.document.document.format.includes('pdf');
      this.docUrl = this.appService.getUrl() + environment.viewDocumentUrl + this.document.document.documentId + '/' + this.document.document.name;

      this.signDocumentForm = this.formBuilder.group({
         note: ['', []],
      });
   }

   handleSignature(type: any) {
      const note = this.signDocumentForm.get('note').value;
      const handleSignatureDto = {
         documentId: this.document.document.documentId,
         caseId: this.data.caseId,
         caseName: this.data.caseName,
         status: type,
         documentName: this.document.document.name,
         documentNote: note,
      };
      // olja.adjelovski
      // potpisivanje dokumenta (Odobravanje)
      if (this.document.document.name.includes('VS-ZAKLJUCAK')) {
         this.vsService.supervisorDecisionMake(type === 'accept', this.data.caseId).subscribe(() => {
            this.alfrescoService.handleSignature(handleSignatureDto).subscribe(res => {
               this.dialogRef.close({ event: 'sign-document', data: note, typeOfDocument: 'VS-ZAKLJUCAK' });
            });
         });
      } else {
         this.alfrescoService.handleSignature(handleSignatureDto).subscribe(res => {
            this.dialogRef.close({
               event: type === 'accept' ? 'sign-document' : 'reject-document',
               data: note,
               typeOfDocument: this.document.document.name.includes('VS-PP') ? 'VS-PP' : 'VS-PU',
            });
         });
      }
   }

   closeDialog() {
      this.dialogRef.close({ event: 'cancel' });
   }
}
/**
 * TODO @gorandivovic @jelenaninkovic @danijelgrabez @oljaandjelovski
 *
 * 1. Connect to API and retrieve correct document for preview
 * 2. Add proper type for Document shape
 */
