/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/pageable';

/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 *
 */
@Injectable({
  providedIn: 'root'
})
export class SupervisionService {

  constructor(private httpClient: HttpClient, private app: AppService) { }

  findSubordinates(supervisorId: any) {

    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.findSubordinates + '/' + supervisorId, { headers }) as Observable<any>;
  }

  findCasesBySubordinate(workerId: any) {

    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.findCasesBySubordinate + '/' + workerId, { headers }) as Observable<any>;
  }


  getCaseDocuments(subjectId: string, caseId: string, pageable: Pageable, csrId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(
      this.app.getUrl() + environment.findDocumentsByCase + '/' + pageable.pageNumber + '/' + pageable.pageSize + '/' 
      +  subjectId + '/' + caseId + '/' + csrId, {headers}) as Observable<any>;
  }

  markAsReadSupervisionCase(caseId: number){
    const headers = new HttpHeaders({
       'Content-type': 'application/json',
    });
    return this.httpClient.put(this.app.getUrl() + environment.markAsReadSupervisionCase + caseId, { headers }) as Observable<any>;
 }
}
