import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImportSocialCaseService {

    constructor(private httpClient: HttpClient, private appService: AppService) { }

    importData(csrId: number, file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
        return this.httpClient
            .post(url + environment.importSocialCasesUrl + csrId,
                formData) as Observable<any>;

    }

    checkStatus() {
        const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
        return this.httpClient
            .get(url + environment.importSocialCasesStatus) as Observable<any>;

    }

    getMessages() {
        const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
        return this.httpClient
            .get(url + environment.importSocialCasesMessages, { responseType: 'text' }) as Observable<any>;

    }

    stopImport() {
        const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
        return this.httpClient
            .delete(url + environment.stopSocialCasesImport) as Observable<any>;

    }

    getLast10SocialCasesImports() {
        return this.httpClient.get(this.appService.getUrl() + environment.socialCasesImports) as Observable<any>;
    }

}
