import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EventEnum } from 'src/app/entrance/types';
import { CodebookService } from 'src/services/codebook.service';

@Component({
    selector: 'app-complain-decision',
    templateUrl: './complain-decision.component.html',
    styleUrls: ['./complain-decision.component.scss']
})
export class ComplainDecisionComponent implements OnInit {

    complainResponses: any = [];
    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ComplainDecisionComponent>,
        private codebookService: CodebookService
    ) { }
    complainDecisionForm: FormGroup;

    ngOnInit(): void {
        this.codebookService.getComplainResponseCodebook().subscribe(response => {
            this.complainResponses = response;
        });
        this.complainDecisionForm = this.formBuilder.group({
            decision: ['', [Validators.required]],
        });
    }

    makeDecision(complainDecisionForm: FormGroup) {
        this.dialogRef.close({ event: EventEnum.SUBMIT, data: this.complainDecisionForm.controls.decision.value });

    }
    closeDialog() {
        this.dialogRef.close({ event: 'Cancel' });
    }
    radioChange(event: any) {
        this.complainDecisionForm.controls.decision.setValue(event);
    }
}
