<form class="form" [formGroup]="closeCaseForm">
  <form-group title="{{ 'VS.CLOSE_CASE.TITLE' | translate }}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'VS.CLOSE_CASE.REASON' | translate }}
        </mat-label>
        <mat-select formControlName="reason" [compareWith]="compareLoadedObjects"
                    [disabled]="data.waitingForApproval===true||!(closeCaseForm.get('fileName').value===null||closeCaseForm.get('fileName').value==='')">
                     <mat-option ></mat-option>
          <mat-option *ngFor="let reason of reasons" [value]="reason">
            {{ reason.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "VS.CLOSE_CASE.RISKS" | translate }}
        </mat-label>
        <textarea matInput type="text" formControlName="risks" [readonly]="data.waitingForApproval===true||!(closeCaseForm.get('fileName').value===null||closeCaseForm.get('fileName').value==='')"
                  autocomplete="off"></textarea>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "VS.CLOSE_CASE.COMMENT" | translate }}
        </mat-label>
        <textarea matInput type="text" formControlName="comment" [readonly]="data.waitingForApproval===true||!(closeCaseForm.get('fileName').value===null||closeCaseForm.get('fileName').value==='')"
                  autocomplete="off"></textarea>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>


    <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden>
    <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary" type="button"
      [disabled]="
      this.data.waitingForApproval===true||
      !(
         (closeCaseForm.value.reason===null||closeCaseForm.value.reason===undefined) &&
         (closeCaseForm.value.risks===null||closeCaseForm.value.risks===''||closeCaseForm.value.risks===undefined) &&
         (closeCaseForm.value.comment===null||closeCaseForm.value.comment===''||closeCaseForm.value.comment===undefined)
       )|| !(closeCaseForm.get('fileName').value===null||closeCaseForm.get('fileName').value==='')" (click)="file.click()">
      {{ 'LAWYER.CREATE' | translate }}
    </button>
     <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary" type="button" 
      [disabled]="this.data.waitingForApproval===true||closeCaseForm.get('fileName').value==null" (click)="handleFileDelete()">
          {{ "VS.CLOSE_CASE.CHANGE_DOCUMENT" | translate }}
    </button>
    <br>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'VS.CLOSE_CASE.EXIT_ACT' | translate }}</mat-label>
        <input matInput type="text" formControlName="fileName" readonly  autocomplete="off" />
      </mat-form-field>
    </field-holder>

    <form-subgroup-divider></form-subgroup-divider>
    <form-footer>
      <button mat-stroked-button [disabled]="isFormDigitallySigned" color="primary" mat-dialog-close
              (click)="closeDialog()" type="button" class="form-footer__button form-footer__button--secondary">
        {{ 'VS.CLOSE_CASE.CANCEL' | translate }}
      </button>
      <button mat-flat-button color="warn" type="submit" class="form-footer__button form-footer__button--primary"
              (click)="closeCase()" [disabled]=" (data.waitingForApproval===true && data.approved===false)|| 
        (
            (
               (closeCaseForm.value.reason===null||closeCaseForm.value.reason===undefined)&&
               (closeCaseForm.value.risks===null||closeCaseForm.value.risks===''||closeCaseForm.value.risks===undefined)&&
               (closeCaseForm.value.comment===null||closeCaseForm.value.comment===''||closeCaseForm.value.comment===undefined)
         )&&
            (closeCaseForm.get('fileName').value===null||closeCaseForm.get('fileName').value==='')
         )||!hasProblem">
        <span *ngIf="data.waitingForApproval===false&&(closeCaseForm.get('fileName').value===null||closeCaseForm.get('fileName').value==='') ">
          {{ 'VS.SEND_ON_REVIEW' | translate }}
        </span>
        <span *ngIf="(data.waitingForApproval===true || data.waitingForApproval === null)||(closeCaseForm.get('fileName').value!==null&&closeCaseForm.get('fileName').value!=='')">
          {{ 'VS.CLOSE_CASE.SEND_SAVE' | translate }}
        </span>
      </button>
    </form-footer>
  </form-group>
</form>
