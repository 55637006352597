/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 *
*/
/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */
export class FosterFamilyData {

  firstName: string;
  lastName: string;
  birthYear: any;
  relationshipCodebook: any;
  address: string;
  phone: string;
  information: string;
  /* fosterFamilyId predstavlja interni id na frontu, kao indikator id-ja dodate bliske osobe. kako bismo obezbedili
    da ne unese vise istih bliskih osoba
  */
  fosterFamilyId: string;

  constructor(firstName: string, lastName: string, birthYear: any, relationship: any, address: string, phone: string, fosterFamilyId: string, information: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.relationshipCodebook = relationship;
    this.birthYear = birthYear;
    this.phone = phone;
    this.address = address;
    this.fosterFamilyId = fosterFamilyId;
    this.information = information;
  }
}
