<container kind="midi">
   <case-page-header (handleComplainAccepted)="acceptedComplain()" (newCaseStatus)="updateStatus($event)"
   *ngIf="caseData" [caseData]="caseData" [caseId]="caseId" [subjectId]="subjectId" [teamMember]="teamMember" [caseName]="caseName"
      [isLawyer]="isLawyer" (newProblem)="problemAdded($event)" (saveEvent)="saveCurrentData()">
   </case-page-header>
   <page-intro-header title="{{title}}"></page-intro-header>

   <mat-card class="card" *ngIf="isLawyer && teamMember===false">
      <form [formGroup]="pnnForm" class="form">
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.DECISION_TYPE' | translate}}
               </mat-label>
               <mat-select formControlName="decisionType" required [compareWith]="compareLoadedObjects"
                  (selectionChange)="showOptionalFields()">
                  <mat-option *ngFor="let decType of decisionTypes" [value]="decType">
                     {{ decType.title}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.DATE_CASE_CREATION' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerDateOfCaseCreation" formControlName="dateOfCaseCreation"
                  autocomplete="off" [max]="maxDate" />
               <mat-datepicker-toggle matSuffix [for]="pickerDateOfCaseCreation"></mat-datepicker-toggle>
               <mat-datepicker #pickerDateOfCaseCreation></mat-datepicker>
            </mat-form-field>
            <mat-radio-group aria-label="Select an option" color="primary" formControlName="decisionMaker"
                         class="form__radio-group form__radio-group--2-cols">
              <span class="form__radio-group-label">
                {{ 'LAWYER.DECISION_MAKER' | translate }}
              </span><br>
              <mat-radio-button *ngFor="let option of decisionMakerOptions" [value]="option" 
              (change)="decisionMakerSelected($event)"> 
                {{ option.title }}
              </mat-radio-button>
            </mat-radio-group>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline" *ngIf="pnnForm.value.decisionMaker?.id === 2">
                <mat-label>{{ 'LAWYER.DECISION_MAKER_DESCRIPTION' | translate }}</mat-label>
                <textarea matInput type="text" formControlName="decisionMakerDescription" autocomplete="off"
                     matTooltip="{{'LAWYER.DECISION_MAKER_DESCRIPTION_TOOLTIP'| translate}}" >
                </textarea>
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
             <mat-form-field appearance="outline" >
              <mat-label>{{ 'LAWYER.DECISION_MAKER_TITLE' | translate }}</mat-label>
              <input matInput type="text" formControlName="decisionMakerTitle" autocomplete="off"/>
            </mat-form-field>
            <mat-form-field appearance="outline" >
              <mat-label>{{ 'LAWYER.DECISION_MAKER_NAME' | translate }}</mat-label>
              <input matInput type="text" formControlName="decisionMakerName" autocomplete="off" required />
            </mat-form-field>
    
          </field-holder>
         <field-holder kind="3-cols" *ngIf="pnnForm?.get('decisionType')?.value?.id === 1">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.INITIATOR' | translate}}
               </mat-label>
               <mat-select formControlName="initiator" [compareWith]="compareLoadedObjects">
                  <mat-option *ngFor="let init of initiatorCodebook" [value]="init">
                     {{ init.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.PAYMENT_METHOD' | translate}}
               </mat-label>
               <mat-select formControlName="paymentMethod" required [compareWith]="compareLoadedObjects"
                  (selectionChange)="paymentMethodChanged()">
                  <mat-option *ngFor="let paymentMethod of paymentMethodCodebook" [value]="paymentMethod">
                     {{ paymentMethod.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline"
               *ngIf="pnnForm.get('decisionType').value.id === 1 &&
                 (pnnForm.get('paymentMethod').value !== null && pnnForm.get('paymentMethod').value !== '' && pnnForm.get('paymentMethod').value.id !==1 && pnnForm.get('paymentMethod').value.id !==6)">
               <mat-label>
                  {{'CASES.ACCOUNT_NUMBER' | translate}}
               </mat-label>
               <input matInput type="text" formControlName="account" autocomplete="off" 
               [required]="pnnForm.get('decisionType')?.value?.id === 1 && 
                 (pnnForm.get('paymentMethod').value !== null && pnnForm.get('paymentMethod').value !== '' && pnnForm.get('paymentMethod').value.id !==1&& pnnForm.get('paymentMethod').value.id !==2 && pnnForm.get('paymentMethod').value.id !==6)"
               
                  placeholder="{{ 'ACCOUNT_NUMBER_FORMAT' | translate }}" [textMask]="mask" />
            </mat-form-field>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>
         <span class="form__radio-group-label">
            {{'DPN.PIO_NOTE' | translate}}
         </span>

         <field-holder kind="3-cols">

            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'DPN.PIO_DECISION' | translate }}
               </mat-label>
               <input type="text" matInput formControlName="pioDecision" [required]="pioDecisionRequred"
                  [matAutocomplete]="autoPioDecision" (focusout)="CheckConclusions(true)" />
               <mat-autocomplete #autoPioDecision="matAutocomplete" [displayWith]="displayCustomFormat">
                  <mat-option *ngFor="let option of pioDecisionsFiltered | async" [value]="option">
                     {{option.branchTypeCodebook.title}} {{ option.name }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error *ngIf="pnnForm.get('pioDecision').hasError('randomInput')">
                  {{ 'GLOBAL.AUTOCOMPLETE_ERROR' | translate }}
               </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'DPN.PIO_NUM_DECISION' | translate }}
               </mat-label>
                    <input matInput type="text" maxlength="250" (focusout)="CheckConclusions(false)"
                  formControlName="pioNumDecision" [required]="pioDecisionRequred" autocomplete="off" />
            </mat-form-field>

            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.PIO_DATE_DECISION' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerPioDateDecision" (focusout)="CheckConclusions(false)"
                  [required]="pioDecisionRequred" formControlName="pioDateDecision" [max]="maxDate" autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="pickerPioDateDecision"></mat-datepicker-toggle>
               <mat-datepicker #pickerPioDateDecision></mat-datepicker>
            </mat-form-field>
         </field-holder>
         <span class="form__radio-group-label">
            {{'DPN.PIO_FINDING_TITLE' | translate}}
         </span>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>{{ 'DPN.PIO_DECISION' | translate }}</mat-label>
               <input type="text" matInput formControlName="pioFinding" [matAutocomplete]="autoPioFinding"
                  (focusout)="CheckFindings(true)" [required]="pioFindingRequred" />
               <mat-autocomplete #autoPioFinding="matAutocomplete" [displayWith]="displayCustomFormat">
                  <mat-option *ngFor="let option of pioFindingsFiltered | async" [value]="option">
                     {{option.branchTypeCodebook.title}} {{ option.name }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error *ngIf="pnnForm.get('pioFinding').hasError('randomInput')">
                  {{ 'GLOBAL.AUTOCOMPLETE_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'DPN.PIO_NUM_FINDING' | translate }}</mat-label>
                    <input matInput type="text" maxlength="250" formControlName="pioNumFinding" autocomplete="off"
                  (focusout)="CheckFindings(false)" [required]="pioFindingRequred" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.PIO_DATE_FINDING' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerPioDateFinding" [required]="pioFindingRequred"
                  formControlName="pioDateFinding" autocomplete="off" (focusout)="CheckFindings(false)" [max]="maxDate" />
               <mat-datepicker-toggle matSuffix [for]="pickerPioDateFinding" (focusout)="CheckFindings(false)">
               </mat-datepicker-toggle>
               <mat-datepicker #pickerPioDateFinding></mat-datepicker>
            </mat-form-field>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>


         <ng-container
            *ngIf="pnnForm.get('decisionType').value !== null&&pnnForm?.get('decisionType')?.value?.id !== undefined">
            <field-holder>
               <mat-form-field appearance="outline" *ngIf="pnnForm?.get('decisionType')?.value?.id !== 1 && pnnForm.get('decisionType').value.id !== 6 && pnnForm.get('decisionType').value.id !== 3">
                  <mat-label>
                     {{ 'GLOBAL.DECISION_FOR_CASE_'+pnnForm.get('decisionType').value.id | translate }}
                  </mat-label>
                  <input matInput type="text" formControlName="terminationOfRights" autocomplete="off"
                     id="terminationOfRights" />
               </mat-form-field>
            </field-holder>
            <field-holder>
               <mat-form-field appearance="outline" *ngIf="pnnForm.get('decisionType').value.id === 3">
                  <mat-label>
                     {{ 'GLOBAL.DECISION_FOR_CASE_3'| translate }}
                  </mat-label>
                  <mat-select formControlName="terminationReason" [compareWith]="compareLoadedObjects" required>
                     <mat-option *ngFor="let reason of terminationReasons" [value]="reason">
                        {{ reason.title}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </field-holder>
         </ng-container>
         <span class="form__radio-group-label" *ngIf="pnnForm?.get('decisionType')?.value?.id === 1">
            {{'DPN.DURATION' | translate}}
         </span>
         <field-holder kind="2-cols" *ngIf="pnnForm?.get('decisionType')?.value?.id === 1">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'DPN.FROM_DATE' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerFromDate" required formControlName="validFrom"
               [max]="maxDate" autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
               <mat-datepicker #pickerFromDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'DPN.AMOUNT' | translate }}</mat-label>
               <input matInput type="text" (change)="checkAmount()" formControlName="amount" required autocomplete="off" id="amount" />
            </mat-form-field>
         </field-holder>
         <ng-container class="aside">
            <button mat-flat-button color="primary" type="button" [matMenuTriggerFor]="menu"
               #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
               {{ 'LAWYER.DOCUMENT' | translate
                    }}
               <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="filter-menu">
               <ng-container *ngFor="let docOptions of documentOptions">
                  <button mat-menu-item (click)="handleNewEvaluation(docOptions)">{{ docOptions.name }}</button>
               </ng-container>
            </mat-menu>

            <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden>
            <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
               type="submit" (click)="file.click()">
               {{ 'LAWYER.CREATE' | translate }}
            </button>
            <br>
            <field-holder>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'LAWYER.SOLUTION' | translate }}</mat-label>
                  <input matInput type="text" formControlName="fileName" readonly required autocomplete="off" />
               </mat-form-field>
            </field-holder>
            <field-holder kind="2-cols" *ngIf="pnnForm?.get('decisionType')?.value?.id === 3">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'DPN.FROM_DATE' | translate}}
                  </mat-label>
                  <input matInput [matDatepicker]="pickerFromDate" required formControlName="validFrom"
                  [max]="maxDate" autocomplete="off" />
                  <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFromDate></mat-datepicker>
               </mat-form-field>
            </field-holder>
         </ng-container>
         <form-footer class="footer">
            <span matTooltip="{{checkIfSendIsDisabled().msg}}" [matTooltipDisabled]="checkIfSendIsDisabled().disabled">
               <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}"
                  [matTooltipDisabled]="(checkIfSendIsDisabled().disabled === false && noRegistrationId === true) || noRegistrationId === false">
                  <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
                     [matTooltipDisabled]="!caseData?.newComplain">
                     <button mat-flat-button color="primary" type="button" (click)="sendRequest(); submitButton.disabled = true" #submitButton
                        [disabled]="!pnnForm.valid || caseData.caseStatus !== 'ACCEPTED' || caseData?.newComplain || !hasProblem||!((checkIfSendIsDisabled().disabled === false && noRegistrationId === true) || noRegistrationId === false)"
                        class="form-footer__button form-footer__button--secondary">
                        {{ 'DPN.SEND' | translate }}
                     </button>
                  </span>
               </span>
            </span>
            <mat-progress-spinner [diameter]="45" *ngIf="isSubmited" color="primary" mode="indeterminate">
            </mat-progress-spinner>
         </form-footer>
      </form>
   </mat-card>
   <mat-card *ngIf="teamMember">
      <team-member [editable]="enableConclusion" [caseId]="caseId" [subjectId]="subjectId"></team-member>
   </mat-card>
</container>
