<div *ngIf="!isEditing && !isNew">
  <h4 class="modal__title">{{ data.title }}</h4>
  <ul class="modal__list">
    <li class="modal__list-item" *ngIf="tracking.trackingData.caseId !== null">
      {{ 'MANAGE_TRACKING.MANAGE_FORM.CASE' | translate }}: {{ data.caseName }}
    </li>
    <li class="modal__list-item">
      {{ 'MANAGE_TRACKING.ACTIVITY' | translate }} {{ data.activity.title }}
    </li>
    <li class="modal__list-item">
      {{ 'MANAGE_TRACKING.CREATED_AT' | translate }}
      {{ data.date | date: 'dd.MM.yyyy.' }}
    </li>
    <li class="modal__list-item">
      {{ 'MANAGE_TRACKING.AUTHOR' | translate }}
      {{ data.author }}
    </li>
  </ul>
  <p class="modal__main">{{ data.description }}</p>
  <footer class="modal__footer">
    <button mat-stroked-button color="primary" class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()" type="button">
      {{ 'MANAGE_TRACKING.CLOSE' | translate }}
    </button>
  </footer>
</div>
<form class="form" [formGroup]="trackingDocumentForm" *ngIf="isEditing || isNew"
  (ngSubmit)="manageTrackingDocument(trackingDocumentForm)">
  <form-group title=" {{      isEditing
        ? ('MANAGE_TRACKING.MANAGE_FORM.HEADING_EDIT' | translate)
        : ('MANAGE_TRACKING.MANAGE_FORM.HEADING_NEW' | translate)}}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline" *ngIf="tracking.trackingData.caseId !== null">
        <mat-label> {{ 'MANAGE_TRACKING.MANAGE_FORM.CASE' | translate}}</mat-label>
        <input matInput type="text" formControlName="caseName" readonly autocomplete="off" />
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label> {{ 'MANAGE_TRACKING.MANAGE_FORM.ACTIVITY' | translate}}</mat-label>
        <input formControlName="activity" required type="text" matInput [matAutocomplete]="autoActivity"
          (focusout)="autoSelectType()" (input)="onInputChange($event)" />
        <mat-autocomplete #autoActivity="matAutocomplete" class="custom-autocomplete"
          (optionSelected)="onOptionSelected($event)" [displayWith]="displayCustomType">
          <mat-option *ngFor="let activity of filteredActivities | async" [value]="activity">
            {{ activity.title }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="trackingDocumentForm.get('activity').hasError('required')">
          {{ 'MANAGE_TRACKING.MANAGE_FORM.ACTIVITY_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label> {{ 'MANAGE_TRACKING.MANAGE_FORM.NOTE' | translate}}</mat-label>
        <textarea class="modal__textarea" matInput type="text" formControlName="note" required autocomplete="off"
          cdkTextareaAutosize></textarea>
        <mat-error *ngIf="trackingDocumentForm.get('note').hasError('required')">
          {{ 'MANAGE_TRACKING.MANAGE_FORM.NOTE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label> {{ 'MANAGE_TRACKING.MANAGE_FORM.DATE' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" required autocomplete="off" [max]="maxDate" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="trackingDocumentForm.get('date').hasError('required')">
          {{ 'MANAGE_TRACKING.MANAGE_FORM.DATE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer *ngIf="isEditing">
    <button mat-stroked-button color="primary" class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()" type="button">
      {{ 'MANAGE_TRACKING.MANAGE_FORM.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" class="modal-footer__button modal-footer__button--primary" type="submit"
      [disabled]="!trackingDocumentForm.valid">
      {{ 'MANAGE_TRACKING.MANAGE_FORM.SAVE' | translate }}
    </button>
  </form-footer>
  <form-footer *ngIf="isNew">
    <button mat-stroked-button color="primary" class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()" type="button">
      {{ 'MANAGE_TRACKING.MANAGE_FORM.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" class="modal-footer__button modal-footer__button--primary" type="submit"
      [disabled]="!trackingDocumentForm.valid || !isActivitySelected">
      {{ 'MANAGE_TRACKING.MANAGE_FORM.SAVE' | translate }}
    </button>
  </form-footer>
</form>