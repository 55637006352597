<container>
    <mat-card class="card">
        <card-header title="{{ 'STATISTICS.CLASSIFICATION_STATS.TITLE' | translate }}">
        </card-header>
        <form [formGroup]="decisionStatsForm" class="form">
            <field-holder kind="2-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{'GLOBAL.CENTER' | translate}}
                    </mat-label>
                    <input type="text" matInput [matAutocomplete]="autoSubcenter" formControlName="center"
                        (focusout)="autoSelectCenter()" required />
                    <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete"
                        [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let cent of filteredAllCenters | async" [value]="cent">
                            {{ cent.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{ 'STATISTICS.CLASSIFICATION_STATS.CLASSIFICATION' | translate }}
                    </mat-label>
                    <mat-select formControlName="classificationsCodebook">
                        <mat-option *ngFor="let element of classificationsOptions" [value]="element?.code">
                            {{ element.code +" "+ element.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <field-holder *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni">
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{ 'STATISTICS.CLASSIFICATION_STATS.CLASSIFICATION' | translate }}
                    </mat-label>
                    <input type="text" matInput formControlName="caseClassification"
                        (focusout)="checkClasificationCase(false) " [matAutocomplete]="auto" required>
                    <mat-autocomplete #auto="matAutocomplete" for="caseClassification"
                        [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.code}} | {{option.title}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error
                        *ngIf="decisionStatsForm.get('caseClassification').hasError('required')||decisionStatsForm.get('caseClassification').hasError('notValid')">
                        {{ 'STATISTICS.CLASSIFICATION_STATS.CASE_CLASSIFICATION_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATISTICS.CLASSIFICATION_STATS.SELECTED_YEAR' | translate }}</mat-label>
                    <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
                        onlyNumber />
                    <mat-error *ngIf="decisionStatsForm.controls.selectedYear.hasError('required')">
                        {{ "STATISTICS.DATE_ERROR" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'STATISTICS.CLASSIFICATION_STATS.AGE_GROUP' | translate
                        }}</mat-label>
                    <mat-select formControlName="ageGroup" multiple>
                        <mat-option *ngFor="let record of ageGroup" [value]="record">
                            {{ record.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'STATISTICS.CLASSIFICATION_STATS.GENDER' | translate
                        }}</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let record of genderOptions" [value]="record">
                            {{ record.gender }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
                <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
                    <mat-label>{{ 'STATISTICS.FROM' | translate }}</mat-label>
                    <input matInput type="text" formControlName="customAgeGroupFrom" autocomplete="off" maxlength="3"
                        (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
                        placeholder="{{ 'STATISTICS.FROM' | translate }}" [readonly]="!shouldShowAgeGroupRange" />
                    <mat-error *ngIf="decisionStatsForm.controls.customAgeGroupFrom.hasError('outOfRange')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="shouldShowAgeGroupRange">
                    <mat-label>{{ 'STATISTICS.TO' | translate }}</mat-label>
                    <input matInput type="text" formControlName="customAgeGroupTo" autocomplete="off" maxlength="3"
                        (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
                        placeholder="{{ 'STATISTICS.TO' | translate }}" [readonly]="!shouldShowAgeGroupRange" />
                    <mat-error *ngIf="decisionStatsForm.controls.customAgeGroupTo.hasError('incorect')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_INCORECT" | translate }}
                    </mat-error>
                    <mat-error *ngIf="decisionStatsForm.controls.customAgeGroupTo.hasError('outOfRange')">
                        {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
                    </mat-error>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button [disabled]="!decisionStatsForm.valid || btnDisabled"
                    class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                    (click)="sendRequest()">
                    {{ 'STATISTICS.SUBMIT' | translate }}
                </button>
                <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </form-footer>
        </form>
    </mat-card>
</container>