<form [formGroup]="conclusionStep" class="form">
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.FAMILY_MEMBERS_COUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="familyMembersCount" required readonly autocomplete="off" />
      <mat-error *ngIf="conclusionStep.get('familyMembersCount').hasError('required')">
        {{ 'NSP_MOCK.CONCLUSION.FAMILY_MEMBERS_COUNT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{'NSP_MOCK.CONCLUSION.FAMILY_MEMBERS_ABLE_TO_WORK_COUNT' | translate}}
      </mat-label>
      <input matInput type="text" formControlName="familyMembersAbleToWorkCount" required readonly autocomplete="off" />
      <mat-error *ngIf="conclusionStep .get('familyMembersAbleToWorkCount').hasError('required')">
        {{'NSP_MOCK.CONCLUSION.FAMILY_MEMBERS_ABLE_TO_WORK_COUNT_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{'NSP_MOCK.CONCLUSION.FAMILY_MEMBERS_UNABLE_TO_WORK_COUNT' | translate}}
      </mat-label>
      <input matInput type="text" formControlName="familyMembersUnableToWorkCount" required readonly
        autocomplete="off" />
      <mat-error *ngIf="conclusionStep.get('familyMembersUnableToWorkCount').hasError('required')">
        {{'NSP_MOCK.CONCLUSION.FAMILY_MEMBERS_UNABLE_TO_WORK_COUNT_ERROR' | translate}}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <h6 class="subgroup-title">
    {{ 'NSP_MOCK.CONCLUSION.GROUP_TITLE_EARNING_OVERVIEW' | translate }}
  </h6>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.EARNING' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningAmount" autocomplete="off" readonly />
      <mat-hint>{{ 'NSP_MOCK.CONCLUSION.EARNING_HINT' | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.EARNING_FROM' | translate }}
      </mat-label>
      <input matInput [matDatepicker]="earningAmountFrom" formControlName="earningAmountFrom" autocomplete="off" 
      [max]="maxDate" />
      <mat-datepicker-toggle matSuffix [for]="earningAmountFrom"></mat-datepicker-toggle>
      <mat-datepicker #earningAmountFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.EARNING_TO' | translate }}
      </mat-label>
      <input matInput [matDatepicker]="earningAmountTo" formControlName="earningAmountTo" autocomplete="off" 
      [max]="maxDate" />
      <mat-datepicker-toggle matSuffix [for]="earningAmountTo"></mat-datepicker-toggle>
      <mat-datepicker #earningAmountTo></mat-datepicker>
    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.MISSED_EARNING' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="missedEarningAmount" autocomplete="off" readonly />
      <mat-hint>{{
        'NSP_MOCK.CONCLUSION.MISSED_EARNING_HINT' | translate
      }}</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.MISSED_EARNING_FROM' | translate }}
      </mat-label>
      <input matInput [matDatepicker]="missedEarningAmountFrom" formControlName="missedEarningAmountFrom"
      [max]="maxDate" autocomplete="off" />
      <mat-datepicker-toggle matSuffix [for]="missedEarningAmountFrom"></mat-datepicker-toggle>
      <mat-datepicker #missedEarningAmountFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.MISSED_EARNING_TO' | translate }}
      </mat-label>
      <input matInput [matDatepicker]="missedEarningAmountTo" formControlName="missedEarningAmountTo"
      [max]="maxDate" autocomplete="off" />
      <mat-datepicker-toggle matSuffix [for]="missedEarningAmountTo"></mat-datepicker-toggle>
      <mat-datepicker #missedEarningAmountTo></mat-datepicker>
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.ABLE_TO_WORK_COUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="ableToLearnJobSkillsCount" autocomplete="off" />
      <mat-hint>{{
        'NSP_MOCK.CONCLUSION.ABLE_TO_WORK_COUNT_HINT' | translate
      }}</mat-hint>
      <mat-error *ngIf="conclusionStep.get('ableToLearnJobSkillsCount').hasError('pattern')">
        {{ 'NSP_MOCK.CONCLUSION.ABLE_TO_WORK_COUNT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{"NSP_MOCK.CONCLUSION.READY_TO_ACTIVATE_COUNT" | translate}}
      </mat-label>
      <input matInput type="text" formControlName="readyToLearnJobSkillsCount" autocomplete="off" />
      <mat-error *ngIf="conclusionStep.get('readyToLearnJobSkillsCount').hasError('pattern')">
        {{ 'NSP_MOCK.CONCLUSION.READY_TO_ACTIVATE_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.CONCLUSION.REQUIRES_ADDITIONAL_SERVICES' | translate }}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="requiresAdditionalServices"
      class="form__radio-group">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.CONCLUSION' | translate }}
      </mat-label>
      <textarea matInput type="text"  formControlName="conclusion" required autocomplete="off"></textarea>
      <mat-error *ngIf="conclusionStep.get('conclusion').hasError('required')">
        {{ 'NSP_MOCK.CONCLUSION.CONCLUSION_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.CONCLUSION.ADVICE' | translate }}
      </mat-label>
      <textarea matInput type="text"  formControlName="advice" required autocomplete="off"></textarea>
      <mat-error *ngIf="conclusionStep.get('advice').hasError('required')">
        {{ 'NSP_MOCK.CONCLUSION.ADVICE_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
    <field-holder  kind="4-cols" >
      <mat-form-field appearance="outline">
         <mat-label>{{ "NSP_MOCK.CONCLUSION.PROPOSАL_VALID_FROM" | translate }}</mat-label>
         <input matInput [matDatepicker]="picker" required formControlName="dateStart" #ref
            (dateChange)=changeDate() autocomplete="off" [max]="maxDate" />
         <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
         <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
   </field-holder>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NSP_MOCK.PREVIOUS_STEP' | translate }}
    </button>
    <button matStepperNext mat-flat-button color="primary" class="form-footer__button form-footer__button--secondary"
      (click)="saveConclusionStep()" [disabled]="
        conclusionStep.valid === false || sendClicked">
      {{ 'NSP_MOCK.SUBMIT_SEND' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="printPreview()"
      class="form-footer__button form-footer__button--primary">
      {{ "PRINT.PREVIEW" | translate }}
    </button>
  </form-footer>
</form>