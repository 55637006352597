import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EventEnum } from 'src/app/entrance/types';
import { LocalStorageService } from 'src/app/local-storage.service';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { getNumberFromString, patchForDecimalNumber } from 'src/app/utils/formatLocalNumber';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { environment } from 'src/environments/environment';
import { FormsSOCostsParticipation } from 'src/models/formsSOCostsParticipation';
import { FormsSOOtherRevenue } from 'src/models/formsSOOtherRevenue.model';
import { FormsSOPension } from 'src/models/formsSOPension.model';
import { AccommodationService } from 'src/services/accommodation-service';
import { AlfrescoService } from 'src/services/alfresco.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { CaseService } from 'src/services/case.service';
import { CodebookService } from 'src/services/codebook.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { CostsParticipationComponent } from '../accommodation/forms-s-o/costs-participation/costs-participation.component';
import { LiabilitiesPerOtherRevenueComponent } from '../accommodation/forms-s-o/liabilities-per-other-revenue/liabilities-per-other-revenue.component';
import { PensionLiabilitiesComponent } from '../accommodation/forms-s-o/pension-liabilities/pension-liabilities.component';
import { BrotherSisterFormComponent } from './brother-sister-form/brother-sister-form.component';
import { fileCheck } from 'src/app/utils/fileInputUtil';

enum AutocompleteFilterEnum {
   FACILITY = 'facility',
}

interface SupportCategoryPrice {
   id: number;
   supportCategoryCodebook: {
      id: number;
      code: string;
      title: string;
   };
   validFrom: Date;
   validTo: Date;
   amountMonth: number;
}
interface CategoryPrice {
   id: number;
   accommodationCategoryCodebook: {
      id: number;
      code: string;
      title: string;
      category: string;
      support: boolean;
      increase: number;
   };
   validFrom: Date;
   validTo: Date;
   price: number;
   pricePerDay: number;
}

@Component({
   selector: 'app-accommodation-lawyer',
   templateUrl: './accommodation-lawyer.component.html',
   styleUrls: ['./accommodation-lawyer.component.scss'],
})
export class AccommodationLawyerComponent implements OnInit {
   @Input() subjectId: any;
   @Input() caseId: any;
   @Input() caseData: any;
   @Input() accommodation: any;
   @Input() caseName: any;
   @Input() isDenial: boolean;
   @Input() complain: boolean;
   @Input() hasProblem: boolean;

   loggedIn: any;
   firstStep: FormGroup;
   secondStep: FormGroup;
   thirdStep: FormGroup;
   decisionTypes: [];
   accommodationFacilityOptions: any = [];
   filteredAccommodationFacilityOptions: Observable<any[]>;
   pocketMoneyAmountOptions: any = [];
   pocketMoneyPercentageOptions: any = [];
   banksOptions: any = [];
   typesOfAccommodationFees: any = [];
   decisionMakerOptions: any;

   showPreview: boolean;
   fileToUpload: File = null;
   documentOptions: any;
   idAccomodation = null;
   newComplain = false;
   accommodationPricingCodebookOptions: any = [];
   accommodationPricingCodebookSupportOptions: any = [];
   terminationReasons: any = [];

   isSubmited = false;
   @ViewChild('submitButton') submitButton;
   @ViewChild('facilityInput') facilityInput: ElementRef;
   withSupport = false;
   supportPriceCodebook = [];
   public mask = {
      guide: false,
      showMask: false,
      mask: [
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         '-',
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         '-',
         /[0-9]/,
         /[0-9]/,
      ],
   };

   object: any = {
      document: '',
      action: '',
      subjectId: '',
      message: '',
      title: '',
   };

   expeditionObject = {
      document: {
         documentId: '',
         caseId: '',
         caseName: '',
      },
      action: '',
      subjectId: '',
   };

   // Penzije
   displayedColumnsPensions: string[] = ['typesOfPensionCodebook', 'branchesPioFundCodebook', 'checkNumber', 'pensionAmount', 'startDate', 'action'];

   selectedPensions: any = [];
   brothersAndSisters: any = [];

   // Ostala primanja
   selectedOtherRevenues: any = [];
   displayedColumnsRevenues: string[] = ['revenueKind', 'branchesPioFundCodebook', 'checkNumber', 'revenueAmount', 'startDate', 'action'];

   // ucesce u troskovima smestaja
   selectedCostsParticipations: any = [];
   displayedColumnsCostsParticipations: string[] = [
      'fullName',
      'jmbg',
      'place',
      'pttNumber',
      'address',
      'typeOfParticipation',
      'participationAmount',
      'startDate',
      'action',
   ];
   displayedColumnsFamily: string[] = ['lastName', 'firstName', 'jmbg', 'note', 'action'];

   firstDegree = false;
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private translate: TranslateService,
      private accommodationService: AccommodationService,
      private codebookService: CodebookService,
      private toastr: ToastrImplService,
      public dialog: MatDialog,
      private formBuilder: FormBuilder,
      private authentication: AuthenticationService,
      private alfrescoService: AlfrescoService,
      private caseService: CaseService,
      private expedition: ExpeditionService,
      private localStorageService: LocalStorageService,
      private caseProblemRecordService: CaseProblemRecordService
   ) {
      // Odluka o smjestaju - OBRAZAC S3
      this.firstStep = this.formBuilder.group({
         dateOfAccommodation: [], // datum smjestaja
         accommodationFacility: [], // ustanova za smestaj
         monthlyCosts: [], // mjesecni troskovi smjesaja
         fosterParentBankAccount: [], // broj racuna ustanove ili hranitelja
         referenceNumber: ['', [Validators.minLength(5), Validators.maxLength(20), Validators.pattern('^[0-9]*$')]], // poziv na broj
         pocketMoneyPercentage: [], // procenat dzeparca
         pocketMoneyAmount: [], // iznos dzeparca
         pocketMoneyAndCostsSum: [], // zbir cijene smjesaja i dzeparca
         subjectBank: [], // poslovna banka korisnika
         subjectBankAccount: [], // tekuci racun korisnika
         accountNumber: [],
         accommodationPricingCodebook: [],
         supportCategory: [],
         accommodationPricingCodebookSupport: [],
         firstName: [], // Ime hranitelja
         lastName: [], // Prezime hranitelja
         jmbg: [], // Jmbg hranitelja
         jmbp: [], // Jmbp hranitelja
      });

      // Dodatne informacije i podaci o ucescu u troskovima - potrebno za S2
      this.secondStep = this.formBuilder.group({
         factualSituation: [], // cinjenicno stanje
         accommodationCostsSituation: [], // troskovi smjestaja stanje
         differenceSum: [], // razlika cene smestaja i dzeparca i penzija
         typeOfAccommodationFee: [],
      });

      this.codebookService.getAccommodationFacilityCodebook().subscribe(result => {
         this.accommodationFacilityOptions = result;
         this.filteredAccommodationFacilityOptions = this.firstStep.controls.accommodationFacility.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.FACILITY) : this.accommodationFacilityOptions.slice()))
         );
      });

      this.codebookService.getActiveBankCodebook().subscribe(result => {
         this.banksOptions = result;
      });

      this.codebookService.getPocketMoneyPercentageCodebook().subscribe(result => {
         this.pocketMoneyPercentageOptions = result;
         this.pocketMoneyPercentageOptions.unshift(null);
      });

      this.codebookService.getTypesOfAccommodationFeesCodebook().subscribe(result => {
         this.typesOfAccommodationFees = result;
      });

      this.codebookService.getDecisionTypeCodebook().subscribe(result => {
         this.decisionTypes = result;
         this.checkIsDenial();
      });

      this.thirdStep = this.formBuilder.group({
         lawyerFile: [''],
         decisionDocument: 0,
         fileName: [''],
         reason: [''],
         terminationDate: [null],
         decisionType: [''],
         decisionMaker: [''],
         decisionMakerDescription: [],
         decisionMakerName: [''],
         decisionMakerTitle: [''],
         fromStateBudget: [null],
         terminationReason: [''],
      });
      this.codebookService.getDecisionMakerCodebook().subscribe(result => {
         this.decisionMakerOptions = result;
         if (!this.accommodation?.socialCase?.decisionMaker) {
            this.thirdStep.controls.decisionMaker.setValue(result[0]);
            this.thirdStep.controls.decisionMakerTitle.setValue(result[0].title);
         } else {
            this.thirdStep.controls.decisionMaker.setValue(result[this.accommodation?.socialCase?.decisionMaker.id - 1]);
            this.thirdStep.controls.decisionMakerTitle.setValue(this.accommodation?.socialCase?.decisionMakerTitle);
         }
      });
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.id + ' | ' + objectValue.name + ', ' + objectValue.community : '';
   }
   checkIfSendIsDisabled() {
      return this.caseProblemRecordService.checkIfSendIsDisabled(
         this.caseData,
         !this.firstStep.valid || !this.secondStep.valid || !this.thirdStep.valid,
         this.hasProblem
      );
   }
   autoSelectItem() {
      const isId = /[0-9]/g.test(this.firstStep.get('accommodationFacility').value);
      autoSelect(this.accommodationFacilityOptions, 'accommodationFacility', isId ? 'id' : 'name', this.firstStep);
      setTimeout(() => {
         this.accommodationFacilityChange();
      }, 600);
   }

   ngOnChanges(changes: any): void {
      if (changes.accommodation && changes.accommodation.currentValue) {
         this.idAccomodation = this.accommodation.id;
         if (this.accommodation.accommodationO !== undefined && this.accommodation.accommodationO !== null) {
            const o = this.accommodation.accommodationO;
            this.firstStep = this.formBuilder.group({
               dateOfAccommodation: o.dateOfAccommodation,
               accommodationFacility: o.accommodationFacility,
               monthlyCosts: patchForDecimalNumber(o.monthlyCosts),
               fosterParentBankAccount: o.fosterParentBankAccount,
               referenceNumber: o.accommodationFacility?.accountReferenceNumber,
               pocketMoneyPercentage: o.pocketMoneyPercentage,
               pocketMoneyAmount: o.pocketMoneyAmount,
               pocketMoneyAndCostsSum: o.pocketMoneyAndCostsSum,
               subjectBank: o.subjectBank,
               subjectBankAccount: o.subjectBankAccount,
               accountNumber: o.accountNumber,
               accommodationPricingCodebook: o.accommodationPricingCodebook,
               supportCategory: o.supportCategoryPrice,
               accommodationPricingCodebookSupport: o.accommodationCategorySupportCodebook,
               firstName: [],
               lastName: [],
               jmbg: [],
               jmbp: [],
            });
            this.makePreparations();
            if (o.accommodationFacility?.accommodationPricingCodebook) {
               this.setPricingCodebookOptions(o.accommodationFacility.accommodationPricingCodebook);
            }
            this.secondStep = this.formBuilder.group({
               factualSituation: o.factualSituation,
               accommodationCostsSituation: o.accommodationCostsSituation,
               differenceSum: [],
               typeOfAccommodationFee: [],
            });
            this.thirdStep = this.formBuilder.group({
               decisionDocument: this.accommodation.type == 'O1' ? 0 : 1,
               decisionType: this.accommodation.decision === null ? '' : this.accommodation.decision,
               reason: this.accommodation.reason,
               terminationDate:
                  this.accommodation.terminationDate !== null
                     ? new Date(this.accommodation.terminationDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'))
                     : this.accommodation.terminationDate,
               lawyerFile: [''],
               fileName: [''],
               decisionMaker: this.accommodation.socialCase?.decisionMaker,
               decisionMakerDescription: this.accommodation.socialCase.decisionMakerDescription,
               decisionMakerName: this.accommodation.socialCase.decisionMakerName,
               decisionMakerTitle: this.accommodation.socialCase.decisionMakerTitle,
               fromStateBudget: o.fromStateBudget,
               terminationReason: this.accommodation.terminationReason,
            });
            if (o.brothersAndSisters !== null && o.brothersAndSisters !== undefined) {
               this.brothersAndSisters = o.brothersAndSisters;
            }
            this.showPreviewButton(this.accommodation.decision);
            this.secondStep.controls.typeOfAccommodationFee.setValue(o.typesOfAccommodationFees);

            this.selectedPensions = this.prepareAmountPensionsForShow(o.pensionObligations);
            this.selectedOtherRevenues = this.prepareAmountOtherRevenueForShow(o.otherObligations);
            this.selectedCostsParticipations = this.prepareAmountParticipationsForShow(o.patricipation);
         }
         if (this.accommodation.accommodationS5 !== undefined && this.accommodation.accommodationS5 !== null) {
            const s5 = this.accommodation.accommodationS5;

            this.firstStep.controls.firstName.setValue(s5.firstName);
            this.firstStep.controls.lastName.setValue(s5.lastName);
            this.firstStep.controls.jmbg.setValue(s5.jmbg);
            this.firstStep.controls.jmbp.setValue(s5.jmbp);
         }
         this.accommodationService.checkIfHaveActiveRight(this.caseId).subscribe(res => {
            if (res === true) {
               this.toastr.warning('SNACKBAR.USER_ALREADY_HAVE_RIGHT');
            }
         });
         this.calculateSum();
      }
      if (changes.caseName && changes.caseName.currentValue) {
         this.getDocumentsByTemplate();
      }
      if (changes.isDenial !== undefined) {
         this.checkIsDenial();
      }
      if (changes.complain !== undefined) {
         this.newComplain = changes.complain.currentValue;
      }
   }

   ngOnInit(): void {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
   }

   compareAccommodationFacility(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.name === object2.name;
   }
   compareTypesOfFee(object1: any, object2: any) {
      return object1.id === object2.id;
   }
   compareLoaded(object1: any, object2: any) {
      return object1 === object2;
   }
   calculateSum() {
      const sumValues =
         Number(
            this.firstStep.get('pocketMoneyAmount').value === null || this.firstStep.get('pocketMoneyAmount').value === ''
               ? 0
               : this.firstStep.get('pocketMoneyAmount').value.amount
         ) +
         getNumberFromString(
            this.firstStep.get('monthlyCosts').value === null || this.firstStep.get('monthlyCosts').value === '' ? 0 : this.firstStep.get('monthlyCosts').value
         );
      const otherSumValues = this.calcluateSecondStepAmounts();
      const differenceSum = sumValues - otherSumValues;
      this.secondStep.get('differenceSum').setValue(differenceSum < 0 ? 0 : patchForDecimalNumber(differenceSum));

      this.firstStep.get('pocketMoneyAndCostsSum').setValue(sumValues);
   }

   calcluateSecondStepAmounts() {
      let sum = 0;
      for (const selectedCost of this.selectedCostsParticipations) {
         sum += getNumberFromString(selectedCost.participationAmount);
      }
      for (const selectedCost of this.selectedOtherRevenues) {
         sum += getNumberFromString(selectedCost.revenueAmount);
      }
      for (const selectedCost of this.selectedPensions) {
         sum += getNumberFromString(selectedCost.pensionAmount);
      }
      return sum;
   }

   addPensionInfo() {
      const dialogRef = this.dialog.open(PensionLiabilitiesComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.selectedPensions.push(
               new FormsSOPension(
                  data.typesOfPensionCodebook,
                  data.branchesPioFundCodebook ? data.branchesPioFundCodebook : null,
                  data.checkNumber ? data.checkNumber : null,
                  data.pensionAmount,
                  data.startDate
               )
            );
            this.selectedPensions = [...this.selectedPensions];
            this.calculateSum();
         }
      });
   }
   getDocumentsByTemplate() {
      if (this.caseData !== undefined && this.caseData != null) {
         this.alfrescoService.getDocumentTemplates(this.caseData.socialCaseClassificationCodebook.oldKindMark).subscribe(result => {
            this.documentOptions = result;
         });
      }
   }

   deletePension(i: any) {
      this.translate.get('WARNING_DIALOG.OBLIGATION_DELETE.MESSAGE_PENSION').subscribe((res: string) => {
         this.object.message = res;
      });
      this.translate.get('WARNING_DIALOG.OBLIGATION_DELETE.TITLE').subscribe((res: string) => {
         this.object.title = res;
      });
      this.object.action = ModalEventEnum.DELETE;
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: this.object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.DELETE && data.status) {
            this.selectedPensions.splice(i, 1);
            this.selectedPensions = [...this.selectedPensions];
            this.calculateSum();
         }
      });
   }

   addOtherRevenuesInfo() {
      const dialogRef = this.dialog.open(LiabilitiesPerOtherRevenueComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.selectedOtherRevenues.push(
               new FormsSOOtherRevenue(
                  data.revenueKind,
                  data.branchesPioFundCodebook ? data.branchesPioFundCodebook : null,
                  data.checkNumber ? data.checkNumber : null,
                  data.revenueAmount,
                  data.startDate
               )
            );
            this.selectedOtherRevenues = [...this.selectedOtherRevenues];
            this.calculateSum();
         }
      });
   }

   deleteRevenue(i: any) {
      this.translate.get('WARNING_DIALOG.OBLIGATION_DELETE.MESSAGE_OTHER_REVENUE').subscribe((res: string) => {
         this.object.message = res;
      });
      this.translate.get('WARNING_DIALOG.OBLIGATION_DELETE.TITLE').subscribe((res: string) => {
         this.object.title = res;
      });
      this.object.action = ModalEventEnum.DELETE;
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: this.object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.DELETE && data.status) {
            this.selectedOtherRevenues.splice(i, 1);
            this.selectedOtherRevenues = [...this.selectedOtherRevenues];
            this.calculateSum();
         }
      });
   }

   addCostSharingInfo() {
      const dialogRef = this.dialog.open(CostsParticipationComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.selectedCostsParticipations.push(
               new FormsSOCostsParticipation(
                  data.fullName,
                  data.jmbg,
                  data.typeOfParticipation,
                  data.participationAmount,
                  data.place,
                  data.pttNumber,
                  data.address,
                  data.startDate
               )
            );
            this.selectedCostsParticipations = [...this.selectedCostsParticipations];
            this.calculateSum();
         }
      });
   }

   deleteCostsParticipants(i: any) {
      this.translate.get('WARNING_DIALOG.PARTICIPATION_DELETE.MESSAGE').subscribe((res: string) => {
         this.object.message = res;
      });
      this.translate.get('WARNING_DIALOG.PARTICIPATION_DELETE.TITLE').subscribe((res: string) => {
         this.object.title = res;
      });
      this.object.action = ModalEventEnum.DELETE;
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: this.object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.DELETE && data.status) {
            this.selectedCostsParticipations.splice(i, 1);
            this.selectedCostsParticipations = [...this.selectedCostsParticipations];
            this.calculateSum();
         }
      });
   }

   saveFormSO() {
      const formO = this.createAccommodationOObject(false);
      this.accommodationService.saveFormO(formO, this.caseId).subscribe(
         res => {
            this.idAccomodation = res.id;
            this.firstStep.markAsPristine();
            this.secondStep.markAsPristine();
            this.thirdStep.markAsPristine();
            this.toastr.success('SNACKBAR.SOLUTION_SAVED');
         },
         error => {
            manageError(this.toastr, ErrorType.SAVE_DATA);
         }
      );
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.thirdStep.get('lawyerFile').setValue(this.fileToUpload);
         this.thirdStep.get('fileName').setValue(this.fileToUpload.name);
      }
   }

   showPreviewButton(selection: any) {
      if (selection !== null && selection !== undefined && selection.id === 1) {
         this.showPreview = true;
         this.thirdStep.controls.fileName.clearValidators();
         this.thirdStep.controls.fileName.setErrors(null);
         this.thirdStep.controls.fileName.disable();
      } else {
         this.showPreview = false;
         this.thirdStep.controls.fileName.setValidators(Validators.required);
         this.thirdStep.controls.fileName.enable();
      }

      if (selection?.id === 3) {
         this.thirdStep.controls.terminationReason.enable();
         this.thirdStep.controls.terminationReason.setValidators(Validators.required);
         if (this.terminationReasons.length == 0) {
            this.codebookService.getTerminationReasonByType(this.caseData?.socialCaseClassificationCodebook?.oldKindMark).subscribe(res => {
               this.terminationReasons = res;
            });
         }
      } else {
         this.thirdStep.controls.terminationReason.disable();
         this.thirdStep.controls.terminationReason.setErrors(null);
         this.thirdStep.controls.terminationReason.setValue(null);
         this.thirdStep.controls.terminationReason.clearValidators();
      }
   }

   handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const body = {
         documentId: doc.documentId,
         fileName: doc.name,
         caseId: this.caseId === undefined ? null : this.caseId,
      };
      this.alfrescoService.downloadDocumentByUrl(body);
   }

   createAccommodationOObject(finish: boolean) {
      const o = {
         id: this.idAccomodation,
         dateOfAccommodation: this.firstStep.controls.dateOfAccommodation.value,
         accommodationFacility: this.firstStep.controls.accommodationFacility.value === '' ? null : this.firstStep.controls.accommodationFacility.value,
         accommodationPricingCodebook: this.withSupport ? null : this.firstStep.controls.accommodationPricingCodebook.value,
         monthlyCosts: getNumberFromString(this.firstStep.controls.monthlyCosts.value),
         fosterParentBankAccount: this.firstStep.controls.fosterParentBankAccount.value,
         referenceNumber: this.firstStep.controls.referenceNumber.value,
         pocketMoneyPercentage: this.firstStep.controls.pocketMoneyPercentage.value,
         pocketMoneyAmount: this.firstStep.controls.pocketMoneyAmount.value,
         pocketMoneyAndCostsSum: this.firstStep.controls.pocketMoneyAndCostsSum.value,
         subjectBank: this.firstStep.controls.subjectBank.value,
         subjectBankAccount: this.firstStep.controls.subjectBankAccount.value,
         accountNumber: this.firstStep.controls.accountNumber.value,
         supportCategoryPrice: !this.withSupport ? null : this.firstStep.controls.supportCategory.value,
         accommodationCategorySupportCodebook: !this.withSupport ? null : this.firstStep.controls.accommodationPricingCodebookSupport.value,
         factualSituation: this.secondStep.controls.factualSituation.value,
         accommodationCostsSituation: this.secondStep.controls.accommodationCostsSituation.value,
         pensionObligations: this.prepareAmountPensions(),
         otherObligations: this.prepareAmountOtherRevenue(),
         patricipation: this.prepareAmountParticipations(),
         finish,
         decisionTypeCodebook: this.thirdStep.controls.decisionType.value === '' ? null : this.thirdStep.controls.decisionType.value,
         reason: this.thirdStep.controls.reason.value,
         terminationDate: this.thirdStep.controls.terminationDate.value,
         decisionDocument: this.thirdStep.controls.decisionDocument.value,
         typesOfAccommodationFees: this.secondStep.controls.typeOfAccommodationFee.value,
         brothersAndSisters: this.brothersAndSisters,
         decisionMaker: this.thirdStep.value.decisionMaker ? this.thirdStep.value.decisionMaker : null,
         decisionMakerDescription: this.thirdStep.value.decisionMaker?.id === 2 ? this.thirdStep.value.decisionMakerDescription : '',
         decisionMakerName: this.thirdStep.value.decisionMakerName ? this.thirdStep.value.decisionMakerName : '',
         decisionMakerTitle: this.thirdStep.value.decisionMakerTitle ? this.thirdStep.value.decisionMakerTitle : '',
         fromStateBudget: this.thirdStep.value.fromStateBudget ? this.thirdStep.value.fromStateBudget : false,
         terminationReason: this.thirdStep.value.terminationReason !== '' ? this.thirdStep.value.terminationReason : null,
      };
      return o;
   }

   async submitConclusion() {
      this.isSubmited = true;
      const formO = this.createAccommodationOObject(true);
      const addNewRecordRequered = await this.addNewRecord(this.thirdStep.controls.decisionType.value.id);
      if (addNewRecordRequered) {
         if (this.caseData?.socialCaseClassificationCodebook?.oldKindMark === 'PS') {
            this.submitFammilyAccommodation(formO);
         } else {
            this.submitInstitutionAccommodation(formO);
         }
      } else {
         this.submitButton.disabled = false;
      }
   }

   stepSelectionChange(event: any) {
      if (event.selectedIndex === 2) {
         const sum = this.calcluateSecondStepAmounts();

         if (this.firstStep.controls.pocketMoneyAndCostsSum.value - sum >= 0) {
            this.thirdStep.controls.decisionDocument.setValue(0);
         } else {
            this.thirdStep.controls.decisionDocument.setValue(1);
         }
      }
   }

   submitFammilyAccommodation(formO: any) {
      if (formO.decisionTypeCodebook.id === 1) {
         this.accommodationService.createAccommodationFammily(formO, this.caseId).subscribe(
            res => {
               this.idAccomodation = res.caseAccomodationId;
               this.expedition.send(
                  this.expeditionObject,
                  { documentId: res.documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId },
                  'acc'
               );
            },
            error => {
               manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
               this.submitButton.disabled = false;
               this.isSubmited = false;
            }
         );
      } else {
         this.submitRejectedDecision(formO);
      }
   }

   submitInstitutionAccommodation(formO: any) {
      if (formO.decisionTypeCodebook.id === 1) {
         this.accommodationService.createAccommodationDecisionO(formO, this.caseId).subscribe(
            res => {
               this.idAccomodation = res.id;
               this.expedition.send(
                  this.expeditionObject,
                  { documentId: res.documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId },
                  'acc'
               );
            },
            error => {
               manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
               this.submitButton.disabled = false;
               this.isSubmited = false;
            }
         );
      } else {
         this.submitRejectedDecision(formO);
      }
   }

   submitRejectedDecision(formO: any) {
      this.accommodationService.saveFormO(formO, this.caseId).subscribe(
         res => {
            if (res) {
               this.idAccomodation = res.id;
               this.caseService
                  .addConclusionFinishCase(this.thirdStep.get('lawyerFile').value, this.subjectId, this.caseData.id)
                  .subscribe((documentId: string) => {
                     this.expedition.send(
                        this.expeditionObject,
                        { documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId },
                        'acc'
                     );
                  });
               this.toastr.success('SNACKBAR.SOLUTION_SAVED');
            } else {
               this.toastr.warning('SNACKBAR.USER_NOT_HAVE_RIGHT');
               this.submitButton.disabled = false;
               this.isSubmited = false;
            }
         },
         error => {
            manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
            this.submitButton.disabled = false;
         }
      );
   }

   preview() {
      const formO = this.createAccommodationOObject(true);
      if (this.caseData?.socialCaseClassificationCodebook?.oldKindMark === 'PS') {
         this.accommodationService.previewFammilyAccommodation(formO, this.caseId);
      } else {
         this.accommodationService.previewO(formO, this.caseId);
      }
   }

   accommodationFacilityChange() {
      this.facilityInput.nativeElement.setSelectionRange(0, 0);
      const facility = this.firstStep.get('accommodationFacility').value;
      if (facility?.accountReferenceNumber !== null) {
         this.firstStep.get('referenceNumber').setValue(facility?.accountReferenceNumber);
         this.setPricingCodebookOptions(facility?.accommodationPricingCodebook);
      } else {
         this.firstStep.get('referenceNumber').setValue('');
         this.setPricingCodebookOptions([]);
      }
      this.firstStep.controls.accommodationPricingCodebook.setValue(null);
   }
   changeSupport() {
      this.firstStep.controls.monthlyCosts.setValue(0);
      this.firstStep.controls.accommodationPricingCodebookSupport.setValue(null);
   }

   accommodationPricingCodebookChange(event: any) {
      if (!this.withSupport) {
         this.firstStep.get('monthlyCosts').setValue(patchForDecimalNumber(event.value?.price));
      } else {
         var sum = (this.firstStep.controls.supportCategory.value.amountMonth * (1 + event.value?.increase / 100)).toFixed(2);
         this.firstStep.get('monthlyCosts').setValue(patchForDecimalNumber(sum));
      }
      this.calculateSum();
   }
   checkIsDenial() {
      if (this.isDenial && Number(this.decisionTypes.length) === 5) {
         this.decisionTypes.splice(0, 1);
      }
   }

   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
   }

   addFamilyInfo() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            const {
               data: { firstName, lastName, jmbg },
            } = obj;

            const memberData = {
               firstName,
               lastName,
               jmbg,
            };

            const dialogRef1 = this.dialog.open(BrotherSisterFormComponent, {
               width: '1200px',
               panelClass: 'overlay-panel',
               data: {
                  memberData,
               },
            });
            dialogRef1.afterClosed().subscribe(result1 => {
               if (result1 !== undefined) {
                  if (result1.event !== EventEnum.CANCEL) {
                     this.brothersAndSisters = [...this.brothersAndSisters, result1.data];
                  }
               }
            });
         }
      });
   }
   deleteFamilyMember(i: any) {
      this.translate.get('WARNING_DIALOG.BROTHER_SISTER_DELETE.MESSAGE').subscribe((res: string) => {
         this.object.message = res;
      });
      this.translate.get('WARNING_DIALOG.BROTHER_SISTER_DELETE.TITLE').subscribe((res: string) => {
         this.object.title = res;
      });
      this.object.action = ModalEventEnum.DELETE;
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: this.object,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.DELETE && data.status) {
            this.brothersAndSisters.splice(i, 1);
            this.brothersAndSisters = [...this.brothersAndSisters];
         }
      });
   }
   decisionMakerSelected(event: any) {
      if (event.value.id === 2) {
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TEXT').subscribe((res: string) => {
            this.thirdStep.controls.decisionMakerDescription.setValue(res);
         });
         this.translate.get('LAWYER.DECISION_MAKER_AUTHORIZATION_TITLE').subscribe((res: string) => {
            this.thirdStep.controls.decisionMakerTitle.setValue(res);
         });
      } else {
         this.thirdStep.controls.decisionMakerDescription.setValue('');
         this.thirdStep.controls.decisionMakerTitle.setValue(event.value.title);
      }
   }

   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.FACILITY: {
            return /[0-9]/g.test(filterValue)
               ? sortByFirstLetter(
                    this.accommodationFacilityOptions.filter((option: any) => String(option.id).toLowerCase().includes(filterValue)),
                    filterValue,
                    'id'
                 )
               : sortByFirstLetter(
                    this.accommodationFacilityOptions.filter((option: any) => option.name.toLowerCase().includes(filterValue)),
                    filterValue,
                    'name'
                 );
         }
         default:
            break;
      }
   }

   async addNewRecord(decision: any): Promise<boolean> {
      if (decision === 1) {
         try {
            return await this.caseProblemRecordService.addNewRecord(this.subjectId, this.caseId, this.caseData?.caseKindCodebook?.kind, {
               fromDate: this.firstStep.controls.dateOfAccommodation.value,
               amount: getNumberFromString(this.secondStep.controls.differenceSum.value),
            });
         } catch (error) {
            return false;
         }
      }
      return true;
   }

   makePreparations() {
      if (
         this.firstStep.value.dateOfAccommodation == null ||
         this.firstStep.value.dateOfAccommodation == undefined ||
         this.firstStep.value.dateOfAccommodation == ''
      ) {
         return;
      }
      this.codebookService.getPocketMoneyAmountCodebookValidFrom(new Date(this.firstStep.value.dateOfAccommodation).getTime()).subscribe(result => {
         this.pocketMoneyAmountOptions = result;
         this.pocketMoneyAmountOptions.unshift(null);
      });

      if (this.caseData?.socialCaseClassificationCodebook.oldKindMark === 'PS') {
         return;
      }

      if (
         this.firstStep.value.accommodationFacility == null ||
         this.firstStep.value.accommodationFacility == undefined ||
         this.firstStep.value.accommodationFacility == ''
      ) {
         return;
      }

      if (this.firstStep.value.accommodationFacility.hasSupportCategory === false) {
         this.withSupport = false;
         this.accommodationFacilityChange();
         return;
      }
      this.supportPriceCodebook = this.filterSupportObjectsByTypeAndDates(
         this.firstStep.value.accommodationFacility.supportCategoryPrice,
         new Date(this.firstStep.value.dateOfAccommodation)
      );
      if (this.supportPriceCodebook == null || this.supportPriceCodebook.length === 0) {
         this.withSupport = false;
         return;
      }
      this.withSupport = true;
      if (this.accommodationPricingCodebookSupportOptions.length == 0) {
         this.codebookService.getAccommodationCategoryCodebookSupport().subscribe(result => {
            this.accommodationPricingCodebookSupportOptions = result;
         });
      }
   }

   filterSupportObjectsByTypeAndDates(objects: SupportCategoryPrice[], date1: Date): SupportCategoryPrice[] {
      const filteredMap: Map<number, SupportCategoryPrice[]> = new Map();

      // Group objects by type
      const groupedByType = objects.reduce((acc, obj) => {
         acc[obj.supportCategoryCodebook.id] = acc[obj.supportCategoryCodebook.id] || [];
         acc[obj.supportCategoryCodebook.id].push(obj);
         return acc;
      }, {} as { [key: number]: SupportCategoryPrice[] });

      // Filter objects by type and date range
      for (const type in groupedByType) {
         const filteredObjects = groupedByType[type]
            .filter(
               obj =>
                  (obj.validTo != null && new Date(obj.validFrom) <= date1 && new Date(obj.validTo) >= date1) ||
                  (obj.validTo == null && new Date(obj.validFrom) <= date1)
            ) // Filter by date range
            .reduce((acc, obj) => {
               if (!acc || obj.id > acc.id) {
                  return obj; // Keep the object with the highest id
               }
               return acc;
            }, null as SupportCategoryPrice | null);

         if (filteredObjects) {
            filteredMap.set(parseInt(type), [filteredObjects]);
         }
      }

      return Array.from(filteredMap.values()).reduce((acc, val) => acc.concat(val), []);
   }

   prepareAmountPensions() {
      var pensions = [...this.selectedPensions];
      pensions.forEach(pension => {
         pension.pensionAmount = getNumberFromString(pension.pensionAmount);
      });
      return pensions;
   }
   prepareAmountOtherRevenue() {
      var otherRevenues = [...this.selectedOtherRevenues];
      otherRevenues.forEach(otherRevenue => {
         otherRevenue.revenueAmount = getNumberFromString(otherRevenue.revenueAmount);
      });
      return otherRevenues;
   }
   prepareAmountParticipations() {
      var participations = [...this.selectedCostsParticipations];
      participations.forEach(participation => {
         participation.participationAmount = getNumberFromString(participation.participationAmount);
      });
      return participations;
   }

   prepareAmountPensionsForShow(pensionsData) {
      var pensions = [...pensionsData];
      pensions.forEach(pension => {
         pension.pensionAmount = patchForDecimalNumber(pension.pensionAmount);
      });
      return pensions;
   }
   prepareAmountOtherRevenueForShow(otherRevenuesData) {
      var otherRevenues = [...otherRevenuesData];
      otherRevenues.forEach(otherRevenue => {
         otherRevenue.revenueAmount = patchForDecimalNumber(otherRevenue.revenueAmount);
      });
      return otherRevenues;
   }
   prepareAmountParticipationsForShow(participationsCostsData) {
      var participations = [...participationsCostsData];
      participations.forEach(participation => {
         participation.participationAmount = patchForDecimalNumber(participation.participationAmount);
      });
      return participations;
   }

   setPricingCodebookOptions(data) {
      if (
         data.length == 0 ||
         this.firstStep.value.dateOfAccommodation == null ||
         this.firstStep.value.dateOfAccommodation == undefined ||
         this.firstStep.value.dateOfAccommodation == ''
      ) {
         this.accommodationPricingCodebookOptions = [];
      } else {
         this.accommodationPricingCodebookOptions = this.filterPriceObjectsByTypeAndDates(data, new Date(this.firstStep.value.dateOfAccommodation));
      }
   }
   filterPriceObjectsByTypeAndDates(objects: CategoryPrice[], date1: Date): CategoryPrice[] {
      const filteredMap: Map<number, CategoryPrice[]> = new Map();

      // Group objects by type
      const groupedByType = objects.reduce((acc, obj) => {
         acc[obj.accommodationCategoryCodebook.id] = acc[obj.accommodationCategoryCodebook.id] || [];
         acc[obj.accommodationCategoryCodebook.id].push(obj);
         return acc;
      }, {} as { [key: number]: CategoryPrice[] });

      // Filter objects by type and date range
      for (const type in groupedByType) {
         const filteredObjects = groupedByType[type]
            .filter(
               obj =>
                  (obj.validTo != null && new Date(obj.validFrom) <= date1 && new Date(obj.validTo) >= date1) ||
                  (obj.validTo == null && new Date(obj.validFrom) <= date1)
            ) // Filter by date range
            .reduce((acc, obj) => {
               if (!acc || obj.id > acc.id) {
                  return obj; // Keep the object with the highest id
               }
               return acc;
            }, null as CategoryPrice | null);

         if (filteredObjects) {
            filteredMap.set(parseInt(type), [filteredObjects]);
         }
      }

      return Array.from(filteredMap.values()).reduce((acc, val) => acc.concat(val), []);
   }
}
