<div
  [ngClass]="{
    holder: true,
    'holder--1-col-with-action': kind === '1-col-with-action',
    'holder--2-cols': kind === '2-cols',
    'holder--2-cols-1-2': kind === '2-cols-1-2',
    'holder--2-cols-with-action': kind === '2-cols-with-action',
    'holder--3-cols': kind === '3-cols',
    'holder--3-cols-1-1-2': kind === '3-cols-1-1-2',
    'holder--3-cols-2-1-1': kind === '3-cols-2-1-1',
    'holder--4-cols': kind === '4-cols'
  }"
>
  <ng-content></ng-content>
</div>
