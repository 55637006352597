<mat-card class="card">
  <card-header title="{{ 'SUBJECT_FAMILY.TITLE' | translate }}">
     <span
      [matTooltip]="
        isModificationDisabled
          ? addMemberText
          : addMemberTextError
      "
      ><button *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni && !isAdmin"
        mat-flat-button
        color="primary"
        (click)="addMember()"
        [disabled]="isModificationDisabled"
      >
        {{ 'SUBJECT_FAMILY.ADD_MEMBER' | translate }}
      </button>
    </span>
  </card-header
  >
  <ul class="family__list" *ngIf="members">
    <li *ngFor="let member of members" class="family__item">
      <family-member-card
        [firstName]="member.firstName"
        [lastName]="member.lastName"
        [regId]="member.registrationId"
        [originalFamilyDossier]="member.familyDossier"
        [relationToDossierHolder]="member.relationship.title"
        [isEditDisabled]="isModificationDisabled"
        [subjectId]="member.subjectIdentity.subjectId"
        (editedUser)="editedUser()"
        (removedFamilyMember)="editedUser()"
      ></family-member-card>
    </li>
  </ul>
  <p class="family-empty" *ngIf="members.length === 0">
    <mat-icon class="family-empty__icon">info</mat-icon>
    {{ 'SUBJECT_FAMILY.NO_RESULTS' | translate }}
  </p>
</mat-card>
