/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StatisticsService } from 'src/services/statistics.service';
import { CodebookService } from 'src/services/codebook.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/services/user.service';
import { map, startWith } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';

enum AutocompleteFilterEnum {
  CENTERS = 'centers',
}

@Component({
  selector: 'nsp-stats',
  templateUrl: './nsp-stats.component.html',
  styleUrls: ['./nsp-stats.component.scss']
})
export class NspStatsComponent implements OnInit {
  nspStatsForm: FormGroup;
  recordOptions = [
    { id: 1, name: 'Сви корисници који су носиоци НСП' },
    { id: 2, name: 'Сви корисници који су носиоци НСП са њиховим члановима домаћинства' },
    { id: 3, name: 'Сви корисници који су радно способни' },
    { id: 4, name: 'Сви корисници који нису радно способни' },
  ];

  centers = [];
  filteredAllCenters: Observable<any>;
  loggedIn: any;
  isMainCenterBelgrade = false;
  btnDisabled = false;
  genderOptions = [];
  isUserDrugostepeni = false;
  maxDate: Date = new Date(2999, 11, 31);

  constructor(
    private formBuilder: FormBuilder,
    private statisticsService: StatisticsService,
    private codebookService: CodebookService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private userService: UserService) {
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.isMainCenterBelgrade = (this.loggedIn?.csrId === '1') ? true : false;
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    if (this.isMainCenterBelgrade) {
      this.codebookService.getAllSubcenters().subscribe(result => {
        this.centers = result;
        this.filteredAllCenters = this.nspStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );      });
    }
    if(this.isUserDrugostepeni){
      this.codebookService.getCsrCodebook().subscribe((result) => {
        this.centers = result;
        this.filteredAllCenters = this.nspStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );
     });
    }
    this.statisticsService.getBtnStatus().subscribe((status) => {
      this.btnDisabled = status;
    });
    this.btnDisabled = false;
  }

  ngOnInit(): void {
    this.resetForm();
  }
  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name : objectValue;
  }
  autoSelectCenter(){
    autoSelect(this.centers, 'center', 'name', this.nspStatsForm);
  }
  /**
   * Send request and generate Excell report
   */
  sendRequest(form: any) {
    let filename = '';
    this.translate.get('STATISTICS.NSP_STATS.TITLE').subscribe((res: string) => {
      filename = res;
    });
    const dto = this.createDto();
    this.statisticsService.getNspStatistics(dto, filename);
  }

  createDto() {
    const form = this.nspStatsForm;
    const csrId = this.isMainCenterBelgrade ? form.value.center.id : this.loggedIn.csrId;
    const queryId = form.value.record.id;
    const dateFrom = form.value.dateFrom;
    const dateTo = form.value.dateTo;
  return {
      csrId,
      queryId,
      dateFrom,
      dateTo
    };
  }

  resetForm() {
    this.nspStatsForm = this.formBuilder.group({
      record: ['', [Validators.required]],
      center: [''],
      dateFrom: [''],
      dateTo: [''],
      gender: [null, []],
      ageGroup: [null, []],
    });
  }
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
       case AutocompleteFilterEnum.CENTERS:
          return sortByFirstLetter(this.centers.filter((option) =>
             option.name.toLowerCase().includes(filterValue)), filterValue, 'name');
       default:
          break;
    }
 }
}
