import { Component, OnInit } from '@angular/core';
import { FosterChildService } from 'src/services/foster-child.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-foster-child-details',
  templateUrl: './foster-child-details.component.html',
  styleUrls: ['./foster-child-details.component.scss']
})
export class FosterChildDetailsComponent implements OnInit {
  fosterChildId: any;
  fosterChild: any;

  displayedColumnsBrothersAndSisters: string[] = [
    'information',
  ];

  displayedColumnsGuardians: string[] = [
    'information',
    'relationshipCodebook'
  ];

  constructor(
    private route: ActivatedRoute,
    private fosterChildService: FosterChildService) {
    this.route.params.subscribe((params) => {
      this.fosterChildId = params.id;
    });
    this.getFosterChild();
  }

  ngOnInit(): void {
  }

  getFosterChild() {
    this.fosterChildService.findById(this.fosterChildId).subscribe((res) => {
      if (res.fatherInformation !== null) {
        if (res.fatherInformation?.addictions?.length > 0) {
            res.fatherInformation.addictions.sort((a, b) => a.addictionCodebook.id - b.addictionCodebook.id);
        }
        res.fatherInformation.dateOfBirth =
          res.fatherInformation?.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      }

      if (res.motherInformation !== null) {
        if (res.motherInformation?.addictions?.length > 0) {
            res.motherInformation.addictions.sort((a, b) => a.addictionCodebook.id - b.addictionCodebook.id);
        }
        res.motherInformation.dateOfBirth =
          res.motherInformation?.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      }

      if (res.healthDeviations?.length > 0) {
        res.healthDeviations.sort((a, b) => a.healthDeviationsCodebook.id - b.healthDeviationsCodebook.id );
      }
      if (res.psychophysicalDevelopmentDeviations?.length > 0) {
        res.psychophysicalDevelopmentDeviations.sort((a, b) =>
                    a.psychophysicalDevelopmentDeviationsCodebook.id - b.psychophysicalDevelopmentDeviationsCodebook.id );
      }

      res.dateOfBirth =
        res?.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');

      this.fosterChild = res;
    });
  }


    sortByAddictions( a, b ) {
      if ( a.addictionCodebook.id < b.addictionCodebook.id ){
        return -1;
      }
      if ( a.addictionCodebook.id > b.addictionCodebook.id ){
        return 1;
      }
      return 0;
    }
}
