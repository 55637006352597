/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})
export class AddUsersComponent implements OnInit {
  usersForm: FormGroup;
  relationshipOptions: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddUsersComponent>,
    private formBuilder: FormBuilder,
    private codebookService: CodebookService) {

  }

  ngOnInit(): void {
    this.getRelationshipCodebook();

    this.usersForm = this.formBuilder.group({
      firstAndLastName: ['', Validators.required],
      relationship: [''],
      phone: ['', [
        Validators.minLength(9),
        Validators.maxLength(13),
        Validators.pattern('^[0-9]*$'),
      ]]
    });
  }

  addNewUser() {
    console.log('Dodavanje korisnika u sacinjavanju plana ');
    this.dialogRef.close({ event: 'Create', data: this.usersForm.value });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  getRelationshipCodebook() {
    this.codebookService.getRelationshipCodebook().subscribe(res => {
      this.relationshipOptions = res;
    });
  }

}
