<div class="modal">
  <form class="form" [formGroup]="form">
    <h4 class="modal__title">
      {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.TITLE_DIALOG' | translate }}
    </h4>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'GLOBAL_ADMIN.CHANGE_REG_ID.OLD_REG_ID' | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        readonly
        formControlName="oldRegId"
        autocomplete="off"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'GLOBAL_ADMIN.CHANGE_REG_ID.NEW_REG_ID' | translate
      }}</mat-label>
      <input
        matInput
        type="text"
        formControlName="newRegId"
        autocomplete="off"
      />
      <mat-error *ngIf="form.get('newRegId').hasError('required')">
        {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <footer class="modal__footer">
      <button
        *ngIf="action !== 'allocationError'"
        mat-stroked-button
        color="primary"
        mat-dialog-close
        (click)="closeDialog()"
        class="modal-footer__button modal-footer__button--secondary"
      >
        {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.CANCEL' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="modal-footer__button modal-footer__button--primary"
        (click)="clickSubmit()"
        [disabled]="!form.valid"
      >
        {{ 'GLOBAL_ADMIN.CHANGE_REG_ID.SUBMIT' | translate }}
      </button>
    </footer>
  </form>
</div>
