<mat-dialog-content>
    <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
        [mat-dialog-close]="true"><span class="material-icons">close</span>
    </button>

    <div class="container">
        <form flex [formGroup]="sendIntoPlannerForm" (ngSubmit)="send()">
            <h2>{{ 'SEND_INTO_PLANNER.TITLE' | translate }}</h2>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'SEND_INTO_PLANNER.REASON' | translate }}</mat-label>
                    <mat-select formControlName="reason">
                        <mat-option *ngFor="let reason of reasonsOptions" [value]="reason">
                            {{ reason.reason }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
                    class="form-footer__button form-footer__button--secondary">
                    {{ 'DIALOG.CANCEL' | translate }}
                </button>
                <button mat-flat-button color="primary" type="submit"
                    class="form-footer__button form-footer__button--primary" [disabled]="!sendIntoPlannerForm.valid">
                    {{ 'DIALOG.CONFIRM' | translate }}
                </button>
            </form-footer>
        </form>
        <br />
    </div>
</mat-dialog-content>