import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ModalSizeEnum } from 'src/types';
import { InputParticipationSkComponent } from '../input-participation-sk/input-participation-sk.component';
interface SelectOptions {
   value: any;
   viewValue: string;
   type:string[]
}
@Component({
   selector: 'app-input-subject-in-sk-form',
   templateUrl: './input-subject-in-sk-form.component.html',
   styleUrls: ['./input-subject-in-sk-form.component.scss']
})
export class InputSubjectInSkFormComponent implements OnInit {

   externalOptions: SelectOptions[] = [
      { value: '1', viewValue: 'Основна новчана социјална помоћ', type: ['NSP'] },
      { value: '2', viewValue: 'Увећана новчана социјална помоћ.', type: ['NSP'] },
      { value: '3', viewValue: 'Увећана новчана социјална помоћ по основу једнородитељства', type: ['NSP'] },
      { value: '0', viewValue: 'Посебна новчана накнада', type: ['PNN'] },
      { value: 'PORSME', viewValue: 'Породични смештај', type: ['DS', 'PS', 'SPO', 'STAR', 'USVO', 'NADZ'] },
      { value: 'USTSME', viewValue: 'Смештај у установи', type: ['DS', 'PS', 'SPO', 'STAR', 'USVO', 'NADZ'] },
      { value: '02', viewValue: 'НУС - УВЕЋАНА, СОЦИЈАЛА', type: ['DZP'] },
      { value: '31', viewValue: 'НУП - ПЕНЗ. I ГРУПА', type: ['DZP'] },
      { value: '01', viewValue: 'НОС - ОСНОВНА', type: ['DZP'] },
      { value: '32', viewValue: 'НУП - ПЕНЗ. II ГРУПА', type: ['DZP'] },
      { value: '33', viewValue: 'НУП - ПЕНЗ. III ГРУПА', type: ['DZP'] },
      { value: '34', viewValue: 'НУП - ПЕНЗ. IV ГРУПА', type: ['DZP'] },
      { value: '04', viewValue: 'НРОД - ДЕЦА I ГР - 60%.', type: ['DZP'] },
      { value: '05', viewValue: 'НРОД - ДЕЦА II ГР - 40%', type: ['DZP'] },
      { value: '06', viewValue: 'НРОД - ДЕЦА III ГР - 30%', type: ['DZP'] },
      { value: '09', viewValue: 'НРОД - ДЕЦА III ГР - ВОЈ.', type: ['DZP'] },
      { value: '10', viewValue: 'НУД - ДЕЦА I ГР. - 60%', type: ['DZP'] },
      { value: '11', viewValue: 'НУД - ДЕЦА II ГР - 40%', type: ['DZP'] },
      { value: '12', viewValue: 'НУД - ДЕЦА III ГР - 30%', type: ['DZP'] },
      { value: '15', viewValue: 'НУД - ДЕЦА III ГР - ВОЈ.', type: ['DZP'] },
      { value: '35', viewValue: 'ПЕНЗИОНЕРИ САМОСТАЛНЕ И П', type: ['DZP'] },
      { value: '16', viewValue: 'НРОД ДЕЦА САМОС. 60%', type: ['DZP'] },
      { value: '17', viewValue: 'НРОД ДЕЦА САМОСТ - 50%', type: ['DZP'] },
      { value: '18', viewValue: 'НРОД ДЕЦА САМОСТ - 40%', type: ['DZP'] },
      { value: '19', viewValue: 'НРОД ДЕЦА САМОСТ 35%', type: ['DZP'] },
      { value: '21', viewValue: 'НУД ДЕЦА САМОСТ 60%', type: ['DZP'] },
      { value: '22', viewValue: 'НУД ДЕЦА САМОСТ 50%', type: ['DZP'] },
      { value: '23', viewValue: 'НУД ДЕЦА САМОСТ 40%', type: ['DZP'] },
      { value: '24', viewValue: 'НУД ДЕЦА САМОСТ 35%', type: ['DZP'] },
      { value: '36', viewValue: 'НУП - I ГРУПА 100% ВОЈНИ', type: ['DZP'] },
      { value: '37', viewValue: 'НУП - I ГРУПА 80% ВОЈНИ', type: ['DZP'] },
      { value: '38', viewValue: 'НУП - II ГРУПА 60% ВОЈНИ', type: ['DZP'] },
      { value: '07', viewValue: 'НРОД - II ГР. 60% ВОЈНИ', type: ['DZP'] },
      { value: '08', viewValue: 'НРОД - II ГР. 40% ВОЈНИ', type: ['DZP'] },
      { value: '13', viewValue: 'НУД - II ГР. 60% ВОЈНИ', type: ['DZP'] },
      { value: '14', viewValue: 'НУД - II ГР. 40% ВОЈНИ', type: ['DZP'] },
      { value: 'OSPOS', viewValue: 'Помоћ за оспособљавање за рад', type: ['OZR'] },
      { value: 'STAR', viewValue: 'Старатељство', type: ['DS', 'PS', 'SPO', 'STAR', 'USVO', 'NADZ'] },
      { value: 'HRAN', viewValue: 'Хранитељство', type: ['DS', 'PS', 'SPO', 'STAR', 'USVO', 'NADZ'] },
      { value: 'USV', viewValue: 'Усвојење', type: ['DS', 'PS', 'SPO', 'STAR', 'USVO', 'NADZ'] },
      { value: 'TEST', viewValue: 'Једнократна помоћ', type: ['L'] }

   ];

   socialMapSubjectForm: FormGroup;
   participations: any = []; // ucesce
   participates: any = []; // utice
   dataSource = new MatTableDataSource<any>([]);
   dataSource1 = new MatTableDataSource<any>([]);
   jmbgList :string[]=[];

   displayedColumns: string[] = [
      'firstName',
      'lastName',
      'jmbg',
      'relationship',
      'actions'
   ];
   constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<any>, public dialog: MatDialog) { }

   ngOnInit(): void {
      this.socialMapSubjectForm = this.formBuilder.group({
         rightCode: [null, Validators.required]
      });
   }
   sendRequest() {
      this.dialogRef.close({ event: 'success', data: this.createBody() });
   }

   closeDialog() {
      this.dialogRef.close({ event: 'cancel' });
   }

   removeParticipations(index: number) {
      this.jmbgList=this.jmbgList.filter(jmbg=>jmbg!==this.participations[index].jmbg)
      this.participations.splice(index, 1);
      this.dataSource.data = this.participations;
   }


   addParticipations() {
      const dialogRef = this.dialog.open(InputParticipationSkComponent, {
         disableClose: true,
         panelClass:'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: 'SUBJECT_SK.ADD_PARTICIPATION'
      });
      dialogRef.componentInstance.jmbgList=this.jmbgList
      dialogRef.afterClosed().subscribe((result) => {
         if (result.event !== 'cancel') {
            this.participations.push(result.data);
            this.jmbgList.push(result.data.jmbg)
            this.dataSource.data = this.participations;
         }
      });

   }

   removeParticipates(index: number) {
      this.jmbgList=this.jmbgList.filter(jmbg=>jmbg!==this.participates[index].jmbg)
      this.participates.splice(index, 1);
      this.dataSource1.data = this.participates;
   }

   addParticipates() {
      const dialogRef = this.dialog.open(InputParticipationSkComponent, {
         disableClose: true,
         panelClass:'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: 'SUBJECT_SK.ADD_PARTIPATES'
      });
      dialogRef.componentInstance.jmbgList=this.jmbgList
      dialogRef.afterClosed().subscribe((result) => {
         if (result.event !== 'cancel') {
            this.participates.push(result.data);
            this.jmbgList.push(result.data.jmbg)
            this.dataSource1.data = this.participates;
         }
      });

   }

   createBody() {
      const participationsDto = []; // ucesce

      this.participations.forEach((elem: any) => {
         const dto = {
            ime: elem.firstName,
            prezime: elem.lastName,
            jmbg: elem.jmbg,
            sifraSrodstva: elem.relationship.value
         };
         participationsDto.push(dto);
      });

      const participatesDto = []; // utice

      this.participates.forEach((elem: any) => {
         const dto = {
            ime: elem.firstName,
            prezime: elem.lastName,
            jmbg: elem.jmbg,
            sifraSrodstva: elem.relationship.value
         };
         participatesDto.push(dto);
      });
      const body = {
         sifraPrava: this.socialMapSubjectForm.value.rightCode,
         ucestvuje: participationsDto.length === 0 ? null : participationsDto,
         utice: participatesDto.length === 0 ? null : participatesDto
      };
      return body;
   }
}
