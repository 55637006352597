import { Injectable } from '@angular/core';
@Injectable({
   providedIn: 'root',
})
export class SystemInfo {
   browserInfo: string;
   osInfo: string;

   constructor() {}

   async getAllData(): Promise<string> {
      this.browserInfo = this.getBrowserInfo();
      this.osInfo = await this.getOSInfo();
      return 'Operating System: ' + this.osInfo + '\nBrowser: ' + this.browserInfo + '\n'+this.getLocaleInfo();
   }

   getBrowserInfo(): string {
      const userAgent = navigator.userAgent;
      const browserInfo = this.getBrowserNameAndVersion(userAgent);
      return browserInfo;
   }
   getBrowserNameAndVersion(userAgent: string): string {
      let browserInfo = '';
      let temp: RegExpExecArray | null;

      // Edge (based on Chromium)
      if ((temp = /\bEdg\/([\d.]+)/.exec(userAgent))) {
         browserInfo = `Microsoft Edge ${temp[1]}`;
      }
      // Opera
      else if ((temp = /\bOPR\/([\d.]+)/.exec(userAgent))) {
         browserInfo = `Opera ${temp[1]}`;
      }
      // Chrome
      else if ((temp = /\bChrome\/([\d.]+)/.exec(userAgent))) {
         browserInfo = `Chrome ${temp[1]}`;
      }
      // Safari
      else if ((temp = /\bSafari\/([\d.]+)/.exec(userAgent))) {
         browserInfo = `Safari ${temp[1]}`;
      }
      // Firefox
      else if ((temp = /\bFirefox\/([\d.]+)/.exec(userAgent))) {
         browserInfo = `Firefox ${temp[1]}`;
      }
      // IE
      else if ((temp = /\bTrident\/.*rv:([\d.]+)/.exec(userAgent))) {
         browserInfo = `Internet Explorer ${temp[1]}`;
      }
      // Other browsers
      else if ((temp = /\b(?:MSIE |rv:)(\d+(\.\d+)?)/.exec(userAgent))) {
         browserInfo = `Internet Explorer ${temp[1]}`;
      }

      return browserInfo || 'Unknown Browser';
   }

   async getOSInfo(): Promise<string> {
      const platform = navigator.platform;
      const userAgent = navigator.userAgent;
      if (platform.includes('Win')) {
         if (userAgent.includes('Windows NT 10.0')) return await this.getPlatformVersion();
         if (userAgent.includes('Windows NT 6.3')) return 'Windows 8.1';
         if (userAgent.includes('Windows NT 6.2')) return 'Windows 8';
         if (userAgent.includes('Windows NT 6.1')) return 'Windows 7';
      } else if (platform.includes('Mac')) {
         return 'Mac OS';
      } else if (platform.includes('Linux')) {
         return 'Linux';
      }
      return 'Unknown OS';
   }

   getPlatformVersion(): Promise<string> {
      const nav = navigator as any;
      if (nav.userAgentData) {
         return nav.userAgentData.getHighEntropyValues(['platformVersion']).then(ua => {
            const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0], 10);
            if (majorPlatformVersion >= 13) {
               return 'Windows 11 or later';
            } else if (majorPlatformVersion > 0) {
               return 'Windows 10';
            }
         });
      } else {
         return Promise.resolve('User-Agent Client Hints API not supported');
      }
   }
   getLocaleInfo(): string {
      const locale = navigator.language || 'Unknown';
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Unknown';
      return `Locale: ${locale}, Time Zone: ${timeZone}`;
    }
}
