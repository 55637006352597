<time ngClass="time">
    <ng-container *ngIf="startOrEnd === 'START'">
        {{ 'STARTDATE'  | translate }}:
    </ng-container>
    <ng-container *ngIf="startOrEnd === 'END'">
        {{ 'REMAININGTIME'  | translate }}:
    </ng-container>
    <mat-icon class="time__icon">calendar_today</mat-icon>
    {{ timeout }}
    <ng-container *ngIf="startOrEnd === 'START'">
        {{ 'HOURS'  | translate }}
    </ng-container>
    <ng-container *ngIf="startOrEnd === 'END'">
        {{ 'DAYS'  | translate }}
    </ng-container>
</time>

