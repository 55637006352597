<div class="item__wraper" (click)="handleItemClick()">
  <div class="item">
    <icon-file></icon-file>
    <main class="item__main">
      <h4 class="item__title">
        {{ title }}
      </h4>
      <footer class="item__meta">
        <span class="item__meta-item">
          {{ 'DOCUMENT_LIST_ITEM.AUTHOR' | translate }}
          {{ author }}
        </span>
        <span class="item__meta-item">
          {{ caseName }}
        </span>
        <span class="item__meta-item" *ngIf="modifiedAt">{{ 'DOCUMENT_LIST_ITEM.MODIFIED' | translate }}
          {{ modifiedAt }}</span>
      </footer>
      <footer class="item__meta" *ngIf="dateOfExpedition">
        <span class="item__meta-item">{{ 'DOCUMENT_LIST_ITEM.DATE_OF_EXPEDITION' | translate }}
          {{ dateOfExpedition }}</span>
      </footer>
    </main>
  </div>
  <button mat-icon-button class="button-square item__button" color="primary"
    matTooltip="{{ 'DOCUMENT_LIST_ITEM.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
    <mat-icon class="button-square__icon">more_horiz</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="filter-menu">
    <button mat-menu-item (click)="handleItemClick()">
      <mat-icon>visibility</mat-icon>
      {{ 'DOCUMENT_LIST_ITEM.VIEW_DOCUMENT' | translate }}
    </button>
    <button mat-menu-item (click)="handleDownloadClick()">
      <mat-icon>cloud_download</mat-icon>
      {{ 'DOCUMENT_LIST_ITEM.DOWNLOAD_DOCUMENT' | translate }}
    </button>
    <span matTooltip="{{sendDocumentTooltipText}}" [matTooltipDisabled]="!checkIfSendIsDisabled()">
      <button mat-menu-item (click)="sendItemClick()" [disabled]="checkIfSendIsDisabled()">
        <mat-icon>local_post_office</mat-icon>
        {{ 'DOCUMENT_LIST_ITEM.SEND_DOCUMENT' | translate }}
      </button>
    </span>
    <button *ngIf="title.substring(title.length-4,title.length)==='.pdf'" mat-menu-item (click)="handlePrintItem()">
      <mat-icon>print</mat-icon>
      {{ 'DOCUMENT_LIST_ITEM.PRINT_DOCUMENT' | translate }}
    </button>
    <button *ngIf="title.substring(title.length-4,title.length)==='.pdf'" mat-menu-item
      (click)="handleViewItemInNewTabClick()">
      <mat-icon>open_in_new</mat-icon>
      {{ 'DOCUMENT_LIST_ITEM.VIEW_DOCUMENT_IN_NEW_TAB' | translate }}
    </button>
  </mat-menu>
</div>