import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CaseService } from 'src/services/case.service';
import { DatePipe } from '@angular/common';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { TranslateService } from '@ngx-translate/core';
import { CaseStatus } from 'src/types';
import { Page } from 'src/app/page';
import { CodebookService } from 'src/services/codebook.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/local-storage.service';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';

enum AutocompleteFilterEnum {
   SUBCENTER = 'subcenter',
   CLASSIFICATION = 'classification'
}

@Component({
   selector: 'app-case-search-by-classification',
   templateUrl: './case-search-by-classification.component.html',
   styleUrls: ['./case-search-by-classification.component.scss']
})
export class CaseSearchByClassificationComponent implements OnInit {

   page: Page<any> = new Page();
   filter: FormGroup;
   text: string[] = [];
   centerId: any;
   finalclassification: any;
   appliedTextFilters: string[] = [];
   subcenters = [];

   filteredSubcenters: Observable<any>;
   areLoaded = true;
   caseClassifications = [];
   filteredcaseClassifications: Observable<any>;
   displayedColumns: string[] = [
      'case',
      'centerName',
      'subjectId',
      'subjectInfo',
      'status',
      'userInfo',
      'actions',
   ];

   constructor(
      private paginationService: CustomPaginationService,
      private caseService: CaseService,
      private formBuilder: FormBuilder,
      public datepipe: DatePipe,
      private translateService: TranslateService,
      private codebookService: CodebookService,
      private localStorageService: LocalStorageService
   ) {
      this.codebookService.getCsrCodebook().subscribe((result) => {
         this.subcenters = result;
         this.filteredSubcenters = this.filter.controls.subcenter.valueChanges.pipe(
            startWith(''),
            map((value) => (typeof value === 'string' ? value : value?.name)),
            map((title) =>
               title
                  ? this._filter(title, AutocompleteFilterEnum.SUBCENTER)
                  : this.subcenters.slice()
            )
         );

         if (this.text.length > 0) {
            this.getCases();
         }
      });
      this.codebookService.getSocialCaseClassificationCodebook().subscribe(
         result => {
            this.caseClassifications = result;
            this.filteredcaseClassifications = this.filter.controls.classification.valueChanges.pipe(
               startWith(''),
               map((value) => (typeof value === 'string' ? value : value?.title)),
               map((title) =>
                  title
                     ? this._filter(title, AutocompleteFilterEnum.CLASSIFICATION)
                     : this.caseClassifications.slice()
               )
            );
         }
      );
   }
   translateStatus(status: any) {
      if (status === CaseStatus.NOT_ASSIGNED) {
         this.translateService
            .get('CASES.STATUSES.NOT_ASSIGNED')
            .subscribe((res: string) => {
               status = res;
            });
      } else if (status === CaseStatus.ASSIGNED) {
         this.translateService
            .get('CASES.STATUSES.ASSIGNED')
            .subscribe((res: string) => {
               status = res;
            });
      } else if (status === CaseStatus.ACCEPTED) {
         this.translateService
            .get('CASES.STATUSES.ACCEPTED')
            .subscribe((res: string) => {
               status = res;
            });
      } else if (status === CaseStatus.CLOSED) {
         this.translateService
            .get('CASES.STATUSES.CLOSED')
            .subscribe((res: string) => {
               status = res;
            });
      } else if (status === CaseStatus.FINISHED_PROCESSING) {
         this.translateService
            .get('CASES.STATUSES.FINISHED_PROCESSING')
            .subscribe((res: string) => {
               status = res;
            });
      } else if (status === CaseStatus.COMPLAIN) {
         this.translateService
            .get('CASES.STATUSES.COMPLAIN')
            .subscribe((res: string) => {
               status = res;
            });
      } else if (status === CaseStatus.SECOND_DEGREE) {
         this.translateService
            .get('CASES.STATUSES.SECOND_DEGREE')
            .subscribe((res: string) => {
               status = res;
            });
      }
      return status;
   }
   ngOnInit(): void {
      this.filter = this.formBuilder.group({
         subcenter: new FormControl(),
         classification: new FormControl(),
         searchValue: ['', []],
      });
   }
   autoSelectCenter(){
      autoSelect(this.subcenters, 'subcenter', 'name', this.filter);
      setTimeout(()=>{
         if(this.centerId !== this.filter.controls.subcenter.value?.id)
            this.reloadCases(this.filter.controls.subcenter.value, "center");
      },500)
   }
   autoSelectClassification(){
      autoSelect(this.caseClassifications, 'classification', 'title', this.filter);
      setTimeout(()=>{
         if(this.finalclassification !== this.filter.controls.classification.value?.code)
            this.reloadCases(this.filter.controls.classification.value, "classification");
      },500)
   }
   public getNextPage(): void {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.getCases();
   }

   public getPreviousPage(): void {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.getCases();
   }
   public getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.getCases();
   }

   public getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.getCases();
   }

   public getPageInNewSize(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(
         this.page,
         pageSize
      );
      this.getCases();
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   displayCustomFormatClassification(objectValue: any): string {
      return objectValue ? (objectValue.code + ' ' + objectValue.title) : objectValue;
   }
   onEnterClickAddFilter() {
      this.text.push(this.filter.get('searchValue').value);
      this.appliedTextFilters.push(this.filter.get('searchValue').value);
      this.getFirstPage();
      this.filter.get('searchValue').setValue('');
   }

   getCases() {
      const isCenterInvalid = this.centerId === null || this.centerId === undefined;
      const isClassificationInvalid = this.finalclassification === null || this.finalclassification === undefined;
      if(!(isCenterInvalid && isClassificationInvalid && this.appliedTextFilters.length === 0)){
      this.areLoaded = false;
      this.caseService
         .findCaseForSecondDegree(
            this.page.pageable,
            this.text.map((text) => text.trim()),
            this.centerId ? this.centerId : '',
            this.finalclassification ? this.finalclassification : ''
         )
         .subscribe((page) => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            this.areLoaded = true;
         });
      } else {
         this.page.content = [];
         this.page.pageable.pageNumber = 1;
      }
   }

   removeTextFilter(textFilter: any) {
      this.text = this.text.filter((item) => item !== textFilter);
      this.appliedTextFilters = this.appliedTextFilters.filter(
         (item) => item !== textFilter
      );
      this.getFirstPage();
   }

   reloadCases(event: any, type: any) {
      if (type === 'center') {
         this.centerId = event?.id;
      } else {
         this.finalclassification = event?.code
      }
      this.getFirstPage();
   }

   checkInput() {
      if (this.filter.controls.subcenter?.value === '') {
         this.centerId = null;
         this.getFirstPage();
      }
      if (this.filter.controls.finalclassification?.value === '') {
         this.finalclassification = null;
         this.getFirstPage();
      }
   }
   checkSubject(item: any) {
      this.localStorageService.set('localStorageCenterId', item.centerId);
      window.open(environment.getFamilyMemberTab + item.subjectId, '_blank');
   }
   checkCase(item: any) {
      this.localStorageService.set('localStorageCenterId', item.centerId);
      window.open(
         environment.getNewCaseTab +
         item.id +
         '/' +
         item.subjectId +
         '/' +
         item.centerId,
         '_blank'
      );
   }
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.SUBCENTER:
            return sortByFirstLetter(this.subcenters.filter((option) =>
               option.name.toLowerCase().includes(filterValue)), filterValue, 'name');
         case AutocompleteFilterEnum.CLASSIFICATION:
            return sortByFirstLetter(this.caseClassifications.filter((option) =>
               option.title.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue)), filterValue, 'title');
         default:
            break;
      }
   }
}
