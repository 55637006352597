<container>
  <mat-card class="card">
    <card-header title="{{ 'STATISTICS.ACTIVITY_STATS.TITLE' | translate }}">
    </card-header>
    <form [formGroup]="activityStatsForm" class="form">
      <field-holder kind="3-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
        <mat-form-field appearance="outline">
          <mat-label>
            {{'GLOBAL.CENTER' | translate}}
          </mat-label>
          <input type="text" matInput [matAutocomplete]="autoSubcenter" formControlName="center"
           (focusout)="autoSelectCenter()" required/>
          <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete"
            [displayWith]="displayCustomFormat" (optionSelected)="findOffices($event)">
            <mat-option *ngFor="let cent of filteredAllCenters | async" [value]="cent">
              {{ cent.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
                <mat-form-field appearance="outline">
          <mat-label>{{
            'STATISTICS.ACTIVITY_STATS.OFFICE' | translate
          }}</mat-label>
          <mat-select formControlName="office" (selectionChange)="findWorkers($event)">
            <mat-option></mat-option>
            <mat-option *ngFor="let office of offices" [value]="office">
              {{ office.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'STATISTICS.ACTIVITY_STATS.WORKER' | translate
          }}</mat-label>
          <mat-select formControlName="workerName">
            <mat-option></mat-option>
            <mat-option *ngFor="let worker of filteredWorkers" [value]="worker">
              {{ worker.name }}
            </mat-option>
          </mat-select>
          <mat-hint>{{
            'STATISTICS.ACTIVITY_STATS.WORKER_HINT' | translate
          }}</mat-hint>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols" *ngIf="!isUserDrugostepeni && !isMainCenterBelgrade">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'STATISTICS.ACTIVITY_STATS.OFFICE' | translate
          }}</mat-label>
          <mat-select formControlName="office" (selectionChange)="findWorkers($event)">
            <mat-option></mat-option>
            <mat-option *ngFor="let office of offices" [value]="office">
              {{ office.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'STATISTICS.ACTIVITY_STATS.WORKER' | translate
          }}</mat-label>
          <mat-select formControlName="workerName">
            <mat-option></mat-option>
            <mat-option *ngFor="let worker of filteredWorkers" [value]="worker">
              {{ worker.name }}
            </mat-option>
          </mat-select>
          <mat-hint>{{
            'STATISTICS.ACTIVITY_STATS.WORKER_HINT' | translate
          }}</mat-hint>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.ACTIVITY_STATS.FROM' | translate }}</mat-label>
          <input matInput type="text" [matDatepicker]="dateFrom" formControlName="dateFrom" autocomplete="off" required
            placeholder="{{ 'STATISTICS.ACTIVITY_STATS.FROM' | translate }}"  [max]="maxDate"/>
          <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
          <mat-datepicker #dateFrom></mat-datepicker>
          <mat-error *ngIf="activityStatsForm.get('dateFrom').hasError('required')">
            {{ "STATISTICS.DATE_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.ACTIVITY_STATS.TO' | translate }}</mat-label>
          <input matInput type="text" [matDatepicker]="dateTo" formControlName="dateTo" 
          [max]="maxDate" autocomplete="off" required
            placeholder="{{ 'STATISTICS.ACTIVITY_STATS.TO' | translate }}" />
          <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
          <mat-datepicker #dateTo></mat-datepicker>
          <mat-error *ngIf="activityStatsForm.get('dateTo').hasError('required')">
            {{ "STATISTICS.DATE_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>

      <form-footer>
        <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
          (click)="sendRequest(activityStatsForm)" [disabled]="!activityStatsForm.valid || btnDisabled">
          {{ 'STATISTICS.SUBMIT' | translate  }}
        </button>
        <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </form-footer>
    </form>
  </mat-card>
</container>