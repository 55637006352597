import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { TeamMemberFindingService } from 'src/services/team-member-finding.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
  selector: 'team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss']
})
export class TeamMemberComponent implements OnInit {
  isValid: any;
  caseData: any;
  teamMemberForm: FormGroup;
  loggedIn: any;
  @Input() subjectId: any;
  @Input() caseId: any;
  @Input() editable: boolean;
  @ViewChild('submitButton') submitButton;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private teamMemberFindingService: TeamMemberFindingService,
    private translate: TranslateService,
    private toastr: ToastrImplService) {
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
  }

  ngOnInit(): void {
    this.teamMemberForm = this.formBuilder.group({
      finding: ['', []]
    });
  }
  submitConclusion() {
    const data = this.createTeamMemberFindingObject();
    this.teamMemberFindingService.createTeamMemberFinding(data).subscribe(res => {
      this.toastr.success('TEAM_MEMBER.SENT');
      this.submitButton.disabled = false;
      this.teamMemberForm = this.formBuilder.group({
        finding: ['', []]
      });
    });
  }

  createTeamMemberFindingObject() {
    const teamMemberFindingObject = {
      socialCaseId: this.caseId,
      finding: this.teamMemberForm.value.finding
    };
    return teamMemberFindingObject;
  }
}
