/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { CodebookService } from 'src/services/codebook.service';
import { ImportDataService } from 'src/services/import-data.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { clearInterval, setInterval } from 'timers';

enum AutocompleteFilterEnum {
   SUBCENTER = 'subcenter',
}

enum ImportStatus {
   IMPORTING = 'IMPORTING',
   IDLE = 'IDLE',
   ERROR = 'ERROR',
   FINISHED = 'FINISHED',
   IN_PROCESS = 'in process',
   STOPPED = 'STOPPED',
}

enum LocalStorageData {
   IMPORTING = 'importing',
   SUBCENTER_ID = 'importSubcenterId',
}

@Component({
   selector: 'app-import-data',
   templateUrl: './import-data.component.html',
   styleUrls: ['./import-data.component.scss'],
})
export class ImportDataComponent implements OnInit, OnDestroy {
   subcenter = new FormControl();
   subcenters = [];
   importDataForm: FormGroup;
   @ViewChild('submitButton') submitButton: any;
   subjectImports = [];

   filteredSubcenters: Observable<any>;
   fileToUpload = null;
   uploadStatus = ImportStatus.IDLE;
   uploadMessage = '';
   checkStatusInterval = null;
   checkMessagesInterval = null;
   traceImportProcess = '';
   disableSync = false;
   selectable = true;
   removable = true;
   displayedColumns: string[] = ['id', 'center', 'startDate', 'endDate', 'nameCSV', 'subjectCount', 'status'];
   constructor(
      private codebookService: CodebookService,
      private builder: FormBuilder,
      private importDataService: ImportDataService,
      private localStorageService: LocalStorageService,
      private toastr: ToastrImplService,
   ) {
      this.importDataForm = this.builder.group({
         files: [null, Validators.required],
         subcenter: [null, []],
      });
      this.getLast10Imports();
      // Učitaj šifarnik centara
      this.codebookService.getCsrCodebook().subscribe(
         result => {
            this.subcenters = result;

            // Ako je u toku import, podesi vrednost u padajućoj listi na centar koji se učitava
            if (
               this.localStorageService.get(LocalStorageData.IMPORTING) === ImportStatus.IN_PROCESS &&
               this.localStorageService.get(LocalStorageData.SUBCENTER_ID)
            ) {
               this.importDataForm.controls.subcenter.setValue(
                  this._filterId(Number(this.localStorageService.get(LocalStorageData.SUBCENTER_ID)), AutocompleteFilterEnum.SUBCENTER)[0]
               );
            }

            this._subcenterFilter();
         },
         () => {
            console.error('Greška kod preuzimanja šifarnika centara.');
         }
      );
   }

   ngOnInit(): void {}
   
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.SUBCENTER:
            return this.subcenters.filter(option => option.name.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   private _filterId(value: number, what: AutocompleteFilterEnum): any[] {
      switch (what) {
         case AutocompleteFilterEnum.SUBCENTER:
            return this.subcenters.filter(option => option.id === value);
         default:
            break;
      }
   }

   public submit(event: any) {
      this.traceImportProcess = '';
      this.uploadMessage = '';
      if (this.uploadStatus === ImportStatus.IMPORTING) {
         return;
      }
      this.importDataForm.value.files = event.target.files;
      this.fileToUpload = event.target.files[0];
      event.target.value = '';
   }

   public dropSuccess(files: any) {
      this.traceImportProcess = '';
      if (this.uploadStatus === ImportStatus.IMPORTING) {
         return;
      }
      this.importDataForm.controls.files.setValue(files);
      this.fileToUpload = files[0];
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }

   submitImport() {
      this.submitButton.disabled = true;
      this.localStorageService.set(LocalStorageData.SUBCENTER_ID, this.importDataForm.value.subcenter.id);
      this.importDataService.importData(this.importDataForm.value.subcenter.id, this.fileToUpload).subscribe(
         () => {
            this.localStorageService.set(LocalStorageData.IMPORTING, ImportStatus.IN_PROCESS);
            this.createIntervals();
            this.fileToUpload = null;
         },
         () => {
            this.submitButton.disabled = false;
            this.toastr.error('SNACKBAR.ЕRROR_IMPORTING');
         }
      );
   }

   _subcenterFilter() {
      this.filteredSubcenters = this.importDataForm.controls.subcenter.valueChanges.pipe(
         startWith(''),

         map(value => (typeof value === 'string' ? value : value.name)),
         map(title => (title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice()))
      );
   }

   checkStatus() {
      this.importDataService.checkStatus().subscribe(
         result => {
            this.uploadStatus = result;

            if (this.uploadStatus === ImportStatus.IMPORTING) {
               this.uploadMessage = 'IMPORT_DATA.IMPORTING';
            }

            if (this.uploadStatus === ImportStatus.IDLE) {
               this.uploadMessage = 'IMPORT_DATA.IDLE';

               this.clearIntervals();
               this.getMessages();
            }

            if (this.uploadStatus === ImportStatus.ERROR) {
               this.uploadMessage = 'IMPORT_DATA.ERROR';
               this.clearIntervals();
               this.getMessages();
               this.toastr.error('SNACKBAR.ЕRROR_IMPORTING');
               this.getLast10Imports();
            }
            if (this.uploadStatus === ImportStatus.STOPPED) {
               this.getLast10Imports();
               this.clearIntervals();
               this.getMessages();
            }

            if (this.uploadStatus === ImportStatus.FINISHED) {
               this.uploadMessage = 'IMPORT_DATA.FINISHED';
               this.clearIntervals();
               this.getMessages();
               this.toastr.success('SNACKBAR.INPUT_ADDED_SUCCESSFULLY');
               this.getLast10Imports();
            }
         },
         () => {
            console.error('Greška kod preuzimanja statusa uvoza podataka.');
            this.clearIntervals();
         }
      );
   }

   getMessages() {
      this.importDataService.getMessages().subscribe(result => {
         this.traceImportProcess += result;
      });
   }

   stopImport() {
      this.importDataService.stopImport().subscribe(
         () => {},
         () => {
            console.error('Greška kod zaustavljanja importa.');
         }
      );
   }

   syncImport() {
      this.disableSync = true;
      if (this.importDataForm.get('subcenter').value?.id) {
         const id = this.importDataForm.get('subcenter').value?.id;
         this.importDataService.syncData(Number(id)).subscribe(
            result => {
               this.toastr.success('IMPORT_DATA.SYNC_DATA_INITIATED');
               this.disableSync = false;
            },
            () => {
               this.toastr.error('IMPORT_DATA.ERROR');
               console.error('Greška kod sinhronizacije podataka sa pisarnicom.');
               this.disableSync = false;
            }
         );
      }
   }

   createIntervals() {
      this.checkStatusInterval = setInterval(() => {
         this.checkStatus();
      }, 500);
      this.checkMessagesInterval = setInterval(() => {
         this.getMessages();
      }, 500);
   }

   clearIntervals() {
      if (this.checkMessagesInterval) {
         clearInterval(this.checkMessagesInterval);
      }
      if (this.checkStatusInterval) {
         clearInterval(this.checkStatusInterval);
      }
   }

   resetFields() {
      this.uploadStatus = ImportStatus.IDLE;
      this.fileToUpload = null;
      this.importDataForm.controls.subcenter.setValue('');
      this.importDataForm.controls.files.setValue(null);

      this.localStorageService.remove(LocalStorageData.IMPORTING);
      this.localStorageService.remove(LocalStorageData.SUBCENTER_ID);
   }

   ngOnDestroy(): void {
      if (this.uploadStatus !== ImportStatus.IMPORTING) {
         this.resetFields();
      }
   }

   getLast10Imports() {
      this.importDataService.getLast10SubjectImports().subscribe(result => {
         this.subjectImports = result;
      });
   }

   onRowClicked(element: any) {
      if (element.status !== 'FINISHED') {
         this.toastr.showInfo(element.error);
      }
   }
}
