/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import { PreviewDocumentComponent } from '../sidebar-widget/preview-document/preview-document.component';
import { Page } from '../page';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { AlfrescoService } from 'src/services/alfresco.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  pageEvaluation: Page<any> = new Page();
  loggedIn: User;
  currentCenter: string;
  workerId: any;

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    private paginationService: CustomPaginationService,
    private alfrescoService: AlfrescoService,
    private userService: UserService) {

    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.loggedIn.roles = this.userService.getUserRoleTitle(JSON.parse(this.localStorageService.get('loggedUser')).role);

    this.currentCenter = this.loggedIn.city;
    this.userService.findUserData(this.loggedIn.id).subscribe(
      res => {
        this.workerId = res.id;
        this.findDocumentsByWorker();
      }
    );
  }

  public findDocumentsByWorker() {
    const filter = {
      minDate: '',
      maxDate: '',
      text: []
    };
    if (this.pageEvaluation.numberOfElements === 1 && this.pageEvaluation.pageable.pageNumber !== 0) {
      this.pageEvaluation.pageable.pageNumber = this.pageEvaluation.pageable.pageNumber - 1;
    }
    this.alfrescoService.getWorkerDocuments(this.workerId, this.pageEvaluation.pageable, filter).subscribe(
      result => {
        this.pageEvaluation = result;
        for (const element of this.pageEvaluation.content) {
          element.dateOfModification = element.dateOfModification.split(' ')[0] + '. '
            + element.dateOfModification.split(' ')[1];
        }
        this.pageEvaluation.pageable.pagePerShow = result.number + 1;
      }
    );
  }

  openDialog(data: any) {
    const { document, requireSignature = false } = data;
    const dialogRef = this.dialog.open(PreviewDocumentComponent, {
      disableClose: true,
      panelClass:'overlay-panel',
      data: { document, requireSignature },
    });

    dialogRef.afterClosed().subscribe(({ event, data = '' }) => {
      const note = data;
    });
  }

  public getNextPageEvaluation(): void {
    this.pageEvaluation.pageable = this.paginationService.getNextPage(this.pageEvaluation);
    this.findDocumentsByWorker();
  }

  public getPreviousPageEvaluation(): void {
    this.pageEvaluation.pageable = this.paginationService.getPreviousPage(this.pageEvaluation);
    this.findDocumentsByWorker();
  }
  
  public getFirstPageEvaluation(): void {
    this.pageEvaluation.pageable = this.paginationService.getFirstPage(this.pageEvaluation);
    this.findDocumentsByWorker();
  }

  public getLastPageEvaluation(): void {
    this.pageEvaluation.pageable = this.paginationService.getLastPage(this.pageEvaluation);
    this.findDocumentsByWorker();
  }

  public getPageInNewSizeEvaluation(pageSize: any): void {
    this.pageEvaluation.pageable = this.paginationService.getPageInNewSize(
      this.pageEvaluation,
      pageSize
    );
    this.findDocumentsByWorker();
  }
}
