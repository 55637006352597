<container>
  <mat-card class="card supervision">
    <aside class="section section--left">
      <header class="section__header">
        <h2 class="section__title">
          {{ 'SUPERVISION.WORKERS_TITLE' | translate }}
        </h2>
      </header>
      <ul class="worker__list">
        <li class="worker__item" *ngFor="let worker of workers">
          <a [routerLink]="'/supervision/' + worker.id" routerLinkActive="worker__link--active" class="worker__link"
            matRipple>{{ worker.firstName }} {{ worker.lastName }} </a>
        </li>
      </ul>
    </aside>
    <section class="section section--right">
      <header class="section__header section__header--smaller-spacing">
        <h2 class="section__title">
          {{ selectedWorker }}
        </h2>
      </header>
      <mat-tab-group animationDuration="0" (selectedTabChange)="changeTab($event)">
        <mat-tab label="{{ 'SUPERVISION.CASES_TAB' | translate }}" >
          <mat-card *ngIf="casesLoading" class="card-spinner wrapper">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </mat-card>
          <div *ngIf="!casesLoading" class="worker-details__tab-wrapper">
            <mat-card *ngIf="cases.length === 0 && !isSupervisionRootPage">
              <no-result-item>{{ 'SUPERVISION.NO_CASES' | translate }}</no-result-item>
            </mat-card>
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true"
                (closed)="panelOpenState = false; documentsLoading = true;" *ngFor="let case of cases"
                (afterExpand)="getFirstPage(case)"
                (click)="markAsRead(case)"
                [expanded]="case.id===caseId">
                <mat-expansion-panel-header [collapsedHeight]="'64px'" [expandedHeight]="'64px'">
                  <mat-panel-title>
                    <case-item-title-expansion-panel [title]="case.caseNumber" [date]="case.dateOfCreation"
                      [firstName]="case.firstName" [lastName]="case.lastName" [newApproving]="case.newApproving"
                      [kind]="case.caseTypeCodebook.mark">
                    </case-item-title-expansion-panel>
                  </mat-panel-title>
                  <mat-panel-description>
                    <span class="status_vs_documents">
                      <span *ngIf="case.unapprovedForm23">VS-PP</span>
                      <span *ngIf="case.unapprovedForm5">VS-PU</span>
                      <span *ngIf="case.unapprovedConclusion">VS-ZAKLJUCAK</span>
                  </span>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container>
                  <div *ngIf="documentsLoading" class="card-spinner">
                    <mat-progress-spinner color="primary" mode="indeterminate">
                    </mat-progress-spinner>
                  </div>
                  <div *ngIf="!documentsLoading">
                    <ul class="worker-details__document-list">
                      <li class="worker-details__no-documents-item" *ngIf="page.content.length === 0">
                        <no-result-item>{{'SUPERVISION.NO_DOCUMENTS' | translate}}</no-result-item>
                      </li>
                      <li class="worker-details__document-item" *ngFor="let document of page.content">
                        <document-item-supervision (click)="
                          openDialog({
                            document: document,
                            requireSignature:
                              document.status === 'NOT_SIGNED'
                          })" [title]=" document.name" [status]="document.status">
                          <button mat-stroked-button color="primary" *ngIf="document.status === 'NOT_SIGNED'">
                            {{ 'SUPERVISION.SIGN_DOCUMENT' | translate }}
                          </button>
                        </document-item-supervision>
                      </li>
                    </ul>
                    <div class="pagination-wrapper" *ngIf="page.content.length !== 0">
                      <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage(case)"
                        (nextPageEvent)="getNextPage(case)" (previousPageEvent)="getPreviousPage(case)"
                        (lastPageEvent)="getLastPage(case)" (pageSizeEvent)="getPageInNewSize($event, case)">
                      </app-custom-pagination>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'SUPERVISION.EVALUATION_TAB' | translate }}">
          <div class="worker-details__tab-wrapper">
            <mat-card>
              <div class="aside">
                <button *ngIf="showDocumentOptions" mat-flat-button color="primary" [matMenuTriggerFor]="menu"
                  #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                  {{ 'SUPERVISION.NEW_EVALUATION' | translate }}<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="filter-menu">
                  <ng-container *ngFor="let docOptions of documentOptions">
                    <button mat-menu-item (click)="handleNewEvaluation(docOptions)">{{ docOptions.name }}</button>
                  </ng-container>
                </mat-menu>
                <input type="file" #file (change)="handleFileInput($event.target.files)" hidden>
                <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
                  type="submit" (click)="file.click()">
                  {{ 'SUPERVISION.SAVE_DOCUMENT' | translate }}
                </button>
              </div>
              <br>
              <ng-container>
                <div *ngIf="evaluationLoading" class="card-spinner">
                  <mat-progress-spinner color="primary" mode="indeterminate">
                  </mat-progress-spinner>
                </div>
                <ul *ngIf="!evaluationLoading"
                  class="worker-details__document-list worker-details__document-list--evaluation">
                  <li *ngIf="pageEvaluation.content.length === 0">
                    <no-result-item>{{ 'SUPERVISION.NO_EVALUATIONS' | translate }}</no-result-item>
                  </li>
                  <li class="worker-details__document-item" *ngFor="let document of pageEvaluation.content">
                    <document-item-evaluation [title]="document.name" [meta]="document.dateOfModification"
                      (handleDownload)="evaluationAction('download', document)"
                      (handlePrint)="evaluationAction('print', document)"
                      (handleClick)="evaluationAction('view', document)">
                    </document-item-evaluation>
                  </li>
                </ul>
                <div class="pagination-wrapper" *ngIf="pageEvaluation.content.length !== 0">
                  <app-custom-pagination [page]="pageEvaluation" (firstPageEvent)="getFirstPageEvaluation()"
                    (nextPageEvent)="getNextPageEvaluation()" (previousPageEvent)="getPreviousPageEvaluation()"
                    (lastPageEvent)="getLastPageEvaluation()" (pageSizeEvent)="getPageInNewSizeEvaluation($event)">
                  </app-custom-pagination>
                </div>
              </ng-container>
            </mat-card>
          </div>
        </mat-tab>
      </mat-tab-group>
    </section>
  </mat-card>
</container>