/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';
import { DossierStatus } from 'src/types';

@Component({
  selector: 'dossier-status-label',
  templateUrl: './dossier-status-label.component.html',
  styleUrls: ['./dossier-status-label.component.scss']
})
export class DossierStatusLabelComponent implements OnInit {
  @Input() status: DossierStatus.CARRIER | DossierStatus.MEMBER | DossierStatus.NONE;
  constructor() { }

  ngOnInit(): void {
  }

}
