/*
* Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
* Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
* u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalAdminService {

    constructor(private httpClient: HttpClient, private app: AppService) { }
    addNewCitizenshipCodebook(title: string) {
        const headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        });
        return this.httpClient.post(this.app.getUrl() + environment.addNewCitizenshipCodebook + title, { headers }) as Observable<any>;
    }
    addNewNationalityCodebook(title: string) {
        const headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        });
        return this.httpClient.post(this.app.getUrl() + environment.addNewNationalityCodebook + title, { headers }) as Observable<any>;
    }
    addNewBankCodebook(cipher: string, title: string, ) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json'
        });
        const newBank = {
            id: Number(cipher),
            name: title
        }
        return this.httpClient.post(this.app.getUrl() + environment.addNewBankCodebook, newBank, { headers }) as Observable<any>;
    }
    updateNationalityCodebook(nationalityCodebook: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.updateNationalityCodebook
            , nationalityCodebook, { headers }) as Observable<any>;
    }
    updateCitizenshipCodebook(citizenshipCodebook: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.updateCitizenshipCodebook
            , citizenshipCodebook, { headers }) as Observable<any>;
    }
    updateBankCodebook(bankCodebook: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        const updateBank = {
            id: bankCodebook.id,
            name: bankCodebook.title
        };
        return this.httpClient.post(this.app.getUrl() + environment.updateBankCodebook
            , updateBank, { headers }) as Observable<any>;
    }
    updateBankStatus(id: number, status: boolean) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        const updateBank = {
            id: id,
            active: status
        };
        return this.httpClient.post(this.app.getUrl() + environment.updateBankStatus
            , updateBank, { headers }) as Observable<any>;
    }
    getCurrentPocketMoneyPercentageCodebook() {
        const headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        });
        return this.httpClient.get(this.app.getUrl() + environment.getCurrentPocketMoneyPercentageCodebook, { headers }) as Observable<any>;
    }

    updateRightsCodebook(rightsCodebook) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.put(this.app.getUrl() + environment.updateRightsCodebook,
            rightsCodebook, { headers }) as Observable<any>;
    }

    deleteRightsCodebook(id) {
        const headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        });
        return this.httpClient.delete(this.app.getUrl() + environment.deleteRightsCodebook + '?id=' + id, { headers }) as Observable<any>;
    }

    updateServicesCodebook(servicesCodebook) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.put(this.app.getUrl() + environment.updateServicesCodebook,
            servicesCodebook, { headers }) as Observable<any>;
    }

    deleteServicesCodebook(id) {
        const headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        });
        return this.httpClient.delete(this.app.getUrl() + environment.deleteServicesCodebook + '?id=' + id, { headers }) as Observable<any>;
    }

    updateLegalProceduresCodebook(legalProceduresCodebook) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.put(this.app.getUrl() + environment.updateLegalProceduresCodebook,
            legalProceduresCodebook, { headers }) as Observable<any>;
    }

    deleteLegalProceduresCodebook(id) {
        const headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        });
        return this.httpClient.delete(this.app.getUrl() + environment.deleteLegalProceduresCodebook + '?id=' + id, { headers }) as Observable<any>;
    }

    updateProfessionalProceduresCodebook(professionalProceduresCodebook) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.put(this.app.getUrl() + environment.updateProfessionalProceduresCodebook,
            professionalProceduresCodebook, { headers }) as Observable<any>;
    }

    deleteProfessionalProceduresCodebook(id) {
        const headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        });
        return this.httpClient.delete(this.app.getUrl() + environment.deleteProfessionalProceduresCodebook + '?id=' + id, { headers }) as Observable<any>;
    }

    addPocketMoneyPercentageCodebook(pocketMoneyPercentageCodebook: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.addPocketMoneyPercentageCodebook
            , pocketMoneyPercentageCodebook, { headers }) as Observable<any>;
    }

    createNspRightCodebooks(nspRightCodebooks: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.createNspRightCodebook
            , nspRightCodebooks, { headers }) as Observable<any>;
    }

    createDpnRightCodebook(dpnRightCodebook: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.createDpnRightCodebook
            , dpnRightCodebook, { headers }) as Observable<any>;
    }

    createAccommodationCodebook(accomodationData: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.createFamilyAccommodationPriceCodebook
            , accomodationData, { headers }) as Observable<any>;
    }

    createPocketMoneyAmountCodebook(pocketMoneyPercentageCodebook: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.createPocketMoneyAmountCodebook
            , pocketMoneyPercentageCodebook, { headers }) as Observable<any>;
    }

    createLegalProcedures(legalProcedures: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.createLegalProcedures
            , legalProcedures, { headers }) as Observable<any>;
    }
    createProfessionalProcedures(professionalProcedures: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.createProfessionalProcedures
            , professionalProcedures, { headers }) as Observable<any>;
    }
    createServicesCodebook(servicesCodebook: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.createServicesCodebook
            , servicesCodebook, { headers }) as Observable<any>;
    }
    createRightsCodebook(rightsCodebook: any) {
        const headers = new HttpHeaders({
            'Content-type': 'application/json;'
        });
        return this.httpClient.post(this.app.getUrl() + environment.createRightsCodebook
            , rightsCodebook, { headers }) as Observable<any>;
    }






}