/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SubjectsService } from 'src/services/subjects.service';
import { Subject } from 'src/types';
import { DialogActions } from '../subject-details.component';
import { DatePipe } from '@angular/common';

interface UnarhiveData {
  subject: Subject;
  action: DialogActions;
}

@Component({
  selector: 'unarchive-subject',
  templateUrl: './unarchive-subject.component.html',
  styleUrls: ['./unarchive-subject.component.scss'],
})
export class UnarchiveSubjectComponent implements OnInit {
  minFromDate = new Date();
  maxFromDate = new Date();
  action: string;
  subject: Subject;
  unarchiveSubjectForm: FormGroup;
  @ViewChild('submitButton') submitButton;

  constructor(
    public subjectsService: SubjectsService,
    public dialogRef: MatDialogRef<UnarchiveSubjectComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UnarhiveData,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.subject = data.subject;
    this.action = data.action;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  ngOnInit() {
    this.unarchiveSubjectForm = this.formBuilder.group({
      dateOfUnarchiving: ['', [Validators.required]],
      unarchiveReason: ['', [Validators.required]],
    });
    this.maxFromDate.setFullYear(this.maxFromDate.getFullYear() + 1);
  }

  // Submit
  unarchiveSubject(submittedForm: FormGroup) {
    const unarchiveData = {
      dateOfUnarchiving: this.datePipe.transform(submittedForm.get('dateOfUnarchiving').value, 'dd/MM/yyyy'),
      unarchiveReason: submittedForm.get('unarchiveReason').value,
    };

    const x = this.subjectsService.unarchiveSubject(
      this.subject.historicalSubjectIdentity.subjectId,
      unarchiveData
    );
    x.subscribe(
      (res) => {
        this.dialogRef.close({ event: this.action, data: res });
      },
      () => {
        console.log('Error occured');
        this.submitButton.disabled = false;
        this.dialogRef.close({ event: this.action, data: this.subject });
      }
    );
  }
}