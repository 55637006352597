export const patchForDecimalNumber = (stringNumber: string | number) => {
   const decimalSeparator = 1.1.toLocaleString().substring(1, 2);

   return getNumberForShow(String(stringNumber).replace('.', decimalSeparator));
};

export const getSystemDecimalSeparator = () => {
   return 1.1.toLocaleString().substring(1, 2);
};
export const getNumberFromString = (stringNumber: string | number) => {
   if (stringNumber === undefined || stringNumber === null) {
      return 0;
   }
   const decimalSeparator = getSystemDecimalSeparator();
   const stringForShow = getNumberForShow(stringNumber);

   return Math.round(parseFloat(stringForShow.replace(decimalSeparator, '.')) * 100) / 100;
};
export const getNumberForShow = (stringNumber: string | number) => {
   if (stringNumber === undefined || stringNumber === null) {
      return '0';
   }
   stringNumber = String(stringNumber);
   const decimalSeparator = getSystemDecimalSeparator();
   const thousandSeparator = decimalSeparator === '.' ? ',' : '.';
   return stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), decimalSeparator);
};

