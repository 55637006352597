import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TasksComponent } from '../../../form5/tasks/tasks.component';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { getNumberForShow } from 'src/app/utils/formatLocalNumber';

@Component({
  selector: 'app-costs-participation',
  templateUrl: './costs-participation.component.html',
  styleUrls: ['./costs-participation.component.scss']
})
export class CostsParticipationComponent implements OnInit {

  costsParticipantForm: FormGroup;
  maxDate: Date = new Date(2999, 11, 31);

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TasksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.costsParticipantForm = this.formBuilder.group({
      fullName: [],
      jmbg: [],
      typeOfParticipation: [],
      participationAmount: [],
      place: [],
      pttNumber: [],
      address: [],
      startDate: null
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  add() {
    this.dialogRef.close({ event: 'Create', data: this.costsParticipantForm.value });
  }

  importSubjectData() {
    const dialogRef = this.dialog.open(SubjectsComponent, {
      width: '1200px',
      panelClass:'overlay-panel',
      data: {
        origin: 'accommodation',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        const obj = JSON.parse(JSON.stringify(result));
        const {
          data: {
            firstName,
            lastName,
            permanentResidence,
            jmbg,
          },
        } = obj;

        this.costsParticipantForm.patchValue({
          fullName: firstName + ' ' + lastName,
          jmbg,
          address: String(permanentResidence.street ? permanentResidence.street : '') +
            String(permanentResidence.number ? ' ' : '') +
            String(permanentResidence.number ? permanentResidence.number : ''),
          pttNumber: permanentResidence.ptt,
          place: permanentResidence.town
        });
      }
    });
  }
  checkAmount() {
    this.costsParticipantForm.controls.participationAmount.setValue(getNumberForShow(this.costsParticipantForm.controls.participationAmount.value));
 }

}
