<container kind="midi">
   <case-page-header *ngIf="caseData" [caseData]="caseData" (handleComplainAccepted)="acceptedComplain()" (newCaseStatus)="updateStatus($event)">
   </case-page-header>
   <page-intro-header title="{{title}}"></page-intro-header>

   <mat-card class="card" *ngIf="teamMember===false">
      <form [formGroup]="administrativeProcedureForm" class="form">
         <div class="aside">
            <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
               (click)="$event.stopPropagation()">
               {{ 'LAWYER.DOCUMENT' | translate
                }}<mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="filter-menu">
               <ng-container *ngFor="let docOptions of documentOptions">
                  <button mat-menu-item (click)="handleNewEvaluation(docOptions)">{{ docOptions.name }}</button>
               </ng-container>
            </mat-menu>

            <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden>
            <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
               type="submit" (click)="file.click()">
               {{ 'LAWYER.CREATE' | translate }}
            </button>
         </div>
         <br>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'LAWYER.SOLUTION' | translate }}</mat-label>
               <input matInput type="text" formControlName="fileName" readonly required autocomplete="off" />
            </mat-form-field>
         </field-holder>
         <form-footer>
            <button mat-stroked-button color="primary" (click)="cancelCase()" type="button"
               class="form-footer__button form-footer__button--secondary">
               {{ 'LAWYER.CANCEL' | translate }}
            </button>
            <span matTooltip="{{'CASES.NOT_ACCEPTED' | translate }}"
               [matTooltipDisabled]="caseData?.caseStatus === 'ACCEPTED'">
               <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
                  [matTooltipDisabled]="!caseData?.newComplain">
                  <button mat-flat-button color="primary" (click)="submitConclusion(); submitButton.disabled = true; "
                     class="form-footer__button form-footer__button--secondary" #submitButton
                     [disabled]="fileToUpload === null || caseData.caseStatus !== 'ACCEPTED'||caseData?.newComplain">
                     {{ 'LAWYER.SUBMIT_AND_SEND' | translate }}
                  </button>
               </span>
            </span>

         </form-footer>
      </form>
   </mat-card>
   <mat-card *ngIf="teamMember">
      <team-member [editable]="caseData.caseStatus === 'ACCEPTED'" [caseId]="caseId" [subjectId]="subjectId">
      </team-member>
   </mat-card>
</container>