/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import { CaseProblemRecordService } from 'src/services/case-problem-record.service';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { ModalSizeEnum } from 'src/types';
import { TranslateService } from '@ngx-translate/core';
import { fileCheck } from 'src/app/utils/fileInputUtil';

@Component({
   selector: 'app-close-case',
   templateUrl: './close-case.component.html',
   styleUrls: ['./close-case.component.scss'],
})
export class CloseCaseComponent implements OnInit {
   closeCaseForm: FormGroup;
   isFormDigitallySigned = false;
   reasons = []; // sifrarnik
   fileToUpload: any;
   hasProblem = false;

   constructor(
      public dialogRef: MatDialogRef<CloseCaseComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private caseProblemRecordService: CaseProblemRecordService,
      private cdr: ChangeDetectorRef,
      private dialog: MatDialog,
      private translate: TranslateService
   ) {
      this.closeCaseForm = this.formBuilder.group({
         reason: [],
         risks: [],
         comment: [],
         lawyerFile: [],
         fileName: [],
      });
      if (this.data.waitingForApproval === true) {
         const closeCaseLocal = data.closingCaseData;
         if (closeCaseLocal !== null) {
            this.closeCaseForm.patchValue({
               reason: closeCaseLocal.closeCaseCodebook,
               risks: closeCaseLocal.explanation,
               comment: closeCaseLocal.comment,
               fileName: closeCaseLocal.documentName,
            });
         }
      }
      this.caseHasProblem();
      this.getCloseCaseCodebook();
   }

   getCloseCaseCodebook() {
      this.codebookService.getCloseCaseCodebook().subscribe(result => {
         const temp = result;
         this.reasons.push(temp.filter((element1: any) => element1.id === 5)[0]);
         temp.forEach((element2: any) => {
            if (element2.id !== 5) {
               this.reasons.push(element2);
            }
         });
      });
   }

   closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
   }

   ngOnInit() {}

   closeCase() {
      this.isFormDigitallySigned = true;
      const closeCase = {
         comment: this.closeCaseForm.controls.comment.value,
         explanation: this.closeCaseForm.controls.risks.value,
         closeCaseCodebook: this.closeCaseForm.controls.reason.value,
         fileToUpload: this.fileToUpload,
         sendDirectly: this.fileToUpload !== null && this.fileToUpload !== undefined,
      };
      this.dialogRef.close({ event: 'Success', data: closeCase });
   }

   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   handleFileInput(files: FileList) {
      const modifiedFiles = fileCheck(files, this.dialog, this.translate);

      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.closeCaseForm.get('lawyerFile').setValue(this.fileToUpload);
         this.closeCaseForm.get('fileName').setValue(this.fileToUpload.name);
      }
   }

   handleFileDelete() {
      this.fileToUpload = null;
      this.closeCaseForm.get('lawyerFile').setValue(null);
      this.closeCaseForm.get('fileName').setValue(null);
   }

   problemAdded(added: boolean) {
      if (added) {
         this.hasProblem = true;
         this.cdr.detectChanges();
      }
   }
   caseHasProblem() {
      if (this.data.type !== null && this.data.type === 'PMN') {
         this.hasProblem = true;
      } else {
         this.caseProblemRecordService.caseHasProblem('ACCEPTED', this.data.caseId, this.data?.caseKind).subscribe(hasProblem => {
            this.hasProblem = hasProblem;
         });
      }
   }
}
