<container kind="default">
    <page-intro-header back="/established-foster-care" title="{{ 'ESTABLISHED_FOSTER_CARE.TITLE' | translate }}">
  </page-intro-header>
</container>
<container kind="default">
    <mat-card class="card">
        <definition-list>
      <definition-item *ngIf="establishedFosterCare?.ordinalNumber" key="{{ 'PROTEGE_DETAILS.R_BR' | translate }}">
        {{ establishedFosterCare.ordinalNumber }}</definition-item>
            <definition-item *ngIf="establishedFosterCare?.fosterParentSubjectId"
                key="{{ 'PROTEGE_DETAILS.ID' | translate }}">
                {{ establishedFosterCare.fosterParentSubjectId }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.fosterParentFirstName"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.FIRST_NAME' | translate }}">
                {{ establishedFosterCare.fosterParentFirstName }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.fosterParentLastName"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.LAST_NAME' | translate }}">
                {{ establishedFosterCare.fosterParentLastName }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.fosterParentGender"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.GENDER' | translate }}">
                {{ establishedFosterCare.fosterParentGender.gender }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.fosterParentJmbg"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.JMBG' | translate }}">
                {{ establishedFosterCare.fosterParentJmbg }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.fosterParentDateOfBirth"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.DATE_OF_BIRTH' | translate }}">
                {{ establishedFosterCare.fosterParentDateOfBirth }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.fosterParentPlaceOfBirth"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ establishedFosterCare.fosterParentPlaceOfBirth }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.fosterParentPermanentResidence"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.PLACE_OF_ORIGIN' | translate }}">
                {{ establishedFosterCare.fosterParentPermanentResidence }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.childrenInFamilyCount"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.CHILDREN_IN_FAMILY_COUNT' | translate }}">
                {{ establishedFosterCare.childrenInFamilyCount }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.childrenInFosterCareCount"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.CHILDREN_IN_FOSTER_CARE_COUNT' | translate }}">
                {{ establishedFosterCare.childrenInFosterCareCount }}</definition-item>

            <definition-item *ngIf="establishedFosterCare?.competentCenterForSocialWork"
                key="{{ 'NEW_ESTABLISHED_FOSTER_CARE.FORM.COMPETENT_CENTER' | translate }}">
                {{ establishedFosterCare.competentCenterForSocialWork }}</definition-item>

            <card-subgroup-divider></card-subgroup-divider>


            <h2 *ngIf="establishedFosterCare?.children.length > 0">{{
          'NEW_ESTABLISHED_FOSTER_CARE.CHILDREN_IN_FOSTER_CARE' | translate
        }}
            </h2>
            <div class="members__table-wrapper table-responsive-wrapper"
                *ngIf="establishedFosterCare?.children.length > 0">
                <table mat-table [dataSource]="establishedFosterCare.children" #mytable class="members__table">
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                  'NEW_ESTABLISHED_FOSTER_CARE.TABLE.FIRST_AND_LAST_NAME'
                    | translate
                }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.firstName + ' ' + element.lastName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dateOfBirth">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                  'NEW_ESTABLISHED_FOSTER_CARE.TABLE.DATE_AND_PLACE_OF_BIRTH'
                    | translate
                }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.dateOfBirth + ' ' + element.placeOfBirth }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="kinship">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'NEW_ESTABLISHED_FOSTER_CARE.TABLE.KINSHIP' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.kinship }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="formOfFosterCare">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                  'NEW_ESTABLISHED_FOSTER_CARE.TABLE.FORM_OF_FOSTER_CARE'
                    | translate
                }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.formOfFosterCare?.title }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dateOfPlacementInAFosterFamily">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                  'NEW_ESTABLISHED_FOSTER_CARE.TABLE.DATE_OF_PLACEMENT_IN_A_FOSTER_FAMILY'
                    | translate
                }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.dateOfPlacementInAFosterFamily | date: 'dd.MM.yyyy.' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="childSupportBearers">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                  'NEW_ESTABLISHED_FOSTER_CARE.TABLE.CHILD_SUPPORT_BEARERS'
                    | translate
                }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.childSupportBearers }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dateOfCustodyTermination">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                  'NEW_ESTABLISHED_FOSTER_CARE.TABLE.DATE_OF_CUSTODY_TERMINATION'
                    | translate
                }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.dateOfCustodyTermination | date: 'dd.MM.yyyy.' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="reasonsForCustodyTermination">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                  'NEW_ESTABLISHED_FOSTER_CARE.TABLE.REASONS_FOR_CUSTODY_TERMINATION'
                    | translate
                }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.reasonsForCustodyTermination?.title }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="courtNumber">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'NEW_ESTABLISHED_FOSTER_CARE.TABLE.COURT_NUMBER' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.courtNumber }}
                        </td>
                    </ng-container>
                     <ng-container matColumnDef="courtName">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'NEW_ESTABLISHED_FOSTER_CARE.TABLE.COURT_NAME' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.courtName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="competentCenter">
                        <th mat-header-cell *matHeaderCellDef>
                            {{
                  'NEW_ESTABLISHED_FOSTER_CARE.TABLE.COMPETENT_CENTER' | translate
                }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.competentCenter }}
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="actions" >
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
                          <button  mat-icon-button 
                            class="button-square table__row-button" color="primary"
                            matTooltip="{{ 'DEPENDENTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
                            #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                            <mat-icon class="button-square__icon">more_horiz</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu" class="filter-menu" >
                            <button  mat-menu-item (click)="openNewTermination(element)" 
                               [disabled]="element.dateOfCustodyTermination !== null && element.dateOfCustodyTermination !== undefined &&
                                           element.reasonsForCustodyTermination !== null && element.reasonsForCustodyTermination !== undefined &&
                                           element.courtName !== null && element.courtName !== undefined && element.courtName !== '' && 
                                           element.courtNumber !== null && element.courtNumber !== undefined && element.courtNumber !== ''">
                              <mat-icon>person_add</mat-icon>
                              {{ 'ESTABLISHED_FOSTER_CARE.NEW_TERMINATION' | translate }}
                            </button>
                
                          </mat-menu>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table__row--header"></tr>
                    <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" class="table__row"></tr>

                </table>
            </div>
        </definition-list>
    </mat-card>
</container>
