<!-- Forma za nadležno lice -->
<form class="form" [formGroup]="assignCaseForm" (ngSubmit)="assignCase(assignCaseForm)">
  <form-group
    title="{{!local_data.isTeamRequest ? ('ASSIGN_CASE.TITLE' | translate) : ('TEAM_REQUEST.TITLE' | translate) }}"
    isLast="true">
    <field-holder *ngIf="!local_data.isTeamRequest">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ASSIGN_CASE.USER' | translate }}</mat-label>
        <input type="text" matInput formControlName="user" [matAutocomplete]="autoUser" (focusout)="autoSelectUser()"
          (focus)="focusUserInput()" [required]="!local_data.isTeamRequest" />
        <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayCustomFormat"
          (optionSelected)="filterTeam($event.option, false); checkRoles($event.option.value, false)">
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
            {{ user.name }}
            ({{user.role}} <ng-container *ngIf="user.roleDescription !== null"> - {{ user.roleDescription}}
            </ng-container>)
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="assignCaseForm.get('user').hasError('required')">
          {{ 'ASSIGN_CASE.USER_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder *ngIf="!local_data.isTeamRequest && isWorkerAndLawyer">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ASSIGN_CASE.ROLE' | translate }}</mat-label>
        <mat-select formControlName="role" [required]="isWorkerAndLawyer" [compareWith]="compareRoles">
          <mat-option *ngFor="let role of roles" [value]="role">
            {{ role.roleName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="assignCaseForm.get('user').hasError('required')">
          {{ 'ASSIGN_CASE.USER_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder *ngIf="local_data.isTeamRequest">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ASSIGN_CASE.USER' | translate }}</mat-label>
        <input matInput type="text" formControlName="assignee" readonly>
      </mat-form-field>
    </field-holder>

    <field-holder *ngIf="local_data.isTeamRequest">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'TEAM_REQUEST.REQUEST' | translate }}</mat-label>
        <input matInput type="text" formControlName="request" readonly>
      </mat-form-field>
    </field-holder>

    <field-holder kind="2-cols" *ngIf="!local_data.isTeamRequest">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ASSIGN_CASE.HOLD_CASE_START_DATE' | translate
          }}</mat-label>
        <input matInput [matDatepicker]="pickerHoldCaseStartDate" [min]="dateOfCreation"
          formControlName="holdCaseStartDate" [required]="!local_data.isTeamRequest" [max]="maxDate"
          autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="pickerHoldCaseStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerHoldCaseStartDate></mat-datepicker>
        <mat-error *ngIf="assignCaseForm.get('holdCaseStartDate').hasError('required')">
          {{ 'ASSIGN_CASE.HOLD_CASE_START_DATE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ASSIGN_CASE.HOLD_CASE_END_DATE' | translate
          }}</mat-label>
        <input matInput [matDatepicker]="pickerHoldCaseEndDate" [min]="minToDate" formControlName="holdCaseEndDate"
          [max]="maxDate" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="pickerHoldCaseEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerHoldCaseEndDate></mat-datepicker>
      </mat-form-field>
    </field-holder>
    <field-holder *ngIf="!local_data.isTeamRequest">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ASSIGN_CASE.WORKLOAD' | translate }}</mat-label>
        <input matInput type="number" (change)="checkValueOfWorkload()" formControlName="workload" autocomplete="off"
          min="1" max="5" />
      </mat-form-field>
    </field-holder>
  </form-group>

  <article>
    <h6 class="members-title">
      {{ 'ASSIGN_CASE.MEMBERS_TITLE' | translate }}
    </h6>

    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ASSIGN_CASE.USER' | translate }}</mat-label>
        <mat-select formControlName="team" [required]="local_data.isTeamRequest || isTeamRequired" multiple
          [compareWith]="compareTeamMembers">
          <mat-option *ngFor="let user of teamFiltered" [value]="user.id">
            {{ user.name }} ({{user.role}})
          </mat-option>
        </mat-select>
        <mat-error *ngIf="assignCaseForm.get('user').hasError('required')">
          {{ 'ASSIGN_CASE.USER_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </article>
  <field-holder *ngIf="forwardingLawyerWorker && userService.isUserLawyer()">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_SUBJECT.OTHER.NOTE' | translate }}</mat-label>
      <textarea matInput type="text" formControlName="taskDescription" maxlength="250" required
        autocomplete="off"></textarea>
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ 'DIALOG.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--secondary"
      [disabled]="!assignCaseForm.valid || disableBtn">
      {{ 'DIALOG.CONFIRM' | translate }}
    </button>

    <button mat-flat-button color="warn" *ngIf="local_data.isTeamRequest" type="button" (click)="declineTeamReaquest()"
      class="form-footer__button form-footer__button--primary">
      {{ 'DIALOG.DECLINE_TEAM' | translate }}
    </button>
  </form-footer>
</form>