<container kind="midi">
   <case-page-header (handleComplainAccepted)="acceptedComplain()" (newCaseStatus)="updateStatus($event)"
      (saveEvent)="saveData()" *ngIf="caseData" [caseData]="caseData"  [caseId]="caseId" [subjectId]="subjectId" [teamMember]="teamMember"
      [caseName]="caseName" (newProblem)="problemAdded($event)" [isLawyer]="isLawyer">
   </case-page-header>
   <page-intro-header title="{{title}}"></page-intro-header>
   <mat-card class="card nsp-wrapper" *ngIf="teamMember===false">
      <ng-container *ngIf="!isLawyer">
         <mat-vertical-stepper [linear]="true" #stepper>
            <mat-step [stepControl]="observationStep">
               <ng-template matStepLabel>{{
          'NSP_MOCK.OBSERVATION.TITLE' | translate
        }}</ng-template>
               <nsp-observation-step [observationStep]="observationStep" [editable]="editable" [noJmbp]="noJmbp"
                  [noRegistrationId]="noRegistrationId" [noAbleToWorkId]="noAbleToWorkId" [noJmbg]="noJmbg"
                  (observationStepEmitter)="updateObservationStep($event)" [hasProblem]="hasProblem" [customData]="customData">
               </nsp-observation-step>
            </mat-step>
            <mat-step [stepControl]="familyMembersStep">
               <ng-template matStepLabel>{{
          'NSP_MOCK.FAMILY.TITLE' | translate
        }}</ng-template>
               <nsp-family-step [familyMembersStep]="familyMembersStep" [editable]="editable" [subjectData]="subject"
                  (familyMembersStepEmitter)="updateFamilyMembersStep($event)" [customData]="customDataFamilyStep" 
                   [sameJmbps]="sameJmbps">
               </nsp-family-step>
            </mat-step>
            <mat-step [stepControl]="propertyStep">
               <ng-template matStepLabel>
                  {{'NSP_MOCK.PROPERTY.TITLE' | translate}}
               </ng-template>
               <nsp-property-step [propertyStep]="propertyStep" [editable]="editable"
                  [nspAmount]="nspAmountForPropertyStep" [familyMembersData]="dataFromFamilyForConclusion" 
                  [subjectData]="subject" [caseCreated]="caseData?.dateOfCreation"
                  (propertyStepEmitter)="updatePropertyStep($event)" [customData]="customData">
               </nsp-property-step>
            </mat-step>
            <mat-step [stepControl]="conclusionStep">
               <ng-template matStepLabel>
                  {{ 'NSP_MOCK.CONCLUSION.TITLE' | translate }}
               </ng-template>
               <nsp-conclusion-step [sendClicked]="sendClicked" [conclusionStep]="conclusionStep" [editable]="editable"
                  [subjectData]="subject" [dataFromFamily]="dataFromFamilyForConclusion"
                  [readOnlyData]="readOnlyDataForConclusionStep" [isFormFinished]="isFormFinished"
                  (conclusionStepEmitter)="updateConclusionStep($event)"
                  (signatureEmitter)="handleSignature()" (saveDataForConclusionEmitter)="saveConclusionStepData($event)"
                  [customData]="customData" (printPreviewEmitter)="printPreview($event)" [startDate]="startDate">
               </nsp-conclusion-step>
            </mat-step>
         </mat-vertical-stepper>
      </ng-container>
      <app-case-conclusion *ngIf="isLawyer && teamMember===false && customData!==null" [caseId]="caseId"
         [subjectId]="subjectId" [templateKind]="caseName" [disableFields]="!editable" [isNsp]="true"
         [customData]="customData" [complain]="caseData?.newComplain" [isDenial]="isDenial" [hasProblem]="hasProblem" >
      </app-case-conclusion>
   </mat-card>
   <mat-card *ngIf="teamMember">
      <team-member [editable]="editable" [caseId]="caseId" [subjectId]="subjectId"></team-member>
   </mat-card>
</container>
