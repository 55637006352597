<header class="header">
   <card-header title="{{ 'SUBJECT_DOCUMENTS.TITLE' | translate }}">
      <div class="header__actions">

         <form [formGroup]="templatesForm" class="form">
            <ng-container *ngIf="(createDocumentOption && !isUserDrugostepeni)">
               <mat-form-field class="selectDocs">
                  <mat-label>{{
                     'SUBJECT_DOCUMENTS.CREATE_TEMPLATE' | translate
                     }}</mat-label>
                  <input type="text" matInput formControlName="templateName" [matAutocomplete]="autoTemplate" />
                  <button *ngIf="templatesForm.value.templateName" matSuffix mat-icon-button (click)="clear()">
                     <mat-icon>close</mat-icon>
                  </button>

                  <mat-autocomplete #autoTemplate="matAutocomplete"
                     (optionSelected)="generateTemplate($event.option.value)" [displayWith]="displayCustomFormat"
                     panelWidth="auto">
                     <mat-option *ngFor="let option of filteredDocumentOptions | async" [value]="option">
                        {{ option.name }}
                     </mat-option>
                  </mat-autocomplete>
               </mat-form-field>
            </ng-container>
         </form>
         <input type="file" #file (change)="handleFileInput($event.target.files)" hidden>
         <button *ngIf="!isUserDrugostepeni" class="header__add-document" mat-flat-button color="primary" type="button"
            (click)="file.click()" [disabled]="addButtonDisabled">
            {{ 'SUBJECT_DOCUMENTS.CREATE' | translate }}
         </button>
         <button mat-icon-button class="button-square" color="primary" [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger" [matBadge]="badgeCount" [matBadgeHidden]="badgeCount === 0"
            matTooltip="{{ 'SUBJECT_DOCUMENTS.TOOLTIP_FILTER' | translate }}">
            <mat-icon class="button-square__icon">filter_list</mat-icon>
         </button>
         <mat-menu #menu="matMenu" class="filter-menu">
            <form [formGroup]="filterForm" class="filter-menu__content" (click)="$event.stopPropagation()">
               <field-holder>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ 'SUBJECT_DOCUMENTS.FILTER.FROM' | translate }}</mat-label>
                     <input matInput [matDatepicker]="minDatepicker" formControlName="minDate" [max]="maxDate" />
                     <mat-datepicker-toggle matSuffix [for]="minDatepicker"></mat-datepicker-toggle>
                     <mat-datepicker #minDatepicker></mat-datepicker>
                  </mat-form-field>
               </field-holder>
               <field-holder>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ 'SUBJECT_DOCUMENTS.FILTER.TO' | translate }}</mat-label>
                     <input matInput [matDatepicker]="maxDatepicker" formControlName="maxDate" [max]="maxDate" />
                     <mat-datepicker-toggle matSuffix [for]="maxDatepicker"></mat-datepicker-toggle>
                     <mat-datepicker #maxDatepicker></mat-datepicker>
                  </mat-form-field>
               </field-holder>
               <form-footer>
                  <button class="form-footer__button form-footer__button--secondary" mat-stroked-button color="primary"
                     type="button" (click)="resetFilter()">
                     {{ 'SUBJECT_DOCUMENTS.FILTER.RESET' | translate }}
                  </button>
                  <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                     type="button" (click)="applyFilter()">
                     {{ 'SUBJECT_DOCUMENTS.FILTER.APPLY' | translate }}
                  </button>
               </form-footer>
            </form>
         </mat-menu>
      </div>
   </card-header>
   <div [class]="appliedFilters.length > 0 ? 'mb-30' : ''">
      <mat-chip-list #chipList aria-label="Applied filters">
         <mat-chip *ngFor="let filter of appliedFilters" (removed)="removeFilter(filter)">
            {{ filter.displayValue }}
            <mat-icon matChipRemove>cancel</mat-icon>
         </mat-chip>
      </mat-chip-list>
   </div>
</header>

<ng-container *ngIf="!isLoading">
   <ul class="document-list">
      <li *ngFor="let document of page.content">
         <document-list-item title="{{ document.name }}" author="{{ document.author }}"
            authorId="{{ document.authorId }}" caseName="{{ document.caseName }}"
            referenceNumber="{{ document.delovodniBroj }}" caseId="{{ document.caseId }}"
            dateOfExpedition="{{document.dateOfExpedition}}" editEnabled="{{ this.addButtonDisabled }}"
            modifiedAt="{{ document.dateOfModification }}" (handleClick)="openDialog('view', document)"
            (handleSend)="openDialog('send', document)" (handleDelete)="openDialog('delete', document)"
            (handleDownload)="openDialog('download', document)" (handlePrint)="openDialog('print', document)"
            (handleViewInNewTab)="openDialog('viewInNewTab', document)" caseStatus="{{document.caseStatus}}"
            assignedUserId="{{document.assignedUserId}}" teamMembers="{{document.teamMembers}}">
         </document-list-item>

      </li>
      <li class="document-list__item document-list__item--empty" *ngIf="page.content.length === 0">
         <mat-icon class="document-list__icon document-list__icon--empty">info</mat-icon>
         {{ 'SUBJECT_DOCUMENTS.NO_RESULTS' | translate }}
      </li>
   </ul>
   <div class="pagination-wrapper" *ngIf="page.content.length !== 0">
      <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()"
         (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()" (pageSizeEvent)="getPageInNewSize($event)">
      </app-custom-pagination>
   </div>
</ng-container>
<ng-container *ngIf="isLoading">
   <div class="spinner-wrapper">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
   </div>
</ng-container>