/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/services/authentication.service';
import { StatisticsService } from 'src/services/statistics.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { CodebookService } from 'src/services/codebook.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/services/user.service';
import { map, startWith } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';

enum AutocompleteFilterEnum {
  USER = 'user',
  CENTERS = 'centers',
}
@Component({
  selector: 'document-stats',
  templateUrl: './document-stats.component.html',
  styleUrls: ['./document-stats.component.scss']
})

export class DocumentStatsComponent implements OnInit {
  documentStatsForm: FormGroup;
  statTypeOptions = [
    { id: 1, name: 'документи' },
    { id: 2, name: 'предмети' },
  ];

  date1: any;
  date2: any;
  user: any = '';
  users: any = [];
  filteredUsers: Observable<any[]>;
  centers = [];
  filteredAllCenters: Observable<any>;
  loggedIn: any;
  isMainCenterBelgrade = false;
  isUserDrugostepeni = false;
  btnDisabled: boolean;
  maxDate: Date = new Date(2999, 11, 31);

  constructor(
    private formBuilder: FormBuilder,
    public authenticationService: AuthenticationService,
    private statisticsService: StatisticsService,
    private localStorageService: LocalStorageService,
    private codebookService: CodebookService,
    private translate: TranslateService,
    private userService: UserService) {
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.isMainCenterBelgrade = (this.loggedIn?.csrId === '1') ? true : false;
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    if (this.isMainCenterBelgrade) {
      this.codebookService.getAllSubcenters().subscribe(result => {
        this.centers = result;
        this.filteredAllCenters = this.documentStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );
      });
    }
    if(this.isUserDrugostepeni){
      this.codebookService.getCsrCodebook().subscribe((result) => {
        this.centers = result;
        this.filteredAllCenters = this.documentStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );
     });
    }
    if (!this.isMainCenterBelgrade && !this.isUserDrugostepeni) {
      this.userService.getUsersInCenterByCsrId(this.loggedIn.csrId).subscribe((res) => {
        this.users = res;
      });
    }
    this.documentStatsForm = this.formBuilder.group({
      statType: ['', []],
      workerName: ['', []],
      from: ['', []],
      to: ['', []],
      center: ['']
    });
    this.statisticsService.getBtnStatus().subscribe((status) => {
      this.btnDisabled = status;
    });
    this.btnDisabled = false;
  }

  ngOnInit(): void {
    this.findWorkers(null);
  }
  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name : objectValue;
  }
  autoSelectCenter(){
    autoSelect(this.centers, 'center', 'name', this.documentStatsForm);
  }
  /**
   * Send request and generate Excell report
   */
  sendRequest(form: any) {

      this.date1 = new Date(this.documentStatsForm.value.from);
      this.date1.setDate(this.date1.getDate() + 1);
      this.date2 = new Date(this.documentStatsForm.value.to);
      this.date2.setDate(this.date2.getDate() + 1);

      const newObject = {
        userId: (this.documentStatsForm.value.workerName !== undefined && this.documentStatsForm.value.workerName !== '') ? this.documentStatsForm.value.workerName.id : '',
        fullname: (this.documentStatsForm.value.workerName !== undefined && this.documentStatsForm.value.workerName !== '')?this.documentStatsForm.value.workerName.name: '',
        fromDate: this.date1.toISOString().split('T')[0].replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1'),
        toDate: this.date2.toISOString().split('T')[0].replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1')
      };
      let csrId = this.loggedIn.csrId;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
        csrId = form.value.center.id;
      }
      let filename = '';

      if (form.value.statType.id === 1) {
         this.translate.get('STATISTICS.DOCUMENT_STATS.DOCUMENTS_EXPORT').subscribe((res: string) => {
            filename = res;
         });
         this.statisticsService.getDocumentsStats(csrId, newObject, filename);
      } else {
         this.translate.get('STATISTICS.DOCUMENT_STATS.SOCIAL_CASES_EXPORT').subscribe((res: string) => {
            filename = res;
         });
         this.statisticsService.getSocialCaseStats(csrId, newObject, filename);
      }
  }

  findWorkers(event: any) {
    let centerId = this.loggedIn.csrId;
    if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
      centerId = this.documentStatsForm.get('center').value.id;
    }
    if (event !== null) {
      this.userService.getUsersInCenterByCsrId(centerId).subscribe((res) => {
        this.users = res;
      });
    }
  }
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
       case AutocompleteFilterEnum.CENTERS:
          return sortByFirstLetter(this.centers.filter((option) =>
             option.name.toLowerCase().includes(filterValue)), filterValue, 'name');
       default:
          break;
    }
 }
}
