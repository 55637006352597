import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';

@Component({
    selector: 'app-add-jmbg',
    templateUrl: './add-jmbg.component.html',
    styleUrls: ['./add-jmbg.component.scss']
})
export class AddJmbgComponent implements OnInit {

    addJmbgForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<AddJmbgComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public userId: any,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private localStorageService: LocalStorageService,
        public toastr: ToastrImplService) {

        this.addJmbgForm = this.formBuilder.group({
            jmbg: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

    }

    closeDialog() {
        const jmbg = this.addJmbgForm.get('jmbg').value;
        this.dialogRef.close({ event: 'Cancel', jmbg});
    }

    changeJmbg() {
        const jmbg = this.addJmbgForm.get('jmbg').value;
        const password = btoa(this.addJmbgForm.get('password').value);
        const changeRequest = { jmbg,  password};

        if (this.checkJMBGRule()) {
            this.userService.changeJmbg(changeRequest, this.userId).subscribe({
                next: () => {
                   this.handleChangeSuccess(jmbg);
                },
                error: () => {
                   this.handleChangeFail();
                }
            });
        }
    }
    private handleChangeSuccess(jmbg: string) {
        const loggedUser = JSON.parse(this.localStorageService.get('loggedUser'));
        loggedUser.jmbg = jmbg;
        this.localStorageService.set('loggedUser', JSON.stringify(loggedUser));
        this.toastr.success('PROFILE.ADD_JMBG_SUCCESS');
        this.dialogRef.close({ event: 'SUCCESS', jmbg });
    }

    private handleChangeFail() {
        this.toastr.error('PROFILE.ADD_JMBG_FAIL');
    }

    checkJMBGRule(): boolean {
        if (this.addJmbgForm.value.jmbg !== null && this.addJmbgForm.value.jmbg !== '') {
            if (this.addJmbgForm.value.jmbg.length < 13) {
                this.addJmbgForm.get('jmbg').setErrors({ pattern: true });
                return false;
            } else {
                const jmbg = this.addJmbgForm.value.jmbg;
                const digits = jmbg.split('').map(Number);
                const delimiter = 11;
                let sum = 0;
                let start = 7;
                for (let index = 0; index < digits.length - 1; index++, start--) {
                    if (start === 1) {
                        start = 7;
                    }
                    sum += start * digits[index];
                }
                let controlNumber = sum % delimiter;
                if (controlNumber > 1) {
                    controlNumber = delimiter - controlNumber;
                }
                if (controlNumber !== digits[digits.length - 1]) {
                    this.addJmbgForm.get('jmbg').setErrors({ pattern: true });
                    this.addJmbgForm.controls.jmbg.markAsTouched();
                    setTimeout(() => {
                        this.addJmbgForm.get('jmbg').setErrors({ pattern: true });
                        this.addJmbgForm.controls.jmbg.markAsTouched();
                        return false;
                    });
                    return false;
                }
                return true;
            }
        }
        return false;
    }

    ngOnInit(): void {
    }

}
