<container>
  <mat-card class="card">
    <card-header title="{{ 'STATISTICS.NSP_STATS.TITLE' | translate }}">
    </card-header>
    <form [formGroup]="nspStatsForm" class="form">
      <field-holder kind="2-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
          <mat-form-field appearance="outline">
            <mat-label>
              {{'GLOBAL.CENTER' | translate}}
            </mat-label>
            <input type="text" matInput [matAutocomplete]="autoSubcenter" formControlName="center"
            (focusout)="autoSelectCenter()" required/>
            <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete"
              [displayWith]="displayCustomFormat">
              <mat-option *ngFor="let cent of filteredAllCenters | async" [value]="cent">
                {{ cent.name }}
              </mat-option>
           </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'STATISTICS.NSP_STATS.RECORD' | translate }}</mat-label>
            <mat-select formControlName="record" required>
            <mat-option *ngFor="let record of recordOptions" [value]="record">
              {{ record.name }}
            </mat-option>
          </mat-select>
          </mat-form-field>
      </field-holder>
      <field-holder *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'STATISTICS.NSP_STATS.RECORD' | translate }}</mat-label>
          <mat-select formControlName="record" required>
          <mat-option *ngFor="let record of recordOptions" [value]="record">
            {{ record.name }}
          </mat-option>
        </mat-select>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
       <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.BASIC_STATS.REPORTING_PERIOD_FROM' | translate }}</mat-label>
          <input matInput type="text" [matDatepicker]="dateFrom" formControlName="dateFrom" autocomplete="off"
            placeholder="{{ 'STATISTICS.BASIC_STATS.REPORTING_PERIOD_FROM' | translate }}" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
          <mat-datepicker #dateFrom></mat-datepicker>
          <mat-error *ngIf="nspStatsForm.get('dateFrom').hasError('required')">
            {{ "STATISTICS.DATE_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.BASIC_STATS.REPORTING_PERIOD_TO' | translate }}</mat-label>
          <input matInput type="text" [matDatepicker]="dateTo" formControlName="dateTo" autocomplete="off"
            placeholder="{{ 'STATISTICS.BASIC_STATS.REPORTING_PERIOD_TO' | translate }}" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
          <mat-datepicker #dateTo></mat-datepicker>
          <mat-error *ngIf="nspStatsForm.get('dateTo').hasError('required')">
            {{ "STATISTICS.DATE_ERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      
      <form-footer>
        <button
          class="form-footer__button form-footer__button--primary"
          mat-flat-button
          color="primary"
          [disabled]="!this.nspStatsForm.valid || btnDisabled"
          (click)="sendRequest(nspStatsForm)"
        >
          {{ 'STATISTICS.SUBMIT' | translate  }}
        </button>
        <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </form-footer>
    </form>
  </mat-card>
</container>
  