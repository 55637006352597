import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { AccommodationFacilityService } from 'src/services/accommodation-facility-service';
import { CodebookService } from 'src/services/codebook.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { AddCategoryPriceComponent } from './add-category-price/add-category-price.component';
import { AddSupportPriceComponent } from './add-support-price/add-support-price.component';

@Component({
   selector: 'app-facility-pricing',
   templateUrl: './facility-pricing.component.html',
   styleUrls: ['./facility-pricing.component.scss'],
})
export class FacilityPricingComponent implements OnInit {
   accommodationFacilityForm: FormGroup;
   accommodationFacilityCodebook: any;
   currentFacility: any;

   displayedColumns: string[] = ['category', 'price', 'pricePerDay', 'validFrom', 'validTo', 'remove'];
   displayedColumnsSupport: string[] = ['category', 'price', 'validFrom', 'validTo', 'remove'];

   warningObj: any = {
      message: '',
      title: '',
      action: '',
   };

   constructor(
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      public dialog: MatDialog,
      public translate: TranslateService,
      public accommodationFacilityService: AccommodationFacilityService
   ) {}

   ngOnInit(): void {
      this.accommodationFacilityForm = this.formBuilder.group({
         id: ['', []],
         accommodationCategory: ['', []],
         subCategory: ['', []],
         monthlyAmount: ['', []],
         dailyAmount: ['', []],
         validFrom: ['', []],
         accomodationFacility: ['', []],
      });

      this.getAccommodationFacilityCodebook();
   }

   getAccommodationFacilityCodebook() {
      this.codebookService.getAccommodationFacilityCodebook().subscribe(res => {
         this.accommodationFacilityCodebook = res;
      });
   }

   selectFacility(value: any) {
      this.currentFacility = value;
   }

   addNewPricing() {
      const selectedCategories = this.currentFacility?.accommodationPricingCodebook.map(facility => facility.accommodationCategoryCodebook);

      const dialogRef = this.dialog.open(AddCategoryPriceComponent, {
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: { isEdit: false, selectedCategories },
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result && result.event !== ModalEventEnum.CANCEL && result.data) {
            this.saveNewPricings(result.data);
         }
      });
   }

   onRowClicked(element: any) {
      const dialogRef = this.dialog.open(AddCategoryPriceComponent, {
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: { isEdit: true, rowData: element },
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result && result.event !== ModalEventEnum.CANCEL && result.data) {
            this.editPricing(result.data, element.id);
         }
      });
   }

   removePricingWarning(element: any) {
      this.translate.get('WARNING_DIALOG.ACCOMMODATION_CATEGORY_DELETE.MESSAGE').subscribe((res: string) => {
         this.warningObj.message = res;
      });
      this.translate.get('WARNING_DIALOG.ACCOMMODATION_CATEGORY_DELETE.TITLE').subscribe((res: string) => {
         this.warningObj.title = res;
      });
      this.warningObj.action = ModalEventEnum.DELETE;

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         panelClass: 'overlay-panel',
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: this.warningObj,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.DELETE && data.status) {
            this.removePricing(element.id);
         }
      });
   }

   private saveNewPricings(pricing: any) {
      const newPricings = [];
      for (const category of pricing.accommodationCategory) {
         newPricings.push({
            accommodationCategoryCodebook: category,
            price: pricing.price,
            pricePerDay: pricing.pricePerDay,
            validTo: pricing.validTo,
            validFrom: pricing.validFrom,
         });
      }
      this.accommodationFacilityService
         .addCategoriesToFacilityCodebook(this.currentFacility.id, newPricings)
         .subscribe((accommodationPricingCodebooks: any) => {
            if (accommodationPricingCodebooks) {
               this.currentFacility.accommodationPricingCodebook = [...accommodationPricingCodebooks];
            }
         });
   }

   private editPricing(newPricing: any, pricingId: any) {
      this.accommodationFacilityService.editCategoryPricingFromFacilityCodebook(this.currentFacility.id, pricingId, newPricing).subscribe(() => {
         this.currentFacility.accommodationPricingCodebook.forEach(pricing => {
            if (pricing.id === pricingId) {
               pricing.price = newPricing.price;
               pricing.pricePerDay = newPricing.pricePerDay;
               pricing.validTo=newPricing.validTo;
               pricing.validFrom=newPricing.validFrom;
            }
         });
      });
   }

   private removePricing(pricingId: any) {
      this.accommodationFacilityService.removeCategoryFromFacilityCodebook(this.currentFacility.id, pricingId).subscribe(() => {
         const index = this.currentFacility.accommodationPricingCodebook.findIndex(pricing => pricing.id === pricingId);
         this.currentFacility.accommodationPricingCodebook.splice(index, 1);
         this.currentFacility.accommodationPricingCodebook = [...this.currentFacility?.accommodationPricingCodebook];
      });
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   // (change)="updateCheckbox($event, 'assigned')"
   changeHasSupportCategory(event: any) {
      this.currentFacility.hasSupportCategory = event.checked;
      //potrebno azurirati facility
   }
   addNewSupport() {
      const selectedCategories = this.currentFacility?.accommodationPricingCodebook.map(facility => facility.accommodationCategoryCodebook);

      const dialogRef = this.dialog.open(AddSupportPriceComponent, {
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: { isEdit: false, selectedCategories },
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result && result.event !== ModalEventEnum.CANCEL && result.data) {
            this.saveNewSupport(result.data);
         }
      });
   }
   private saveNewSupport(pricing: any) {
      const supportCategoryPrice = {
         supportCategoryCodebook: pricing.supportCategory,
         validTo: pricing.validTo,
         validFrom: pricing.validFrom,
         amountMonth: pricing.amountMonth,
      };

      this.accommodationFacilityService
         .addSupportCategoriesToFacilityCodebook(this.currentFacility.id, supportCategoryPrice)
         .subscribe((supportCategoryPrice: any) => {
            if (supportCategoryPrice) {
               this.currentFacility.supportCategoryPrice = [...supportCategoryPrice];
            }
         });
   }

   onSupportRowClicked(element: any) {
      const dialogRef = this.dialog.open(AddSupportPriceComponent, {
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: { isEdit: true, rowData: element },
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result && result.event !== ModalEventEnum.CANCEL && result.data) {
            this.editSupport(result.data, element.id);
         }
      });
   }

   private editSupport(newPricing: any, pricingId: any) {
      newPricing = { ...newPricing, id: pricingId };
      this.accommodationFacilityService
         .editSupportCategoryPricingFromFacilityCodebook(this.currentFacility.id, newPricing)
         .subscribe((supportCategoryPrice: any) => {
            this.currentFacility.supportCategoryPrice = [...supportCategoryPrice];
         });
   }

   removeSupportWarning(element: any) {
      this.translate.get('WARNING_DIALOG.ACCOMMODATION_CATEGORY_DELETE.MESSAGE').subscribe((res: string) => {
         this.warningObj.message = res;
      });
      this.translate.get('WARNING_DIALOG.ACCOMMODATION_CATEGORY_DELETE.TITLE').subscribe((res: string) => {
         this.warningObj.title = res;
      });
      this.warningObj.action = ModalEventEnum.DELETE;

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         panelClass: 'overlay-panel',
         disableClose: true,
         width: ModalSizeEnum.MINI,
         data: this.warningObj,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.DELETE && data.status) {
            this.removeSupport(element.id);
         }
      });
   }
   private removeSupport(pricingId: any) {
      this.accommodationFacilityService.removeSupportCategoryFromFacilityCodebook(this.currentFacility.id, pricingId).subscribe((supportCategoryPrice: any) => {
         this.currentFacility.supportCategoryPrice = [...supportCategoryPrice];
      });
   }
}
