/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

// tslint:disable-next-line: import-spacing
import  { Pageable } from './pageable';
import { Sort } from './sort';

export class Page<T> {
  content: Array<T>;
  pageable: Pageable;
  sort: Sort;
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;

  public constructor() {
    this.pageable = new Pageable();
    this.content = [];
  }

}
