/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormsSOComponent } from './forms-s-o/forms-s-o.component';

@Component({
    selector: 'accommodation',
    templateUrl: './accommodation.component.html',
    styleUrls: ['./accommodation.component.scss']
})
export class AccommodationComponent implements OnInit {
    @Input() data: any = {}; // TODO: Type me
    @Input() editEnabled: boolean;
    @Input() isCaseClosed: boolean;
    @Input() caseId: any;
    @Input() caseData: any;
    @Input() subjectId: any;
    @Output() handleSubmitData = new EventEmitter();
    @Input() waitingForApproval: boolean;
    @Input() accommodation: any;
    @Output() handleSaveData = new EventEmitter();
    @Input() noRegistrationId: boolean;
    @ViewChild(FormsSOComponent) formsSOComponent: FormsSOComponent;
    stepStatus: 'INITIAL' | 'STARTED' | 'FINISHED' = 'INITIAL';
    isEditMode = false;
    accomodationStep: FormGroup;
    submittedData: any; // TODO: Type me
    currentDate = new Date();
    formSOData: any;
    acceptCaseMissingPermitionsTooltip = '';

    constructor(
        private formBuilder: FormBuilder,
        public translate: TranslateService) {
        this.translate.get('GLOBAL.TOOLTIPS.ACCEPT_CASE_MISSING_PERMITIONS').subscribe((res: string) => {
            this.acceptCaseMissingPermitionsTooltip = res;
        });
    }

    ngOnInit(): void {

        this.accomodationStep = this.formBuilder.group({
            plan: ['', []],
            planTitle: ['', []],
            planDescription: ['', []],
        });
    }

    ngOnChanges(changes: any): void {
        if (changes.accommodation && changes.accommodation.currentValue) {
            this.stepStatus = 'STARTED';
        }
        else {
            this.stepStatus = 'INITIAL';
        }
    }


    handleSaveChanges({ step, isFinished ,accommodation}) {
        if (isFinished) {
            this.stepStatus = 'FINISHED';
        } else {
            this.stepStatus = 'STARTED';
        }
        this.isEditMode = false;
        this.handleSaveData.emit(accommodation);
    }

    handleSubmitStep() {
        this.isEditMode = false;
        this.stepStatus = 'INITIAL';
        this.handleSubmitData.emit({
            step: 'STEP_07',
            data: this.accomodationStep,
        });
    }

    handleUpdatePlan() {
        this.isEditMode = false;
    }

    handleInitStep() {
        this.isEditMode = true;
    }

    toggleEdit() {
        this.isEditMode = !this.isEditMode;

        if (this.isEditMode === false) {
            this.accomodationStep.reset();
        }
    }
}
