/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';

@Component({
   selector: 'app-add-user-comment',
   templateUrl: './add-user-comment.component.html',
   styleUrls: ['./add-user-comment.component.scss'],
})
export class AddUserCommentComponent implements OnInit {
   addUserCommentForm: FormGroup;
   minFromDate = new Date();
   relationshipOptions: any;
   maxDate: Date = new Date(2999, 11, 31);
   isEdit = false;

   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<AddUserCommentComponent>,
      private formBuilder: FormBuilder,
      private codebookService: CodebookService
   ) {}

   ngOnInit(): void {
      this.getRelationshipCodebook();
      if (this.data !== null) {
         this.isEdit = true;
         this.patchForm();
      } else {
         this.addUserCommentForm = this.formBuilder.group({
            comment: ['', [Validators.required]],
            commentDate: ['' /*[Validators.required]*/],
            relationship: ['' /*[Validators.required]*/],
         });
      }
   }

   addUserComment() {
      this.dialogRef.close({ event: 'Create', data: this.addUserCommentForm.value });
   }
   editUserComment() {
      this.dialogRef.close({ event: 'Edit', data: this.addUserCommentForm.value });
   }
   closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
   }

   getRelationshipCodebook() {
      this.codebookService.getRelationshipCodebookWithoutUser().subscribe(res => {
         this.relationshipOptions = res;
      });
   }
   checkDateComment(event: any) {
      if (event.value === null) {
         this.addUserCommentForm.controls.commentDate.reset();
      }
   }
   patchForm() {
      this.addUserCommentForm = this.formBuilder.group({
         comment: [this.data.comment, [Validators.required]],
         commentDate: [this.data.commentDate],
         relationship: [this.data.relationship],
      });
   }

   compareRelationship(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }
}
