<container kind="default">
    <h2 class="title">{{ 'NEW_SUBJECT.PERSONAL_DETAILS.CASE_DETAILS' | translate }} {{ caseName }}</h2>
</container>
<container kind="default">
    <mat-card class="card">
        <definition-list>
            <definition-item *ngIf="case?.dateOfCreation" key="{{ 'CASE_DETAILS.DATE_OF_CREATION' | translate }}">
                {{ case.dateOfCreation | date: 'dd.MM.yyyy. h:mm:ss' }}</definition-item>
            <definition-item *ngIf="case?.caseNumber" key="{{ 'CASE_DETAILS.CASE_NUMBER' | translate }}">
                {{ case.caseNumber }}</definition-item>
            <definition-item *ngIf="case?.caseType" key="{{ 'CASE_DETAILS.CASE_TYPE' | translate }}">
                {{ case.caseType }}</definition-item>
            <definition-item *ngIf="case?.socialCaseClassificationCodebook?.title"
                key="{{ 'CASE_DETAILS.CASE_NAME' | translate }}">
                {{ case.socialCaseClassificationCodebook.title }}</definition-item>
            <definition-item *ngIf="case?.subject?.subjectIdentity?.subjectId"
                key="{{ 'CASE_DETAILS.SUBJECT_ID' | translate }}">
                {{ case.subject.subjectIdentity.subjectId }}</definition-item>
            <definition-item *ngIf="assignedUser!==null&&assignedUser.trim()!==''"
                key="{{ 'CASE_DETAILS.ASSIGNED' | translate }}">
                {{ assignedUser }}</definition-item>
            <definition-item *ngIf="case?.caseStatus" key="{{ 'CASE_DETAILS.STATUS' | translate }}">
                <div *ngIf="case?.intoPlanner === false && case?.newComplain===true&&case?.secondDegree!==true">
                    {{translateStatus('COMPLAIN')}} </div>
                <div *ngIf="case?.intoPlanner === false && case?.newComplain!==true&&case?.secondDegree===true">
                    {{translateStatus('SECOND_DEGREE')}} </div>
                <div *ngIf="case?.intoPlanner === false && case?.newComplain!==true&&case?.secondDegree!==true">
                    {{translateStatus(case?.caseStatus)}} </div>
                <div *ngIf="case?.intoPlanner === true">
                    <status-label status="2">
                        {{ 'SEND_INTO_PLANNER.INTO_PLANNER' | translate }}
                    </status-label>
                    <h5>{{'SUBJECT_DOCUMENTS.FILTER.FROM' | translate}} {{case.dateIntoPlanner | date: 'dd.MM.yyyy.'}}
                    </h5>
                </div>
            </definition-item>
            <definition-item *ngIf="case?.imported" key="{{ 'CASE_DETAILS.IMPORTED' | translate }}">
                {{ 'GLOBAL.YES' | translate }}</definition-item>
            <definition-item *ngIf="case?.note" key="{{ 'CASE_DETAILS.NOTE' | translate }}">
                {{case.note}}</definition-item>
            <definition-item *ngIf="case?.closeCaseSignature!==null&&case?.closeCaseSignature.trim()!==''&&(case?.caseStatus=='FINISHED_PROCESSING'||case?.caseStatus=='CLOSED')" key="{{ 'CASE_DETAILS.ASSIGNED' | translate }}">
                    {{case?.closeCaseSignature}}</definition-item>
            <definition-item *ngIf="teamMembersNames.length!==0" key="{{ 'CASE_DETAILS.TEAM_MEMBERS' | translate }}">
                {{teamMembersNames}}</definition-item>

            <definition-item *ngIf="case?.parentImportInfo" key="{{ 'CASE_DETAILS.GUARDIAN' | translate }}">
                {{case.parentImportInfo}}</definition-item>
        </definition-list>
        <card-subgroup-divider></card-subgroup-divider>
    </mat-card>

</container>
<container kind="default">
    <mat-card class="card">
        <subject-documents [addBtnDisabled]="addButtonDisabled"></subject-documents>
    </mat-card>
</container>

<container kind="default">
    <mat-card class="card">

        <card-header title="{{ 'SUBJECT_PROBLEMS.TITLE' | translate }}">
        </card-header>
        <div class="table-responsive-wrapper" *ngIf="page.content.length > 0">
            <table mat-table [dataSource]="page.content" #mytable class="problems-table">
                <ng-container matColumnDef="problemId">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_RECORDS.TABLE.PROBLEM_ID' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.problemId }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_PROBLEMS.TABLE.START_DATE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.fromDate | date: 'dd.MM.yyyy.' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_PROBLEMS.TABLE.END_DATE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element?.toDate | date: 'dd.MM.yyyy.'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_PROBLEMS.TABLE.DURATION' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.duration }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="levelOfSeverity">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_PROBLEMS.TABLE.LEVEL_OF_SEVERITY' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.intensity }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_PROBLEMS.TABLE.DESCRIPTION' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element"
                        class="problems-table__cell problems-table__cell--description">
                        {{ element?.codebookTitle }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" class="problems-table__row"></tr>
            </table>
        </div>
        <div class="pagination" *ngIf="page.content.length > 0">
            <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage()"
                (previousPageEvent)="getPreviousPage()" (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"
                (pageSizeEvent)="getPageInNewSize($event)">
            </app-custom-pagination>
        </div>
        <p class="problems-empty" *ngIf="page.content.length === 0">
            <mat-icon class="problems-empty__icon">info</mat-icon>
            {{ 'SUBJECT_PROBLEMS.NO_RESULTS' | translate }}
        </p>
    </mat-card>
</container>

<container kind="default">
    <mat-card class="card">
        <card-header title="{{ 'SUBJECT_RECORDS.TITLE' | translate }}"> </card-header>
        <div class="table-responsive-wrapper" *ngIf="pageRight.content.length > 0">
            <table mat-table [dataSource]="pageRight.content" #mytable class="records-table">
                <ng-container matColumnDef="problemId">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_RECORDS.TABLE.PROBLEM_ID' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.problemId }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_RECORDS.TABLE.START_DATE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.fromDate | date: 'dd.MM.yyyy.' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_RECORDS.TABLE.END_DATE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.toDate | date: 'dd.MM.yyyy.' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="reimbursment">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_RECORDS.TABLE.REIMBURSMENT' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element?.price">
                            {{ formatNumber(element?.price.toFixed(2)) }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="reimbursmentProvider">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_RECORDS.TABLE.REIMBURSMENT_PROVIDER' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.serviceProviderTitle }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="procedure">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_RECORDS.TABLE.PROCEDURE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="records-table__cell records-table__cell--procedure">
                        {{ element?.caseRightTitle }}
                        {{ element?.servicesTitle }}
                        {{ element?.professionalProceduresTitle }}
                        {{ element?.otherProceduresAcitvitiesTitle }}
                        {{ element?.legalProceduresTitle }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsRec"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsRec" class="records-table__row"></tr>
            </table>
        </div>
        <div class="pagination" *ngIf="pageRight.content.length > 0">
            <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPageRecord()"
                (previousPageEvent)="getPreviousPageRecord()" (nextPageEvent)="getNextPageRecord()"
                (lastPageEvent)="getLastPageRecord()" (pageSizeEvent)="getPageInNewSizeRecord($event)">
            </app-custom-pagination>
        </div>
        <p class="records-empty" *ngIf="pageRight.content.length === 0">
            <mat-icon class="problems-empty__icon">info</mat-icon>
            {{ 'SUBJECT_RECORDS.NO_RESULTS' | translate }}
        </p>
    </mat-card>
</container>