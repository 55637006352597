<mat-dialog-content>
    <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
        [mat-dialog-close]="true"><span class="material-icons">close</span>
    </button>

    <div class="container">
        <form flex [formGroup]="editProtegeDetailsForm" (ngSubmit)="editProtegeDetails()">
            <h2>{{ 'PROTEGE_DETAILS.EDIT_DETAILS' | translate }}</h2>

            <h3 *ngIf="editProperty">{{ 'PROTEGE_DETAILS.PROPERTY_INFO' | translate }}</h3>
            <field-holder *ngIf="editProperty">
                <mat-form-field appearance="outline">
                    <mat-label>{{
        'NEW_PROTEGE.FORM.ASSET_VALUE_ON_CENSUS_DAY' | translate
      }}</mat-label>
                    <input matInput type="text" formControlName="assetValueOnCensusDay" autocomplete="off" />
                </mat-form-field>
            </field-holder>
            <field-holder *ngIf="editProperty">
                <mat-form-field appearance="outline">
                    <mat-label>{{
        'NEW_PROTEGE.FORM.ASSET_VALUE_ON_THE_DAY_OF_TERMINATION_OF_CUSTODY' | translate
      }}</mat-label>
                    <input matInput type="text" formControlName="assetValueOnTheDayOfTerminationOfCustody"
                        autocomplete="off" />
                </mat-form-field>
            </field-holder>

            <h3 *ngIf="editGuardianship">{{ 'NEW_PROTEGE.GUARDIAN.GUARDIAN_TITLE' | translate }}</h3>

            <div *ngIf="editGuardianship" class="button-add-holder">
                <button mat-flat-button color="primary" type="button" (click)="importSubjectData('guardian')">
                    <mat-icon>person_add</mat-icon>
                    {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
                </button>
            </div>
            <br *ngIf="editGuardianship">
            <field-holder *ngIf="editGuardianship" kind="2-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.FIRST_NAME' | translate }}</mat-label>
                    <input matInput type="text" formControlName="guardianFirstName" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.LAST_NAME' | translate }}</mat-label>
                    <input matInput type="text" formControlName="guardianLastName" autocomplete="off" />
                </mat-form-field>
            </field-holder>
            <field-holder *ngIf="editGuardianship">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.JMBG' | translate }}</mat-label>
                    <input matInput type="text" formControlName="guardianJmbg" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                            'NEW_PROTEGE.FORM.RESIDENCE_GUARDIAN' | translate
                          }}</mat-label>
                    <input matInput type="text" formControlName="guardianAddress" autocomplete="off" />
                </mat-form-field>
            </field-holder>
            <field-holder *ngIf="editGuardianship">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.CITIZENSHIP' | translate }}</mat-label>
                    <input type="text" matInput formControlName="guardianCitizenship"
                        [matAutocomplete]="autoCitizenship" />
                    <mat-autocomplete #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let option of filteredCitizenshipOptions | async" [value]="option">
                            {{ option.title }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </field-holder>
            <field-holder *ngIf="editGuardianship">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.OCCUPATION' | translate }}</mat-label>
                    <input type="text" matInput formControlName="guardianOccupation"
                        [matAutocomplete]="autoOccupation" />
                    <mat-autocomplete panelWidth="auto" #autoOccupation="matAutocomplete"
                        [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let option of filteredOccupationOptions | async" [value]="option">
                            {{ option.title }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.RELATIONSHIP' | translate  }}</mat-label>
                    <input type="text" matInput formControlName="guardianRelationship"
                        [matAutocomplete]="autoRelationship" />
                    <mat-autocomplete panelWidth="auto" #autoRelationship="matAutocomplete"
                        [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let option of filterRelationshipOptions | async" [value]="option">
                            {{ option.title }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                            'NEW_PROTEGE.FORM.PHONE' | translate
                          }}</mat-label>
                    <input matInput type="text" formControlName="guardianPhone" autocomplete="off" />
                </mat-form-field>
            </field-holder>

            <h3 *ngIf="editGuardianship">{{ 'NEW_PROTEGE.GUARDIAN.COLLECTIVE_GUARDIAN' | translate }}</h3>
            <div *ngIf="editGuardianship" class="button-add-holder">
                <button mat-flat-button color="primary" type="button" (click)="importSubjectData('collectiveGuardian')">
                    <mat-icon>person_add</mat-icon>
                    {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
                </button>
            </div>
            <br *ngIf="editGuardianship">
            <field-holder *ngIf="editGuardianship" kind="2-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.FIRST_NAME' | translate }}</mat-label>
                    <input matInput type="text" formControlName="collectiveGuardianFirstName" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.LAST_NAME' | translate }}</mat-label>
                    <input matInput type="text" formControlName="collectiveGuardianLastName" autocomplete="off" />
                </mat-form-field>
            </field-holder>
            <field-holder *ngIf="editGuardianship" kind="2-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{
            'NEW_PROTEGE.FORM.TYPE_OF_JOB' | translate
          }}</mat-label>
                    <input matInput type="text" formControlName="collectiveGuardianTypeOfJob" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
            'NEW_PROTEGE.FORM.SOCIAL_PROTECTION_NAME' | translate
          }}</mat-label>
                    <input matInput type="text" formControlName="collectiveGuardianProtectionName" autocomplete="off" />
                </mat-form-field>
            </field-holder>

            <h3 *ngIf="editGuardianship">{{ 'NEW_PROTEGE.GUARDIAN.DIRECT_GUARDIAN' | translate }}</h3>
            <div *ngIf="editGuardianship" class="button-add-holder">
                <button mat-flat-button color="primary" type="button" (click)="importSubjectData('directGuardian')">
                    <mat-icon>person_add</mat-icon>
                    {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
                </button>
            </div>
            <br *ngIf="editGuardianship">
            <field-holder *ngIf="editGuardianship" kind="2-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{
            'NEW_PROTEGE.FORM.FIRST_NAME_GUARDIANSHIP_AUTHORITY_EXPERT'
              | translate
          }}</mat-label>
                    <input matInput type="text" formControlName="directGuardianFirstName" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
            'NEW_PROTEGE.FORM.LAST_NAME_GUARDIANSHIP_AUTHORITY_EXPERT'
              | translate
          }}</mat-label>
                    <input matInput type="text" formControlName="directGuardianLastName" autocomplete="off" />
                </mat-form-field>
            </field-holder>
            <field-holder *ngIf="editGuardianship">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'NEW_PROTEGE.FORM.OCCUPATION' | translate }}</mat-label>
                    <input type="text" matInput formControlName="directGuardianOccupation"
                        [matAutocomplete]="autoOccupation" />
                    <mat-autocomplete panelWidth="auto" #autoOccupation="matAutocomplete"
                        [displayWith]="displayCustomFormat">
                        <mat-option *ngFor="let option of filtereDirectOccupationOptions | async" [value]="option">
                            {{ option.title }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
                    class="form-footer__button form-footer__button--secondary">
                    {{ 'PROTEGE_DETAILS.FORM_EDIT_DETAILS.CANCEL' | translate }}
                </button>
                <button mat-flat-button color="primary" type="submit"
                    class="form-footer__button form-footer__button--primary">
                    {{ 'PROTEGE_DETAILS.FORM_EDIT_DETAILS.SUBMIT' | translate }}
                </button>
            </form-footer>
        </form>
        <br />
    </div>


</mat-dialog-content>