/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { DataSharingService } from 'src/services/data-sharing.service';
import { NotificationService } from 'src/services/notification.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from 'src/services/user.service';
import { Subject } from 'rxjs';
import { SystemInfo } from 'src/app/utils/systemInfo';

@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent implements AfterViewInit {
  isNotificationDropdownOpen = false;
  isProfileDropdownOpen = false;
  hidden = false;
  cases = [];
  unassignedCases = [];
  notifications = [];
  entrancePapers = [];
  submissions = [];
  supervision = [];
   public loggedUser: any;
  isLegalCounselor = false;
  isUserEntrancer = false;
  isUserWorker = false;
  isUserSupervisor = false;
  isUserAssigner = false;
  isMainCenterBelgrade = false;
  isGlobalAdmin = false;
  isCenterAdmin = false;
  isUserDrugostepeni = false;
  @ViewChild('sidenav') public sidenav: MatSidenav;
  currentLocation = '';
  eventSidenav: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private dataSharingService: DataSharingService,
    private notificationsService: NotificationService,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private authentication: AuthenticationService,
    private userService: UserService,
    private system: SystemInfo) {

    this.router.events.subscribe((ev) => {
      if(ev instanceof NavigationStart){
        if(ev.url.includes('subjects')){
          this.currentLocation=ev.url
        }
      }
      if (ev instanceof NavigationEnd) {
        this.currentLocation = this.getLastPart(window.location.href);
        if (this.sidenav !== undefined) {
          if (this.currentLocation === 'cases' || this.currentLocation === 'submissions') {
            this.sidenav.open();
            this.eventSidenav.next();
          } else {
            this.sidenav.close();
          }
          this.cdr.detectChanges();
        }
      }
    });

    this.dataSharingService.messages.subscribe((value) => {
      console.log('gen');
      this.notificationsService.getNotifications('CASES').subscribe((res) => {
        this.cases = res;
        if (this.cases.length > 0) {
          this.cdr.detectChanges();
        }
      });
      this.notificationsService.getNotifications('GENERAL').subscribe((res) => {
        this.notifications = res;
        if (this.notifications.length > 0) {
          this.cdr.detectChanges();
        }
      });
      this.notificationsService.getNotifications('SUPERVISION').subscribe((res) => {
        this.supervision = res;
        if (this.supervision.length > 0) {
          this.cdr.detectChanges();
        }
      });
    });

    this.dataSharingService.newCase.subscribe((result) => {
      this.notificationsService
        .getNotifications('UNASSIGNED')
        .subscribe((res) => {
          this.unassignedCases = res;
          if (this.unassignedCases.length > 0) {
            this.cdr.detectChanges();
          }
        });
    });

    this.dataSharingService.newSubmission.subscribe((result) => {
      console.log('sub');
      this.notificationsService
        .getNotifications('SUBMISSIONS')
        .subscribe((res) => {
          this.submissions = res;
          if (this.submissions.length > 0) {
            this.cdr.detectChanges();
          }
        });
    });

    this.isLegalCounselor = this.userService.isUserLawyer();
    this.isUserEntrancer = this.userService.isUserEntrancer();
    this.isUserWorker = this.userService.isUserWorker();
    this.isUserSupervisor = this.userService.isUserSupervisor();
    this.isUserAssigner = this.userService.isUserAssigner();
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    this.isGlobalAdmin = this.userService.isGlobalAdmin();
    this.isCenterAdmin = this.userService.isCenterAdmin();
    this.isMainCenterBelgrade = (JSON.parse(this.localStorageService.get('loggedUser')).csrId === '1') ? true : false;
  }
  ngAfterViewInit(): void {
  }

  logout() {
    this.authentication.logout();
  }

  onClose(): void {
    if ((this.currentLocation === 'cases' && !this.currentLocation.includes('schedule-cases')) || this.currentLocation === 'submissions') {
      this.router.navigateByUrl('/');
    }
  }

  handleProfileDropdown() {
    this.isProfileDropdownOpen = !this.isProfileDropdownOpen;
  }

  handleNotificationDropdown() {
    this.isNotificationDropdownOpen = !this.isNotificationDropdownOpen;
    this.hideNotifications('notifications');
  }

  hideNotifications(source: any) {
    const notificationIds = [];
    if (source === 'cases') {
      for (const newCase of this.cases) {
        notificationIds.push(newCase.id);
      }
    } else if (source === 'notifications') {
      for (const notification of this.notifications) {
        notificationIds.push(notification.id);
      }
    } else if (source === 'unassignedCases') {
      for (const unassignedCase of this.unassignedCases) {
        notificationIds.push(unassignedCase.id);
      }
    } else if (source === 'submissions') {
      for (const submission of this.submissions) {
        notificationIds.push(submission.id);
      }
    } else if (source === 'supervision') {
      for (const supervision of this.supervision) {
        notificationIds.push(supervision.id);
      }
    }

    this.notificationsService
      .notificationsReceived(notificationIds)
      .subscribe((res) => {
        if (source === 'cases') {
          this.cases = [];
        } else if (source === 'notifications') {
          this.notifications = [];
        } else if (source === 'unassignedCases') {
          this.unassignedCases = [];
        } else if (source === 'submissions') {
          this.submissions = [];
        } else if (source === 'supervision') {
          this.supervision = [];
        }
      });
  }

  onSubjectsClick() {
    this.sidenav.close();
    /*
      Olja Andjelovski olja.andjelovski@iten.rs
      Obrisi mi localStorageSubcenter ukoliko ga kliknem sa homepage-a. Da ne bi registar
      bio prepopunjen sa onim sto je ranije bilo izabrano.
    */
    this.localStorageService.remove('localStorageSubcenter');
  }

  getLastPart(url) {
    const parts = url.split('/');
    return (url.lastIndexOf('/') !== url.length - 1
      ? parts[parts.length - 1]
      : parts[parts.length - 2]);
  }

 
   async getSupport() {
      this.loggedUser =  JSON.parse(this.localStorageService.get('loggedUser'));
      const email = 'sozis@open-digitalsolutions.com';
      const subject = 'Стручни рад - '+this.loggedUser.center;
      // Collect some metadata
      const systemInfoData = await this.system.getAllData();
      // Construct the body with metadata
      const body = `\n\n\n\n\n\n\n\n\nМолимо вас да не бришете следеће податке\nОви подаци су важни за анализу проблема и морају остати сачувани\n\n${systemInfoData}`;
      // Construct the mailto link
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
   }
}

