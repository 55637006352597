/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum } from 'src/types';
import { ImportSubjectFromProviderComponent } from '../import-subject-from-provider/import-subject-from-provider.component';
@Component({
  selector: 'new-subject',
  templateUrl: './new-subject.component.html',
  styleUrls: ['./new-subject.component.scss'],
})
export class NewSubjectComponent implements OnInit {
  importedSubjectData: any;
  constructor(private toastr: ToastrImplService, private translate: TranslateService, public dialog: MatDialog) { }
  ngOnInit(): void { }
  openDialog() {
    const dialogRef = this.dialog.open(ImportSubjectFromProviderComponent, {
      width: ModalSizeEnum.MINI,
      panelClass:'overlay-panel',
    });

    dialogRef.afterClosed().subscribe((event) => {
      let message;
      let message2;
      this.translate.get('SNACKBAR.EXTERNAL_CRS').subscribe((res: string) => {
        message2 = res;
      });
      if (event.event === 'success') {
        this.importedSubjectData = event.data // passed further to subject-form
        this.toastr.success('SNACKBAR.EXTERNAL_INFO_INPUT');
      } else if (event.event === 'error') {
        this.translate.get('SNACKBAR.EXTERNAL_INFO_INPUT_ERROR').subscribe((res: string) => {
          message = res;
        });
        this.toastr.showError(message + message2 + '-a');
      }
    });
  }
}
