import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OzrCaseService {

  constructor(private httpClient: HttpClient, private app: AppService) { }

  findOzrCaseById(socialCaseId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getDataForOzrFormUrl + '/' + socialCaseId,
      { headers }) as Observable<any>;
  }

  checkIsActiveRight(socialCaseId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.get(this.app.getUrl() + environment.checkIsActiveRightUrl + '/' + socialCaseId,
      { headers }) as Observable<any>;
  }

  saveOzrCase(ozrCase: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.saveOzr, ozrCase,
      { headers }) as Observable<any>;
  }
}
