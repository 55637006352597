/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'new-foster-child-cost-carriers-step',
  templateUrl: './new-foster-child-cost-carriers-step.component.html',
  styleUrls: ['./new-foster-child-cost-carriers-step.component.scss']
})
export class NewFosterChildCostCarriersStepComponent implements OnInit {
  @Output() costCarriersStepEmitter = new EventEmitter();
  @Input() costCarriersStep: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.costCarriersStep = this.formBuilder.group({
      parents: ['', []],
      otherRelatives: ['', []],
      ownIncome: ['', []],
    });
  }

  /**
   * Send step information to parent component
   */
  updateCostCarriersStep() {
    this.costCarriersStepEmitter.emit(
      this.costCarriersStep
    );
  }
}
