<container>
  <div id="pdfTable" #pdfTable>
    <page-intro-header title="{{ 'SUBJECTS.TITLE' | translate }}">

      <button mat-flat-button color="primary" routerLink="/new-subject"
        *ngIf="this.object.origin !== 'entrance' && !isMainCenterBelgrade && !isUserDrugostepeni">
        {{ 'SUBJECTS.NEW_SUBJECT' | translate }}
      </button>
    </page-intro-header>
    <mat-card *ngIf="areLoaded" class="card" style="align-content: center;">
      <card-header title="{{ 'SUBJECTS.CARD_TITLE' | translate }}">
        <div class="card__aside">
          <mat-form-field appearance="outline" class="search centar-search"
            *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
            <mat-label>
              {{'GLOBAL.CENTER' | translate}}
            </mat-label>
            <input type="text" matInput [formControl]="subcenter" [matAutocomplete]="autoSubcenter" />
            <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete" [displayWith]="displayCustomFormat"
              (optionSelected)="reloadSubjects($event.option.value)">
              <mat-option *ngFor="let cent of filteredSubcenters | async" [value]="cent">
                {{ cent.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline" class="search">
            <mat-label>{{
              'GLOBAL.SEARCH' | translate
            }}</mat-label>
            <input matInput type="text" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
              placeholder="{{'GLOBAL.SEARCH' | translate}}">
          </mat-form-field>
          <button mat-button color="primary" (click)="exportCsv()"
            class="button-square button-square--large button-export">
            <icon-file-csv matTooltip="{{ 'CSV' | translate }}" class="button-square__icon" size="24"></icon-file-csv>
          </button>
          <button mat-button color="primary" (click)="printPDF()"
            class="button-square button-square--large button-export">

            <icon-file-pdf matTooltip="{{ 'PDF' | translate }}" class="button-square__icon" size="24"></icon-file-pdf>
          </button>
        </div>
      </card-header>
      <mat-chip-list #chipList class="chip-list" [ngStyle]="{ 'display': searchItems.length > 0 ? 'block' : 'none' }">
        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable" [removable]="removable"
          (removed)="remove(item)">
          {{item.name}}
          <mat-icon matChipRemove *ngIf="removable">
            cancel
          </mat-icon>
        </mat-chip>
      </mat-chip-list>
      <div class="table-responsive-wrapper">
        <table mat-table id="myTable" class="subjects__table" #myTable [dataSource]="page.content">
          <ng-container matColumnDef="registrationId">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.REGID' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.registrationId }}
            </td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>{{ 'ID' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ element?.subjectIdentity?.subjectId }}
            </td>
          </ng-container>
          <ng-container matColumnDef="dateOfCreation">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.DATEOFCREATION' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ element?.dateOfCreation | date:"dd.MM.yyyy." }}
            </td>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.LASTNAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.lastName }}</td>
          </ng-container>
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.FIRSTNAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.firstName }}</td>
          </ng-container>
          <ng-container matColumnDef="parentName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.PARENTNAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.parentName }}</td>
          </ng-container>
          <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.DATEOFBIRTH' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.dateOfBirth | date:"dd.MM.yyyy."}}
            </td>
          </ng-container>
          <ng-container matColumnDef="yearOfBirth">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.YEAROFBIRTH' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.yearOfBirth }}
            </td>
          </ng-container>
          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.GENDER' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.gender?.gender }}
            </td>
          </ng-container>
          <ng-container matColumnDef="jmbg">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.JMBG' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.jmbg }}</td>
          </ng-container>
          <ng-container matColumnDef="jmbp">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.JMBP' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.jmbp }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBJECTS.STATUS' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <status-label *ngIf="element.status.id != 3" status="{{element.status.id}}">
                {{ element?.status?.title }}
              </status-label>
            </td>
          </ng-container>
          <ng-container matColumnDef="familyDossier">
            <th mat-header-cell class="dossier-status-header" *matHeaderCellDef>
              {{ 'SUBJECTS.DOSSIERSTATUS' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <dossier-status-label status="{{element.familyDossier}}">
                {{ element?.familyDossierId }}
              </dossier-status-label>
            </td>
          </ng-container>
          <ng-container *ngIf="isUserManager" matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
              <button mat-icon-button class="button-square table__row-button" color="primary"
                matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()" *ngIf="!isMainCenterBelgrade">
                <mat-icon class="button-square__icon">more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="filter-menu">
                <button mat-menu-item (click)="handleOpenDialog(element)"
                  [disabled]="element.registrationId === null || element.status.id === 2 || element.status.id === 3">
                  <mat-icon>person_add</mat-icon>
                  {{ 'SUBJECTS.ASSIGNE_DOSSIER' | translate }}
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
          <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" (click)="onRowClicked(element)"
            class="subjects__row">
          </tr>
        </table>
      </div>

      <div class="paginator-wrapper">
        <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
          (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
        </app-custom-pagination>
      </div>

    </mat-card>
    <mat-card *ngIf="!areLoaded" class="card">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
  </div>
</container>
