import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalEventEnum } from 'src/types';

@Component({
  selector: 'annulment-of-adoption',
  templateUrl: './annulment-of-adoption.component.html',
  styleUrls: ['./annulment-of-adoption.component.scss']
})
export class AnnulmentOfAdoptionComponent implements OnInit {

  annulmentAdoptionForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.annulmentAdoptionForm = this.formBuilder.group({
      annulmentOfAdoption: []
    });
  }

  save() {
    this.dialogRef.close({
      event: ModalEventEnum.SUCCESS,
      data: this.annulmentAdoptionForm.value.annulmentOfAdoption,
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: ModalEventEnum.CANCEL });
  }
}
