<form class="form" [formGroup]="forwardCaseForm" (ngSubmit)="forwardCase(forwardCaseForm)">
    <form-group title="{{ ('CASES.SEND_LAWYER' | translate) }}" isLast="true">
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ASSIGN_CASE.USER' | translate }}</mat-label>
                <input type="text" matInput formControlName="user" [matAutocomplete]="autoUser" [required]="true"  (focusout)="autoSelectItem()"/>
                <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayCustomFormat">
                    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                        {{ user.name }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="forwardCaseForm.get('user').hasError('required')">
                    {{ 'ASSIGN_CASE.USER_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </field-holder>
    </form-group>
    <form-footer>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
            class="form-footer__button form-footer__button--secondary">
            {{ 'DIALOG.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
            [disabled]="!forwardCaseForm.valid">
            {{ 'DIALOG.CONFIRM' | translate }}
        </button>
    </form-footer>
</form>