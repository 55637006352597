<form class="form" [formGroup]="newForm" (ngSubmit)="createLegalSubject(newForm)">
  <form-group title="{{ 'NEW_LEGAL_SUBJECT.COMMON.TITLE' | translate }}">
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.COMMON.NAME' | translate }}
        </mat-label>
        <input matInput type="text" maxlength="250" formControlName="name" required autocomplete="off" />
        <mat-error *ngIf="
            newForm.get('name').hasError('required') ||
            newForm.get('name').hasError('pattern')">
          {{ 'NEW_LEGAL_SUBJECT.COMMON.NAME_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.COMMON.INSTITUTION_TYPE' | translate }}
        </mat-label>
        <input type="text" matInput formControlName="institutionType" (input)="checkInstitution()" [matAutocomplete]="autoInstitutionType"
               required />
        <mat-autocomplete #autoInstitutionType="matAutocomplete" [displayWith]="displayCustomFormat">
          <mat-option *ngFor="let option of filteredInstitutionTypesOptions | async" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="newForm.get('institutionType').hasError('required')">
          {{ 'NEW_LEGAL_SUBJECT.COMMON.INSTITUTION_TYPE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-group title="{{ 'NEW_LEGAL_SUBJECT.ADDRESS.TITLE' | translate }}">
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.CITY' | translate }}
        </mat-label>
        <input type="text" matInput maxlength="250" formControlName="city" [matAutocomplete]="autoCity" required />
        <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="filterPtt($event.option.value)">
          <mat-option *ngFor="let city of filteredCityOptions | async" [value]="city">
            {{ city }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="newForm.get('city').hasError('required')">
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.CITY_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.PTT_NUMBER' | translate }}
        </mat-label>
        <input type="text" matInput formControlName="pttNumber" [matAutocomplete]="autoPttNumber" required />
        <mat-autocomplete #autoPttNumber="matAutocomplete">
          <mat-option *ngFor="let option of filteredPttNumberOptions | async" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="newForm.get('pttNumber').hasError('required')">
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.PTT_NUMBER_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_LEGAL_SUBJECT.ADDRESS.REGION' | translate }}</mat-label>
        <mat-select formControlName="region" required>
          <mat-option *ngFor="let region of regionOptions" [value]="region">
            {{ region }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="newForm.get('region').hasError('required')">
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.REGION_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>

    <field-holder kind="3-cols-2-1-1">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.STREET' | translate }}
        </mat-label>
        <input type="text" matInput formControlName="street" (input)="checkLengthStreetName()" [matAutocomplete]="autoStreet" required />
        <mat-autocomplete #autoStreet="matAutocomplete">
          <mat-option *ngFor="let option of filteredStreetOptions | async" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="newForm.get('street').hasError('required')">
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.STREET_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.ADDRESS_NUM' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="addressNum" autocomplete="off" required />
        <mat-error
                   *ngIf="newForm.get('addressNum').hasError('required') || newForm.get('addressNum').hasError('pattern')">
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.ADDRESS_NUM_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.ADDRESS_SUB_NUM' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="addressSubNum" autocomplete="off" />
        <mat-error *ngIf="newForm.get('addressSubNum').hasError('pattern')">
          {{ 'NEW_LEGAL_SUBJECT.ADDRESS.ADDRESS_SUB_NUM_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>   
  </form-group>
  <form-group title="{{ 'NEW_LEGAL_SUBJECT.CONTACT.TITLE' | translate }}">
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_LEGAL_SUBJECT.CONTACT.EMAIL' | translate }}</mat-label>
        <input matInput type="text" formControlName="email" autocomplete="off" />
        <mat-error *ngIf="newForm.get('email').hasError('email')">
          {{ 'NEW_LEGAL_SUBJECT.CONTACT.EMAIL_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_LEGAL_SUBJECT.CONTACT.PHONE' | translate }}</mat-label>
        <input matInput type="text" formControlName="phone" autocomplete="off" />
        <mat-error *ngIf="newForm.get('phone').hasError('pattern') ">
          {{ 'NEW_LEGAL_SUBJECT.CONTACT.PHONE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-group title="{{ 'NEW_LEGAL_SUBJECT.OTHER.TITLE' | translate }}" isLast="true">
    <field-holder kind="4-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.OTHER.ACCOUNT_NUMBER' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="account" autocomplete="off"
               placeholder="{{ 'ACCOUNT_NUMBER_FORMAT' | translate }}" [textMask]="mask" />
        <mat-error *ngIf="newForm.get('account').hasError('required')">
          {{ 'NEW_LEGAL_SUBJECT.OTHER.ACCOUNT_NUMBER_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.OTHER.JBKBS' | translate }}
        </mat-label>
        <input matInput type="text" maxlength="5" formControlName="jbkbs" autocomplete="off" />
        <mat-error *ngIf="newForm.get('jbkbs').hasError('pattern')">
          {{ 'NEW_LEGAL_SUBJECT.OTHER.JBKBS_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.OTHER.PIB' | translate }}
        </mat-label>
        <input matInput type="text" maxlength="9" onlyNumber formControlName="pib" autocomplete="off" required
               (input)="checkPIB($event)" [readonly]="id !== undefined && !isAdmin"/>
        <mat-error *ngIf="newForm.get('pib').hasError('required') || newForm.get('pib').hasError('pattern')">
          {{ 'NEW_LEGAL_SUBJECT.OTHER.PIB_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_LEGAL_SUBJECT.OTHER.IDENTIFICATION_NUMBER' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="identificationNumber" [readonly]="id !== undefined && !isAdmin" onlyNumber
               maxlength="8" autocomplete="off" required (input)="checkIdentificationNumber()" />
        <mat-error
                   *ngIf="newForm.get('identificationNumber').hasError('required') || newForm.get('identificationNumber').hasError('pattern')">
          {{ 'NEW_LEGAL_SUBJECT.OTHER.IDENTIFICATION_NUMBER_ERROR' | translate }}
        </mat-error>
        <mat-error
                   *ngIf="newForm.get('identificationNumber').hasError('minlength')||newForm.get('identificationNumber').hasError('maxlength')">
          {{ 'NEW_LEGAL_SUBJECT.OTHER.IDENTIFICATION_NUMBER_SIZE_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="newForm.get('identificationNumber').hasError('notValid')">
          {{ 'NEW_LEGAL_SUBJECT.OTHER.IDENTIFICATION_NUMBER_TAKEN' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button class="form-footer__button form-footer__button--secondary" mat-stroked-button color="primary" type="button"
            (click)="handleCancel()">
      {{ 'GLOBAL.CANCEL' | translate }}
    </button>
    <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary" type="submit"
            [disabled]="(id === undefined ? (!newForm.valid || !isValidPib) : !newForm.valid)||indetificationNumberExist===true||clicked===true">
      {{ (id === undefined ? 'NEW_LEGAL_SUBJECT.SUBMIT' : 'NEW_LEGAL_SUBJECT.CHANGE') | translate  }}
    </button>
  </form-footer>
</form>
