/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { CodebookService } from 'src/services/codebook.service';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

enum AutocompleteFilterEnum {
  NATIONALITY = 'nationality',
  CITIZENSHIP = 'citizenship',
}

@Component({
  selector: 'new-foster-child-personal-information-step',
  templateUrl: './new-foster-child-personal-information-step.component.html',
  styleUrls: ['./new-foster-child-personal-information-step.component.scss'],
})
export class NewFosterChildPersonalInformationStepComponent implements OnInit {
  @Output() personalInformationStepEmitter = new EventEmitter();
  @Input() personalInformationStep: FormGroup;
  currentDate = new Date();
  familyStatusOptions: any = [];
  schoolStatusOptions: any = [];
  nationalityOptions: any = [];
  citizenshipOptions = [];
  genderOptions = [];
  generalHealthStatusOptions: any;
  phychophysicalDevelopmentOptions: any;
  generalHealthDeviations: any = [];
  phychophysicalDevelopmentDeviations: any = [];
  mapGeneralHealthDeviations: any = new Map();
  mapPhychophysicalDevelopmentDeviations: any = new Map<any, string>();
  yesNoOptions = [
    {value: true, viewValue: 'GLOBAL.YES'},
    {value: false, viewValue: 'GLOBAL.NO'}
  ];
  maxDate: Date = new Date(2999, 11, 31);

  constructor(
    private formBuilder: FormBuilder,
    private codebookService: CodebookService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {

    this.getFamilyStatus();
    this.getSchoolStatus();
    this.getGender();
    this.getNationality();
    this.getCitizenship();
    this.getGeneralHealthStatusCodebook();
    this.getPsychophysicalDevelopmentCodebook();
    this.getGeneralHealthDeviationsCodebook();
    this.getPsychophysicalDevelopmentDeviationsCodebook();


    // this.codebookService.getCitizenshipCodebook().subscribe((result) => {
    //   this.citizenshipOptions = result;
    //   this.filteredCitizenshipOptions =
    //     this.personalInformationStep.controls.citizenship.valueChanges.pipe(
    //       startWith(''),
    //       map((value) => (typeof value === 'string' ? value : value.title)),
    //       map((title) =>
    //         title
    //           ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP)
    //           : this.citizenshipOptions.slice()
    //       )
    //     );
    // });
  }


  // Državljanstvo

  filteredCitizenshipOptions: Observable<any[]>;
  filteredNationalityOptions: Observable<any[]>;

  ngOnInit(): void {
    this.personalInformationStep = this.formBuilder.group({
      subjectId: [''],
      registrationId: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      gender: [null, [Validators.required]],
      foreigner: [false],
      dateOfBirth: ['', [Validators.required]],
      placeOfBirth: ['', [Validators.required]],
      jmbg: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      citizenship: [null, []],
      placeOfOrigin: ['', [Validators.required]],
      nationality: [null, []],
      familyStatus: ['', [Validators.required]],
      fosteringStatus: ['', [Validators.required]],
      fosteringFrom: ['', []],
      educationStatus: ['', [Validators.required]],
      class: ['', []],
      generalHealthStatus: ['', [Validators.required]],
      phychophysicalDevelopment: [''],
      healthDeviationItems: this.formBuilder.array([]),
      phisicalDeviationItems: this.formBuilder.array([]),
      phisicalDeviationItemsMap: [''],
      healthDeviationItemsMap: [''],
      psychophysicalPrognosis: [''],
      yearOfBirth: ['']
    });
  }


  /**
   * Send step information to parent component
   */
  updatePersonalInformationStep() {
    this.personalInformationStepEmitter.emit(this.personalInformationStep);
  }

  /**
   * _filter
   * @param value input value
   * @param what which filter should be applied (check AutocompleteFilterEnum)
   */
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();

    switch (what) {
      case AutocompleteFilterEnum.NATIONALITY:
        return this.nationalityOptions.filter((option: any) =>
          option.title.toLowerCase().includes(filterValue)
        );
      case AutocompleteFilterEnum.CITIZENSHIP:
        return this.citizenshipOptions.filter((option: any) =>
          option.title.toLowerCase().includes(filterValue)
        );
      default:
        break;
    }
  }

  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.title : '';
  }

  getFamilyStatus() {
    this.codebookService.getFamilyStatusCodebook().subscribe((res) => {
      this.familyStatusOptions = res;
    });
  }

  getSchoolStatus() {
    this.codebookService.getSchoolStatusCodebook().subscribe((res) => {
      this.schoolStatusOptions = res;
    });
  }

  getNationality() {
    this.codebookService.getNationalityCodebook().subscribe((res) => {
      this.nationalityOptions = res;
      this.filteredNationalityOptions = this.personalInformationStep.controls.nationality.valueChanges
        .pipe(
          startWith(''),
          map(value => value === null ? null : typeof value === 'string' ? value : value.title),
          map(title => title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalityOptions.slice())
        );
    }
    );
  }

  getGender() {
    this.codebookService.getGenderCodebook().subscribe((res) => { this.genderOptions = res; }
    );
  }

  getCitizenship() {
    this.codebookService.getCitizenshipCodebook().subscribe((res) => {
      this.citizenshipOptions = res;
      this.filteredCitizenshipOptions = this.personalInformationStep.controls.citizenship.valueChanges
        .pipe(
          startWith(''),
          map(value => value === null ? null : typeof value === 'string' ? value : value.title),
          map(title => title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice())
        );
    }
    );
  }

  getGeneralHealthStatusCodebook() {
    this.codebookService.getGeneralHealthStatusCodebook().subscribe((res) => { this.generalHealthStatusOptions = res; }
    );
  }

  getPsychophysicalDevelopmentCodebook() {
    this.codebookService.getPsychophysicalDevelopmentCodebook().subscribe((res) => { this.phychophysicalDevelopmentOptions = res; }
    );
  }

  getGeneralHealthDeviationsCodebook() {
    this.codebookService.getGeneralHealthDeviationsCodebook().subscribe((res) => {
      this.generalHealthDeviations = res;
      for (const gh of this.generalHealthDeviations) {
        this.healthDeviationItems().push(this.createItem(gh.id, gh.name));
      }
    }
    );
  }

  getPsychophysicalDevelopmentDeviationsCodebook() {
    this.codebookService.getPsychophysicalDevelopmentDeviationsCodebook().subscribe((res) => {
      this.phychophysicalDevelopmentDeviations = res;
      for (const gh of this.phychophysicalDevelopmentDeviations) {
        this.phisicalDeviationItems().push(this.createItem(gh.id, gh.name));
      }
    }
    );
  }

  createItem(id: any, name: any): FormGroup {
    return this.formBuilder.group({
      key: id,
      name,
      value: ''
    });
  }

  healthDeviationItems(): FormArray {
    return this.personalInformationStep.get('healthDeviationItems') as FormArray;
  }

  phisicalDeviationItems(): FormArray {
    return this.personalInformationStep.get('phisicalDeviationItems') as FormArray;
  }

  importSubjectData() {
    const dialogRef = this.dialog.open(SubjectsComponent, {
      // width: ModalSizeEnum.DEFAULT,
      width: '1200px',
      panelClass:'overlay-panel',
      data: {
        origin: 'entrance',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        const address =
          String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
          String(result.data.permanentResidence.number ? (' ' + result.data.permanentResidence.number) : '') +
          String(result.data.permanentResidence.subnumber ? (' ' + result.data.permanentResidence.subnumber) : '') +
          String(result.data.permanentResidence.town ? (', ' + result.data.permanentResidence.town) : '') +
          String((result.data.permanentResidence.street && result.data.residence.street) ? '/' : '') +
          String(result.data.residence.street ? result.data.residence.street : '') +
          String(result.data.residence.number ? (' ' + result.data.residence.number) : '') +
          String(result.data.residence.subnumber ? (' ' + result.data.residence.subnumber) : '') +
          String(result.data.residence.town ? (', ' + result.data.residence.town) : '');
        this.personalInformationStep.patchValue({
          subjectId: result.data.subjectIdentity.subjectId,
          registrationId: result.data.registrationId,
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          foreigner: result.data.foreigner,
          gender: result.data.gender,
          dateOfBirth: result.data.dateOfBirth === null
            ? null : new Date(result.data.dateOfBirth.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')),
          placeOfBirth: result.data.placeOfBirth,
          jmbg: result.data.jmbg,
          citizenship: result.data.citizenship,
          placeOfOrigin: address,
          nationality: result.data.nationality,
          yearOfBirth: result.data.yearOfBirth
        });
        this.personalInformationStep.controls.dateOfBirth.markAsTouched();
        this.personalInformationStep.controls.gender.markAsTouched();
        this.personalInformationStep.controls.jmbg.markAsTouched();
      }
    });
  }

  compareObj(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id;
  }
  updateJMBGOnGenderChange(event: any) {
    if (this.personalInformationStep.value.foreigner === true) {
        return;
    }
    if (this.personalInformationStep.value.jmbg !== null && this.personalInformationStep.value.jmbg.length === 13) {
      const jmbg = this.personalInformationStep.value.jmbg.substring(9, 12);
      if (this.personalInformationStep.get('gender').value !== null && this.personalInformationStep.get('gender').value !== undefined) {
        if (this.personalInformationStep.value.jmbg.length === 13 && jmbg >= '000' && jmbg <= '499') {
          if (this.personalInformationStep.value.gender.code === 'F') {
            this.personalInformationStep.get('gender').setErrors({ incorrect: true });
          } else {
            this.personalInformationStep.get('gender').setErrors(null);
          }
        } else if (jmbg >= '500' && jmbg <= '999') {
          if (this.personalInformationStep.value.gender.code === 'M') {
            this.personalInformationStep.get('gender').setErrors({ incorrect: true });
          } else {
            this.personalInformationStep.get('gender').setErrors(null);
          }
        }
      }
    }
  }

  updateJMBGOnDateOfBirthChange(event: any) {
    if (this.personalInformationStep.value.dateOfBirth !== null && this.personalInformationStep.value.foreigner !== true) {
      const birthDate = new Date(this.personalInformationStep.value.dateOfBirth).getTime();
      const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
      const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
      if (this.personalInformationStep.value.jmbg !== null && this.personalInformationStep.value.jmbg.length === 13) {
        const jmbg = this.personalInformationStep.value.jmbg.substring(0, 7);
        if (newBirthDate !== jmbg) {
          this.personalInformationStep.get('dateOfBirth').setErrors({ incorrect: true });
        } else {
          this.personalInformationStep.get('dateOfBirth').setErrors(null);
        }
      }
    }

  }

  fosteringStatusChanged() {
    if (this.personalInformationStep.controls.fosteringStatus.value.value === true) {
      this.personalInformationStep.controls.fosteringFrom.enable();
    } else {
      this.personalInformationStep.controls.fosteringFrom.disable();
    }
  }

  educationStatusChanged() {
    if (this.personalInformationStep.controls.educationStatus.value.id > 1) {
      this.personalInformationStep.controls.class.enable();
    } else {
      this.personalInformationStep.controls.class.disable();
    }
  }

  checkNationality() {
    if (this.personalInformationStep.value.nationality !== undefined && this.personalInformationStep.value.nationality !== null
      && this.personalInformationStep.value.nationality !== '') {
      const choosedValue = this.personalInformationStep.value.nationality;

      var result = this.nationalityOptions.find(value => {
        if (choosedValue.title === undefined) {
          if (value.title === choosedValue) {
            return value;
          }
        } else {
          if (value.title === choosedValue.title) {
            return value;
          }
        }
      });

      if (result === undefined) {
        this.personalInformationStep.controls.nationality.setErrors({ notValid: true });
      } else {
        this.personalInformationStep.controls.nationality.setValue(result);
        this.personalInformationStep.controls.nationality.setErrors(null);
      }
    }
  }

  checkCitizenship() {
    if (this.personalInformationStep.value.citizenship !== undefined && this.personalInformationStep.value.citizenship !== null
      && this.personalInformationStep.value.citizenship !== '') {
      const choosedValue = this.personalInformationStep.value.citizenship;
      var result = this.citizenshipOptions.find(value => {
        if (choosedValue.title === undefined) {
          if (value.title === choosedValue) {
            return value;
          }
        } else {
          if (value.title === choosedValue.title) {
            return value;
          }
        }
      });

      if (result === undefined) {
        this.personalInformationStep.controls.citizenship.setErrors({ notValid: true });
      } else {
        this.personalInformationStep.controls.citizenship.setValue(result);
        this.personalInformationStep.controls.citizenship.setErrors(null);
      }
    }
  }
}
