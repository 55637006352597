/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FosterFamilyData } from 'src/models/fosterFamilyData.model';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum } from 'src/types';
import { FamilyDataComponent } from '../family-data/family-data.component';

@Component({
   selector: 'new-foster-child-close-members-step',
   templateUrl: './new-foster-child-close-members-step.component.html',
   styleUrls: ['./new-foster-child-close-members-step.component.scss'],
})
export class NewFosterChildCloseMembersStepComponent implements OnInit {
   @Output() closeMembersStepEmitter = new EventEmitter();
   @Input() closeMembersStep: FormGroup;
   @Input() subjectData: any;
   brothersAndSisters = [];
   guardians = [];
   displayedColumnsBrothersAndSisters: string[] = ['information', 'action'];

   displayedColumnsGuardians: string[] = ['information', 'relationshipCodebook', 'action'];
   constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private translate: TranslateService, private toastr: ToastrImplService) {}

   ngOnInit(): void {
      this.closeMembersStep = this.formBuilder.group({
         guardianInfo: [null, []],
         acquaintancesInfo: ['', [Validators.required]],
         closeMembersInfo: [null],
      });
   }

   /**
    * Send step information to parent component
    */
   updateCloseMembersStep() {
      this.closeMembersStepEmitter.emit(this.closeMembersStep);
   }

   addBrothersAndSisters() {
      const dialogRef = this.dialog.open(FamilyDataComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: {
            guardian: false,
            brothersAndSisters: true,
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            const isIncludedInBrothersAndSisters = this.checkExistsInList(data, this.brothersAndSisters);
            const isIncludedInGuardians = this.checkExistsInList(data, this.guardians);
            this.setMessage(isIncludedInBrothersAndSisters, isIncludedInGuardians, 'brothersAndSisters');
            if (isIncludedInBrothersAndSisters === false && isIncludedInGuardians === false) {
               this.brothersAndSisters.push(new FosterFamilyData(null, null, null, null, null, null, data.fosterFamilyId, data.information));
               this.brothersAndSisters = [...this.brothersAndSisters];
               this.closeMembersStep.controls.closeMembersInfo.setValue(this.brothersAndSisters);
            }
         } else if (event !== 'Cancel') {
            this.toastr.error('SNACKBAR.ADD_FOSTER_FAMILY_ERROR');
         }
      });
   }

   addGuardian() {
      const dialogRef = this.dialog.open(FamilyDataComponent, {
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: {
            guardian: true,
            brothersAndSisters: false,
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            const tempData = data;
            tempData.yearOfBirth = data.birthYear;
            const tempSubject = { ...this.subjectData.value };
            tempSubject.dateOfBirth = tempSubject.dateOfBirth?.toString().replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$1/$2/$3');
            const isIncludedInBrothersAndSisters = this.checkExistsInList(data, this.brothersAndSisters);
            const isIncludedInGuardians = this.checkExistsInList(data, this.guardians);
            this.setMessage(isIncludedInBrothersAndSisters, isIncludedInGuardians, 'guardians');
            if (isIncludedInGuardians === false && isIncludedInBrothersAndSisters === false) {
               this.guardians.push(new FosterFamilyData(null, null, null, data.relationshipCodebook, null, data.phone, data.fosterFamilyId, data.information));
               this.guardians = [...this.guardians];
               this.closeMembersStep.controls.guardianInfo.setValue(this.guardians);
            }
         } else if (event !== 'Cancel') {
            this.toastr.error('SNACKBAR.ADD_GUARDIAN_ERROR');
         }
      });
   }

   deleteGuardians(i: any) {
      this.guardians.splice(i, 1);
      this.guardians = [...this.guardians];
      this.closeMembersStep.controls.guardianInfo.setValue(this.guardians);
   }

   deleteBrothersAndSisters(i: any) {
      this.brothersAndSisters.splice(i, 1);
      this.brothersAndSisters = [...this.brothersAndSisters];
      this.closeMembersStep.controls.closeMembersInfo.setValue(this.brothersAndSisters);
   }

   /*
      Olja Andjelovski olja.andjelovski@iten.rs
      Funkcija koja proverava da li se osoba - data nalazi u listi koja je prosledjena kao - checkList.
      Da li se nalazi u listi brothersAndSisters/staratelja.
      Prolazimo kroz listu brothersAndSisters/staratelja i proveravamo da li u listi postoji id osobe koju dodajemo.
      Ukoliko ne postoji u redu je da dodamo korisnika, u suprotnom treba ispisati gresku da je osoba vec dodata kao bliska osoba.
    */
   checkExistsInList(data: any, checkList: any) {
      if (data.fosterFamilyId !== '') {
         let isInList = false;
         checkList.forEach((item: any) => {
            if (item.fosterFamilyId === data.fosterFamilyId) {
               isInList = true;
            }
         });
         return isInList;
      } else {
         return false;
      }
   }

   /*
      Olja Andjelovski olja.andjelovski@itne.rs
      Funkcija postavlja odgovarajucu poruku u zavisnosti od toga da li je uspesno kreiran ili vec postoji bliska osoba.
      Ukoliko se nalazi vec u listi brothersAndSisters treba ispisati prouku da postoji isto vazi i za staratelje
    */
   setMessage(isIncludedInBrothersAndSisters: any, isIncludedInGuardians: any, list: any) {
      let message = '';
      if (isIncludedInBrothersAndSisters) {
         this.toastr.error('SNACKBAR.FOSTER_CHILD_BROTHERS_SISTERS_EXISTS');
      } else if (isIncludedInGuardians) {
         this.toastr.error('SNACKBAR.FOSTER_CHILD_GUARDIANS_EXISTS');
      } else if (list === 'guardians') {
         this.toastr.success('SNACKBAR.ADDED_GUARDIAN');
      } else if (list === 'brothersAndSisters') {
         this.toastr.success('SNACKBAR.ADDED_FOSTER_FAMILY');
      }
   }
}
