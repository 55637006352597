export const sortByFirstLetter=(arr:any, value:string, type:string) => {
    return arr.sort((a:any ,b:any) => {
      const aFirstLetter = String(a[type]).split(' ')[0].toLowerCase();
      const bFirstLetter = String(b[type]).split(' ')[0].toLowerCase();
      const aFirstLetterMatch= aFirstLetter.includes(value) && aFirstLetter[0] === value[0];
      const bFirstLetterMatch= bFirstLetter.includes(value) && bFirstLetter[0] === value[0];
      return aFirstLetterMatch === bFirstLetterMatch ? 0 : aFirstLetterMatch?  -1 : 1;
    });
  }

export const autoSelect = (arr: any[], input: string, type: string, form:any)=>{
    setTimeout(()=>{
       let value = form.get(input).value;
       let item: any;

       if(value === undefined  || value === null){
        return;
       }
       if((value[type] === undefined || value[type] === null)){
            if(value === ''){
                return;
            }
            if(typeof value === 'string'){
              value = String(value)?.trim().toLowerCase();
            }
            item = sortByFirstLetter(arr.filter((item:any)=>(item.id +' ' +item[type]).toLowerCase().includes(value)), value, type)[0];
       } else {
            value = String(value[type])?.trim().toLowerCase();
            item = sortByFirstLetter(arr.filter((item:any)=>(item.id +' ' +item[type]).toLowerCase().includes(value)), value, type)[0];
         }    
        if(form.get(input)?.value?.id === undefined){
            form.get(input).setValue(item);
        }
    },500)
  }
