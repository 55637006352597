/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubjectsService } from 'src/services/subjects.service';


export interface SubjectData {
  id: number;
  firstName: string;
  lastName: string;
  jmbg: number;
  street: string;
  city: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  action: string;
  // tslint:disable-next-line: variable-name
  local_data: any;

  constructor(public subjectsService: SubjectsService, public dialogRef: MatDialogRef<DeleteDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: SubjectData) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  doAction() {
    const x = this.subjectsService.removeSubject(this.local_data.subject.jmbg);
    x.subscribe(
      res => {
        console.log(res);
      },
      () => {
        console.log('Error occured');
      }
    );
    this.dialogRef.close({ event: this.action, data: this.local_data });

  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  ngOnInit() {
  }

}
