<form class="form" [formGroup]="newRecord">
  <form-group title="{{ 'ADD_FOSTER_CHILD.TITLE' | translate }}" isLast="true">
    <div class="button-add-holder">
      <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>
    <field-holder kind="4-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ADD_FOSTER_CHILD.FIRST_NAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" required autocomplete="off" />
        <mat-error *ngIf="newRecord.get('firstName').hasError('required')">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ADD_FOSTER_CHILD.LAST_NAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="lastName" required autocomplete="off" />
        <mat-error *ngIf="newRecord.get('lastName').hasError('required')">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ADD_FOSTER_CHILD.DATE_OF_BIRTH' | translate
        }}</mat-label>
        <input matInput [matDatepicker]="dateOfBirth" formControlName="dateOfBirth" required autocomplete="off"
          placeholder="{{ 'DATE_FORMAT' | translate }}" [max]="maxDate" />
        <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
        <mat-datepicker #dateOfBirth></mat-datepicker>
        <mat-error *ngIf="newRecord.get('dateOfBirth').hasError('required')">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ADD_FOSTER_CHILD.PLACE_OF_BIRTH' | translate
        }}</mat-label>
        <input matInput type="text" formControlName="placeOfBirth" required autocomplete="off" />
        <mat-error *ngIf="newRecord.get('placeOfBirth').hasError('required')">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ADD_FOSTER_CHILD.KINSHIP' | translate }}</mat-label>
        <input matInput type="text" formControlName="kinship" required autocomplete="off" />
        <mat-error *ngIf="newRecord.get('kinship').hasError('required')">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ADD_FOSTER_CHILD.FORM_OF_FOSTER_CARE' | translate
        }}</mat-label>
        <mat-select formControlName="formOfFosterCare" required>
          <mat-option *ngFor="let fosterCareForm of fosterCareFormOptions" [value]="fosterCareForm">
            {{ fosterCareForm.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="newRecord.get('formOfFosterCare').hasError('required')">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ADD_FOSTER_CHILD.DATE_OF_PLACEMENT_IN_A_FOSTER_FAMILY' | translate
        }}</mat-label>
        <input matInput [matDatepicker]="dateOfPlacementInAFosterFamily" [max]="currentDate"
          formControlName="dateOfPlacementInAFosterFamily" required autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="dateOfPlacementInAFosterFamily"></mat-datepicker-toggle>
        <mat-datepicker #dateOfPlacementInAFosterFamily></mat-datepicker>
        <mat-error *ngIf="
            newRecord.get('dateOfPlacementInAFosterFamily').hasError('required')
          ">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ADD_FOSTER_CHILD.CHILD_SUPPORT_BEARERS' | translate
        }}</mat-label>
        <input matInput type="text" formControlName="childSupportBearers" required autocomplete="off" />
        <mat-error *ngIf="newRecord.get('childSupportBearers').hasError('required')">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ADD_FOSTER_CHILD.REASONS_FOR_CUSTODY_TERMINATION' | translate
          }}</mat-label>
        <mat-select formControlName="reasonsForCustodyTermination">
          <mat-option *ngFor="let reason of reasonsForCustodyTerminations" [value]="reason">
            {{ reason.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </field-holder>

    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ADD_FOSTER_CHILD.DATE_OF_CUSTODY_TERMINATION' | translate
          }}</mat-label>
        <input matInput [matDatepicker]="dateOfCustodyTermination" [max]="currentDate"
               formControlName="dateOfCustodyTermination" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="dateOfCustodyTermination"></mat-datepicker-toggle>
        <mat-datepicker #dateOfCustodyTermination></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ADD_FOSTER_CHILD.COMPETENT_CENTER' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="competentCenter" required autocomplete="off" />
        <mat-error *ngIf="newRecord.get('competentCenter').hasError('required')">
          {{ 'ADD_FOSTER_CHILD.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button color="primary" class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()" type="button">
      {{ 'ADD_FOSTER_CHILD.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="addNewRecord(newRecord)"
      class="modal-footer__button modal-footer__button--primary" type="submit" [disabled]="!newRecord.valid">
      {{ 'ADD_FOSTER_CHILD.SAVE' | translate }}
    </button>
  </form-footer>
</form>
