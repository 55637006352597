/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Page } from 'src/app/page';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomPaginationComponent implements OnInit {
  @Input() page: Page<any>;
  @Output() firstPageEvent = new EventEmitter();
  @Output() lastPageEvent = new EventEmitter();
  @Output() nextPageEvent = new EventEmitter();
  @Output() previousPageEvent = new EventEmitter();
  @Output() pageSizeEvent: EventEmitter<number> = new EventEmitter<number>();
  pageSizeStr = 10;

  constructor() { }

  ngOnInit(): void {
    this.pageSizeStr = this.page.pageable.pageSize;
  }

  firstPage(): void {
    this.firstPageEvent.emit(null);
  }
  lastPage(): void {
    this.lastPageEvent.emit(null);
  }

  nextPage(): void {
    this.nextPageEvent.emit(null);
  }

  previousPage(): void {
    this.previousPageEvent.emit(null);
  }

  updatePageSize(): void {
    const pageSize: number = +this.pageSizeStr;
    this.pageSizeEvent.emit(pageSize);
  }
}
