/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountingProceduresService } from 'src/services/accounting-procedures.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { CodebookService } from 'src/services/codebook.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { LocalStorageService } from '../local-storage.service';
@Component({
  selector: 'accounting-procedures',
  templateUrl: './accounting-procedures.component.html',
  styleUrls: ['./accounting-procedures.component.scss']
})
export class AccountingProceduresComponent implements OnInit {
  @ViewChild('allSelectedCenters') private allSelectedCenters: MatOption;
  @ViewChild('allSelectedRights') private allSelectedRights: MatOption;
  @ViewChild ('multiSearch') private multiSearch: ElementRef;
  accountingProceduresForm: FormGroup;
  centerOptions = [];
  filteredCenterOptions: any = [];
  rightOptions = [];
  isAccountingProcedures = false;
  loggedIn: any;
  isMainCenterBelgrade = false;
  btnDisabled: boolean;
  isUserDrugostepeni = false;
  maxDate: Date = new Date(2999, 11, 31);
  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthenticationService,
    private accountingProceduresService: AccountingProceduresService,
    private codebookService: CodebookService,
    private translate: TranslateService,
    private toastr: ToastrImplService,
    private localStorageService: LocalStorageService,
    private userService: UserService) {
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.isMainCenterBelgrade = (this.loggedIn?.csrId === '1') ? true : false;
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    this.isAccountingProcedures = this.loggedIn.username === "racunovodstvo"

    if(this.isUserDrugostepeni || this.isAccountingProcedures){
      this.codebookService.getCsrCodebook().subscribe(result => {
        this.centerOptions = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.filteredCenterOptions = this.centerOptions;
      });
    }
    if(this.isMainCenterBelgrade){
      this.codebookService.getAllSubcenters().subscribe(result => {
        this.centerOptions = result.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.filteredCenterOptions = this.centerOptions;
      });
    }
    this.codebookService.getAccountingRightCodebook().subscribe(result => {
      this.rightOptions = result.sort((a: any, b: any) => a.title.localeCompare(b.title));
    });
    this.accountingProceduresService.getBtnStatus().subscribe((status) => {
      this.btnDisabled = status;
    });
    this.btnDisabled = false;
  }

  ngOnInit(): void {
    this.accountingProceduresForm = this.formBuilder.group({
      periodFrom: ['', []],
      periodTo: ['', []],
      centers: ['', []],
      rights: ['', []]
    });
  }

  tosslePerOneCenter() {
    if (this.allSelectedCenters.selected) {
      this.allSelectedCenters.deselect();
      return false;
    }
    if (this.accountingProceduresForm.controls.centers.value.length === this.centerOptions.length) {
      this.allSelectedCenters.select();
    }
  }

  tosslePerOneRight() {
    if (this.allSelectedRights.selected) {
      this.allSelectedRights.deselect();
      return false;
    }
    if (this.accountingProceduresForm.controls.rights.value.length === this.rightOptions.length) {
      this.allSelectedRights.select();
    }
  }

  toggleAllSelectionCenter() {
    if (this.allSelectedCenters.selected) {
      this.accountingProceduresForm.controls.centers.patchValue([...this.centerOptions.map(item => item.id), 0]);
    } else {
      this.accountingProceduresForm.controls.centers.patchValue([]);
    }
  }

  toggleAllSelectionRight() {
    if (this.allSelectedRights.selected) {
      this.accountingProceduresForm.controls.rights.patchValue([...this.rightOptions.map(item => item.id), 0]);
    } else {
      this.accountingProceduresForm.controls.rights.patchValue([]);
    }
  }

  selectMulti(){
    this.multiSearch.nativeElement.focus();
    this.multiSearch.nativeElement.value = null;
    this.filteredCenterOptions = this.centerOptions;
  }

  onInputChange(inputValue: string){
    const input = inputValue.toLowerCase();
    this.filteredCenterOptions = this.centerOptions.filter((center:any) => {
      const selectedCenters = this.accountingProceduresForm.controls.centers.value;
      return center.name.toLowerCase().includes(input) || selectedCenters.includes(center.id);
      });
  }

  /**
   * Send request
   */
  submitForm(form: any) {
    const dto = form.value;
    if (this.allSelectedRights.selected) {
      dto.rights = this.rightOptions.map(item => item.id);
    }
    if(!this.isAccountingProcedures && !this.isUserDrugostepeni && !this.isMainCenterBelgrade){
      dto.centers = [+this.loggedIn?.csrId]
    } else {
      if (this.allSelectedCenters.selected) {
        dto.centers = this.centerOptions.map(item => item.id);
      }
    }    
    this.accountingProceduresService.exportCSV(form.value);
    if(this.isAccountingProcedures){
      this.toastr.success('SNACKBAR.SUCCESSFUL_REQUEST_SENT');
    }
  }

  logout() {
    console.log('Logout user');

    this.authentication.logout();
  }
}
