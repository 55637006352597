/**
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { UserService } from 'src/services/user.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  fieldTextType = false;
  matcher = new MyErrorStateMatcher();

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public userId: any,
    private formBuilder: FormBuilder,
    private userService: UserService) {

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: [null, [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]],
      newPassword: [null, [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]],
      newPasswordConfirm: [null, [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]]
    }, { validators: this.checkPasswords });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  changePassword(form) {
    const newObject = {
      currentPassword: btoa(form.get('oldPassword').value),
      newPassword: btoa(form.get('newPassword').value),
      confirmation: btoa(form.get('newPasswordConfirm').value)
    };

    this.userService.changePassword(newObject, this.userId).subscribe(
      result => {
        this.dialogRef.close({ event: 'SUCCESS' });
      }
    );
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('newPassword').value;
    let confirmPass = group.get('newPasswordConfirm').value
    return pass === confirmPass ? null : { notSame: true }
  }

}
