<form class="form" [formGroup]="violenceMembers" (ngSubmit)="addViolenceMembers()">
  <form-group title="{{ 'ENTRANCE.VIOLENCE_STEP.TITLE' | translate }}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "ENTRANCE.VIOLENCE_STEP.FIRST_LAST_NAME" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="off" [readonly]="origin!=='entranceViolenceNotRegistered'" />
        <mat-error *ngIf="violenceMembers.get('name').hasError('required')">
          {{ "ENTRANCE.VIOLENCE_STEP.FIRST_LAST_NAME_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{ "ENTRANCE.VIOLENCE_STEP.RELATIONSHIP" | translate }}</mat-label>
        <mat-select formControlName="relationship" required>
          <mat-option *ngFor="let relationship of relationshipOptions" [value]="relationship">
            {{ relationship.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="violenceMembers.get('relationship').hasError('required')">
          {{ "FAMILY_MEMBERS.RELATIONSHIP_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "ENTRANCE.VIOLENCE_STEP.REG_NUMBER" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="regNumber" autocomplete="off" readonly/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "ENTRANCE.VIOLENCE_STEP.SUBJECT_ID" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="subjectId" autocomplete="off" readonly/>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button mat-dialog-close color="primary" (click)="closeDialog()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ "FAMILY_MEMBERS.CANCEL" | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
      [disabled]="members.length === 0 && !violenceMembers.valid">
      {{ "FAMILY_MEMBERS.ADD" | translate }}
    </button>
  </form-footer>
</form>