import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-timeout-indicator',
  templateUrl: './timeout-indicator.component.html',
  styleUrls: ['./timeout-indicator.component.scss']
})
export class TimeoutIndicatorComponent implements OnInit {

  timeout;
  @Input() date: string = '';
  @Input() startOrEnd: string;

  constructor() { }

  ngOnInit(): void {
    this.timeout = this.date;
  }

}
