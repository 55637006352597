import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { checkRelationshipAgeDifference } from 'src/app/utils/checkRelationshipAgeDifference';
import { CodebookService } from 'src/services/codebook.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { Subject } from 'src/types';
import { FamilyMembersComponent } from '../family-members/family-members.component';
import { EventEnum, FamilyMember } from '../types';
import * as myLetters from './../../letters';

@Component({
  selector: 'app-violence-members',
  templateUrl: './violence-members.component.html',
  styleUrls: ['./violence-members.component.scss']
})
export class ViolenceMembersComponent implements OnInit {
  violenceMembers: FormGroup;
  members: Array<FamilyMember> = [];
  origin: any;
  yesNoOptions = [];
  relationshipOptions = [];

  constructor(
    public dialog: MatDialog,
    public subjectsService: SubjectsService,
    public dialogRef: MatDialogRef<FamilyMembersComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public familyData: any,
    private formBuilder: FormBuilder,
    private codebook: CodebookService,
    private toastr: ToastrImplService) {
    if (familyData.origin === 'entranceViolence') {
      this.origin = 'entranceViolence';
    } else if (familyData.origin === 'entranceViolenceNotRegistered') {
      this.origin = 'entranceViolenceNotRegistered';
    }
    this.codebook.getRelationshipCodebookWithoutUser().subscribe(
      result => {
        this.relationshipOptions = result;
      }
    );
  }

  closeDialog() {
    this.dialogRef.close({ event: EventEnum.CANCEL });
  }

  ngOnInit() {
    this.violenceMembers = this.formBuilder.group({
      name: [
        '',
        [Validators.required, Validators.pattern(myLetters.letters)],
      ],
      relationship: ['', [Validators.required]],
      regNumber: ['', []],
      subjectId: ['', []],
      dateOfBirth: [''],
      jmbg: [''],
      yearOfBirth: [''],
      foreigner: [false]
    });
    if (this.familyData.data) {
      this.patchForm(this.familyData.data);
    }
  }

  /**
   * Update form afer getting the subject data (on init, or after adding new user)
   */
  patchForm(data: Partial<Subject>) {
    const {
      firstName,
      lastName,
      registrationId,
      subjectIdentity,
      dateOfBirth,
      jmbg,
      yearOfBirth,
      foreigner
    } = data;

    this.violenceMembers.patchValue({
      name: `${firstName} ${lastName}`,
      regNumber: registrationId,
      subjectId: subjectIdentity.subjectId,
      dateOfBirth,
      jmbg,
      yearOfBirth,
      foreigner
    });
  }

  // Submit
  addViolenceMembers() {
    if (this.violenceMembers.value.relationship !== null) {
      if (!checkRelationshipAgeDifference
        (this.violenceMembers.value, this.familyData.subject, this.violenceMembers.value.relationship.id, this.toastr)) {
        this.dialogRef.close({ event: EventEnum.CANCEL })
        return;
      }
    }
    if (this.violenceMembers.value.firstLastName !== null) {
      const member = this.violenceMembers.value;
      if (this.origin !== 'entranceViolenceNotRegistered') {
        member.subjectIdentity = this.familyData.data.subjectIdentity;
      }
      this.members = [...this.members, member];
    }
    this.dialogRef.close({ event: EventEnum.SUBMIT, data: this.members });
  }
}
