/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProtegePersonService } from 'src/services/protege-person.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
  selector: 'app-new-protege',
  templateUrl: './new-protege.component.html',
  styleUrls: ['./new-protege.component.scss']
})
export class NewProtegeComponent implements OnInit {
  public guardianshipType: 'adult' | 'minor';
  personalInformationStep: FormGroup;
  propertyStep: FormGroup;
  guardianStep: FormGroup;
  terminationOfGuardianshipStep: FormGroup;
  protegePersonId: any;
  subjectId: any;
  year: any
  constructor(
    private router: Router,
    private protegePersonService: ProtegePersonService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private toastr: ToastrImplService) {
    this.personalInformationStep = formBuilder.group({});
    this.propertyStep = formBuilder.group({});
    this.guardianStep = formBuilder.group({});
    this.terminationOfGuardianshipStep = formBuilder.group({});
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.protegePersonId = params.id;
        this.guardianshipType = params.guardianshipType;
        this.subjectId = params.subjectId;
        this.year = params.year;
      });
  }

  /**
   * Take information from personal step
   */
  updatePersonalInformationStep(step: FormGroup) {
    this.personalInformationStep = step;
  }

  /**
   * Take information from property step
   */
  updatePropertyStep(step: FormGroup) {
    this.propertyStep = step;
  }

  /**
   * Take information from reason for guardian step
   */
  updateGuardianStep(step: FormGroup) {
    this.guardianStep = step;
  }

  /**
   * Take information from reason for termination of guardianship step
   */
  updateTerminationOfGuardianshipStep(step: FormGroup) {
    // This is the final step where, apart from validating the data from this step
    // we are supposed to submit the new record to dedicated service.
    this.terminationOfGuardianshipStep = step;

    this.insertProtegeCollection();
  }

  insertProtegeCollection() {
    const body = {
      protegePersonDetails: {
        firstName: this.personalInformationStep.value.firstName,
        lastName: this.personalInformationStep.value.lastName,
        parentFirstName: this.personalInformationStep.value.parentFirstName,
        parentLastName: this.personalInformationStep.value.parentLastName,
        jmbg: this.personalInformationStep.value.jmbg,
        placeOfBirth: this.personalInformationStep.value.placeOfBirth !== '' ? this.personalInformationStep.value.placeOfBirth : null,
        municipality: this.personalInformationStep.value.municipality !== '' ? this.personalInformationStep.value.municipality : null,
        placeOfOrigin: this.personalInformationStep.value.placeOfOrigin !== '' ? this.personalInformationStep.value.placeOfOrigin : null,
        nationality: this.personalInformationStep.value.nationality !== '' ? this.personalInformationStep.value.nationality : null,
        citizenship: this.personalInformationStep.value.citizenship !== '' ? this.personalInformationStep.value.citizenship : null,
        occupation: this.personalInformationStep.value.occupation !== '' ? this.personalInformationStep.value.occupation : null,
        accomodationInfo: this.personalInformationStep.value.accomodationInfo,
        accomodationType: this.personalInformationStep.value.accomodationType,
        decisionNumberDeprivation: this.personalInformationStep.value.decisionNumberDeprivation,
        decisionDateDeprivation: this.personalInformationStep.value.decisionDateDeprivation !== null ?
           this.datePipe.transform(new Date(this.personalInformationStep.value.decisionDateDeprivation).getTime(), 'yyyy-MM-dd') : null,
        decisionMakerNameDeprivation: this.personalInformationStep.value.decisionMakerNameDeprivation,
        courtDecisionContent: this.personalInformationStep.value.courtDecisionContent,
        decisionNumberGuardianship: this.personalInformationStep.value.decisionNumberGuardianship,
        decisionDateGuardianship: this.personalInformationStep.value.decisionDateGuardianship !== null ?
           this.datePipe.transform(new Date(this.personalInformationStep.value.decisionDateGuardianship).getTime(), 'yyyy-MM-dd') : null,
        decisionMakerNameGuardianship: this.personalInformationStep.value.decisionMakerNameGuardianship,
      },
      assetValueOnCensusDay: this.propertyStep.value.assetValueOnCensusDay,
      assetValueOnTheDayOfTerminationOfCustody: this.propertyStep.value.assetValueOnTheDayOfTerminationOfCustody,
      decisionNumber: this.terminationOfGuardianshipStep.value.decisionNumber,
      decisionName: this.terminationOfGuardianshipStep.value.decisionName,
      decisionDate: this.terminationOfGuardianshipStep.value.decisionDate !== null ?
        this.datePipe.transform(new Date(this.terminationOfGuardianshipStep.value.decisionDate).getTime(), 'yyyy-MM-dd') : null,
      terminationReasonCodebook: this.terminationOfGuardianshipStep.value.terminationReasonCodebook === ''?null:this.terminationOfGuardianshipStep.value.terminationReasonCodebook,
      guardianFirstName: this.guardianStep.value.guardian.firstName !== '' ? this.guardianStep.value.guardian.firstName : null,
      guardianLastName: this.guardianStep.value.guardian.lastName !== '' ? this.guardianStep.value.guardian.lastName : null,
      guardianJmbg: this.guardianStep.value.guardian.jmbg,
      guardianAddress: this.guardianStep.value.guardian.residence,
      guardianOccupation: this.guardianStep.value.guardian.occupation !== '' ? this.guardianStep.value.guardian.occupation : null,
      guardianCitizenship: this.guardianStep.value.guardian.citizenship !== '' ? this.guardianStep.value.guardian.citizenship : null,
      guardianRelationship: this.guardianStep.value.guardian.relationship !== '' ? this.guardianStep.value.guardian.relationship : null,
      guardianPhone: this.guardianStep.value.guardian.phone !== '' ? this.guardianStep.value.guardian.phone : null,
      collectiveGuardianFirstName: this.guardianStep.value.collectiveGuardian.firstName,
      collectiveGuardianLastName: this.guardianStep.value.collectiveGuardian.lastName,
      collectiveGuardianTypeOfJob: this.guardianStep.value.collectiveGuardian.typeOfJob,
      collectiveGuardianProtectionName: this.guardianStep.value.collectiveGuardian.socialProtectionName,
      directGuardianFirstName: this.guardianStep.value.directGuardian.firstName,
      directGuardianLastName: this.guardianStep.value.directGuardian.lastName,
      directGuardianOccupation: this.guardianStep.value.directGuardian.occupation !== '' ?
        this.guardianStep.value.directGuardian.occupation : null,

    };

    this.protegePersonService.insertIntoCollection(this.protegePersonId, body,
      ).subscribe((res) => {
        if (res != null) {
          this.toastr.success('SNACKBAR.ADDED_PROTEGE_PERSON');
          this.router.navigateByUrl('/proteges');
        } else {
          this.toastr.error('SNACKBAR.ADD_PROTEGE_PERSON_ERROR');
          this.router.navigateByUrl('/proteges');
        }
      });

  }
  /**
   * Review if all form steps are valid
   */
  checkDocumentValidation(): boolean {
    const isPersonalInformationStepValid =
      this.personalInformationStep && this.personalInformationStep.valid;
    const isPropertyStepValid =
      this.propertyStep && this.propertyStep.valid;
    const isGuardianStepValid =
      this.guardianStep && this.guardianStep.valid;
    const isTerminationOfGuardianshipStepd =
      this.terminationOfGuardianshipStep && this.terminationOfGuardianshipStep.valid;

    return [
      isPersonalInformationStepValid,
      isPropertyStepValid,
      isGuardianStepValid,
      isTerminationOfGuardianshipStepd
    ].every((val) => val === true);
  }

}
