<form [formGroup]="accommodationFacilityForm" class="form">
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>
        {{'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.FACILITY' | translate }}
      </mat-label>
      <mat-select formControlName="accomodationFacility" [compareWith]="compareObj" required
        (selectionChange)="selectFacility($event.value)">
        <mat-option *ngFor="let option of accommodationFacilityCodebook" [value]="option">
          {{ option.id}} | {{option.name}} , {{option.community}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </field-holder>

  <div class="table-responsive-wrapper" *ngIf="currentFacility?.accommodationPricingCodebook?.length > 0">
    <table mat-table id="myTable" class="category__table" #myTable
      [dataSource]="currentFacility?.accommodationPricingCodebook">
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.CATEGORY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          ({{ element?.accommodationCategoryCodebook?.code}}) {{ element?.accommodationCategoryCodebook?.title}}
        </td>
      </ng-container>
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.PRICE.MONTHLY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.price}} {{ 'GLOBAL.RSD' | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="pricePerDay">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.PRICE.DAILY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.pricePerDay }} {{ 'GLOBAL.RSD' | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="validFrom">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.VALID_FROM' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.validFrom  | date : 'dd.MM.yyyy.'}} 
        </td>
      </ng-container>
      <ng-container matColumnDef="validTo">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.VALID_TO' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.validTo  | date : 'dd.MM.yyyy.'}} 
        </td>
      </ng-container>
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button class="button-square table__row-button" color="primary"
            matTooltip="{{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.REMOVE_CATEGORY' | translate }}"
            (click)="$event.stopPropagation(); removePricingWarning(element);">
            <mat-icon class="button-square__icon">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="category__row--header"></tr>
      <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" (click)="onRowClicked(element)"
        class="category__row">
      </tr>
    </table>
  </div>

  <button mat-flat-button color="primary" (click)="addNewPricing()" [disabled]="!currentFacility">
    {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.ADD_PRICE' | translate }}
  </button>

  <field-holder kind="3-cols">
    <mat-checkbox color="primary" class="form__checkbox" *ngIf="currentFacility!=null"
      [checked]="currentFacility!=null && currentFacility.hasSupportCategory"
      (change)="changeHasSupportCategory($event)">
      {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.SUPPORT_DEGREE' | translate }}
    </mat-checkbox>
  </field-holder>
  <div class="table-responsive-wrapper" *ngIf="currentFacility?.supportCategoryPrice?.length > 0  && currentFacility.hasSupportCategory">
    <table mat-table id="supportCategoryPrice" class="category__table" #supportCategoryPrice
      [dataSource]="currentFacility?.supportCategoryPrice">
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.SUPPORT_DEGREE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          ({{ element?.supportCategoryCodebook?.code}}) {{ element?.supportCategoryCodebook?.title}}
        </td>
      </ng-container>
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.PRICE.MONTHLY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.amountMonth}} {{ 'GLOBAL.RSD' | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="validFrom">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.VALID_FROM' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.validFrom  | date : 'dd.MM.yyyy.'}} 
        </td>
      </ng-container>
      <ng-container matColumnDef="validTo">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.VALID_TO' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.validTo  | date : 'dd.MM.yyyy.'}} 
        </td>
      </ng-container>
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button class="button-square table__row-button" color="primary"
            matTooltip="{{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.REMOVE_CATEGORY' | translate }}"
            (click)="$event.stopPropagation(); removeSupportWarning(element);">
            <mat-icon class="button-square__icon">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsSupport" class="category__row--header"></tr>
      <tr mat-row *matRowDef="let element; let row; columns: displayedColumnsSupport" (click)="onSupportRowClicked(element)"
        class="category__row">
      </tr>
    </table>
  </div>
  <button mat-flat-button color="primary" (click)="addNewSupport()" *ngIf="currentFacility!=null && currentFacility.hasSupportCategory">
    {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.ADD_SUPPORT_CATEGORY' | translate }}
  </button>

</form>