<div class="wrapper">
  <icon-file size="26" class="document-icon"></icon-file>
  <div class="content">
    <header class="title-wrapper">
      <h5 class="title">{{ title }}</h5>
    </header>
    <span
      [ngClass]="{
        'status': true,
        'status--signed': status === 'SIGNED',
        'status--not-signed': status === 'NOT_SIGNED',
        'status--rejected': status === 'REJECTED',
        'status--none': status === 'NONE'
      }"
      >
      {{ signatureStatus }}
      </span
    >
  </div>
  <div class="action-wrapper">
    <ng-content></ng-content>
  </div>
</div>
