/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 *
*/
/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */
export class Form5Task {

  outcome: string;
  task: string;
  responsiblePerson: string;
  fromDate: Date;
  toDate: Date;

  constructor(outcome: string, task: string, responsiblePerson: string, fromDate: Date, toDate: Date) {
    this.outcome = outcome;
    this.task = task;
    this.responsiblePerson = responsiblePerson;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }
}
