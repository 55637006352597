<form [formGroup]="reasonsForParentalAbsenceStep" class="form">
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.CHILD_WITHOUT_PARENTAL_CARE' | translate
      }}</mat-label>
      <mat-select formControlName="childWithoutParentalCare" multiple>
        <mat-option *ngFor="let option of withoutCareOptions" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.CHILD_WITH_PARENTAL_CARE' | translate
      }}</mat-label>
      <mat-select formControlName="childWithParentalCare" multiple>
        <mat-option *ngFor="let option of withCareOptions" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_FOSTER_CHILD.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!reasonsForParentalAbsenceStep.valid"
      class="form-footer__button" (click)="updateReasonsForParentalAbsenceStep()">
      {{ 'NEW_FOSTER_CHILD.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>