import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SubjectNewRecordComponent } from 'src/app/subjects/subject-details/subject-new-record/subject-new-record.component';
import { CaseService } from 'src/services/case.service';
import { ModalSizeEnum } from 'src/types';
import { ToastrImplService } from './toastr.service';

@Injectable({
   providedIn: 'root',
})
export class CaseProblemRecordService {
   // tipovi predmeta za koje je potrebno dodavanje problema i evidencije
   private caseKinds = ['551', '553', '560', '561', '572'];
   constructor(public dialog: MatDialog, private translate: TranslateService, private toastr: ToastrImplService, private caseService: CaseService) {}
   caseHasProblem(caseStatus: any, caseId: any, caseKind: any): Observable<boolean> {
      if (caseStatus === 'ACCEPTED' && this.caseKinds.includes(caseKind)) {
         return new Observable<boolean>(observer => {
            this.caseService.hasProblem(caseId).subscribe(result => {
               if (result) {
                  observer.next(true);
               } else {
                  this.toastr.warning('CASES.NO_PROBLEM');
                  observer.next(false);
               }
               observer.complete();
            });
         });
      } else {
         return new Observable<boolean>(observer => {
            observer.next(true);
            observer.complete();
         });
      }
   }
   async addNewRecord(subjectId: any, caseId: any, caseKind: any, patchData: any): Promise<boolean> {
      if (!this.caseKinds.includes(caseKind)) {
         return true;
      }
      const hasRecord = await this.caseHasRecord(caseId).toPromise();
      if (!hasRecord) {
         const dialogRef = this.dialog.open(SubjectNewRecordComponent, {
            disableClose: true,
            width: ModalSizeEnum.DEFAULT,
            panelClass: 'overlay-panel',
            data: {
               subjectId,
               record: {
                  caseId: null,
               },
               caseId,
               patchData,
            },
         });
         try {
            const result = await dialogRef.afterClosed().toPromise();
            const { event, record } = result;
            if (event === 'Create') {
               this.toastr.success('SNACKBAR.ADDED_RECORD');
               return true;
            }
            return false;
         } catch (error_1) {
            console.error('An error occurred:', error_1);
            return false;
         }
      }
      return true;
   }

   caseHasRecord(caseId: any): Observable<boolean> {
      return new Observable<boolean>(observer => {
         this.caseService.checkExistRightForCaseId(caseId).subscribe(result => {
            if (result) {
               observer.next(true);
            } else {
               observer.next(false);
            }
            observer.complete();
         });
      });
   }
   // provera za ispisivanje matTooltipa kod submit dugmeta
   checkIfSendIsDisabled = (caseData: any, forms: boolean, hasProblem: boolean) => {
      let msg = '';
      if (caseData?.caseStatus === 'ACCEPTED') {
         if (!hasProblem) {
            this.translate.get('CASES.NO_PROBLEM').subscribe((resp: string) => {
               msg = resp;
            });
            return { disabled: false, msg: msg };
         } else {
            if (forms) {
               this.translate.get('CASES.FORM_NOT_VALID').subscribe((resp: string) => {
                  msg = resp;
               });
               return { disabled: false, msg: msg };
            } else {
               return { disabled: true, msg: msg };
            }
         }
      } else {
         this.translate.get('CASES.NOT_ACCEPTED').subscribe((resp: string) => {
            msg = resp;
         });
         return { disabled: false, msg: msg };
      }
   };
}
