import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TeamRequestService } from 'src/services/team-request.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
   selector: 'app-team-request',
   templateUrl: './team-request.component.html',
   styleUrls: ['./team-request.component.scss'],
})
export class TeamRequestComponent implements OnInit {
   explanation = new FormControl('', [Validators.required]);

   constructor(
      public dialog: MatDialog,
      private teamRequestService: TeamRequestService,
      public dialogRef: MatDialogRef<TeamRequestComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private toastr: ToastrImplService
   ) {}

   ngOnInit(): void {}

   sendRequest() {
      const teamRequestObject = {
         request: this.explanation.value,
         caseId: this.data,
      };
      this.teamRequestService.createTeamRequest(teamRequestObject).subscribe(
         result => {
            this.toastr.info('TEAM_REQUEST.REQUEST_SENT');
            this.dialogRef.close({ event: 'CREATED' });
         },
         error => {
            this.dialogRef.close({ event: 'ERROR' });
         }
      );
   }

   closeDialog() {
      this.dialogRef.close({ event: 'CANCEL' });
   }
}
