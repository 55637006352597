/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-file-tracking',
  templateUrl: './icon-file-tracking.component.svg',
  styleUrls: ['./icon-file-tracking.component.scss']
})
export class IconFileTrackingComponent implements OnInit {
  @Input() fill: string = '#e0e0e0';
  @Input() fillAccent: string = '#3c4ff9';
  @Input() size: number = 36;
  @Input() class: string | string[] | Set<string>;
  constructor() { }

  ngOnInit(): void {
  }

}
