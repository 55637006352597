/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';

@Component({
  selector: 'new-foster-child-reasons-for-parental-absence-step',
  templateUrl:
    './new-foster-child-reasons-for-parental-absence-step.component.html',
  styleUrls: [
    './new-foster-child-reasons-for-parental-absence-step.component.scss',
  ],
})
export class NewFosterChildReasonsForParentalAbsenceStepComponent
  implements OnInit {
  @Output() reasonsForParentalAbsenceStepEmitter = new EventEmitter();
  @Input() reasonsForParentalAbsenceStep: FormGroup;


  constructor(private formBuilder: FormBuilder, private codebookService: CodebookService) { }
  withoutCareOptions: any = [];

  withCareOptions: any = [];

  ngOnInit(): void {
    this.reasonsForParentalAbsenceStep = this.formBuilder.group({
      childWithoutParentalCare: ['', []],
      childWithParentalCare: ['', []],
    });

    this.getAbsenceCareWithParents();
    this.getAbsenceCareWithoutParents();
  }

  /**
   * Send step information to parent component
   */
  updateReasonsForParentalAbsenceStep() {
    this.reasonsForParentalAbsenceStepEmitter.emit(
      this.reasonsForParentalAbsenceStep
    );
  }

  getAbsenceCareWithoutParents() {
    this.codebookService.getAbsenceCareWithoutParentsCodebook().subscribe((res) => {
      this.withoutCareOptions = res;
    })
  }

  getAbsenceCareWithParents() {
    this.codebookService.getAbsenceCareWithParentsCodebook().subscribe((res) => {
      this.withCareOptions = res;
    })
  }
}
