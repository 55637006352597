/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import {
  Component,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  Optional,
  Inject,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { SubjectsService } from './../../../services/subjects.service';
import { Page } from '../../page';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { Subject } from 'src/models/subject.model';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { SubjectFormComponent } from 'src/app/subjects/subject-form/subject-form.component';
import { environment } from 'src/environments/environment';
import { CodebookService } from 'src/services/codebook.service';
import { FormControl } from '@angular/forms';
import { LocalStorageService } from 'src/app/local-storage.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from 'src/services/user.service';

export interface SearchItem {
  name: string;
}

enum AutocompleteFilterEnum {
  SUBCENTER = 'subcenter',
}

@Component({
  selector: 'side-subjects',
  templateUrl: './side-subjects.component.html',
  styleUrls: ['./side-subjects.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideSubjectsComponent {

  selectable = true;
  removable = true;
  readonly separatorKeysCodes: number[] = [COMMA, ENTER];
  public searchTerms: string[] = [];

  public globalFilter = '';
  public searchItems: SearchItem[] = [];
  page: Page<Subject> = new Page();
  public resetCourses = this.page;
  myInput = '';
  object: { origin: string; };
  newSubject: any;

  displayedColumns: string[] = [
    'registrationId',
    'id',
    'lastName',
    'firstName',
    'dateOfBirth',
    'jmbg'
  ];
  areLoaded = false;
  fileUrl: any;
  subcenter = new FormControl();
  subcenterCsrId: any;
  subcenters = [];
  loggedIn: any;
  isMainBelgradeCenter = false;
  isUserDrugostepeni = false;
  filteredSubcenters: Observable<any>;
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;

  constructor(
    private subjectsService: SubjectsService,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<SubjectFormComponent>,
    private paginationService: CustomPaginationService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    private codebookService: CodebookService,
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) {

    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    if (this.loggedIn?.csrId === '1') {
      this.isMainBelgradeCenter = true;
    }

    if (this.isUserDrugostepeni || this.isMainBelgradeCenter) {
      this.subcenterCsrId = 0;
    }

    if (this.isUserDrugostepeni) {
      // Drugostepeni - svi centri
      this.codebookService.getCsrCodebook().subscribe(result => {
        this.subcenters = result;
        this.filteredSubcenters = this.subcenter.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(title => title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice())
          );
      });
    } else {
      // Glavni centar Beograd - podcentri
      this.codebookService.getAllSubcenters().subscribe(result => {
        this.subcenters = result;
        this.filteredSubcenters = this.subcenter.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(title => title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice())
          );
      });
    }

    this.object = { ...data };
    this.doFilter();
  }

  public getNextPage(): void {
    this.areLoaded = false;
    this.page.pageable = this.paginationService.getNextPage(this.page);
    this.doFilter();
  }

  public getPreviousPage(): void {
    this.areLoaded = false;
    this.page.pageable = this.paginationService.getPreviousPage(this.page);
    this.doFilter();
  }

  public getFirstPage(): void {
    this.areLoaded = false;
    this.page.pageable = this.paginationService.getFirstPage(this.page);
    this.doFilter();
  }

  public getLastPage(): void {
    this.areLoaded = false;
    this.page.pageable = this.paginationService.getLastPage(this.page);
    this.doFilter();
  }

  public getPageInNewSize(pageSize: any): void {
    this.areLoaded = false;
    this.page.pageable = this.paginationService.getPageInNewSize(
      this.page,
      pageSize
    );
    this.doFilter();
  }

  doFilter() {
    // Ako se radi o glavnom centru u Beogradu -> GCBG ili drugostepenom postupku
    if (this.loggedIn?.csrId === '1' || this.isUserDrugostepeni) {
      if (this.subcenterCsrId !== undefined) {
        this.subjectsService
          .getFilteredPage(this.page.pageable, this.myInput, this.subcenterCsrId)
          .subscribe((page) => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            for (const element of this.page.content) {
              element.dateOfBirth = (element.dateOfBirth as any)?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
              element.dateOfCreation = (element.dateOfCreation as any)?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
            }
            this.areLoaded = true;
            this.cdr.detectChanges();
          });
      }
    } else {
      this.subjectsService
        .getFilteredPage(this.page.pageable, this.myInput, this.loggedIn?.csrId)
        .subscribe((page) => {
          this.page = page;
          this.page.pageable.pagePerShow = page.number + 1;
          for (const element of this.page.content) {
            element.dateOfBirth = (element.dateOfBirth as any)?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
            element.dateOfCreation = (element.dateOfCreation as any)?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
          }
          this.areLoaded = true;
          this.cdr.detectChanges();
        });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add new search term
    if ((value || '').trim()) {
      this.searchItems.push({ name: value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.searchTerms = this.searchItems.map(function (searchItem) {
      return searchItem.name;
    });
    this.globalFilter = '';
    this.applyFilter(this.searchTerms);
  }

  remove(item: SearchItem): void {
    const index = this.searchItems.indexOf(item);
    if (index >= 0) {
      this.searchItems.splice(index, 1);
      this.searchTerms = this.searchItems.map(function (searchItem) {
        return searchItem.name;
      });
      this.applyFilter(this.searchTerms);
    }
  }

  applyFilter(filterValue: any) {
    this.globalFilter = filterValue;
    if (this.loggedIn?.csrId === '1' || this.isUserDrugostepeni) {
      if (this.subcenterCsrId !== undefined) {
        this.subjectsService
          .getFilteredPage(this.page.pageable, filterValue, this.subcenterCsrId)
          .subscribe((page) => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            for (const element of this.page.content) {
              element.dateOfBirth = (element.dateOfBirth as any)?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
              element.dateOfCreation = (element.dateOfCreation as any)?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
            }
            this.areLoaded = true;
            this.cdr.detectChanges();
          });
      }
    } else {
      this.subjectsService
        .getFilteredPage(this.page.pageable, filterValue, this.loggedIn?.csrId)
        .subscribe((page) => {
          this.page = page;
          this.page.pageable.pagePerShow = page.number + 1;
          for (const element of this.page.content) {
            element.dateOfBirth = (element.dateOfBirth as any)?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
            element.dateOfCreation = (element.dateOfCreation as any)?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
          }
          this.areLoaded = true;
          this.cdr.detectChanges();
        });
    }
  }

  // Otvaranje stranice za kreiranje novog koriniska u novom tabu
  newSubjectInNewTab() {
    window.open(environment.getNewSubjectTab, '_blank');
  }

  reloadSubjects(event: any) {
    this.areLoaded = false;
    this.subcenterCsrId = event?.id;
    this.doFilter();
  }

  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();

    switch (what) {
      case AutocompleteFilterEnum.SUBCENTER:
        return this.subcenters.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
      default:
        break;
    }
  }

  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name : objectValue;
  }
  clickSubject(subjectId: any) {
    this.localStorageService.set('localStorageCenterId', (this.loggedIn?.csrId === '1' || this.isUserDrugostepeni) ?
      this.subcenterCsrId : this.loggedIn?.csrId);
    window.open(environment.getFamilyMemberTab + subjectId, '_blank');
  }
}
