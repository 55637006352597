<container kind="default">
    <page-intro-header back="/foster-child" title="{{ 'FOSTER_CHILD.T' | translate }}">
    </page-intro-header>
</container>
<container kind="default">
    <mat-card class="card">
        <definition-list>
            <definition-item *ngIf="fosterChild?.ordinalNumber" key="{{ 'PROTEGE_DETAILS.R_BR' | translate }}">
                {{ fosterChild.ordinalNumber}}</definition-item>
            <definition-item *ngIf="fosterChild?.subjectId" key="{{ 'PROTEGE_DETAILS.ID' | translate }}">
                {{ fosterChild.subjectId }}</definition-item>

            <definition-item *ngIf="fosterChild?.firstName" key="{{ 'NEW_FOSTER_CHILD.FORM.FIRST_NAME' | translate }}">
                {{ fosterChild.firstName }}</definition-item>


            <definition-item *ngIf="fosterChild?.lastName" key="{{ 'NEW_FOSTER_CHILD.FORM.LAST_NAME' | translate }}">
                {{ fosterChild.lastName }}</definition-item>

            <definition-item *ngIf="fosterChild?.gender" key="{{ 'NEW_FOSTER_CHILD.FORM.GENDER' | translate }}">
                {{ fosterChild.gender.gender }}</definition-item>

            <definition-item *ngIf="fosterChild?.dateOfBirth"
                key="{{ 'NEW_FOSTER_CHILD.FORM.DATE_OF_BIRTH' | translate }}">
                {{ fosterChild.dateOfBirth }}</definition-item>

            <definition-item *ngIf="fosterChild?.placeOfBirth"
                key="{{ 'NEW_FOSTER_CHILD.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ fosterChild.placeOfBirth }}</definition-item>

            <definition-item *ngIf="fosterChild?.jmbg" key="{{ 'NEW_FOSTER_CHILD.FORM.JMBG' | translate }}">
                {{ fosterChild.jmbg }}</definition-item>

            <definition-item *ngIf="fosterChild?.nationality"
                key="{{ 'NEW_FOSTER_CHILD.FORM.NATIONALITY' | translate }}">
                {{ fosterChild.nationality.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.citizenship"
                key="{{ 'NEW_FOSTER_CHILD.FORM.CITIZENSHIP' | translate }}">
                {{ fosterChild.citizenship.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.familyStatus"
                key="{{ 'NEW_FOSTER_CHILD.FORM.FAMILY_STATUS' | translate }}">
                {{ fosterChild.familyStatus.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.fosteringStatus !== null"
                key="{{ 'NEW_FOSTER_CHILD.FORM.FOSTERING_STATUS' | translate }}">
                {{ (fosterChild?.fosteringStatus ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}</definition-item>

            <definition-item *ngIf="fosterChild?.schoolStatus"
                key="{{ 'NEW_FOSTER_CHILD.FORM.EDUCATION_STATUS' | translate }}">
                {{ fosterChild.schoolStatus.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.schoolLevel" key="{{ 'NEW_FOSTER_CHILD.FORM.CLASS' | translate }}">
                {{ fosterChild.schoolLevel }}</definition-item>

            <definition-item *ngIf="fosterChild?.generalHealthStatusCodebook"
                key="{{ 'NEW_FOSTER_CHILD.FORM.GENERAL_HEALTH_STATUS' | translate }}">
                {{ fosterChild.generalHealthStatusCodebook.title }}</definition-item>
                
            <definition-item *ngIf="fosterChild?.healthDeviations?.length !== 0">
                <ul>
                    <li *ngFor="let dev of fosterChild?.healthDeviations">
                        {{dev.healthDeviationsCodebook.name}}
                        <span *ngIf="dev.additionalInfo !== null && dev.additionalInfo !== '' ">-
                            {{dev.additionalInfo}}</span>
                    </li>
                </ul>
            </definition-item>

            <definition-item *ngIf="fosterChild?.psychophysicalDevelopmentCodebook"
                key="{{ 'NEW_FOSTER_CHILD.FORM.PSYCHOPHYSICAL_DEVELOPMENT' | translate }}">
                {{ fosterChild.psychophysicalDevelopmentCodebook.title }}</definition-item>
                
            <definition-item *ngIf="fosterChild?.psychophysicalDevelopmentDeviations?.length !== 0">
                <ul>
                    <li *ngFor="let dev of fosterChild?.psychophysicalDevelopmentDeviations">
                        {{dev.psychophysicalDevelopmentDeviationsCodebook.name}}
                        <span *ngIf="dev.additionalInfo !== null && dev.additionalInfo !== '' ">-
                            {{dev.additionalInfo}}</span>
                    </li>
                </ul>
            </definition-item>

            <definition-item *ngIf="fosterChild?.psychophysicalPrognosis"
                key="{{ 'NEW_FOSTER_CHILD.FORM.PSYCHOPHYSICAL_PROGNOSIS' | translate }}">
                {{ fosterChild.psychophysicalPrognosis }}</definition-item>

            <definition-item *ngIf="fosterChild?.permanentResidence"
                key="{{ 'NEW_FOSTER_CHILD.FORM.PLACE_OF_ORIGIN' | translate }}">
                {{ fosterChild.permanentResidence }}</definition-item>

            <h2 *ngIf="fosterChild?.motherInformation">{{
          'NEW_FOSTER_CHILD.PARENT_INFORMATION.MOTHER' | translate
        }}
            </h2>

            <definition-item *ngIf="fosterChild?.motherInformation?.firstName"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FIRST_NAME' | translate }}">
                {{ fosterChild.motherInformation.firstName }}</definition-item>


            <definition-item *ngIf="fosterChild?.motherInformation?.lastName"
                key="{{ 'NEW_FOSTER_PARENT.FORM.LAST_NAME' | translate }}">
                {{ fosterChild.motherInformation.lastName }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.gender"
                key="{{ 'NEW_FOSTER_PARENT.FORM.GENDER' | translate }}">
                {{ fosterChild.motherInformation.gender.gender }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.dateOfBirth"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DATE_OF_BIRTH' | translate }}">
                {{ fosterChild.motherInformation.dateOfBirth }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.placeOfBirth"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ fosterChild.motherInformation.placeOfBirth }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.jmbg"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ fosterChild.motherInformation.jmbg }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.nationality"
                key="{{ 'NEW_FOSTER_PARENT.FORM.NATIONALITY' | translate }}">
                {{ fosterChild.motherInformation.nationality.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.citizenship"
                key="{{ 'NEW_FOSTER_PARENT.FORM.CITIZENSHIP' | translate }}">
                {{ fosterChild.motherInformation.citizenship.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.occupation"
                key="{{ 'NEW_FOSTER_PARENT.FORM.OCCUPATION' | translate }}">
                {{ fosterChild.motherInformation.occupation.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.employment"
                key="{{ 'NEW_FOSTER_PARENT.FORM.WORK_STATUS' | translate }}">
                {{ fosterChild.motherInformation.employment.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.education"
                key="{{ 'NEW_FOSTER_PARENT.FORM.EDUCATION' | translate }}">
                {{ fosterChild.motherInformation.education.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.residentalStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.HOUSEHOLD_STATUS' | translate }}">
                {{ fosterChild.motherInformation.residentalStatus }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.permanentResidence"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PLACE_OF_ORIGIN' | translate }}">
                {{ fosterChild.motherInformation.permanentResidence }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.maritalStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.MARITAL_STATUS' | translate }}">
                {{ fosterChild.motherInformation.maritalStatus.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.healthStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.HEALTH_STATUS' | translate }}">
                {{ fosterChild.motherInformation.healthStatus }}</definition-item>

            <definition-item *ngIf="fosterChild?.motherInformation?.residentialStatus"
                key="{{ 'NEW_FOSTER_CHILD.FORM.HOUSING_STATUS' | translate }}">
                {{ fosterChild.motherInformation.residentialStatus }}</definition-item>
            <definition-item
                *ngIf="fosterChild?.motherInformation !== null && fosterChild?.motherInformation?.addictions?.length !== 0"
                key="{{ 'FOSTER_CHILD.ADDICTIONS_TITLE' | translate }}">
                <ul>
                    <li *ngFor="let fc of fosterChild?.motherInformation?.addictions">
                        {{fc.addictionCodebook.title}}
                        <span *ngIf="fc.additionalInfo !== null && fc.additionalInfo !== '' ">-
                            {{fc.additionalInfo}}</span>
                    </li>
                </ul>
            </definition-item>


            <h2 *ngIf="fosterChild?.fatherInformation">{{
          'NEW_FOSTER_CHILD.PARENT_INFORMATION.FATHER' | translate
        }}
            </h2>

            <definition-item *ngIf="fosterChild?.fatherInformation?.firstName"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FIRST_NAME' | translate }}">
                {{ fosterChild.fatherInformation.firstName }}</definition-item>


            <definition-item *ngIf="fosterChild?.fatherInformation?.lastName"
                key="{{ 'NEW_FOSTER_PARENT.FORM.LAST_NAME' | translate }}">
                {{ fosterChild.fatherInformation.lastName }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.gender"
                key="{{ 'NEW_FOSTER_PARENT.FORM.GENDER' | translate }}">
                {{ fosterChild.fatherInformation.gender.gender }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.dateOfBirth"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DATE_OF_BIRTH' | translate }}">
                {{ fosterChild.fatherInformation.dateOfBirth }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.placeOfBirth"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ fosterChild.fatherInformation.placeOfBirth }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.jmbg"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ fosterChild.fatherInformation.jmbg }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.nationality"
                key="{{ 'NEW_FOSTER_PARENT.FORM.NATIONALITY' | translate }}">
                {{ fosterChild.fatherInformation.nationality.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.citizenship"
                key="{{ 'NEW_FOSTER_PARENT.FORM.CITIZENSHIP' | translate }}">
                {{ fosterChild.fatherInformation.citizenship.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.occupation"
                key="{{ 'NEW_FOSTER_PARENT.FORM.OCCUPATION' | translate }}">
                {{ fosterChild.fatherInformation.occupation.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.employment"
                key="{{ 'NEW_FOSTER_PARENT.FORM.WORK_STATUS' | translate }}">
                {{ fosterChild.fatherInformation.employment.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.education"
                key="{{ 'NEW_FOSTER_PARENT.FORM.EDUCATION' | translate }}">
                {{ fosterChild.fatherInformation.education.title }}</definition-item>
            <definition-item *ngIf="fosterChild?.fatherInformation?.residentalStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.HOUSEHOLD_STATUS' | translate }}">
                {{ fosterChild.fatherInformation.residentalStatus }}</definition-item>
            <definition-item *ngIf="fosterChild?.fatherInformation?.permanentResidence"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PLACE_OF_ORIGIN' | translate }}">
                {{ fosterChild.fatherInformation.permanentResidence }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.maritalStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.MARITAL_STATUS' | translate }}">
                {{ fosterChild.fatherInformation.maritalStatus.title }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.healthStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.HEALTH_STATUS' | translate }}">
                {{ fosterChild.fatherInformation.healthStatus }}</definition-item>

            <definition-item *ngIf="fosterChild?.fatherInformation?.residentialStatus"
                key="{{ 'NEW_FOSTER_CHILD.FORM.HOUSING_STATUS' | translate }}">
                {{ fosterChild.fatherInformation.residentialStatus }}</definition-item>

            <definition-item
                *ngIf="fosterChild?.fatherInformation !==  null && fosterChild?.fatherInformation?.addictions?.length !== 0"
                key="{{ 'FOSTER_CHILD.ADDICTIONS_TITLE' | translate }}">
                <ul>
                    <li *ngFor="let fc of fosterChild?.fatherInformation?.addictions">
                        {{fc.addictionCodebook.title}} <span
                            *ngIf="fc.additionalInfo !== null && fc.additionalInfo !== '' ">-
                            {{fc.additionalInfo}}</span></li>
                </ul>
            </definition-item>

            <h2 *ngIf="fosterChild?.brothersAndSisters.length !== 0">
                {{
          'NEW_FOSTER_CHILD.CLOSE_MEMBERS.BROTHERS_AND_SISTERS' | translate
        }}
            </h2>

            <div class="table-responsive-wrapper" *ngIf="fosterChild?.brothersAndSisters.length>0">
                <table mat-table [dataSource]="fosterChild?.brothersAndSisters" #mytable>
                    <ng-container matColumnDef="information">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'FOSTER_CHILD.FAMILY_DATA.INFORMATION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.information }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsBrothersAndSisters"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsBrothersAndSisters"
                        class="problems-table__row"></tr>
                </table>
            </div>


            <h2 *ngIf="fosterChild?.guardians">{{
          'NEW_FOSTER_CHILD.CLOSE_MEMBERS.ADDITIONAL_INFORMATION' | translate
        }}
            </h2>

            <div class="table-responsive-wrapper" *ngIf="fosterChild?.guardians?.length>0">
                <table mat-table [dataSource]="fosterChild?.guardians" #mytable>
                    <ng-container matColumnDef="information">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'FOSTER_CHILD.FAMILY_DATA.INFORMATION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.information }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="relationshipCodebook">
                        <mat-form-field appearance="outline">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'FOSTER_CHILD.FAMILY_DATA.RELATIONSHIP' | translate }}
                            </th>
                            <input matInput type="text" formControlName="relationshipCodebook" autocomplete="off" />
                            <td mat-cell *matCellDef="let element">
                                {{ element.relationshipCodebook?.title }}
                            </td>
                        </mat-form-field>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumnsGuardians"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsGuardians" class="problems-table__row">
                    </tr>
                </table>
            </div>

            <definition-item *ngIf="fosterChild?.acquaintancesInfo"
                key="{{ 'NEW_FOSTER_CHILD.FORM.ACQUAINTANCES_INFO' | translate }}">
                {{ fosterChild.acquaintancesInfo }}</definition-item>

            <h2 *ngIf="fosterChild?.costCarriersParents">{{
          'NEW_FOSTER_CHILD.COST_CARRIERS.TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="fosterChild?.costCarriersParents"
                key="{{ 'NEW_FOSTER_CHILD.FORM.PARENTS' | translate }}">
                {{ fosterChild.costCarriersParents }}</definition-item>

            <definition-item *ngIf="fosterChild?.costCarriersOtherRelatives"
                key="{{ 'NEW_FOSTER_CHILD.FORM.OTHER_RELATIVES' | translate }}">
                {{ fosterChild.costCarriersOtherRelatives }}</definition-item>

            <definition-item *ngIf="fosterChild?.costCarriersOwnEarnings"
                key="{{ 'NEW_FOSTER_CHILD.FORM.OWN_INCOME' | translate }}">
                {{ fosterChild.costCarriersOwnEarnings}}</definition-item>
                
            <definition-item *ngIf="fosterChild?.costCarriers"
                key="{{ 'NEW_FOSTER_CHILD.FORM.COST_CARRIERS' | translate }}">
                {{ fosterChild.costCarriers }}</definition-item>

            <h2 *ngIf="fosterChild?.professionalOpinion">{{
          'NEW_FOSTER_PARENT.SUITABILITY.TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.psychologistDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PSYCHOLOGIST' | translate }}">
                {{ fosterChild.professionalOpinion.psychologistDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.pedagogueDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PEDAGOGUE' | translate }}">
                {{ fosterChild.professionalOpinion.pedagogueDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.lawyerDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.LAWYER' | translate }}">
                {{ fosterChild.professionalOpinion.lawyerDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.doctorDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DOCTOR' | translate }}">
                {{ fosterChild.professionalOpinion.doctorDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.socialWorkerDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.SOCIAL_WORKER' | translate }}">
                {{ fosterChild.professionalOpinion.socialWorkerDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.institutionDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.OTHER_INSTITUTIONS' | translate }}">
                {{ fosterChild.professionalOpinion.institutionDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.numberOfDecision"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DECISION_NUMBER' | translate }}">
                {{ fosterChild.professionalOpinion.numberOfDecision }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.decisionDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DECISION_DATE' | translate }}">
                {{ fosterChild.professionalOpinion.decisionDate | date: 'dd.MM.yyyy.' }}</definition-item>


            <h2 *ngIf="fosterChild?.professionalOpinion">{{
          'NEW_FOSTER_PARENT.CENTER.GUARDIANSHIP_AUTHORITY' | translate
        }}
            </h2>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.guardianshipName"
                key="{{ 'NEW_FOSTER_PARENT.FORM.GUARDIANSHIP_NAME' | translate }}">
                {{ fosterChild.professionalOpinion.guardianshipName }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.guardianshipPhone"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PHONE' | translate }}">
                {{ fosterChild.professionalOpinion.guardianshipPhone }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.guardianshipEmail"
                key="{{ 'NEW_FOSTER_PARENT.FORM.EMAIL_ADDRESS' | translate }}">
                {{ fosterChild.professionalOpinion.guardianshipEmail }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.guardianshipAddress"
                key="{{ 'NEW_FOSTER_PARENT.FORM.ADDRESS' | translate }}">
                {{ fosterChild.professionalOpinion.guardianshipAddress }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.questionnaireNumber"
                key="{{ 'NEW_FOSTER_PARENT.FORM.QUESTIONNAIRE_NUMBER' | translate }}">
                {{ fosterChild.professionalOpinion.questionnaireNumber }}</definition-item>

            <definition-item *ngIf="fosterChild?.professionalOpinion?.questionnaireDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.QUESTIONNAIRE_DATE' | translate }}">
                {{ fosterChild.professionalOpinion.questionnaireDate | date: 'dd.MM.yyyy.' }}</definition-item>


        </definition-list>

    </mat-card>
</container>