/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { documentSigningNotification } from 'src/models/documentsigningnotification';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  public messages: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public newDocument: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public newCase: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public subjectStatusChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public caseStatusChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public newSubmission: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public documentSigned: BehaviorSubject<documentSigningNotification> = new BehaviorSubject<documentSigningNotification>(null);
  public acceptedSubmission: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public supervisionChangeCase: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private centerId = new BehaviorSubject<number>(0);
  castCenterId = this.centerId.asObservable();

  sendCenterId(newCenterId: any) {
    this.centerId.next(newCenterId);
  }

}

