<mat-dialog-content>
  <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
    [mat-dialog-close]="true"><span class="material-icons">close</span>
  </button>
  <div class="container">
    <form flex [formGroup]="recordForm" (ngSubmit)="addNewRecords()">
      <h2 *ngIf="data.record.caseId === null">{{ 'NEW_SUBJECT_RECORD.TITLE' | translate }}</h2>
      <h2 *ngIf="data.record.caseId !== null">{{ 'NEW_SUBJECT_RECORD.EDIT_RECORD' | translate }}</h2>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_RECORD.PROBLEM_ID' | translate }}</mat-label>
          <input matInput type="text" (focusout)="checkIdInRange()" formControlName="problemId"
            [matAutocomplete]="autoProblemIds" required />
          <mat-autocomplete #autoProblemIds="matAutocomplete" class="custom-autocomplete"
            [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredProblemIdsOptions | async" [value]="option">
              {{ option.id }} ({{option.type}}<ng-container *ngIf="option.caseNumber">,
                {{option.caseNumber}}</ng-container>)
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="recordForm.get('problemId').hasError('pattern')">
            {{ 'NEW_SUBJECT_RECORD.PROBLEM_ID_PATTERN_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="recordForm.get('problemId').hasError('errorProblemNumber')">
            {{ 'NEW_SUBJECT_RECORD.PROBLEM_NOT_EXISTS' | translate }}
          </mat-error>
          <mat-error *ngIf="recordForm.get('problemId').hasError('required')">
            {{ 'NEW_SUBJECT_RECORD.PROBLEM_ID_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_RECORD.CHOOSE_TYPE' | translate }}</mat-label>
          <mat-select formControlName="codebookType"
            (selectionChange)="onChangeCodebookType($event)" required>
            <mat-option *ngFor="let ct of codebookTypes" [value]="ct.id">
              {{ ct.title }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="recordForm.get('codebookType').hasError('required')">
            {{ 'NEW_SUBJECT_RECORD.CHOOSE_TYPE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_RECORD.RECORD_TYPE' | translate }}</mat-label>
          <mat-select formControlName="recordType" required
            (selectionChange)="recordTypeChange()">
            <mat-option *ngFor="let recordType of recordTypeOptions" [value]="recordType.id">
              {{ recordType.title }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="recordForm.get('recordType').hasError('required')">
            {{ 'NEW_SUBJECT_RECORD.RECORD_TYPE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder *ngIf="format !== null && (format === 4 || format === 5 || format === 6)">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_RECORD.AMMOUNT' | translate }}</mat-label>
          <input matInput type="text" formControlName="price" (change)="checkPrice()" required autocomplete="off" />
          <span matSuffix>{{ 'NEW_SUBJECT_RECORD.RSD' | translate }}</span>
          <mat-error *ngIf="recordForm.get('price').hasError('required')">
            {{ 'NEW_SUBJECT_RECORD.AMMOUNT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder *ngIf="format !== null && (format === 3 || format === 7)">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_RECORD.VENDOR' | translate }}</mat-label>
          <mat-select formControlName="serviceProvider" required>
            <mat-option *ngFor="let vendor of serviceProviders" [value]="vendor.id">
              {{ vendor.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="recordForm.get('serviceProvider').hasError('required')">
            {{ 'NEW_SUBJECT_RECORD.VENDOR_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT_RECORD.RECORD_START' | translate }}</mat-label>
          <input matInput [matDatepicker]="pickerStartDate" formControlName="fromDate" (dateChange)="checkToDate()"
            autocomplete="off" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerStartDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline"
          *ngIf="format !== null && (format === 2 || format === 3 || format === 4 || format === 5)">
          <mat-label>{{ 'NEW_SUBJECT_RECORD.RECORD_END' | translate }}</mat-label>
          <input matInput [matDatepicker]="pickerEndDate" formControlName="toDate" (dateChange)="checkToDate()"
            autocomplete="off" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerEndDate></mat-datepicker>
          <mat-error *ngIf="recordForm.get('toDate').hasError('errorToDate')">
            {{ 'NEW_SUBJECT_RECORD.DATE_ERROR' | translate }}</mat-error>
          <mat-error *ngIf="recordForm.get('toDate').hasError('notValid')">
            {{ 'NEW_SUBJECT_RECORD.DATE_ERROR_EMPTY' | translate }}</mat-error>
        </mat-form-field>
      </field-holder>

      <form-footer>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
          class="form-footer__button form-footer__button--secondary">
          {{ 'NEW_SUBJECT_RECORD.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
          [disabled]="!recordForm.valid" #submitButton>
          <span *ngIf="data.record.caseId === null">
            {{ 'NEW_SUBJECT_RECORD.SUBMIT' | translate }}
          </span>
          <span *ngIf="data.record.caseId !== null">
            {{ 'NEW_SUBJECT_RECORD.EDIT_RECORD' | translate }}
          </span>
        </button>
      </form-footer>
    </form>

    <br />
  </div>
</mat-dialog-content>