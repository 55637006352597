/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'document-list-item',
    templateUrl: './document-list-item.component.html',
    styleUrls: ['./document-list-item.component.scss']
})
export class DocumentListItemComponent implements OnInit {
    @Input() id: string;
    @Input() title: string;
    @Input() author: string;
    @Input() authorId: string;
    @Input() caseName: string;
    @Input() caseId: any;
    @Input() createdBy: string;
    @Input() modifiedAt: string;
    @Input() dateOfExpedition: string;
    @Input() editEnabled;
    @Input() referenceNumber;

    @Input() caseStatus: any;
    @Input() assignedUserId: string;
    @Input() teamMembers: any;


    @Output() handleClick = new EventEmitter();
    @Output() handleDelete = new EventEmitter();
    @Output() handleSend = new EventEmitter();
    @Output() handleDownload = new EventEmitter();
    @Output() handlePrint = new EventEmitter();
    @Output() handleViewInNewTab = new EventEmitter();
    
    loaded = false;
    isDisabled = false;
    isDisabledWithoutCaseId = false;

    isDisabledWithoutReferenceNumber = false;
    sendDocumentTooltipText = '';
    deleteDocumentTooltipText = '';

    constructor(
        private translate: TranslateService) {

    }

    ngOnInit(): void {
        if(this.caseId === ''){
            this.isDisabledWithoutCaseId = true;
        }
    }

    ngOnChanges(changes: any) {
        if (changes.modifiedAt !== null && changes.modifiedAt !== undefined && this.loaded === false) {
            this.modifiedAt = this.modifiedAt.replace(' ', '. ');
            this.loaded = true;
        }
        if (changes.dateOfExpedition !== null && changes.dateOfExpedition !== undefined) {
            this.dateOfExpedition = this.dateOfExpedition.replace(' ', '. ');
        }
    }


    checkIfSendIsDisabled() {
        // Olja Andjelovski olja.andjelovski@iten
        /*
        isDisabledWithoutCaseId - Ukoliko je bez predmeta treba da se disable-uje dugme.
        isDisabled - Ukoliko je iz razloga navedenih u metodi ngOnInit disableovan
    
        U zavisnosti od uslova bice prikazano odgovarajuce obavestenje
        */

        // Nema dozvolu
//        if (this.isDisabled) {
//            this.translate.get('SNACKBAR.DOCUMENT_SEND_TOOLTIP_WITHOUT_PERMISION').subscribe((resp: string) => {
//                this.sendDocumentTooltipText = resp;
//            });
//        }
        // Ne nalazi se u predmetu
        if (this.isDisabledWithoutCaseId) {
            this.translate.get('SNACKBAR.DOCUMENT_SEND_TOOLTIP_WITHOUT_CASE').subscribe((resp: string) => {
                this.sendDocumentTooltipText = resp;
            });
            
           return true;
        }

//        if (this.isDisabledWithoutCaseId === false && this.isDisabled === false) {
//            return false;
//        }

        return false;



    }
    handleItemClick(): void {
        // Open the document (handled by parent)
        this.handleClick.next();
    }
    handleDownloadClick(): void {
        // Open the document (handled by parent)
        this.handleDownload.next();
    }

/*     deleteCallback(): void {
        // Delete document (handled by parent)
        this.handleDelete.next();
    } */

    sendItemClick() {
        this.handleSend.next();
    }

    handlePrintItem() {
        this.handlePrint.next();
    }

    handleViewItemInNewTabClick() {
        this.handleViewInNewTab.next();
    }

}
