<div class="modal__wrapper">
  <main class="modal__main">
    <div style="display: flex; align-items: center; justify-content: center">
      <pdf-viewer *ngIf="isPdf"
        [src]="docUrl"
        [render-text]="true"
        style="display: block; height: 86vh"
      ></pdf-viewer>
    </div>
    <ngx-doc-viewer *ngIf="!isPdf"
       [url]="docUrl"
       viewer="mammoth"
      style="display: block"
    ></ngx-doc-viewer>
  </main>
  <aside class="modal__aside">
    <h4 class="modal__aside-title">{{ data.name }}</h4>
    <ul class="modal__aside-list">
      <li class="modal__aside-list-item">
        {{ 'PREVIEW_DOCUMENT.MODIFIED_AT' | translate }}
         {{ time }}
      </li>
    </ul>
    <footer class="modal__aside-footer">
      <div *ngIf="requireSignature" class="sign-document">
        <h5 class="sign-document__title">{{ 'PREVIEW_DOCUMENT.SIGN_DOCUMENT_TITLE' | translate }}</h5>
        <form [formGroup]="signDocumentForm">
          <field-holder>
            <mat-form-field appearance="outline">
              <mat-label>Napomena</mat-label>
              <textarea matInput formControlName="note"></textarea>
            </mat-form-field>
          </field-holder>
        </form>
        <footer class="sign-document__footer">
          <button
            (click)="handleSignature('reject')"
            mat-flat-button
            color="warn"
            class="sign-document__button"
          >
            {{ 'PREVIEW_DOCUMENT.REJECT_DOCUMENT' | translate }}
          </button>
          <button
            (click)="handleSignature('accept')"
            mat-flat-button
            color="primary"
            class="sign-document__button"
          >
            {{ 'PREVIEW_DOCUMENT.SIGN_DOCUMENT' | translate }}
          </button>
        </footer>
      </div>

      <button
        *ngIf="!requireSignature"
        mat-flat-button
        color="primary"
        class="modal-footer__button modal-footer__button--primary"
        (click)="closeDialog()"
      >
        {{ 'PREVIEW_DOCUMENT.CLOSE' | translate }}
      </button>
      <button
        *ngIf="requireSignature"
        mat-stroked-button
        color="primary"
        class="modal-footer__button modal-footer__button--primary"
        (click)="closeDialog()"
      >
        {{ 'PREVIEW_DOCUMENT.CLOSE' | translate }}
      </button>
    </footer>
  </aside>
</div>
