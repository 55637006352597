<div
  [ngClass]="{
    'collapsible-case__wrapper': true,
    'collapsible-case__wrapper--expanded': groupExpanded
  }"
>
  <header class="collapsible-case__header" (click)="toggle()">
    <span
      [ngClass]="{
        'collapsible-case__kind': true,
        'collapsible-case__kind--red': kind === 'C',
        'collapsible-case__kind--yellow': kind === 'Ž',
        'collapsible-case__kind--white': kind === 'O',
        'collapsible-case__kind--blue': kind === 'P',
        'collapsible-case__kind--purple': kind === 'LJ',
        'collapsible-case__kind--dashedred': kind === 'IC',
        'collapsible-case__kind--dashedblue': kind === 'IP'
      }"
    ></span>
    <div class="collapsible-case__header-text">
      <h4 class="collapsible-case__number" *ngIf="number">
        {{ number }}
      </h4>
      <h4 class="collapsible-case__title" *ngIf="title">
        {{ title }}
      </h4>
      <time class="collapsible-case__date" *ngIf="!teamRequest">
        <mat-icon class="collapsible-case__meta-icon">calendar_today</mat-icon>{{ date }}
      </time>
      <span *ngIf="teamRequest"><mat-icon class="collapsible-case__meta-icon">people_alt</mat-icon>{{ 'COLLAPSIBLE_CASE.TEAM_REQUEST' | translate }}</span>
    </div>
    <mat-icon
      [ngClass]="{
        'collapsible-case__chevron-icon': true,
        'collapsible-case__chevron-icon--expanded': groupExpanded
      }"
      >chevron_right</mat-icon
    >
  </header>
  <ng-content></ng-content>
</div>
