/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'collapsible-group',
  templateUrl: './collapsible-group.component.html',
  styleUrls: ['./collapsible-group.component.scss']
})
export class CollapsibleGroupComponent implements OnInit {
  groupTitle: string;
  @Input() groupExpanded: boolean = true;
  @Input() title: string;
  data: any

  constructor() { }

  ngOnInit(): void {}

  toggle() {
    this.groupExpanded = !this.groupExpanded
  }

}
