/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { checkRelationshipAgeDifference } from 'src/app/utils/checkRelationshipAgeDifference';
import { CodebookService } from 'src/services/codebook.service';
import { ToastrImplService } from 'src/services/toastr.service';

enum AutocompleteFilterEnum {
   CITIZENSHIP = 'citizenship',
   NATIONALITY = 'nationality',
}

@Component({
   selector: 'new-foster-child-parent-information-step',
   templateUrl: './new-foster-child-parent-information-step.component.html',
   styleUrls: ['./new-foster-child-parent-information-step.component.scss'],
})
export class NewFosterChildParentInformationStepComponent implements OnInit {
   @Output() parentInformationStepEmitter = new EventEmitter();
   @Input() parentInformationStep: FormGroup;
   @Input() subjectData: any;
   motherInformationStep: FormGroup;
   fatherInformationStep: FormGroup;
   currentDate = new Date();
   healthStatusOptions: any = [];
   maritalStatusOptions: any = [];
   nationalityOptions: any = [];
   genderOptions: any = [];
   citizenshipOptions: any = [];
   educationOptions: any = [];
   employmentOptions: any = [];
   occupationOptions: any = [];
   addictionOptions: any = [];
   residentalStatusOptions: any = [];
   filteredCitizenshipOptionsMother: Observable<any[]>;
   filteredCitizenshipOptionsFather: Observable<any[]>;

   filteredNationalityOptionsFather: Observable<any[]>;
   filteredNationalityOptionsMother: Observable<any[]>;
   shouldShowAddictionMetaMother = false;
   shouldShowAddictionMetaFather = false;
   showFatherAddictionId1 = false;
   showFatherAddictionId2 = false;
   showFatherAddictionId3 = false;

   showMotherAddictionId1 = false;
   showMotherAddictionId2 = false;
   showMotherAddictionId3 = false;
   constructor(
      private dialog: MatDialog,
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private datePipe: DatePipe,
      private toastr: ToastrImplService
   ) {
      this.getMaritalStatus();
      this.getNationality();
      this.getGender();
      this.getCitizenship();
      this.getOccupation();
      this.getEducation();
      this.getEmployment();
      this.getAddiction();
   }

   ngOnInit(): void {
      this.fatherInformationStep = this.formBuilder.group({
         fatherId: [null, []],
         firstName: [null, []],
         lastName: [null, []],
         dateOfBirth: [null],
         placeOfBirth: ['', []],
         foreigner: [false],
         jmbg: [null, []],
         citizenship: [null, []],
         permanentResidence: [null, []],
         nationality: [null, []],
         education: [null, []],
         occupation: [null, []],
         employment: [null, []],
         residentalStatus: [null, []],
         healthStatus: [null, []],
         addictions: this.formBuilder.array([]),
         addictionCodebook: [null, []],
         addictionId1: [null, []],
         addictionId2: [null, []],
         addictionId3: [null, []],
         addictionCodebookMeta: [null],
      });

      this.motherInformationStep = this.formBuilder.group({
         motherId: [null, []],
         firstName: [null, []],
         lastName: [null, []],
         dateOfBirth: [null],
         placeOfBirth: [null, []],
         foreigner: [false],
         jmbg: [null, []],
         citizenship: [null, []],
         permanentResidence: [null, []],
         nationality: [null, []],
         education: [null, []],
         occupation: [null, []],
         employment: [null, []],
         residentalStatus: [null, []],
         healthStatus: [null, []],
         addictions: this.formBuilder.array([]),
         addictionCodebook: [null, []],
         addictionId1: [null, []],
         addictionId2: [null, []],
         addictionId3: [null, []],
         addictionCodebookMeta: [null],
      });
      this.parentInformationStep = this.formBuilder.group({
         fatherInformation: this.fatherInformationStep,
         motherInformation: this.motherInformationStep,
      });
      this.dynamicFormUpdate();
   }

   /**
    * Send step information to parent component
    */
   updateParentInformationStep() {
      if (this.motherInformationStep.value.citizenship === '') {
         this.motherInformationStep.controls.citizenship.setValue(null);
      }
      if (this.motherInformationStep.value.nationality === '') {
         this.motherInformationStep.controls.nationality.setValue(null);
      }
      if (this.fatherInformationStep.value.citizenship === '') {
         this.fatherInformationStep.controls.citizenship.setValue(null);
      }
      if (this.fatherInformationStep.value.nationality === '') {
         this.fatherInformationStep.controls.nationality.setValue(null);
      }
      for (let i = 0; i < this.fatherInformationStepAddictionItem().controls.length; i++) {
         if (this.fatherInformationStepAddictionItem().controls[i].get('key').value === 1) {
            this.fatherInformationStepAddictionItem().controls[i].get('value').setValue(this.fatherInformationStep.value.addictionId1);
         } else if (this.fatherInformationStepAddictionItem().controls[i].get('key').value === 2) {
            this.fatherInformationStepAddictionItem().controls[i].get('value').setValue(this.fatherInformationStep.value.addictionId2);
         } else {
            this.fatherInformationStepAddictionItem().controls[i].get('value').setValue(this.fatherInformationStep.value.addictionId3);
         }
      }

      for (let i = 0; i < this.motherInformationStepAddictionItem().controls.length; i++) {
         if (this.motherInformationStepAddictionItem().controls[i].get('key').value === 1) {
            this.motherInformationStepAddictionItem().controls[i].get('value').setValue(this.motherInformationStep.value.addictionId1);
         } else if (this.motherInformationStepAddictionItem().controls[i].get('key').value === 2) {
            this.motherInformationStepAddictionItem().controls[i].get('value').setValue(this.motherInformationStep.value.addictionId2);
         } else {
            this.motherInformationStepAddictionItem().controls[i].get('value').setValue(this.motherInformationStep.value.addictionId3);
         }
      }

      this.parentInformationStepEmitter.emit(this.parentInformationStep);
   }

   /**
    * _filter
    * @param value input value
    * @param what which filter should be applied (check AutocompleteFilterEnum)
    */
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.CITIZENSHIP:
            return this.citizenshipOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.NATIONALITY:
            return this.nationalityOptions.filter((option: any) => option.title.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   getHealthStatus() {
      this.codebookService.getHealtStatusCodebook().subscribe(res => {
         this.healthStatusOptions = res;
      });
   }

   getMaritalStatus() {
      this.codebookService.getMaritalStatusCodebook().subscribe(res => {
         this.maritalStatusOptions = res;
      });
   }

   getNationality() {
      this.codebookService.getNationalityCodebook().subscribe(res => {
         this.nationalityOptions = res;
         this.filteredNationalityOptionsFather = this.fatherInformationStep.controls.nationality.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalityOptions.slice()))
         );
         this.filteredNationalityOptionsMother = this.motherInformationStep.controls.nationality.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalityOptions.slice()))
         );
      });
   }

   getGender() {
      this.codebookService.getGenderCodebook().subscribe(res => {
         this.genderOptions = res;
      });
   }

   getCitizenship() {
      this.codebookService.getCitizenshipCodebook().subscribe(res => {
         this.citizenshipOptions = res;
         this.filteredCitizenshipOptionsFather = this.fatherInformationStep.controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
         this.filteredCitizenshipOptionsMother = this.motherInformationStep.controls.citizenship.valueChanges.pipe(
            startWith(''),
            map(value => (value === null ? null : typeof value === 'string' ? value : value?.title)),
            map(title => (title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice()))
         );
      });
   }

   getEmployment() {
      this.codebookService.getEmploymentCodebook().subscribe(res => {
         if (res !== null) {
            res.forEach(element => {
               if ([1, 3, 4, 8, 9].includes(element.id)) {
                  this.employmentOptions.push(element);
               }
            });
         }
      });
   }

   getEducation() {
      this.codebookService.getEducationCodebook().subscribe(res => {
         this.educationOptions = res;
      });
   }

   getOccupation() {
      this.codebookService.getOccupationCodebook().subscribe(res => {
         this.occupationOptions = res;
      });
   }

   getResidentalStatus() {
      this.codebookService.getResidentalStatusCodebook().subscribe(res => {
         this.residentalStatusOptions = res;
      });
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   importFatherData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            if (!checkRelationshipAgeDifference(result.data, this.subjectData.value, 4, this.toastr)) {
               return;
            }
            const address =
               String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
               String(result.data.permanentResidence.number ? ' ' + result.data.permanentResidence.number : '') +
               String(result.data.permanentResidence.subnumber ? ' ' + result.data.permanentResidence.subnumber : '') +
               String(result.data.permanentResidence.town ? ', ' + result.data.permanentResidence.town : '') +
               String(result.data.permanentResidence.street && result.data.residence.street ? '/' : '') +
               String(result.data.residence.street ? result.data.residence.street : '') +
               String(result.data.residence.number ? ' ' + result.data.residence.number : '') +
               String(result.data.residence.subnumber ? ' ' + result.data.residence.subnumber : '') +
               String(result.data.residence.town ? ', ' + result.data.residence.town : '');

            this.fatherInformationStep.patchValue({
               fatherId: result.data.subjectIdentity.subjectId,
               firstName: result.data.firstName,
               lastName: result.data.lastName,
               dateOfBirth: result.data.dateOfBirth === null ? null : new Date(result.data.dateOfBirth.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$3-$2-$1')),
               placeOfBirth: result.data.placeOfBirth,
               foreigner: result.data.foreigner,
               jmbg: result.data.jmbg,
               citizenship: result.data.citizenship,
               permanentResidence: address,
               nationality: result.data.nationality,
               education: result.data.education,
               employment: result.data.employment,
               occupation: result.data.occupation,
            });
            this.fatherInformationStep.controls.jmbg.markAsTouched();
            this.fatherInformationStep.controls.dateOfBirth.markAsTouched();
         }
      });
   }

   importMotherData() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         width: '1200px',
         panelClass: 'overlay-panel',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const address =
               String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
               String(result.data.permanentResidence.number ? ' ' + result.data.permanentResidence.number : '') +
               String(result.data.permanentResidence.subnumber ? ' ' + result.data.permanentResidence.subnumber : '') +
               String(result.data.permanentResidence.town ? ', ' + result.data.permanentResidence.town : '') +
               String(result.data.permanentResidence.street && result.data.residence.street ? '/' : '') +
               String(result.data.residence.street ? result.data.residence.street : '') +
               String(result.data.residence.number ? ' ' + result.data.residence.number : '') +
               String(result.data.residence.subnumber ? ' ' + result.data.residence.subnumber : '') +
               String(result.data.residence.town ? ', ' + result.data.residence.town : '');

            // 4 - srodstvo roditelj
            if (!checkRelationshipAgeDifference(result.data, this.subjectData.value, 4, this.toastr)) {
               return;
            }
            this.motherInformationStep.patchValue({
               motherId: result.data.subjectIdentity.subjectId,
               firstName: result.data.firstName,
               lastName: result.data.lastName,
               dateOfBirth: result.data.dateOfBirth === null ? null : new Date(result.data.dateOfBirth.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$3-$2-$1')),
               placeOfBirth: result.data.placeOfBirth,
               foreigner: result.data.foreigner,
               jmbg: result.data.jmbg,
               citizenship: result.data.citizenship,
               permanentResidence: address,
               nationality: result.data.nationality,
               education: result.data.education,
               employment: result.data.employment,
               occupation: result.data.occupation,
            });
            this.motherInformationStep.controls.jmbg.markAsTouched();
            this.motherInformationStep.controls.dateOfBirth.markAsTouched();
         }
      });
   }

   dynamicFormUpdate() {
      this.fatherInformationStep.get('addictionCodebook').valueChanges.subscribe(option => {
         this.fatherInformationStepAddictionItem().clear();

         this.disableFatherAdditionalInformations();
         if (option) {
            for (var o of option) {
               this.fatherInformationStepAddictionItem().push(this.createItem(o.id, ''));
            }
         }

         for (let i = 0; i < this.fatherInformationStepAddictionItem().controls.length; i++) {
            if (this.fatherInformationStepAddictionItem().controls[i].get('key').value === 1) {
               this.showFatherAddictionId1 = true;
               this.fatherInformationStep.controls.addictionId1.enable();
            } else if (this.fatherInformationStepAddictionItem().controls[i].get('key').value === 2) {
               this.showFatherAddictionId2 = true;
               this.fatherInformationStep.controls.addictionId2.enable();
            } else {
               this.showFatherAddictionId3 = true;
               this.fatherInformationStep.controls.addictionId3.enable();
            }
         }
      });

      this.motherInformationStep.get('addictionCodebook').valueChanges.subscribe(option => {
         this.motherInformationStepAddictionItem().clear();
         this.disableMotherAdditionalInformations();
         if (option) {
            for (var o of option) {
               this.motherInformationStepAddictionItem().push(this.createItem(o.id, ''));
            }
         }
         for (let i = 0; i < this.motherInformationStepAddictionItem().controls.length; i++) {
            if (this.motherInformationStepAddictionItem().controls[i].get('key').value === 1) {
               this.showMotherAddictionId1 = true;
               this.motherInformationStep.controls.addictionId1.enable();
            } else if (this.motherInformationStepAddictionItem().controls[i].get('key').value === 2) {
               this.showMotherAddictionId2 = true;
               this.motherInformationStep.controls.addictionId2.enable();
            } else {
               this.showMotherAddictionId3 = true;
               this.motherInformationStep.controls.addictionId3.enable();
            }
         }
      });
   }

   disableFatherAdditionalInformations() {
      this.showFatherAddictionId1 = false;
      this.showFatherAddictionId2 = false;
      this.showFatherAddictionId3 = false;
      this.fatherInformationStep.controls.addictionId1.disable();
      this.fatherInformationStep.controls.addictionId2.disable();
      this.fatherInformationStep.controls.addictionId3.disable();
   }

   disableMotherAdditionalInformations() {
      this.showMotherAddictionId1 = false;
      this.showMotherAddictionId2 = false;
      this.showMotherAddictionId3 = false;
      this.motherInformationStep.controls.addictionId1.disable();
      this.motherInformationStep.controls.addictionId2.disable();
      this.motherInformationStep.controls.addictionId3.disable();
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.title : '';
   }

   updateJMBGOnDateOfBirthChangeMother(event: any) {
      if (this.motherInformationStep.value.foreigner === true) {
         return;
      }
      if (this.motherInformationStep.value.dateOfBirth !== null) {
         const birthDate = new Date(this.motherInformationStep.value.dateOfBirth).getTime();
         const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
         const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
         const jmbg = this.motherInformationStep.value.jmbg.substring(0, 7);

         if (this.motherInformationStep.value.jmbg.length === 13) {
            if (newBirthDate !== jmbg) {
               this.motherInformationStep.get('dateOfBirth').setErrors({ incorrect: true });
            } else {
               this.motherInformationStep.get('dateOfBirth').setErrors(null);
            }
         }
      }
   }
   updateJMBGOnDateOfBirthChangeFather(event: any) {
      if (this.fatherInformationStep.value.foreigner === true) {
         return;
      }
      if (this.fatherInformationStep.value.dateOfBirth !== null) {
         const birthDate = new Date(this.fatherInformationStep.value.dateOfBirth).getTime();
         const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
         const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
         const jmbg = this.fatherInformationStep.value.jmbg.substring(0, 7);

         if (this.fatherInformationStep.value.jmbg.length === 13) {
            if (newBirthDate !== jmbg) {
               this.fatherInformationStep.get('dateOfBirth').setErrors({ incorrect: true });
            } else {
               this.fatherInformationStep.get('dateOfBirth').setErrors(null);
            }
         }
      }
   }

   fatherInformationStepAddictionItem(): FormArray {
      return this.fatherInformationStep.get('addictions') as FormArray;
   }

   motherInformationStepAddictionItem(): FormArray {
      return this.motherInformationStep.get('addictions') as FormArray;
   }

   getAddiction() {
      this.codebookService.getAddictionCodebook().subscribe(res => {
         this.addictionOptions = res;
      });
   }

   onAddictionChange(from: any) {
      if (from === 'mother') {
         this.shouldShowAddictionMetaMother = true;
      } else {
         this.shouldShowAddictionMetaFather = true;
      }
   }

   createItem(id: any, name: any): FormGroup {
      return this.formBuilder.group({
         key: id,
         name,
         value: '',
      });
   }

   checkNationality(type: string) {
      let step;
      if (type === 'mother') {
         step = this.motherInformationStep;
      } else {
         step = this.fatherInformationStep;
      }
      if (step.value.nationality !== undefined && step.value.nationality !== null && step.value.nationality !== '') {
         const choosedValue = step.value.nationality;

         var result = this.nationalityOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            step.controls.nationality.setErrors({ notValid: true });
         } else {
            step.controls.nationality.setValue(result);
            step.controls.nationality.setErrors(null);
         }
      }
   }

   checkCitizenship(type: string) {
      let step;
      if (type === 'mother') {
         step = this.motherInformationStep;
      } else {
         step = this.fatherInformationStep;
      }
      if (step.value.citizenship !== undefined && step.value.citizenship !== null && step.value.citizenship !== '') {
         const choosedValue = step.value.citizenship;

         var result = this.citizenshipOptions.find(value => {
            if (choosedValue.title === undefined) {
               if (value.title === choosedValue) {
                  return value;
               }
            } else {
               if (value.title === choosedValue.title) {
                  return value;
               }
            }
         });

         if (result === undefined) {
            step.controls.citizenship.setErrors({ notValid: true });
         } else {
            step.controls.citizenship.setValue(result);
            step.controls.citizenship.setErrors(null);
         }
      }
   }
}
