<form [formGroup]="personalInformationStep" class="form">

  <div class="button-add-holder">
    <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
      <mat-icon>person_add</mat-icon>
      {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
    </button>
  </div>
  <br>
  <field-holder kind="4-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.FIRST_NAME' | translate }}</mat-label>
      <input matInput type="text" formControlName="firstName" required autocomplete="off" />
      <mat-error *ngIf="personalInformationStep.get('firstName').hasError('required')">
        {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.LAST_NAME' | translate }}</mat-label>
      <input matInput type="text" formControlName="lastName" required autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.PARENT_FIRST_NAME' | translate
        }}</mat-label>
      <input matInput type="text" formControlName="parentFirstName" required autocomplete="off" />
      <mat-error *ngIf="
          personalInformationStep.get('parentFirstName').hasError('required')
        ">
        {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.PARENT_LAST_NAME' | translate
        }}</mat-label>
      <input matInput type="text" formControlName="parentLastName" autocomplete="off" />

    </mat-form-field>
  </field-holder>
  <field-holder kind="4-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.DATE_OF_BIRTH' | translate }}</mat-label>
      <input matInput [matDatepicker]="dateOfBirth" (dateChange)="updateJMBGOnDateOfBirthChange()"
        formControlName="dateOfBirth" autocomplete="off" [max]="maxDate" />
      <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
      <mat-datepicker #dateOfBirth></mat-datepicker>

    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.JMBG' | translate }}</mat-label>
      <input matInput type="text" formControlName="jmbg" (input)="updateJMBGOnDateOfBirthChange()" maxlength="13"
        required autocomplete="off" />
      <mat-error
        *ngIf="!personalInformationStep.get('foreigner') && personalInformationStep.get('jmbg').hasError('required')">
        {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error
        *ngIf="!personalInformationStep.get('foreigner') && personalInformationStep.get('jmbg').hasError('pattern')">
        {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.PLACE_OF_BIRTH' | translate }}</mat-label>
      <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.MUNICIPALITY' | translate }}</mat-label>
      <input matInput type="text" formControlName="municipality" autocomplete="off" />

    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols-2-1-1">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.PLACE_OF_ORIGIN' | translate }}</mat-label>
      <input matInput type="text" formControlName="placeOfOrigin" autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NEW_PROTEGE.FORM.CITIZENSHIP' | translate }}
      </mat-label>
      <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenship"
        (focusout)="checkCitizenship()" />
      <mat-autocomplete #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredCitizenshipOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="personalInformationStep.get('citizenship').hasError('notValid')">
        {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.NATIONALITY' | translate }}</mat-label>
      <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationality"
        (focusout)="checkNationality()" />
      <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredNationalityOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="personalInformationStep.get('nationality').hasError('notValid')">
        {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="isAdult">
      <mat-label>{{ 'NEW_PROTEGE.FORM.OCCUPATION' | translate }}</mat-label>
      <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupation"
        (focusout)="checkOccupation()" />
      <mat-autocomplete #autoOccupation="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredOccupationOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="personalInformationStep.get('occupation').hasError('required')">
        {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('occupation').hasError('notValid')">
        {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label> {{'NEW_PROTEGE.FORM.ACCOMODATION_INFO' | translate}} </mat-label>
      <mat-select formControlName="accomodationType" required>
        <mat-option *ngFor="let accommodation of accommodations" [value]="accommodation">
          {{ accommodation.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="personalInformationStep.get('accomodationType').hasError('required')">
        {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <section>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder kind="3-cols" *ngIf="isAdult">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_PROTEGE.FORM.DECISION_NUMBER_DEPRIVATION' | translate }}</mat-label>
        <input matInput type="text" formControlName="decisionNumberDeprivation" autocomplete="off" />
        <mat-error *ngIf="
            personalInformationStep
              .get('decisionNumberDeprivation')
              .hasError('required')
          ">
          {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_PROTEGE.FORM.DECISION_DATE_DEPRIVATION' | translate }}</mat-label>
        <input matInput [matDatepicker]="decisionDateDeprivation" [max]="currentDate"
          formControlName="decisionDateDeprivation" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="decisionDateDeprivation"></mat-datepicker-toggle>
        <mat-datepicker #decisionDateDeprivation></mat-datepicker>
        <mat-error *ngIf="
            personalInformationStep
              .get('decisionDateDeprivation')
              .hasError('required')
          ">
          {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_PROTEGE.FORM.DECISION_MAKER_NAME_DEPRIVATION' | translate }}</mat-label>
        <input matInput type="text" formControlName="decisionMakerNameDeprivation" autocomplete="off" />
        <mat-error *ngIf="
            personalInformationStep
              .get('decisionMakerNameDeprivation')
              .hasError('required')
          ">
          {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline" *ngIf="isAdult">
        <mat-label>{{ 'NEW_PROTEGE.FORM.COURT_DECISION_CONTENT' | translate }}</mat-label>
        <input matInput type="text" formControlName="courtDecisionContent" autocomplete="off" />
        <mat-error *ngIf="
            personalInformationStep
              .get('courtDecisionContent')
              .hasError('required')
          ">
          {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_PROTEGE.FORM.DECISION_NUMBER_GUARDIANSHIP' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="decisionNumberGuardianship" autocomplete="off" />
        <mat-error *ngIf="personalInformationStep.get('decisionNumberGuardianship').hasError('required')">
          {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_PROTEGE.FORM.DECISION_DATE_GUARDIANSHIP' | translate }}</mat-label>
        <input matInput [matDatepicker]="decisionDateGuardianship" [max]="currentDate"
          formControlName="decisionDateGuardianship" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="decisionDateGuardianship"></mat-datepicker-toggle>
        <mat-datepicker #decisionDateGuardianship></mat-datepicker>
        <mat-error *ngIf="
            personalInformationStep
              .get('decisionDateGuardianship')
              .hasError('required')
          ">
          {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_PROTEGE.FORM.DECISION_MAKER_NAME_GUARDIANSHIP' | translate }}</mat-label>
        <input matInput type="text" formControlName="decisionMakerNameGuardianship" autocomplete="off" />
        <mat-error *ngIf="
            personalInformationStep
              .get('decisionMakerNameGuardianship')
              .hasError('required')
          ">
          {{ 'NEW_PROTEGE.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </section>
  <form-footer>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!personalInformationStep.valid"
      class="form-footer__button" (click)="updatePersonalInformationStep()">
      {{ 'NEW_PROTEGE.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>