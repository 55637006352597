/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CaseService } from 'src/services/case.service';
import { SearchSocialCase } from 'src/models/searchSocialCase.model';
import { DatePipe } from '@angular/common';
import { SocialCase } from 'src/models/socialCase.model';
import { Page } from '../../page';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { environment } from 'src/environments/environment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CaseStatus } from 'src/types';
import { LocalStorageService } from 'src/app/local-storage.service';
import { UserService } from 'src/services/user.service';

@Component({
   selector: 'app-side-subject-cases',
   templateUrl: './side-subject-cases.component.html',
   styleUrls: ['./side-subject-cases.component.scss'],
})
export class SideSubjectCasesComponent implements OnInit {
   @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
   filterForm: FormGroup;
   extendedFilter: FormGroup;
   subjectId: string;
   filter: SearchSocialCase = new SearchSocialCase();
   appliedTextFilters: string[] = [];
   appliedFilters: string[] = [];
   badgeCount: number = 0;
   casesList: any[] = [];
   page: Page<SocialCase> = new Page();
   displayedColumns: string[] = ['case', 'classification', 'startDate', 'title', 'status', 'imported'];
   localStorageCenterId: any;
   from: string;
   to: string;
   maxDate: Date = new Date(2999, 11, 31);
   loggedIn: any;
   areLoaded = false;

   constructor(
      public datepipe: DatePipe,
      private paginationService: CustomPaginationService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private cdr: ChangeDetectorRef,
      private caseService: CaseService,
      private localStorageService: LocalStorageService,
      private translateService: TranslateService,
      private userService: UserService,
      private router: Router
   ) {
      //
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      if (this.loggedIn?.csrId === '1' || this.userService.isUserDrugostepeni()) {
         this.localStorageCenterId = localStorage.getItem('localStorageCenterId');
      } else {
         this.localStorageCenterId = this.loggedIn?.csrId;
      }
      const url = this.router.url;

      if (url.includes('/cases/')) {
         this.subjectId = this.extractSubjectIdFromUrl(url, '/cases/');
      } else {
         this.subjectId = this.extractSubjectIdFromUrl(url, '/subjects/');
      }

      this.translateService.get('SUBJECT_CASES.FILTER.FROM').subscribe((res: string) => {
         this.from = res;
      });

      this.translateService.get('SUBJECT_CASES.FILTER.TO').subscribe((res: string) => {
         this.to = res;
      });
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translateService.get('SUBJECT_CASES.FILTER.FROM').subscribe((res: string) => {
            this.from = res;
         });

         this.translateService.get('SUBJECT_CASES.FILTER.TO').subscribe((res: string) => {
            this.to = res;
         });
      });
   }
   private extractSubjectIdFromUrl(url: string, splitWord: string): string {
      const parts = url.split(splitWord)[1].split('/');
      return parts.length > 0 ? parts[splitWord == '/cases/' ? 2 : 0] : null;
   }
   translateStatus(status: any) {
      if (status === CaseStatus.NOT_ASSIGNED) {
         this.translateService.get('CASES.STATUSES.NOT_ASSIGNED').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.ASSIGNED) {
         this.translateService.get('CASES.STATUSES.ASSIGNED').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.ACCEPTED) {
         this.translateService.get('CASES.STATUSES.ACCEPTED').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.CLOSED) {
         this.translateService.get('CASES.STATUSES.CLOSED').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.FINISHED_PROCESSING) {
         this.translateService.get('CASES.STATUSES.FINISHED_PROCESSING').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.COMPLAIN) {
         this.translateService.get('CASES.STATUSES.COMPLAIN').subscribe((res: string) => {
            status = res;
         });
      } else if (status === CaseStatus.SECOND_DEGREE) {
         this.translateService.get('CASES.STATUSES.SECOND_DEGREE').subscribe((res: string) => {
            status = res;
         });
      }
      return status;
   }

   returnColor(status: any) {
      if (status === 'RED') {
         status = "'background-color'='red'";
      } else if (status === 'WHITE') {
         status = "'background-color'='white'";
      } else if (status === 'YELLOW') {
         status = "'background-color'='yellow'";
      }
      return status;
   }

   ngOnInit(): void {
      this.extendedFilter = this.formBuilder.group({
         filtervalue: ['', []],
      });
      this.filterForm = this.formBuilder.group({
         minDate: ['', []],
         maxDate: ['', []],
      });
      this.resetDateFilterValues();
      this.doFilter();

      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translateService.get('SUBJECT_CASES.FILTER.FROM').subscribe((res: string) => {
            this.from = res;
         });

         this.translateService.get('SUBJECT_CASES.FILTER.TO').subscribe((res: string) => {
            this.to = res;
         });

         this.applyFilter(true);
      });
   }

   onEnterClickAddFilter() {
      this.filter.text.push(this.extendedFilter.get('filtervalue').value);
      this.appliedTextFilters.push(this.extendedFilter.get('filtervalue').value);
      this.extendedFilter.reset();

      this.getFirstPage();
   }

   removeTextFilter(textFilter: any) {
      this.filter.text = this.filter.text.filter(item => item !== textFilter);
      this.appliedTextFilters = this.appliedTextFilters.filter(item => item !== textFilter);
      this.doFilter();
   }

   clickCase(caseRow: any) {
      window.open(environment.getNewCaseTab + caseRow.id + '/' + this.subjectId + '/' + this.localStorageCenterId, '_blank');
   }

   removeFilter(fil: any) {
      this.appliedFilters = this.appliedFilters.filter(item => item !== fil);
      if (fil.indexOf(this.from) !== -1) {
         this.filter.minDate = '';
         this.badgeCount--;
         this.filterForm.patchValue({
            minDate: '',
         });
      }
      if (fil.indexOf(this.to) !== -1) {
         this.filter.maxDate = '';
         this.badgeCount--;
         this.filterForm.patchValue({
            maxDate: '',
         });
      }
      this.doFilter();
   }

   applyFilter(langChangeEvent: boolean) {
      this.resetDateFilterValues();
      this.appliedFilters = [];

      if (this.filterForm.get('minDate').value !== '') {
         this.filter.minDate = this.datepipe.transform(new Date(this.filterForm.get('minDate').value), 'yyyy-MM-dd');
         this.badgeCount++;
         this.appliedFilters.push(this.from + ' ' + this.datepipe.transform(new Date(this.filterForm.get('minDate').value), 'dd.MM.yyyy'));
      }
      if (this.filterForm.get('maxDate').value !== '') {
         this.filter.maxDate = this.datepipe.transform(new Date(this.filterForm.get('maxDate').value), 'yyyy-MM-dd');
         this.badgeCount++;
         this.appliedFilters.push(this.to + ' ' + this.datepipe.transform(new Date(this.filterForm.get('maxDate').value), 'dd.MM.yyyy'));
      }

      this.trigger.closeMenu();
      if (langChangeEvent === false) {
         this.doFilter();
      }
   }

   resetFilter() {
      this.resetDateFilterValues();
      this.resetDateFilterForm();
      this.appliedFilters = [];
      this.badgeCount = 0;
      this.trigger.closeMenu();
      this.doFilter();
   }

   doFilter() {
      this.areLoaded = false;
      this.caseService
         .getFilteredCasesByIdSubject(this.page.pageable, this.subjectId, this.localStorageCenterId, this.filter)
         .subscribe((page: Page<SocialCase>) => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            this.areLoaded = true;
            this.cdr.detectChanges();
         });
   }

   resetDateFilterValues() {
      this.filter.minDate = '';
      this.filter.maxDate = '';
      this.badgeCount = 0;
   }

   resetDateFilterForm() {
      this.filterForm.patchValue({
         maxDate: '',
         minDate: '',
      });
   }

   getNextPage() {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   getPreviousPage() {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }
   getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter();
   }

   getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter();
   }

   getPageInNewSize(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter();
   }
}
