<div class="login__wrapper">
  <div class="login__content">
    <div class="login__media"></div>
    <main class="login__main">
      <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)" class="login__form">
        <h1 class="login__title">{{ 'LOGIN.TITLE' | translate }}</h1>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'LOGIN.USER_NAME' | translate }}</mat-label>
            <input matInput type="text" formControlName="username" required />
            <mat-error *ngIf="loginForm.get('username').hasError('required')">
              {{ 'LOGIN.USER_NAME_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="loginForm.get('username').hasError('pattern')">
              {{ 'LOGIN.LETTER_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder>
          <mat-form-field appearance="outline">
            <input matInput type="password" formControlName="password" required />
            <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
            <mat-error *ngIf="loginForm.get('password').hasError('required')">
              {{ 'LOGIN.PASSWORD_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="loginForm.get('password').hasError('pattern')">
              {{ 'LOGIN.LETTER_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <form-footer>
          <button
            class="login__button"
            mat-flat-button
            color="primary"
            type="submit"
            [disabled]="!loginForm.valid"
          >
            {{ 'LOGIN.SUBMIT' | translate }}
          </button>
        </form-footer>
        <span class="login__forgot-password">
          {{ 'LOGIN.FORGOT_PASSWORD' | translate }} 
        </span>
      </form>
    </main>
  </div>
</div>
