/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';

interface SelectOptions {
   value: any;
   viewValue: string;
}

@Component({
   selector: 'other-institutions',
   templateUrl: './other-institutions.component.html',
   styleUrls: ['./other-institutions.component.scss'],
   providers: [DatePipe],
})
export class OtherInstitutionsComponent implements OnInit {
   socialMapForm: FormGroup;
   item: any;
   trackingListObject: any;
   currentDate = new Date();
   showDate: string;
   center: string;
   dateOfSubmission: string;
   activities: [];
   caseId: any;
   maxDate: Date = new Date(2999, 11, 31);
   
   constructor(
      private subjectService: SubjectsService,
      private formBuilder: FormBuilder,
      private datePipe: DatePipe,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private toastr: ToastrImplService,
      private router: Router
   ) {
      if (JSON.parse(this.localStorageService.get('loggedUser')) !== null) {
         this.center = JSON.parse(this.localStorageService.get('loggedUser')).city;
      }
      this.showDate = this.datePipe.transform(this.currentDate, 'dd.MM.yyyy.');
      this.router.events.subscribe(ev => {
         if (ev instanceof NavigationEnd) {
            if (ev.url.includes('/cases/') && !ev.url.includes('/cases/details/')) {
               const urlParams = ev.url.split('/cases/')[1];
               if (urlParams != undefined) {
                  const caseParams = urlParams.split('/');
                  this.caseId = caseParams[1];
               }
            }
         }
      });
   }

   rightCodeOptions: SelectOptions[] = [
      { value: 'NSP', viewValue: 'Новчана социјална помоћ' },
      { value: 'NEGA', viewValue: 'Додатак за помоћ и негу другог лица' },
      { value: 'UNEGA', viewValue: 'Увећани додатак за помоћ и негу другог лица' },
      { value: 'PNN', viewValue: 'Посебна новчана накнада' },
      { value: 'SME', viewValue: 'Услуге смештаја' },
      { value: 'OSPOS', viewValue: 'Помоћ за оспособљавање за рад' },
      { value: 'JEDNOKRATNA', viewValue: 'Једнократна помоћ' },
      { value: 'LOKALNEU', viewValue: 'Локалне услуге и права' },
      { value: 'STARATELJSTVO', viewValue: 'Старатељство' },
      { value: 'HRANITELJSTVO', viewValue: 'Хранитељство' },
      { value: 'USVAJANJE', viewValue: 'Усвојење' },
      { value: 'RD', viewValue: 'Родитељски додатак' },
      { value: 'DD', viewValue: 'Дечији додатак' },
      { value: 'POR', viewValue: 'Накнада зараде за време породиљског, НД и ПНД' },
      { value: 'POROST', viewValue: 'Остале накнаде по основу рођења и неге детета и посебне неге детета' },
      { value: 'NT-PU-DBRS', viewValue: 'Накнада трошкова боравка у предшк. уст. за децу без родитељског старања' },
      {
         value: 'NT-PU-DSRI',
         viewValue: 'Накнада трошкова боравка у предшк. уст. за децу са сметњама у развоју и децу са инвалидитетом',
      },
      { value: 'NT-PU-DNSP', viewValue: 'Накнада трошкова боравка у предшк. уст. за децу корисника нсп' },
   ];

   ngOnInit(): void {
      this.socialMapForm = this.formBuilder.group({
         selectedTypeOfData: ['Подаци из социјалне карте по праву'],
         subjectJMBG: ['', Validators.compose([Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern('^[0-9]*$')])],
         dateOfSubmission: [this.showDate, [Validators.required]],
         dateFrom: [null],
         dateTo: [null],
         rightCode: [null],
      });
   }

   sendRequest() {
      const loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      if (loggedIn.jmbg === undefined || loggedIn.jmbg === null) {
         this.toastr.info('SIDEBAR_WIDGET.OTHER_INSTITUTIONS.NO_WORKER_JMBG');
      } else {
         const body = {
            caseId: this.caseId,
            subjectJMBG: this.socialMapForm.value.subjectJMBG,
            selectedTypeOfData: 9,
            dateFrom: this.socialMapForm.controls.dateFrom.value !== null ? this.parseDate(this.socialMapForm.controls.dateFrom.value) : null,
            dateTo: this.socialMapForm.controls.dateTo.value !== null ? this.parseDate(this.socialMapForm.controls.dateTo.value) : null,
            rightCode: this.socialMapForm.controls.rightCode.value !== null ? this.socialMapForm.controls.rightCode.value : null,
            rightCodeView:
               this.socialMapForm.controls.rightCode.value !== null
                  ? this.rightCodeOptions.find(option => option.value === this.socialMapForm.controls.rightCode.value).viewValue
                  : null,
         };
         this.subjectService.downloadExternalInfo(body);
      }
   }
   parseDate(date: Date) {
      date.setDate(date.getDate() + 1);
      const month = date.getUTCMonth() + 1; //months from 1-12
      const day = date.getUTCDate();
      const year = date.getUTCFullYear();
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
   }
}
