import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getNumberForShow, getNumberFromString } from 'src/app/utils/formatLocalNumber';
import { CodebookService } from 'src/services/codebook.service';
import { GlobalAdminService } from 'src/services/global-admin.service';
import { ToastrImplService } from 'src/services/toastr.service';
interface SelectOptions {
   viewValue: string;
   type: Number;
}
@Component({
   selector: 'app-pocket-money-amount',
   templateUrl: './pocket-money-amount.component.html',
   styleUrls: ['./pocket-money-amount.component.scss'],
})
export class PocketMoneyAmountComponent implements OnInit {
   pocketMoneyAmountForm: FormGroup;
   currentPocketMoneyAmount = {
      validFrom: '',
      amount: '',
      type: '',
   };
   pocketMoneyAmoutCodebook: any = [];
   displayedColumns: string[] = ['category', 'amount', 'validFrom'];
   categoryOptions: SelectOptions[] = [
      { type: 1, viewValue: 'за децу и омладину као и кориснике који у трошковима смештаја учествују личним средствима' },
      { type: 2, viewValue: 'За одрасла лица без прихода' },
   ];
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private formBuilder: FormBuilder,
      private globalAdminService: GlobalAdminService,
      private toastr: ToastrImplService,
      private codebookService: CodebookService,
      private translate: TranslateService,
      private datePipe: DatePipe
   ) {}

   ngOnInit(): void {
      this.pocketMoneyAmountForm = this.formBuilder.group({
         id: ['', []],
         amount: ['', [Validators.pattern('^[0-9.,]*$')]],
         validFrom: ['', []],
         category: ['', []],
         type: ['', []],
      });
      this.getPocketMoneyAmount();
   }
   checkPockentMoneyAmount() {
      const amount = getNumberForShow(this.pocketMoneyAmountForm.controls.amount.value);
      if (amount) this.pocketMoneyAmountForm.controls.amount.setValue(amount);
   }

   submitPocketMoneyAmountForm(data: any) {
      const newObject = {
         id: null,
         amount: getNumberFromString(data.value.amount),
         category: data.value.category.viewValue,
         validFrom: this.datePipe.transform(new Date(data.value.validFrom).getTime(), 'yyyy-MM-dd'),

         type: data.value.category.type,
      };
      this.globalAdminService.createPocketMoneyAmountCodebook(newObject).subscribe(res => {
         this.toastr.success('SNACKBAR.CHANGE_SAVED');
         this.getPocketMoneyAmount();
      });
      // If successful show snackbar
   }

   getPocketMoneyAmount() {
      this.codebookService.getPocketMoneyAmountCodebook().subscribe(res => {
         this.pocketMoneyAmoutCodebook = res.sort((a, b) =>
            a.validFrom != b.validFrom ? new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime() : a.type - b.type
         );
      });
   }
   public updateDataInRow(value: any) {
      this.pocketMoneyAmountForm.controls.id.setValue(value.id);
      this.pocketMoneyAmountForm.controls.validFrom.setValue(new Date(value.validFrom));
      this.pocketMoneyAmountForm.controls.amount.setValue(value.amount);
      this.pocketMoneyAmountForm.controls.category.setValue(value.type == 1 ? this.categoryOptions[0] : this.categoryOptions[1]);
      this.pocketMoneyAmountForm.controls.type.setValue(value.type);

      this.currentPocketMoneyAmount = value;
   }
   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.type === object2.type;
   }
}
