/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'definition-item-divider',
  templateUrl: './definition-item-divider.component.html',
  styleUrls: ['./definition-item-divider.component.scss'],
})
export class DefinitionItemDividerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
