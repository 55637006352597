<div [ngClass]="{
    'collapsible-worker__wrapper': true,
    'collapsible-worker__wrapper--expanded': groupExpanded
  }">
  <header class="collapsible-worker__header" (click)="toggle()">
    <span class="collapsible-worker__workload"
      matTooltip="{{ 'SCHEDULE_CASES.WORKERS.WORKLOAD_TOOLTIP' | translate }} "(click)="openWorkloadCalculation()">{{ workload }}</span>
    <h4 class="collapsible-worker__title" *ngIf="title">
      {{ title }}
    </h4>
    <span [ngClass]="{
        'collapsible-worker__meta': true,
        'collapsible-worker__meta--hidden': groupExpanded
      }">
      {{ meta }}
    <ng-container *ngIf="meta === 1"> {{ 'SCHEDULE_CASES.WORKERS.CASE' | translate }}</ng-container>
    <ng-container *ngIf="meta > 1 || meta === 0"> {{ 'SCHEDULE_CASES.WORKERS.CASES' | translate }}</ng-container>
    </span>
    <mat-icon
      [ngClass]="{ 'collapsible-worker__chevron-icon': true, 'collapsible-worker__chevron-icon--expanded': groupExpanded }">
      chevron_right</mat-icon>
  </header>
  <div class="collapsible-worker__content">
    <ng-content></ng-content>
  </div>
</div>
