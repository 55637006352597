<container kind="midi">

   <case-page-header (newCaseStatus)="updateStatus($event)" *ngIf="caseData"
      [caseData]="caseData" (handleCloseCaseData)="handleCloseCase($event)" [isLawyer]="isLawyer"></case-page-header>
   <page-intro-header title="{{caseData?.socialCaseClassificationCodebook?.title}}"></page-intro-header>
   <mat-card class="card" *ngIf="isLawyer && teamMember===false">
      <form [formGroup]="pmnForm" class="form">
         <div class="pmn__check-status"
            *ngIf="caseData?.socialCaseClassificationCodebook?.caseKindViewCodebook.type === 1">
            <button mat-stroked-button color="primary" type="button" (click)="checkSubjectStatus()"
               [disabled]="!isAccepted">
               <mat-icon>verified_user</mat-icon>{{ 'PMN.CHECK_STATUS' | translate }}
            </button>
         </div>
         <form-subgroup-divider *ngIf="caseData?.socialCaseClassificationCodebook?.caseKindViewCodebook.type === 1"></form-subgroup-divider>
         <div class="pmn__button-wrapper">
            <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
               (click)="$event.stopPropagation()" class="pmn__select-button" type="button" [disabled]="!isAccepted">
               {{ 'PMN.DOCUMENT' | translate }}<mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
               <ng-container *ngFor="let docOptions of documentOptions">
                  <button mat-menu-item (click)="handleNewDocument(docOptions)">
                     {{ docOptions.name }}
                  </button>
               </ng-container>
            </mat-menu>
            <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden />
            <button class="header__add-document" mat-flat-button color="primary" type="button" (click)="file.click()"
               [disabled]="!isAccepted">
               {{ 'PMN.CREATE' | translate }}
            </button>
         </div>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'PMN.SOLUTION' | translate }}</mat-label>
               <input matInput type="text" formControlName="fileName" readonly required autocomplete="off" />
            </mat-form-field>
         </field-holder>
         <form-footer class="footer">
            <span>
               <button mat-flat-button color="primary" type="button"
                  (click)="sendRequest(); submitButton.disabled = true" #submitButton
                  class="form-footer__button form-footer__button--secondary"
                  [disabled]="fileToUpload === null">
                  {{ 'PMN.SEND' | translate }}
               </button>
            </span>
            <mat-progress-spinner [diameter]="45" *ngIf="isSubmited" color="primary" mode="indeterminate">
            </mat-progress-spinner>
         </form-footer>
      </form>
   </mat-card>
   <mat-card *ngIf="teamMember">
      <team-member [editable]="isAccepted" [caseId]="caseId" [subjectId]="subjectId"></team-member>
   </mat-card>
</container>