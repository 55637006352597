<header class="header">
  <card-header title="{{ 'SUBJECT_TRACKING.TITLE' | translate }}">
    <div class="header__actions">
      <button *ngIf="!isUserDrugostepeni" class="header__add-document" mat-flat-button color="primary" 
              type="button" [disabled]="addButtonDisabled" (click)="openDialog('new')">
        {{ 'SUBJECT_TRACKING.CREATE' | translate }}
      </button>
      <button mat-icon-button class="button-square" color="primary" (click)="printTrackingList()">
        <mat-icon class="button-square__icon">print</mat-icon>
      </button>
      <button mat-icon-button class="button-square" color="primary" [matMenuTriggerFor]="menu"
        #menuTrigger="matMenuTrigger" [matBadge]="badgeCount" [matBadgeHidden]="badgeCount === 0"
        matTooltip="{{ 'SUBJECT_TRACKING.TOOLTIP_FILTER' | translate }}">
        <mat-icon class="button-square__icon">filter_list</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="filter-menu">
        <form [formGroup]="filterForm" class="filter-menu__content" (click)="$event.stopPropagation()">
          <field-holder>
            <mat-form-field appearance="outline">
              <mat-label>{{
                'SUBJECT_TRACKING.FILTER.FROM' | translate
              }}</mat-label>
              <input matInput [matDatepicker]="minDatepicker" formControlName="minDate" [max]="maxDate"/>
              <mat-datepicker-toggle matSuffix [for]="minDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #minDatepicker></mat-datepicker>
            </mat-form-field>
          </field-holder>
          <field-holder>
            <mat-form-field appearance="outline">
              <mat-label>{{
                'SUBJECT_TRACKING.FILTER.TO' | translate
              }}</mat-label>
              <input matInput [matDatepicker]="maxDatepicker" formControlName="maxDate" [max]="maxDate"/>
              <mat-datepicker-toggle matSuffix [for]="maxDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #maxDatepicker></mat-datepicker>
            </mat-form-field>
          </field-holder>
          <form-footer>
            <button class="form-footer__button form-footer__button--secondary" mat-stroked-button color="primary"
              type="button" (click)="resetFilter()">
              {{ 'SUBJECT_TRACKING.FILTER.RESET' | translate }}
            </button>
            <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
              type="button" (click)="applyFilter()">
              {{ 'SUBJECT_TRACKING.FILTER.APPLY' | translate }}
            </button>
          </form-footer>
        </form>
      </mat-menu>
    </div>
  </card-header>
  <div [class]="appliedFilters.length > 0 ? 'mb-30' : ''">
    <mat-chip-list #chipList aria-label="Applied filters">
      <mat-chip *ngFor="let filter of appliedFilters" (removed)="removeFilter(filter)">
        {{ filter.displayValue }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</header>
<ng-container *ngIf="!isLoading">
<ul class="tracking-list">
  <li *ngFor="let item of page.content">
    <tracking-list-item activity="{{ item.activity.title }}" date="{{ item.date | date: 'dd.MM.yyyy.'}}" caseId="{{ item.caseId }}"
      caseName="{{ item.caseName }}" author="{{ item.author }}" description="{{ item.description}}" activityId="{{ item.activity.id }}" id="{{ item.id }}"
      isEditable="{{ this.addButtonDisabled }}"
      (handleClick)="openDialog('view', item)"
      (handleDelete)="openDialog('delete', item)"
      (handleEdit)="openDialog('edit', item)"></tracking-list-item>
  </li>
  <li class="tracking-list__item tracking-list__item--empty" *ngIf="page.content.length === 0">
    <mat-icon class="tracking-list__icon tracking-list__icon--empty">info</mat-icon>
    {{ 'SUBJECT_TRACKING.NO_RESULTS' | translate }}
  </li>
</ul>
<div class="pagination-wrapper" *ngIf="page.content.length !== 0">
  <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
  </app-custom-pagination>
</div>
</ng-container>
<ng-container *ngIf="isLoading">
  <div class="spinner-wrapper">
     <mat-progress-spinner color="primary" mode="indeterminate">
     </mat-progress-spinner>
  </div>
</ng-container>
