/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

export interface FamilyMember {
  firstLastName: string;
  relationship: any;
  yearOfBirth: number;
  qualification: any;
  regNumber: number;
  disabledPerson: any;
  ableToWork: any;
  subjectIdentity: SubjectIdentity;
  forBilling: any;
  dateOfBirth: Date;
  isEditable: boolean;
  jmbp: string;
  qualificationEmployment:any;
  jmbg: any
}

export interface SubjectIdentity {
  subjectId: number;
  csrId: number;
}

export enum EventEnum {
  CANCEL = 'Cancel',
  SUBMIT = 'Submit',
}

export interface SubmitterData {
  entranceIdentity: number;
  center: string;
  dateOfSubmission: string;
  timeOfSubmission: string;
  typeOfSubmission: string;
  kindOfSubmission: string;
  nameOfSubmitter: string;
  surnameOfSubmitter: string;
  addressOfSubmitter: string;
  phoneOfSubmitter: string;
  reasonForSubmit: string;
  previousEvidention: string;
  otherService: string;
  revId: number;
  subjectId: number;
  usefulData: string;
  entranceDecision: string;
  decisionAddInfo: string;
  conclusion_explanation: string;
  subjectRegNumb: number;
  familyRegNumb: number;
  signature: string;
}

export interface FamilyMembersData {
  members: Array<FamilyMember>;
  event: EventEnum;
}

export enum ArchiveReasonEnum {
  ONE = 'One',
  TWO = 'Two',
}

export interface ArchiveReason {
  value: ArchiveReasonEnum;
  viewValue: string;
}

export enum EmploymentStatusEnum {
  ZAPOSLEN = 'Zaposlen',
  RADNO_ANGAZOVAN = 'Radno angazovan',
  NEZAPOSLEN = 'Nezaposlen',
  PENZIONER = 'Penzioner',
  UCENIK = 'Ucenik',
  NESPOSOBAN_ZA_RAD = 'Nesposoban za rad',
  OSTALO = 'Ostalo'
}

export interface EmploymentStatuses {
  value: EmploymentStatusEnum;
  viewValue: string;
}

export enum ChildSchoolEnum {
  IDU_U_SKOLU = 'Redovno idu u skolu',
  NE_IDU_U_SKOLU = 'Ne idu u skolu'
}

export interface ChildSchoolStatuses {
  value: ChildSchoolEnum;
  viewValue: string;
}

export enum HousingStatusesEnum {
  VLASNIK_SUVLASNIK = 'Vlasnik/suvlasnik',
  NOSILAC_PRAVA = 'Nosilac stanarskog prava',
  SUSTANAR = 'Sustanar',
  ZAKUPAC_STANA = 'Zakupac stana',
  ZASTICENI_STANAR = 'Zasticeni stanar',
  OSTALO = 'Ostalo'
}

export interface HousingStatuses {
  value: HousingStatusesEnum;
  viewValue: string;
}

export enum HouseInfo1Enum {
  NE_ODGOVARA_POTREBAMA = 'Ne odgovara potrebama',
  ODGOVARA_POTREBAMA = 'Odgovara potrebama',
  PREVAZILAZI_POTREBE = 'Prevazilazi potrebe porodice'
}

export interface HouseInfo1Statuses {
  value: HouseInfo1Enum;
  viewValue: string;
}

export enum YesOrNoEnum {
  DA = 'Da',
  NE = 'Ne',
}

export interface YesOrNoStatuses {
  value: YesOrNoEnum;
  viewValue: string;
}

export enum DependentStatusesEnum {
  SRODNICI_UCESTVUJU = 'Ima srodnike koji ucestvuju u njegovom izdrzavanju',
  SRODNICI_NE_UCESTVUJU = 'Ima srodnike koji ne ucestvuju u njegovom izdrzavanju',
  NEMA_SRODNIKE = 'Nema srodnike koji su obavezni da ga izdrzavaju'
}

export interface DependentStatuses {
  value: DependentStatusesEnum;
  viewValue: string;
}

export enum BreadWinnerStatusesEnum {
  NEMA_OBAVEZU = 'NEMA_OBAVEZU',
  IMA_OBAVEZU = 'IMA_OBAVEZU'
}

export interface BreadWinnerStatuses {
  value: BreadWinnerStatusesEnum;
  viewValue: string;
}

export enum EngagementStatusesEnum {
  UKLJUCIVANJE_U_SKOLOVANJE = 'UKLJUCIVANJE USKOLOVANJE',
  OSPOSOBLJAVANJE_ZA_RAD = 'OSPOSOBLJAVANJE ZA RAD',
  RADNO_ANGAZOVANJE = 'RADNO ANGAZOVANJE',
  DRUSTVENO_ANGAZOVANJE = 'DRUSTVENO ANGAZOVANJE'
}

export interface EngagementStatuses {
  value: EngagementStatusesEnum;
  viewValue: string;
}

export enum EmploymentOptionsEnum {
  POSLODAVAC = 'NAZIV POSLODAVCA',
  POLJOPRIVREDNIK = 'POLJOPRIVREDNIK',
  SAMOSTALNA_DELATNOST = 'SAMOSTALNA DELATNOST'
}

export interface EmploymentOptions {
  value: EmploymentOptionsEnum;
  viewValue: string;
}

export enum RevenueEnum {
  PO_OSNOVU_RADNOG_ODNOSA = '01',
  OD_POVREMENIH_POSLOVA = '02',
  PENZIJA_INVALIDNINA = '03',
  POLJOPRIVREDNE_DELATNOSTI = '04',
  PO_OSNOVU_SAMOSTALNE_DELATNOSTI = '05',
  ZA_VREME_NEZAPOSLENOSTI = '06',
  PO_OSNOVU_SOCIJALNIH_PROGRAMA = '07',
  IZDAVANJE_U_ZAKUP = '08',
  IMOVINSKA_PRAVA_I_AKCIJE = '09',
  STEDNJA_I_GOTOVINA = '10',
  SEZONSKI_POSLOVI = '11',
  IZDRAVANJE_SRODNIKA = '12'
}

export interface FamilyRevenues {
  value: RevenueEnum;
  viewValue: string;
}
