/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { Pageable } from 'src/app/pageable';
import { Observable } from 'rxjs';
import { Page } from 'src/app/page';
import { LegalSubject } from 'src/models/legalSubject.model';
import { environment } from 'src/environments/environment';
import * as printJS from 'print-js';

@Injectable({
  providedIn: 'root'
})
export class LegalSubjectsService {

  constructor(private httpClient: HttpClient, private app: AppService) { }

  getFilteredPage(pageable: Pageable, filterValue: string, nextPage?: boolean): Observable<Page<LegalSubject>> {

    if (filterValue === null) {
      const headers = new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
      });
      return this.httpClient.get(this.app.getUrl() + environment.getPaginationLegalUrl + pageable.pageNumber
        + '/' + pageable.pageSize, { headers }) as Observable<any>;
    } else {
      const filterJSON = JSON.parse(JSON.stringify(filterValue));
      let nextPagePar = false;
      if (nextPage !== null && nextPage !== undefined) {
        nextPagePar = true;
      }
      return this.httpClient.post(this.app.getUrl() + environment.getFilterPaginationLegalUrl + pageable.pageNumber
        + '/' + pageable.pageSize + '/' + nextPagePar, filterJSON) as Observable<any>;
    }
  }

  getHistoryPage(pageable: Pageable, id: String): Observable<Page<Object>> {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getLegalHistoryUrl
      + id + '/' + pageable.pageNumber
      + '/' + pageable.pageSize, { headers }) as Observable<any>;

  }

  getCurrentLegalSubject(id: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getCurrentLegalSubjectUrl + id, { headers }) as Observable<any>;
  }
  getLegalSubjectById(id: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getLegalSubjectById + id, { headers }) as Observable<any>;
  }


  getPdf(printDto): any {

    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    this.httpClient.put(this.app.getUrl() + environment.getPdfLegalUrl, printDto,
      { responseType: 'blob', headers }).subscribe(data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        printJS(fileURL);
      });
  }

  addSubject(subject: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.put(this.app.getUrl() + environment.addLegalSubjectUrl, subject, { headers }) as Observable<any>;
  }

  exportCSV(filterValue: any) {
    const filterJSON = JSON.parse(JSON.stringify(filterValue));
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    this.httpClient.put(this.app.getUrl() + environment.getCsvLegalUrl, filterJSON, { responseType: 'blob', headers }).subscribe(data => {
      const parts = [];
      parts.push('\uFEFF');
      parts.push(data);
      const file = new Blob(parts, { type: 'text/csv' });
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = fileURL;
      a.download = 'Правна лица.csv';
      a.click();
      window.URL.revokeObjectURL(fileURL);
      a.remove();
    });
  }

  getLegalSubject(pib: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getLegalSubjectUrl + '/' + pib, { headers }) as Observable<any>;
  }

  checkIndetificationNumber(indetificationNumber: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });

    return this.httpClient.get(this.app.getUrl() + environment.checkIndetificationNumber +
      indetificationNumber, { headers }) as Observable<any>;
  }
}
