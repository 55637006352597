/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import { CaseService } from 'src/services/case.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { Router } from '@angular/router';
import { RecordValidators } from './record.validators';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { patchForDecimalNumber, getNumberForShow, getNumberFromString } from 'src/app/utils/formatLocalNumber';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';

/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */

@Component({
   selector: 'subject-new-record',
   templateUrl: './subject-new-record.component.html',
   styleUrls: ['./subject-new-record.component.scss'],
})
export class SubjectNewRecordComponent implements OnInit {
   recordTypeOptions: any = [];
   servicesList = [];
   legalProceduresList = [];
   activeRightsList = [];
   visible: boolean;
   minFromDate = new Date();
   minToDate = new Date();
   serviceProviders: any = [];
   recordForm: FormGroup;
   format: any = null;
   problemIds: any = [];
   filteredProblemIdsOptions: Observable<any[]>;

   codebookTypes = [
      { id: 'Services', title: 'Услуге' },
      { id: 'LegalProcedures', title: 'Мере' },
      { id: 'RightsCodebook', title: 'Права' },
   ];
   maxDate: Date = new Date(2999, 11, 31);

   @ViewChild('submitButton') submitButton;

   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public router: Router,
      public dialogRef: MatDialogRef<SubjectNewRecordComponent>,
      private fb: FormBuilder,
      private codebookService: CodebookService,
      private caseService: CaseService
   ) {
      this.visible = false;

      this.recordForm = this.fb.group({
         id: null,
         subjectId: this.data.subjectId,
         recordType: ['', [Validators.required]],
         codebookType: ['', [Validators.required]],
         problemId: ['', [Validators.required]],
         fromDate: [''],
         toDate: [''],
         serviceProvider: [''],
         price: [''],
      });

      if (data?.caseId) {
         this.caseService.getAllProblemIdsForCase(this.data.caseId).subscribe(result => {
            this.problemIds = result;
            this.filteredProblemIdsOptions = this.recordForm.controls.problemId.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.id)),
               map(problemId => (problemId ? this._filter(problemId) : this.problemIds.slice()))
            );
         });
      } else {
         this.caseService.getAllProblemIds(this.data.subjectId).subscribe(result => {
            this.problemIds = result;
            this.filteredProblemIdsOptions = this.recordForm.controls.problemId.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.id)),
               map(problemId => (problemId ? this._filter(problemId) : this.problemIds.slice()))
            );
         });
      }
      if (this.data.record.caseId != null) {
         this.patchData();
      }
   }

   ngOnInit() {
      this.getServiceProviderCodebook();
   }

   recordTypeChange() {
      if (this.recordForm.controls.recordType.value !== '') {
         const choosedRecord = this.recordTypeOptions.find((x: any) => x.id === this.recordForm.controls.recordType.value);
         this.format = choosedRecord.format;
         this.setConditionsForFields();
         if (this.data.record.caseId !== null || this.data.patchData !== undefined) {
            this.setConditionalFields();
         }
      } else {
         this.setConditionsForFields();
         this.format = null;
      }
   }

   checkPrice() {
      this.recordForm.controls.price.setValue(getNumberForShow(this.recordForm.value.price));
   }

   addNewRecords() {
      this.submitButton.disabled = true;
      this.checkIdInRange();
      if (!this.recordForm.valid) {
         return;
      }
      this.caseService.addCaseRight(this.makeBody()).subscribe(
         res => {
            this.dialogRef.close({ event: 'Create', data: res });
         },
         () => {
            // error
            this.submitButton.disabled = false;
         }
      );
   }

   closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
   }

   getRightsCodebook() {
      this.codebookService.getActiveRightsCodebook().subscribe(res => {
         this.recordTypeOptions = res;
      });
   }

   getServiceProviderCodebook() {
      this.codebookService.getServiceProviderCodebook().subscribe(res => {
         this.serviceProviders = res;
      });
   }

   checkCaseProblem() {
      if (!this.recordForm.get('problemId').hasError('pattern')) {
         const body = {
            caseNumber: this.recordForm.get('caseNumber').value,
            problemNumber: this.recordForm.get('problemId').value,
         };
         if (body.caseNumber !== '') {
            this.caseService.findByCaseProblem(body).subscribe(res => {
               if (res == null) {
                  this.recordForm.get('problemId').setErrors({ errorProblemNumber: true });
               } else {
                  this.recordForm.get('problemId').setErrors(null);
               }
            });
         }
      }
   }

   onChangeCodebookType(event: any) {
      this.recordForm.controls.codebookType.setValue(event.value);
      this.recordForm.controls.recordType.setValue('');
      if (event.value === 'Services') {
         if (this.servicesList.length === 0) {
            this.codebookService.getActiveServicesCodebook().subscribe(res => {
               this.servicesList = res;
               this.recordTypeOptions = this.servicesList;
            });
         } else {
            this.recordTypeOptions = this.servicesList;
         }
      } else if (event.value === 'LegalProcedures') {
         if (this.legalProceduresList.length === 0) {
            this.codebookService.getActiveLegalProceduresCodebook().subscribe(res => {
               this.legalProceduresList = res;
               this.recordTypeOptions = this.legalProceduresList;
            });
         } else {
            this.recordTypeOptions = this.legalProceduresList;
         }
      } else {
         if (this.activeRightsList.length === 0) {
            this.codebookService.getActiveRightsCodebook().subscribe(res => {
               this.activeRightsList = res;
               this.recordTypeOptions = this.activeRightsList;
            });
         } else {
            this.recordTypeOptions = this.activeRightsList;
         }
      }
   }

   public _filter(value: string): any[] {
      const filterValue = value.toString().toLowerCase();
      return sortByFirstLetter(
         this.problemIds.filter((option: any) => option.id.toString().toLowerCase().includes(filterValue)),
         filterValue,
         'id'
      );
   }

   displayCustomFormat(problem: any): string {
      return problem ? `${problem.id} (${problem.type}${problem.caseNumber ? `, ${problem.caseNumber}` : ''})` : '';
   }

   checkIdInRange() {
      autoSelect(this.problemIds, 'problemId', 'id', this.recordForm);
      if (this.recordForm.controls.problemId.valid) {
         if (!this.problemIds.find(problem => problem.id === Number(this.recordForm.value.problemId.id))) {
            this.recordForm.controls.problemId.setErrors({ errorProblemNumber: true });
         }
      }
   }

   patchData() {
      const caseRights = this.data.record;
      const problem = { id: caseRights.problemId, type: caseRights.problemTitle, caseNumber: caseRights.caseNumber };

      this.recordForm.controls.id.setValue(caseRights.caseId);
      this.recordForm.controls.problemId.setValue(problem);
      if (caseRights.servicesId !== null) {
         this.recordForm.controls.codebookType.setValue(this.codebookTypes[0].id);
         this.recordForm.controls.recordType.setValue(caseRights.servicesId);
         this.codebookService.getActiveServicesCodebook().subscribe(res => {
            this.recordTypeOptions = res;
            this.recordTypeChange();
         });
      }
      if (caseRights.legalProceduresId !== null) {
         this.recordForm.controls.codebookType.setValue(this.codebookTypes[1].id);
         this.recordForm.controls.recordType.setValue(caseRights.legalProceduresId);
         this.codebookService.getActiveLegalProceduresCodebook().subscribe(res => {
            this.recordTypeOptions = res;
            this.recordTypeChange();
         });
      }
      if (caseRights.caseRightId !== null) {
         this.recordForm.controls.codebookType.setValue(this.codebookTypes[2].id);
         this.recordForm.controls.recordType.setValue(caseRights.caseRightId);
         this.codebookService.getActiveRightsCodebook().subscribe(res => {
            this.recordTypeOptions = res;
            this.recordTypeChange();
         });
      }
      this.recordForm.controls.problemId.disable();
      this.recordForm.controls.codebookType.disable();
      this.recordForm.controls.recordType.disable();

      this.recordForm.markAllAsTouched();
   }
   setConditionalFields() {
      if (this.recordForm.controls.fromDate !== undefined) {
         this.recordForm.controls.fromDate.setValue(this.data.record.fromDate ? this.data?.record?.fromDate : this.data?.patchData?.fromDate);
      }
      if (this.recordForm.controls.toDate !== undefined) {
         this.recordForm.controls.toDate.setValue(this.data.record.toDate ? this.data.record?.toDate : this.data.patchData?.toDate);
      }
      if (this.recordForm.controls.price !== undefined) {
         this.recordForm.controls.price.setValue(patchForDecimalNumber(this.data.record?.price ? this.data.record?.price : this.data.patchData?.amount));
      }
      if (this.recordForm.controls.serviceProvider !== undefined) {
         this.recordForm.controls.serviceProvider.setValue(this.data.record?.serviceProviderId);
      }
   }

   setConditionsForFields() {
      switch (this.format) {
         case 2: {
            this.resetControl(this.recordForm.controls.serviceProvider);
            this.resetControl(this.recordForm.controls.price);
            break;
         }
         case 3: {
            this.recordForm.controls.serviceProvider.setValidators(Validators.required);
            this.resetControl(this.recordForm.controls.price);
            break;
         }
         case 4:
         case 5: {
            this.resetControl(this.recordForm.controls.serviceProvider);
            this.recordForm.controls.price.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            break;
         }
         case 6: {
            this.resetControl(this.recordForm.controls.serviceProvider);
            this.recordForm.controls.price.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            this.recordForm.controls.price.updateValueAndValidity();
            break;
         }
         case 7: {
            this.recordForm.controls.serviceProvider.setValidators(Validators.required);
            this.resetControl(this.recordForm.controls.price);
            break;
         }
         default: {
            this.resetControl(this.recordForm.controls.serviceProvider);
            this.resetControl(this.recordForm.controls.price);
            break;
         }
      }
      this.recordForm.controls.price.setValue('');
      this.recordForm.controls.fromDate.setValue('');
      this.recordForm.controls.toDate.setValue('');
      this.recordForm.controls.serviceProvider.setValue('');
      this.recordForm.updateValueAndValidity();
   }
   resetControl(control: AbstractControl): void {
      control.setValidators(null);
      control.setErrors(null);
      control.setValue(null);
      control.markAsUntouched();
   }

   checkToDate() {
      if (this.recordForm.controls.toDate !== undefined && this.recordForm.controls.fromDate !== undefined) {
         if (this.recordForm.controls.toDate.value !== null && this.recordForm.controls.fromDate.value === null) {
            this.recordForm.controls.toDate.setErrors({ notValid: true });
         } else if (this.recordForm.controls.toDate.hasError('notValid')) {
            this.recordForm.controls.toDate.setErrors(null);
         }
      }
   }

   makeBody() {
      return {
         id: this.recordForm.controls.id.value,
         subjectId: this.recordForm.controls.subjectId.value,
         problemId: this.recordForm.controls.problemId.value.id,
         fromDate: this.recordForm.controls.fromDate.value,
         toDate: this.recordForm.controls.toDate.value,
         recordType: this.recordForm.controls.recordType.value,
         price: getNumberFromString(this.recordForm.controls.price.value),
         serviceProvider: this.recordForm.controls.serviceProvider.value,
         codebookType: this.recordForm.controls.codebookType.value,
      };
   }
}
