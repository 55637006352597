<form class="form" [formGroup]="terminationForm" (ngSubmit)="addNewRecord(terminationForm)">
  <form-group
    title="{{ 'ESTABLISHED_FOSTER_CARE.TERMINATION.TITLE' | translate }}"
    isLast="true"
  >
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'ESTABLISHED_FOSTER_CARE.TERMINATION.COURT_NAME' | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="courtName"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ESTABLISHED_FOSTER_CARE.TERMINATION.COURT_NUMBER' | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="courtNumber"
          autocomplete="off"
        />
      </mat-form-field>
      
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ESTABLISHED_FOSTER_CARE.TERMINATION.DATE_OF_CUSTODY_TERMINATION' | translate
        }}</mat-label>
        <input
          matInput
          [matDatepicker]="dateOfCustodyTermination"
          [max]="currentDate"
          formControlName="dateOfCustodyTermination"
          required
          autocomplete="off"
        />
        <mat-datepicker-toggle
          matSuffix
          required
          [for]="dateOfCustodyTermination"
        ></mat-datepicker-toggle>
        <mat-datepicker #dateOfCustodyTermination></mat-datepicker>
        <mat-error
          *ngIf="terminationForm.get('dateOfCustodyTermination').hasError('required')"
        >
          {{ 'ESTABLISHED_FOSTER_CARE.TERMINATION.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    
    
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'ESTABLISHED_FOSTER_CARE.TERMINATION.REASONS_FOR_CUSTODY_TERMINATION' | translate
          }}</mat-label>
        <mat-select required formControlName="reasonsForCustodyTermination" [compareWith]="compareObjects">
          <mat-option *ngFor="let reason of reasonsForCustodyTerminations" [value]="reason">
            {{ reason.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="terminationForm.get('reasonsForCustodyTermination').hasError('required')">
          {{ 'ESTABLISHED_FOSTER_CARE.TERMINATION.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button
      mat-stroked-button
      color="primary"
      class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()"
      type="button"
    >
      {{ 'ESTABLISHED_FOSTER_CARE.TERMINATION.CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="modal-footer__button modal-footer__button--primary"
      type="submit"
      [disabled]="!terminationForm.valid"
    >
      {{ 'ESTABLISHED_FOSTER_CARE.TERMINATION.SAVE' | translate }}
    </button>
  </form-footer>
</form>
