/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import { ProtegePersonService } from 'src/services/protege-person.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

enum AutocompleteFilterEnum {
    OCCUPATION_GUARDIAN = 'occupation_guardian',
    OCCUPATION_DIRECT = 'occupation_direct',
    RELATIONSHIP = 'relationship',
    CITIZENSHIP = 'citizenship'
}
/**
 *
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 *
 */
@Component({
    selector: 'app-edit-protege-detail',
    templateUrl: './edit-protege-detail.component.html',
    styleUrls: ['./edit-protege-detail.component.scss']
})
export class EditProtegeDetailComponent implements OnInit {
    relationshipOptions: any = [];
    occupationOptions: any = [];
    occupationDirectOptions: any = [];
    citizenshipOptions: any = [];
    editProtegeDetailsForm: FormGroup;
    editProperty: any;
    editGuardianship: any;
    filteredOccupationOptions: Observable<any[]>;
    filtereDirectOccupationOptions: Observable<any[]>;
    filterRelationshipOptions: Observable<any[]>;
    filteredCitizenshipOptions: Observable<any[]>;

    constructor(
        public dialog: MatDialog,
        private protegePersonService: ProtegePersonService,
        private codebookService: CodebookService,
        public dialogRef: MatDialogRef<EditProtegeDetailComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder) {
        this.editProtegeDetailsForm = this.formBuilder.group({
            assetValueOnCensusDay: [this.data.protege.protegePersonCollection.assetValueOnCensusDay],
            assetValueOnTheDayOfTerminationOfCustody: [this.data.protege.protegePersonCollection.assetValueOnTheDayOfTerminationOfCustody],
            guardianFirstName: [this.data.protege.protegePersonCollection.guardianFirstName],
            guardianLastName: [this.data.protege.protegePersonCollection.guardianLastName],
            guardianJmbg: [this.data.protege.protegePersonCollection.guardianJmbg],
            guardianAddress: [this.data.protege.protegePersonCollection.guardianAddress],
            guardianOccupation: [this.data.protege.protegePersonCollection.guardianOccupation],
            guardianRelationship: [this.data.protege.protegePersonCollection.guardianRelationship],
            collectiveGuardianFirstName: [this.data.protege.protegePersonCollection.collectiveGuardianFirstName],
            collectiveGuardianLastName: [this.data.protege.protegePersonCollection.collectiveGuardianLastName],
            collectiveGuardianTypeOfJob: [this.data.protege.protegePersonCollection.collectiveGuardianTypeOfJob],
            collectiveGuardianProtectionName: [this.data.protege.protegePersonCollection.collectiveGuardianProtectionName],
            directGuardianFirstName: [this.data.protege.protegePersonCollection.directGuardianFirstName],
            directGuardianLastName: [this.data.protege.protegePersonCollection.directGuardianLastName],
            directGuardianOccupation: [this.data.protege.protegePersonCollection.directGuardianOccupation],
            guardianCitizenship: [this.data.protege.protegePersonCollection.guardianCitizenship],
            guardianPhone: [this.data.protege.protegePersonCollection.guardianPhone]
        });
        this.editProperty = this.data.editProperty;
        this.editGuardianship = this.data.editGuardianship;

        this.getRelationshipCodebook();
        this.getOccupation();
        this.getCitizenship();
    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close({ event: 'Cancel' });
    }


    editProtegeDetails() {
        if (this.editProtegeDetailsForm.value.guardianRelationship === '') {
            this.editProtegeDetailsForm.value.guardianRelationship = null;
        }

        if (this.editProtegeDetailsForm.value.guardianOccupation === '') {
            this.editProtegeDetailsForm.value.guardianOccupation = null;
        }

        if (this.editProtegeDetailsForm.value.directGuardianOccupation === '') {
            this.editProtegeDetailsForm.value.directGuardianOccupation = null;
        }
        if (this.editProtegeDetailsForm.value.guardianCitizenship === '') {
            this.editProtegeDetailsForm.value.guardianCitizenship = null;
        }

        this.protegePersonService.editProtegeDetails
            (this.data.protege.id, this.editProperty,
                this.editProtegeDetailsForm.value,
            ).subscribe((res) => {
                if (res != null) {
                    this.dialogRef.close({ event: 'Success' });
                } else {
                    this.dialogRef.close({ event: 'Unsuccessful' });
                }
            }
            );

    }

    getRelationshipCodebook() {
        this.codebookService.getRelationshipCodebookWithoutUser().subscribe(res => {
            this.relationshipOptions = res;

            this.filterRelationshipOptions = this.editProtegeDetailsForm.controls.guardianRelationship.valueChanges.pipe(
                startWith(''),
                map(value => value === null ? value : typeof value === 'string' ? value : value?.title),
                map(title => title ? this._filter(title, AutocompleteFilterEnum.RELATIONSHIP) : this.relationshipOptions.slice())
            );
        });
    }

    getOccupation() {
        this.codebookService.getOccupationCodebook().subscribe((res) => {
            this.occupationOptions = res;
            this.occupationDirectOptions = res;
            this.filteredOccupationOptions = this.editProtegeDetailsForm.controls.guardianOccupation.valueChanges.pipe(
                startWith(''),
                map(value => value === null ? value : typeof value === 'string' ? value : value?.title),
                map(title => title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION_GUARDIAN) : this.occupationOptions.slice())
            );

            this.filtereDirectOccupationOptions = this.editProtegeDetailsForm.controls.directGuardianOccupation.valueChanges.pipe(
                startWith(''),
                map(value => value === null ? value : typeof value === 'string' ? value : value?.title),
                map(title => title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION_DIRECT) : this.occupationOptions.slice())
            );
        }
        );
    }

    compareObj(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }

    importSubjectData(type: any) {
        const dialogRef = this.dialog.open(SubjectsComponent, {
            width: '1200px',
            panelClass:'overlay-panel',
            data: {
                origin: 'entrance',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (type === 'guardian') {
                    this.editProtegeDetailsForm.patchValue({
                        guardianFirstName: result.data.firstName,
                        guardianLastName: result.data.lastName,
                        guardianJmbg: result.data.jmbg,
                        guardianAddress: String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                            String(result.data.permanentResidence.number ? ' ' : '') +
                            String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                            String(result.data.permanentResidence.subnumber ? '/' : '') +
                            String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
                        guardianOccupation: result.data.occupation,
                        guardianCitizenship: result.data.citizenship,
                        guardianPhone: (result.data.mobile !== null && result.data.mobile !== '') ? result.data.mobile :
                            +            ((result.data.phone !== null && result.data.phone !== '') ? result.data.phone : null)

                    });
                } else if (type === 'directGuardian') {
                    this.editProtegeDetailsForm.patchValue({
                        directGuardianFirstName: result.data.firstName,
                        directGuardianLastName: result.data.lastName,
                        directGuardianOccupation: result.data.occupation

                    });
                } else {
                    this.editProtegeDetailsForm.patchValue({
                        collectiveGuardianFirstName: result.data.firstName,
                        collectiveGuardianLastName: result.data.lastName,
                    });
                }
            }
        });
    }

    displayCustomFormat(objectValue: any): string {
        return objectValue ? objectValue.title : '';
    }

    private _filter(value: string, what: AutocompleteFilterEnum): any[] {
        const filterValue = value.toLowerCase();
        switch (what) {
            case AutocompleteFilterEnum.OCCUPATION_GUARDIAN:
                return this.occupationOptions.filter((option) =>
                    option.title.toLowerCase().includes(filterValue)
                );

            case AutocompleteFilterEnum.OCCUPATION_DIRECT:
                return this.occupationDirectOptions.filter((option) =>
                    option.title.toLowerCase().includes(filterValue)
                );

            case AutocompleteFilterEnum.RELATIONSHIP:
                return this.relationshipOptions.filter((option) =>
                    option.title.toLowerCase().includes(filterValue)
                );
            case AutocompleteFilterEnum.CITIZENSHIP:
                return this.citizenshipOptions.filter((option: any) =>
                    option.title.toLowerCase().includes(filterValue)
                );
            default:
                break;
        }
    }
    getCitizenship() {
        this.codebookService.getCitizenshipCodebook().subscribe((res) => {
            this.citizenshipOptions = res;
            this.filteredCitizenshipOptions = this.editProtegeDetailsForm.controls.guardianCitizenship.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value?.title),
                    map(title => title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice())
                );
        }
        );
    }
}
