<form [formGroup]="addJmbgForm" autocomplete="off">
    <form-group title="{{'PROFILE.ADD_JMBG_TITLE' | translate  }}">
        <field-holder>
            <mat-form-field appearance="outline">
                <input matInput maxlength="13" type='text' formControlName="jmbg" required  autocomplete="off" (input)="checkJMBGRule()"/>
                <mat-label>{{ 'PROFILE.JMBG' | translate }}</mat-label>
                <mat-error *ngIf="addJmbgForm.get('jmbg').hasError('required') || addJmbgForm.get('jmbg').hasError('pattern')">
                    {{'PROFILE.ADD_JMBG_ERROR' | translate}}
                </mat-error>
            </mat-form-field>
        </field-holder>
        <field-holder>
            <mat-form-field appearance="outline">
                <input matInput type='password' formControlName="password" required autocomplete="off" />
                <mat-label>{{ 'PROFILE.PASSWORD' | translate }}</mat-label>
                <mat-error *ngIf="addJmbgForm.get('password').hasError('required')">
                    {{'PROFILE.NEW_PASSWORD_CONFIRM' | translate}}
                </mat-error>
            </mat-form-field>
        </field-holder>
    </form-group>
</form>

<div>
    <button class="form-footer__button form-footer__button--secondary" type="button" (click)="closeDialog()" mat-flat-button
        color="secondary">
        {{ 'GLOBAL.CANCEL' | translate }}
    </button>
    <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary"
        (click)="changeJmbg()" type="submit" [disabled]="!addJmbgForm.valid">
        {{ 'GLOBAL.SAVE' | translate }}
    </button>
</div>