<mat-dialog-content>
    <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
        [mat-dialog-close]="true"><span class="material-icons">close</span>
    </button>
    <div class="container">
        <form flex [formGroup]="addUserCommentForm">
            <h2>{{ 'VS.FORM5.USER_COMMENT.ADD_COMMENT' | translate }}</h2>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'VS.FORM5.USER_COMMENT.ADD_COMMENT_R' | translate }}</mat-label>
                    <textarea matInput type="text" formControlName="comment" required autocomplete="off"></textarea>
                </mat-form-field>
            </field-holder>
            <field-holder kind="2-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'VS.FORM5.USER_COMMENT.RELATIONSHIP' | translate }}</mat-label>
                    <mat-select formControlName="relationship" [compareWith]="compareRelationship">
                        <mat-option *ngFor="let relationship of relationshipOptions" [value]="relationship">
                            {{ relationship.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'VS.FORM5.USER_COMMENT.COMMENT_DATE' | translate}}</mat-label>
                    <input matInput [matDatepicker]="commentDate" [min]="minFromDate" formControlName="commentDate"
                        autocomplete="off" (dateChange)="checkDateComment($event)" [max]="maxDate" />
                    <mat-datepicker-toggle matSuffix [for]="commentDate"></mat-datepicker-toggle>
                    <mat-datepicker #commentDate></mat-datepicker>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
                    class="form-footer__button form-footer__button--secondary">
                    {{ 'VS.FORM5.USER_COMMENT.CANCEL' | translate }}
                </button>
                <button *ngIf="!isEdit" mat-flat-button color="primary" type="button" (click)="addUserComment()"
                    [disabled]="!addUserCommentForm.valid" class="form-footer__button form-footer__button--primary">
                    {{ 'VS.FORM5.USER_COMMENT.SUBMIT' | translate }}
                </button>
                <button *ngIf="isEdit" mat-flat-button color="primary" type="button" (click)="editUserComment()"
                    [disabled]="!addUserCommentForm.valid" class="form-footer__button form-footer__button--primary">
                    {{ 'VS.EDIT' | translate }}
                </button>
            </form-footer>
        </form>
        <br />
    </div>
</mat-dialog-content>