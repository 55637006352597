<container>
  <mat-card class="card schedule-case">
    <section class="section section--left">
      <header class="section__header">
        <h2 class="section__title">
          {{ 'SCHEDULE_CASES.CASES.TITLE' | translate }}
        </h2>
        <p>{{ 'SCHEDULE_CASES.CASES.DESCRIPTION' | translate }}</p>
      </header>
      <div *ngIf="allOpenCases.length === 0">
        <p class="section__no-results">
          <mat-icon class="section__no-results-icon">info</mat-icon>
          {{ 'SCHEDULE_CASES.CASES.ALL_CASES_ASSIGNED' | translate }}
        </p>
      </div>
      <div class="open-case__list" *ngIf="allOpenCases.length > 0">
        <div class="open-case__item" *ngFor="let scase of allOpenCases; let i = index">
          <collapsible-case [number]="scase.caseNumber" [title]="" [kind]="scase.mark" [date]="scase.dateOfCreation"
            [teamRequest]="scase.teamFormingRequest" [groupExpanded]="i === 0">
            <div class="open-case__details">
              <definition-list>
                <definition-item key="{{ 'GLOBAL.SUBJECT.ID' | translate }}" fontSize="mini" valueOffset="150px">
                  {{ scase?.subjectId ? scase?.subjectId : scase?.subject.id}}</definition-item>
                <definition-item key="{{ 'GLOBAL.SUBJECT.FIRST_AND_LAST_NAME' | translate }}" fontSize="mini"
                  valueOffset="150px">
                  {{ scase?.name ? scase?.name : scase?.subject.firstName }}
                  {{ scase?.name ? '' : scase?.subject.lastName}}
                </definition-item>
                <definition-item *ngIf="scase?.dossierOwner" key="{{ 'SUBJECT_DETAILS.DOSSIER_OWNER' | translate }}" fontSize="mini" valueOffset="150px">
                  {{ scase?.dossierOwner }} 
                </definition-item>
                <definition-item *ngIf="!scase.teamFormingRequest &&scase.originArea!==null&&scase.originArea!==''"
                  key="{{ 'SCHEDULE_CASES.CASES.ORIGIN_AREA' | translate }}" fontSize="mini" valueOffset="150px">
                  {{ scase?.originArea}}</definition-item>
                  <definition-item *ngIf="scase.teamFormingRequest && scase?.subject?.originArea!==null&&scase?.subject?.originArea!==''"
                  key="{{ 'SCHEDULE_CASES.CASES.ORIGIN_AREA' | translate }}" fontSize="mini" valueOffset="150px">
                  {{ scase?.subject?.originArea}}</definition-item>
                <ng-container *ngIf="!scase.teamFormingRequest">
                  <definition-item key="{{ 'SCHEDULE_CASES.CASES.CASE_TYPE' | translate }}" fontSize="mini"
                    valueOffset="150px">
                    {{ scase.caseTitle }}
                  </definition-item>
                </ng-container>
              </definition-list>
              <footer class="open-case__item-footer">
                <ng-container *ngIf="!scase.teamFormingRequest">
                  <button mat-flat-button color="warn" type="button"
                    class="form-footer__button form-footer__button--secondary" (click)="return(scase)">
                    {{ 'SCHEDULE_CASES.CASES.RETURN' | translate }}
                  </button>
                </ng-container>
                <ng-container *ngIf="scase.teamFormingRequest">
                  <button mat-flat-button color="warn" type="button"
                    class="form-footer__button form-footer__button--secondary" (click)="declineTeamReaquest(scase)">
                    {{ 'SCHEDULE_CASES.CASES.DECLINE_TEAM' | translate }}
                  </button>
                </ng-container>
                <button mat-flat-button color="primary" type="button"
                  class="form-footer__button form-footer__button--secondary" (click)="openAssignedCaseDialog(scase)">
                  {{ 'SCHEDULE_CASES.CASES.ASSIGN' | translate }}
                </button>
                  <button mat-flat-button color="primary" type="button"
                  class="form-footer__button form-footer__button--primary" (click)="previewCase(scase)">
                  {{ 'SCHEDULE_CASES.CASES.PREVIEW' | translate }}
                </button>
              </footer>
            </div>
          </collapsible-case>
        </div>
      </div>
    </section>
    <section class="section section--right"  *ngIf="!isLoading">
      <header class="section__header">
        <h2 class="section__title">
          {{ 'SCHEDULE_CASES.WORKERS.TITLE' | translate }}
          <ng-container *ngIf="loggedIn.office !== 'null'"> - {{loggedIn.office}}</ng-container>
        </h2>
        <p>
          {{ 'SCHEDULE_CASES.WORKERS.DESCRIPTION' | translate }}
        </p>
      </header>
      <div *ngIf="workersWithCases.length === 0">
        <p class="section__no-results">
          <mat-icon class="section__no-results-icon">info</mat-icon>
          {{ 'SCHEDULE_CASES.WORKERS.NO_WORKERS' | translate }}
        </p>
      </div>
      <div class="worker__list" *ngIf="workersWithCases.length > 0">
        <div *ngFor="let item of workersGrouped | keyvalue">
          <collapsible-workers-groups [title]="item.key">
            <div class="worker__item" *ngFor="let worker of workersGrouped[item.key]">
              <collapsible-worker (renderCases)="renderWorkerCases($event)" [worker]="worker" [workload]="worker?.workload" [title]="worker?.name" [groupExpanded]="casesRendered.includes(worker.userId)"
                [meta]="worker?.scInfo.length">
                <header class="assigned-cases__header">
                  <h5 class="assigned-cases__title">
                    {{ 'SCHEDULE_CASES.WORKERS.ASSIGNED_CASES_TITLE' | translate }}
                  </h5>
                </header>
                <p *ngIf="worker?.scInfo.length === 0">
                  {{ 'SCHEDULE_CASES.WORKERS.NO_ASSIGNED_CASES' | translate }}
                </p>
                <ul class="assigned-cases__list" *ngIf="worker.scInfo.length > 0">
                  <li class="assigned-cases__item" *ngFor="let scase of worker.scInfo">
                    <div class="assigned-case-item" *ngIf="casesRendered.includes(worker.userId)">
                      <span class="assigned-case-item__workload" matTooltip="{{
                      'SCHEDULE_CASES.WORKERS.WORKLOAD' | translate
                    }}">{{ scase.workload }}</span>
                      <header class="assigned-case-item__header">
                        <span [ngClass]="{
                        'assigned-case-item__kind': true,
                        'assigned-case-item__kind--red': scase.mark === 'C',
                        'assigned-case-item__kind--yellow': scase.mark === 'Ž',
                        'assigned-case-item__kind--white': scase.mark === 'O',
                        'assigned-case-item__kind--blue': scase.mark === 'P',
                        'assigned-case-item__kind--purple': scase.mark === 'LJ',
                        'assigned-case-item__kind--dashedred': scase.mark === 'IC',
                        'assigned-case-item__kind--dashedblue': scase.mark === 'IP'
                      }"></span>
                        <span class="assigned-case-item__case-number" (click)="previewCase(scase)">{{ scase.caseNumber }}</span>
                      </header>
                      <div class="assigned-case-item__time" matTooltip="{{
                      'SCHEDULE_CASES.WORKERS.CASE_DATE_INFO' | translate
                    }}">
                        <time>{{ scase.dateOfCreation }} </time>
                        <mat-icon class="assigned-case-item__arrow-icon">arrow_right_alt</mat-icon>
                        <time *ngIf="scase.dateOfAcceptance">{{ scase.dateOfAcceptance }}</time>
                        <mat-icon *ngIf="!scase.dateOfAcceptance" class="assigned-case-item__close-icon">close
                        </mat-icon>
                      </div>
                      <button mat-icon-button aria-label="Edit" class="assigned-case-item__edit"
                        matTooltip="{{ 'SCHEDULE_CASES.WORKERS.EDIT' | translate }}"
                        (click)="openAssignedCaseDialog(scase)">
                        <mat-icon>mode</mat-icon>
                      </button>
                    </div>
                  </li>
                </ul>
              </collapsible-worker>
            </div>
          </collapsible-workers-groups>
        </div>
      </div>
    </section>
    <section class="section section--right" *ngIf="isLoading">
      <header class="section__header">
        <h2 class="section__title">
          {{ 'SCHEDULE_CASES.WORKERS.TITLE' | translate }}
          <ng-container *ngIf="loggedIn.office !== 'null'"> - {{loggedIn.office}}</ng-container>
        </h2>
        <p>
          {{ 'SCHEDULE_CASES.WORKERS.DESCRIPTION' | translate }}
        </p>
      </header>
        <mat-card style="display: flex; justify-content: center; align-items: center; height: 100vh;">
            <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>
    </section>
    
  </mat-card>
  
</container>