/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { formatDate } from 'src/app/utils/formatDate';

/**
 * NOTE:
 * This document contains encapsulated logic for filtering an array by
 * `createdAt` property. Please refer to <subject-documents> for more information
 * on hot to apply the logic.
 */

// Utils
/**
 * maybeDate — returns date in miliseconds or 0
 * (if empty string is provided)
 * @param value
 */
const maybeDate = (value: Date | string) => {
  if (typeof value === 'string') {
    return 0;
  } else {
    const d = new Date(value);

    return d.getTime();
  }
};

// Types
type ChipType = {
  displayValue: string;
  value: Object | string;
  key: 'minDate' | 'maxDate';
}

type AppliedFilter = {
  displayValue: string,
  value: Object | string,
  key: 'minDate' | 'maxDate',
}

type ApplyFilterByDateInput = {
  filterForm: FormGroup,
  list: { createdAt: Date }[],
  trigger: MatMenuTrigger
}

type RemoveFilterByDateInput = {
  filterForm: FormGroup,
  filter: AppliedFilter,
  appliedFilters: AppliedFilter[],
  list: { createdAt: Date }[],
}

type ResetFilterByDateInput = {
  filterForm: FormGroup,
  trigger: MatMenuTrigger
  list: { createdAt: Date }[],
}

type FilterByDatePayload = {
  appliedFilters: ChipType[] | [],
  filteredList: { createdAt: Date }[] | [],
  badgeCount: number
}

/**
 * filterByDateUtil
 *
 * Apply filter to provided list to the minDate and maxDate form controls.
 * As a result it returns:
 *  - applied filters (used for chip component)
 *  - filtered list
 *  - count of applied filters (used on badge component)
 */
export const filterByDateUtil = ({ filterForm, list, trigger }: ApplyFilterByDateInput): FilterByDatePayload => {
  const minDate = maybeDate(filterForm.get('minDate').value);
  const maxDate = maybeDate(filterForm.get('maxDate').value);

  // Add chip elements
  const appliedFilters = [
    { prefix: 'Od', value: minDate, key: 'minDate' },
    { prefix: 'Do', value: maxDate, key: 'maxDate' },
  ].reduce((acc, val) => {
    if (val.value !== 0) {
      acc.push({
        displayValue: `${val.prefix} ${formatDate(val.value)}`,
        value: val.value,
        key: val.key,
      });
    }

    return acc;
  }, []);

  // Apply filter to list
  const filteredList = list
    .filter((item) =>
      minDate === 0 ? item : (new Date(item.createdAt)).getTime() >= minDate
    )
    .filter((item) =>
      maxDate === 0 ? item : (new Date(item.createdAt)).getTime() <= maxDate
    )
    ;

  // Add badge count
  const badgeCount = appliedFilters.length;
  trigger.closeMenu();

  return {
    appliedFilters: appliedFilters,
    filteredList: filteredList,
    badgeCount: badgeCount,
  }
}

/**
 * removeFilterByDateUtil
 *
 * Updates list and applied filter list along with the updated badge count
 */
export const removeFilterByDateUtil = ({
  filterForm,
  filter,
  appliedFilters,
  list,
}: RemoveFilterByDateInput): FilterByDatePayload => {
  appliedFilters = appliedFilters.filter(
    (item) => item.value !== filter.value
  );
  const key = filter.key;
  filterForm.patchValue({
    [key]: '',
  });

  const badgeCount = appliedFilters.length;

  const minDate = maybeDate(filterForm.get('minDate').value);
  const maxDate = maybeDate(filterForm.get('maxDate').value);

  // Apply filter to list
  const filteredList = list
    .filter((item) =>
      minDate === 0 ? item : maybeDate(item.createdAt) >= minDate
    )
    .filter((item) =>
      maxDate === 0 ? item : maybeDate(item.createdAt) <= maxDate
    );


  return { appliedFilters: appliedFilters, badgeCount, filteredList };
}

/**
 * resetFilterByDateUtil
 *
 * Reset filter state and closes menu component.
 * (it resets badge count, filter form, applied filters and filtered list)
 */
export const resetFilterByDateUtil = ({ filterForm, trigger, list }: ResetFilterByDateInput): FilterByDatePayload => {
    trigger.closeMenu();
    filterForm.patchValue({
      minDate: '',
      maxDate: '',
    });

    return {
      appliedFilters: [],
      filteredList: list,
      badgeCount: 0
    }
}
