<mat-dialog-content>
  <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
          [mat-dialog-close]="true"><span class="material-icons">close</span>
  </button>

  <div class="container">
    <form flex [formGroup]="otherRevenuesForm" (ngSubmit)="add()">
      <h2>{{ 'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.TITLE' | translate }}</h2>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.REVENUE_KIND' | translate }}</mat-label>
          <mat-select required formControlName="revenueKind">
            <mat-option *ngFor="let option of incomeTypes" [value]="option">
              {{option.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.PIO' | translate }}</mat-label>
          <input type="text" matInput formControlName="branchesPioFundCodebook" [matAutocomplete]="autoPioDecision" (focusout)="autoSelectItem()"/>
          <mat-autocomplete #autoPioDecision="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of pioUnitsFiltered | async" [value]="option">
              {{option.branchTypeCodebook.title}} {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.CHECK_NUMBER' | translate}}
          </mat-label>
          <input matInput type="text" formControlName="checkNumber" autocomplete="off" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.START_DATE'| translate}}
          </mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="startDate" autocomplete="off" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.REVENUE_AMOUNT' | translate}}
          </mat-label>
          <input matInput type="text" required formControlName="revenueAmount" (change)="checkAmount()" autocomplete="off" />
        </mat-form-field>

      </field-holder>
      <form-footer>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
                class="form-footer__button form-footer__button--secondary">
          {{ 'GLOBAL.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" type="submit" [disabled]="!otherRevenuesForm.valid"
                class="form-footer__button form-footer__button--primary">
          {{ 'GLOBAL.SAVE' | translate }}
        </button>
      </form-footer>
    </form>
    <br />
  </div>
</mat-dialog-content>
