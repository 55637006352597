<header
  [ngClass]="{
    'page-intro-header': true,
    'page-intro-header--with-back': back
  }"
>
  <div class="page-intro-header__left">
    <a
      (click)="backClicked()"
      class="link link--is-back page-intro-header__link"
      *ngIf="back && !isAdmin"
      ><mat-icon>chevron_left</mat-icon>{{ 'GLOBAL.BACK' | translate }}</a
    >
    <h2 class="page-intro-header__title" *ngIf="title">
      {{ title }}
    </h2>
  </div>
  <div class="page-intro-header__right">
    <ng-content></ng-content>
  </div>
</header>
