<form class="form" [formGroup]="familyMembers" (ngSubmit)="addFamilyMembers()">
  <form-group title="{{ 'FAMILY_MEMBERS.TITLE' | translate }}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "FAMILY_MEMBERS.FIRST_LAST_NAME" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="off" [readonly]="origin!=='entranceNotRegistered' || isEdit" />
        <mat-error *ngIf="familyMembers.get('name').hasError('required')">
          {{ "FAMILY_MEMBERS.FIRST_LAST_NAME_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "FAMILY_MEMBERS.BIRTH_YEAR" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="yearOfBirth" autocomplete="off" maxlength="4" (change)="checkYear()" [readonly]="origin!=='entranceNotRegistered'  || isEdit" />
        <mat-error *ngIf="familyMembers.get('yearOfBirth').hasError('required') || familyMembers.get('yearOfBirth').hasError('minLength')">
          {{ "FAMILY_MEMBERS.BIRTH_YEAR_ERROR" | translate }}
        </mat-error>
        <mat-error *ngIf="familyMembers.get('yearOfBirth').hasError('pattern')">
         {{ "FAMILY_MEMBERS.BIRTH_YEAR_PATTERN" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "FAMILY_MEMBERS.RELATIONSHIP" | translate }}</mat-label>
        <mat-select formControlName="relationship" required [compareWith]="compareRelationship" [disabled]="isEdit">
          <mat-option *ngFor="let relationship of relationshipOptions" [value]="relationship">
            {{ relationship.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="familyMembers.get('relationship').hasError('required')">
          {{ "FAMILY_MEMBERS.RELATIONSHIP_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{ "FAMILY_MEMBERS.QUALIFICATION_EMPLOYMENT" | translate }}</mat-label>
        <input matInput type="text" formControlName="qualificationEmployment" required autocomplete="off" maxlength="60" />
        <mat-error *ngIf="familyMembers.get('qualificationEmployment').hasError('required')">
          {{ "FAMILY_MEMBERS.QUALIFICATION_EMPLOYMENT_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder *ngIf="origin === 'entrance'">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "FAMILY_MEMBERS.REG_NUMBER" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="regNumber" autocomplete="off" readonly/>
      </mat-form-field>
    </field-holder>
    <field-holder *ngIf="origin === 'nsp'">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "FAMILY_MEMBERS.JMBG" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="jmbg" autocomplete="off" readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "FAMILY_MEMBERS.FOR_BILLING" | translate }}
        </mat-label>
        <mat-select formControlName="forBilling" required  [compareWith]="compareYesNo">
          <mat-option *ngFor="let opt of yesNoOptions" [value]="opt.value">
            {{ opt.viewValue | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button mat-dialog-close color="primary" (click)="closeDialog()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ "FAMILY_MEMBERS.CANCEL" | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
      [disabled]="members.length === 0 && !familyMembers.valid">
      {{ "FAMILY_MEMBERS.ADD" | translate }}
    </button>
  </form-footer>
</form>