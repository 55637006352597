/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { checkRelationshipAgeDifference } from 'src/app/utils/checkRelationshipAgeDifference';
import { Subject } from 'src/models/subject.model';
import { CodebookService } from 'src/services/codebook.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { EventEnum, FamilyMember } from '../types';
import * as myLetters from './../../letters';

@Component({
   selector: 'app-family-members',
   templateUrl: './family-members.component.html',
   styleUrls: ['./family-members.component.scss'],
})
export class FamilyMembersComponent implements OnInit {
   familyMembers: FormGroup;
   members: Array<FamilyMember> = [];
   origin: any;
   relationshipOptions = [];
   earliestYearOfBirth = 0;
   latestYearOfBirth = 0;
   isEdit: any;
   yesNoOptions = [
      { value: true, viewValue: 'GLOBAL.YES' },
      { value: false, viewValue: 'GLOBAL.NO' },
   ];
   constructor(
      public dialog: MatDialog,
      public subjectsService: SubjectsService,
      public dialogRef: MatDialogRef<FamilyMembersComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public familyData: any,
      private formBuilder: FormBuilder,
      private toastr: ToastrImplService,
      private codebook: CodebookService
   ) {
      if (familyData.origin === 'nsp') {
         this.origin = 'nsp';
      } else if (familyData.origin === 'entrance') {
         this.origin = 'entrance';
      } else if (familyData.origin === 'VS.INITIAL_ASSESSMENT.FORMS23') {
         this.origin = 'VS.INITIAL_ASSESSMENT.FORMS23';
      } else if (familyData.origin === 'entranceNotRegistered') {
         this.origin = 'entranceNotRegistered';
      } else if (familyData.origin === 'entranceViolence') {
         this.origin = 'entranceViolence';
      } else if (familyData.origin === 'entranceViolenceNotRegistered') {
         this.origin = 'entranceViolenceNotRegistered';
      }

      this.isEdit = this.familyData?.isEdit;

      this.codebook.getRelationshipCodebookWithoutUser().subscribe(result => {
         this.relationshipOptions = result;
      });

      let relationship = '';
      if (this.origin === 'nsp' && this.isEdit) {
         relationship = this.familyData?.data?.relationship;
      }
      this.familyMembers = this.formBuilder.group({
         name: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
         relationship: [relationship, [Validators.required]],
         yearOfBirth: ['', [Validators.maxLength(4), Validators.pattern('[0-9]+')]],
         qualificationEmployment: ['', [Validators.required]],
         ableToWork: ['', []],
         disabledPerson: ['', []],
         regNumber: ['', []],
         jmbg: [''],
         forBilling: [null],
         dateOfBirth: [''],
         foreigner: [false],
      });

      if (this.origin === 'nsp' && this.isEdit) {
         this.familyMembers.controls.forBilling.setValue(this.familyData.data.forBilling);
      }
      const currentDate = new Date();
      this.latestYearOfBirth = currentDate.getFullYear();
      currentDate.setFullYear(currentDate.getFullYear() - 120);
      this.earliestYearOfBirth = currentDate.getFullYear();
   }

   closeDialog() {
      this.dialogRef.close({ event: EventEnum.CANCEL });
   }

   ngOnInit() {
      if (this.familyData.data) {
         this.patchForm(this.familyData.data);
      }
   }

   /**
    * Update form afer getting the subject data (on init, or after adding new user)
    */
   patchForm(data: Partial<Subject>) {
      const { firstName, lastName, registrationId, yearOfBirth, jmbg, occupation, ableToWork, disabledPerson, dateOfBirth, foreigner } = data;
      // Patch form with retrived data
      this.familyMembers.patchValue({
         name: `${firstName} ${lastName}`,
         yearOfBirth,
         qualificationEmployment: occupation != null && occupation.title ? occupation.title : occupation,
         ableToWork: ableToWork !== null ? ableToWork : null,
         regNumber: registrationId,
         jmbg,
         disabledPerson: disabledPerson !== null ? disabledPerson : null,
         dateOfBirth,
         foreigner,
      });
   }

   // Submit
   addFamilyMembers() {
      if (this.familyMembers.value.relationship !== null) {
         if (!checkRelationshipAgeDifference(this.familyMembers.value, this.familyData.subject, this.familyMembers.value.relationship.id, this.toastr)) {
            this.dialogRef.close({ event: EventEnum.CANCEL });
            return;
         }
      }
      if (this.familyMembers.value.firstLastName !== null) {
         const member = this.familyMembers.value;
         if (this.origin !== 'entranceNotRegistered') {
            member.subjectIdentity = this.familyData.data.subjectIdentity;
         }
         member.jmbp = this.familyData?.data?.jmbp;
         this.members = [...this.members, member];
      }
      this.dialogRef.close({ event: EventEnum.SUBMIT, data: this.members });
   }

   checkYear() {
      if (this.familyMembers.value.yearOfBirth !== '') {
         if (!isNaN(parseFloat(this.familyMembers.value.yearOfBirth)) && isFinite(this.familyMembers.value.yearOfBirth)) {
            if (Number(this.familyMembers.value.yearOfBirth) > this.latestYearOfBirth) {
               this.familyMembers.controls.yearOfBirth.setErrors({ required: true });
               this.toastr.warning('FAMILY_MEMBERS.WARNING_DATE_BIRTH');
            } else if (Number(this.familyMembers.value.yearOfBirth) < this.earliestYearOfBirth) {
               this.toastr.showWarning('Члан има ' + (this.latestYearOfBirth - this.familyMembers.value.yearOfBirth) + ' година');
            }
         }
      }
   }

   compareRelationship(object1: any, object2: any) {
      return object1.id === object2.id;
   }
   compareYesNo(object1: any, object2: any) {
      return object1.value === object2.value;
   }
}
