<container kind="default">
    <page-intro-header back="/foster-parent" title="{{ 'FOSTER_PARENT.T' | translate }}">
    </page-intro-header>
</container>
<container kind="default">
    <mat-card class="card">
        <definition-list>
            <definition-item *ngIf="fosterParent?.ordinalNumber" key="{{ 'PROTEGE_DETAILS.R_BR' | translate }}">
                {{ fosterParent.ordinalNumber }}</definition-item>
            <definition-item *ngIf="fosterParent?.subjectId" key="{{ 'PROTEGE_DETAILS.ID' | translate }}">
                {{ fosterParent.subjectId }}</definition-item>

            <definition-item *ngIf="fosterParent?.firstName"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FIRST_NAME' | translate }}">
                {{ fosterParent.firstName }}</definition-item>


            <definition-item *ngIf="fosterParent?.lastName" key="{{ 'NEW_FOSTER_PARENT.FORM.LAST_NAME' | translate }}">
                {{ fosterParent.lastName }}</definition-item>

            <definition-item *ngIf="fosterParent?.gender" key="{{ 'NEW_FOSTER_PARENT.FORM.GENDER' | translate }}">
                {{ fosterParent.gender.gender }}</definition-item>

            <definition-item *ngIf="fosterParent?.dateOfBirth"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DATE_OF_BIRTH' | translate }}">
                {{ fosterParent.dateOfBirth }}</definition-item>

            <definition-item *ngIf="fosterParent?.placeOfBirth"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ fosterParent.placeOfBirth }}</definition-item>

            <definition-item *ngIf="fosterParent?.jmbg" key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ fosterParent.jmbg }}</definition-item>

            <definition-item *ngIf="fosterParent?.nationality"
                key="{{ 'NEW_FOSTER_PARENT.FORM.NATIONALITY' | translate }}">
                {{ fosterParent.nationality.title }}</definition-item>

            <definition-item *ngIf="fosterParent?.citizenship"
                key="{{ 'NEW_FOSTER_PARENT.FORM.CITIZENSHIP' | translate }}">
                {{ fosterParent.citizenship.title }}</definition-item>

            <definition-item *ngIf="fosterParent?.occupation"
                key="{{ 'NEW_FOSTER_PARENT.FORM.OCCUPATION' | translate }}">
                {{ fosterParent.occupation.title }}</definition-item>

            <definition-item *ngIf="fosterParent?.employment"
                key="{{ 'NEW_FOSTER_PARENT.FORM.WORK_STATUS' | translate }}">
                {{ fosterParent.employment.title }}</definition-item>

            <definition-item *ngIf="fosterParent?.education" key="{{ 'NEW_FOSTER_PARENT.FORM.EDUCATION' | translate }}">
                {{ fosterParent.education.title }}</definition-item>

            <definition-item *ngIf="fosterParent?.permanentResidence"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PLACE_OF_ORIGIN' | translate }}">
                {{ fosterParent.permanentResidence }}</definition-item>

            <definition-item *ngIf="fosterParent?.maritalStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.MARITAL_STATUS' | translate }}">
                {{ fosterParent.maritalStatus.title }}</definition-item>

            <definition-item *ngIf="fosterParent?.healthStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.HEALTH_STATUS' | translate }}">
                {{ fosterParent.healthStatus }}</definition-item>

            <h2>{{
          'NEW_FOSTER_PARENT.FAMILY_INFORMATION.TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="fosterParent?.residentialStatus"
                key="{{ 'NEW_FOSTER_PARENT.FORM.HOUSEHOLD_STATUS' | translate }}">
                {{ fosterParent.residentialStatus.name }}</definition-item>

            <definition-item *ngIf="fosterParent?.incomeFromEmployment"
                key="{{ 'NEW_FOSTER_PARENT.FORM.EMPLOYMENT' | translate }}">
                {{ fosterParent.incomeFromEmployment }}</definition-item>

            <definition-item *ngIf="fosterParent?.incomeFromPrivateActivity"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PRIVATE_ACTIVITY' | translate }}">
                {{ fosterParent.incomeFromPrivateActivity }}</definition-item>

            <definition-item *ngIf="fosterParent?.otherIncome" key="{{ 'NEW_FOSTER_PARENT.FORM.OTHER' | translate }}">
                {{ fosterParent.otherIncome }}</definition-item>

            <definition-item *ngIf="fosterParent?.pension" key="{{ 'NEW_FOSTER_PARENT.FORM.PENSION' | translate }}">
                {{ fosterParent.pension }}</definition-item>


            <definition-item *ngIf="fosterParent?.averageMonthlyIncome"
                key="{{ 'NEW_FOSTER_PARENT.FORM.MONTHLY_INCOME_UPPER' | translate }}">
                {{ fosterParent.averageMonthlyIncome }}</definition-item>
            <definition-item *ngIf="fosterParent?.averageMonthlyIncomePerHousehold"
                key="{{ 'NEW_FOSTER_PARENT.FORM.MONTHLY_INCOME_PER_HOUSEHOLD_MEMBER_UPPER' | translate }}">
                {{ fosterParent.averageMonthlyIncomePerHousehold }}</definition-item>
            <definition-item *ngIf="fosterParent?.numberOfFamilyMembers"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FAMILY_MEMBER_COUNT' | translate }}">
                {{ fosterParent.numberOfFamilyMembers }}</definition-item>

            <definition-item *ngIf="fosterParent?.numberOfFamilyChildren"
                key="{{ 'NEW_FOSTER_PARENT.FORM.CHILDREN_COUNT' | translate }}">
                {{ fosterParent.numberOfFamilyChildren }}</definition-item>

            <definition-item *ngIf="fosterParent?.maxChildren"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_TO_ACCEPT_COUNT' | translate }}">
                {{ fosterParent.maxChildren }}</definition-item>

            <definition-item *ngIf="fosterParent?.childGender.length != 0"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_GENDER' | translate }}">
                <ul>
                    <li *ngFor="let p of fosterParent?.childGender">{{p.gender}}</li>
                </ul>
            </definition-item>

            <definition-item *ngIf="fosterParent?.ageOfChild.length != 0"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_AGE_TO_ACCEPT' | translate }}">
                <ul>
                    <li *ngFor="let p of fosterParent?.ageOfChild">{{p.title}}</li>
                </ul>
            </definition-item>

            <definition-item *ngIf="fosterParent?.childOfAnotherNationality !== null"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_NATIONALITY_TO_ACCEPT' | translate }}">
                {{ (fosterParent?.childOfAnotherNationality ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}
            </definition-item>

            <definition-item *ngIf="fosterParent?.childWithHealthDisorders !== null"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_WITH_SPECIAL_NEEDS' | translate }}">
                {{ (fosterParent?.childWithHealthDisorders ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}
            </definition-item>

            <definition-item *ngIf="fosterParent?.childWithBehavioralDisorder !== null"
                key="{{ 'NEW_FOSTER_PARENT.FORM.FOSTER_CHILDREN_WITH_BEHAVIOUR_DISORDER' | translate }}">
                {{ (fosterParent?.childWithBehavioralDisorder ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}
            </definition-item>

            <h2>{{
          'NEW_FOSTER_PARENT.SUITABILITY.TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.psychologistDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PSYCHOLOGIST' | translate }}">
                {{ fosterParent.professionalOpinion.psychologistDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.pedagogueDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PEDAGOGUE' | translate }}">
                {{ fosterParent.professionalOpinion.pedagogueDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.lawyerDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.LAWYER' | translate }}">
                {{ fosterParent.professionalOpinion.lawyerDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.doctorDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DOCTOR' | translate }}">
                {{ fosterParent.professionalOpinion.doctorDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.socialWorkerDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.SOCIAL_WORKER' | translate }}">
                {{ fosterParent.professionalOpinion.socialWorkerDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.institutionDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.OTHER_INSTITUTIONS' | translate }}">
                {{ fosterParent.professionalOpinion.institutionDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.numberOfDecision"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DECISION_NUMBER' | translate }}">
                {{ fosterParent.professionalOpinion.numberOfDecision }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.decisionDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.DECISION_DATE' | translate }}">
                {{ fosterParent.professionalOpinion.decisionDate | date: 'dd.MM.yyyy.' }}</definition-item>

            <h2 *ngIf="fosterParent?.professionalOpinion">{{
          'NEW_FOSTER_PARENT.CENTER.GUARDIANSHIP_AUTHORITY' | translate
        }}
            </h2>
            <definition-item *ngIf="fosterParent?.professionalOpinion?.guardianshipName"
                key="{{ 'NEW_FOSTER_PARENT.FORM.GUARDIANSHIP_NAME' | translate }}">
                {{ fosterParent.professionalOpinion.guardianshipName }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.guardianshipPhone"
                key="{{ 'NEW_FOSTER_PARENT.FORM.PHONE' | translate }}">
                {{ fosterParent.professionalOpinion.guardianshipPhone }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.guardianshipEmail"
                key="{{ 'NEW_FOSTER_PARENT.FORM.EMAIL_ADDRESS' | translate }}">
                {{ fosterParent.professionalOpinion.guardianshipEmail }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.guardianshipAddress"
                key="{{ 'NEW_FOSTER_PARENT.FORM.ADDRESS' | translate }}">
                {{ fosterParent.professionalOpinion.guardianshipAddress }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.questionnaireNumber"
                key="{{ 'NEW_FOSTER_PARENT.FORM.QUESTIONNAIRE_NUMBER' | translate }}">
                {{ fosterParent.professionalOpinion.questionnaireNumber }}</definition-item>

            <definition-item *ngIf="fosterParent?.professionalOpinion?.questionnaireDate"
                key="{{ 'NEW_FOSTER_PARENT.FORM.QUESTIONNAIRE_DATE' | translate }}">
                {{ fosterParent.professionalOpinion.questionnaireDate | date: 'dd.MM.yyyy.' }}</definition-item>


        </definition-list>

    </mat-card>
</container>