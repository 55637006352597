<container class="wrapper">
  <mat-accordion>
    <nsp-admin [panelOpenState]="panelOpenState"></nsp-admin>
    <dpn-admin [panelOpenState]="panelOpenState"></dpn-admin>
    <mat-expansion-panel (opened)="panelOpenState" (closed)="!panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "GLOBAL_ADMIN.ACCOMMODATION_FOSTER.TITLE" | translate }}
        </mat-panel-title>
        <mat-panel-description>
          {{ "GLOBAL_ADMIN.ACCOMMODATION_FOSTER.DETAILS" | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="accommodationFosterForm" class="form">

        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{'GLOBAL_ADMIN.ACCOMMODATION_FOSTER.CATEGORY' | translate }}
            </mat-label>
            <mat-select formControlName="choiceAccomodation" [compareWith]="compareObj" required
              (selectionChange)="selectAccomodation($event.value)">
              <mat-option *ngFor="let option of accomodationData" [value]="option">
                {{option.category}} {{option.subCategory}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>

        <h4 *ngIf="currentAccomodation.id!==''">{{ "GLOBAL_ADMIN.ACCOMMODATION_FOSTER.CATEGORY" | translate }}
          {{ currentAccomodation.category }}</h4>
        <h4 *ngIf="currentAccomodation.id!==''">{{ "GLOBAL_ADMIN.ACCOMMODATION_FOSTER.SUBCATEGORY" | translate }}
          {{ currentAccomodation.subCategory }}</h4>
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'GLOBAL_ADMIN.ACCOMMODATION_FOSTER.MANAGE.NEW_MOUNTHLY_AMOUNT' | translate
              }}</mat-label>
            <input matInput type="text"
              (change)="checkAccommodationAmounts(accommodationFosterForm.controls.monthlyAmount)"
              formControlName="monthlyAmount" autocomplete="off" />
            <span matSuffix>{{ 'GLOBAL_ADMIN.FORM.RSD' | translate }}</span>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'GLOBAL_ADMIN.ACCOMMODATION_FOSTER.MANAGE.NEW_DAILY_AMOUNT' | translate
              }}</mat-label>
            <input matInput type="text"
              (change)="checkAccommodationAmounts(accommodationFosterForm.controls.dailyAmount)"
              formControlName="dailyAmount" autocomplete="off" />
            <span matSuffix>{{ 'GLOBAL_ADMIN.FORM.RSD' | translate }}</span>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="picker" formControlName="validFrom" required autocomplete="off"
              placeholder="{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </field-holder>

        <form-footer>
          <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
            (click)="submitAccomodationForm(accommodationFosterForm)" [disabled]="!accommodationFosterForm.valid">
            {{ 'GLOBAL_ADMIN.FORM.SUBMIT' | translate }}
          </button>
        </form-footer>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState" (closed)="!panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "GLOBAL_ADMIN.ACCOMMODATION_FACILITY.TITLE" | translate }}
        </mat-panel-title>
        <mat-panel-description>
          {{ "GLOBAL_ADMIN.ACCOMMODATION_FACILITY.DETAILS" | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <app-facility-pricing></app-facility-pricing>

    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState" (closed)="!panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "GLOBAL_ADMIN.CIPHERS.TITLE" | translate }}
        </mat-panel-title>
        <mat-panel-description>
          {{ "GLOBAL_ADMIN.CIPHERS.DETAILS" | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="cipherCitinzenshipForm" class="form">
        <form-group title="{{ 'GLOBAL_ADMIN.CIPHERS.CITIZENSHIP' | translate }}">
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'GLOBAL_ADMIN.FORM.CIPHER_OPTION' | translate }}</mat-label>
              <mat-select formControlName="activeOption" (selectionChange)="showManageButton('citinzenship')">
                <mat-option *ngFor="let option of cipherCitinzenshipData" [value]="option"> {{ option.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isManageButtonVisible.citinzenship">
              <button mat-stroked-button color="primary" class="mt-18"
                (click)="manageCipher('edit', 'citinzenship', cipherCitinzenshipForm.get('activeOption').value)">
                {{ 'GLOBAL_ADMIN.FORM.MANAGE' | translate }}
              </button>
            </div>
          </field-holder>
          <button mat-flat-button color="primary" (click)="manageCipher('new', 'citinzenship', null)">
            {{ 'GLOBAL_ADMIN.FORM.ADD_NEW_CIPHER_OPTION' | translate }}
          </button>
        </form-group>
      </form>
      <form [formGroup]="cipherNationalityForm" class="form">
        <form-group title="{{ 'GLOBAL_ADMIN.CIPHERS.NATIONALITY' | translate }}">
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'GLOBAL_ADMIN.FORM.CIPHER_OPTION' | translate }}</mat-label>
              <mat-select formControlName="activeOption" (selectionChange)="showManageButton('nationality')">
                <mat-option *ngFor="let option of cipherNationalityData" [value]="option"> {{ option.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isManageButtonVisible.nationality">
              <button mat-stroked-button color="primary" class="mt-18"
                (click)="manageCipher('edit', 'nationality', cipherNationalityForm.get('activeOption').value)">
                {{ 'GLOBAL_ADMIN.FORM.MANAGE' | translate }}
              </button>
            </div>
          </field-holder>
          <button mat-flat-button color="primary" (click)="manageCipher('new', 'nationality', null)">
            {{ 'GLOBAL_ADMIN.FORM.ADD_NEW_CIPHER_OPTION' | translate }}
          </button>
        </form-group>
      </form>
      <form [formGroup]="cipherBankForm" class="form">
        <form-group title="{{ 'GLOBAL_ADMIN.CIPHERS.BANKS' | translate }}" isLast="true">
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'GLOBAL_ADMIN.FORM.CIPHER_OPTION' | translate }}</mat-label>
              <mat-select formControlName="activeOption" (selectionChange)="showManageButton('bank')">
                <mat-option *ngFor="let option of cipherBankData" [value]="option"> {{option.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="button-container">
              <button *ngIf="isManageButtonVisible.bank" mat-stroked-button color="primary" class="mt-18"
                (click)="manageCipher('edit', 'bank', cipherBankForm.get('activeOption').value)">
                {{ 'GLOBAL_ADMIN.FORM.MANAGE' | translate }}
              </button>
              <button *ngIf="isManageButtonVisible.bank && cipherBankForm.get('activeOption').value.active == true" 
                mat-stroked-button class="mt-18 active-button red-button"
                (click)="updateBankStatus(cipherBankForm.get('activeOption').value.id, false)">
                 {{ 'GLOBAL_ADMIN.CIPHERS.MANAGE.BANK_DEACTIVATE' | translate }}
              </button>
              <button *ngIf="isManageButtonVisible.bank && cipherBankForm.get('activeOption').value.active == false" 
                mat-stroked-button class="mt-18 active-button green-button"
                (click)="updateBankStatus(cipherBankForm.get('activeOption').value.id, true)">
                 {{ 'GLOBAL_ADMIN.CIPHERS.MANAGE.BANK_ACTIVATE' | translate }}
              </button>
            </div>
          </field-holder>
          <button mat-flat-button color="primary" (click)="manageCipher('new', 'bank', null)">
            {{ 'GLOBAL_ADMIN.FORM.ADD_NEW_CIPHER_OPTION' | translate }}
          </button>
        </form-group>
      </form>

    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState" (closed)="!panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "GLOBAL_ADMIN.POCKET_MONEY_AMOUNT.TITLE" | translate }}
        </mat-panel-title>

      </mat-expansion-panel-header>
      <app-pocket-money-amount></app-pocket-money-amount>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState" (closed)="!panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "GLOBAL_ADMIN.POCKET_MONEY_PERCENTAGE.TITLE" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-pocket-money-percentage></app-pocket-money-percentage>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState" (closed)="!panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "GLOBAL_ADMIN.RIGHTS_SERVICES_AND_PROCEDURE.TITLE" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="rightCodebookForm" class="form">
        <form-group title="{{ 'GLOBAL_ADMIN.RIGHTS_SERVICES_AND_PROCEDURE.RIGHTS' | translate }}">
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'GLOBAL_ADMIN.FORM.CIPHER_OPTION' | translate }}</mat-label>
              <mat-select formControlName="activeOption" (selectionChange)="showManageButton('rights')">
                <mat-option *ngFor="let option of rightsData" [value]="option"> {{ option.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isManageButtonVisible.rights">
              <button mat-stroked-button color="primary" class="mt-18"
                (click)="manageCipher('edit', 'rightsCodebook', rightCodebookForm.get('activeOption').value)">
                {{ 'GLOBAL_ADMIN.FORM.MANAGE' | translate }}
              </button>
            </div>
          </field-holder>
          <button mat-flat-button color="primary" (click)="manageCipher('new', 'rightsCodebook', null)">
            {{ 'GLOBAL_ADMIN.FORM.ADD_NEW_CIPHER_OPTION' | translate }}
          </button>
        </form-group>
      </form>

      <form [formGroup]="servicesForm" class="form">
        <form-group title="{{ 'GLOBAL_ADMIN.RIGHTS_SERVICES_AND_PROCEDURE.SERVICES' | translate }}">
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'GLOBAL_ADMIN.FORM.CIPHER_OPTION' | translate }}</mat-label>
              <mat-select formControlName="activeOption" (selectionChange)="showManageButton('services')">
                <mat-option *ngFor="let option of servicesData" [value]="option"> {{ option.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isManageButtonVisible.services">
              <button mat-stroked-button color="primary" class="mt-18"
                (click)="manageCipher('edit', 'services', servicesForm.get('activeOption').value)">
                {{ 'GLOBAL_ADMIN.FORM.MANAGE' | translate }}
              </button>
            </div>
          </field-holder>
          <button mat-flat-button color="primary" (click)="manageCipher('new', 'services', null)">
            {{ 'GLOBAL_ADMIN.FORM.ADD_NEW_CIPHER_OPTION' | translate }}
          </button>
        </form-group>
      </form>
      <form [formGroup]="legalProcedureForm" class="form">
        <form-group title="{{ 'GLOBAL_ADMIN.RIGHTS_SERVICES_AND_PROCEDURE.LEGAL_PROCEDURE' | translate }}">
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'GLOBAL_ADMIN.FORM.CIPHER_OPTION' | translate }}</mat-label>
              <mat-select formControlName="activeOption" (selectionChange)="showManageButton('legalProcedure')">
                <mat-option *ngFor="let option of legalProcedureData" [value]="option"> {{ option.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isManageButtonVisible.legalProcedure">
              <button mat-stroked-button color="primary" class="mt-18"
                (click)="manageCipher('edit', 'legalProcedure', legalProcedureForm.get('activeOption').value)">
                {{ 'GLOBAL_ADMIN.FORM.MANAGE' | translate }}
              </button>
            </div>
          </field-holder>
          <button mat-flat-button color="primary" (click)="manageCipher('new', 'legalProcedure', null)">
            {{ 'GLOBAL_ADMIN.FORM.ADD_NEW_CIPHER_OPTION' | translate }}
          </button>
        </form-group>
      </form>
      <form [formGroup]="profesionalProcedureForm" class="form">
        <form-group title="{{ 'GLOBAL_ADMIN.RIGHTS_SERVICES_AND_PROCEDURE.PROFESIONAL_PROCEDURE' | translate }}"
          isLast="true">
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'GLOBAL_ADMIN.FORM.CIPHER_OPTION' | translate }}</mat-label>
              <mat-select formControlName="activeOption" (selectionChange)="showManageButton('professionalProcedure')">
                <mat-option *ngFor="let option of professionalProcedureData" [value]="option"> {{ option.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isManageButtonVisible.professionalProcedure">
              <button mat-stroked-button color="primary" class="mt-18"
                (click)="manageCipher('edit', 'professionalProcedure', profesionalProcedureForm.get('activeOption').value)">
                {{ 'GLOBAL_ADMIN.FORM.MANAGE' | translate }}
              </button>
            </div>
          </field-holder>
          <button mat-flat-button color="primary" (click)="manageCipher('new', 'professionalProcedure', null)">
            {{ 'GLOBAL_ADMIN.FORM.ADD_NEW_CIPHER_OPTION' | translate }}
          </button>
        </form-group>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</container>