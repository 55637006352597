/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ageGroupData } from 'src/app/utils/ageGroupData';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';

enum AutocompleteFilterEnum {
   CENTERS = 'centers',
   REQUESTS_AND_PROBLEMS = 'requestsAndProblems',
   SERVICES = 'services',
   LEGAL_PROCEDURES = 'legalProcedures',
   PROFESSIONAL_PROCEDURES = 'professionalProcedures',
   RIGHTS = 'rights',
}
@Component({
   selector: 'basic-stats',
   templateUrl: './basic-stats.component.html',
   styleUrls: ['./basic-stats.component.scss'],
})
export class BasicStatsComponent implements OnInit, AfterViewInit {
   basicStatsForm: FormGroup;
   shouldShowAgeGroupRange = false;
   ageGroup = ageGroupData;

   period = [
      { id: 1, name: 'За годину' },
      { id: 2, name: 'Слободан унос периода' },
   ];
   maxSelectionLimit = 10;
   numSelected = 0;
   numSelectedOverflow = false;
   gender = [];
   requestsAndProblems = [];
   services = [];
   legalProcedures = [];
   professionalProcedures = [];
   rights = [];
   requestsAndProblemsFiltered = [];
   servicesFiltered = [];
   legalProceduresFiltered = [];
   professionalProceduresFiltered = [];
   rightsFiltered = [];
   centers = [];
   filteredAllCenters: Observable<any>;
   loggedIn: any;
   isMainCenterBelgrade = false;
   isUserDrugostepeni = false;
   btnDisabled: boolean;
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private statisticsService: StatisticsService,
      private localStorage: LocalStorageService,
      private translate: TranslateService,
      private cdr: ChangeDetectorRef,
      private userService: UserService,
      private toastr: ToastrImplService
   ) {
      this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.basicStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.basicStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
   }
   ngAfterViewInit(): void {
      this.cdr.detectChanges();
   }
   ngOnInit(): void {
      this.resetForm();

      this.dynamicFormUpdate();

      this.codebookService.getGenderCodebook().subscribe(result => {
         this.gender = result;
      });

      this.codebookService.getProblemsCodebook().subscribe(res => {
         this.requestsAndProblems = res;
         this.requestsAndProblems.sort((a, b) => a.title.localeCompare(b.title));
         this.requestsAndProblemsFiltered = this.requestsAndProblems;
      });

      this.codebookService.getServicesCodebook().subscribe(res => {
         this.services = res;
         this.services.sort((a, b) => a.title.localeCompare(b.title));
         this.servicesFiltered = this.services;
      });

      this.codebookService.getLegalProceduresCodebook().subscribe(res => {
         this.legalProcedures = res;
         this.legalProcedures.sort((a, b) => a.title.localeCompare(b.title));
         this.legalProceduresFiltered = this.legalProcedures;
      });

      this.codebookService.getProfessionalProceduresCodebook().subscribe(res => {
         this.professionalProcedures = res;
         this.professionalProcedures.sort((a, b) => a.title.localeCompare(b.title));
         this.professionalProceduresFiltered = this.professionalProcedures;
      });

      this.codebookService.getRightsCodebook().subscribe(res => {
         this.rights = res;
         this.rights.sort((a, b) => a.title.localeCompare(b.title));
         this.rightsFiltered = this.rights;
      });
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.basicStatsForm);
   }
   dynamicFormUpdate() {
      this.basicStatsForm.get('ageGroup').valueChanges.subscribe(ageArray => {
         if (ageArray.some(age => age.id === 6)) {
            this.shouldShowAgeGroupRange = true;
            this.basicStatsForm.get('customAgeGroupFrom').setValidators(Validators.required);
            this.basicStatsForm.get('customAgeGroupTo').setValidators(Validators.required);
         } else {
            this.shouldShowAgeGroupRange = false;
            this.basicStatsForm.get('customAgeGroupFrom').clearValidators();
            this.basicStatsForm.get('customAgeGroupFrom').setValue(null);
            this.basicStatsForm.get('customAgeGroupTo').clearValidators();
            this.basicStatsForm.get('customAgeGroupTo').setValue(null);
         }
      });
      this.basicStatsForm.get('period').valueChanges.subscribe(res => {
         if (res.id === 1) {
            this.basicStatsForm.get('dateFrom').setValue('');
            this.basicStatsForm.get('dateTo').setValue('');
            this.basicStatsForm.get('dateFrom').setErrors(null);
            this.basicStatsForm.get('dateTo').setErrors(null);
         } else {
            this.basicStatsForm.get('selectedYear').setValue(null);
            this.basicStatsForm.get('selectedYear').setErrors(null);
         }
      });
   }

   tosslePerOne(field: any, id: any, selected: boolean) {
      if (selected) {
         this.numSelected++;
         if (this.numSelected > this.maxSelectionLimit) {
            this.basicStatsForm.get(field).setErrors({ maxSelectionOverflow: true });
            let message: string;
            this.translate.get('STATISTICS.BASIC_STATS.OVERLIMIT').subscribe((res: string) => {
               message = res;
            });
            this.toastr.showError(message + this.maxSelectionLimit);
            this.numSelectedOverflow = true;
         }
      } else {
         this.numSelected--;
         if (this.numSelectedOverflow && this.numSelected <= this.maxSelectionLimit) {
            this.basicStatsForm.controls.requestsAndProblems.setErrors(null);
            this.basicStatsForm.controls.services.setErrors(null);
            this.basicStatsForm.controls.legalProcedures.setErrors(null);
            this.basicStatsForm.controls.professionalProcedures.setErrors(null);
            this.basicStatsForm.controls.rights.setErrors(null);
            this.numSelectedOverflow = false;
         }
      }
   }

   compareObjects(object1: any, object2: any) {
      return object1.id === object2;
   }
   compareObjectsId(object1: any, object2: any) {
      return object1 != null && object2 != null && object1.id === object2.id;
   }
   /**
    * Send request and generate Excell report
    */
   sendRequest(form: any) {
      if (!this.basicStatsForm.valid) {
         return;
      }
      let ageGroups: { id: number; name: string; from: number; to: number }[] = [];

      ageGroups = form.value.ageGroup === null || form.value.ageGroup.length === 0 ? null : form.value.ageGroup;
      const gender = form.value.gender === null || form.value.gender === undefined || form.value.gender.length === 0 ? null : form.value.gender;
      let caseProblems = form.value.requestsAndProblems === null || form.value.requestsAndProblems.length === 0 ? null : form.value.requestsAndProblems;

      let services = form.value.services === null || form.value.services.length === 0 ? null : form.value.services;

      let legalProcedures = form.value.legalProcedures === null || form.value.legalProcedures.length === 0 ? null : form.value.legalProcedures;

      let professionalProcedures =
         form.value.professionalProcedures === null || form.value.professionalProcedures.length === 0 ? null : form.value.professionalProcedures;

      let rights = form.value.rights === null || form.value.rights.length === 0 ? null : form.value.rights;

      const customAgeFrom = form.value.customAgeGroupFrom === null || form.value.customAgeGroupFrom.length === 0 ? null : form.value.customAgeGroupFrom;
      const customAgeTo = form.value.customAgeGroupTo === null || form.value.customAgeGroupTo.length === 0 ? null : form.value.customAgeGroupTo;
      if (ageGroups != null) {
         const indexToUpdate = ageGroups.findIndex(group => group.id === 6);
         if (indexToUpdate != -1) {
            const newAgeGroup = {
               id: 6,
               name: 'Слободан унос опсега година (' + customAgeFrom + ' - ' + customAgeTo + ')',
               from: customAgeFrom,
               to: customAgeTo,
            };
            ageGroups[indexToUpdate] = newAgeGroup;
         }
      }
      let csrId = this.loggedIn.csrId;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         csrId = form.value.center.id;
      }

      const submitDto = {
         csrId,
         selectedYear: form.value.selectedYear,
         ageGroups,
         gender,
         caseProblems,
         services,
         legalProcedures,
         professionalProcedures,
         rights,
         dateFrom: form.value.dateFrom,
         dateTo: form.value.dateTo,
         crossData: form.value.crossData,
      };

      let filename = '';
      this.translate.get('STATISTICS.BASIC_STATS.TITLE').subscribe((res: string) => {
         filename = res;
      });
      this.statisticsService.getBasicStatistics(submitDto, filename);
   }

   checkCustomYears() {
      if (this.basicStatsForm.controls.customAgeGroupTo.touched && this.basicStatsForm.controls.customAgeGroupFrom.touched) {
         if (
            this.basicStatsForm.value.customAgeGroupTo !== null &&
            this.basicStatsForm.value.customAgeGroupTo !== '' &&
            this.basicStatsForm.value.customAgeGroupFrom !== null &&
            this.basicStatsForm.value.customAgeGroupFrom !== ''
         ) {
            if (this.basicStatsForm.value.customAgeGroupTo < this.basicStatsForm.value.customAgeGroupFrom) {
               this.basicStatsForm.controls.customAgeGroupTo.setErrors({
                  incorect: true,
               });
            } else {
               this.basicStatsForm.controls.customAgeGroupTo.setErrors(null);
            }
         }
      }
      if (this.basicStatsForm.value.customAgeGroupTo !== null && this.basicStatsForm.value.customAgeGroupTo !== '') {
         if (!this.basicStatsForm.controls.customAgeGroupTo.hasError('incorect') && Number(this.basicStatsForm.value.customAgeGroupTo) > 150) {
            this.basicStatsForm.controls.customAgeGroupTo.setErrors({
               outOfRange: true,
            });
         } else if (!this.basicStatsForm.controls.customAgeGroupTo.hasError('incorect')) {
            this.basicStatsForm.controls.customAgeGroupTo.setErrors(null);
         }
      }
      if (this.basicStatsForm.value.customAgeGroupFrom !== null && this.basicStatsForm.value.customAgeGroupFrom !== '') {
         if (Number(this.basicStatsForm.value.customAgeGroupFrom) > 150) {
            this.basicStatsForm.controls.customAgeGroupFrom.setErrors({
               outOfRange: true,
            });
         } else {
            this.basicStatsForm.controls.customAgeGroupFrom.setErrors(null);
         }
      }
   }
   resetForm() {
      this.basicStatsForm = this.formBuilder.group({
         selectedYear: [null, [Validators.minLength(4)]],
         ageGroup: [null, []],
         customAgeGroupFrom: [null, []],
         customAgeGroupTo: [null, []],
         gender: [null, []],
         requestsAndProblems: [null, []],
         services: [null, []],
         legalProcedures: [null, []],
         professionalProcedures: [null, []],
         rights: [null, []],
         center: [null],
         period: [this.period[0]],
         dateFrom: [''],
         dateTo: [''],
         crossData: [false],
      });
   }

   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.CENTERS:
            return sortByFirstLetter(
               this.centers.filter(option => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.REQUESTS_AND_PROBLEMS:
            return sortByFirstLetter(
               this.requestsAndProblems.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.SERVICES:
            return sortByFirstLetter(
               this.services.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.LEGAL_PROCEDURES:
            return sortByFirstLetter(
               this.legalProcedures.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.PROFESSIONAL_PROCEDURES:
            return sortByFirstLetter(
               this.professionalProcedures.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         case AutocompleteFilterEnum.RIGHTS:
            return sortByFirstLetter(
               this.rights.filter(option => option.title.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         default:
            break;
      }
   }
   onInputChange(inputValue: string, autocompleteEnum: String) {
      const input = inputValue.toLowerCase();
      if (input.trim() == '') {
         if (autocompleteEnum === 'requestsAndProblems') {
            this.requestsAndProblemsFiltered = this.requestsAndProblems;
         } else if (autocompleteEnum === 'services') {
            this.servicesFiltered = this.services;
         } else if (autocompleteEnum === 'legalProcedures') {
            this.legalProceduresFiltered = this.legalProcedures;
         } else if (autocompleteEnum === 'professionalProcedures') {
            this.professionalProceduresFiltered = this.professionalProcedures;
         } else if (autocompleteEnum === 'rights') {
            this.rightsFiltered = this.rights;
         }
      } else {
         if (autocompleteEnum === 'requestsAndProblems') {
            this.requestsAndProblemsFiltered = this._filter(input, AutocompleteFilterEnum.REQUESTS_AND_PROBLEMS);
         } else if (autocompleteEnum === 'services') {
            this.servicesFiltered = this._filter(input, AutocompleteFilterEnum.SERVICES);
         } else if (autocompleteEnum === 'legalProcedures') {
            this.legalProceduresFiltered = this._filter(input, AutocompleteFilterEnum.LEGAL_PROCEDURES);
         } else if (autocompleteEnum === 'professionalProcedures') {
            this.professionalProceduresFiltered = this._filter(input, AutocompleteFilterEnum.PROFESSIONAL_PROCEDURES);
         } else if (autocompleteEnum === 'rights') {
            this.rightsFiltered = this._filter(input, AutocompleteFilterEnum.RIGHTS);
         }
      }
   }
}
