/*
* Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
* Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
* u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
*/

import { Component, OnInit } from '@angular/core';
import { CaseService } from 'src/services/case.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { LocalStorageService } from '../local-storage.service';
import { SideNavService } from 'src/services/side-nav.service';
import { UserService } from 'src/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

    open = 0;
    unaccepted = 0;
    unassigned = 0;
    loggedUserRole = [];
    loggedIn: any;
    isMainCenterBelgrade = false;
    primaryRoleStrucniRadnik = false;
    constructor(
        private caseService: CaseService,
        public authentication: AuthenticationService,
        public dataSharing: DataSharingService,
        private localStorageService: LocalStorageService,
        private sideNavService: SideNavService,
        public userService: UserService) {
        this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
        this.isMainCenterBelgrade = (this.loggedIn?.csrId === '1') ? true : false;
        this.dataSharing.messages.subscribe(value => {
            this.primaryRoleStrucniRadnik =
                (this.localStorageService.get('primaryRole') === ' Стручни радник' ||
                 this.localStorageService.get('primaryRole') === ' Правник') ||
                (JSON.parse(this.localStorageService.get('loggedUser')).role.length === 1 && (
                    JSON.parse(this.localStorageService.get('loggedUser')).role[0] === environment.strucni_radnik ||
                    JSON.parse(this.localStorageService.get('loggedUser')).role[0] === environment.pravnik));
            if (this.authentication.checkCredentials() && !this.isMainCenterBelgrade && !this.userService.isCenterAdmin() && !this.userService.isGlobalAdmin()) {
                this.caseService.assignedCasesStatus().subscribe(
                    result => {
                        this.open = result.open;
                        this.unaccepted = result.unaccepted;
                        this.unassigned = result.unassigned;
                    }
                );
            }
        });

    }

    ngOnInit(): void {
        if (this.loggedIn !== null) {
            this.loggedUserRole = this.userService.getUserRoleTitle(JSON.parse(this.localStorageService.get('loggedUser')).role);
        }
    }

    openSidenav() {
        this.sideNavService.toggle();
    }

    onSubjectsClick() {
        /*
        Olja Andjelovski olja.andjelovski@iten.rs
        Obrisi mi localStorageSubcenter ukoliko ga kliknem sa homepage-a. Da ne bi registar bio prepopunjen sa onim sto je ranije bilo izabrano.
        */
        this.localStorageService.remove('localStorageSubcenter');
    }

}
