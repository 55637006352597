<form [formGroup]="suitabilityStep" class="form">
  <form-group isLast="true" title="{{ 'NEW_FOSTER_CHILD.SUITABILITY.REPORT_DATES' | translate }}">
    <section formGroupName="reportDates">
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.PSYCHOLOGIST' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="psychologist" [max]="currentDate" required formControlName="psychologist"
            autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="psychologist"></mat-datepicker-toggle>
          <mat-datepicker #psychologist></mat-datepicker>
          <mat-error *ngIf="suitabilityStep.get('reportDates').get('psychologist').hasError('required')">
            {{ 'NEW_FOSTER_PARENT.FORM.DATE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.PEDAGOGUE' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="pedagogue" [max]="currentDate" required formControlName="pedagogue"
            autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="pedagogue"></mat-datepicker-toggle>
          <mat-datepicker #pedagogue></mat-datepicker>
          <mat-error *ngIf="suitabilityStep.get('reportDates').get('pedagogue').hasError('required')">
            {{ 'NEW_FOSTER_PARENT.FORM.DATE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.SOCIAL_WORKER' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="socialWorker" [max]="currentDate" required formControlName="socialWorker"
            autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="socialWorker"></mat-datepicker-toggle>
          <mat-datepicker #socialWorker></mat-datepicker>
          <mat-error *ngIf="suitabilityStep.get('reportDates').get('socialWorker').hasError('required')">
            {{ 'NEW_FOSTER_PARENT.FORM.DATE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.LAWYER' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="lawyer" [max]="currentDate" required formControlName="lawyer"
            autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="lawyer"></mat-datepicker-toggle>
          <mat-datepicker #lawyer></mat-datepicker>
          <mat-error *ngIf="suitabilityStep.get('reportDates').get('lawyer').hasError('required')">
            {{ 'NEW_FOSTER_PARENT.FORM.DATE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.DOCTOR' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="doctor" [max]="currentDate" required formControlName="doctor"
            autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="doctor"></mat-datepicker-toggle>
          <mat-datepicker #doctor></mat-datepicker>
          <mat-error *ngIf="suitabilityStep.get('reportDates').get('doctor').hasError('required')">
            {{ 'NEW_FOSTER_PARENT.FORM.DATE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.OTHER_INSTITUTIONS' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="otherInstitutions" [max]="currentDate" required
            formControlName="otherInstitutions" autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="otherInstitutions"></mat-datepicker-toggle>
          <mat-datepicker #otherInstitutions></mat-datepicker>
          <mat-error *ngIf="suitabilityStep.get('reportDates').get('otherInstitutions').hasError('required')">
            {{ 'NEW_FOSTER_PARENT.FORM.DATE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
    </section>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_FOSTER_CHILD.FORM.DECISION_NUMBER' | translate
        }}</mat-label>
        <input matInput type="text" formControlName="decisionNumber" required autocomplete="off" />
        <mat-error *ngIf="suitabilityStep.get('decisionNumber').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_FOSTER_CHILD.FORM.DECISION_DATE' | translate
        }}</mat-label>
        <input matInput [matDatepicker]="decisionDate" [max]="currentDate" formControlName="decisionDate"
          autocomplete="off" required />
        <mat-datepicker-toggle matSuffix [for]="decisionDate"></mat-datepicker-toggle>
        <mat-datepicker #decisionDate></mat-datepicker>
        <mat-error *ngIf="suitabilityStep.get('decisionDate').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_FOSTER_CHILD.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!suitabilityStep.valid"
      class="form-footer__button" (click)="updateSuitabilityStep()">
      {{ 'NEW_FOSTER_CHILD.NEXT_STEP' | translate }}
    </button>

  </form-footer>
</form>