/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss']
})
export class ProfileDropdownComponent implements OnInit {

  constructor() { }

  @Output() addDocumentHandler = new EventEmitter();
  @Output() toggleDropdownHandler = new EventEmitter();

  ngOnInit(): void {}

  toggleDropdown(): void {
    this.toggleDropdownHandler.next();
  }
}
