<div class="modal">
  <h4 class="modal__title">{{ 'DELETE_TRACKING.TITLE' | translate }}</h4>
  <p class="modal__content">
    {{ 'DELETE_TRACKING.MESSAGE' | translate }}

    <strong>{{ data.activity.title }}</strong
    >?
  </p>
  <footer class="modal__footer">
    <button
      mat-stroked-button
      color="primary"
      mat-dialog-close
      (click)="closeDialog()"
      class="modal-footer__button modal-footer__button--secondary"
    >
      {{ 'DELETE_TRACKING.CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      color="warn"
      class="modal-footer__button modal-footer__button--primary"
      (click)="deleteTracking()"
    >
      {{ 'DELETE_TRACKING.DELETE' | translate }}
    </button>
  </footer>
</div>
