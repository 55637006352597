/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common'
import { LocalStorageService } from 'src/app/local-storage.service';

@Component({
    selector: 'evaluation-and-reassessment',
    templateUrl: './evaluation-and-reassessment.component.html',
    styleUrls: ['./evaluation-and-reassessment.component.scss']
})
export class EvaluationAndReassessmentComponent implements OnInit {
    @Input() data: any = {}; // TODO: Type me
    @Input() evaluationsOfPlan: []; // TODO: Type me
    @Input() editEnabled: boolean;
    @Input() isCaseClosed: boolean;
    @Output() handleSubmitData = new EventEmitter();
    @Input() waitingForApproval: boolean;
    @Input() noRegistrationId: boolean;

    isEditMode = false;
    stepStatus: 'INITIAL' | 'STARTED' | 'FINISHED' = 'INITIAL';
    newEvaluationAndAssessment: FormGroup;
    notes = [];
    submittedData: any; // TODO: Type me
    acceptCaseMissingPermitionsTooltip = '';
    loggedIn: any;

    constructor(
        private localStorageService: LocalStorageService,
        public datepipe: DatePipe,
        public dialog: MatDialog,
        private translate: TranslateService,
        private formBuilder: FormBuilder) {
        this.loggedIn = (JSON.parse(this.localStorageService.get('loggedUser')));
        this.translate.get('GLOBAL.TOOLTIPS.ACCEPT_CASE_MISSING_PERMITIONS').subscribe((res: string) => {
            this.acceptCaseMissingPermitionsTooltip = res;
        });

    }

    ngOnInit(): void {
        const { stepStatus } = this.data;
        this.stepStatus = stepStatus;
        this.notes = this.evaluationsOfPlan;
        this.notes = this.notes.reverse();
        this.newEvaluationAndAssessment = this.formBuilder.group({
            note: ['', []],
        });
        if (this.evaluationsOfPlan.length !== 0) {
            this.stepStatus = 'STARTED';
        }
    }

    ngOnChanges(changes: any): void {
        if (changes.notes && changes.notes.currentValue) {
            if (this.notes.length !== 0) {
                this.stepStatus = 'STARTED';
            }

        }
    }

    handleInitStep() {
        this.isEditMode = true;
    }

    handleSaveChanges() {
        this.stepStatus = 'STARTED';
        this.isEditMode = false;
        const date = new Date();
        const dateStr = this.datepipe.transform(date, 'dd.MM.yyyy');
        const input = this.newEvaluationAndAssessment.value.note;
        if (input !== '' && input !== null && input !== undefined) {
            const submittedData = {
                dateOfCreation: dateStr,
                author: this.loggedIn.firstName + ' ' + this.loggedIn.lastName,
                note: input,
            };
            this.handleSubmitData.emit({ step: 'STEP_06', data: submittedData });
        }

        this.newEvaluationAndAssessment.reset();
    }

    toggleEdit() {
        this.isEditMode = !this.isEditMode;
        if (this.isEditMode === false) {
            this.newEvaluationAndAssessment.reset();
        }
    }

}
