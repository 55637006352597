<div class="item__wrapper">
  <a routerLink="{{link}}" class="item" target="{{ isExternal ? '_blank' : '_self' }}">
    <span [ngClass]="{
        item__kind: true,
        'item__kind--red': kind === 'C',
        'item__kind--yellow': kind === 'Ž',
        'item__kind--white': kind === 'O',
        'item__kind--blue': kind === 'P',
        'item__kind--purple': kind === 'LJ',
        'item__kind--dashedred': kind === 'IC',
        'item__kind--dashedblue': kind === 'IP'
      }"></span>
    <header class="item__header">
      <h5 class="item__title">{{ title }}</h5>
      <h6 class="item__description" *ngIf="caseName"><mat-icon class="item__icon">category</mat-icon>{{ caseName }}</h6>
      <h6 class="item__description" *ngIf="status"><mat-icon class="item__icon">trending_flat</mat-icon>{{ ('CASES.STATUSES.' + status) | translate }}</h6>
    <h6 class="item__description"><mat-icon class="item__icon">person</mat-icon>{{ firstName }} {{lastName}}</h6>
      <footer class="item__meta">
        <time class="item__date">
          <mat-icon class="item__icon">calendar_today</mat-icon>{{ dateFormat }}
        </time>
        <span class="item__people" *ngIf="people">
          <mat-icon class="item__icon" *ngIf="people.length > 1">people_alt</mat-icon>
          <mat-icon class="item__icon" *ngIf="people.length === 1">person</mat-icon>{{ peopleList }}
        </span>
      </footer>
    </header>
  </a>
  <div class="item__action">
    <ng-content></ng-content>
  </div>
</div>
