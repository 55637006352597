<container>
  <div class="content">
    <div class="content__item content__item--left">
      <profile-card [showProfileLink]="false"></profile-card>
    </div>
    <mat-card class="content__item content__item--right">
      <card-header title="{{ 'PROFILE.EVALUATION' | translate }}"></card-header>
      <ul class="worker-details__document-list">
        <li *ngIf="pageEvaluation.content.length === 0">
          <no-result-item>{{
            'SUPERVISION.NO_EVALUATIONS' | translate
          }}</no-result-item>
        </li>
        <li
          class="worker-details__document-item"
          *ngFor="let document of pageEvaluation.content"
        >
          <document-item-evaluation
            [title]="document.name"
            [meta]="document.dateOfModification"
            (click)="
              openDialog({
                document: document,
                requireSignature: false
              })
            "
          >
          </document-item-evaluation>
        </li>
      </ul>
      <div
        class="pagination-wrapper"
        *ngIf="pageEvaluation.content.length !== 0"
      >
        <app-custom-pagination
          [page]="pageEvaluation"
          (firstPageEvent)="getFirstPageEvaluation()"
          (nextPageEvent)="getNextPageEvaluation()"
          (previousPageEvent)="getPreviousPageEvaluation()"
          (lastPageEvent)="getLastPageEvaluation()"
          (pageSizeEvent)="getPageInNewSizeEvaluation($event)"
        >
        </app-custom-pagination>
      </div>
    </mat-card>
  </div>
</container>
