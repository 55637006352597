/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/services/authentication.service';
import { ModalEventEnum } from 'src/types';
import { SubjectsService } from 'src/services/subjects.service';

export interface PrintDetailsData {
  maxNumber: number;
  whole: boolean;
  partial: {
    from: number;
    to: number;
    type: string;
  };
  src: string;
  csr: number;
}

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  printForm: FormGroup;
  options: any = [
    { value: 'whole', viewValue: 'Цео регистар' },
    { value: 'partial', viewValue: 'Део регистра' },
    { value: 'partialById', viewValue: 'Део регистра по ид-у' },
    { value: 'partialByRegId', viewValue: 'Део регистра по Рег. броју' }
  ];
  conditionVisible = false;

  maxNumberRegistrationNumber = 0;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PrintComponent>,
    public authenticationService: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PrintDetailsData,
    private subjectsService: SubjectsService) {
  }

  ngOnInit(): void {
    this.printForm = this.formBuilder.group({
      printSize: ['', [Validators.required]],
      conditionFrom: [],
      conditionTo: [{ value: '', disabled: true }],
    });
    if (this.data.src === 's') {
      this.subjectsService.getCountOfRegisteredSubjects(this.data.csr).subscribe((res: number) => {
        this.maxNumberRegistrationNumber = res;
        this.setFormConditionalFields();
      });
    } else {
      this.setFormConditionalFields();
    }


  }

  setFormConditionalFields() {
    const conditionFrom = this.printForm.get('conditionFrom');
    const conditionTo = this.printForm.get('conditionTo');
    this.printForm
      .get('printSize')
      .valueChanges.subscribe((value) => {
        if (value === 'whole') {
          conditionFrom.setValidators([]);
          conditionFrom.setValue(null);
          conditionTo.setValidators([]);
          conditionTo.setValue(null);
          this.conditionVisible = false;
        } else {
          conditionFrom.clearValidators();
          conditionTo.clearValidators();
          conditionFrom.setValidators(
            [
              Validators.required,
              Validators.min(1),
              Validators.max(this.data.src === 's' ?
                (value === 'partialByRegId' ? this.maxNumberRegistrationNumber : this.data.maxNumber) : this.data.maxNumber),
              Validators.pattern('^[0-9]*$')
            ]);
          conditionTo.setValidators(
            [
              Validators.required,
              Validators.min(value),
              // Validators.max(this.data.src === 's' ?
              //   (value === 'partialByRegId' ? this.maxNumberRegistrationNumber : this.data.maxNumber) : this.data.maxNumber),
              Validators.pattern('^[0-9]*$')
            ]
          );
          conditionTo.disable();
          conditionTo.setValue('');
          conditionFrom.setValue('');
          this.conditionVisible = true;
        }
        conditionFrom.updateValueAndValidity();
        conditionTo.updateValueAndValidity();
      });

    conditionFrom.valueChanges.subscribe(value => {
      if (value !== null && value !== '') {
        this.printForm.get('conditionTo').enable();
      } else {
        this.printForm.get('conditionTo').disable();
        this.printForm.controls.conditionTo.setValue('');
      }
    });

    conditionFrom.updateValueAndValidity();
    conditionTo.updateValueAndValidity();
  }

  print(submittedForm: any) {
    if (submittedForm.get('printSize').value === 'whole') {
      this.data.whole = true;
    } else {
      this.data.whole = false;
      this.data.partial.from = submittedForm.get('conditionFrom').value;
      this.data.partial.to = submittedForm.get('conditionTo').value;
      this.data.partial.type = submittedForm.get('printSize').value;
    }
    this.dialogRef.close({ data: this.data, event: ModalEventEnum.SUCCESS });
  }

  closeDialog() {
    this.dialogRef.close({ event: ModalEventEnum.CANCEL });
  }

}
