import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { SubjectsService } from 'src/services/subjects.service';
import { ModalSizeEnum } from 'src/types';

interface DocumentData {
  document: any;
  action: 'cancel' | 'confirm';
  regId: string;
  csrId: string;
}

@Component({
  selector: 'app-change-reg-id-dialog',
  templateUrl: './change-reg-id-dialog.component.html',
  styleUrls: ['./change-reg-id-dialog.component.scss'],
})
export class ChangeRegIdDialogComponent implements OnInit {
  action: string;
  data: any;
  regId: String;
  csrId: String;
  recRegId: Number;
  form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    public subjectService: SubjectsService,
    private translate: TranslateService,
    public dialog: MatDialog,

    @Optional() @Inject(MAT_DIALOG_DATA) public document: DocumentData
  ) {}

  ngOnInit(): void {
    this.data = this.document.document;
    this.action = this.document.action;
    this.regId = this.document.regId;
    this.csrId = this.document.csrId;
    this.form = this.formBuilder.group({
      oldRegId: [''],
      newRegId: ['', Validators.required],
    });
    this.form.get('oldRegId').setValue(this.regId);
    this.subjectService.getRegisterId(this.csrId).subscribe((res) => {
      this.recRegId = res;
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }
  clickSubmit() {
    const newRegId = this.form.get('newRegId').value;
    const isRecRegId = newRegId > this.recRegId ? false : true;
    if (!isRecRegId) {
      //kondicional za registarski broj
      let message = '';
      let title = '';
      this.translate
        .get('NEW_SUBJECT.OTHER.REG_NUM_ERR_TITLE1')
        .subscribe((res: string) => {
          title = res + newRegId;
        });
      this.translate
        .get('NEW_SUBJECT.OTHER.REG_NUM_ERR_TITLE2')
        .subscribe((res: string) => {
          title += res + this.recRegId + '.';
        });
      this.translate
        .get('NEW_SUBJECT.OTHER.REG_NUM_ERR_MSG')
        .subscribe((res: string) => {
          message = res + newRegId + '?';
        });
      const object = {
        document: null,
        message,
        title,
      };
      const dialogRef = this.dialog.open(WarningMessageComponent, {
        data: object,
        width: ModalSizeEnum.DEFAULT,
        panelClass: 'overlay-panel',
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res.event === 'cancel') {
          return;
        } else {
          this.submitAction();
        }
      });
    } else {
      this.submitAction();
    }
  }
  submitAction() {
    this.dialogRef.close({
      event: this.action,
      data: { status: true, newRegId: this.form.get('newRegId').value },
    });
  }
}
