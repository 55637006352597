/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SubjectsService } from 'src/services/subjects.service';
import { CodebookService } from 'src/services/codebook.service';
import { Subject } from 'src/types';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { DialogActions } from '../subject-details.component';
import { DatePipe } from '@angular/common';

interface ArhiveData {
  subject: Subject;
  action: DialogActions;
}

@Component({
  selector: 'archive-subject',
  templateUrl: './archive-subject.component.html',
  styleUrls: ['./archive-subject.component.scss'],
  // providers: [
  //   {
  //     provide: DateAdapter,
  //     useClass: AppDateAdapter,
  //   },
  //   {
  //     provide: MAT_DATE_FORMATS,
  //     useValue: APP_DATE_FORMATS,
  //   },
  // ],
})
export class ArchiveSubjectComponent implements OnInit {
  minFromDate = new Date();
  maxFromDate = new Date();
  action: string;
  subject: Subject;
  archiveSubjectForm: FormGroup;
  archivationReasons: any[];
  @ViewChild('submitButton') submitButton;

  constructor(
    public codebookService: CodebookService,
    public subjectsService: SubjectsService,
    public dialogRef: MatDialogRef<ArchiveSubjectComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ArhiveData,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.subject = data.subject;
    this.action = data.action;
    this.populateArchivationReasons();
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  ngOnInit() {
    this.archiveSubjectForm = this.formBuilder.group({
      dateOfArchiving: ['', [Validators.required]],
      archiveReason: ['', [Validators.required]],
    });
    this.populateArchivationReasons();
    this.maxFromDate.setFullYear(this.maxFromDate.getFullYear() + 1);  
  }

  populateArchivationReasons() {
    this.codebookService.getArchivationReasonsCodebook()
      .subscribe((reasons) => {
        this.archivationReasons = reasons;
      });
  }

  archiveSubject(submittedForm: FormGroup) {
    const archiveData = {
      dateOfArchiving: this.datePipe.transform(submittedForm.get('dateOfArchiving').value, 'dd/MM/yyyy'),
      archiveReason: submittedForm.get('archiveReason').value,
    };
    const x = this.subjectsService.archiveSubject(this.subject.historicalSubjectIdentity.subjectId, archiveData);
    x.subscribe(
      (res) => {
        this.dialogRef.close({ event: this.action, data: res });
      },
      () => {
        console.log('Error occured');
        this.submitButton.disabled = false;
        this.dialogRef.close({ event: this.action, data: this.subject });
      }
    );


  }
}
