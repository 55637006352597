<form [formGroup]="propertyStep" class="form">
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.ASSET_VALUE_ON_CENSUS_DAY' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="assetValueOnCensusDay" autocomplete="off" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_PROTEGE.FORM.ASSET_VALUE_ON_THE_DAY_OF_TERMINATION_OF_CUSTODY' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="assetValueOnTheDayOfTerminationOfCustody"
        autocomplete="off" />
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_PROTEGE.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!propertyStep.valid"
      class="form-footer__button" (click)="updatePropertyStep()">
      {{ 'NEW_PROTEGE.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>
