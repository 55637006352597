<div class="wrapper" (click)="handleItemClick()">
  <icon-file size="32" class="document-icon"></icon-file>
  <div class="content">
    <header class="title-wrapper">
      <h5 class="title">{{ title }}</h5>
      <span class="meta">{{ meta }}</span>
    </header>
  </div>
  <button mat-icon-button class="button-square item__button" color="primary"
    matTooltip="{{ 'DOCUMENT_LIST_ITEM.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
    <mat-icon class="button-square__icon">more_horiz</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="filter-menu">
    <button mat-menu-item (click)="handleItemClick()">
      <mat-icon>visibility</mat-icon>
      {{ 'DOCUMENT_LIST_ITEM.VIEW_DOCUMENT' | translate }}
    </button>
    <button mat-menu-item (click)="handleDownloadClick()">
      <mat-icon>cloud_download</mat-icon>
      {{ 'DOCUMENT_LIST_ITEM.DOWNLOAD_DOCUMENT' | translate }}
    </button>
    <button *ngIf="title.substring(title.length-4,title.length)==='.pdf'" mat-menu-item (click)="handlePrintItem()">
      <mat-icon>print</mat-icon>
      {{ 'DOCUMENT_LIST_ITEM.PRINT_DOCUMENT' | translate }}
    </button>
  </mat-menu>
  <div class="action-wrapper">
    <ng-content></ng-content>
  </div>
</div>