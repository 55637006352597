/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { formatDate } from 'src/app/utils/formatDate';


@Component({
  selector: 'case-list-item',
  templateUrl: './case-list-item.component.html',
  styleUrls: ['./case-list-item.component.scss'],
})
export class CaseListItemComponent implements OnInit {
  peopleList: string = '';
  dateFormat: string = '';

  constructor(public router: Router) {
  }
  @Input() link: string;
  @Input() kind: string;
  @Input() title: string;
  @Input() caseName: string;
  @Input() date: Date;
  @Input() status: string;
  @Input() people: string[];
  @Input() isExternal: boolean = false;
  @Input() firstName: string;
  @Input() lastName: string;

  ngOnInit(): void {
    this.peopleList = this.people && this.handlePeopleList();
    this.dateFormat = formatDate(this.date);

  }

  handlePeopleList(): string {

    const count = this.people.length;
    if (count >= 2) {
      const [first, ...rest] = this.people;
      return `${first} i još ${rest.length} ${
        rest.length === 1
          ? 'član'
          : rest.length > 1 && rest.length < 5
            ? 'člana'
            : 'članova'
        }`;
    } else {
      return this.people.join('');
    }
  }

}

/**
 * TODO @gorandivovic @jelenaninkovic @danijelgrabez
 *
 * 1. Format date
 * 2. Establish kind values
 */
