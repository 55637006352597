<div class="modal">
  <h4 class="modal__title">{{ title }}</h4>
  <p class="modal__content" style="white-space: pre-wrap;">
    {{ message }}
  </p>
  <footer class="modal__footer">
    <button *ngIf="action!=='allocationError' && !singleButtonMode"
      mat-stroked-button
      color="primary"
      mat-dialog-close
      (click)="closeDialog()"
      class="modal-footer__button modal-footer__button--secondary"
    >
      {{ 'WARNING_DIALOG.CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      [color]="saveData?'primary':'warn'"
      class="modal-footer__button modal-footer__button--primary"
      (click)="applyAction()"
    >
      {{ singleButtonMode ? 'OK' : 
        (saveData ? 'WARNING_DIALOG.SAVE' : "WARNING_DIALOG.SUBMIT") | translate }}
    </button>
  </footer>
</div>
