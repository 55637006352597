/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportCollectionsService {

  constructor(private httpClient: HttpClient, private appService: AppService) { }

  importData(importCollection: any, csrId: number, file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('collection', importCollection);
    const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
    return this.httpClient
      .post(url + environment.importCollectionsUrl + csrId,
        formData) as Observable<any>;

  }

  checkStatus() {
    const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
    return this.httpClient
      .get(url + environment.importCollectionsStatus) as Observable<any>;

  }

  getMessages() {
    const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
    return this.httpClient
      .get(url + environment.importCollectionsMessages, { responseType: 'text' }) as Observable<any>;

  }

  stopImport() {
    const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
    return this.httpClient
      .delete(url + environment.stopCollectionsImport) as Observable<any>;

  }

  syncData(csrId: number) {
    const url = window.location.protocol + '//' + window.location.hostname + environment.dataImporterPort;
    return this.httpClient
      .get(url + environment.importIntegration + csrId) as Observable<any>;

  }
}
