<mat-dialog-content>
  <form class="form" [formGroup]="newSubjectForm">
    <form-group title="{{ 'NEW_SUBJECT.PERSONAL_DETAILS.TITLE' | translate }}">
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.FIRST_NAME' | translate
            }}</mat-label>
          <input matInput maxlength="250" type="text" formControlName="firstName" autocomplete="off" required />
          <mat-error *ngIf="
              newSubjectForm.get('firstName').hasError('required') ||
              newSubjectForm.get('firstName').hasError('pattern')
            ">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.FIRST_NAME_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.LAST_NAME' | translate
            }}</mat-label>
          <input matInput type="text" maxlength="250" formControlName="lastName" autocomplete="off" required />
          <mat-error *ngIf="
              newSubjectForm.get('lastName').hasError('required') ||
              newSubjectForm.get('lastName').hasError('pattern')
            ">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.LAST_NAME_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.PARENT_NAME' | translate
            }}</mat-label>
          <input matInput type="text" maxlength="250" formControlName="parentName" autocomplete="off" />
          <mat-error *ngIf="newSubjectForm.get('parentName').hasError('pattern')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.PARENT_NAME_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <div class="checbox-holder">
        <mat-checkbox color="primary" formControlName="foreigner">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.FOREIGNER' | translate }}
        </mat-checkbox>
      </div>
      <field-holder kind="4-cols">
        <mat-form-field appearance="outline">
          <mat-label *ngIf="newSubjectForm.get('foreigner').value === true">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.EBS' | translate }}
          </mat-label>
          <mat-label *ngIf="newSubjectForm.get('foreigner').value !== true">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG' | translate }}
          </mat-label>
          <input *ngIf="newSubjectForm.get('foreigner').value !== true" maxlength="13" matInput type="text"
            formControlName="jmbg" autocomplete="off" (input)="checkJMBG($event); workStatusUpdateFromJmbg()"
            (focusout)="checkJMBGRule($event)" (change)="updateDateOfBirthFromJMBG()" />
          <input *ngIf="newSubjectForm.get('foreigner').value === true" maxlength="250" matInput type="text"
            formControlName="jmbg" autocomplete="off" />
          <mat-error *ngIf="
              newSubjectForm.get('foreigner').value !== true &&
              (newSubjectForm.get('jmbg').hasError('required') ||
                newSubjectForm.get('jmbg').hasError('maxLength') ||
                newSubjectForm.get('jmbg').hasError('pattern'))
            ">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('jmbg').hasError('incorrect')">
            {{ 'NEW_SUBJECT.EXISTS' | translate }}
          </mat-error>
          <mat-error *ngIf="
              newSubjectForm.get('foreigner').value !== true &&
              newSubjectForm.get('jmbg').hasError('controlNumber')
            ">
            {{
            'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR_VALIDATION' | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH' | translate
            }}</mat-label>
          <input matInput [matDatepicker]="picker" [max]="currentDate" formControlName="dateOfBirth" (dateChange)="updateJMBGOnDateOfBirthChange($event);
              updateYearOfBirthOnDateOfBirthChange($event);
              workStatusUpdateFromJmbg()
            " autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="
              newSubjectForm.get('dateOfBirth').hasError('required') ||
              newSubjectForm.get('dateOfBirth').hasError('incorrect')
            ">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.YEAR_OF_BIRTH' | translate
            }}</mat-label>
          <input maxlength="4" matInput type="text" formControlName="yearOfBirth"
            (change)="updateDateOfBirthOnYearOfBirthChange($event)" autocomplete="off" />
          <mat-error *ngIf="
              newSubjectForm.get('yearOfBirth').hasError('required') ||
              newSubjectForm.get('yearOfBirth').hasError('pattern') ||
              newSubjectForm.get('yearOfBirth').hasError('minLength') ||
              newSubjectForm.get('yearOfBirth').hasError('maxLength') ||
              newSubjectForm.get('yearOfBirth').hasError('max') ||
              newSubjectForm.get('yearOfBirth').hasError('incorrect')
            ">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.YEAR_OF_BIRTH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.GENDER' | translate
            }}</mat-label>
          <mat-select formControlName="gender" [compareWith]="compareGenderObjects" (selectionChange)="
                              updateJMBGOnGenderChange($event); workStatusUpdateFromJmbg()
                            ">
            <mat-option></mat-option>
            <mat-option *ngFor="let gender of genderOptions" [value]="gender">
              {{ gender.gender }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
                      newSubjectForm.get('gender').hasError('required') ||
                      newSubjectForm.get('gender').hasError('incorrect')
                    ">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="4-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.PLACE_OF_BIRTH' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" />
          <mat-error *ngIf="
              newSubjectForm.get('placeOfBirth').hasError('required') ||
              newSubjectForm.get('placeOfBirth').hasError('pattern')
            ">
            {{
            'NEW_SUBJECT.PERSONAL_DETAILS.PLACE_OF_BIRTH_ERROR' | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.NATIONALITY' | translate }}
          </mat-label>
          <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationality"
            (focusout)="autoSelectItem(nationalityOptions, 'nationality')" />
          <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredNationalityOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('nationality').hasError('required')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.NATIONALITY_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('nationality').hasError('match')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MATCH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.CITIZENSHIP' | translate }}
          </mat-label>
          <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenship"
            (focusout)="autoSelectItem(citizenshipOptions, 'citizenship')" />
          <mat-autocomplete panelWidth="auto" #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredCitizenshipOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('citizenship').hasError('required')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.CITIZENSHIP_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('citizenship').hasError('match')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MATCH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'GLOBAL.SUBJECT.CATEGORY_OF_PERSON' | translate }}
          </mat-label>
          <input type="text" matInput formControlName="category" [matAutocomplete]="autoCategory"
            (focusout)="autoSelectItem(categoryOptions, 'category')" />
          <mat-autocomplete panelWidth="auto" #autoCategory="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredCategoryOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('category').hasError('required')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.CITIZENSHIP_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('category').hasError('match')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MATCH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder kind="4-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.DISABLED_PERSON' | translate
            }}</mat-label>
          <mat-select formControlName="disabledPerson" (selectionChange)="updateDisabilityType($event)"
            [compareWith]="compareYesNoObjects">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of yesNoOptions" [value]="option">
              {{ option.viewValue | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newSubjectForm.get('disabledPerson').hasError('required')">
            {{
            'NEW_SUBJECT.PERSONAL_DETAILS.DISABLED_PERSON_ERROR' | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.DISABILITY_TYPE' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="disabilityType" autocomplete="off" [required]="
              newSubjectForm.get('disabledPerson').value === undefined ||
              newSubjectForm.get('disabledPerson').value === null
                ? false
                : newSubjectForm.get('disabledPerson').value.id === 1
            " />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.ABLE_TO_WORK' | translate
            }}</mat-label>
          <mat-select formControlName="ableToWork" [compareWith]="compareYesNoObjects">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of yesNoOptions" [value]="option">
              {{ option.viewValue | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newSubjectForm.get('ableToWork').hasError('required')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.ABLE_TO_WORK_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.NATIVE_LANGUAGE' | translate }}
          </mat-label>
          <input type="text" matInput formControlName="nativeLanguage" [matAutocomplete]="autoNativeLanguage"
            (focusout)="autoSelectItem(nativeLanguageOptions, 'nativeLanguage')" />
          <mat-autocomplete #autoNativeLanguage="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredNativeLanguageOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('nativeLanguage').hasError('required')">
            {{
            'NEW_SUBJECT.PERSONAL_DETAILS.NATIVE_LANGUAGE_ERROR' | translate
            }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('nativeLanguage').hasError('match')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MATCH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="4-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.OCCUPATION' | translate
            }}</mat-label>
          <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupation"
            (focusout)="autoSelectItem(occupationOptions, 'occupation')" />
          <mat-autocomplete panelWidth="auto" #autoOccupation="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredOccupationOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('occupation').hasError('required')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.OCCUPATION_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('occupation').hasError('match')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MATCH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.EMPLOYMENT' | translate
            }}</mat-label>
          <input type="text" matInput formControlName="employment" [matAutocomplete]="autoEmployment"
            (focusout)="autoSelectItem(employmentOptions, 'employment')" />
          <mat-autocomplete #autoEmployment="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredEmploymentOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('employment').hasError('required')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.EMPLOYMENT_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('employment').hasError('match')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MATCH_ERROR' | translate }}
          </mat-error>
          <mat-hint *ngIf="
              workstatus === true &&
              newSubjectForm.get('employment').value !== '' &&
              newSubjectForm.get('employment').value?.id !== 6
            ">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.EMPLOYMENT_CHECK' | translate }}
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.EDUCATION' | translate
            }}</mat-label>
          <input type="text" matInput formControlName="education" [matAutocomplete]="autoEducation"
            (focusout)="autoSelectItem(educationOptions, 'education')" />
          <mat-autocomplete #autoEducation="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredEducationOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('education').hasError('required')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.EDUCATION_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('education').hasError('match')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MATCH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.LEVEL_OF_EDUCATION' | translate
            }}</mat-label>
          <mat-select formControlName="levelOfEducation" [compareWith]="compareObjects">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of levelOfEducation" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newSubjectForm.get('levelOfEducation').hasError('required')">
            {{
            'NEW_SUBJECT.PERSONAL_DETAILS.LEVEL_OF_EDUCATION_ERROR'
            | translate
            }}
          </mat-error>
        </mat-form-field>

      </field-holder>
      <field-holder kind="4-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.BUSINESS_ABILITY' | translate
            }}</mat-label>
          <mat-select formControlName="businessAbility" [compareWith]="compareYesNoObjects"
            (selectionChange)="updateDisabilityType($event)">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of yesNoOptions" [value]="option">
              {{ option.viewValue | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newSubjectForm.get('businessAbility').hasError('required')">
            {{
            'NEW_SUBJECT.PERSONAL_DETAILS.BUSINESS_ABILITY_ERROR' | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.DEPTIVATION_EXTENT' | translate
            }}</mat-label>
          <mat-select formControlName="deprivationExtent" [compareWith]="compareObjects">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of deprivationExtents" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
    </form-group>
    <form-group title="{{ 'NEW_SUBJECT.ADDRESS.TITLE' | translate }}">
      <div class="checbox-holder">
        <mat-checkbox color="primary" (change)="registerOnCenterAddress($event)" [checked]="registeredOnCenter">
          {{ 'NEW_SUBJECT.ADDRESS.REGISTER_ON_CENTER' | translate }}
        </mat-checkbox>
      </div>
      <field-holder kind="4-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.ADDRESS.ORIGIN_PLACE' | translate
            }}</mat-label>
          <input type="text" matInput maxlength="250" formControlName="originPlace" [matAutocomplete]="autoOriginPlace"
            [readonly]="registeredOnCenter" />
          <mat-autocomplete #autoOriginPlace="matAutocomplete" (optionSelected)="filterOriginPtt($event.option.value)">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredOriginPlaceOptions | async" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('originPlace').hasError('required')">
            {{ 'NEW_SUBJECT.ADDRESS.ORIGIN_PLACE_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="newSubjectForm.get('originPlace').hasError('match')">
            {{ 'NEW_SUBJECT.PERSONAL_DETAILS.MATCH_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.ADDRESS.AREA' | translate }}</mat-label>
          <input type="text" matInput formControlName="originArea" (keyup)="filterOriginArea($event.target.value)"
            [matAutocomplete]="autoOriginArea" />
          <mat-autocomplete #autoOriginArea="matAutocomplete">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredAreaOptionsOrigin | async" [value]="option | titlecase">
              {{ option | titlecase }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('originArea').hasError('required')">
            {{ 'NEW_SUBJECT.ADDRESS.AREA_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.ADDRESS.PTT_NUMBER' | translate
            }}</mat-label>
          <input type="text" matInput formControlName="originPttNumber" [matAutocomplete]="autoOriginPttNumber" />
          <mat-autocomplete #autoOriginPttNumber="matAutocomplete"
            (optionSelected)="getAreaOptions($event.option.value, 'origin')">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredOriginPttNumberOptions | async" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('originPttNumber').hasError('required')">
            {{ 'NEW_SUBJECT.ADDRESS.PTT_NUMBER_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.ADDRESS.AREA_TYPE' | translate
            }}</mat-label>
          <mat-select formControlName="originAreaType" [compareWith]="compareObjects">
            <mat-option></mat-option>
            <mat-option *ngFor="let originAreaType of AreaType" [value]="originAreaType">
              {{ originAreaType.title }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newSubjectForm.get('originAreaType').hasError('required')">
            {{ 'NEW_SUBJECT.ADDRESS.AREA_TYPE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols-2-1-1">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.ADDRESS.ORIGIN_ADDRESS' | translate
            }}</mat-label>
          <input type="text" matInput formControlName="originAddress" (input)="checkLengthStreetName('origin')"
            [matAutocomplete]="autoOriginAddress" />
          <mat-autocomplete #autoOriginAddress="matAutocomplete">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredOriginAddressOptions | async" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="newSubjectForm.get('originAddress').hasError('required')">
            {{ 'NEW_SUBJECT.ADDRESS.ORIGIN_ADDRESS_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.ADDRESS.ADDRESS_NUM' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="originAddressNum" autocomplete="off"
            [readonly]="registeredOnCenter" />
          <mat-error *ngIf="
              newSubjectForm.get('originAddressNum').hasError('required') ||
              newSubjectForm.get('originAddressNum').hasError('pattern')
            ">
            {{ 'NEW_SUBJECT.ADDRESS.ADDRESS_NUM_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.ADDRESS.ADDRESS_SUB_NUM' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="originAddressSubNum" autocomplete="off"
            [readonly]="registeredOnCenter" />
          <mat-error *ngIf="
              newSubjectForm.get('originAddressSubNum').hasError('pattern')
            ">
            {{ 'NEW_SUBJECT.ADDRESS.ADDRESS_SUB_NUM_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <div class="checbox-holder">
        <mat-checkbox color="primary" (change)="handleChange($event)" [checked]="checkDiffAddress"
          [disabled]="registeredOnCenter">{{
          'NEW_SUBJECT.ADDRESS.CHECK_ADDRESS_MATCHING' | translate
          }}</mat-checkbox>
      </div>
      <div *ngIf="!residenceMatchOriginAddress">
        <field-holder kind="4-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_SUBJECT.ADDRESS.RESIDENCE_PLACE' | translate
              }}</mat-label>
            <input type="text" maxlength="250" matInput formControlName="residencePlace"
              [matAutocomplete]="autoResidencePlace" />
            <mat-autocomplete #autoResidencePlace="matAutocomplete"
              (optionSelected)="filterResidencePtt($event.option.value)">
              <mat-option></mat-option>
              <mat-option *ngFor="let option of filteredResidencePlaceOptions | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="newSubjectForm.get('residencePlace').hasError('required')">
              {{ 'NEW_SUBJECT.ADDRESS.RESIDENCE_PLACE_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'NEW_SUBJECT.ADDRESS.AREA' | translate }}</mat-label>
            <input type="text" matInput formControlName="residenceArea" [matAutocomplete]="autoResidenceArea" />
            <mat-autocomplete #autoResidenceArea="matAutocomplete">
              <mat-option></mat-option>
              <mat-option *ngFor="let option of filteredAreaOptions | async" [value]="option | titlecase">
                {{ option | titlecase }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_SUBJECT.ADDRESS.PTT_NUMBER' | translate
              }}</mat-label>
            <input type="text" matInput formControlName="residencePttNumber"
              [matAutocomplete]="autoResidencePttNumber" />
            <mat-autocomplete #autoResidencePttNumber="matAutocomplete" (optionSelected)="
                getAreaOptions($event.option.value, 'residence')
              ">
              <mat-option></mat-option>
              <mat-option *ngFor="let option of filteredResidencePttNumberOptions | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="
                newSubjectForm.get('residencePttNumber').hasError('required')
              ">
              {{ 'NEW_SUBJECT.ADDRESS.PTT_NUMBER_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_SUBJECT.ADDRESS.AREA_TYPE' | translate
              }}</mat-label>
            <mat-select formControlName="residenceAreaType" [compareWith]="compareObjects">
              <mat-option></mat-option>
              <mat-option *ngFor="let areaType of AreaType" [value]="areaType">
                {{ areaType.title }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                newSubjectForm.get('residenceAreaType').hasError('required')
              ">
              {{ 'NEW_SUBJECT.ADDRESS.AREA_TYPE_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <field-holder kind="3-cols-2-1-1">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_SUBJECT.ADDRESS.RESIDENCE_ADDRESS' | translate
              }}</mat-label>
            <input type="text" matInput formControlName="residenceAddress" (input)="checkLengthStreetName('residence')"
              [matAutocomplete]="autoResidenceAddress" />
            <mat-autocomplete #autoResidenceAddress="matAutocomplete">
              <mat-option></mat-option>
              <mat-option *ngFor="let option of filteredResidenceAddressOptions | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="
                newSubjectForm.get('residenceAddress').hasError('required')
              ">
              {{ 'NEW_SUBJECT.ADDRESS.RESIDENCE_ADDRESS_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_SUBJECT.ADDRESS.ADDRESS_NUM' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="residenceAddressNum" autocomplete="off" />

            <mat-error *ngIf="
                newSubjectForm
                  .get('residenceAddressNum')
                  .hasError('required') ||
                newSubjectForm.get('residenceAddressNum').hasError('pattern')
              ">
              {{ 'NEW_SUBJECT.ADDRESS.ADDRESS_NUM_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'NEW_SUBJECT.ADDRESS.ADDRESS_SUB_NUM' | translate
              }}</mat-label>
            <input matInput type="text" formControlName="residenceAddressSubNum" autocomplete="off" />
            <mat-error *ngIf="
                newSubjectForm.get('residenceAddressSubNum').hasError('pattern')
              ">
              {{ 'NEW_SUBJECT.ADDRESS.ADDRESS_SUB_NUM_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
      </div>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.ADDRESS.PHONE' | translate }}</mat-label>
          <input matInput type="text" formControlName="phone" autocomplete="off" />
          <mat-error *ngIf="newSubjectForm.get('phone').hasError('pattern')">
            {{ 'NEW_SUBJECT.ADDRESS.PHONE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.ADDRESS.MOBILE' | translate }}</mat-label>
          <input matInput type="text" formControlName="mobile" autocomplete="off" />
          <mat-error *ngIf="newSubjectForm.get('mobile').hasError('pattern')">
            {{ 'NEW_SUBJECT.ADDRESS.MOBILE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.ADDRESS.EMAIL' | translate }}</mat-label>
          <input matInput type="text" formControlName="email" autocomplete="off" />
          <mat-error *ngIf="newSubjectForm.get('email').hasError('email')">
            {{ 'NEW_SUBJECT.ADDRESS.EMAIL_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
    </form-group>
    <form-group title="{{ 'NEW_SUBJECT.FAMILY.TITLE' | translate }}">
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.FAMILY.FAMILY_DOSSIER' | translate
            }}</mat-label>
          <span matTooltip="{{
              'NEW_SUBJECT.FAMILY.DOSSIER_STATUS_DISABLED' | translate
            }}" [matTooltipDisabled]="
              !(familyDossierStatus !== 'undefined' && fammilyMembers !== 0)
            ">
            <mat-select formControlName="familyDossier" [disabled]="
                familyDossierStatus !== 'undefined' && fammilyMembers !== 0
              ">
              <mat-option> </mat-option>
              <mat-option *ngFor="let status of familyDossier" [value]="status.value">
                {{ status.viewValue }}
              </mat-option>
            </mat-select>
          </span>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.FAMILY.JMBP' | translate }}</mat-label>
          <input matInput maxlength="13" type="text" formControlName="jmbp" autocomplete="off"
            [readOnly]="isCarrierOrMember" />
          <mat-hint>{{ 'NEW_SUBJECT.FAMILY.JMBP_HINT' | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.FAMILY_TYPE' | translate
            }}</mat-label>
          <mat-select overlayPanelClass="mat-select-panel" [compareWith]="compareObjects" formControlName="familyType">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of familyType" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.PERSONAL_DETAILS.MARITAL_STATUS' | translate
            }}</mat-label>
          <mat-select overlayPanelClass="mat-select-panel" [compareWith]="compareObjects"
            formControlName="maritalStatus">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of maritalStatusOptions" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
    </form-group>
    <form-group title="{{ 'NEW_SUBJECT.OTHER.TITLE' | translate }}" isLast="true">
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.OTHER.NOTE' | translate }}</mat-label>
          <textarea matInput type="text" formControlName="note" autocomplete="off"></textarea>
          <mat-error *ngIf="newSubjectForm.get('note').hasError('required')">
            {{ 'NEW_SUBJECT.OTHER.NOTE_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.OTHER.NOTE1' | translate }}</mat-label>
          <textarea matInput type="text" formControlName="note1" autocomplete="off"></textarea>
          <mat-error *ngIf="newSubjectForm.get('note1').hasError('required')">
            {{ 'NEW_SUBJECT.OTHER.NOTE1_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.OTHER.NOTE2' | translate }}</mat-label>
          <textarea matInput type="text" formControlName="note2" autocomplete="off"></textarea>
          <mat-error *ngIf="newSubjectForm.get('note2').hasError('required')">
            {{ 'NEW_SUBJECT.OTHER.NOTE2_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_SUBJECT.OTHER.NOTE3' | translate }}</mat-label>
          <textarea matInput type="text" formControlName="note3" autocomplete="off"></textarea>
          <mat-error *ngIf="newSubjectForm.get('note3').hasError('required')">
            {{ 'NEW_SUBJECT.OTHER.NOTE3_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols-2-1-1">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_SUBJECT.OTHER.DATE_OF_CREATION' | translate
            }}</mat-label>
          <input [disabled]="isDisabledRegistrationDate" matInput [matDatepicker]="dateOfCreation"
            formControlName="dateOfCreation" required autocomplete="off" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dateOfCreation"></mat-datepicker-toggle>
          <mat-datepicker #dateOfCreation></mat-datepicker>
          <mat-error *ngIf="newSubjectForm.get('dateOfCreation').hasError('required')">
            {{ 'NEW_SUBJECT.OTHER.DATE_OF_CREATION_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="registrationId === null || registrationId === undefined">
          <div>
            <mat-checkbox (change)="setRegistrationNumber('automatic')" color="primary" class="form__checkbox"
              formControlName="regNumberAutomatic">
              {{ 'NEW_SUBJECT.OTHER.SET_REG_NUM' | translate }}
            </mat-checkbox>
            <mat-checkbox (change)="setRegistrationNumber('input')" color="primary" class="form__checkbox"
              formControlName="regNumberInput">
              {{ 'NEW_SUBJECT.OTHER.INPUT_REG_NUM' | translate }}
            </mat-checkbox>
          </div>
        </ng-container>

        <mat-form-field appearance="outline" *ngIf="
            (registrationId === null || registrationId === undefined) &&
            typeOfInputRegNum === true
          ">
          <mat-label>{{ 'NEW_SUBJECT.OTHER.REG_NUM' | translate }}</mat-label>
          <input matInput type="text" formControlName="newRegNumber" autocomplete="off" required onlyNumber />
          <mat-error *ngIf="newSubjectForm.get('newRegNumber').hasError('invalid')">
            {{ 'NEW_SUBJECT.OTHER.REG_NUM_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
    </form-group>
    <form-footer>
      <button class="form-footer__button form-footer__button--secondary" mat-stroked-button color="primary"
        type="button" (click)="handleCancel()">
        {{ 'GLOBAL.CANCEL' | translate }}
      </button>
      <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary" type="button"
        (click)="submitSubject(newSubjectForm)"
        [disabled]="(!newSubjectForm.valid && possibleToRegisterOnCenter) || !isSavePossible" [matTooltip]="tooltipText"
        #submitButton>
        {{
        (subjectId === undefined
        ? 'NEW_SUBJECT.SUBMIT'
        : 'NEW_SUBJECT.CHANGE'
        ) | translate
        }}
      </button>
    </form-footer>
  </form>
</mat-dialog-content>