/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as myLetters from '../../../../letters';
import { MAT_DATEPICKER_VALIDATORS } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'new-foster-child-center-step',
  templateUrl: './new-foster-child-center-step.component.html',
  styleUrls: ['./new-foster-child-center-step.component.scss']
})

export class NewFosterChildCenterStepComponent implements OnInit {
  @Output() centerStepEmitter = new EventEmitter();
  @Input() centerStep: FormGroup;
  @Input() personalInformationStep: FormGroup;
  @Input() familyInformationStep: FormGroup;
  @Input() reasonsForParentalAbsenceStep: FormGroup;
  @Input() closeMembersStep: FormGroup;
  @Input() suitabilityStep: FormGroup;
  currentDate = new Date();

  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.centerStep = this.formBuilder.group({
      guardianshipName: ['', [Validators.required]],
      address: [''],
      phone: ['', [
        Validators.minLength(9),
        Validators.maxLength(13),
        Validators.pattern('^[0-9]*$'),
      ]],
      emailAddress: ['', [Validators.email]],
      questionnaireNumber: [''],
      questionnaireDate: [''],
    });
  }

  /**
   * Send step information to parent component
   */
  updateCenterStep() {
    this.centerStepEmitter.emit(this.centerStep);
  }
}
