import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventEnum, FamilyMember } from 'src/app/entrance/types';
import { LocalStorageService } from 'src/app/local-storage.service';
import { checkRelationshipAgeDifference } from 'src/app/utils/checkRelationshipAgeDifference';
import { CodebookService } from 'src/services/codebook.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { Subject } from 'src/types';

interface FamilyDossier {
   value: string;
   viewValue: string;
}

@Component({
   selector: 'app-family-dossier',
   templateUrl: './family-dossier.component.html',
   styleUrls: ['./family-dossier.component.scss'],
})
export class FamilyDossierComponent implements OnInit {
   familyMembers: FormGroup;
   relationshipOptions = [];
   members: Array<FamilyMember> = [];
   statuses: FamilyDossier[] = [
      { value: 'CARRIER', viewValue: 'Носилац' },
      { value: 'MEMBER', viewValue: 'Члан' },
   ];

   familyDossierId = null;
   @ViewChild('submitButton') submitButton;

   constructor(
      private formBuilder: FormBuilder,
      private codebook: CodebookService,
      public subjectService: SubjectsService,
      public dialogRef: MatDialogRef<FamilyDossierComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public familyData: any,
      private localStorageService: LocalStorageService,
      private toastr: ToastrImplService
   ) {
      this.codebook.getRelationshipCodebookWithoutUser().subscribe(result => {
         this.relationshipOptions = result;
      });
   }

   ngOnInit(): void {
      this.familyMembers = this.formBuilder.group({
         name: ['', [Validators.required]],
         subjectId: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(7), Validators.pattern('^[0-9]*$')])],
         relationship: ['', []],
         jmbp: ['', []],
         regId: ['', []],
         status: [''],
         familyDossierId: ['', []],
         dateOfBirth: [''],
         jmbg: [''],
         yearOfBirth: [''],
         foreigner: [false],
      });

      if (this.familyData.data) {
         this.patchForm(this.familyData.data);
      }
   }

   /**
    * Update form afer getting the subject data (on init, or after adding new user)
    */
   patchForm(data: Partial<Subject>) {
      const { firstName, lastName, registrationId, subjectIdentity, familyDossierId, jmbp, dateOfBirth, jmbg, yearOfBirth, foreigner } = data;

      // Patch form with retrived data
      this.familyMembers.patchValue({
         name: `${firstName} ${lastName}`,
         regId: registrationId,
         subjectId: subjectIdentity.subjectId,
         familyDossierId,
         status: this.statuses[1].value,
         jmbp,
         dateOfBirth,
         jmbg,
         yearOfBirth,
         foreigner,
      });
   }

   writeDossierMemberData() {
      // Provjera da li postoji clan sa odabranim ID brojem
      if (this.checkSubjectId(this.familyMembers.controls.subjectId.value)) {
         this.dialogRef.close({ event: EventEnum.CANCEL });
      }
   }

   checkSubjectId(subjectId: any): boolean {
      const x = this.subjectService.getSubject(subjectId);
      x.subscribe(
         res => {
            if (res !== null && res !== '') {
               return true;
            } else {
               this.toastr.error('SNACKBAR.USER_NOT_EXIST');
               return false;
            }
         },
         () => {
            console.log('Error occured');
         }
      );
      return false;
   }

   closeDialog() {
      this.dialogRef.close({ event: EventEnum.CANCEL });
   }

   addFamilyMembers() {
      // ovo bi bilo potrebno izmeniti da ne zavisi od id u bazi @Andrija Trifunovic
      if (this.familyMembers.value.relationship !== null) {
         if (!checkRelationshipAgeDifference(this.familyMembers.value, this.familyData.subject, this.familyMembers.value.relationship.id, this.toastr)) {
            this.dialogRef.close({ event: EventEnum.CANCEL });
            return;
         }
      }

      if (this.familyMembers.value.firstLastName !== null) {
         this.members.push(this.familyMembers.value);
      }
      // Validacija unosa
      if (this.familyMembers.value.regId === undefined) {
         this.closeDialog();
         this.toastr.error('SNACKBAR.CANT_BE_MEMBER_IN_DOSSIER');
      } else {
         const dossierObject = {
            familyDossierIdentity: {
               csrId: JSON.parse(this.localStorageService.get('loggedUser')).csrId,
               familyDossierId: this.familyMembers.value.familyDossierId !== null ? this.familyMembers.value.familyDossierId : this.familyDossierId,
            },
            familyMembers: this.members,
         };
         // provjeriti da li vec postoji u listi clanova porodicnog dosijea
         // sacuvaj novog clana u bazu
         this.subjectService.addFamilyMember(dossierObject).subscribe(
            result => {
               this.dialogRef.close({ event: EventEnum.SUBMIT, data: this.members });
            },
            error => {
               // Dodatno obraditi izuzetak sa BE
               this.submitButton.disabled = false;
               this.toastr.error('SNACKBAR.ALREADY_MEMBER_OF_DOSSIER');
               this.dialogRef.close({ event: EventEnum.SUBMIT, data: this.members });
            }
         );
      }
   }
}
