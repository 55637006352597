/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/local-storage.service';
import { StatisticsService } from 'src/services/statistics.service';
import { CodebookService } from 'src/services/codebook.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/services/user.service';
import { map, startWith } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';

enum AutocompleteFilterEnum {
  CENTERS = 'centers',
}
@Component({
  selector: 'record-control-stats',
  templateUrl: './record-control-stats.component.html',
  styleUrls: ['./record-control-stats.component.scss']
})

export class RecordControlStatsComponent implements OnInit {
  recordControlStatsForm: FormGroup;
  centers = [];
  filteredAllCenters: Observable<any>;
  loggedIn: any;
  isMainCenterBelgrade = false;
  btnDisabled: boolean;
  isUserDrugostepeni = false;

  recordOptions = [
    { id: 1, name: 'Активни корисници без евидентираних права, мера и услуга' },
    { id: 2, name: 'Активни корисници којима недостаје потреба/проблем' },
    { id: 3, name: 'Пасивизирани корисници без евидентираних права, мера и услуга' },
    { id: 4, name: 'Пасивизирани корисници којима недостаје потреба/проблем' },
  ];
  constructor(private formBuilder: FormBuilder,
              private localStorage: LocalStorageService,
              private statisticsService: StatisticsService,
              private codebookService: CodebookService,
              private translate: TranslateService,
              private userService: UserService) {
    this.statisticsService.getBtnStatus().subscribe((status) => {
      this.btnDisabled = status;
    });
    this.btnDisabled = false;
    this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
    this.isMainCenterBelgrade = (this.loggedIn?.csrId === '1') ? true : false;
    this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
    if (this.isMainCenterBelgrade) {
      this.codebookService.getAllSubcenters().subscribe(result => {
        this.centers = result;
        this.filteredAllCenters = this.recordControlStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );      });
    }
    if(this.isUserDrugostepeni){
      this.codebookService.getCsrCodebook().subscribe((result) => {
        this.centers = result;
        this.filteredAllCenters = this.recordControlStatsForm.controls.center.valueChanges.pipe(
           startWith(''),
           map((value) => (typeof value === 'string' ? value : value?.name)),
           map((title) =>
              title
                 ? this._filter(title, AutocompleteFilterEnum.CENTERS)
                 : this.centers.slice()
           )
        );
     });
    }
  }

  ngOnInit(): void {
    this.recordControlStatsForm = this.formBuilder.group({
      record: ['', [Validators.required]],
      center: [''],
    });
  }
  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name : objectValue;
  }
  autoSelectCenter(){
    autoSelect(this.centers, 'center', 'name', this.recordControlStatsForm);
  }
  /**
   * Send request and generate Excell report
   */
  sendRequest(form: any) {
    const dto = {
      csrId: this.isMainCenterBelgrade || this.isUserDrugostepeni ? form.value.center.id : this.loggedIn.csrId,
      id: form.value.record.id
    };
    let filename = '';
    this.translate.get('STATISTICS.RECORD_CONTROL_STATS.TITLE').subscribe((res: string) => {
      filename = res;
    });
    this.statisticsService.getControlStats(dto, filename);
  }
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
       case AutocompleteFilterEnum.CENTERS:
          return sortByFirstLetter(this.centers.filter((option) =>
             option.name.toLowerCase().includes(filterValue)), filterValue, 'name');
       default:
          break;
    }
 }
}
