import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
interface SelectOptions {
   value: any;
   viewValue: string;
}
@Component({
   selector: 'app-input-participation-sk',
   templateUrl: './input-participation-sk.component.html',
   styleUrls: ['./input-participation-sk.component.scss']
})
export class InputParticipationSkComponent implements OnInit {
   socialMapPartForm: FormGroup;
   jmbgList :string[]=[];
   relationshipOptions: SelectOptions[] = [
      { value: '0', viewValue: 'Подносилац захтева' },
      { value: '1', viewValue: 'Брачни друг' },
      { value: '2', viewValue: 'Ванбрачни друг' },
      { value: '3', viewValue: 'Дете' },
      { value: '4', viewValue: 'Родитељ' },
      { value: '5', viewValue: 'Брат/сестра' },
      { value: '6 ', viewValue: 'Лице обавезано на давање издржавања' },
      { value: '7', viewValue: 'Баба/деда' },
      { value: '8', viewValue: 'Унук/унука' },
      { value: '9', viewValue: 'Лице узето на издржавање' },
      { value: '11', viewValue: 'Старатељ' },
      { value: '12', viewValue: 'Хранитељ' },
      { value: '13', viewValue: 'Штићеник' },
      { value: '14', viewValue: 'Храњеник' },
      { value: '10', viewValue: 'Остало' }
   ];
   constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<any>,
           @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
) { }

   ngOnInit(): void {
      this.socialMapPartForm = this.formBuilder.group({
         firstName: [null, Validators.required],
         lastName: [null, Validators.required],
         jmbg: [null, Validators.required],
         relationship: [null, Validators.required]

      });
   }
   closeDialog() {
      this.dialogRef.close({ event: 'cancel' });
   }
   checkJMBGRule(event: any) {
      if (this.socialMapPartForm.value.jmbg !== null && this.socialMapPartForm.value.jmbg !== '') {
         if (this.socialMapPartForm.value.jmbg.length < 13) {
            this.socialMapPartForm.get('jmbg').setErrors({ pattern: true });
         } else {
            const jmbg = this.socialMapPartForm.value.jmbg;
            const digits = jmbg.split('').map(Number);
            const delimiter = 11;
            let sum = 0;
            let start = 7;
            for (let index = 0; index < digits.length - 1; index++, start--) {
               if (start === 1) {
                  start = 7;
               }
               sum += start * digits[index];
            }
            let controlNumber = sum % delimiter;
            if (controlNumber > 1) {
               controlNumber = delimiter - controlNumber;
            }
            if (controlNumber !== digits[digits.length - 1]) {
               this.socialMapPartForm.get('jmbg').setErrors({ controlNumber: true });
               setTimeout(() => {
                  this.socialMapPartForm.get('jmbg').setErrors({ controlNumber: true });
                  this.socialMapPartForm.controls.jmbg.markAsTouched();
               });
            }
            if(this.jmbgList?.includes(this.socialMapPartForm.value.jmbg)){
               this.socialMapPartForm.get('jmbg').setErrors({unique:true})
            }
         }
      }
   }

   addNewPart() {
      this.dialogRef.close({ data: this.socialMapPartForm.value, event: 'confirm' });
   }
}
