/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LegalSubject } from 'src/models/legalSubject.model';
import { LegalSubjectsService } from 'src/services/legal-subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';

@Component({
   selector: 'app-legal-subject-details',
   templateUrl: './legal-subject-details.component.html',
   styleUrls: ['./legal-subject-details.component.scss'],
})
export class LegalSubjectDetailsComponent implements OnInit {
   minFromDate = new Date();
   id: any;
   isEditMode = false;
   legalSubject: any;
   isEditable = true;
   isSubjectHistoryRendered: Boolean = false;

   constructor(
      private route: ActivatedRoute,
      private legalSubjectService: LegalSubjectsService,
      public dialog: MatDialog,
      private toastr: ToastrImplService,
      public userService: UserService
   ) {
      this.route.params.subscribe(params => {
         this.id = params.id;

         this.getCurrentSubject(this.id);
      });
   }

   public patchLegalSubjectAfterEdit(legalSubject: LegalSubject): void {
      if (typeof legalSubject !== 'undefined') {
         this.getCurrentSubject(legalSubject.id);
         this.toastr.success('SNACKBAR.DATA_CHANGED');
         this.isSubjectHistoryRendered = false;
      }
      this.isEditMode = false;
   }

   selectTab(tab: any) {
      if (tab.index === 1) {
         this.isSubjectHistoryRendered = true;
      }
   }

   getCurrentSubject(id: any) {
      this.legalSubjectService.getCurrentLegalSubject(id).subscribe(res => {
         this.legalSubject = res;
         this.legalSubject.dateOfModification = this.legalSubject.dateOfModification?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
         this.isEditable = !this.legalSubject?.isLoaded || this.userService.isGlobalAdmin();
      });
   }

   formatDate(date: string) {
      const [y, m, d] = date.split('-');
      return [d, m, y].join('.');
   }

   editLegalSubject() {
      this.isEditMode = true;
   }

   ngOnInit(): void {}
}
