<container kind="mini">
  <page-intro-header title="{{ 'HOME.NOTIFICATIONS' | translate }}">
  </page-intro-header>
  <mat-card class="card">
    <no-result-item *ngIf="notifications.length === 0">{{ 'HOME.CARDS.NOTIFICATIONS.NO_NOTIFICATIONS' | translate }}</no-result-item>
    <mat-list >
      <mat-list-item *ngFor="let notification of notifications; last as last">
        <h4 mat-line>
          <mat-label>
            <b>{{ notification.fromWho }}</b></mat-label
          >
        </h4>
        <h2 mat-line>{{ notification.message }}</h2>
        <span class="date">{{
          notification.date | date: 'dd.MM.yyyy. h:mm:ss'
        }}</span>
        <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-card>
  <mat-card class="paginator-wrapper">
    <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
      (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
    </app-custom-pagination>
  </mat-card>
</container>
