<mat-vertical-stepper #stepper class="stepper">
  <mat-step [stepControl]="servicePlan">
    <ng-template matStepLabel>{{
      'VS.FORM5.SERVICE_PLAN_STEP' | translate
      }}</ng-template>

    <form [formGroup]="servicePlan" class="form">
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'VS.FORM5.SERVICE_PLAN.FIRST_LAST_NAME' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="firstAndLastName" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'VS.FORM5.SERVICE_PLAN.REG_BR' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="regBr" autocomplete="off" />
        </mat-form-field>
      </field-holder>

      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'VS.FORM5.SERVICE_PLAN.PLAN' | translate }}</mat-label>
          <mat-select formControlName="plan" [compareWith]="comparePlan">
            <mat-option></mat-option>
            <mat-option *ngFor="let plan of servicePlanList" [value]="plan">
              {{ plan.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.FORM5.SERVICE_PLAN.AREAS_OF_WORK' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="areasOfWork" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.FORM5.SERVICE_PLAN.STRENGTH' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="strength" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.FORM5.SERVICE_PLAN.GOAL' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="goal" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <button mat-flat-button color="primary" type="button" (click)="addTask()" class="mb-16">
        {{ 'VS.FORM5.SERVICE_PLAN.ADD_TASK' | translate }}
      </button>
      <div *ngIf="templatedTasks.length > 0">
        <table mat-table [dataSource]="templatedTasks" #mytable class="table mb-32">
          <ng-container matColumnDef="outcome">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.TASKS.OUTCOME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.outcome }}
            </td>
          </ng-container>
          <ng-container matColumnDef="task">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.TASKS.TASK' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.task }}
            </td>
          </ng-container>
          <ng-container matColumnDef="responsiblePerson">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.TASKS.RESPONSIBLE_PERSON' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.responsiblePerson }}
            </td>
          </ng-container>
          <ng-container matColumnDef="fromDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.TASKS.FROM_DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.fromDate | date: 'dd.MM.yyyy.' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="toDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.TASKS.TO_DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.toDate | date: 'dd.MM.yyyy.' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.ACTION_OPTION' | translate }}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" class="action-link">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editTask(i)">
                  <mat-icon>edit</mat-icon>
                  <span> {{ 'VS.EDIT' | translate }}</span>
                </button>
                <button mat-menu-item (click)="deleteTask(i)">
                  <mat-icon>delete</mat-icon>
                  <span>{{ 'VS.FORM5.DELETE' | translate }}</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsServicePlan"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsServicePlan" class="problems-table__row"></tr>
        </table>
      </div>

      <form-subgroup-divider></form-subgroup-divider>

      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.FORM5.SERVICE_PLAN.NOTES' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="notes" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>

      <form-footer>
        <button mat-stroked-button matStepperNext color="primary" class="form-footer__button">
          {{ 'NSP_MOCK.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>

  <mat-step [stepControl]="participationPlan">
    <ng-template matStepLabel>{{
      'VS.FORM5.PARTICIPATION_PLAN_STEP' | translate
      }}</ng-template>
    <form [formGroup]="participationPlan" class="form">
      <button mat-flat-button color="primary" type="button" (click)="addUser()" class="mb-16">
        {{ 'VS.FORM5.PARTICIPATION_PLAN.ADD_USER' | translate }}
      </button>

      <div *ngIf="templatedUsers.length > 0">
        <table mat-table [dataSource]="templatedUsers" #mytable class="table">
          <ng-container matColumnDef="firstAndLastName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.USERS_FORM.FIRST_LAST_NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.firstAndLastName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="relationship">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.USERS_FORM.RELATIONSHIP' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.relationship?.title }}
            </td>
          </ng-container>
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.USERS_FORM.PHONE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.phone }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.ACTION_OPTION' | translate }}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" class="action-link">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteUser(i)">
                  <mat-icon>delete</mat-icon>
                  <span>{{ 'VS.FORM5.DELETE' | translate }}</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsParticipationPlan"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsParticipationPlan" class="problems-table__row"></tr>
        </table>
      </div>

      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'NSP_MOCK.PREVIOUS_STEP' | translate }}
        </button>

        <button mat-stroked-button matStepperNext color="primary" class="form-footer__button">
          {{ 'NSP_MOCK.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>

  <mat-step [stepControl]="userComment">
    <ng-template matStepLabel>{{
      'VS.FORM5.USER_COMMENT_STEP' | translate
      }}</ng-template>

    <form [formGroup]="userComment" class="form">
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.FORM5.USER_COMMENT.COMMENT' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="comment" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>

      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'VS.FORM5.USER_COMMENT.COMMENT_DATE' | translate
            }}</mat-label>
          <input matInput [matDatepicker]="pickerCommentDate" [min]="minToDate" formControlName="commentDate"
            autocomplete="off" (dateChange)="checkDate('commentDate',$event)" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerCommentDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerCommentDate></mat-datepicker>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <button mat-flat-button color="primary" type="button" (click)="addUserComment()" class="mb-16">
        {{ 'VS.FORM5.USER_COMMENT.ADD_USER_COMMENT' | translate }}
      </button>
      <div *ngIf="templatedComments.length > 0">
        <table mat-table [dataSource]="templatedComments" #mytable class="table">
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.USER_COMMENT.USER_COMMENT' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.comment }}
            </td>
          </ng-container>
          <ng-container matColumnDef="relationship">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.USER_COMMENT.RELATIONSHIP' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.relationship?.title }}
            </td>
          </ng-container>
          <ng-container matColumnDef="signature">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.USER_COMMENT.SIGNATURE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.signature }}
            </td>
          </ng-container>
          <ng-container matColumnDef="commentDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.FORM5.USER_COMMENT.COMMENT_DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.commentDate | date: 'dd.MM.yyyy.' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'VS.ACTION_OPTION' | translate }}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" class="action-link">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editUserComment(i)">
                  <mat-icon>edit</mat-icon>
                  <span>{{ 'VS.EDIT' | translate }}</span>
                </button>
                <button mat-menu-item (click)="deleteComment(i)">
                  <mat-icon>delete</mat-icon>
                  <span>{{ 'VS.FORM5.DELETE' | translate }}</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsComments"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsComments" class="problems-table__row"></tr>
        </table>
      </div>
      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'NSP_MOCK.PREVIOUS_STEP' | translate }}
        </button>
        <button mat-stroked-button matStepperNext color="primary" class="form-footer__button">
          {{ 'NSP_MOCK.NEXT_STEP' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-step>
  <mat-step [stepControl]="evaluationPlan">
    <ng-template matStepLabel>
      {{ 'VS.FORM5.EVALUATION_PLAN_STEP' | translate }}
    </ng-template>
    <form [formGroup]="evaluationPlan" class="form">
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'VS.FORM5.EVALUATION_PLAN.TO_DATE' | translate
            }}</mat-label>
          <input matInput [matDatepicker]="pickerStartDate" [min]="minToDate" formControlName="toDate"
            (dateChange)="checkDate('toDate',$event)" autocomplete="off" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerStartDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'VS.FORM5.EVALUATION_PLAN.EVALUATION_METHOD' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="evaluationMethod" autocomplete="off" maxlength="250" />
        </mat-form-field>
      </field-holder>
      <div class="radio-group">
        <span class="radio-group__label">{{
          'VS.FORM5.EVALUATION_PLAN.EVALUATION_COPY' | translate
          }}</span>
        <mat-radio-group aria-label="Select an option" color="primary" formControlName="copyPlan">
          <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value" class="radio-group__option">
            {{ option.viewValue }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.FORM5.EVALUATION_PLAN.NOTE' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="note" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.FORM5.EVALUATION_PLAN.USER_NEEDS' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="needsCannotBeMet" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'VS.FORM5.EVALUATION_PLAN.STEPS' | translate }}
          </mat-label>
          <textarea matInput type="text" formControlName="steps" autocomplete="off"></textarea>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button matStepperPrevious mat-stroked-button color="primary"
          class="form-footer__button form-footer__button--secondary">
          {{ 'NSP_MOCK.PREVIOUS_STEP' | translate }}
        </button>
        <button mat-flat-button mat-stroked-button (click)="preview()"
          class="form-footer__button form-footer__button--secondary">
          {{ 'VS.PREVIEW' | translate }}
        </button>
        <div matTooltip="{{'VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.NO_SUPERVISOR' | translate}}"
          [matTooltipDisabled]="hasSupervisor">
          <button mat-flat-button color="primary" type="button" (click)="submitForm5()"
            #submitButton [disabled]="!hasSupervisor">
            {{ 'VS.FORM5.SAVE_FORM_5' | translate }}
          </button>
        </div>
      </form-footer>
    </form>
  </mat-step>
</mat-vertical-stepper>
<button mat-stroked-button color="primary" (click)="closeForm()" type="button"
  class="form-footer__button form-footer__button--secondary">
  {{ 'DIALOG.CANCEL' | translate }}
</button>
<button mat-flat-button color="primary" class="form-footer__button form-footer__button--secondary"
  (click)="saveForm5()">
  {{ 'VS.SAVE' | translate }}
</button>