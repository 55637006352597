/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalEventEnum } from 'src/types';
import { DatePipe } from '@angular/common';
import * as myLetters from './../../../../letters';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'new-dependent-table-two',
  templateUrl: './new-dependent-table-two.component.html',
  styleUrls: ['./new-dependent-table-two.component.scss']
})
export class NewDependentTableTwoComponent implements OnInit {
  newRecord: FormGroup;
  currentDate = new Date();
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.newRecord = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
      lastName: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
      dateOfBirth: [null],
      placeOfBirth: [''],
      foreigner: [false],
      jmbg: ['', [Validators.pattern('^[0-9]*$')]],
      address: [''],
      legalRelation: ['']
    });
  }

  addNewRecord(trackingDocumentForm: FormGroup) {
    if (trackingDocumentForm.value.dateOfBirth !== null) {
      trackingDocumentForm.get('dateOfBirth').setValue(
        this.datePipe.transform(new Date(trackingDocumentForm.value.dateOfBirth).getTime(), 'yyyy-MM-dd'));
    }
    this.dialogRef.close({
      event: ModalEventEnum.SUCCESS,
      data: trackingDocumentForm.value,
    });

  }

  closeDialog() {
    this.dialogRef.close({ event: ModalEventEnum.CANCEL });
  }

  updateJMBGOnDateOfBirthChange(event: any) {
    if (event != null && !this.newRecord.value.foreigner) {
      if (this.newRecord.value.dateOfBirth !== null) {
        const birthDate = new Date(this.newRecord.value.dateOfBirth).getTime();
        const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
        const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
        if (this.newRecord.value.jmbg !== null && this.newRecord.value.jmbg.length === 13) {
          const jmbg = this.newRecord.value.jmbg.substring(0, 7);
          if (newBirthDate !== jmbg) {
            this.newRecord.get('dateOfBirth').setErrors({ incorrect: true });
          } else {
            this.newRecord.get('dateOfBirth').setErrors(null);
          }
        }
      }
    }
  }
  importSubjectData() {
    const dialogRef = this.dialog.open(SubjectsComponent, {
      width: '1200px',
      panelClass:'overlay-panel',
      data: {
        origin: 'entrance',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.newRecord.patchValue({
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          placeOfBirth: result.data.placeOfBirth,
          address: String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
            String(result.data.permanentResidence.number ? ' ' : '') +
            String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
            String(result.data.permanentResidence.subnumber ? '/' : '') +
            String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
          foreigner: result.data.foreigner,
          jmbg: result.data.jmbg,
          dateOfBirth: result.data.dateOfBirth !== null ? new Date(result.data.dateOfBirth?.replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3')) : null,
        });
        const obj = JSON.parse(JSON.stringify(result));
        this.newRecord.controls.jmbg.markAsTouched();
        this.newRecord.controls.dateOfBirth.markAsTouched();
      }
    });
  }

}
