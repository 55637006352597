<container>
  <page-intro-header title="{{ 'NEW_FOSTER_CHILD.TITLE' | translate }}" back="/foster-child">
  </page-intro-header>
  <mat-card class="card new-foster-child">
    <mat-vertical-stepper (selectionChange)="onStepChange($event)" linear="true" #stepper>
      <mat-step [stepControl]="personalInformationStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_CHILD.PERSONAL_INFORMATION.TITLE' | translate
        }}</ng-template>
        <new-foster-child-personal-information-step [personalInformationStep]="personalInformationStep"
          (personalInformationStepEmitter)="updatePersonalInformationStep($event)">
        </new-foster-child-personal-information-step>
      </mat-step>
      <mat-step [stepControl]="parentInformationStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_CHILD.PARENT_INFORMATION.TITLE' | translate
        }}</ng-template>
        <new-foster-child-parent-information-step [subjectData]="personalInformationStep"
          [parentInformationStep]="parentInformationStep"
          (parentInformationStepEmitter)="updateParentInformationStep($event)">
        </new-foster-child-parent-information-step>
      </mat-step>
      <mat-step [stepControl]="reasonsForParentalAbsenceStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_CHILD.REASONS_FOR_PARENTAL_ABSENCE.TITLE' | translate
        }}</ng-template>
        <new-foster-child-reasons-for-parental-absence-step
          [reasonsForParentalAbsenceStep]="reasonsForParentalAbsenceStep"
          (reasonsForParentalAbsenceStepEmitter)="updateReasonsForParentalAbsenceStep($event)">
        </new-foster-child-reasons-for-parental-absence-step>
      </mat-step>
      <mat-step [stepControl]="closeMembersStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_CHILD.CLOSE_MEMBERS.TITLE' | translate
        }}</ng-template>
        <new-foster-child-close-members-step [closeMembersStep]="closeMembersStep"
          [subjectData]="personalInformationStep" (closeMembersStepEmitter)="updateCloseMembersStep($event)">
        </new-foster-child-close-members-step>
      </mat-step>
      <mat-step [stepControl]="costCarriersStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_CHILD.COST_CARRIERS.TITLE' | translate
        }}</ng-template>
        <new-foster-child-cost-carriers-step [costCarriersStep]="costCarriersStep"
          (costCarriersStepEmitter)="updateCostCarriersStep($event)"></new-foster-child-cost-carriers-step>
      </mat-step>
      <mat-step [stepControl]="suitabilityStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_CHILD.SUITABILITY.TITLE' | translate
        }}</ng-template>
        <new-foster-child-suitability-step [suitabilityStep]="suitabilityStep"
          (suitabilityStepEmitter)="updateSuitabilityStep($event)"></new-foster-child-suitability-step>
      </mat-step>

      <mat-step [stepControl]="centerStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_PARENT.CENTER.TITLE' | translate
        }}</ng-template>
        <new-foster-child-center-step [centerStep]="centerStep"  [personalInformationStep]= "personalInformationStep"  [reasonsForParentalAbsenceStep]="reasonsForParentalAbsenceStep " [familyInformationStep]="parentInformationStep"  [closeMembersStep]="closeMembersStep" [suitabilityStep] = "suitabilityStep"
          (centerStepEmitter)="updateCenterStep($event)"></new-foster-child-center-step>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card>
</container>