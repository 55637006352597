import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evaluation-of-plan-item',
  templateUrl: './evaluation-of-plan-item.component.html',
  styleUrls: ['./evaluation-of-plan-item.component.scss']
})
export class EvaluationOfPlanItemComponent implements OnInit {
  @Input() key: string;
  @Input() valueOffset: string = '580px';
  @Input() fontSize: 'default' | 'mini' = 'default';
  @Input() meta: string;
  constructor() { }

  ngOnInit(): void {
  }

}
