<mat-expansion-panel (opened)="(panelOpenState)" (closed)="(!panelOpenState)">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ 'GLOBAL_ADMIN.NSP.TITLE' | translate }}
    </mat-panel-title>
    <mat-panel-description>
      {{ 'GLOBAL_ADMIN.NSP.CURRENT_AMOUNT' | translate }}
      {{ nspCurrentValues.value }}
      {{ 'GLOBAL_ADMIN.NSP.VALID_FROM' | translate }}
      {{ nspCurrentValues.validFrom | date : 'dd.MM.yyyy.' }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <form [formGroup]="nspForm" class="form">
    <field-holder kind="2-cols">
      <mat-form-field
        appearance="outline"
        *ngFor="let nspRightCodebook of nspRightCodebooks; index as i"
      >
        <mat-label>{{ nspRightCodebook.title }}</mat-label>
        <input
          matInput
          type="text"
          (change)="checkNspAmount()"
          formControlName="value{{ i }}"
          autocomplete="off"
          required
        />
        <span matSuffix>{{ 'GLOBAL_ADMIN.FORM.RSD' | translate }}</span>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}</mat-label>
        <input
          matInput
          type="text"
          [matDatepicker]="nspValidFrom"
          formControlName="validFrom"
          autocomplete="off"
          placeholder="{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}"
          required
          [max]="maxDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="nspValidFrom"
        ></mat-datepicker-toggle>
        <mat-datepicker #nspValidFrom></mat-datepicker>
      </mat-form-field>
    </field-holder>
    <div class="table-responsive-wrapper">
      <table
        mat-table
        id="myTable"
        class="table"
        #myTable
        [dataSource]="nspDataSource"
      >
        <ng-container matColumnDef="amount1">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'GLOBAL_ADMIN.NSP.TYPE_1' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.amount1 }} {{ 'GLOBAL.RSD' | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="amount2">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'GLOBAL_ADMIN.NSP.TYPE_2' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.amount2 }} {{ 'GLOBAL.RSD' | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="amount3">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'GLOBAL_ADMIN.NSP.TYPE_3' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.amount3 }} {{ 'GLOBAL.RSD' | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'GLOBAL_ADMIN.NSP.VALID_FROM2' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.validFrom | date : 'dd.MM.yyyy.' }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="nspDisplayedColumns"
          class="category__row--header"
        ></tr>
        <tr
          mat-row
          *matRowDef="let element; let row; columns: nspDisplayedColumns"
          class="category__row"
        ></tr>
      </table>
    </div>
    <div class="pagination" *ngIf="page.content.length > 0">
      <app-custom-pagination
        [page]="page"
        (firstPageEvent)="getFirstPage()"
        (previousPageEvent)="getPreviousPage()"
        (nextPageEvent)="getNextPage()"
        (lastPageEvent)="getLastPage()"
        (pageSizeEvent)="getPageInNewSize($event)"
      >
      </app-custom-pagination>
    </div>
    <form-footer>
      <button
        class="form-footer__button form-footer__button--primary"
        mat-flat-button
        color="primary"
        (click)="submitNSPForm(nspForm)"
        [disabled]="!nspForm.valid"
      >
        {{ 'GLOBAL_ADMIN.FORM.SUBMIT' | translate }}
      </button>
    </form-footer>
  </form>
</mat-expansion-panel>
