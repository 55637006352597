/**
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, ViewChild, ElementRef, Optional, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Page } from 'src/app/page';
import { Observable } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { Router } from '@angular/router';
import { CodebookService } from 'src/services/codebook.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { UserService } from 'src/services/user.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

export interface SearchItem {
    name: string;
}

enum AutocompleteFilterEnum {
    SUBCENTER = 'subcenter',
}

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    areLoaded = true;
    selectable = true;
    removable = true;
    readonly separatorKeysCodes: number[] = [COMMA, ENTER];
    public searchTerms: string[] = [];
    csrOuId = null;
    subcenter = new FormControl();
    subcenters = [];
    public globalFilter = '';
    public searchItems: SearchItem[] = [];
    page: Page<any> = new Page();
    public reset_courses = this.page;
    myInput = '';
    object: { origin: string; };
    isUserManager = false;
    displayedColumns: string[] = [
        'center',
        'office',
        'name',
        'role',
        'status'
    ];

    fileUrl: any;
    loggedInCsr: any;
    isMainCenterBelgrade = false;
    isUserDrugostepeni = false;
    allCsrCodebooks = [];
    filteredSubcenters: Observable<any>;

    @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;

    constructor(
        private userService: UserService,
        public dialog: MatDialog,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private paginationService: CustomPaginationService,
        public router: Router,
        private localStorageService: LocalStorageService,
        private codebookService: CodebookService) {
    }

    ngOnInit(): void {

        this.loggedInCsr = JSON.parse(this.localStorageService.get('loggedUser'))?.csrId;

        this.codebookService.getCsrCodebook().subscribe(result => {
            if (this.loggedInCsr) {
                const csr = result.find(e => e.id == this.loggedInCsr);
                this.csrOuId = csr.ouId;
            } else {
                this.subcenters = result;
                this.filteredSubcenters = this.subcenter.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => typeof value === 'string' ? value : value.name),
                        map(title => title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice())
                    );
            }
            this.getFilters();
        });
    }
    getFilters() {
        const tempFilter = JSON.parse(localStorage.getItem('filter'));
        const tempPage = JSON.parse(localStorage.getItem('filterPage'));
        const tempCsrOuId = JSON.parse(localStorage.getItem('filterCsrOuId'));

        if ((tempFilter !== null && tempFilter !== undefined) ||
            (tempPage !== null && tempPage !== undefined) ||
            (tempCsrOuId !== null && tempCsrOuId !== undefined)) {
            this.page = tempPage;
            if (tempFilter !== null) {
                this.searchItems = tempFilter;
                this.searchTerms = this.searchItems.map(function(searchItem) {
                    return searchItem.name;
                });
            }
            if (tempCsrOuId !== null) {
                this.csrOuId = tempCsrOuId.ouId;
                this.subcenter.setValue(tempCsrOuId);
            }
        } else {
            this.getWorkers();
        }
    }

    public getNextPage(): void {
        this.areLoaded = false;
        this.page.pageable = this.paginationService.getNextPage(this.page);
        this.applyFilter(this.searchTerms.length === 0 ? null : this.searchTerms, false);
    }

    public getPreviousPage(): void {
        this.areLoaded = false;
        this.page.pageable = this.paginationService.getPreviousPage(this.page);
        this.applyFilter(this.searchTerms.length === 0 ? null : this.searchTerms, false);
    }
    public getFirstPage(): void {
        this.areLoaded = false;
        this.page.pageable = this.paginationService.getFirstPage(this.page);
        this.applyFilter(this.searchTerms.length === 0 ? null : this.searchTerms, false);
    }

    public getLastPage(): void {
        this.areLoaded = false;
        this.page.pageable = this.paginationService.getLastPage(this.page);
        this.applyFilter(this.searchTerms.length === 0 ? null : this.searchTerms, false);
    }

    public getPageInNewSize(pageSize: any): void {
        this.areLoaded = false;
        this.page.pageable = this.paginationService.getPageInNewSize(
            this.page,
            pageSize
        );

        this.applyFilter(this.searchTerms.length === 0 ? null : this.searchTerms, true);
    }

    onRowClicked(element: any) {
        //   // Na osnovu kliknutog subjecta uzimamo njegov csrId i smestamo ga u localStorage
        this.router.navigate(['/users/', element.userId]);
    }

    getWorkers() {
        this.userService.getFilteredPage(this.page.pageable, this.globalFilter !== '' ?
            this.myInput : this.globalFilter,
            this.csrOuId === null ? '' : this.csrOuId, this.page.pageable.pageNumber === 0 ? false : true)
            .subscribe((page) => {
                this.page = page;
                this.page.pageable.pagePerShow = page.number + 1;

                this.localStorageService.set('filter', JSON.stringify(this.searchItems));
                this.localStorageService.set('filterPage', JSON.stringify(this.page));

                this.areLoaded = true;
            });
    }

    reloadWorkers(event: any) {
        this.localStorageService.set('filterCsrOuId', JSON.stringify(event));
        this.csrOuId = event?.ouId;

        this.applyFilter(this.searchTerms.length === 0 ? null : this.searchTerms, true);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        // Add new search term
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.searchTerms = this.searchItems.map(function(searchItem) {
            return searchItem.name;
        });
        this.globalFilter = '';
        this.applyFilter(this.searchTerms, true);
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
            this.searchTerms = this.searchItems.map(function(searchItem) {
                return searchItem.name;
            });
            this.applyFilter(this.searchTerms.length === 0 ? null : this.searchTerms, true);
        }
    }

    displayCustomFormat(objectValue: any): string {
        return objectValue ? objectValue.name : objectValue;
    }

    private _filter(value: string, what: AutocompleteFilterEnum): any[] {
        const filterValue = value.toLowerCase();

        switch (what) {
            case AutocompleteFilterEnum.SUBCENTER:
                return this.subcenters.filter((option) =>
                    option.name.toLowerCase().includes(filterValue)
                );
            default:
                break;
        }
    }

    applyFilter(filterValue: any, returnToBegining: any) {
        if (returnToBegining === true) {
            this.page.pageable.pageNumber = 0;
        }
        this.userService.getFilteredPage(this.page.pageable, filterValue,
            this.csrOuId === null ? '' : this.csrOuId, this.page.pageable.pageNumber === 0 ? false : true)
            .subscribe((page) => {
                this.page = page;
                this.page.pageable.pagePerShow = page.number + 1;
                this.localStorageService.set('filter', JSON.stringify(this.searchItems));
                this.localStorageService.set('filterPage', JSON.stringify(this.page));

                this.areLoaded = true;
            });
    }
    checkInput() {
        if (this.subcenter.value === '') {
            this.csrOuId = null;
            this.localStorageService.remove('filterCsrOuId');
            if (this.searchTerms.length === 0) {
                this.localStorageService.remove('filter');
                this.localStorageService.remove('filterPage');
            }
            this.applyFilter(this.searchTerms.length === 0 ? null : this.searchTerms, true);
        }
    }


}
