<!--olja.andjelovski CaseKind predstavlja vrstu predmeta iz baze npr. NSP, STAR. Za sada ukoliko je NSP otvara se NSP u svim ostalim slucajevima otvara se forma VODJENJE_SLUCAJA-->
<div class="search-container">
  <form class="search-form" [formGroup]="searchForm" (ngSubmit)="searchCases(searchForm.value.casesTerm)">
    <mat-form-field appearance="outline" class="custom-field" style="max-width: 201px;">
      <mat-label>{{'CASES.STATUSES.SEARCH_CASES' | translate}}</mat-label>
      <input matInput placeholder="{{'CASES.STATUSES.SEARCH_CASES' | translate}}" type="text"
        formControlName="casesTerm" autocomplete="off" />
      <button type="button" *ngIf="searchForm.value.casesTerm" matSuffix mat-icon-button (click)="clear()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>
  <button mat-icon-button class="btn-filter button-square button-square--large" color="primary"
    [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" [matBadge]="badgeCount > 0 ? badgeCount : null"
    matTooltip="{{ 'SUBJECT_CASES.TOOLTIP_FILTER' | translate }}">
    <mat-icon class="button-square__icon">filter_list</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="filter-menu">
    <form [formGroup]="filterForm" class="filter-menu__content" (click)="$event.stopPropagation()">
      <mat-label class="status-label">{{ 'CASES.FILTER.STATUS' | translate }}</mat-label>
      <mat-radio-group formControlName="selectedFilter">
        <field-holder>
          <mat-radio-button value="all" selected color="primary" class="form__checkbox">
            {{ 'CASES.FILTER.ALL_CATEGORIES' | translate }}
          </mat-radio-button>
        </field-holder>
        <field-holder>
          <mat-radio-button value="assigned" color="primary" class="form__checkbox">
            {{ 'CASES.FILTER.ASSIGNED' | translate }}
          </mat-radio-button>
        </field-holder>
        <field-holder>
          <mat-radio-button value="accepted" color="primary" class="form__checkbox">
            {{ 'CASES.FILTER.ACCEPTED' | translate }}
          </mat-radio-button>
        </field-holder>
        <field-holder>
          <mat-radio-button value="member" color="primary" class="form__checkbox">
            {{ 'CASES.FILTER.MEMBER' | translate }}
          </mat-radio-button>
        </field-holder>
      </mat-radio-group>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'CASES.FILTER.CATEGORY' | translate }}</mat-label>
          <mat-select multiple #multiSelect formControlName="categories">
            <mat-option #allCategoriesSelected (click)="toggleAllCategories()" [value]="0">
              {{ 'CASES.FILTER.ALL_CATEGORIES' | translate }}
            </mat-option>
            <mat-option *ngFor="let value of filteredCategories" [value]="value" (click)="togglePerOneCategory()">
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button class="form-footer__button form-footer__button--secondary" mat-stroked-button color="primary"
          type="button" (click)="resetFilter()">
          {{ 'SUBJECT_CASES.FILTER.RESET' | translate }}
        </button>
        <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary" type="button"
          (click)="searchCases(searchForm.value.casesTerm)">
          {{ 'SUBJECT_CASES.FILTER.APPLY' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-menu>
</div>
<div *ngFor="let key of objectKeys(filteredCases)">
  <div *ngIf="filteredCases[key].length">
    <collapsible-group title="{{ key }}" [hidden]="isUserEntrancer" [groupExpanded]="false">
      <div *ngFor="let case of filteredCases[key]">

        <case-list-item *ngIf="case.socialCaseClassificationCodebook.caseKindViewCodebook?.base === 'AP'"
          [link]="'cases/' +  (case.socialCaseClassificationCodebook.oldKindMark!='L'?(case.socialCaseClassificationCodebook.caseKindViewCodebook?.name):(case.socialCaseClassificationCodebook.oldKindMark))   + '/' + case.id + '/' + case.subjectIdentity.subjectId"
          [kind]="case.caseTypeCodebook.mark" [title]="case.caseNumber"
          [caseName]="case.socialCaseClassificationCodebook.title" [date]="case.dateOfCreation"
          [firstName]="case.firstName" [lastName]="case.lastName" [isExternal]=""
          [status]="case.assignedUserId === loggedIn.id ? case.caseStatus : 'MEMBER'" (click)="markAsRead(case)">
        </case-list-item>

        <case-list-item *ngIf="case.socialCaseClassificationCodebook.caseKindViewCodebook?.base ==='NSP'"
          [link]="'cases/' +  case.socialCaseClassificationCodebook.caseKindViewCodebook?.base + '/' + case.id + '/' + case.subjectIdentity.subjectId"
          [kind]="case.caseTypeCodebook.mark" [title]="case.caseNumber" [date]="case.dateOfCreation"
          [caseName]="case.socialCaseClassificationCodebook.title" [firstName]="case.firstName"
          [lastName]="case.lastName" [isExternal]=""
          [status]="case.assignedUserId === loggedIn.id ? case.caseStatus : 'MEMBER'" (click)="markAsRead(case)">
        </case-list-item>

        <case-list-item *ngIf="case.socialCaseClassificationCodebook.caseKindViewCodebook?.base === 'VS'"
          [link]="'cases/' + case.socialCaseClassificationCodebook.caseKindViewCodebook?.base + '/' + case.id + '/' + case.subjectIdentity.subjectId"
          [kind]="case.caseTypeCodebook.mark" [title]="case.caseNumber" [date]="case.dateOfCreation"
          [caseName]="case.socialCaseClassificationCodebook.title" [firstName]="case.firstName"
          [lastName]="case.lastName" [isExternal]=""
          [status]="case.assignedUserId === loggedIn.id ? case.caseStatus : 'MEMBER'" (click)="markAsRead(case)">
        </case-list-item>

        <case-list-item *ngIf="case.socialCaseClassificationCodebook.caseKindViewCodebook?.base === 'UV'"
          [link]="'cases/' +  case.socialCaseClassificationCodebook.caseKindViewCodebook?.base + '/' + case.id + '/' + case.subjectIdentity.subjectId"
          [kind]="case.caseTypeCodebook.mark" [title]="case.caseNumber" [date]="case.dateOfCreation"
          [caseName]="case.socialCaseClassificationCodebook.title" [firstName]="case.firstName"
          [lastName]="case.lastName" [isExternal]=""
          [status]="case.assignedUserId === loggedIn.id ? case.caseStatus : 'MEMBER'" (click)="markAsRead(case)">
        </case-list-item>

        <case-list-item *ngIf="case.socialCaseClassificationCodebook.caseKindViewCodebook?.base ==='PMN'"
          [link]="'cases/' +  case.socialCaseClassificationCodebook.caseKindViewCodebook?.base + '/' + case.id + '/' + case.subjectIdentity.subjectId"
          [kind]="case.caseTypeCodebook.mark" [title]="case.caseNumber" [date]="case.dateOfCreation"
          [caseName]="case.socialCaseClassificationCodebook.title" [firstName]="case.firstName"
          [lastName]="case.lastName" [isExternal]=""
          [status]="case.assignedUserId === loggedIn.id ? case.caseStatus : 'MEMBER'" (click)="markAsRead(case)">
        </case-list-item>

      </div>
    </collapsible-group>
  </div>
</div>