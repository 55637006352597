/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

export const letters = '[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u0100-\u017F\u0180-\u024F\u0400-\u04FF ]*';
export const num_letters = '[0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u0100-\u017F\u0180-\u024F\u0400-\u04FF ]*';
export const pttValidator = '[1-9]{1}[0-9]{4}';
export const emailValidator = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
export const numberValidator = '[0-9]*';
export const dateBirthValidator = '[0-9]*';
export const percentValidator = '[1-5]{1}[0-9]{1}%';
export const number = '[0-9]+';
export const addressSubnumber = '[0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u0100-\u017F\u0180-\u024F\u0400-\u04FF\/ ]*'