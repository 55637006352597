import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventEnum } from 'src/app/entrance/types';
import { getNumberForShow, getNumberFromString } from 'src/app/utils/formatLocalNumber';
import { CodebookService } from 'src/services/codebook.service';

@Component({
  selector: 'app-add-support-price',
  templateUrl: './add-support-price.component.html',
  styleUrls: ['./add-support-price.component.scss']
})
export class AddSupportPriceComponent implements OnInit {

  constructor( private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddSupportPriceComponent>,
    private codebookService: CodebookService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,) { }
    
  supportCategoryPriceForm: FormGroup;
  supportCategoryCodebook: any;
  isEdit: boolean;
  preSelectedCategories: any;
  editRowData: any;
  minToDate: Date;
  maxDate: Date = new Date(2999, 11, 31);

  ngOnInit(): void {
    this.isEdit = this.data.isEdit;
    this.preSelectedCategories = this.data.selectedCategories;
    this.editRowData = this.data.rowData;

    this.createForm();
    this.getSupportCategoryCodebook();


    this.supportCategoryPriceForm.get('validFrom').valueChanges.subscribe((fromDate: Date) => {
      this.minToDate = fromDate;
});
  }

  checkPrice(form){
    const amount = getNumberForShow(form.value); 
    if(amount) form.setValue(amount);
  }

  private createForm() {
    this.supportCategoryPriceForm = this.formBuilder.group({
      supportCategory: ['', []],
      amountMonth: ['', [Validators.pattern('^[0-9.,]*$')]],
      validFrom: ['', [Validators.required]],
      validTo: ['', []],

    });

    if (this.editRowData) {
        const categoryTxt =
            `(${this.editRowData?.supportCategoryCodebook?.code}) ${this.editRowData?.supportCategoryCodebook?.title}`;
        this.supportCategoryPriceForm.controls.amountMonth.setValue(this.editRowData.amountMonth);
        this.supportCategoryPriceForm.controls.validFrom.setValue(this.editRowData.validFrom);
        this.supportCategoryPriceForm.controls.validTo.setValue(this.editRowData.validTo);

        this.supportCategoryPriceForm.controls.supportCategory.setValue(categoryTxt);
    }
  }
  getSupportCategoryCodebook(){
    this.codebookService.getAccommodationSupportCategoryCodebook().subscribe((res) => {
      if (!this.isEdit) {
        res = res.map(category => {
            category.disabled = this.preSelectedCategories.find(item => item.id === category.id);
            return category;
        } );
      }
      this.supportCategoryCodebook = res;
    });
  }
  submitForm() {
    let formValue = this.supportCategoryPriceForm;
    formValue.setValue({
      ...formValue.value, 
      amountMonth: getNumberFromString(formValue.value.amountMonth), 
    }
    );
    this.dialogRef.close({ event: EventEnum.SUBMIT, data: formValue.value });
  }
  
  closeDialog() {
    this.dialogRef.close({ event: EventEnum.CANCEL });
  }
  compareObj(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id;
  }
}
