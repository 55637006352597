<container>
  <page-intro-header title="{{ 'COLLECTIONS.TITLE' | translate }}">
  </page-intro-header>
  <ul class="collection-card__list">
    <li class="collection-card__item">
      <a routerLink="/domestic-violence-victim" class="collection-card__link">{{
        'COLLECTIONS.LIST.VICTIM' | translate
      }}</a>
    </li>
    <li class="collection-card__item">
      <a routerLink="/domestic-violence-committer" class="collection-card__link">{{
        'COLLECTIONS.LIST.BULLY' | translate
      }}</a>
    </li>
    <li class="collection-card__item">
      <a routerLink="/dependents" class="collection-card__link">{{
        'COLLECTIONS.LIST.DEPENDENTS' | translate
      }}</a>
    </li>
    <li class="collection-card__item">
      <a routerLink="/established-foster-care" class="collection-card__link">{{
        'COLLECTIONS.LIST.ESTABLISHED_FOSTER_CARE' | translate
      }}</a>
    </li>
    <li class="collection-card__item">
      <a routerLink="/foster-parent" class="collection-card__link">{{
        'COLLECTIONS.LIST.FOSTER_PARENTS' | translate
      }}</a>
    </li>
    <li class="collection-card__item">
      <a routerLink="/foster-child" class="collection-card__link">{{
        'COLLECTIONS.LIST.FOSTER_CHILD' | translate
      }}</a>
    </li>
    <li class="collection-card__item">
      <a class="collection-card__link" routerLink="/proteges">{{
        'COLLECTIONS.LIST.PROTEGE' | translate
      }}</a>
    </li>

    <li class="collection-card__item">
      <header class="collection-card__label-wrapper">

      </header>
      <a class="collection-card__link" routerLink="/adopted-children">{{
        'COLLECTIONS.LIST.ADOPTED' | translate
      }}</a>
    </li>
  </ul>
</container>