/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import { TranslateService } from '@ngx-translate/core';
import { ViolenceService } from 'src/services/violence-service';
import { ToastrImplService } from 'src/services/toastr.service';

export enum characteristicType {
   Karakteristike_zrtve = 1,
   Karakteristike_pocinioca = 2,
   Karakteristike_situacije = 3,
   Rizik = 4,
}

@Component({
   selector: 'violence',
   templateUrl: './violence.component.html',
   styleUrls: ['./violence.component.scss'],
})
export class ViolenceComponent implements OnInit {
   newViolence: FormGroup;
   submittedData: any;
   @Input() waitingForApproval: boolean;
   @Input() data: any = {}; // TODO: Type me
   @Input() editEnabled: boolean;
   @Input() isCaseClosed: boolean;
   @Input() caseId: any;
   @Input() caseData: any;
   @Output() handleSubmitData = new EventEmitter();
   @Input() noRegistrationId: boolean;

   stepStatus: 'INITIAL' | 'STARTED' | 'FINISHED' = 'INITIAL';
   isEditMode = false;
   characteristicType = characteristicType;

   formsOfNeglectCodebook = [];
   violenceHappenedContextCodebook = [];
   formsOfViolenceCodebook = [];
   formsOfSexualViolence = [];
   housingStatusCodebook = [];
   householdOrFamilyTypeCodebook = [];
   formsOfEmotionalCodebook = [];
   economicViolenceCodebook = [];
   exploitationChildCodebook = [];
   bullyingCodebook = [];
   migrantStatusCodebook = [];
   engagedCollaborativeNetworkCodebook = [];
   availabilityVictimToPerpetratorCodebook = [];
   victimCharacteristicCodebook = [];
   situationCharacteristicCodebook = [];
   perpetratorCharacteristicCodebook = [];
   riskCharacteristicCodebook = [];
   relationshipViolence = [];

   riskExistenceOptions = [
      { id: 1, exists: false, title: '' },
      { id: 2, exists: true, title: '' },
   ];
   acceptCaseMissingPermitionsTooltip = '';
   loadedData: any;

   constructor(
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private translate: TranslateService,
      private violenceService: ViolenceService,
      private toastr: ToastrImplService
   ) {
      this.translate.get('GLOBAL.TOOLTIPS.ACCEPT_CASE_MISSING_PERMITIONS').subscribe((res: string) => {
         this.acceptCaseMissingPermitionsTooltip = res;
      });
      this.translate.get('VS.VIOLENCE.RISK_DOESNT_EXIST').subscribe((res: string) => {
         this.riskExistenceOptions[0].title = res;
      });
      this.translate.get('VS.VIOLENCE.RISK_EXISTS').subscribe((res: string) => {
         this.riskExistenceOptions[1].title = res;
      });
   }

   ngOnInit(): void {
      this.loadExistingViolence();
      this.getCodebooks();
   }

   loadExistingViolence() {
      this.violenceService.findViolenceByCaseId(this.caseId).subscribe(
         result => {
            this.loadedData = result;
            if (this.loadedData !== undefined && this.loadedData !== null) {
               this.stepStatus = 'STARTED';
               this.patchValues();
               if (this.loadedData.finished) {
                  this.stepStatus = 'FINISHED';
               }
            }
         },
         error => {
            console.log(error);
         }
      );
   }

   handleInitStep() {
      this.isEditMode = true;
      this.newViolence = this.formBuilder.group({
         engagedCollaborativeNetwork: [null, []],
         usefulDataPerPerson: [null, []],
         migrantStatus: [null, []],
         householdOrFamilyType: [null, []],
         housingStatus: [null, []],
         violenceHappenedContext: [null, []],
         victimCharacteristic: [null, []],
         situationCharacteristic: [null, []],
         riskCharacteristic: [null, []],
         riskExistence: [null, []],
         perpetratorCharacteristic: [null, []],
         formOfNeglect: [null, []],
         formOfViolence: [null, []],
         formOfSexualViolence: [null, []],
         formOfEmotionalViolence: [null, []],
         exploitationChild: [null, []],
         economicViolence: [null, []],
         bullying: [null, []],
         relationshipWithViolenceCommiter: [null, []],
      });
   }
   patchValues() {
      this.newViolence = this.formBuilder.group({
         engagedCollaborativeNetwork: new FormControl([...this.loadedData.engagedCollaborativeNetwork]),
         usefulDataPerPerson: new FormControl(this.loadedData.usefulDataPerPerson),
         migrantStatus: new FormControl(this.loadedData.migrantStatus),
         householdOrFamilyType: new FormControl(this.loadedData.householdOrFamilyType),
         housingStatus: new FormControl(this.loadedData.housingStatus),
         violenceHappenedContext: new FormControl(this.loadedData.violenceHappenedContext),
         victimCharacteristic: new FormControl(this.loadedData.victimCharacteristic),
         situationCharacteristic: new FormControl(this.loadedData.situationCharacteristic),
         riskCharacteristic: new FormControl(this.loadedData.riskCharacteristic),
         riskExistence: this.loadedData.riskExistence ? this.riskExistenceOptions[1] : this.riskExistenceOptions[0],
         perpetratorCharacteristic: new FormControl(this.loadedData.perpetratorCharacteristic),
         formOfNeglect: new FormControl(this.loadedData.formOfNeglect),
         formOfViolence: new FormControl(this.loadedData.formOfViolence),
         formOfSexualViolence: new FormControl(this.loadedData.formOfSexualViolence),
         formOfEmotionalViolence: new FormControl(this.loadedData.formOfEmotionalViolence),
         exploitationChild: new FormControl(this.loadedData.exploitationChild),
         economicViolence: new FormControl(this.loadedData.economicViolence),
         bullying: new FormControl(this.loadedData.bullying),
         relationshipWithViolenceCommiter: new FormControl(this.loadedData.relationshipWithViolenceCommiter),
      });
   }
   toggleEdit() {
      this.isEditMode = !this.isEditMode;

      if (this.isEditMode === false) {
         this.newViolence.reset();
      }
   }
   closeForm() {
      this.isEditMode = false;
   }
   handleSaveChanges() {
      this.stepStatus = 'STARTED';
      this.isEditMode = false;
   }

   handleSubmitStep() {
      this.isEditMode = false;
      this.stepStatus = 'FINISHED';
   }

   getCodebooks() {
      this.codebookService.getFormsOfNeglectCodebook().subscribe(res => {
         this.formsOfNeglectCodebook = res;
      });
      this.codebookService.getFormsOfEmotionalViolenceCodebook().subscribe(res => {
         this.formsOfEmotionalCodebook = res;
      });
      this.codebookService.getViolenceHappenedContextCodebook().subscribe(res => {
         this.violenceHappenedContextCodebook = res;
      });
      this.codebookService.getFormsOfViolenceCodebook().subscribe(res => {
         this.formsOfViolenceCodebook = res;
      });
      this.codebookService.getFormsOfSexualViolenceCodebook().subscribe(res => {
         this.formsOfSexualViolence = res;
      });
      this.codebookService.getHousingStatusCodebook().subscribe(res => {
         this.housingStatusCodebook = res;
      });
      this.codebookService.getHouseholdOrFamilyTypeCodebook().subscribe(res => {
         this.householdOrFamilyTypeCodebook = res;
      });
      this.codebookService.getEconomicViolenceCodebook().subscribe(res => {
         this.economicViolenceCodebook = res;
      });
      this.codebookService.getExploitationChildCodebook().subscribe(res => {
         this.exploitationChildCodebook = res;
      });
      this.codebookService.getBullyingCodebook().subscribe(res => {
         this.bullyingCodebook = res;
      });
      this.codebookService.getMigrantStatusCodebook().subscribe(res => {
         this.migrantStatusCodebook = res;
      });
      this.codebookService.getEngagedCollaborativeNetworkCodebook().subscribe(res => {
         this.engagedCollaborativeNetworkCodebook = res;
      });
      this.codebookService.getAvailabilityVictimToPerpetratorCodebook().subscribe(res => {
         this.availabilityVictimToPerpetratorCodebook = res;
      });
      this.codebookService.getVictimCharacteristicCodebook().subscribe(res => {
         this.victimCharacteristicCodebook = res;
      });
      this.codebookService.getSituationCharacteristicCodebook().subscribe(res => {
         this.situationCharacteristicCodebook = res;
      });
      this.codebookService.getPerpetratorCharacteristicCodebook().subscribe(res => {
         this.perpetratorCharacteristicCodebook = res;
      });
      this.codebookService.getRiskCharacteristicCodebook().subscribe(res => {
         this.riskCharacteristicCodebook = res;
      });
      this.codebookService.getRelationshipViolenceCodebook().subscribe(res => {
         this.relationshipViolence = res;
      });
   }

   createViolenceObject() {
      const violenceObject = {
         engagedCollaborativeNetwork: this.newViolence.value.engagedCollaborativeNetwork,
         usefulDataPerPerson: this.newViolence.value.usefulDataPerPerson,
         migrantStatus: this.newViolence.value.migrantStatus,
         householdOrFamilyType: this.newViolence.value.householdOrFamilyType,
         housingStatus: this.newViolence.value.housingStatus,
         violenceHappenedContext: this.newViolence.value.violenceHappenedContext,
         victimCharacteristic: this.newViolence.value.victimCharacteristic,
         situationCharacteristic: this.newViolence.value.situationCharacteristic,
         riskCharacteristic: this.newViolence.value.riskCharacteristic,
         riskExistence: this.newViolence.value.riskExistence === null ? null : this.newViolence.value.riskExistence.exists,
         perpetratorCharacteristic: this.newViolence.value.perpetratorCharacteristic,
         formOfNeglect: this.newViolence.value.formOfNeglect,
         formOfViolence: this.newViolence.value.formOfViolence,
         formOfSexualViolence: this.newViolence.value.formOfSexualViolence,
         formOfEmotionalViolence: this.newViolence.value.formOfEmotionalViolence,
         exploitationChild: this.newViolence.value.exploitationChild,
         economicViolence: this.newViolence.value.economicViolence,
         bullying: this.newViolence.value.bullying,
         caseId: this.caseId,
         id: this.loadedData?.id,
         relationshipWithViolenceCommiter: this.newViolence.value.relationshipWithViolenceCommiter,
      };
      return violenceObject;
   }

   printPreview() {
      const violenceObject = this.createViolenceObject();
      this.violenceService.previewViolenceForm(violenceObject);
   }

   saveViolenceForm() {
      const violenceObject = { ...this.createViolenceObject(), tempSave: false };
      this.violenceService.saveViolenceForm(violenceObject).subscribe(
         result => {
            this.toastr.success('SNACKBAR.DOCUMENT_ADDED');
            this.loadedData = result;
            this.handleSubmitStep();
         },
         error => {
            console.log(error);
            this.toastr.error('SNACKBAR.ERROR_OCCURRED');
         }
      );
   }
   saveCurrentData() {
      if (this.newViolence !== undefined) {
         const violenceObject = { ...this.createViolenceObject(), tempSave: true };
         this.violenceService.saveViolenceForm(violenceObject).subscribe(
            result => {
               this.toastr.success('SNACKBAR.SOLUTION_SAVED');
               this.loadedData = result;
               this.handleSaveChanges();
               this.newViolence.markAsPristine();
            },
            error => {
               console.log(error);
               this.toastr.error('SNACKBAR.ERROR_OCCURRED');
            }
         );
      }
   }

   compareLoadedObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }
}
