<h4 class="modal__title">
    {{ 'SUBJECT_DOCUMENTS.DIALOG.TITLE' | translate }}
</h4>
<form [formGroup]="typeOfDocumentForm" class="form">
    <field-holder >
    <mat-form-field appearance="outline">
        <mat-label>
            {{'SUBJECT_DOCUMENTS.DIALOG.TYPE' | translate }}
        </mat-label>
        <mat-select formControlName="value"  required>
            <mat-option *ngFor="let option of selectOptions" [value]="option">
                {{option.viewValue}}
            </mat-option>
        </mat-select> 
    </mat-form-field>
    </field-holder>
    <form-footer>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
            class="form-footer__button form-footer__button--secondary">
            {{ 'DIALOG.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" type="button" (click)="handleUpdate()"
            class="form-footer__button form-footer__button--primary" [disabled]="!typeOfDocumentForm.valid">
            {{ 'DIALOG.CONFIRM' | translate }}
        </button>
    </form-footer>
</form>