/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from 'src/app/utils/formatDate';

@Component({
  selector: 'collapsible-case',
  templateUrl: './collapsible-case.component.html',
  styleUrls: ['./collapsible-case.component.scss']
})
export class CollapsibleCaseComponent implements OnInit {
  groupTitle: string;
  @Input() groupExpanded = true;
  @Input() title: string;
  @Input() number: string;
  @Input() date: string;
  @Input() teamRequest = false;
  @Input() kind: 'C' | 'Ž' | 'O' | 'P' | 'LJ' | 'IC' | 'IP';
  dateFormat;

  constructor() {  }

  ngOnInit(): void {
    this.dateFormat = formatDate(this.date);
  }

  toggle() {
    this.groupExpanded = !this.groupExpanded;
  }

}
