/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DataSharingService } from 'src/services/data-sharing.service';
import { SubmissionService } from 'src/services/submission.service';
import { UserService } from 'src/services/user.service';
import { SubjectDocumentsComponent } from './subject-documents/subject-documents.component';
import { SubjectTrackingComponent } from './subject-tracking/subject-tracking.component';
import { LocalStorageService } from '../local-storage.service';
@Component({
   selector: 'sidebar-widget',
   templateUrl: './sidebar-widget.component.html',
   styleUrls: ['./sidebar-widget.component.scss'],
})
export class SidebarWidgetComponent implements OnInit {
   sidebarExpanded: boolean = false;
   activeTab: number = 0;
   csrIdFromTab: any;
   subjectId: any;
   loggedIn: any;
   @ViewChild(SubjectTrackingComponent) SubjectTrackingComponent: SubjectTrackingComponent;
   @ViewChild(SubjectDocumentsComponent) SubjectDocumentsComponent: SubjectDocumentsComponent;

   disabledTabs: {
      otherInstitutions: boolean;
      documents: boolean;
      subjects: boolean;
      tracking: boolean;
      cases: boolean;
   } = {
      otherInstitutions: false,
      documents: true,
      subjects: true,
      tracking: true,
      cases: true,
   };
   // kad stigne (true) da uhvati
   allTabsEnabled = {
      otherInstitutions: false,
      documents: false,
      subjects: false,
      tracking: false,
      cases: false,
   };

   onlySubjectsAndDocumentsEnabled = {
      otherInstitutions: false,
      documents: false,
      subjects: false,
      tracking: true,
      cases: false,
   };

   onlySubjectsEnabled = {
      otherInstitutions: true,
      documents: true,
      subjects: false,
      tracking: true,
      cases: true,
   };

   allTabsDisabled = {
      otherInstitutions: true,
      documents: true,
      subjects: true,
      tracking: true,
      cases: true,
   };

   constructor(
      private dataSharingService: DataSharingService,
      private submissionService: SubmissionService,
      private router: Router,
      private cdr: ChangeDetectorRef,
      private userService: UserService,
      private localStorageService: LocalStorageService
   ) {
      this.dataSharingService.acceptedSubmission.subscribe(value => {
         if (value) {
            this.disabledTabs = this.onlySubjectsAndDocumentsEnabled;
         } else {
            this.disabledTabs = this.onlySubjectsEnabled;
         }
      });
      this.dataSharingService.supervisionChangeCase.subscribe(() => {
         this.handleSupervision();
      });
      const handleSubjectDetailRouteValidation = (url: string) => {
         this.sidebarExpanded = false;
         if (url.includes('/subjects/')) {
            this.disabledTabs = { otherInstitutions: true, documents: false, subjects: false, tracking: false, cases: false };
         } else {
            this.disabledTabs = this.onlySubjectsEnabled;
         }
         this.cdr.detectChanges();
      };

      const handleCaseRouteValidation = (url: string) => {
         this.sidebarExpanded = false;
         if (url.includes('/cases/')) {
            if (url.includes('/details/')) {
               this.disabledTabs = { otherInstitutions: true, documents: false, subjects: false, tracking: false, cases: false };
            } else {
               this.disabledTabs = this.allTabsEnabled;
            }
         } else {
            this.disabledTabs = this.onlySubjectsEnabled;
         }
         this.cdr.detectChanges();
      };

      const handleSubmissionRouteValidation = (url: string) => {
         const [_, submissionId] = url.split('/submissions/entrancePaper/');
         this.submissionService.findById(submissionId).subscribe(res => {
            if (res.submission.assignedUser != null) {
               this.disabledTabs = this.onlySubjectsAndDocumentsEnabled;
            } else {
               this.sidebarExpanded = false;
               this.disabledTabs = this.onlySubjectsEnabled;
            }
         });
      };

      this.router.events.subscribe(ev => {
         if (ev instanceof NavigationStart) {
            if (ev.url.includes('/subjects')) {
               return handleSubjectDetailRouteValidation(ev.url);
            }
         }
         if (ev instanceof NavigationEnd) {
            if (ev.url.includes('/subjects')) {
               return handleSubjectDetailRouteValidation(ev.url);
            } else if (ev.url.includes('/cases')) {
               return handleCaseRouteValidation(ev.url);
            } else if (ev.url.includes('/submissions/entrancePaper/')) {
               return handleSubmissionRouteValidation(ev.url);
            } else if (ev.url.includes('/supervision')) {
               return this.handleSupervision();
            } else if (ev.url.includes('/')) {
               if (this.userService.isGlobalAdmin() || this.userService.isCenterAdmin()) {
                  this.disabledTabs = this.allTabsDisabled;
               } else {
                  this.disabledTabs = this.onlySubjectsEnabled;
               }
               return this.closeSidebar();
            } else {
               this.disabledTabs = this.allTabsDisabled;
               return this.closeSidebar();
            }
         }
      });
   }

   ngOnInit(): void {}

   handleSidebar(tab: 'otherInstitutions' | 'documents' | 'subjects' | 'tracking' | 'cases'): void {
      const getIndex = tab =>
         tab === 'otherInstitutions' ? 0 : tab === 'documents' ? 1 : tab === 'subjects' ? 2 : tab === 'cases' ? 3 : tab === 'tracking' ? 4 : 0;
      this.sidebarExpanded = true;
      this.activeTab = getIndex(tab);
      if (this.activeTab === 1) {
         this.SubjectDocumentsComponent?.getAllDocuments();
      }
      if (this.activeTab === 4) {
         this.SubjectTrackingComponent?.getTrackingData();
      }
   }

   closeSidebar() {
      this.sidebarExpanded = false;
   }

   handleSupervision() {
      if (this.localStorageService.check('supervisionCaseId') && this.localStorageService.get('supervisionCaseId') !== '-1') {
         this.disabledTabs = { otherInstitutions: true, documents: true, subjects: false, cases: true, tracking: false };
      } else {
         this.disabledTabs = { otherInstitutions: true, documents: true, subjects: false, cases: true, tracking: true };
         if (this.sidebarExpanded) {
            this.closeSidebar();
         }
      }
   }

}

/**
 * TODO @danijelgrabez
 * 1. Imrove validation
 * 2. Filter results on tabs based on route (e.g. documents by user, or subject)
 */
