import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
export interface DeactivateComponent {
  saveChanges: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({
  providedIn: 'root'
})
export class DeactivateGuardService implements CanDeactivate<DeactivateComponent> {
  constructor() { }
  canDeactivate(
    component:DeactivateComponent,
    ) : Observable<boolean> | Promise<boolean> | boolean{
      
      return component.saveChanges ? component.saveChanges() : true;
  }
}

