/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';

@Component({
   selector: 'case-item-title-expansion-panel',
   templateUrl: './case-item-title-expansion-panel.component.html',
   styleUrls: ['./case-item-title-expansion-panel.component.scss'],
})
export class CaseItemTitleExpansionPanelComponent implements OnInit {
   groupTitle: string;
   @Input() title: string;
   @Input() date: string;
   @Input() firstName: string;
   @Input() lastName: string;
   @Input() kind: 'C' | 'Ž' | 'O' | 'P' | 'LJ' | 'IC' | 'IP';
   @Input() newApproving: Boolean;

   constructor() {}

   ngOnInit(): void {}
 
}
