/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CloseCaseComponent } from 'src/app/cases/vs/close-case/close-case.component';
import { ForwardCaseDialogComponent } from 'src/app/forward-case-dialog/forward-case-dialog.component';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ReturnCaseDialogComponent } from 'src/app/return-case-dialog/return-case-dialog.component';
import { SendIntoPlannerComponent } from 'src/app/send-into-planner/send-into-planner.component';
import { SubjectNewProblemComponent } from 'src/app/subjects/subject-details/subject-new-problem/subject-new-problem.component';
import { SubjectNewRecordComponent } from 'src/app/subjects/subject-details/subject-new-record/subject-new-record.component';
import { TeamRequestComponent } from 'src/app/team-request/team-request.component';
import { formatDate } from 'src/app/utils/formatDate';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { CaseService } from 'src/services/case.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { ComplainDecisionComponent } from '../complain-decision/complain-decision.component';
import { InputSubjectInSkFormComponent } from '../input-subject-in-sk-form/input-subject-in-sk-form.component';

@Component({
   selector: 'case-page-header',
   templateUrl: './case-page-header.component.html',
   styleUrls: ['./case-page-header.component.scss'],
})
export class CasePageHeaderComponent implements OnChanges, AfterViewInit {
   panelInfoOpen = false;
   loggedIn: any;
   isValid: boolean;
   dateFormat: any;
   subject: any;
   subjectDate: any;
   isSentToLegal = false;
   remainingTime: any;
   loaded = false;
   title = '';
   caseTitle = '';
   object: any = {
      caseId: '',
      userId: '',
      caseStatus: '',
      source: '',
      forwardingLawyerWorker: false,
   };
   warningObject: any = {
      document: '',
      action: '',
      subjectId: '',
      message: '',
      title: '',
   };
   teamMembersNames = [];

   @Output() newCaseStatus = new EventEmitter<string>();
   @Output() newProblem = new EventEmitter<boolean>();
   @Output() saveEvent = new EventEmitter<any>();
   @Output() handleCloseCaseData = new EventEmitter<any>();
   @Output() handleNewTeamRequest = new EventEmitter<any>();
   @Output() handleComplainAccepted = new EventEmitter<any>();

   @Input() isLawyer: boolean;
   @Input() waitingForApproval: boolean;
   @Input() approved: boolean;
   @Input() closingCaseData: any;

   @Input() caseData: any;
   @Input() caseId: any;
   @Input() subjectId: any;
   @Input() teamMember: boolean;
   @Input() caseName: any;
   @Input() hasTeamRequest: boolean;
   localHasTeamRequest: boolean;
   forwardingLawyerWorker = false;
   sendToSK = true;
   labelpadding = '240px';
   workerOnCase: any;
   constructor(
      public dialog: MatDialog,
      private caseService: CaseService,
      private cdr: ChangeDetectorRef,
      private localStorageService: LocalStorageService,
      private router: Router,
      private route: ActivatedRoute,
      private dataService: DataSharingService,
      private translate: TranslateService,
      private toastr: ToastrImplService,
      private userService: UserService,
      private subjectService: SubjectsService
   ) {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.loaded = false;
      this.isLawyer = this.userService.isUserLawyer();
      this.route.params.subscribe(() => {
         const [, caseType] = this.router.url.split('/cases/');
         if (caseType !== '') {
            [this.caseName, this.caseId, this.subjectId] = caseType.split('/');
            if (this.caseName === 'DZP' || this.caseName === 'DPN' || this.caseName === 'OZR' || this.caseName === 'PNN') {
               this.forwardingLawyerWorker = true;
            }
            this.workerOnCase = null;
            this.caseService.getTeamMembersForCase(this.caseId).subscribe((res: any) => {
               this.teamMembersNames = res.map((name: string, id: number) => (id !== 0 ? ' ' + name : name));
               if (this.teamMembersNames.length !== 0) {
                  this.caseService.getCaseManagerNameForCase(this.caseId).subscribe(resp => {
                     this.workerOnCase = resp;
                  });
               }
            });
         }
      });
   }

   ngAfterViewInit(): void {
      this.setCaseTitle();
      if (this.userService.isUserLawyer()) {
         if (this.caseData.legalCounselorId == null) {
            this.isSentToLegal = true;
         }
      }
      if (this.caseData?.socialCaseClassificationCodebook?.caseKindViewCodebook?.base === 'PMN') {
         this.forwardingLawyerWorker = true;
      }
      if (this.loggedIn?.id !== this.caseData.assignedUserId) {
         this.teamMember = true;
      } else {
         this.teamMember = false;
      }
      if (this.caseData.caseTypeCodebook.mark === 'Ž' || this.caseData.caseTypeCodebook.mark === 'C') {
         this.remainingTime = this.calculateRemaining(this.calculateDiff(this.caseData.dateOfCreation), 60);
      }

      this.dateFormat = formatDate(this.caseData.dateOfCreation);
      this.subject = this.caseData.subject;
      this.title = ''
         .concat(String(this.subject?.firstName))
         .concat(' ' + this.subject?.lastName)
         .concat(this.subject?.parentName === null ? '' : ', ')
         .concat(this.subject?.parentName === null ? '' : this.subject?.parentName)
         .concat(this.subject?.jmbg === null ? '' : ', ')
         .concat(this.subject?.jmbg === null ? '' : this.subject?.jmbg);
      this.cdr.detectChanges();
      if (this.caseData?.caseStatus === 'ACCEPTED' && this.caseData?.newComplain) {
         this.toastr.info('CASES.COMPLAIN_RESPOND_NEEDED');
      }
   }

   setCaseTitle() {
      this.caseTitle = this.caseData?.caseKindCodebook.mark + ' - ' + this.caseData?.caseNumber + ' ';
      this.translate.get('VS.STATUSES.' + this.caseData?.caseStatus).subscribe((resp: string) => {
         this.caseTitle += '(' + resp + ')';
      });
      if (this.caseData?.assignedUserId !== this.loggedIn.id) {
         this.translate.get('VS.STATUSES.MEMBER').subscribe((resp: string) => {
            this.caseTitle += ' (' + resp + ')';
         });
      }
   }

   calculateDiff(dateSent: any) {
      const currentDate = new Date();
      dateSent = new Date(dateSent);
      return Math.floor(
         (Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24)
      );
   }

   calculateRemaining(started: any, defined: any) {
      const remaining = defined - started;
      if (remaining < 0) {
         return 0;
      } else {
         return remaining;
      }
   }

   ngOnChanges(changes: any) {
      if (
         changes.teamMember !== null &&
         changes.caseData !== undefined &&
         changes.caseData.currentValue !== undefined &&
         changes.caseData.currentValue !== null &&
         changes.subjectId !== null &&
         changes.caseId !== null &&
         changes.caseName !== null &&
         this.loaded === false
      ) {
         if (this.caseName === 'DZP' || this.caseName === 'DPN' || this.caseName === 'OZR' || this.caseName === 'PNN') {
            this.forwardingLawyerWorker = true;
         }
         if (this.userService.isUserLawyer()) {
            if (this.caseData.legalCounselorId == null) {
               this.isSentToLegal = true;
            }
         }
         if (this.caseData?.socialCaseClassificationCodebook?.caseKindViewCodebook?.base === 'PMN') {
            this.forwardingLawyerWorker = true;
         }

         if (this.caseData.caseTypeCodebook.mark === 'Ž' || this.caseData.caseTypeCodebook.mark === 'C') {
            this.remainingTime = this.calculateRemaining(this.calculateDiff(this.caseData.dateOfCreation), 60);
         }
         if (this.caseData.skZahtevID !== null && this.caseData.skZahtevID !== '') {
            this.sendToSK = false;
         }
         this.dateFormat = formatDate(this.caseData.dateOfCreation);
         this.subject = this.caseData.subject;
         this.subject.dateOfBirth = this.subject.dateOfBirth !== null ? this.subject.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3') : null;
         this.loaded = true;
      }
      if (changes.hasTeamRequest && changes.hasTeamRequest.currentValue !== undefined && changes.hasTeamRequest.currentValue !== null) {
         this.localHasTeamRequest = this.hasTeamRequest;
      }
   }

   forwardCase(caseId: number) {
      const dialogRef = this.dialog.open(ForwardCaseDialogComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: this.object,
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res.event === 'SUCCESS') {
            const forwardDto = {
               userId: res.data.user,
               role: res.data.role,
            };
            this.caseService.forwardCase(this.caseId, forwardDto).subscribe(
               result => {
                  if (result) {
                     this.dataService.messages.next(true);
                     this.router.navigateByUrl('/');
                     this.cdr.detectChanges();
                     this.toastr.success('ASSIGN_CASE.SUCCESSFULL_ASSIGN');
                  } else {
                     this.toastr.error('PISARNICA.ERROR_ACCEPTED');
                  }
               },
               err => {
                  console.log('Error occured forwarding case:', err);
               }
            );
         }
      });
   }

   returnCase(caseId: number) {
      const dialogRef = this.dialog.open(ReturnCaseDialogComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: this.object,
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res.event === 'SUCCESS') {
            const returnDto = {
               taskComment: res.data.taskDescription,
               role: res.data.role,
            };
            this.caseService.returnCase(this.caseId, returnDto).subscribe(
               result => {
                  if (result) {
                     this.dataService.messages.next(true);
                     this.router.navigateByUrl('/');
                     this.cdr.detectChanges();
                     this.toastr.success('ASSIGN_CASE.SUCCESSFULL_ASSIGN');
                  } else {
                     this.toastr.error('PISARNICA.ERROR_ACCEPTED');
                  }
               },
               err => {
                  console.log('Error occured returning case:', err);
               }
            );
         }
      });
   }

   acceptCase(socialCaseId: any) {
      if (this.caseData?.subject?.status?.id === 2) {
         this.toastr.warning('CASES.NOT_ACTIVE_SUBJECT');
         return;
      }
      if (this.caseData?.subject?.registrationId !== null && this.caseData?.subject?.registrationId < 0) {
         this.toastr.warning('CASES.REGISTRATION_ID_LESS_THAN_ZERO');
         return;
      }
      const x = this.caseService.acceptCase(socialCaseId);
      x.subscribe(
         result => {
            if (result) {
               this.caseData.caseStatus = 'ACCEPTED';
               this.setCaseTitle();
               this.newCaseStatus.emit(this.caseData.caseStatus);
               this.dataService.messages.next(true);
               this.dataService.caseStatusChange.next(false);
               this.cdr.detectChanges();
            } else {
               this.toastr.error('PISARNICA.ERROR_ACCEPTED');
            }
         },
         () => {
            console.log('Error occured');
         }
      );
   }

   declineCase(socialCaseId: any) {
      const x = this.caseService.declineCase(socialCaseId);
      x.subscribe(
         () => {
            this.dataService.messages.next(true);
            this.router.navigateByUrl('/');
         },
         () => {
            console.log('Error occured');
         }
      );
   }

   sendIntoPlanner(socialCaseId: any) {
      const dialogRef = this.dialog.open(SendIntoPlannerComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: this.object,
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res.event === 'Success') {
            this.caseService.sendIntoPlanner(socialCaseId, res.data).subscribe(
               result => {
                  if (result != null) {
                     this.toastr.success('SNACKBAR.SUBJECT_SEND_IN_PLANNER');
                     this.router.navigateByUrl('/notifications');
                  } else {
                     this.toastr.error('SNACKBAR.SUBJECT_SEND_IN_PLANNER_ERROR');
                  }
               },
               error => {
                  this.toastr.error('SNACKBAR.SUBJECT_SEND_IN_PLANNER_ERROR');
               }
            );
         }
      });
   }
   resolveAsTeamMember() {
      this.warningObject.action = ModalEventEnum.CONFIRM;
      this.warningObject.subjectId = this.subjectId;
      this.translate.get('WARNING_DIALOG.RESOLVE_AS_TEAM_MEMBER.MESSAGE').subscribe((resp: string) => {
         this.warningObject.message = resp;
      });
      this.translate.get('WARNING_DIALOG.RESOLVE_AS_TEAM_MEMBER.TITLE').subscribe((resp: string) => {
         this.warningObject.title = resp;
      });
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.MINI,
         data: this.warningObject,
      });
      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.CONFIRM) {
            this.caseService.teamMemberFinished(this.caseId, this.loggedIn.id).subscribe(
               () => {
                  this.cdr.detectChanges();
                  this.dataService.messages.next(true);
                  this.router.navigateByUrl('/subjects/' + this.subjectId);
               },
               error => {
                  console.log(error);
               }
            );
         }
      });
   }

   openTeamRequest(caseId: any) {
      let dialogRef = null;
      dialogRef = this.dialog.open(TeamRequestComponent, {
         width: ModalSizeEnum.DEFAULT,
         panelClass: 'overlay-panel',
         data: caseId,
      });
      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'CREATED') {
            this.handleNewTeamRequest.emit();
         }
      });
   }

   saveData() {
      this.saveEvent.emit();
   }

   addProblem() {
      const dialogRef = this.dialog.open(SubjectNewProblemComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: {
            fromCase: true,
            subjectId: this.subjectId,
            problem: {
               caseNumber: this.caseData.caseNumber,
               caseId: this.caseData.id,
            },
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.toastr.success('SNACKBAR.ADDED_PROBLEM');
            this.newProblem.emit(true);
         } else {
            this.newProblem.emit(false);
         }
      });
   }

   addRecord() {
      const dialogRef = this.dialog.open(SubjectNewRecordComponent, {
         panelClass: 'overlay-panel',
         disableClose: true,
         width: ModalSizeEnum.DEFAULT,
         data: {
            subjectId: this.subjectId,
            record: {
               caseId: null,
            },
            caseId: this.caseId,
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.toastr.success('SNACKBAR.ADDED_RECORD');
         } else {
            this.toastr.error('SNACKBAR.ADD_RECORD_ERROR');
         }
      });
   }

   handleCloseCase() {
      const data = {
         waitingForApproval: this.waitingForApproval,
         approved: this.approved,
         closingCaseData: this.closingCaseData,
         subjectId: this.subjectId,
         caseId: this.caseId,
         caseKind: this.caseData?.caseKindCodebook?.kind,
      };
      const dialogRef = this.dialog.open(CloseCaseComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.MINI,
         data,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event !== 'Cancel') {
            this.handleCloseCaseData.emit({ res: data, status: true });
         }
      });
   }

   handleClosePmnCase() {
      const data = {
         waitingForApproval: null,
         approved: true,
         closingCaseData: this.closingCaseData,
         subjectId: this.subjectId,
         caseId: this.caseId,
         type: 'PMN',
         caseKind: this.caseData?.caseKindCodebook?.kind,
      };
      const dialogRef = this.dialog.open(CloseCaseComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.MINI,
         data,
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event !== 'Cancel') {
            this.handleCloseCaseData.emit({ res: data, status: true });
         }
      });
   }

   addResponseToComplain() {
      if (this.caseData.complain) {
         const dialogRef = this.dialog.open(ComplainDecisionComponent, {
            disableClose: true,
            panelClass: 'overlay-panel',
            width: ModalSizeEnum.MINI,
         });
         dialogRef.afterClosed().subscribe(result => {
            const { event, data } = result;
            if (event !== 'Cancel') {
               this.caseService.addCaseComplainResponse(this.caseId, data).subscribe(() => {
                  if (data.id === 1 || data.id === 2 || data.id === 4) {
                     this.dataService.messages.next(true);

                     this.localStorageService.set('case', true);
                     this.router.navigateByUrl('/subjects/' + this.subjectId);
                  } else {
                     this.handleComplainAccepted.emit();
                  }
               });
            }
         });
      }
   }

   addSubjectInSK() {
      if (this.loggedIn.jmbg === undefined || this.loggedIn.jmbg === null) {
         this.toastr.info('SIDEBAR_WIDGET.OTHER_INSTITUTIONS.NO_WORKER_JMBG');
      } else if (this.subject.jmbg === undefined || this.subject.jmbg === null || this.subject.jmbg === '') {
         this.toastr.info('SUBJECT_SK.NO_JMBG');
      } else {
         const dialogRef = this.dialog.open(InputSubjectInSkFormComponent, {
            disableClose: true,
            panelClass: 'overlay-panel',
            width: ModalSizeEnum.DEFAULT,
         });
         // filter externalOptions
         const oldKindMark = this.caseData.socialCaseClassificationCodebook.oldKindMark;
         dialogRef.componentInstance.externalOptions = dialogRef.componentInstance.externalOptions.filter(item => item.type.includes(oldKindMark));

         // automatika dodavanja clanova familije i dodavanje liste jmbg za proveru duplikacije
         if (this.caseData.subject.familyDossierId) {
            this.subjectService
               .getFamilyMembersDossierDto(this.caseData.subject.subjectIdentity.subjectId, this.caseData.subject.familyDossierId, this.caseData.subject.subjectIdentity.csrId)
               .subscribe(subject => {
                  const familyMembersList = [];
                  const jmbgList = [];
                  subject.forEach((item: any) => {
                     const familyMemberData = {
                        firstName: item.firstName,
                        lastName: item.lastName,
                        jmbg: item.jmbg,
                        relationship: { value: item.relationship.skCode, viewValue: item.relationship.title },
                     };
                     familyMembersList.push(familyMemberData);
                     jmbgList.push(item.jmbg);
                  });
                  jmbgList.push(this.caseData.subject.jmbg);
                  dialogRef.componentInstance.participations = familyMembersList;
                  dialogRef.componentInstance.dataSource.data = dialogRef.componentInstance.participations;
                  dialogRef.componentInstance.jmbgList = jmbgList;
               });
         }

         dialogRef.afterClosed().subscribe(result => {
            if (result.event !== 'cancel') {
               this.subjectService.insertSubjectInSK(result.data, this.caseId).subscribe(
                  resp => {
                     let message = '';
                     let title = '';
                     this.translate.get('WARNING_DIALOG.INPUT_SUBJECT_IN_SK.TITLE').subscribe((res: string) => {
                        title = res;
                     });

                     this.translate.get('WARNING_DIALOG.INPUT_SUBJECT_IN_SK.MESSAGE').subscribe((res: string) => {
                        message = res;
                     });
                     const object = {
                        document: null,
                        action: 'allocationError',
                        message,
                        title,
                     };
                     this.sendToSK = false;

                     const dialogRef = this.dialog.open(WarningMessageComponent, {
                        disableClose: true,
                        panelClass: 'overlay-panel',
                        width: ModalSizeEnum.DEFAULT,
                        data: object,
                     });
                  },
                  error => {
                     if (error.status == 409) {
                        this.toastr.showError(error.error.message);
                     } else {
                        this.toastr.info('SUBJECT_SK.ERROR_SEND');
                     }
                  }
               );
            }
         });
      }
   }

   closeWithoutChange() {
      this.caseService.closeWithoutChange(this.caseData.id).subscribe((res: any) => {
         if (res === true) {
            this.toastr.success('CASES.CLOSE_WITHOUT_CHANGE_SUCCESS');
            this.router.navigateByUrl('/subjects/' + this.subjectId);
         } else {
            this.toastr.error('CASES.CLOSE_WITHOUT_CHANGE_ERROR');
         }
      });
   }

   saveDataCondition() {
      const caseAccepted = this.caseData?.caseStatus == 'ACCEPTED';
      const notTeamMember = !this.teamMember;
      const notAP = this.caseName !== 'AP';
      const notPmn = this.caseName !== 'PMN';
      const notAssurance = this.caseName !== 'UV';
      const notVsStrucniRadnik = !((this.caseName === 'VS' || this.caseName === 'STAR') && !this.isLawyer);

      return caseAccepted && notTeamMember && notAP && notPmn && notAssurance && notVsStrucniRadnik;
   }
}
