<mat-card class="step__card" [ngClass]="{
    step__card: true,
    'step__card--initial': stepStatus === 'INITIAL',
    'step__card--started': stepStatus === 'STARTED',
    'step__card--finished': stepStatus === 'FINISHED'
  }">
    <header class="step__header">
        <main>
            <h4 class="step__title">
                {{ 'VS.INITIAL_ASSESSMENT.TITLE' | translate }}
            </h4>
            <label-step-status [status]="stepStatus" *ngIf="stepStatus !== 'INITIAL'"></label-step-status>
            <app-timeout-indicator [startOrEnd]="'START'" [date]="remainingTime" *ngIf="stepStatus === 'INITIAL'">
            </app-timeout-indicator>
        </main>
        <aside class="step__action" *ngIf="!isEditMode">
            <span [matTooltip]="!editEnabled ? acceptCaseMissingPermitionsTooltip : ''">
                <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}"
                    [matTooltipDisabled]="editEnabled===false||noRegistrationId===false">
                    <button mat-stroked-button color="primary" (click)="handleInitStep()"
                        *ngIf="stepStatus === 'INITIAL'"
                        [disabled]="!editEnabled || waitingForApproval|| noRegistrationId">
                        {{ 'VS.INITIAL_ASSESSMENT.CREATE' | translate }}
                    </button>
                </span>
            </span>
            <button mat-stroked-button color="primary" (click)="toggleEdit()" *ngIf="stepStatus === 'STARTED'"
                [disabled]="waitingForApproval" class="step__action">
                {{ 'VS.EDIT' | translate }}
            </button>
            <button mat-flat-button color="primary" (click)="handleSubmitStep()" *ngIf="stepStatus === 'FINISHED'"
                [disabled]="!documentSigned || waitingForApproval">
                {{ 'VS.INITIAL_ASSESSMENT.FINISH' | translate }}
            </button>
        </aside>
    </header>
    <span *ngIf="isEditMode">
        <span class="toggle-left">
            {{ 'VS.INITIAL_ASSESSMENT.FORM2'| translate }}
        </span>
        <mat-slide-toggle color="primary" [(ngModel)]="isChecked" (change)="toggle($event)" class="toggle">
            {{ 'VS.INITIAL_ASSESSMENT.FORM3'| translate }}
        </mat-slide-toggle>
    </span>
    <app-forms2-3 *ngIf="isEditMode === true" (handleSubmitData)="handleSubmitStep()" [approved]="data[data.length - 1]?.approved"
        (handleSaveChanges)="handleSaveChanges($event)" (handleFormNumberChange)="handleFormNumberChange($event)"
        [isCaseClosed]="isCaseClosed" [formNumber]="formNumber" [caseId]="caseId" [subject]="subject"
        (handleNewTeamRequest)="handleNewTeamRequestLocal()" [hasTeamRequest]="hasTeamRequest"
        [familyMembers]="members"></app-forms2-3>

</mat-card>