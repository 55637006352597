/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AlfrescoService } from 'src/services/alfresco.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { SupervisionService } from 'src/services/supervision.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum } from 'src/types';
import { LocalStorageService } from '../local-storage.service';
import { Page } from '../page';
import { PreviewDocumentComponent } from '../sidebar-widget/preview-document/preview-document.component';
import { DataSharingService } from 'src/services/data-sharing.service';

@Component({
   selector: 'supervision',
   templateUrl: './supervision.component.html',
   styleUrls: ['./supervision.component.scss'],
})
export class SupervisionComponent implements OnInit {
   fileToUpload: File = null;
   loggedIn: any;
   workers: any[];
   caseId: any = -1;
   cases: any = [];
   documents: any[];
   documentsByCaseNumber: any = [];
   evaluationDocumentsByWorkerId: any = [];
   selectedWorker = '';
   selectedPanel = '';
   panelOpenState = false;
   isSupervisionRootPage: boolean;
   documentsByCase: any = [];
   documentOptions: any = [];
   showDocumentOptions = true;
   selectedCase: any;
   workerId: any;
   supervisionType: any;
   filterValue: {
      minDate: any;
      maxDate: any;
      text: any;
   };
   object: any = {
      document: '',
      action: '',
      subjectId: '',
      message: '',
      title: '',
   };
   @ViewChild('file') file: ElementRef<HTMLInputElement>;
   page: Page<any> = new Page();
   pageEvaluation: Page<any> = new Page();
   casesLoading: boolean = false;
   documentsLoading: boolean = true;
   evaluationLoading: boolean = false;
   constructor(
      private cdr: ChangeDetectorRef,
      private alfrescoService: AlfrescoService,
      private paginationService: CustomPaginationService,
      private localStorageService: LocalStorageService,
      private supervisionService: SupervisionService,
      private router: Router,
      private route: ActivatedRoute,
      public dialog: MatDialog,
      private authentication: AuthenticationService,
      private dataService: DataSharingService,
      private toastr: ToastrImplService
   ) {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.localStorageService.remove('supervisionCaseId');
      // olja.andjelovski
      // Trazi radnike kojima je ulogovani radnik supervizor
      this.findWorkers(this.loggedIn.id);
      this.router.events.subscribe(ev => {
         if (ev instanceof NavigationEnd) {
            this.route.firstChild.params.subscribe(params => {
               if (this.workerId === params.workerId) {
                  return;
               }
               this.workerId = params.workerId;
               if (params.caseId != undefined && !isNaN(Number(params.caseId))) {
                  this.caseId = Number(params.caseId);
               } else {
                  this.caseId = -1;
               }

               if (this.workerId != undefined && !isNaN(Number(this.workerId))) {
                  this.isSupervisionRootPage = false;
                  this.findDocumentsByWorker();
                  this.findCasesByWorker(this.workerId);
               } else {
                  this.selectedWorker = 'Изаберите радника';
                  this.isSupervisionRootPage = true;
               }
            });
         }
      });
   }

   ngOnInit(): void {
      this.findEvaluationTemplates();
   }

   openDialog(data: any) {
      const { document, requireSignature = false } = data;
      const dialogRef = this.dialog.open(PreviewDocumentComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         data: { document, requireSignature },
      });

      dialogRef.afterClosed().subscribe(data => {
         if (data.typeOfDocument === 'VS-ZAKLJUCAK') {
            this.selectedCase.unapprovedConclusion = false;
         } else if (data.typeOfDocument === 'VS-PP') {
            this.selectedCase.unapprovedForm23 = false;
         } else if (data.typeOfDocument === 'VS-PU') {
            this.selectedCase.unapprovedForm5 = false;
         }
         if (data.event === 'sign-document') {
            this.findDocumentsByCase(this.selectedCase);
            this.toastr.success('SNACKBAR.DOCUMENT_SIGNED');
            this.cdr.detectChanges();
         }
         if (data.event === 'reject-document') {
            this.findDocumentsByCase(this.selectedCase);
            this.toastr.error('SNACKBAR.DOCUMENT_REJECTED');
            this.cdr.detectChanges();
         }
      });
   }

   handleNewEvaluation(document: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const body = {
         documentId: document.documentId,
         fileName: document.name,
         caseId:  null ,
      };
      this.alfrescoService.downloadDocumentByUrl(body);
   }

   findWorkers(supervisorId: any) {
      this.supervisionService.findSubordinates(supervisorId).subscribe(res => {
         this.workers = res;
      });
   }

   findCasesByWorker(wordkerId: any) {
      this.casesLoading = true;
      this.supervisionService.findCasesBySubordinate(wordkerId).subscribe(res => {
         this.cases = res;
         this.selectedCase = null;
         this.localStorageService.set('supervisionCaseId', '-1');
         this.dataService.supervisionChangeCase.next(true);
         for (const element of this.cases) {
            element.dateOfCreation =
               element.dateOfCreation.split(' ')[0].replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3.$2.$1.') + ' ' + element.dateOfCreation.split(' ')[1];
         }
         this.casesLoading = false;
         if (this.caseId != -1) {
            this.openCaseDocuments();
         }
      });
   }

   findDocumentsByCase(c: any) {
      this.documentsLoading = true;
      this.selectedCase = c;
      if (this.page.numberOfElements === 1 && this.page.pageable.pageNumber !== 0) {
         this.page.pageable.pageNumber = this.page.pageable.pageNumber - 1;
      }
      this.localStorageService.set('supervisionCaseId', c.id);
      this.dataService.supervisionChangeCase.next(true);
      this.supervisionService.getCaseDocuments(c.subjectId, c.id, this.page.pageable, c.csrId).subscribe(page => {
         this.documentsLoading = false;
         this.page = page;
         this.page.pageable.pagePerShow = page.number + 1;
      });
   }

   public getNextPage(c: any): void {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.findDocumentsByCase(c);
   }

   public getPreviousPage(c: any): void {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.findDocumentsByCase(c);
   }
   public getFirstPage(c: any): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.findDocumentsByCase(c);
   }

   public getLastPage(c: any): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.findDocumentsByCase(c);
   }

   public getPageInNewSize(pageSize: any, c: any): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.findDocumentsByCase(c);
   }

   findEvaluationTemplates() {
      this.evaluationLoading = true;
      this.alfrescoService.getDocumentTemplates('SV').subscribe(result => {
         this.evaluationLoading = false;
         this.documentOptions = result;
         if (this.documentOptions == null || this.documentOptions.length === 0) {
            this.showDocumentOptions = false;
         }
      });
   }

   public findDocumentsByWorker() {
      const filter = {
         minDate: '',
         maxDate: '',
         text: [],
      };
      if (this.pageEvaluation.numberOfElements === 1 && this.pageEvaluation.pageable.pageNumber !== 0) {
         this.pageEvaluation.pageable.pageNumber = this.pageEvaluation.pageable.pageNumber - 1;
      }
      this.alfrescoService.getWorkerDocuments(this.workerId, this.pageEvaluation.pageable, filter).subscribe(result => {
         this.pageEvaluation = result;
         for (const element of this.pageEvaluation.content) {
            element.dateOfModification = element.dateOfModification.split(' ')[0] + '. ' + element.dateOfModification.split(' ')[1];
         }
         this.pageEvaluation.pageable.pagePerShow = result.number + 1;
      });
   }

   public getNextPageEvaluation(): void {
      this.pageEvaluation.pageable = this.paginationService.getNextPage(this.pageEvaluation);
      this.findDocumentsByWorker();
   }

   public getPreviousPageEvaluation(): void {
      this.pageEvaluation.pageable = this.paginationService.getPreviousPage(this.pageEvaluation);
      this.findDocumentsByWorker();
   }
   public getFirstPageEvaluation(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.findDocumentsByWorker();
   }

   public getLastPageEvaluation(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.findDocumentsByWorker();
   }

   public getPageInNewSizeEvaluation(pageSize: any): void {
      this.pageEvaluation.pageable = this.paginationService.getPageInNewSize(this.pageEvaluation, pageSize);
      this.findDocumentsByWorker();
   }

   handleFileInput(files: FileList) {
      this.fileToUpload = files.item(0);
      this.alfrescoService.postFileForUserEvaluation(this.fileToUpload, this.workerId).subscribe(
         () => {
            this.cdr.detectChanges();
            this.findDocumentsByWorker();
            this.toastr.success('SNACKBAR.DOCUMENT_ADDED');
            this.router.navigate(['supervision/' + this.workerId]);
         },
         error => {
            this.toastr.error('SNACKBAR.DOCUMENT_ADD_ERROR');
         }
      );
      this.file.nativeElement.value = null;
   }

   evaluationAction(action: 'view' | 'cancel' | 'download' | 'print', obj: any) {
      this.object.document = obj;
      this.object.action = action;

      if (action === ModalEventEnum.VIEW) {
         const dialogRef = this.dialog.open(PreviewDocumentComponent, {
            panelClass: 'overlay-panel',
            disableClose: true,
            data: this.object,
         });

         dialogRef.afterClosed().subscribe(() => {});
      } else if (action === ModalEventEnum.DOWNLOAD) {
         this.alfrescoService.downloadDocument(obj.documentId, obj.name, obj.format).subscribe(result => {
            const file = new Blob([result], { type: result.type });
            const fileURL = URL.createObjectURL(file);
            const anchor = document.createElement('a');
            anchor.download = obj.name;
            anchor.href = fileURL;
            anchor.click();
         });
      } else if (action === ModalEventEnum.PRINT) {
         this.alfrescoService.printDocument(obj.documentId, obj.name);
      }
   }
   ngOnDestroy(): void {
      this.localStorageService.remove('supervisionCaseId');
   }

   changeTab(event: any) {
      if (event.index == 1) {
         this.localStorageService.set('supervisionCaseId', '-1');
      } else {
         this.localStorageService.set('supervisionCaseId', this.selectedCase.id);
      }
      this.dataService.supervisionChangeCase.next(true);
   }

   openCaseDocuments() {
      const index = this.cases.findIndex(item => item.id === this.caseId);
      if (index === -1) {
         return;
      }
      this.panelOpenState = true;
      this.findDocumentsByCase(this.cases[index]);
   }

   markAsRead(caseItem: any) {
      if (caseItem.newApproving) {
         this.supervisionService.markAsReadSupervisionCase(caseItem.id).subscribe(() => {
            const index = this.cases.findIndex(item => item.id === caseItem.id);
            this.cases[index].newApproving = false;
         });
      }
   }
}
