<form [formGroup]="familyMembersStep" class="form">
   <table mat-table [dataSource]="members" #mytable class="members__table" *ngIf="members.length > 0">
      <ng-container matColumnDef="name">
         <th mat-header-cell *matHeaderCellDef> {{ 'FAMILY_MEMBERS.FIRST_LAST_NAME' | translate }} </th>
         <td mat-cell *matCellDef="let element">
            {{ element.name }}
         </td>
      </ng-container>
      <ng-container matColumnDef="relationship">
         <th mat-header-cell *matHeaderCellDef> {{ 'FAMILY_MEMBERS.RELATIONSHIP' | translate }} </th>
         <td mat-cell *matCellDef="let element">
            {{ element.relationship?.title }}
         </td>
      </ng-container>
      <ng-container matColumnDef="jmbg">
         <th mat-header-cell *matHeaderCellDef> {{ 'FAMILY_MEMBERS.JMBG' | translate }} </th>
         <td mat-cell *matCellDef="let element">
            {{ element?.jmbg }}
         </td>
      </ng-container>
      <ng-container matColumnDef="qualificationEmployment">
         <th mat-header-cell *matHeaderCellDef> {{ 'FAMILY_MEMBERS.QUALIFICATION_EMPLOYMENT' | translate }} </th>
         <td mat-cell *matCellDef="let element">
            {{ element.qualificationEmployment }}
         </td>
      </ng-container>
      <ng-container matColumnDef="osi">
         <th mat-header-cell *matHeaderCellDef> {{ 'FAMILY_MEMBERS.OSI' | translate }} </th>
         <td mat-cell *matCellDef="let element">
            {{ (element?.disabledPerson === null ? null : element?.disabledPerson === true ? "GLOBAL.YES" : "GLOBAL.NO") | translate }}
         </td>
      </ng-container>
      <ng-container matColumnDef="ableToWork">
         <th mat-header-cell *matHeaderCellDef> {{ 'FAMILY_MEMBERS.ABLE_TO_WORK' | translate }} </th>
         <td mat-cell *matCellDef="let element">
            {{ (element?.ableToWork === null ? null : element?.ableToWork === true ? "GLOBAL.YES" : "GLOBAL.NO") | translate }}
         </td>
      </ng-container>
      <ng-container matColumnDef="forBilling">
         <th mat-header-cell *matHeaderCellDef> {{ 'FAMILY_MEMBERS.FOR_BILLING' | translate }} </th>
         <td mat-cell *matCellDef="let element">
            {{ (element?.forBilling === true ? "GLOBAL.YES" : "GLOBAL.NO") | translate }}
         </td>
      </ng-container>
      <ng-container matColumnDef="edit">
         <th mat-header-cell *matHeaderCellDef></th>
         <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
            <button mat-icon-button *ngIf="element?.editable" class="button-square table__row-button" color="primary"
               matTooltip="{{ 'GLOBAL.EDIT' | translate }}" (click)="familyMembersDialog(element)">
               <mat-icon class="button-square__icon">edit</mat-icon>
            </button>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="members__row-header"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowSelected(row)"
         [ngClass]="{jmbpWarning: row.jmbpWarning, hovered: row.hovered, highlighted: row.highlighted, changeWarning: row.changeWarning}"
         [matTooltip]="row.tooltipText"
         [matTooltipDisabled]="row.jmbpWarning === undefined && row.changeWarning === undefined"
         [matTooltipPosition]="'right'" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
   </table>
   <div class="members__add-holder">
      <button mat-flat-button mat-stroked-button color="primary" (click)="importFamilyMember()"
         class="form-footer__button form-footer__button--secondary">
         <mat-icon>person_add</mat-icon>
         {{ 'NSP_MOCK.FAMILY.ADD_MEMBER' | translate }}
      </button>
      <button mat-flat-button mat-stroked-button color="primary" (click)="removeFamilyMember()"
         [disabled]="!isMemberSelected">
         <mat-icon>person_remove</mat-icon>
         {{ "ENTRANCE.STEP_03.REMOVE_MEMBER" | translate }}
      </button>
   </div>
   <field-holder kind="2-cols">
      <mat-checkbox color="primary" class="form__checkbox" formControlName="increasedNsp" [disabled]="!increaseNSP"
         (change)="updateIncreasedNspReason($event)" *ngIf="members.length > 0">
         {{ 'NSP_MOCK.FAMILY.INCREASED_NSP' | translate }}
      </mat-checkbox>
      <mat-radio-group aria-label="Select an option" formControlName="increasedNspReason"
         *ngIf="familyMembersStep.get('increasedNsp').value != 0 && members.length > 0" class="form__radio-group"
         color="primary">
         <mat-radio-button *ngFor="let option of increasedNspReasons"
            [disabled]="(option.value==0 && !oneParent)||(option.value==1 && !allDisabled)" [value]="option.value"
            [checked]="familyMembersStep.get('increasedNspReason').value==option.value">
            {{ option.viewValue }}
         </mat-radio-button>
      </mat-radio-group>
   </field-holder>
   <field-holder>
      <mat-form-field appearance="outline">
         <mat-label>
            {{ 'NSP_MOCK.FAMILY.NOTE' | translate }}
         </mat-label>
         <textarea matInput type="text" maxlength="333" formControlName="note" autocomplete="off"></textarea>
      </mat-form-field>
   </field-holder>
   <form-footer>
      <button matStepperPrevious mat-stroked-button color="primary"
         class="form-footer__button form-footer__button--secondary">
         {{ 'NSP_MOCK.PREVIOUS_STEP' | translate }}
      </button>
      <span matTooltip="{{'SNACKBAR.NOT_EQUAL_JMBP_TOOLTIP' | translate }}" [matTooltipDisabled]="sameJmbps">
         <button matStepperNext mat-stroked-button color="primary" class="form-footer__button"
            [disabled]="isValidStep()" (click)="updateFamilyMembersStep()">
            {{ 'NSP_MOCK.NEXT_STEP' | translate }}
         </button>
      </span>
   </form-footer>
</form>