<div class="item__wrapper">
    <a routerLink="{{link}}" class="item" target="{{ isExternal ? '_blank' : '_self' }}">
        <header class="item__header">
            <h5 class="item__title">{{ title }}</h5>
            <footer class="item__meta">
                <time class="item__date">
                    <mat-icon class="item__icon">calendar_today</mat-icon>{{ dateFormat }}
                </time>

            </footer>
        </header>
    </a>
    <div class="item__action">
        <ng-content></ng-content>
    </div>
</div>
