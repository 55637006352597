/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CaseService } from 'src/services/case.service';
import { ModalSizeEnum } from 'src/types';
import { AssignCaseDialogComponent } from '../assign-case-dialog/assign-case-dialog.component';
// import { teamFormingRequests } from './schedule-cases-mock';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from 'src/app/utils/formatDate';
import { environment } from 'src/environments/environment';
import { DataSharingService } from 'src/services/data-sharing.service';
import { TeamRequestService } from 'src/services/team-request.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { LocalStorageService } from '../local-storage.service';
import { WarningMessageComponent } from '../warning-message/warning-message.component';

const groupWorkersByOffice = (workers: any, codebook: any) =>
   codebook.reduce((acc: any, value: any) => {
      acc[value] = workers.filter((item: any) => item.OfficeName === value);
      return acc;
   }, {});

@Component({
   selector: 'schedule-cases',
   templateUrl: './schedule-cases.component.html',
   styleUrls: ['./schedule-cases.component.scss'],
})
export class ScheduleCasesComponent implements OnInit {
   public kind = 'C';
   public workersWithCases = [];
   allOpenCases = [];
   openCases = [];
   teamFormingRequests = [];
   codebookOffices = [];
   workersGrouped = {};
   casesRendered: string[] = [];
   object = {
      caseId: '',
      workload: '',
      source: '',
      isTeamRequest: null,
      request: '',
      assignee: '',
      assignedUserId: '',
      team: [],
      dateAssignedFrom: '',
      dateAssignedTo: '',
      asLawyer: false,
      forLawyer: null,
      dateOfCreation: '',
      caseKind: '',
      oldKindMark: '',
   };
   loggedIn;
   isLoading = true;

   constructor(
      public dialog: MatDialog,
      private caseService: CaseService,
      private cdr: ChangeDetectorRef,
      private dataSharing: DataSharingService,
      private teamRequestService: TeamRequestService,
      private translate: TranslateService,
      private localStorage: LocalStorageService,
      private toastr: ToastrImplService
   ) {
      {
         this.dataSharing.newCase.subscribe(value => {
            this.getCases();
         });
         this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      }
   }
   getCases() {
      this.caseService.getUnassignedCases().subscribe(result => {
         this.openCases = result;
         this.createAllOpenCases();
      });

      this.teamRequestService.findAll().subscribe(result => {
         this.teamFormingRequests = result;
         this.createAllOpenCases();
      });
   }

   renderWorkerCases(body: any) {
      if (body.groupExpanded) {
         if (!this.casesRendered.includes(body.userId)) {
            this.casesRendered.push(body.userId);
         }
      } else {
         this.casesRendered = this.casesRendered.filter(user => user !== body.userId);
      }
   }

   createAllOpenCases() {
      this.allOpenCases = [...this.teamFormingRequests, ...this.openCases];
      this.cdr.detectChanges();
   }

   getWorkers() {
      this.caseService.findAllWorkers().subscribe(result => {
         this.isLoading = false;
         this.workersWithCases = result;
         this.formatAndGroupWorkers();
      });
   }

   formatAndGroupWorkers() {
      this.makeCodebook();
      this.workersGrouped = groupWorkersByOffice(this.workersWithCases, this.codebookOffices);
   }

   formatDate(date: any) {
      return formatDate(date);
   }

   ngOnInit(): void {
      this.getWorkers();
   }

   // assign modal, or edit if worker (team members) and case id is provided
   openAssignedCaseDialog(scase: any) {
      this.caseService.getCaseTeamMembers(scase.caseId).subscribe(res => {
         scase.teamMembers = res.teamMembers;

         this.object.workload = scase.workload;
         this.object.source = 'scheduling';
         this.object.team = scase.teamMembers;
         this.object.forLawyer = scase.forLawyer;
         this.object.caseKind = scase.caseKind;
         this.object.asLawyer = scase.asLawyer;
         this.object.oldKindMark = scase.oldKindMark;
         if (scase.teamFormingRequest) {
            this.object.isTeamRequest = true;
            this.object.request = scase.request;
            this.object.assignee = scase.assignee;
            this.object.assignedUserId = scase.assignedUserId;
         } else {
            this.object.isTeamRequest = false;
            this.object.assignedUserId = scase.assignedUserId;
            this.object.dateAssignedFrom = scase.dateAssignedFrom;
            this.object.dateAssignedTo = scase.dateAssignedTo;
         }
         this.object.dateOfCreation = scase.dateOfCreation;

         const dialogRef = this.dialog.open(AssignCaseDialogComponent, {
            disableClose: true,
            width: ModalSizeEnum.DEFAULT,
            data: this.object,
            panelClass: 'overlay-panel',
         });

         dialogRef.afterClosed().subscribe(res => {
            if (res.event === 'SUCCESS') {
               let ids = null;
               if (res.data.team.length > 0 && res.data.team[0].id !== undefined && res.data.team[0].id !== null) {
                  ids = res.data.team.map(x => x.userId);

                  let index = -1;

                  for (let i = 0; i < ids.length; i++) {
                     if (ids[i] == res.data.user) {
                        index = i;
                     }
                  }
                  if (index != -1) {
                     ids.splice(index, 1);
                  }
               }

               const assignedDto = {
                  userId: res.data.user,
                  dateAssignedFrom: res.data.holdCaseStartDate,
                  dateAssignedTo: res.data.holdCaseEndDate,
                  teamMembers: ids === null ? res.data.team : ids,
                  workload: res.data.workload,
                  source: res.data.source,
                  role: res.data.role,
               };
               this.caseService.assignCase(scase.caseId, assignedDto).subscribe(
                  result => {
                     this.replaceOpenCasesValues(scase.caseId);
                     this.replaceWorkerValues(result);
                     this.cdr.detectChanges();
                     let subject = '';
                     this.translate.get('SCHEDULE_CASES.CASES.ASSIGNED_SUBJECT').subscribe((res1: string) => {
                        subject = res1;
                     });
                     let sucsesfulyAdded = '';
                     this.translate.get('SCHEDULE_CASES.CASES.SUCCSESSFULLY_SUBJECT').subscribe((res2: string) => {
                        sucsesfulyAdded = res2;
                     });
                     this.toastr.showSuccess(subject + ' ' + scase.caseNumber + ' ' + sucsesfulyAdded + ' ' + res.data.name);
                  },
                  err => {
                     console.log('Error occured:', err);
                  }
               );
            } else if (res.event === 'SUCCESS_TEAM') {
               const teamMembers = res.data.team;

               this.caseService.assignTeam(scase.caseId, teamMembers).subscribe(
                  result => {
                     this.replaceTeamFormingRequests(scase.caseId);
                     this.replaceWorkerValues(result);
                     this.cdr.detectChanges();
                  },
                  err => {
                     console.log('Error occured:', err);
                  }
               );
            } else if (res.event === 'DECLINE_TEAM') {
               this.declineTeamReaquest(scase);
            }
         });
      });
   }

   replaceOpenCasesValues(caseId) {
      const itemIndex = this.openCases.findIndex(item => item.caseId === caseId);
      if (itemIndex !== -1) {
         this.openCases.splice(itemIndex, 1);
      }
      this.createAllOpenCases();
   }

   replaceTeamFormingRequests(caseId) {
      const itemIndex = this.teamFormingRequests.findIndex(item => item.caseId === caseId);
      if (itemIndex !== -1) {
         this.teamFormingRequests.splice(itemIndex, 1);
      }
      this.createAllOpenCases();
   }

   replaceWorkerValues(array) {
      array.forEach(element => {
         const itemIndex = this.workersWithCases.findIndex(item => item.userId === element.userId);
         this.workersWithCases[itemIndex] = element;
      });
      this.formatAndGroupWorkers();
   }

   return(scase: any) {
      let message = '';
      let title = '';

      this.translate.get('WARNING_DIALOG.RETURN_CASE.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.RETURN_CASE.MESSAGE').subscribe((res: string) => {
         message = res;
      });

      const object = {
         document: null,
         message: message,
         title: title,
         saveData: false,
      };

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res?.event !== 'cancel' && res !== undefined) {
            this.caseService.return(scase.caseId).subscribe(
               result => {
                  this.replaceOpenCasesValues(scase.caseId);
                  this.cdr.detectChanges();
                  this.toastr.success('SCHEDULE_CASES.CASES.RETURNED');
               },
               error => {
                  this.toastr.error('SNACKBAR.RETURN_CASE_ERROR');
               }
            );
         }
      });
   }

   previewCase(prcase: any) {
      if (prcase.teamFormingRequest) {
         window.open(environment.getNewCaseTab + prcase.caseId + '/' + prcase.subject.id + '/' + this.loggedIn?.csrId, '_blank');
      } else {
         window.open(environment.getNewCaseTab + prcase.caseId + '/' + prcase.subjectId + '/' + this.loggedIn?.csrId, '_blank');
      }
   }

   makeCodebook() {
      this.workersWithCases.forEach(element => {
         if (!this.codebookOffices.includes(element.OfficeName)) {
            this.codebookOffices.push(element.OfficeName);
         }
      });
   }

   declineTeamReaquest(scase: any) {
      let message = '';
      let title = '';
      this.translate.get('WARNING_DIALOG.DECLINE_TEAM.TITLE').subscribe((res: string) => {
         title = res;
      });
      this.translate.get('WARNING_DIALOG.DECLINE_TEAM.MESSAGE').subscribe((res: string) => {
         message = res;
      });
      const object = {
         document: null,
         message: message,
         title: title,
         saveData: false,
      };

      const dialogRef = this.dialog.open(WarningMessageComponent, {
         data: object,
         width: ModalSizeEnum.MINI,
         panelClass: 'overlay-panel',
      });
      dialogRef.afterClosed().subscribe(res => {
         if (res?.event !== 'cancel' && res !== undefined) {
            this.caseService.declineTeamRequest(scase.caseId).subscribe(
               result => {
                  this.replaceTeamFormingRequests(scase.caseId);
                  this.cdr.detectChanges();
                  this.toastr.success('SCHEDULE_CASES.CASES.SUCCSESSFULLY_DECLINED_TEAM');
               },
               error => {
                  this.toastr.error('SNACKBAR.ERROR_OCCURRED');
               }
            );
         }
      });
   }
}
