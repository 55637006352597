<form class="form" [formGroup]="familyMembers" (ngSubmit)="addFamilyMembers(); submitButton.disabled = true;">
  <form-group title="{{ 'FAMILY_MEMBER_CARD.TITLE' | translate }}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "FAMILY_MEMBERS.FIRST_LAST_NAME" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="off" />
        <mat-error *ngIf="familyMembers.get('name').hasError('required')">
          {{ "FAMILY_MEMBERS.FIRST_LAST_NAME_ERROR" | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ "FAMILY_MEMBER_CARD.RELATIONSHIP" | translate }}</mat-label>
        <mat-select formControlName="relationship" required>
          <mat-option *ngFor="let relationship of relationshipOptions" [value]="relationship">
            {{ relationship.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "FAMILY_MEMBERS.JMBP" | translate }}
        </mat-label>
        <input matInput type="text" formControlName="jmbp" readonly autocomplete="off" minlength="13" maxlength="13"/>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button mat-dialog-close color="primary" type="button" (click)="closeDialog()" class="form-footer__button form-footer__button--secondary">
      {{ "FAMILY_MEMBER_CARD.CANCEL" | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit" #submitButton class="form-footer__button form-footer__button--primary" [disabled]="!familyMembers.valid">
      {{ "FAMILY_MEMBER_CARD.ADD" | translate }}
    </button>
  </form-footer>
</form>