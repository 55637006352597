<form
  class="form"
  [formGroup]="importSubjectFromProviderForm"
 
>
  <form-group title="{{ 'IMPORT_SUBJECT_FROM_PROVIDER.TITLE' | translate }}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'IMPORT_SUBJECT_FROM_PROVIDER.JMBG' | translate
        }}</mat-label>
        <input
          maxlength="13"
          matInput
          type="text"
          formControlName="jmbg"
          autocomplete="off"
        />
        <mat-error
          *ngIf="
            importSubjectFromProviderForm.get('jmbg').hasError('required') ||
            importSubjectFromProviderForm.get('jmbg').hasError('maxLength') ||
            importSubjectFromProviderForm.get('jmbg').hasError('pattern')
          "
        >
          {{ 'IMPORT_SUBJECT_FROM_PROVIDER.JMBG_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button
      mat-stroked-button
      color="primary"
      mat-dialog-close
      (click)="closeDialog()"
      type="button"
      class="form-footer__button form-footer__button--secondary"
    >
    {{ 'IMPORT_SUBJECT_FROM_PROVIDER.CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      class="form-footer__button form-footer__button--primary"
      [disabled]="!importSubjectFromProviderForm.valid"
    >
    {{ 'IMPORT_SUBJECT_FROM_PROVIDER.CONFIRM' | translate }}
    </button>
  </form-footer>
</form>
