<mat-card class="wrapper">
   <mat-vertical-stepper #stepper linear="true" (selectionChange)="stepSelectionChange($event)" class="stepper">
      <mat-step [stepControl]="firstStep">
         <form class="form" [formGroup]="firstStep">
            <ng-template matStepLabel>
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_03.TITLE' | translate }}
            </ng-template>
            <field-holder kind="2-cols">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.DATE_OF_ACCOMMODATION'| translate}}
                  </mat-label>
                  <input matInput [matDatepicker]="picker2" formControlName="dateOfAccommodation" autocomplete="off"
                     (dateChange)="makePreparations()" [max]="maxDate"/>
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
               </mat-form-field>
               <mat-form-field appearance="outline"
                  *ngIf="caseData?.socialCaseClassificationCodebook.oldKindMark==='DS'">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.ACCOMMODATION_FACILITY' | translate}}
                  </mat-label>
                  <input #facilityInput [matAutocomplete]="autoFacility" formControlName="accommodationFacility"
                     type="text" matInput (focusout)="autoSelectItem()" />
                  <mat-autocomplete #autoFacility="matAutocomplete" panelWidth="auto"
                     (optionSelected)="accommodationFacilityChange();makePreparations()"
                     [displayWith]="displayCustomFormat">
                     <mat-option *ngFor="let opt of filteredAccommodationFacilityOptions | async" [value]="opt">
                        {{ opt.id + ' | ' + opt.name + ', ' + opt.community }}
                     </mat-option>
                  </mat-autocomplete>
               </mat-form-field>

            </field-holder>
            <field-holder kind="2-cols" *ngIf="caseData?.socialCaseClassificationCodebook.oldKindMark==='PS'">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.FIRST_NAME'| translate}}
                  </mat-label>
                  <input matInput type="text" formControlName="firstName" autocomplete="off" readonly />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.LAST_NAME'| translate}}
                  </mat-label>
                  <input matInput type="text" formControlName="lastName" autocomplete="off" readonly />
               </mat-form-field>
            </field-holder>
            <field-holder kind="2-cols" *ngIf="caseData?.socialCaseClassificationCodebook.oldKindMark==='PS'">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.JMBG'| translate}}
                  </mat-label>
                  <input matInput type="text" formControlName="jmbg" autocomplete="off" readonly />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.JMBP'| translate}}
                  </mat-label>
                  <input matInput type="text" formControlName="jmbp" autocomplete="off" readonly />
               </mat-form-field>
            </field-holder>

            <field-holder kind="2-cols" *ngIf="caseData?.socialCaseClassificationCodebook.oldKindMark==='DS'">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{ 'VS.ACCOMMODATION.FORMSO.STEP_03.REFERENCE_NUMBER' | translate }}
                  </mat-label>
                  <input matInput type="text" formControlName="referenceNumber" autocomplete="off" maxlength="20" />
               </mat-form-field>
               <mat-form-field appearance="outline"
                  *ngIf="!withSupport&&this.firstStep.get('accommodationFacility').value!=null && this.firstStep.get('accommodationFacility').value!=undefined">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.ACCOMMODATION_CATEGORY' | translate}}
                  </mat-label>
                  <mat-select formControlName="accommodationPricingCodebook"
                     [compareWith]="compareAccommodationFacility"
                     (selectionChange)="accommodationPricingCodebookChange($event)">
                     <mat-option *ngFor="let opt of accommodationPricingCodebookOptions" [value]="opt">
                        ({{ opt?.accommodationCategoryCodebook?.code}}) {{ opt.accommodationCategoryCodebook.title }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols" *ngIf="withSupport">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.SUPPORT_DEGREE'| translate}}
                  </mat-label>
                  <mat-select formControlName="supportCategory" [compareWith]="compareLoadedObjects"
                     (selectionChange)="changeSupport()">
                     <mat-option *ngFor="let opt of supportPriceCodebook" [value]="opt">
                        ({{ opt?.supportCategoryCodebook?.code}}) {{ opt.supportCategoryCodebook?.title }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.ACCOMMODATION_CATEGORY'| translate}}
                  </mat-label>
                  <mat-select formControlName="accommodationPricingCodebookSupport" [compareWith]="compareLoadedObjects"
                     (selectionChange)="accommodationPricingCodebookChange($event)">
                     <mat-option *ngFor="let opt of accommodationPricingCodebookSupportOptions" [value]="opt">
                        ({{ opt?.code}}) {{ opt.title }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.PERCENTAGE_OF_POCKET_MONEY'| translate}}
                  </mat-label>
                  <mat-select formControlName="pocketMoneyPercentage" [compareWith]="compareLoadedObjects">
                     <mat-option *ngFor="let opt of pocketMoneyPercentageOptions" [value]="opt">
                        {{ opt ? opt.amount : null }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.POCKET_MONEY_AMOUNT' | translate}}
                  </mat-label>
                  <mat-select formControlName="pocketMoneyAmount" (selectionChange)="calculateSum()"
                     [compareWith]="compareLoadedObjects">
                     <mat-option *ngFor="let opt of pocketMoneyAmountOptions" [value]="opt">
                        {{ opt ? opt.amount + ' ' + opt.category : null}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{ 'VS.ACCOMMODATION.FORMSO.STEP_03.MONTHLY_COSTS' | translate }}
                  </mat-label>
                  <input matInput type="text" formControlName="monthlyCosts"
                     [readonly]="caseData?.socialCaseClassificationCodebook.oldKindMark==='DS'" autocomplete="off"
                     (input)="calculateSum()" />
               </mat-form-field>
               <mat-form-field appearance="outline" *ngIf="false">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.POCKET_MONEY_AND_COSTS_SUM'| translate}}
                  </mat-label>
                  <input matInput type="text" formControlName="pocketMoneyAndCostsSum" readonly autocomplete="off" />
               </mat-form-field>
            </field-holder>
            <field-holder kind="2-cols">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{ 'VS.ACCOMMODATION.FORMSO.STEP_03.SUBJECT_BANK' | translate }}
                  </mat-label>
                  <mat-select formControlName="subjectBank" [compareWith]="compareLoadedObjects">
                     <mat-option *ngFor="let opt of banksOptions" [value]="opt">
                        {{ opt.id + ' | ' + opt.name}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_03.SUBJECT_BANK_ACCOUNT' | translate}}
                  </mat-label>
                  <input matInput type="text" formControlName="subjectBankAccount" autocomplete="off"
                     placeholder="{{ 'ACCOUNT_NUMBER_FORMAT' | translate }}" [textMask]="mask" />
               </mat-form-field>
            </field-holder>
         </form>
         <form-footer>
            <button matStepperNext mat-stroked-button color="primary" class="form-footer__button">
               {{ "VS.NEXT_STEP" | translate }}
            </button>
         </form-footer>
      </mat-step>
      <mat-step [stepControl]="secondStep">
         <form class="form" [formGroup]="secondStep">
            <ng-template matStepLabel>
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.TITLE' | translate }}
            </ng-template>

            <field-holder *ngIf="caseData?.socialCaseClassificationCodebook.oldKindMark==='PS'">
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'VS.ACCOMMODATION.FORMSO.STEP_04.TYPE_OF_FEE' | translate }}</mat-label>
                  <mat-select formControlName="typeOfAccommodationFee" multiple [compareWith]="compareTypesOfFee">
                     <mat-option *ngFor="let value of typesOfAccommodationFees" [value]="value">
                        {{ value.title }}
                     </mat-option>
                  </mat-select>

               </mat-form-field>
            </field-holder>

            <field-holder>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_04.FACTUAL_SITUATION' | translate}}
                  </mat-label>
                  <textarea matInput type="text" formControlName="factualSituation" autocomplete="off"></textarea>
               </mat-form-field>
            </field-holder>
            <field-holder>
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{'VS.ACCOMMODATION.FORMSO.STEP_04.ACCOMMODATION_COSTS_SITUATION'| translate}}
                  </mat-label>
                  <textarea matInput type="text" formControlName="accommodationCostsSituation"
                     autocomplete="off"></textarea>
               </mat-form-field>
            </field-holder>
            <span *ngIf="caseData?.socialCaseClassificationCodebook.oldKindMark==='PS'">
               <form-subgroup-divider></form-subgroup-divider>
               <h6 class="subgroup-title">
                  {{'VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS' | translate}}
               </h6>
               <button mat-flat-button color="primary" type="button" (click)="addFamilyInfo()" class="mb-16">
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.ADD' | translate }}
               </button>
               <div *ngIf="brothersAndSisters.length > 0">
                  <table mat-table class="table" [dataSource]="brothersAndSisters" #mytable1>
                     <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef>
                           {{'VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS_FIELDS.FIRST_NAME'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                           {{ element?.lastName }}
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef>
                           {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS_FIELDS.LAST_NAME' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                           {{ element?.firstName }}
                        </td>
                     </ng-container>12
                     <ng-container matColumnDef="jmbg">
                        <th mat-header-cell *matHeaderCellDef>
                           {{'VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS_FIELDS.JMBG'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                           {{ element?.jmbg }}
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef>
                           {{'VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS_FIELDS.NOTE'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                           {{ element?.note }}
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                           {{ 'VS.ACTION_OPTION' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index" class="action-link">
                           <button mat-icon-button (click)="deleteFamilyMember(i)">
                              <mat-icon>delete</mat-icon>
                           </button>
                        </td>
                     </ng-container>
                     <tr mat-header-row *matHeaderRowDef="displayedColumnsFamily"></tr>
                     <tr mat-row *matRowDef="let row; columns: displayedColumnsFamily" class="problems-table__row">
                     </tr>
                  </table>
               </div>
            </span>
            <form-subgroup-divider></form-subgroup-divider>
            <h6 class="subgroup-title">
               {{'VS.ACCOMMODATION.FORMSO.STEP_04.KIND_OF_PENSION_TITLE' | translate}}
            </h6>
            <button mat-flat-button color="primary" type="button" (click)="addPensionInfo()" class="mb-16">
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.ADD' | translate }}
            </button>
            <div *ngIf="selectedPensions.length > 0">
               <table mat-table class="table" [dataSource]="selectedPensions" #mytable>
                  <ng-container matColumnDef="typesOfPensionCodebook">
                     <th mat-header-cell *matHeaderCellDef>
                        {{'VS.ACCOMMODATION.FORMSO.STEP_04.PENSION.PENSION_KIND'| translate}}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.typesOfPensionCodebook?.title }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="branchesPioFundCodebook">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.PENSION.PIO' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.branchesPioFundCodebook?.branchTypeCodebook === null ? '' :
                        (element?.branchesPioFundCodebook?.branchTypeCodebook?.title !== null &&
                        element?.branchesPioFundCodebook?.branchTypeCodebook?.title !== undefined ?
                        element?.branchesPioFundCodebook?.branchTypeCodebook?.title : '' ) }}

                        {{ element?.branchesPioFundCodebook?.branchTypeCodebook === null ? '' :
                        (element?.branchesPioFundCodebook?.name !== null &&
                        element?.branchesPioFundCodebook?.name !== undefined ?
                        ' ' + element?.branchesPioFundCodebook?.name : '' ) }}
                     </td>
                  </ng-container>12
                  <ng-container matColumnDef="checkNumber">
                     <th mat-header-cell *matHeaderCellDef>
                        {{'VS.ACCOMMODATION.FORMSO.STEP_04.PENSION.CHECK_NUMBER'| translate}}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.checkNumber }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="pensionAmount">
                     <th mat-header-cell *matHeaderCellDef>
                        {{'VS.ACCOMMODATION.FORMSO.STEP_04.PENSION.PENSION_AMOUNT'| translate}}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.pensionAmount }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="startDate">
                     <th mat-header-cell *matHeaderCellDef>
                        {{'VS.ACCOMMODATION.START_DATE'| translate}}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.startDate| date: 'dd.MM.yyyy.' }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACTION_OPTION' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element; let i = index" class="action-link">
                        <button mat-icon-button (click)="deletePension(i)">
                           <mat-icon>delete</mat-icon>
                        </button>
                     </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsPensions"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsPensions" class="problems-table__row">
                  </tr>
               </table>
            </div>

            <form-subgroup-divider></form-subgroup-divider>
            <h6 class="subgroup-title">
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES_TITLE' | translate }}
            </h6>
            <button mat-flat-button color="primary" type="button" (click)="addOtherRevenuesInfo()" class="mb-16">
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.ADD' | translate }}
            </button>
            <div *ngIf="selectedOtherRevenues.length > 0">
               <table mat-table class="table" [dataSource]="selectedOtherRevenues" #mytable>
                  <ng-container matColumnDef="revenueKind">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.REVENUE_KIND' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.revenueKind?.title }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="branchesPioFundCodebook">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.PIO' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.branchesPioFundCodebook?.branchTypeCodebook === null ? '' :
                        (element?.branchesPioFundCodebook?.branchTypeCodebook?.title !== null &&
                        element?.branchesPioFundCodebook?.branchTypeCodebook?.title !== undefined ?
                        element?.branchesPioFundCodebook?.branchTypeCodebook?.title : '' ) }}

                        {{ element?.branchesPioFundCodebook?.branchTypeCodebook === null ? '' :
                        (element?.branchesPioFundCodebook?.name !== null &&
                        element?.branchesPioFundCodebook?.name !== undefined ?
                        ' ' + element?.branchesPioFundCodebook?.name : '' ) }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="checkNumber">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.CHECK_NUMBER' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.checkNumber }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="revenueAmount">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.OTHER_REVENUES.REVENUE_AMOUNT'| translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.revenueAmount }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="startDate">
                     <th mat-header-cell *matHeaderCellDef>
                        {{'VS.ACCOMMODATION.START_DATE'| translate}}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.startDate | date: 'dd.MM.yyyy.'}}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACTION_OPTION' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element; let i = index" class="action-link">
                        <button mat-icon-button (click)="deleteRevenue(i)">
                           <mat-icon>delete</mat-icon>
                        </button>
                     </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsRevenues"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsRevenues" class="problems-table__row">
                  </tr>
               </table>
            </div>

            <form-subgroup-divider></form-subgroup-divider>
            <h6 class="subgroup-title">
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING_TITLE' | translate }}
            </h6>
            <button mat-flat-button color="primary" type="button" (click)="addCostSharingInfo()" class="mb-16">
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.ADD' | translate }}
            </button>

            <div *ngIf="selectedCostsParticipations.length > 0">
               <table mat-table class="table" [dataSource]="selectedCostsParticipations" #mytable>
                  <ng-container matColumnDef="fullName">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.FULLNAME' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.fullName }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="jmbg">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.JMBG' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.jmbg}}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="typeOfParticipation">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.TYPE_OF_PARTICIPATION' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.typeOfParticipation }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="participationAmount">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.PARTICIPATION_AMOUNT'| translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.participationAmount }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="place">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.PLACE'| translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.place }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="pttNumber">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.PTT_NUMBER'| translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.pttNumber }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="address">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.ADDRESS'| translate }}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.address }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="startDate">
                     <th mat-header-cell *matHeaderCellDef>
                        {{'VS.ACCOMMODATION.START_DATE'| translate}}
                     </th>
                     <td mat-cell *matCellDef="let element">
                        {{ element?.startDate| date: 'dd.MM.yyyy.' }}
                     </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                     <th mat-header-cell *matHeaderCellDef>
                        {{ 'VS.ACTION_OPTION' | translate }}
                     </th>
                     <td mat-cell *matCellDef="let element; let i = index" class="action-link">
                        <button mat-icon-button (click)="deleteCostsParticipants(i)">
                           <mat-icon>delete</mat-icon>
                        </button>
                     </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsCostsParticipations"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsCostsParticipations"
                     class="problems-table__row">
                  </tr>
               </table>
            </div>
            <form-subgroup-divider></form-subgroup-divider>
            <mat-form-field class="sum" appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_04.DIFFERENCE_SUM'| translate}}
               </mat-label>
               <input matInput type="text" formControlName="differenceSum" readonly autocomplete="off" />
            </mat-form-field>
         </form>
         <form-footer>
            <button matStepperPrevious mat-stroked-button color="primary"
               class="form-footer__button form-footer__button--secondary">
               {{ "VS.PREVIOUS_STEP" | translate }}
            </button>
            <button matStepperNext mat-stroked-button color="primary"
               class="form-footer__button form-footer__button--secondary">
               {{ "VS.NEXT_STEP" | translate }}
            </button>
         </form-footer>
      </mat-step>
      <mat-step [stepControl]="thirdStep">
         <form class="form" [formGroup]="thirdStep">
            <ng-template matStepLabel>
               {{ 'VS.ACCOMMODATION.FINAL_ACT' | translate }}
            </ng-template>
            <field-holder kind="3-cols">
               <mat-form-field appearance="outline">
                  <mat-label>
                     {{ 'LAWYER.DECISION_TYPE' | translate}}
                  </mat-label>
                  <mat-select formControlName="decisionType" (selectionChange)="showPreviewButton($event.value)"
                     required [compareWith]="compareLoadedObjects">
                     <mat-option *ngFor="let decType of decisionTypes" [value]="decType">
                        {{ decType.title}}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field appearance="outline"
                  *ngIf="caseData?.socialCaseClassificationCodebook?.oldKindMark !== 'PS' && thirdStep.get('decisionType').value.id===1">
                  <mat-label>
                     {{ 'LAWYER.DECISION_TYPE' | translate}}
                  </mat-label>
                  <mat-select formControlName="decisionDocument" [compareWith]="compareLoaded">
                     <mat-option [value]="0">
                        {{'VS.ACCOMMODATION.FORMSO.LAWYER_O.FORM_O1' | translate }}
                     </mat-option>
                     <mat-option [value]="1">
                        {{'VS.ACCOMMODATION.FORMSO.LAWYER_O.FORM_O2' | translate }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field appearance="outline" *ngIf="thirdStep.get('decisionType').value.id===3">
                  <mat-label>{{ "LAWYER.TERMINATION_DATE" | translate }}</mat-label>
                  <input matInput [matDatepicker]="pickert" formControlName="terminationDate" [max]="maxDate" autocomplete="off" />
                  <mat-datepicker-toggle matSuffix [for]="pickert"></mat-datepicker-toggle>
                  <mat-datepicker #pickert></mat-datepicker>
               </mat-form-field>
               
               <mat-radio-group aria-label="Select an option" color="primary" formControlName="decisionMaker"
                  class="form__radio-group form__radio-group--2-cols">
                  <span class="form__radio-group-label">
                     {{ 'LAWYER.DECISION_MAKER' | translate }}
                  </span><br>
                  <mat-radio-button *ngFor="let option of decisionMakerOptions" [value]="option"
                     (change)="decisionMakerSelected($event)">
                     {{ option.title }}
                  </mat-radio-button>
               </mat-radio-group>
            </field-holder>
            <field-holder>
               <mat-form-field appearance="outline" *ngIf="thirdStep.value.decisionMaker?.id === 2">
                  <mat-label>{{ 'LAWYER.DECISION_MAKER_DESCRIPTION' | translate }}</mat-label>
                  <textarea matInput type="text" formControlName="decisionMakerDescription" autocomplete="off"
                     matTooltip="{{'LAWYER.DECISION_MAKER_DESCRIPTION_TOOLTIP'| translate}}">
               </textarea>
               </mat-form-field>
            </field-holder>
            <field-holder kind="3-cols">
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'LAWYER.DECISION_MAKER_TITLE' | translate }}</mat-label>
                  <input matInput type="text" formControlName="decisionMakerTitle" autocomplete="off" />
               </mat-form-field>
               <mat-form-field appearance="outline">
                  <mat-label>{{ 'LAWYER.DECISION_MAKER_NAME' | translate }}</mat-label>
                  <input matInput type="text" formControlName="decisionMakerName" autocomplete="off" required />
               </mat-form-field>

            </field-holder>
            <ng-container
               *ngIf="thirdStep.get('decisionType').value !== null && thirdStep.get('decisionType').value.id !== undefined">
               <field-holder>
                  <mat-form-field appearance="outline"
                     *ngIf="thirdStep.get('decisionType').value.id !== 1&&thirdStep.get('decisionType').value.id !== 6 && thirdStep.get('decisionType').value.id!==3">
                     <mat-label> {{ 'GLOBAL.DECISION_FOR_CASE_'+thirdStep.get('decisionType').value.id | translate }}
                     </mat-label>
                     <input matInput type="text" formControlName="reason" autocomplete="off" id="reason" />
                  </mat-form-field>
               </field-holder>
               <field-holder>
                  <mat-form-field appearance="outline" *ngIf="thirdStep.get('decisionType').value.id===3">
                     <mat-label>
                        {{ 'GLOBAL.DECISION_FOR_CASE_3'| translate }}
                     </mat-label>
                     <mat-select formControlName="terminationReason" [compareWith]="compareLoadedObjects" required>
                        <mat-option *ngFor="let reason of terminationReasons" [value]="reason">
                           {{ reason.title}}
                        </mat-option>
                     </mat-select>
                  </mat-form-field>
               </field-holder>
            </ng-container>
            <mat-checkbox color="primary" class="form__checkbox"
               *ngIf="caseData?.socialCaseClassificationCodebook.oldKindMark==='PS' && thirdStep.get('decisionType').value.id===1"
               formControlName="fromStateBudget">
               {{ 'VS.ACCOMMODATION.FORMSO.FROM_STATE_BUDGET' | translate }}
            </mat-checkbox>
            <br>
            <div class="aside"
               *ngIf="showPreview===false&&(thirdStep.value.decisionType!==''&&thirdStep.value.decisionType!==null&&thirdStep.value.decisionType!==undefined)">
               <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
                  (click)="$event.stopPropagation()">
                  {{ 'LAWYER.DOCUMENT' | translate }}
                  <mat-icon>arrow_drop_down</mat-icon>
               </button>
               <mat-menu #menu="matMenu" class="filter-menu">
                  <ng-container *ngFor="let docOptions of documentOptions">
                     <button mat-menu-item (click)="handleNewEvaluation(docOptions)">{{ docOptions.name }}</button>
                  </ng-container>
               </mat-menu>

               <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden>
               <button class="header__add-document" style="margin-left: 15px;" mat-flat-button color="primary"
                  type="submit" (click)="file.click()">
                  {{ 'LAWYER.CREATE' | translate }}
               </button>
               <br>
               <field-holder>
                  <mat-form-field appearance="outline">
                     <mat-label>{{ 'LAWYER.SOLUTION' | translate }}</mat-label>
                     <input matInput type="text" formControlName="fileName" readonly required autocomplete="off" />
                  </mat-form-field>
               </field-holder>
            </div>
            <form-footer>
               <button matStepperPrevious mat-stroked-button color="primary"
                  class="form-footer__button form-footer__button--secondary">
                  {{ "VS.PREVIOUS_STEP" | translate }}
               </button>
               <span matTooltip="{{checkIfSendIsDisabled().msg}}"
                  [matTooltipDisabled]="checkIfSendIsDisabled().disabled">
                  <span matTooltip="{{'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
                     [matTooltipDisabled]="!newComplain">
                     <button mat-flat-button color="primary" class="form-footer__button form-footer__button--secondary"
                        (click)="submitConclusion(); submitButton.disabled = true" #submitButton
                        [disabled]="caseData?.caseStatus !== 'ACCEPTED' || !thirdStep.valid || newComplain || !hasProblem">
                        {{ 'LAWYER.SUBMIT_AND_SEND' | translate }}
                     </button>
                  </span>
               </span>
               <button mat-flat-button color="primary" type="button" *ngIf="showPreview" (click)="preview()"
                  class="form-footer__button form-footer__button--secondary">
                  {{ "PRINT.PREVIEW" | translate }}
               </button>
               <button mat-stroked-button color="primary" type="button" (click)="saveFormSO()"
                  class="form-footer__button form-footer__button--secondary">
                  {{ "GLOBAL.SAVE" | translate }}
               </button>
            </form-footer>
         </form>
      </mat-step>
   </mat-vertical-stepper>
</mat-card>