/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "form-footer",
  templateUrl: "./form-footer.component.html",
  styleUrls: ["./form-footer.component.scss"],
})
export class FormFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
