<container kind="mini">
  <page-intro-header title="{{ 'NSP_REQUEST.TITLE' | translate }}">
    <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" class="dropdown-button">
      {{ 'GLOBAL.MANAGE_NSP_REQUEST' | translate }} <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <span>
        <button mat-menu-item (click)="openNSPFamilyRequest()">
          {{ 'NSP_REQUEST.NSP_P' | translate }}
        </button>
      </span>
      <span>
        <button mat-menu-item (click)="printPreview()">
          {{ "PRINT.PREVIEW" | translate }}
        </button>
      </span>
    </mat-menu>
  </page-intro-header>
  <mat-card class="card nsp-request-wrapper">
    <mat-vertical-stepper #stepper [linear]="true">
      <mat-step [stepControl]="firstStep">
        <form class="form" [formGroup]="firstStep">
          <ng-template matStepLabel>{{
            "NSP_REQUEST.STEP_01.TITLE" | translate
          }}</ng-template>
          <div class="button-add-holder">
            <button mat-flat-button color="primary" (click)="importSubjectData()">
              <mat-icon>person_add</mat-icon>
              {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
            </button>
          </div>
          <form-subgroup-divider></form-subgroup-divider>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.CSR" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="center" readonly autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.NUMBER" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="nspRequestIdentity" readonly autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ "NSP_REQUEST.STEP_01.DATE" | translate }}</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="dateOfSubmission" autocomplete="off" [max]="maxDate" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="firstStep.get('dateOfSubmission').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.DATE_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
          </field-holder>
          <form-subgroup-divider></form-subgroup-divider>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.FIRST_NAME" | translate }}
              </mat-label>
              <input matInput type="text" readonly formControlName="firstName" autocomplete="off" />
              <mat-error *ngIf="firstStep.get('firstName').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.FIRST_NAME_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.LAST_NAME" | translate }}
              </mat-label>
              <input matInput type="text" readonly formControlName="lastName" autocomplete="off" />
              <mat-error *ngIf="firstStep.get('lastName').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.LAST_NAME_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.PARENT_NAME" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="parentName" autocomplete="off" />
              <mat-error *ngIf="firstStep.get('parentName').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.PARENT_NAME_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
          </field-holder>
           <div class="checbox-holder">
              <mat-checkbox color="primary" formControlName="foreigner">
                {{ 'NSP_REQUEST.STEP_01.FOREIGNER' | translate }}
              </mat-checkbox>
            </div>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label *ngIf="firstStep.get('foreigner').value === true"> 
                  {{'NSP_REQUEST.STEP_01.EBS' | translate}}
              </mat-label>
              <mat-label *ngIf="firstStep.get('foreigner').value !== true">
                {{ "NSP_REQUEST.STEP_01.JMBG" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="jmbg" autocomplete="off" maxlength="13"
                (change)="checkJMBG($event)" />
              <mat-error *ngIf="
                firstStep.get('foreigner').value !== true &&
                  (firstStep.get('jmbg').hasError('required') ||
                  firstStep.get('jmbg').hasError('minLength') ||
                  firstStep.get('jmbg').hasError('maxLength') ||
                  firstStep.get('jmbg').hasError('pattern'))
                ">
                {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
              </mat-error>
              <mat-error *ngIf="firstStep.get('foreigner').value !== true && firstStep.get('jmbg').hasError('controlNumber')">
                {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR_VALIDATION' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{  "NSP_REQUEST.STEP_01.GENDER" | translate }}
              </mat-label>
              <mat-select formControlName="gender" [compareWith]="compareGenderObjects"
                (selectionChange)="updateJMBGOnGenderChange($event)">
                <mat-option></mat-option>
                <mat-option *ngFor="let gender of genderOptions" [value]="gender">
                  {{ gender.gender }}
                </mat-option>
              </mat-select>
              <mat-error
                  *ngIf="firstStep.get('gender').hasError('required') || firstStep.get('gender').hasError('incorrect')">
                  {{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.DATE_OF_BIRTH" | translate }}
              </mat-label>
              <input matInput [matDatepicker]="picker2" formControlName="dateOfBirth" (dateChange)="checkJMBG($event)"
                autocomplete="off" [max]="maxDate"/>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <mat-error
                *ngIf="firstStep.get('dateOfBirth').hasError('required') || firstStep.get('dateOfBirth').hasError('incorrect')">
                {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.PLACE_OF_BIRTH" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "NSP_REQUEST.STEP_01.CITIZENSHIP" | translate
              }}</mat-label>
              <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenship" (focusout)="autoSelectItem(citizenships, 'citizenship', firstStep)"/>
              <mat-autocomplete #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat" panelWidth="auto">
                <mat-option *ngFor="let option of filteredCitizenshipOptions | async" [value]="option">
                  {{ option.title }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="firstStep.get('citizenship').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.CITIZENSHIP" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "NSP_REQUEST.STEP_01.NATIONALITY" | translate
              }}</mat-label>
              <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationality" (focusout)="autoSelectItem(nationalities, 'nationality', firstStep)" />
              <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="displayCustomFormat">
                <mat-option *ngFor="let option of filteredNationalityOptions | async" [value]="option">
                  {{ option.title }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="firstStep.get('nationality').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.NATIONALITY" | translate }}
              </mat-error>
            </mat-form-field>
          </field-holder>
          <form-subgroup-divider></form-subgroup-divider>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.ADDRESS" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="address" maxlength="60" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.ADDRESS_NUMBER" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="addressNumber" maxlength="10" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.EMAIL_ADDRESS" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="emailAddress" autocomplete="off" />
            </mat-form-field>
          </field-holder>
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.PHONE" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="phone" maxlength="20" autocomplete="off" />
              <mat-error *ngIf="firstStep.get('phone').hasError('pattern')">
                {{ 'NSP_REQUEST.STEP_01.PHONE_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.MOBILE" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="mobile" maxlength="20" autocomplete="off" />
              <mat-error *ngIf="firstStep.get('mobile').hasError('pattern')">
                {{ 'NSP_REQUEST.STEP_01.MOBILE_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </field-holder>
          <form-subgroup-divider></form-subgroup-divider>
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.MARITAL_STATUS" | translate }}
              </mat-label>
              <mat-select formControlName="maritalStatus">
                <mat-option *ngFor="let marStat of maritalStatuses" [value]="marStat.title">
                  {{ marStat.title }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="firstStep.get('maritalStatus').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.MARITAL_STATUS_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.EDUCATION" | translate }}
              </mat-label>
              <mat-select formControlName="education">
                <mat-option *ngFor="let edu of educations" [value]="edu.title">
                  {{ edu.title }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="firstStep.get('education').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.EDUCATION_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
          </field-holder>
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.OCCUPATION" | translate }}
              </mat-label>
              <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupation" (focusout)="autoSelectItem(occupations, 'occupation', firstStep)"/>
              <mat-autocomplete #autoOccupation="matAutocomplete" [displayWith]="displayCustomFormat" panelWidth="auto">
                <mat-option *ngFor="let option of filteredOccupationOptions | async" [value]="option">
                  {{ option.title }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="firstStep.get('occupation').hasError('required')">
                {{ "NSP_REQUEST.STEP_01.OCCUPATION" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.EMPLOYMENT" | translate }}
              </mat-label>
              <mat-select formControlName="employment" (selectionChange)="clearEmploymentFields($event)">
                <mat-option *ngFor="let emp of employments" [value]="emp.title">
                  {{ emp.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="firstStep.get('employment').value ==='Остало' ">
              <mat-label>
                {{ "NSP_FAMILY_INFO.STEP_01.EMPLOYMENT_OTHER" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="employmentOther" autocomplete="off" [required]="
                  firstStep.get('employment').value === 'Остало' " />
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline"
              *ngIf="firstStep.get('employment').value === 'Запослен' || firstStep.get('employment').value === 'Радно ангажован' ">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.EMPLOYER_NAME" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="employerName" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline"
              *ngIf="firstStep.get('employment').value === 'Запослен' || firstStep.get('employment').value === 'Радно ангажован' ">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.INDEPENDENT_AGENCY" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="independentAgency" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline"
              *ngIf="firstStep.get('employment').value === 'Запослен' || firstStep.get('employment').value === 'Радно ангажован' ">
              <mat-label>
                {{ "NSP_REQUEST.STEP_01.AGRICULTURIST" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="agriculturist" autocomplete="off" />
            </mat-form-field>
          </field-holder>
          <form-footer>
            <div
              [matTooltip]="subjectIdentity.subjectId===null?'Учитајте корисника из регистра да би сте наставили даље':''">
              <button matStepperNext mat-stroked-button color="primary"
                [disabled]="!firstStep.valid || subjectIdentity.subjectId===null" class="form-footer__button">
                {{ "NSP_REQUEST.NEXT_STEP" | translate }}
              </button>
            </div>
          </form-footer>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondStep">
        <form class="form" [formGroup]="secondStep">
          <ng-template matStepLabel>{{
            "NSP_REQUEST.STEP_02.TITLE" | translate
          }}</ng-template>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.FAMILY_MEMBERS_NUMB" | translate }}
              </mat-label>
              <input matInput type="text" (change)="notifyForFamilyRequest()" formControlName="familyMembersNumb"
                autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.MINOR_CHILDREN_NUMB" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="minorChildrenNumb" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.CHILD_SCHOOL_STATUS" | translate }}
              </mat-label>
              <mat-select formControlName="childSchoolStatus">
                <mat-option *ngFor="let schoolStat of childSchoolStatuses" [value]="schoolStat.value">
                  {{ schoolStat.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </field-holder>
          <form-subgroup-divider></form-subgroup-divider>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.HOUSING_STATUS" | translate }}
              </mat-label>
              <mat-select formControlName="housingStatus" (selectionChange)="housingStatusChange($event.value)">
                <mat-option *ngFor="let houseStat of housingStatuses" [value]="houseStat.value">
                  {{houseStat.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="secondStep.get('housingStatus').value ==='Ostalo' ">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.OTHER" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="housingStatusOther" autocomplete="off" [required]="
                  secondStep.get('housingStatus').value === 'Ostalo' " />
            </mat-form-field>
            <mat-form-field appearance="outline"
              matTooltip="{{ 'NSP_REQUEST.STEP_02.HOUSE_CONCEPT_TOOLTIP' | translate }}">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.HOUSE_CONCEPT" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="houseConcept" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline"
              matTooltip="{{ 'NSP_REQUEST.STEP_02.HOUSE_AREA_TOOLTIP' | translate }}">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.HOUSE_AREA" | translate }}
              </mat-label>
              <input matInput type="number" formControlName="houseArea" autocomplete="off" />
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.HOUSE_INFO_1" | translate }}
              </mat-label>
              <mat-select formControlName="houseInfo1">
                <mat-option *ngFor="let houseInf of houseInfo1Statuses" [value]="houseInf.value">
                  {{houseInf.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.HOUSE_INFO_2" | translate }}
              </mat-label>
              <mat-select formControlName="houseInfo2">
                <mat-option *ngFor="let house2 of houseInfo2Statuses" [value]="house2.value">
                  {{house2.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_02.TOTAL_LAND_AREA" | translate }}
              </mat-label>
              <input matInput type="number" formControlName="totalLandArea" autocomplete="off" />
              <span matSuffix>{{ 'GLOBAL.HA' | translate }}</span>
            </mat-form-field>
          </field-holder>
          <form-footer>
            <button matStepperPrevious mat-stroked-button color="primary"
              class="form-footer__button form-footer__button--secondary">
              {{ "NSP_REQUEST.PREVIOUS_STEP" | translate }}
            </button>
            <button matStepperNext mat-stroked-button color="primary" (click)="calculateNspAmount()"
              [disabled]="secondStep.invalid" class="form-footer__button">
              {{ "NSP_REQUEST.NEXT_STEP" | translate }}
            </button>
          </form-footer>
        </form>
      </mat-step>

      <mat-step [stepControl]="thirdStep">
        <form class="form" [formGroup]="thirdStep">
          <ng-template matStepLabel>{{
                "NSP_REQUEST.STEP_03.TITLE" | translate
            }}</ng-template>
          <field-holder>
            <mat-form-field appearance="outline" (click)="selectMulti()">
              <mat-label>{{
              'NSP_REQUEST.STEP_03.SUBTITLE' | translate
            }}</mat-label>
              <mat-select (selectionChange)="selectChangeHandler($event)" [formControl]="revenues" multiple>
                <input type="text" class="multi-input" autocomplete="off" matInput placeholder="{{'NSP_REQUEST.STEP_03.SEARCH_REVENUES' | translate}}" #multiSearch (input)="onInputChange($event.target.value)"/>
                <mat-option *ngFor="let rev of filteredFamilyRevenues" [value]="rev.value">
                  {{ rev.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('01')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.01' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.01' | translate}}</mat-label>
              <input matInput type="text" formControlName="revenue01" autocomplete="off"
                [required]="revenueSelected.includes('01')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('02')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.02' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.02' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue02" [required]="revenueSelected.includes('02')"
                autocomplete="off" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('03')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.03' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.03' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue03" autocomplete="off"
                [required]="revenueSelected.includes('03')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('04')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.04' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.04' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue04" autocomplete="off"
                [required]="revenueSelected.includes('04')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('05')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.05' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.05' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue05" autocomplete="off"
                [required]="revenueSelected.includes('05')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('06')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.06' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.06' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue06" autocomplete="off"
                [required]="revenueSelected.includes('06')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('07')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.07' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.07' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue07" autocomplete="off"
                [required]="revenueSelected.includes('07')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('08')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.01' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.08' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue08" autocomplete="off"
                [required]="revenueSelected.includes('08')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('09')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.09' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.09' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue09" autocomplete="off"
                [required]="revenueSelected.includes('09')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('10')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.10' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.10' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue10" autocomplete="off"
                [required]="revenueSelected.includes('10')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('11')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.11' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.11' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue11" autocomplete="off"
                [required]="revenueSelected.includes('11')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="revenueSelected.includes('12')"
              matTooltip="{{'NSP_REQUEST.STEP_03.REVENUES.12' | translate}}">
              <mat-label>
                {{'NSP_REQUEST.STEP_03.REVENUES.12' | translate}}
              </mat-label>
              <input matInput type="text" formControlName="revenue12" autocomplete="off"
                [required]="revenueSelected.includes('12')" (change)="updateTotalRevenue($event)" />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
          </field-holder>
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{
              'NSP_REQUEST.STEP_03.REVENUES.13' | translate
              }}</mat-label>
              <input matInput type="text" formControlName="revenue13" autocomplete="off" readonly />
              <span matSuffix>{{ 'GLOBAL.RSD' | translate }}</span>
            </mat-form-field>
          </field-holder>
          <form-footer>
            <button matStepperPrevious mat-stroked-button color="primary"
              class="form-footer__button form-footer__button--secondary">
              {{ "NSP_REQUEST.PREVIOUS_STEP" | translate }}
            </button>
            <button matStepperNext mat-stroked-button color="primary" [disabled]="!thirdStep.valid"
              class="form-footer__button">
              {{ "NSP_REQUEST.NEXT_STEP" | translate }}
            </button>
          </form-footer>
        </form>
      </mat-step>

      <mat-step [stepControl]="fourthStep">
        <form class="form" [formGroup]="fourthStep">
          <ng-template matStepLabel>{{
            "NSP_REQUEST.STEP_04.TITLE" | translate
          }}</ng-template>

          <field-holder>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "NSP_REQUEST.STEP_04.DEPENDENT_STATUS" | translate
              }}</mat-label>
              <mat-select formControlName="dependentStatus" required>
                <mat-option *ngFor="let depStat of dependentStatuses" [value]="depStat.value">
                  {{ depStat.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </field-holder>
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
              <mat-label>{{
                "NSP_REQUEST.STEP_04.BREADWINNER_STATUS" | translate
              }}</mat-label>
              <mat-select formControlName="breadWinnerStatus" (selectionChange)="optionalFiealdStatusSet()" required>
                <mat-option *ngFor="let brWinStat of breadWinnerStatuses" [value]="brWinStat.value">
                  {{ brWinStat.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "NSP_REQUEST.STEP_04.ENGAGEMENT" | translate
              }}</mat-label>
              <mat-select formControlName="engagementStatus">
                <mat-option *ngFor="let engStat of engagementStatuses" [value]="engStat.value">
                  {{ engStat.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </field-holder>
          <field-holder kind="2-cols">
            <mat-form-field appearance="outline" *ngIf="fourthStep.get('breadWinnerStatus').value === 'IMA_OBAVEZU'">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.FIRST_LAST_NAME" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="firstLastName" autocomplete="off" required />
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="fourthStep.get('breadWinnerStatus').value === 'IMA_OBAVEZU'">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.RELATIONSHIP" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="relationship" (focusout)="autoSelectItem(relationships, 'relationship', fourthStep)"
                [matAutocomplete]="autoRelationship" required />
              <mat-autocomplete #autoRelationship="matAutocomplete" [displayWith]="displayCustomFormat">
                <mat-option *ngFor="let option of relationshipsFiltered | async" [value]="option">
                  {{ option.title }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="fourthStep.get('relationship').hasError('required')">
                {{ "GLOBAL.AUTOCOMPLETE_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
          </field-holder>
          <field-holder>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.IMPORTANT_INFO" | translate }}
              </mat-label>
              <textarea matInput type="text" maxlength="1000" formControlName="importantInfo" autocomplete="off"></textarea>
            </mat-form-field>
          </field-holder>
          <form-subgroup-divider></form-subgroup-divider>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.FAMILY_MEMBERS_NUMBER" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="familyMembersNumber" autocomplete="off" maxlength="2" />
              <mat-error *ngIf="fourthStep.get('familyMembersNumber').hasError('pattern')">
                {{ "NSP_REQUEST.STEP_04.FAMILY_MEMBERS_NUMBER_ERROR" | translate }}
              </mat-error>
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.EVIDENCE01" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="evidence01" maxlength="55" autocomplete="off" matTooltip="{{
              'NSP_REQUEST.STEP_04.EVIDENCE_TOOLTIP' | translate}}" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.EVIDENCE02" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="evidence02" maxlength="55" autocomplete="off" matTooltip="{{
              'NSP_REQUEST.STEP_04.EVIDENCE_TOOLTIP' | translate}}" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.EVIDENCE03" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="evidence03" maxlength="55" autocomplete="off" matTooltip="{{
              'NSP_REQUEST.STEP_04.EVIDENCE_TOOLTIP' | translate}}" />
            </mat-form-field>
          </field-holder>
          <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.EVIDENCE04" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="evidence04" maxlength="55" autocomplete="off" matTooltip="{{
              'NSP_REQUEST.STEP_04.EVIDENCE_TOOLTIP' | translate}}" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.EVIDENCE05" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="evidence05" maxlength="55" autocomplete="off" matTooltip="{{
              'NSP_REQUEST.STEP_04.EVIDENCE_TOOLTIP' | translate}}" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "NSP_REQUEST.STEP_04.EVIDENCE06" | translate }}
              </mat-label>
              <input matInput type="text" formControlName="evidence06" maxlength="55" autocomplete="off" matTooltip="{{
              'NSP_REQUEST.STEP_04.EVIDENCE_TOOLTIP' | translate}}" />
            </mat-form-field>
          </field-holder>
          <form-footer>
            <button matStepperPrevious mat-stroked-button color="primary"
              class="form-footer__button form-footer__button--secondary">
              {{ "NSP_REQUEST.PREVIOUS_STEP" | translate }}
            </button>
            <span [matTooltip]="notMadeEnoughNspPMessage" [matTooltipDisabled]="numberOfNSPF<=numberOfNSPFMade">
              <button mat-flat-button color="primary" (click)="saveNSPRequest(); submitButton.disabled = true;"
                [disabled]="!fourthStep.valid || numberOfNSPF>numberOfNSPFMade"
                class="form-footer__button form-footer__button--secondary"
                #submitButton>
                {{ "NSP_REQUEST.SAVE" | translate }}
              </button>
            </span>
          </form-footer>
        </form>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card>
</container>