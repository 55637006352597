/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { CodebookService } from 'src/services/codebook.service';
import { ImportCollectionsService } from 'src/services/import-collections.service';
import { ImportDataService } from 'src/services/import-data.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { clearInterval, setInterval } from 'timers';


enum AutocompleteFilterEnum {
    SUBCENTER = 'subcenter',
}

enum ImportStatus {
    IMPORTING = 'IMPORTING',
    IDLE = 'IDLE',
    ERROR = 'ERROR',
    FINISHED = 'FINISHED',
    IN_PROCESS = 'in process'
}

enum LocalStorageData {
    COLLECTIONS_IMPORTING = 'importing',
    COLLECTIONS_SUBCENTER_ID = 'importSubcenterId',
}

enum MessageStatus {
    ERROR = 'Greška',
}

enum Collections {
    PROTEGE_PERSON_REGISTRY = 'REGISTAR_LICA_POD_STARATELJSTVOM',
    PROTEGE_PERSON_ADULTS = 'KNJIGA_PUNOLETNIH_STICENIKA',
    PROTEGE_PERSON_CHILD = 'KNJIGA_MALOLETNIH_STICENIKA',
    FOSTER_CARE = 'ZASNOVANA_HRANITELJSTVA',
    FOSTER_CHILD = 'HRANJENICI',
    FOSTER_PARENT = 'HRANITELJI',
    DEPENDENT_PERSON = 'IZDRZAVANO_LICE',
    ABUSED_PERSON = 'ZRTVE_NASILJA',
    VIOLENCE_PERSON = 'NASILINICI',
    ADOPTED = 'USVOJENA_DECA'
}

@Component({
    selector: 'app-import-collections',
    templateUrl: './import-collections.component.html',
    styleUrls: ['./import-collections.component.scss']
})
export class ImportCollectionsComponent implements OnInit, OnDestroy {

    subcenter = new FormControl();
    subcenters = [];
    importDataForm: FormGroup;
    @ViewChild('submitButton') submitButton: any;

    filteredSubcenters: Observable<any>;
    fileToUpload = null;
    uploadStatus = '';
    uploadMessage = '';
    checkStatusInterval = null;
    checkMessagesInterval = null;
    traceImportProcess = '';
    selectedCollection = '';
    collections = Collections;
    collectionsImports = [];
    displayedColumns: string[] = [
        'id',
        'center',
        'startDate',
        'endDate',
        'nameCSV',
        'subjectCount',
        'status',
        'collectionName'
    ];

    constructor(private codebookService: CodebookService,
        private builder: FormBuilder,
        private importCollectionService: ImportCollectionsService,
        private localStorageService: LocalStorageService,
        private toastr: ToastrImplService,
        private importDataService: ImportDataService) {

        this.importDataForm = this.builder.group({
            files: [null, Validators.required],
            subcenter: [null, []],
            collection: null
        });
        this.getLast10Imports();
        this.codebookService.getCsrCodebook().subscribe(result => {
            this.subcenters = result;
            this.filteredSubcenters = this.importDataForm.controls.subcenter.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value.name),
                    map(title => title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice())
                );
        }, () => {
            console.log('greska');
        });

        if (this.localStorageService.get(LocalStorageData.COLLECTIONS_IMPORTING) === ImportStatus.IN_PROCESS
            && this.localStorageService.get(LocalStorageData.COLLECTIONS_SUBCENTER_ID)) {
            this.createIntervals();
            this.codebookService.getCsrCodebook().subscribe(result => {
                this.subcenters = result;
                const centerId = Number(this.localStorageService.get(LocalStorageData.COLLECTIONS_SUBCENTER_ID))
                this.importDataForm.controls.subcenter.setValue(this.subcenters.find(x => x.id === centerId));
                this.filteredSubcenters = this.subcenter.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => typeof value === 'string' ? value : value.name),
                        map(title => title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice())
                    );
            }, () => {
                console.log('greska');
            });

        }
    }

    ngOnInit(): void {
    }

    private _filter(value: string, what: AutocompleteFilterEnum): any[] {
        const filterValue = value.toLowerCase();

        switch (what) {
            case AutocompleteFilterEnum.SUBCENTER:
                return this.subcenters.filter((option) =>
                    option.name.toLowerCase().includes(filterValue)
                );
            default:
                break;
        }
    }

    private _filterId(value: number, what: AutocompleteFilterEnum): any[] {
        switch (what) {
            case AutocompleteFilterEnum.SUBCENTER:
                return this.subcenters.filter((option) =>
                    option.id === value
                );
            default:
                break;
        }
    }

    public submit(event: any) {
        this.uploadMessage = '';
        this.traceImportProcess = '';
        if (this.uploadStatus === ImportStatus.IMPORTING) {
            return;
        }
        this.importDataForm.value.files = event.target.files;
        this.fileToUpload = event.target.files[0];
        event.target.value = '';
    }
    public dropSuccess(files: any) {
        this.traceImportProcess = '';
        if (this.uploadStatus === ImportStatus.IMPORTING) {
            return;
        }
        this.importDataForm.controls.files.setValue(files);
        this.fileToUpload = files[0];
    }

    displayCustomFormat(objectValue: any): string {
        return objectValue ? objectValue.name : objectValue;
    }

    submitImport() {
        this.submitButton.disabled = true;
        this.localStorageService.set(LocalStorageData.COLLECTIONS_SUBCENTER_ID, this.importDataForm.value.subcenter.id);
        this.importCollectionService.importData(this.importDataForm.value.collection,
            this.importDataForm.value.subcenter.id,
            this.fileToUpload)
            .subscribe(
                () => {
                    this.localStorageService.set(LocalStorageData.COLLECTIONS_IMPORTING, ImportStatus.IN_PROCESS);
                    this.createIntervals();
                    this.fileToUpload = null;
                }, () => {
                    this.submitButton.disabled = false;
                }
            );

    }

    checkStatus() {
        this.importCollectionService.checkStatus().subscribe(
            (result) => {
                this.uploadStatus = result;
                if (this.uploadStatus === ImportStatus.IMPORTING) {
                    this.uploadMessage = 'IMPORT_COLLECTIONS.IMPORTING';
                }
                if (this.uploadStatus === ImportStatus.IDLE) {
                    this.uploadMessage = 'IMPORT_COLLECTIONS.IDLE';
                    this.clearIntervals();
                    this.getMessages();
                    this.localStorageService.remove(LocalStorageData.COLLECTIONS_IMPORTING);
                    this.localStorageService.remove(LocalStorageData.COLLECTIONS_SUBCENTER_ID);

                }
                if (this.uploadStatus === ImportStatus.ERROR) {
                    this.uploadMessage = 'IMPORT_COLLECTIONS.ERROR';
                    this.clearIntervals();
                    this.getMessages();
                    this.localStorageService.remove(LocalStorageData.COLLECTIONS_IMPORTING);
                    this.localStorageService.remove(LocalStorageData.COLLECTIONS_SUBCENTER_ID);
                    this.getLast10Imports();
                }
                if (this.uploadStatus === ImportStatus.FINISHED) {
                    this.uploadMessage = 'IMPORT_COLLECTIONS.FINISHED';
                    this.clearIntervals();
                    this.getMessages();
                    this.localStorageService.remove(LocalStorageData.COLLECTIONS_IMPORTING);
                    this.localStorageService.remove(LocalStorageData.COLLECTIONS_SUBCENTER_ID);
                    this.getLast10Imports();
                }
            }, () => {
                console.log('greska');
                this.clearIntervals();
            }
        );
    }

    getMessages() {
        this.importCollectionService.getMessages().subscribe(
            result => {
                if (result.startsWith(MessageStatus.ERROR)) {
                    this.clearLocalStorage();
                    this.uploadStatus = ImportStatus.FINISHED;
                }
                if (result !== '') {
                    this.traceImportProcess += result + '\n';
                }
            }
        );
    }

    stopImport() {
        this.uploadStatus = ImportStatus.IDLE;
        this.importCollectionService.stopImport().subscribe(
            () => {
                this.clearLocalStorage();
            }, () => {
                console.log('greska');
            }
        );
    }

    syncImport() {
        if (this.localStorageService.get(LocalStorageData.COLLECTIONS_SUBCENTER_ID)) {
            const id = this.localStorageService.get(LocalStorageData.COLLECTIONS_SUBCENTER_ID);
            this.importCollectionService.syncData(Number(id)).subscribe(
                (result) => {
                    console.log(result + '\n');

                }, () => {
                    console.log('greska');
                }
            );
            this.clearLocalStorage();
        } else {
            this.clearLocalStorage();
        }
    }


    createIntervals() {
        this.checkStatusInterval = setInterval(() => {
            this.checkStatus();
        }, 1000);
        this.checkMessagesInterval = setInterval(() => {
            this.getMessages();
        }, 1000);
    }

    clearIntervals() {
        if (this.checkMessagesInterval) {
            clearInterval(this.checkMessagesInterval);
        }
        if (this.checkStatusInterval) {
            clearInterval(this.checkStatusInterval);
        }
    }

    resetFileds() {
        if (this.uploadStatus === ImportStatus.FINISHED && this.fileToUpload == null ||
            this.uploadStatus === ImportStatus.ERROR && this.fileToUpload == null) {
            this.clearLocalStorage();
        }
    }

    clearLocalStorage() {
        this.clearIntervals();
        this.uploadStatus = '';
        this.uploadMessage = '';
        this.traceImportProcess = '';
        this.fileToUpload = null;
        this.importDataForm.controls.files.setValue(null);
        this.importDataForm.controls.subcenter.setValue('');
        this.importDataForm.controls.collection.setValue(null);
        this.localStorageService.remove(LocalStorageData.COLLECTIONS_IMPORTING);
        this.localStorageService.remove(LocalStorageData.COLLECTIONS_SUBCENTER_ID);

    }

    ngOnDestroy(): void {
        if (this.uploadStatus !== ImportStatus.IMPORTING) {
            this.clearLocalStorage();
        }

    }

    getLast10Imports() {
        this.importDataService.getLast10CollectionImports().subscribe(result => {
            this.collectionsImports = result;
        });
    }

    onRowClicked(element: any) {
        if (element.status !== 'FINISHED') {
            this.toastr.showError(element.error);
        }
    }
}
