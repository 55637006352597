/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';

@Component({
  selector: 'app-send-into-planner',
  templateUrl: './send-into-planner.component.html',
  styleUrls: ['./send-into-planner.component.scss']
})
export class SendIntoPlannerComponent implements OnInit {
  reasonsOptions: any = [];
  sendIntoPlannerForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SendIntoPlannerComponent>,
    private formBuilder: FormBuilder,
    private codebookService: CodebookService) {
    this.getReasonsCodebookOptions();
  }

  ngOnInit(): void {
    this.sendIntoPlannerForm = this.formBuilder.group({
      reason: ['', [Validators.required]],
    });
  }

  send() {
    this.dialogRef.close({ event: 'Success', data: this.sendIntoPlannerForm.value.reason });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  getReasonsCodebookOptions() {
    this.codebookService.getPlannerReasonsCodebook().subscribe((res) => {
      this.reasonsOptions = res;
    });
  }
}
