/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CodebookService } from 'src/services/codebook.service';
import { LegalSubjectsService } from 'src/services/legal-subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import * as myLetters from './../../letters';

enum AutocompleteFilterEnum {
   CITY = 'city',
   PTT_NUMBER = 'pttNumber',
   REGION = 'region',
   INSTITUTION_TYPE = 'institution',
   STREET = 'street',
}

@Component({
   selector: 'legal-subject-form',
   templateUrl: './legal-subject-form.component.html',
   styleUrls: ['./legal-subject-form.component.scss'],
})
export class LegalSubjectFormComponent implements OnInit {
   indetificationNumberExist = true;
   oldIndeticationNumber: any;
   newForm: FormGroup;
   id: any;
   cities = [];

   clicked = false;

   isValidPib = false;

   cityOptions: string[] = []; // šifarnik
   filteredCityOptions: Observable<string[]>;

   // PTT broj
   pttNumberOptions = []; // šifarnik
   filteredPttNumberOptions: Observable<string[]>;

   // region
   regionOptions = []; // šifarnik
   filteredRegionOptions: Observable<string[]>;

   // Tip ustanove
   institutionTypes = [];
   filteredInstitutionTypesOptions: Observable<any[]>;

   // Ulica
   streetOptions: string[] = []; // ? šifarnik
   filteredStreetOptions: Observable<string[]>;

   @Input() isEditMode = false;
   @Output() emitUpdatedSubject = new EventEmitter();

   isLoaded: boolean;
   isAdmin: boolean = false;

   /* Maska predstavlja strukturu tekuceg racuna, prikazana u formatu XXX-XXXXXXXXXXXXX-XX */
   public mask = {
      guide: false,
      showMask: false,
      mask: [
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         '-',
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         /[0-9]/,
         '-',
         /[0-9]/,
         /[0-9]/,
      ],
   };

   constructor(
      private formBuilder: FormBuilder,
      public legalSubjectService: LegalSubjectsService,
      private router: Router,
      private route: ActivatedRoute,
      private codebookService: CodebookService,
      private translate: TranslateService,
      private userService: UserService,
      private toastr: ToastrImplService
   ) {
      this.route.params.subscribe(params => {
         this.id = params.id;
      });

      this.isAdmin = this.userService.isGlobalAdmin();

      this.codebookService.getCityCodebook().subscribe(result => {
         this.cities = result;
         for (const city of result) {
            this.cityOptions.push(city.name);
            this.pttNumberOptions.push(city['ptt-number']);
         }

         this.filteredCityOptions = this.newForm.controls.city.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, AutocompleteFilterEnum.CITY))
         );
      });

      this.codebookService.getRegionCodebook().subscribe(result => {
         for (const region of result) {
            this.regionOptions.push(region.name);
         }
         this.filteredRegionOptions = this.newForm.controls.region.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, AutocompleteFilterEnum.REGION))
         );
      });

      this.codebookService.getServiceProviderCodebook().subscribe(result => {
         this.institutionTypes = result;
         this.filteredInstitutionTypesOptions = this.newForm.controls.institutionType.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value.name)),
            map(name => (name ? this._filter(name, AutocompleteFilterEnum.INSTITUTION_TYPE) : this.institutionTypes.slice()))
         );
      });
   }

   checkInstitution() {
      if (this.newForm.controls.institutionType.value !== '' && this.newForm.controls.institutionType.value.name === undefined) {
         this.newForm.controls.institutionType.setErrors({ match: true });
      }
   }

   ngOnInit(): void {
      this.newForm = this.formBuilder.group({
         name: ['', [Validators.required]],
         institutionType: ['', [Validators.required]],
         street: ['', [Validators.required]],
         addressId: [null],
         addressNum: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
         addressSubNum: ['', [Validators.pattern(myLetters.addressSubnumber)]],
         city: ['', [Validators.required, this.valueSelected(this.cityOptions)]],
         pttNumber: [{ value: '', disabled: true }, [Validators.required, this.valueSelected(this.pttNumberOptions)]],
         region: ['', [Validators.required, this.valueSelected(this.regionOptions)]],
         phone: ['', [Validators.minLength(9), Validators.maxLength(13), Validators.pattern('^[0-9]*$')]],
         email: ['', Validators.email],
         account: ['', []],
         jbkbs: ['', [Validators.minLength(5), Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
         pib: ['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
         identificationNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8), Validators.maxLength(8)]],
      });
      this.newForm.controls.street.disable();
      this.filterOptions();
      this.dynamicValidation();

      if (this.id !== undefined) {
         this.legalSubjectService.getLegalSubjectById(this.id).subscribe(res => {
            this.indetificationNumberExist = false;
            this.oldIndeticationNumber = res.identificationNumber;
            this.isLoaded = res.isLoaded;
            this.newForm.patchValue({
               name: res.name,
               institutionType: res.institutionType,
               street: res.address?.street !== null ? res.address?.street : '',
               addressNum: res.address?.number !== null ? res.address?.number : '',
               addressSubNum: res.address?.subnumber !== null ? res.address?.subnumber : '',
               city: res.address?.town !== null ? res.address?.town : '',
               pttNumber: res.address?.ptt !== null ? res.address?.ptt : '',
               addressId: res.address?.id !== null ? res.address?.id : '',
               region: res.address?.region !== null ? res.address?.region : '',
               phone: res.phone,
               mobile: res.mobile,
               email: res.email,
               account: res.accountNumber,
               jbkbs: res.jbkbs,
               pib: res.pib,
               identificationNumber: res.identificationNumber,
            });
            res.address?.street !== '' && res.address !== null ? this.newForm.controls.street.enable() : this.newForm.controls.street.disable();
            res.address?.street !== '' && res.address !== null ? this.getStreetCodebook((res.address?.street as string).substring(0, 3)) : '';
         });
         this.newForm.markAllAsTouched();
      }
   }

   dynamicValidation() {
      this.newForm.get('city').valueChanges.subscribe(city => {
         if (city !== '') {
            this.newForm.get('pttNumber').enable();
            this.newForm.controls.street.enable();
         } else {
            this.newForm.get('pttNumber').disable();
            this.newForm.controls.street.disable();
         }
      });
   }

   filterOptions() {
      this.filteredCityOptions = this.newForm.controls.city.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.CITY))
      );

      this.filteredPttNumberOptions = this.newForm.controls.pttNumber.valueChanges.pipe(
         startWith(''),
         map(value => this._filter(value, AutocompleteFilterEnum.PTT_NUMBER))
      );
   }

   handleCancel() {
      if (!this.isEditMode) {
         this.router.navigate(['/subjects']);
      }

      this.emitUpdatedSubject.emit();
   }

   // Submit
   createLegalSubject(submittedForm: FormGroup) {
      this.clicked = true;
      const address = {
         id: submittedForm.get('addressId').value,
         town: submittedForm.get('city').value,
         ptt: submittedForm.get('pttNumber').value,
         street: submittedForm.get('street').value,
         number: submittedForm.get('addressNum').value,
         subnumber: submittedForm.get('addressSubNum').value,
         region: submittedForm.get('region').value,
      };

      this.isLoaded = this.isEditMode ? this.isLoaded : false;
      const newSubject = {
         id: this.id,
         name: submittedForm.get('name').value,
         institutionType: submittedForm.get('institutionType').value,
         address,
         email: submittedForm.get('email').value,
         phone: submittedForm.get('phone').value,
         accountNumber: submittedForm.get('account').value,
         jbkbs: submittedForm.get('jbkbs').value,
         pib: submittedForm.get('pib').value,
         identificationNumber: submittedForm.get('identificationNumber').value,
         loaded: this.isLoaded,
      };

      const x = this.legalSubjectService.addSubject(newSubject);
      x.subscribe(
         result => {
            if (result !== null) {
               if (!this.isEditMode) {
                  this.router.navigateByUrl('/legal_subjects');
                  this.toastr.success('SNACKBAR.USER_ADDED_IN_REGISTER');
               } else {
                  this.emitUpdatedSubject.emit(result);
               }
            } else {
               this.toastr.error('ERROR_OCCURED');
            }
            this.clicked = false;
         },
         () => {
            this.toastr.error('ERROR_OCCURED');
            this.clicked = false;
         }
      );
   }

   /**
    * Check if selected option from autocomplete is not altered
    * (e.g. if the correct value is Novi Sad, but the user added New Sad)
    */
   valueSelected(myArray: any[]) {
      return (control): { [key: string]: boolean } | null => {
         const selectboxValue = control.value;
         if (selectboxValue !== '' && myArray.length !== 0) {
            const itemExist = myArray.find(item => item === selectboxValue);

            if (itemExist) {
               // everything's fine. return no error. therefore it's null.
               return null;
            } else {
               // there's no matching select box value selected. so return match error.
               return { match: true };
            }
         }
      };
   }

   /**
    * _filter
    * @param value input value
    * @param what which filter should be applied (check AutocompleteFilterEnum)
    */
   private _filter(value: string, what: AutocompleteFilterEnum): string[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.CITY:
            return this.cityOptions.filter(option => option.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.PTT_NUMBER:
            return this.pttNumberOptions.filter(option => option.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.REGION:
            return this.regionOptions.filter(option => option.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.INSTITUTION_TYPE:
            return this.institutionTypes.filter(option => option.name.toLowerCase().includes(filterValue));
         case AutocompleteFilterEnum.STREET:
            return this.streetOptions.filter(option => option.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }

   filterPtt(city: any) {
      for (const value of this.cities) {
         if (value.name === city) {
            this.newForm.get('pttNumber').setValue(value['ptt-number']);
            this.filteredPttNumberOptions = this.newForm.controls.pttNumber.valueChanges.pipe(
               startWith(''),
               map(valuefilter => this._filter(valuefilter, AutocompleteFilterEnum.PTT_NUMBER))
            );
         }
      }
   }

   // Trenutna provjera da li postoji pravno lice sa unesenim PIB-om
   checkPIB(event: any) {
      if (this.id === undefined) {
         if (event.target.value.length === 9) {
            const x = this.legalSubjectService.getLegalSubject(event.target.value);
            x.subscribe(
               res => {
                  if (res !== null && res !== '') {
                     if (this.isAdmin) {
                        this.toastr.warning('SNACKBAR.USER_EXIST');
                        this.isValidPib = true;
                     } else {
                        this.toastr.error('SNACKBAR.USER_EXIST');
                        this.isValidPib = false;
                     }
                  } else {
                     this.toastr.success('SNACKBAR.USER_NOT_EXIST');
                     this.isValidPib = true;
                  }
               },
               () => {
                  console.log('Error occured');
               }
            );
         }
      }
   }

   displayCustomFormat(object: any): string {
      return object ? object.name : '';
   }

   checkIdentificationNumber() {
      if (this.newForm.value.identificationNumber.length === 8) {
         if (this.oldIndeticationNumber !== this.newForm.value.identificationNumber) {
            this.legalSubjectService.checkIndetificationNumber(this.newForm.value.identificationNumber).subscribe(res => {
               this.indetificationNumberExist = res;
               if (this.indetificationNumberExist === true && !this.isAdmin) {
                  this.newForm.controls.identificationNumber.markAsTouched();
                  this.newForm.controls.identificationNumber.setErrors({ notValid: true });
               } else if (this.indetificationNumberExist === true && this.isAdmin) {
                  this.toastr.warning('LEGAL_SUBJECTS.IDENTIFICATION_NUMBER_EXIST');
                  this.indetificationNumberExist = false;
               } else {
                  this.newForm.controls.identificationNumber.setErrors(null);
               }
            });
         } else {
            this.indetificationNumberExist = false;
            this.newForm.controls.identificationNumber.setErrors(null);
         }
      } else {
         this.indetificationNumberExist = true;
      }
   }

   getStreetCodebook(streetName: any) {
      this.codebookService.getStreetCodebookByStreetName(streetName).subscribe(result => {
         this.streetOptions = [];
         for (const street of result) {
            this.streetOptions.push(street.name);
         }
         this.filteredStreetOptions = this.newForm.controls.street.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value === null ? '' : value, AutocompleteFilterEnum.STREET))
         );
      });
   }

   checkLengthStreetName() {
      if ((this.newForm.controls.street.value as string).length === 3) {
         this.getStreetCodebook(this.newForm.controls.street.value);
      }
   }
}
