<form class="form" [formGroup]="annulmentAdoptionForm" (ngSubmit)="save()">
  <form-group
    title="{{ 'NEW_ADOPTED_CHILDREN.FORM.ANNULMENT_OF_ADOPTION_T' | translate }}"
    isLast="true"
  >
    <field-holder kind="1-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_ADOPTED_CHILDREN.FORM.ANNULMENT_OF_ADOPTION' | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="annulmentOfAdoption"
          autocomplete="off"
        />
      </mat-form-field>
    </field-holder>
    
  </form-group>
  <form-footer>
    <button
      mat-stroked-button
      color="primary"
      class="modal-footer__button modal-footer__button--secondary"
      (click)="closeDialog()"
      type="button"
    >
      {{ 'ESTABLISHED_FOSTER_CARE.TERMINATION.CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="modal-footer__button modal-footer__button--primary"
      type="submit"
    >
      {{ 'ESTABLISHED_FOSTER_CARE.TERMINATION.SAVE' | translate }}
    </button>
  </form-footer>
</form>
