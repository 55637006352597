/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */


export class SearchSocialCase {

	public minDate: any;
	public maxDate: any;
	public text: string[];


	/*constructor(minDate: any, maxDate: any, text: string[]) {
		this.minDate = minDate;
		this.maxDate = maxDate;
		this.text = text;
	}*/
	constructor() {
		this.minDate = '';
		this.maxDate = '';
		this.text = [];
	}

}