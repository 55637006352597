import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { StatisticsService } from 'src/services/statistics.service';
import { CodebookService } from '../../../services/codebook.service';
import { Observable } from 'rxjs';
import { UserService } from 'src/services/user.service';
import { map, startWith } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';

enum StatusEnum {
   ONLY_CLOSED = 'true',
   CLOSED_AND_FINISHED = 'false',
}
enum AutocompleteFilterEnum {
   CENTERS = 'centers',
}
interface SelectOptions {
   value: any;
   viewValue: string;
}

@Component({
   selector: 'app-jnp-stats',
   templateUrl: './jnp-stats.component.html',
   styleUrls: ['./jnp-stats.component.scss'],
})
export class JnpStatsComponent implements OnInit {
   classificationsOptions = [];
   jnpStatsForm: FormGroup;
   csrId: any;
   statusOptions: SelectOptions[] = [];
   csr: any;
   fileTypes = ['Csv', 'Txt'];
   centers = [];
   filteredAllCenters: Observable<any>;
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled: boolean;
   isUserDrugostepeni = false;
   maxDate: Date = new Date(2999, 11, 31);
   @ViewChild('allSelectedRights') private allSelectedRights: MatOption;

   constructor(
      private formBuilder: FormBuilder,
      private statisticsService: StatisticsService,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private datePipe: DatePipe,
      private codebookService: CodebookService,
      private userService: UserService
   ) {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.jnpStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.jnpStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      this.resetForm();
      this.getCsrId();
      this.getStatusOptions();
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
   }
   ngOnInit(): void {
      if (!this.isMainCenterBelgrade && !this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebookById().subscribe(result => {
            this.csr = result;
         });
      }
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.jnpStatsForm);
   }
   resetForm() {
      this.jnpStatsForm = this.formBuilder.group({
         fromDate: ['', [Validators.required]],
         toDate: ['', [Validators.required]],
         onlyClosed: ['', [Validators.required]],
         fileType: ['', [Validators.required]],
         classificationsCodebook: ['', [Validators.required]],
         center: [''],
         dateOfSignifance: ['1'],
      });
      this.codebookService.getClassificationForJnpStats().subscribe(result => {
         this.classificationsOptions = result;
         this.jnpStatsForm.controls.classificationsCodebook.patchValue([...this.classificationsOptions.map(item => item.code), 0]);
      });
   }
   sendRequest() {
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         this.csr = this.jnpStatsForm.controls.center.value;
         this.csrId = this.csr.id;
      }

      const filename = 'JNP_' + this.transliterate(this.csr.city) + '_' + this.datePipe.transform(new Date(), 'dd.MM.yyyy._hh:mm:ss');

      const request = { ...this.jnpStatsForm.value, csrId: this.csrId };

      if (this.jnpStatsForm.value.fileType === 'Csv') {
         this.statisticsService.getJnpStatistics(request, filename);
      }
      if (this.jnpStatsForm.value.fileType === 'Txt') {
         this.statisticsService.getJnpStatisticsTxt(request, filename);
      }
   }

   private getCsrId() {
      const loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.csrId = loggedIn?.csrId;
   }

   private transliterate(text: any) {
      const mapping = {
         А: 'A',
         Б: 'B',
         В: 'V',
         Г: 'G',
         Д: 'D',
         Ђ: 'Dj',
         Е: 'E',
         Ж: 'Z',
         З: 'Z',
         И: 'I',
         Ј: 'J',
         К: 'K',
         Л: 'L',
         Љ: 'Lj',
         М: 'M',
         Н: 'N',
         Њ: 'Nj',
         О: 'O',
         П: 'P',
         Р: 'R',
         С: 'S',
         Т: 'T',
         Ћ: 'C',
         У: 'U',
         Ф: 'F',
         Х: 'H',
         Ц: 'C',
         Ч: 'C',
         Џ: 'Dz',
         Ш: 'S',
         а: 'a',
         б: 'b',
         в: 'v',
         г: 'g',
         д: 'd',
         ђ: 'dj',
         е: 'e',
         ж: 'z',
         з: 'z',
         и: 'i',
         ј: 'j',
         к: 'k',
         л: 'l',
         љ: 'lj',
         м: 'm',
         н: 'n',
         њ: 'nj',
         о: 'o',
         п: 'p',
         р: 'r',
         с: 's',
         т: 't',
         ћ: 'ć',
         у: 'u',
         ф: 'f',
         х: 'h',
         ц: 'c',
         ч: 'c',
         џ: 'dz',
         ш: 's',
         ' ': '_',
      };

      let result = '';
      for (let i = 0; i < text.length; i++) {
         const char = text[i];
         const mapped = mapping[char];
         result += mapped || char;
      }
      return result;
   }

   private getStatusOptions() {
      this.translate.get('STATISTICS.JNP_STATS.CLOSED_AND_FINISHED').subscribe((res: string) => {
         this.statusOptions.push({ value: StatusEnum.CLOSED_AND_FINISHED, viewValue: res });
      });
      this.translate.get('STATISTICS.JNP_STATS.ONLY_CLOSED').subscribe((res: string) => {
         this.statusOptions.push({ value: StatusEnum.ONLY_CLOSED, viewValue: res });
      });
   }
   toggleAllSelectionRights() {
      if (this.allSelectedRights.selected) {
         this.jnpStatsForm.controls.classificationsCodebook.patchValue([...this.classificationsOptions.map(item => item.code), 0]);
      } else {
         this.jnpStatsForm.controls.classificationsCodebook.patchValue([]);
      }
   }
   tosslePerOneRight() {
      if (this.allSelectedRights.selected) {
         this.allSelectedRights.deselect();
         return false;
      }
      if (this.jnpStatsForm.controls.classificationsCodebook.value.length === this.classificationsOptions.length) {
         this.allSelectedRights.select();
      }
   }

   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.CENTERS:
            return sortByFirstLetter(
               this.centers.filter(option => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         default:
            break;
      }
   }

   onOptionSelected(event: any) {
      if (this.isUserDrugostepeni || this.isMainCenterBelgrade) {
         this.csr = event.option.value; // selected center
      }
   }
}
