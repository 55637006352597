/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "field-holder",
  templateUrl: "./field-holder.component.html",
  styleUrls: ["./field-holder.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FieldHolderComponent implements OnInit {
  constructor() {}
  @Input() kind:
    | "default"
    | "1-col-with-action"
    | "2-cols"
    | "2-cols-1-2"
    | "2-cols-with-action"
    | "3-cols"
    | "3-cols-1-1-2"
    | "3-cols-2-1-1"
    | "3-cols-1-1-2"
    | "4-cols" = "default";

  ngOnInit(): void {}
}
