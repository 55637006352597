/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DataSharingService } from 'src/services/data-sharing.service';
import { environment } from 'src/environments/environment';
import { documentSigningNotification } from 'src/models/documentsigningnotification';
import { Form5Component } from '../form5/form5.component';

@Component({
    selector: 'measures-and-services',
    templateUrl: './measures-and-services.component.html',
    styleUrls: ['./measures-and-services.component.scss'],
    providers: [DatePipe],
})
export class MeasuresAndServicesComponent implements OnInit {
    @Input() data: any = {}; // TODO: Type me
    @Input() editEnabled: boolean;
    @Input() isCaseClosed: boolean;
    @Input() caseId: any;
    @Input() subjectId: any;
    @Output() handleSubmitData = new EventEmitter();
    @Input() waitingForApproval: boolean;
    @Input() documentSigned: boolean;
    @Input() noRegistrationId: boolean;
    @ViewChild(Form5Component) form5Component: Form5Component;
    stepStatus: 'INITIAL' | 'STARTED' | 'FINISHED' = 'INITIAL';
    isEditMode = false;
    newMeasuresAndServicesStep: FormGroup;
    submittedData: any; // TODO: Type me
    currentDate = new Date();
    deadlineDate: string;
    form5Data: any;
    caseName: any;
    subject: any;
    acceptCaseMissingPermitionsTooltip = '';

    constructor(
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private dataSharingService: DataSharingService) {
        this.translate.get('GLOBAL.TOOLTIPS.ACCEPT_CASE_MISSING_PERMITIONS').subscribe((res: string) => {
            this.acceptCaseMissingPermitionsTooltip = res;
        });
        this.dataSharingService.documentSigned.subscribe((data: documentSigningNotification) => {
            if (this.caseId !== undefined && data !== null) {
                if (data.caseId === this.caseId && data.document === environment.vsDocumentPU) {
                    this.documentSigned = true;
                    this.cdr.detectChanges();
                }
            }
        });
    }

    ngOnInit(): void {
        if (this.data.length !== 0) {
            this.documentSigned = this.data[this.data.length - 1].approved;
            if (this.data[this.data.length - 1].approvedAndFinished) {
                this.stepStatus = 'INITIAL';
            } else {
                if (this.data[this.data.length - 1].finished) {
                    this.stepStatus = 'FINISHED';
                } else {
                    this.stepStatus = 'STARTED';
                }
            }
            // if (this.data.approved) {
            //   this.stepStatus = 'FINISHED';
            // }
        } else {
            this.stepStatus = 'INITIAL';
        }
        this.deadlineDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');


        this.newMeasuresAndServicesStep = this.formBuilder.group({
            plan: ['', []],
            planTitle: ['', []],
            planDescription: ['', []],
            planDeadline: [this.deadlineDate, []],
        });

        this.setConditionalTitleValues();
    }

    setConditionalTitleValues() {
        const planTitle = this.newMeasuresAndServicesStep.get('planTitle');

        this.newMeasuresAndServicesStep
            .get('plan')
            .valueChanges.subscribe((item) => {
                planTitle.updateValueAndValidity();
            });
    }

    handleInitStep() {
        this.isEditMode = true;
    }

    handleSaveChanges(isFinished: boolean) {
        if (isFinished) {
            this.stepStatus = 'FINISHED';
        } else {
            this.stepStatus = 'STARTED';
        }
        this.isEditMode = false;
        this.documentSigned = false;
    }

    handleSubmitStep() {
        this.isEditMode = false;
        this.stepStatus = 'INITIAL';
        this.submittedData = this.newMeasuresAndServicesStep.value;
        this.handleSubmitData.emit({
            step: 'STEP_05',
            data: this.newMeasuresAndServicesStep,
        });
    }

    handleUpdatePlan() {
        this.isEditMode = false;
    }

    toggleEdit() {
        this.isEditMode = !this.isEditMode;

        if (this.isEditMode === false) {
            this.newMeasuresAndServicesStep.reset();
        }
    }
}
