import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PnnCaseService {

  constructor(private httpClient: HttpClient, private app: AppService) { }

  addPnnCase(pnnCase: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.createPnnUrl, pnnCase,
      { headers }) as Observable<any>;
  }

  findPnnCaseById(socialCaseId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getDataForPnnFormUrl + '/' + socialCaseId,
      { headers }) as Observable<any>;
  }

  savePnnCase(pnnCase: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json' 
    });
    return this.httpClient.post(this.app.getUrl() + environment.savePnn, pnnCase,
      { headers }) as Observable<any>;
  }
}
