<mat-dialog-content>
    <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
        (click)="closeDialog()"><span class="material-icons">close</span>
    </button>
    <div class="container">
        <form flex [formGroup]="familyData" (ngSubmit)="addNewFamily()">
            <h2 *ngIf="isBrothersAndSisters"> {{ 'FOSTER_CHILD.FAMILY_DATA.TITLE_BROTHERS_AND_SISTERS' | translate }}
            </h2>
            <h2 *ngIf="isGuardian">{{ 'FOSTER_CHILD.FAMILY_DATA.TITLE_OTHERS' | translate }}</h2>

            <div class="button-add-holder">
                <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
                    <mat-icon>person_add</mat-icon>
                    {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
                </button>
            </div>
            <br>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label *ngIf=isGuardian>{{ 'FOSTER_CHILD.FAMILY_DATA.INFORMATION' | translate }}</mat-label>
                    <mat-label *ngIf=isBrothersAndSisters>
                        {{ 'FOSTER_CHILD.FAMILY_DATA.INFORMATION_BROTHER' | translate }}</mat-label>
                    <input matInput type="text" formControlName="information" autocomplete="off" required />
                </mat-form-field>
            </field-holder>
            <field-holder kind="2-cols">
                <mat-form-field appearance="outline" *ngIf=isGuardian>
                    <mat-label>{{ 'FOSTER_CHILD.FAMILY_DATA.RELATIONSHIP' | translate }}</mat-label>
                    <mat-select formControlName="relationshipCodebook" [compareWith]="compareObj">
                        <mat-option *ngFor="let status of relationshipOptions" [value]="status">
                            {{ status.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
                    class="form-footer__button form-footer__button--secondary">
                    {{ 'FOSTER_CHILD.FAMILY_DATA.CANCEL' | translate }}
                </button>
                <button mat-flat-button color="primary" type="submit" [disabled]="isEmptyFields()"
                    class="form-footer__button form-footer__button--primary">
                    {{ 'FOSTER_CHILD.FAMILY_DATA.ADD' | translate }}
                </button>
            </form-footer>
        </form>
    </div>
</mat-dialog-content>