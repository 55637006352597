<form [formGroup]="costCarriersStep" class="form">
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NEW_FOSTER_CHILD.FORM.PARENTS' | translate }}
      </mat-label>
      <textarea
        matInput
        type="text"
        formControlName="parents"
        autocomplete="off"
      ></textarea>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NEW_FOSTER_CHILD.FORM.OTHER_RELATIVES' | translate }}
      </mat-label>
      <textarea
        matInput
        type="text"
        formControlName="otherRelatives"
        autocomplete="off"
      ></textarea>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NEW_FOSTER_CHILD.FORM.OWN_INCOME' | translate }}
      </mat-label>
      <textarea
        matInput
        type="text"
        formControlName="ownIncome"
        autocomplete="off"
      ></textarea>
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button
      matStepperPrevious
      mat-stroked-button
      color="primary"
      class="form-footer__button form-footer__button--secondary"
    >
      {{ 'NEW_FOSTER_CHILD.PREVIOUS_STEP' | translate }}
    </button>
    <button
      mat-stroked-button
      matStepperNext
      color="primary"
      [disabled]="!costCarriersStep.valid"
      class="form-footer__button"
      (click)="updateCostCarriersStep()"
    >
      {{ 'NEW_FOSTER_CHILD.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>
