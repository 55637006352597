/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { User } from 'src/models/user.model';
import { AuthenticationService } from 'src/services/authentication.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalSizeEnum } from 'src/types';
import { AddJmbgComponent } from './add-jmbg/add-jmbg.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  @Input() showProfileLink: boolean = true;
  @Input() showLanguageSwitcher: boolean = true;
  @Output() toggleDropdownHandler = new EventEmitter();
  currentLanguage = new FormControl(this.translate.currentLang);
  languages: { id: string; value: string }[] | { id: string; value: any }[];
  primaryRole;
  roles = [];
  latinica: string;
  cirilica: string;
  loggedIn: User;
  currentCenter: string;
  roleChooserNeeded = false;

  constructor(
    public translate: TranslateService,
    private authentication: AuthenticationService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrImplService
  ) {
    translate.addLangs(['sr-Latn', 'sr-Cyrl']);
    this.languages = [
      {
        id: 'sr-Latn',
        value: 'Latinica',
      },
      {
        id: 'sr-Cyrl',
        value: 'Ћирилица',
      },
    ];

    this.translate.onLangChange.subscribe((result) => {
      this.translate.get('LATINICA').subscribe((res: string) => {
        this.latinica = res;
      });
      this.translate.get('CIRILICA').subscribe((res: string) => {
        this.cirilica = res;
      });
      this.languages = [
        {
          id: 'sr-Latn',
          value: this.latinica,
        },
        {
          id: 'sr-Cyrl',
          value: this.cirilica,
        },
      ];
      translate.use(result.lang);
    });

    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    if (this.loggedIn !== null) {
      this.loggedIn.roles = this.userService.getUserRoleTitle(JSON.parse(this.localStorageService.get('loggedUser')).role);
    }

    if (this.localStorageService.get('primaryRole') !== null) {
      this.roleChooserNeeded = true;
      this.roles = this.userService.getUserRoleTitle(JSON.parse(this.localStorageService.get('loggedUser')).role);
      this.primaryRole = new FormControl(this.localStorageService.get('primaryRole'));
    }
    this.currentCenter = this.loggedIn.city;
  }

  onLanguageChange(event) {
    this.translate.use(event.value);
  }

  onRoleChange(event) {
    this.localStorageService.set('primaryRole', event.value);

    this.router.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
  }

  logout() {
    this.authentication.logout();
  }

  toggleDropdown(): void {
    if (this.toggleDropdownHandler) {
      this.toggleDropdownHandler.next();
    }
  }

  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      disableClose: true,
      panelClass:'overlay-panel',
      width: ModalSizeEnum.DEFAULT,
      data: this.loggedIn.id,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'SUCCESS') {
        this.toastr.success('SNACKBAR.PASSWORD_UPDATED');
      }
    });
  }

  addJmbg() {
    const dialogRef = this.dialog.open(AddJmbgComponent, {
      disableClose: true,
      panelClass:'overlay-panel',
      width: ModalSizeEnum.DEFAULT,
      data: this.loggedIn.id,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'SUCCESS') {
        this.loggedIn.jmbg = result.jmbg;
      }
    });
  }

  ngOnInit() { }
}
