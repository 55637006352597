import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import printJS from 'print-js';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class ViolenceService {

   constructor(private httpClient: HttpClient, private app: AppService) { }

   previewViolenceForm(violenceForm: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json'
      });

      this.httpClient
         .put(this.app.getUrl() + environment.getViolenceFormReportUrl, violenceForm, { responseType: 'blob', headers })
         .subscribe(data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         });
   }

   saveViolenceForm(violenceForm: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json'
      });
      return this.httpClient.put(this.app.getUrl() + environment.getViolenceFormSaveUrl,
         violenceForm, { headers }) as Observable<any>;
   }

   findViolenceByCaseId(caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json'
      });
      return this.httpClient.get(this.app.getUrl() + environment.findViolenceByCaseId + caseId,
         { headers }) as Observable<any>;
   }


}
