/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'no-result-item',
  templateUrl: './no-result-item.component.html',
  styleUrls: ['./no-result-item.component.scss']
})
export class NoResultItemComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
