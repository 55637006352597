/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { AppService } from './app.service';
import { Observable } from 'rxjs';
import * as printJS from 'print-js';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { ToastrImplService } from './toastr.service';

@Injectable({
   providedIn: 'root',
})
export class DpnRequestService {
   constructor(private httpClient: HttpClient, private app: AppService, private toastr: ToastrImplService) {}

   createDpnRequest(dpnRequest: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.createDpnRequest, dpnRequest, { responseType: 'text', headers }) as Observable<any>;
   }

   dpnPreview(dpnRequest: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.previewDpnRequestUrl, dpnRequest, { responseType: 'blob', headers }).subscribe(
         data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         },
         error => {
            manageError(this.toastr, ErrorType.PREVIEW_DOC);
         }
      );
   }

   findDpnRequestById(socialCaseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getDataForDpnFormUrl + '/' + socialCaseId, { headers }) as Observable<any>;
   }

   saveDpnRequest(dpnRequest: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.saveDpnRequest, dpnRequest, { headers }) as Observable<any>;
   }

   base64toBlob(base64Data, contentType) {
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
         const begin = sliceIndex * sliceSize;
         const end = Math.min(begin + sliceSize, bytesLength);
         const bytes = new Array(end - begin);
         for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
         }
         byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
   }

   checkActiveRight(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.checkActiveRightDpn + caseId) as Observable<any>;
   }
}
