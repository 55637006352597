/**
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CaseService } from 'src/services/case.service';
import { SubjectsService } from 'src/services/subjects.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialCaseData } from '../assign-case-dialog/assign-case-dialog.component';
import { AuthenticationService } from 'src/services/authentication.service';
import { LocalStorageService } from '../local-storage.service';
import { UserService } from 'src/services/user.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { startWith, map } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from '../utils/autoSelect';


enum AutocompleteFilterEnum {
  USER = 'user',
}

@Component({
  selector: 'app-forward-case-dialog',
  templateUrl: './forward-case-dialog.component.html',
  styleUrls: ['./forward-case-dialog.component.scss']
})
export class ForwardCaseDialogComponent implements OnInit {

  local_data: any;
  users: any = [];
  filteredUsers: Observable<any[]>;
  isLawyer = false;
  isWorkerAndLawyer = false;
  roles = [{ role: environment.strucni_radnik, roleName: 'Стручни радник' }, { role: environment.pravnik, roleName: 'Правник' }];
  loggedIn;

  forwardCaseForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              public caseService: CaseService,
              public subjectsService: SubjectsService,
              public dialogRef: MatDialogRef<ForwardCaseDialogComponent>,
              public authenticationService: AuthenticationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: SocialCaseData,
              private localStorageService: LocalStorageService,
              public userService: UserService) {

    this.local_data = { ...data };
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.userService.findAllByRole(this.loggedIn.csrId, environment.pravnik).subscribe((res) => {
      this.users = res;
      this.filteredUsers = this.forwardCaseForm.controls.user.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name),
          map(title => title ? this._filter(title, AutocompleteFilterEnum.USER) : this.users.slice())
        );
    });
  }

  ngOnInit(): void {

    this.forwardCaseForm = this.formBuilder.group({
      user: ['', [Validators.required]],
    });
  }

  forwardCase(submittedForm) {
    this.local_data.user = submittedForm.get('user').value.userId;
    if (this.userService.isUserWorker()) {
      this.local_data.role = environment.pravnik;
    } else {
      this.local_data.role = environment.strucni_radnik;
    }
    this.dialogRef.close({ event: 'SUCCESS', data: this.local_data });

  }
  autoSelectItem(){
    autoSelect(this.users, 'user', 'name', this.forwardCaseForm );
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name : '';
  }

  /**
   * _filter
   * @param value input value
   * @param what which filter should be applied (check AutocompleteFilterEnum)
   */
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();

    switch (what) {
      case AutocompleteFilterEnum.USER:
        return sortByFirstLetter(this.users.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        ), filterValue, 'name');
      default:
        break;

    }
  }

}
