<li class="d-list-item" [ngClass]="{
  'd-list-item': true,
  'd-list-item--mini': fontSize === 'mini'
}">
  <em class="d-list-item__key">{{ key }}</em>
  <span class="d-list-item__value" [ngStyle]="{ 'margin-left': valueOffset }"
    ><ng-content></ng-content
  ></span>

</li>
