/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { logging } from 'protractor';


export class Institution {
    public kind: string;
    public name: string;
    public address: string;
    public phone: string;
    public contactPerson: string;
    public participation: string;
    public ordinal: number;

    constructor(kind: string, name: string, address: string, phone: string, contactPerson: string, participation: string, ordinal: number) {
        this.kind = kind;
        this.name = name;
        this.address = address;
        this.phone = phone;
        this.contactPerson = contactPerson;
        this.participation = participation;
        this.ordinal = ordinal;
    }
}
