<form [formGroup]="guardianStep" class="form">
  <form-group title="{{ 'NEW_PROTEGE.GUARDIAN.GUARDIAN_TITLE' | translate }}">
    <section formGroupName="guardian">
      <div class="button-add-holder">
        <button mat-flat-button color="primary" type="button" (click)="importSubjectData('guardian')">
          <mat-icon>person_add</mat-icon>
          {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
        </button>
      </div>
      <br>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_PROTEGE.FORM.FIRST_NAME' | translate }}</mat-label>
          <input matInput type="text" formControlName="firstName" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_PROTEGE.FORM.LAST_NAME' | translate }}</mat-label>
          <input matInput type="text" formControlName="lastName" autocomplete="off" />
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_PROTEGE.FORM.JMBG' | translate }}</mat-label>
          <input matInput type="text" formControlName="jmbg" maxlength="13" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
      <mat-label>{{ 'NEW_PROTEGE.FORM.CITIZENSHIP' | translate }}</mat-label>
      <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenship" />
      <mat-autocomplete #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredCitizenshipOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_PROTEGE.FORM.RESIDENCE_GUARDIAN' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="residence" autocomplete="off" />
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_PROTEGE.FORM.OCCUPATION' | translate }}</mat-label>
          <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupation"
            (focusout)="checkOccupation('guardian')" />
          <mat-autocomplete #autoOccupation="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredOccupationOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="guardianStep.get('guardian').get('occupation').hasError('notValid')">
            {{ 'COLLECTIONS.CODEBOOK_ERROR'  | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_PROTEGE.FORM.RELATIONSHIP' | translate
          }}</mat-label>
          <mat-select formControlName="relationship" (selectionChange)="checkRelations()">
            <mat-option *ngFor="let relationship of relationshipOptions" [value]="relationship">
              {{ relationship.title }}
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="relationshipError">
            {{'FAMILY_MEMBER_CARD.CHECK_DATE_OF_BIRTH' |translate}}
          </mat-hint>
        </mat-form-field>
         <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_PROTEGE.FORM.PHONE' | translate }}</mat-label>
          <input matInput type="text" formControlName="phone" autocomplete="off" />
        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-group title="{{ 'NEW_PROTEGE.GUARDIAN.COLLECTIVE_GUARDIAN' | translate }}">
    <section formGroupName="collectiveGuardian">
      <div class="button-add-holder">
        <button mat-flat-button color="primary" type="button" (click)="importSubjectData('collectiveGuardian')">
          <mat-icon>person_add</mat-icon>
          {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
        </button>
      </div>
      <br>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_PROTEGE.FORM.FIRST_NAME' | translate }}</mat-label>
          <input matInput type="text" formControlName="firstName" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_PROTEGE.FORM.LAST_NAME' | translate }}</mat-label>
          <input matInput type="text" formControlName="lastName" autocomplete="off" />
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_PROTEGE.FORM.TYPE_OF_JOB' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="typeOfJob" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_PROTEGE.FORM.SOCIAL_PROTECTION_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="socialProtectionName" autocomplete="off" />
        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-group title="{{ 'NEW_PROTEGE.GUARDIAN.DIRECT_GUARDIAN' | translate }}" isLast="true">
    <section formGroupName="directGuardian">
      <div class="button-add-holder">
        <button mat-flat-button color="primary" type="button" (click)="importSubjectData('directGuardian')">
          <mat-icon>person_add</mat-icon>
          {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
        </button>
      </div>
      <br>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_PROTEGE.FORM.FIRST_NAME_GUARDIANSHIP_AUTHORITY_EXPERT'
              | translate
          }}</mat-label>
          <input matInput type="text" formControlName="firstName" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_PROTEGE.FORM.LAST_NAME_GUARDIANSHIP_AUTHORITY_EXPERT'
              | translate
          }}</mat-label>
          <input matInput type="text" formControlName="lastName" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'NEW_PROTEGE.FORM.OCCUPATION' | translate }}</mat-label>
          <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupationDirect"
            (focusout)="checkOccupation('directGuardian')" />
          <mat-autocomplete #autoOccupationDirect="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredOccupationDirectOptions | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="guardianStep.get('directGuardian').get('occupation').hasError('notValid')">
            {{ 'COLLECTIONS.CODEBOOK_ERROR'  | translate }}
          </mat-error>

        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_PROTEGE.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!guardianStep.valid"
      class="form-footer__button" (click)="updateGuardianStep()">
      {{ 'NEW_PROTEGE.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>