import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbusedPersonService } from 'src/services/abused-person.service';
@Component({
  selector: 'app-domestic-violence-victim-details',
  templateUrl: './domestic-violence-victim-details.component.html',
  styleUrls: ['./domestic-violence-victim-details.component.scss']
})
export class DomesticViolenceVictimDetailsComponent implements OnInit {
  abusedPersonId: any;
  abusedPerson: any;
  year: any;
  constructor(
    private route: ActivatedRoute,
    private abusedPersonService: AbusedPersonService,
    ) {
    this.route.params.subscribe((params) => {
      this.abusedPersonId = params.id;
      this.year = params.year;
    });
    this.getAbusedPerson();
  }

  ngOnInit(): void {
  }

  getAbusedPerson() {
    this.abusedPersonService.findByIdAndCsrId(this.abusedPersonId, this.year).subscribe((res) => {
      res.dateOfBirth =
      res.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      res.judgmentDate =
      res.judgmentDate?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      res.entryForceDate =
      res.entryForceDate?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
	  res.measureDurationFrom =
	  res.measureDurationFrom?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
	  res.measureDurationTo =
	  res.measureDurationTo?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      res.terminationDate =
      res.terminationDate?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');

      res.violenceKindCodebook.sort((a, b) =>  a.id - b.id);
      res.familyMemberCommiter.sort((a, b) =>  a.key - b.key);

      this.abusedPerson = res;
    });
  }
}
