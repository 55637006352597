<container kind="default">
    <page-intro-header back="/adopted-children" title="{{ 'NEW_ADOPTED_CHILDREN.TITLE_T' | translate }}">
    </page-intro-header>
</container>

<container kind="default">
    <mat-card class="card">
        <definition-list>
            <definition-item *ngIf="adoptChild?.ordinalNumber"
                key="{{ 'PROTEGE_DETAILS.R_BR' | translate }}">
                {{ adoptChild.ordinalNumber }}</definition-item>
            <definition-item *ngIf="adoptChild?.subjectId" key="{{ 'PROTEGE_DETAILS.ID' | translate }}">
                {{ adoptChild.subjectId }}</definition-item>

            <h2 *ngIf="adoptChild?.beforeAdoption">{{
          'NEW_ADOPTED_CHILDREN.ADOPTEE.BEFORE_ADOPTION_TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="adoptChild?.beforeAdoptionFirstName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate }}">
                {{ adoptChild.beforeAdoptionFirstName }}</definition-item>

            <definition-item *ngIf="adoptChild?.beforeAdoptionLastName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate }}">
                {{ adoptChild.beforeAdoptionLastName }}</definition-item>

            <definition-item *ngIf="adoptChild?.beforeAdoptionJmbg"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ adoptChild.beforeAdoptionJmbg }}</definition-item>

            <definition-item *ngIf="adoptChild?.beforeAdoptionDateOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate }}">
                {{ adoptChild.beforeAdoptionDateOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.beforeAdoptionPlaceOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ adoptChild.beforeAdoptionPlaceOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.beforeAdoptionCitizenship"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate }}">
                {{ adoptChild.beforeAdoptionCitizenship.title }}</definition-item>
            <definition-item *ngIf="adoptChild?.beforeAdoptionPermanentResidence"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate }}">
                {{ adoptChild.beforeAdoptionPermanentResidence }}</definition-item>

            <h2 *ngIf="adoptChild?.afterAdoption">{{
          'NEW_ADOPTED_CHILDREN.ADOPTEE.AFTER_ADOPTION_TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="adoptChild?.afterAdoption?.firstName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate }}">
                {{ adoptChild.afterAdoption.firstName }}</definition-item>

            <definition-item *ngIf="adoptChild?.afterAdoption?.lastName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate }}">
                {{ adoptChild.afterAdoption.lastName }}</definition-item>

            <definition-item *ngIf="adoptChild?.afterAdoption?.jmbg"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ adoptChild.afterAdoption.jmbg }}</definition-item>

            <definition-item *ngIf="adoptChild?.afterAdoption?.dateOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate }}">
                {{ adoptChild.afterAdoption.dateOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.afterAdoption?.placeOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ adoptChild.afterAdoption.placeOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.afterAdoption?.citizenship"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate }}">
                {{ adoptChild.afterAdoption.citizenship.title }}</definition-item>

            <definition-item *ngIf="adoptChild?.afterAdoption?.residence"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate }}">
                {{ adoptChild.afterAdoption.residence }}</definition-item>

            <h2 *ngIf="adoptChild?.mother">{{
          'NEW_ADOPTED_CHILDREN.BIOLOGICAL_PARENTS.MOTHER_TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="adoptChild?.mother?.firstName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate }}">
                {{ adoptChild.mother.firstName }}</definition-item>

            <definition-item *ngIf="adoptChild?.mother?.lastName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate }}">
                {{ adoptChild.mother.lastName }}</definition-item>

            <definition-item *ngIf="adoptChild?.mother?.jmbg" key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ adoptChild.mother?.jmbg }}</definition-item>

            <definition-item *ngIf="adoptChild?.mother?.dateOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate }}">
                {{ adoptChild.mother.dateOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.mother?.placeOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ adoptChild.mother.placeOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.mother?.citizenship"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate }}">
                {{ adoptChild.mother.citizenship.title }}</definition-item>

            <definition-item *ngIf="adoptChild?.mother?.residence"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate }}">
                {{ adoptChild.mother.residence }}</definition-item>


            <h2 *ngIf="adoptChild?.father">{{
          'NEW_ADOPTED_CHILDREN.BIOLOGICAL_PARENTS.FATHER_TITLE' | translate
        }}
            </h2>
            <definition-item *ngIf="adoptChild?.father?.firstName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate }}">
                {{ adoptChild.father.firstName }}</definition-item>

            <definition-item *ngIf="adoptChild?.father?.lastName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate }}">
                {{ adoptChild.father.lastName }}</definition-item>

            <definition-item *ngIf="adoptChild?.father?.jmbg" key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ adoptChild.father?.jmbg }}</definition-item>

            <definition-item *ngIf="adoptChild?.father?.dateOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate }}">
                {{ adoptChild.father.dateOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.father?.placeOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ adoptChild.father.placeOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.father?.citizenship"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate }}">
                {{ adoptChild.father.citizenship.title }}</definition-item>

            <definition-item *ngIf="adoptChild?.father?.residence"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate }}">
                {{ adoptChild.father.residence }}</definition-item>

            <h2 *ngIf="adoptChild?.adoptiveMother">{{
          'NEW_ADOPTED_CHILDREN.ADOPTERS.ADOPTIVE_MOTHER_TITLE' | translate
        }}
            </h2>
            <definition-item *ngIf="adoptChild?.adoptiveMother?.firstName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate }}">
                {{ adoptChild.adoptiveMother.firstName }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveMother?.lastName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate }}">
                {{ adoptChild.adoptiveMother.lastName }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveMother?.jmbg"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ adoptChild.adoptiveMother?.jmbg }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveMother?.dateOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate }}">
                {{ adoptChild.adoptiveMother.dateOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveMother?.placeOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ adoptChild.adoptiveMother.placeOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveMother?.citizenship"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate }}">
                {{ adoptChild.adoptiveMother.citizenship.title }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveMother?.residence"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate }}">
                {{ adoptChild.adoptiveMother.residence }}</definition-item>

            <h2 *ngIf="adoptChild?.father">{{
          'NEW_ADOPTED_CHILDREN.ADOPTERS.ADOPTIVE_FATHER_TITLE' | translate
        }}
            </h2>
            <definition-item *ngIf="adoptChild?.adoptiveFather?.firstName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate }}">
                {{ adoptChild.adoptiveFather?.firstName }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveFather?.lastName"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate }}">
                {{ adoptChild.adoptiveFather.lastName }}</definition-item>

            <definition-item *ngIf="adoptChild?.father?.jmbg" key="{{ 'NEW_ADOPTED_CHILDREN.FORM.JMBG' | translate }}">
                {{ adoptChild.adoptiveFather.jmbg }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveFather?.dateOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate }}">
                {{ adoptChild.adoptiveFather.dateOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveFather?.placeOfBirth"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ adoptChild.adoptiveFather.placeOfBirth }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveFather?.citizenship"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate }}">
                {{ adoptChild.adoptiveFather.citizenship.title }}</definition-item>

            <definition-item *ngIf="adoptChild?.adoptiveFather?.residence"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate }}">
                {{ adoptChild.adoptiveFather.residence }}</definition-item>

            <h2 *ngIf="adoptChild?.adoptersCodebook">{{
          'NEW_ADOPTED_CHILDREN.ADOPTERS.ADOPTS_TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="adoptChild?.adoptersCodebook"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.RELATIONSHIP_RELATION' | translate }}">
                {{ adoptChild.adoptersCodebook.title }}</definition-item>

            <h2 *ngIf="adoptChild">{{
          'NEW_ADOPTED_CHILDREN.OTHER_INFORMATION.TITLE' | translate
        }}
            </h2>

            <definition-item *ngIf="adoptChild?.typeOfRelationship"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.TYPE_OF_RELATIONSHIP' | translate }}">
                {{ adoptChild.typeOfRelationship }}</definition-item>

            <definition-item *ngIf="adoptChild?.decisionNumber"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DECISION_NUMBER' | translate }}">
                {{ adoptChild.decisionNumber }}</definition-item>

            <definition-item *ngIf="adoptChild?.decisionDate"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DECISION_DATE' | translate }}">
                {{ adoptChild.decisionDate }}</definition-item>

            <definition-item *ngIf="adoptChild?.decisionNumberNewBirthRegistration"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DECISION_NUMBER_NEW_BIRTH_REGISTRATION' | translate }}">
                {{ adoptChild.decisionNumberNewBirthRegistration }}</definition-item>

            <definition-item *ngIf="adoptChild?.decisionDateNewBirthRegistration"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.DECISION_DATE_NEW_BIRTH_REGISTRATION' | translate }}">
                {{ adoptChild.decisionDateNewBirthRegistration  }}</definition-item>

            <definition-item *ngIf="adoptChild?.annulmentOfAdoption"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.ANNULMENT_OF_ADOPTION_T' | translate }}">
                {{ adoptChild.annulmentOfAdoption}}</definition-item>

            <definition-item *ngIf="adoptChild?.holderOfMaintenanceObligation"
                key="{{ 'NEW_ADOPTED_CHILDREN.FORM.HOLDER_OF_MAINTENANCE_OBLIGATION' | translate }}">
                {{ adoptChild.holderOfMaintenanceObligation}}</definition-item>

            <definition-item *ngIf="adoptChild?.note" key="{{ 'NEW_ADOPTED_CHILDREN.FORM.NOTE' | translate }}">
                {{ adoptChild.note}}</definition-item>

        </definition-list>

    </mat-card>
</container>