<div [ngClass]="{
    'collapsible-worker__wrapper': true,
    'collapsible-worker__wrapper--expanded': groupExpanded
  }">
  <header class="collapsible-worker__header" (click)="toggle()">
    <h3 class="collapsible-worker__title">
      <span *ngIf="title!=''">
        {{ title }}
      </span>
      <span *ngIf="title==''">
       {{ 'SCHEDULE_CASES.WORKERS.NONE_WORKERS_GROUPS' | translate }}
      </span>
      
    </h3>
    <mat-icon
      [ngClass]="{ 'collapsible-worker__chevron-icon': true, 'collapsible-worker__chevron-icon--expanded': groupExpanded }">
      chevron_right</mat-icon>
  </header>
  <div class="collapsible-worker__content">
    <ng-content></ng-content>
  </div>
</div>
