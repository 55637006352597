<form class="form" [formGroup]="unarchiveSubjectForm"
  (ngSubmit)="unarchiveSubject(unarchiveSubjectForm); submitButton.disabled = true">
  <form-group title="{{ 'UNARCHIVE_SUBJECT.TITLE' | translate }}" isLast="true">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'UNARCHIVE_SUBJECT.UNARCHIVE_DATE' | translate
        }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dateOfUnarchiving" required autocomplete="off" [min]="minFromDate" [max]="maxFromDate"/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="
            unarchiveSubjectForm.get('dateOfUnarchiving').hasError('required')
          ">
          {{ 'UNARCHIVE_SUBJECT.UNARCHIVE_DATE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'UNARCHIVE_SUBJECT.UNARCHIVE_REASON' | translate
        }}</mat-label>
        <textarea matInput type="text" formControlName="unarchiveReason" required autocomplete="off"></textarea>
        <mat-error *ngIf="
            unarchiveSubjectForm.get('unarchiveReason').hasError('required')
          ">
          {{ 'UNARCHIVE_SUBJECT.UNARCHIVE_REASON_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ 'UNARCHIVE_SUBJECT.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
      [disabled]="!unarchiveSubjectForm.valid" #submitButton>
      {{ 'UNARCHIVE_SUBJECT.UNARCHIVE' | translate }}
    </button>
  </form-footer>
</form>