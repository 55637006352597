<container>
  <page-intro-header title="{{ 'NEW_DEPENDENT.TITLE' | translate }}" back="/dependents">
  </page-intro-header>
  <mat-card class="card new-dependent">
    <form [formGroup]="newRecord" class="form">

      <div class="button-add-holder">
        <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
          <mat-icon>person_add</mat-icon>
          {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
        </button>
      </div>
      <br>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_DEPENDENT.FORM.FIRST_NAME_MAIN' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="firstName" required autocomplete="off" />
          <mat-error *ngIf="newRecord.get('firstName').hasError('required')">
            {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_DEPENDENT.FORM.LAST_NAME_MAIN' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="lastName" required autocomplete="off" />
          <mat-error *ngIf="newRecord.get('lastName').hasError('required')">
            {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_DEPENDENT.FORM.ID' | translate
            }}</mat-label>
          <input matInput type="text" formControlName="subjectId" required autocomplete="off" />
          <mat-error *ngIf="newRecord.get('subjectId').hasError('required')">
            {{ 'NEW_DEPENDENT.FORM.INPUT_ERROR' | translate }}
          </mat-error>
		  <mat-error *ngIf="newRecord.get('subjectId').hasError('pattern')
            ">
            {{ 'NEW_FOSTER_PARENT.FORM.MONEY_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <form-subgroup-divider></form-subgroup-divider>
      <form-group title="{{ 'NEW_DEPENDENT.TABLE_ONE_TITLE' | translate }}">
        <div class="records__table-wrapper table-responsive-wrapper" *ngIf="table.tableOne.length > 0">
          <table mat-table [dataSource]="table.tableOne" #mytable class="records__table">
            <ng-container matColumnDef="courtName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.COURT_NAME' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.courtName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="courtNumber">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.COURT_NUMBER' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.courtNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="courtDate">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.COURT_DATE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.courtDate | date:"dd.MM.yyyy."}}
              </td>
            </ng-container>
            <ng-container matColumnDef="courtValidityDate">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.COURT_VALIDITY_DATE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.courtValidityDate | date:"dd.MM.yyyy."}}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateOfVerdictDelivery">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.DATE_OF_VERDICT_DELIVERY' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfVerdictDelivery | date:"dd.MM.yyyy."}}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateOfCommencment">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.DATE_OF_COMMENCMENT' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfCommencment | date:"dd.MM.yyyy." }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns.tableOne"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns.tableOne"
              (click)="onRowSelected('tableOne', row)"
              [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (mouseover)="row.hovered = true"
              (mouseout)="row.hovered = false"></tr>
          </table>
        </div>
        <div class="button-holder">
          <button mat-flat-button color="primary" type="button" (click)="addTableRecord('tableOne')"
            class="form-footer__button form-footer__button--secondary">
            <mat-icon>add</mat-icon>
            {{ 'NEW_DEPENDENT.ADD' | translate }}
          </button>
          <button mat-flat-button color="warn" (click)="removeTableRecord('tableOne')"
            [disabled]="!isTableRecordSelected.tableOne" type="button">
            <mat-icon>remove</mat-icon>
            {{ 'NEW_DEPENDENT.REMOVE' | translate }}
          </button>
        </div>
      </form-group>
      <form-group title="{{ 'NEW_DEPENDENT.TABLE_TWO_TITLE' | translate }}">
        <div class="records__table-wrapper table-responsive-wrapper" *ngIf="table.tableTwo.length > 0">
          <table mat-table [dataSource]="table.tableTwo" #mytable class="records__table">
            <ng-container matColumnDef="firstAndLastName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.FIRST_AND_LAST_NAME_MAIN' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.firstName + ' ' + element.lastName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateAndPlaceOfBirth">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.DATE_AND_PLACE_OF_BIRTH' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfBirth | date:"dd.MM.yyyy."}} {{ element.placeOfBirth }}
              </td>
            </ng-container>
            <ng-container matColumnDef="jmbg">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.JMBG' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.jmbg }}  
              </td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.ADDRESS' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.address }}
              </td>
            </ng-container>
            <ng-container matColumnDef="legalRelation">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.LEGAL_RELATION_TWO' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.legalRelation }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns.tableTwo"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns.tableTwo"
              (click)="onRowSelected('tableTwo', row)"
              [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (mouseover)="row.hovered = true"
              (mouseout)="row.hovered = false"></tr>
          </table>
        </div>
        <div class="button-holder">
          <button mat-flat-button color="primary" type="button" (click)="addTableRecord('tableTwo')"
            class="form-footer__button form-footer__button--secondary">
            <mat-icon>add</mat-icon>
            {{ 'NEW_DEPENDENT.ADD' | translate }}
          </button>
          <button mat-flat-button color="warn" (click)="removeTableRecord('tableTwo')"
            [disabled]="!isTableRecordSelected.tableTwo" type="button">
            <mat-icon>remove</mat-icon>
            {{ 'NEW_DEPENDENT.REMOVE' | translate }}
          </button>
        </div>
      </form-group>
      <form-group title="{{ 'NEW_DEPENDENT.TABLE_THREE_TITLE' | translate }}">
        <div class="records__table-wrapper table-responsive-wrapper" *ngIf="table.tableThree.length > 0">
          <table mat-table [dataSource]="table.tableThree" #mytable class="records__table">
            <ng-container matColumnDef="firstAndLastName">
              <th mat-header-cell *matHeaderCellDef>
                {{
                  'NEW_DEPENDENT.TABLE.FIRST_AND_LAST_NAME_THREE' | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.firstName + ' ' + element.lastName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateAndPlaceOfBirth">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.DATE_AND_PLACE_OF_BIRTH' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfBirth  | date:"dd.MM.yyyy."}} {{ element.placeOfBirth }}
              </td>
            </ng-container>
            <ng-container matColumnDef="jmbg">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.JMBG' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.jmbg }}
              </td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.ADDRESS' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.address }}
              </td>
            </ng-container>
            <ng-container matColumnDef="legalRelation">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.LEGAL_RELATION_THREE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.legalRelation }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns.tableThree"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns.tableThree"
              (click)="onRowSelected('tableThree', row)"
              [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (mouseover)="row.hovered = true"
              (mouseout)="row.hovered = false"></tr>
          </table>
        </div>
        <div class="button-holder">
          <button mat-flat-button color="primary" type="button" (click)="addTableRecord('tableThree')"
            class="form-footer__button form-footer__button--secondary">
            <mat-icon>add</mat-icon>
            {{ 'NEW_DEPENDENT.ADD' | translate }}
          </button>
          <button mat-flat-button color="warn" (click)="removeTableRecord('tableThree')"
            [disabled]="!isTableRecordSelected.tableThree" type="button">
            <mat-icon>remove</mat-icon>
            {{ 'NEW_DEPENDENT.REMOVE' | translate }}
          </button>
        </div>
      </form-group>
      <form-group title="{{ 'NEW_DEPENDENT.TABLE_FOUR_TITLE' | translate }}">
        <div class="records__table-wrapper table-responsive-wrapper" *ngIf="table.tableFour.length > 0">
          <table mat-table [dataSource]="table.tableFour" #mytable class="records__table">
            <ng-container matColumnDef="firstAndLastName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.FIRST_AND_LAST_NAME_FOUR' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.firstName + ' ' + element.lastName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateAndPlaceOfBirth">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.DATE_AND_PLACE_OF_BIRTH' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfBirth | date:"dd.MM.yyyy." }} {{ element.placeOfBirth }}
              </td>
            </ng-container>
            <ng-container matColumnDef="jmbg">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.JMBG' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.jmbg }}
              </td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.ADDRESS' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.address }}
              </td>
            </ng-container>
            <ng-container matColumnDef="legalRelation">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.LEGAL_RELATION_THREE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.legalRelation }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns.tableFour"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns.tableFour"
              (click)="onRowSelected('tableFour', row)"
              [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (mouseover)="row.hovered = true"
              (mouseout)="row.hovered = false"></tr>
          </table>
        </div>
        <div class="button-holder">
          <button mat-flat-button color="primary" type="button" (click)="addTableRecord('tableFour')"
            class="form-footer__button form-footer__button--secondary">
            <mat-icon>add</mat-icon>
            {{ 'NEW_DEPENDENT.ADD' | translate }}
          </button>
          <button mat-flat-button color="warn" (click)="removeTableRecord('tableFour')"
            [disabled]="!isTableRecordSelected.tableFour" type="button">
            <mat-icon>remove</mat-icon>
            {{ 'NEW_DEPENDENT.REMOVE' | translate }}
          </button>
        </div>
      </form-group>
      <form-group title="{{ 'NEW_DEPENDENT.TABLE_FIVE_TITLE' | translate }}">
        <div class="records__table-wrapper table-responsive-wrapper" *ngIf="table.tableFive.length > 0">
          <table mat-table [dataSource]="table.tableFive" #mytable class="records__table">
            <ng-container matColumnDef="supportMannerMoney">
              <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_MANNER_TOOLTIP' | translate
                }}">
                {{ 'NEW_DEPENDENT.TABLE.SUPPORT_MANNER_MONEY' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.supportMannerMoney }}
              </td>
            </ng-container>
            <ng-container matColumnDef="supportMannerOther">
              <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_MANNER_TOOLTIP' | translate
                }}">
                {{ 'NEW_DEPENDENT.TABLE.SUPPORT_MANNER_OTHER' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.supportMannerOther }}
              </td>
            </ng-container>
            <ng-container matColumnDef="supportAmmountFiscal">
              <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_AMMOUNT_TOOLTIP' | translate
                }}">
                {{ 'NEW_DEPENDENT.TABLE.SUPPORT_AMMOUNT_FISCAL' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.supportAmmountFiscal }}
              </td>
            </ng-container>
            <ng-container matColumnDef="supportAmmountPercentage">
              <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_AMMOUNT_TOOLTIP' | translate
                }}">
                {{
                  'NEW_DEPENDENT.TABLE.SUPPORT_AMMOUNT_PERCENTAGE' | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.supportAmmountPercentage }}
              </td>
            </ng-container>
            <ng-container matColumnDef="supportDurationLimited">
              <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_DURATION_TOOLTIP' | translate
                }}">
                {{ 'NEW_DEPENDENT.TABLE.SUPPORT_DURATION_LIMITED' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.supportDurationLimited }}
              </td>
            </ng-container>
            <ng-container matColumnDef="supportDurationUnlimited">
              <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_DURATION_TOOLTIP' | translate
                }}">
                {{
                  'NEW_DEPENDENT.TABLE.SUPPORT_DURATION_UNLIMITED' | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.supportDurationUnlimited }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns.tableFive"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns.tableFive"
              (click)="onRowSelected('tableFive', row)"
              [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (mouseover)="row.hovered = true"
              (mouseout)="row.hovered = false"></tr>
          </table>
        </div>
        <div class="button-holder">
          <button mat-flat-button color="primary" type="button" (click)="addTableRecord('tableFive')"
            class="form-footer__button form-footer__button--secondary">
            <mat-icon>add</mat-icon>
            {{ 'NEW_DEPENDENT.ADD' | translate }}
          </button>
          <button mat-flat-button color="warn" (click)="removeTableRecord('tableFive')"
            [disabled]="!isTableRecordSelected.tableFive" type="button">
            <mat-icon>remove</mat-icon>
            {{ 'NEW_DEPENDENT.REMOVE' | translate }}
          </button>
        </div>
      </form-group>
      <form-group title="{{ 'NEW_DEPENDENT.TABLE_SIX_TITLE' | translate }}" isLast="true">
        <div class="records__table-wrapper table-responsive-wrapper" *ngIf="table.tableSix.length > 0">
          <table mat-table [dataSource]="table.tableSix" #mytable class="records__table">
            <ng-container matColumnDef="courtName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.COURT_NAME' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.courtName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="courtNumber">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.COURT_NUMBER' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.courtNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="courtDate">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.COURT_DATE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.courtDate | date:"dd.MM.yyyy."}}
              </td>
            </ng-container>
            <ng-container matColumnDef="dateOfVerdictTermination">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.DATE_OF_VERDICT_TERMINATION' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.dateOfVerdictTermination | date:"dd.MM.yyyy." }}
              </td>
            </ng-container>
            <ng-container matColumnDef="reasonsForVerdictTermination">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'NEW_DEPENDENT.TABLE.REASONS_FOR_VERDICT_TERMINATON' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.reasonsForVerdictTermination }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns.tableSix"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns.tableSix"
              (click)="onRowSelected('tableSix', row)"
              [ngClass]="{ hovered: row.hovered, highlighted: row.highlighted }" (mouseover)="row.hovered = true"
              (mouseout)="row.hovered = false"></tr>
          </table>
        </div>
        <div class="button-holder">
          <button mat-flat-button color="primary" type="button" (click)="addTableRecord('tableSix')"
            class="form-footer__button form-footer__button--secondary">
            <mat-icon>add</mat-icon>
            {{ 'NEW_DEPENDENT.ADD' | translate }}
          </button>
          <button mat-flat-button color="warn" (click)="removeTableRecord('tableSix')"
            [disabled]="!isTableRecordSelected.tableSix" type="button">
            <mat-icon>remove</mat-icon>
            {{ 'NEW_DEPENDENT.REMOVE' | translate }}
          </button>
        </div>
      </form-group>
      <form-footer>
        <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
          (click)="createRecord(newRecord)" [disabled]="!newRecord.valid">
          {{ 'NEW_DEPENDENT.CREATE' | translate }}
        </button>
      </form-footer>
    </form>
  </mat-card>
</container>
