/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

export class User {

  roles: string [];
  firstName: string;
  lastName: string;
  username: string;
  id: string;
  city: string;
  csrId: string;
  center: string;
  jmbg: string;

  constructor(roles: string[], firstName: string, lastName: string, id: string,
    city: string, csrId: string, username: string, center: string, jmbg: string) {
    this.roles = roles;
    this.firstName = firstName;
    this.lastName = lastName;
    this.id = id;
    this.city = city;
    this.csrId = csrId;
    this.username = username;
    this.center = center;
    this.jmbg = jmbg;
  }

}
