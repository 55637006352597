<mat-dialog-content>
    <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
        [mat-dialog-close]="true"><span class="material-icons">close</span>
    </button>

    <div class="container">
        <form flex [formGroup]="usersForm" (ngSubmit)="addNewUser()">
            <h2>{{ 'VS.FORM5.USERS_FORM.TITLE' | translate }}</h2>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'VS.FORM5.USERS_FORM.FIRST_LAST_NAME' | translate }}</mat-label>
                    <input matInput type="text" required formControlName="firstAndLastName" maxlength="60" autocomplete="off" />
                </mat-form-field>
            </field-holder>

            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'VS.FORM5.USERS_FORM.RELATIONSHIP' | translate }}</mat-label>
                    <mat-select formControlName="relationship">
                        <mat-option *ngFor="let relationship of relationshipOptions" [value]="relationship">
                            {{ relationship.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </field-holder>

            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'VS.FORM5.USERS_FORM.PHONE' | translate }}</mat-label>
                    <input matInput type="text" formControlName="phone" maxlength="13" autocomplete="off" />
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
                    class="form-footer__button form-footer__button--secondary">
                    {{ 'VS.FORM5.USERS_FORM.CANCEL' | translate }}
                </button>
                <button mat-flat-button color="primary" type="submit" [disabled]="!usersForm.valid"
                    class="form-footer__button form-footer__button--primary">
                    {{ 'VS.FORM5.USERS_FORM.SUBMIT' | translate }}
                </button>
            </form-footer>
        </form>
    </div>
</mat-dialog-content>