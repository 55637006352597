<container kind="default">
    <page-intro-header back="/domestic-violence-victim" title="{{ 'DOMESTIC_VIOLENCE_VICTIM.T' | translate }}">
  </page-intro-header>
</container>
<container kind="default">
    <mat-card class="card">
        <definition-list>
            <definition-item *ngIf="abusedPerson?.ordinalNumber"
                key="{{ 'PROTEGE_DETAILS.R_BR' | translate }}">
                {{ abusedPerson.ordinalNumber }}</definition-item>
            <definition-item *ngIf="abusedPerson?.subjectId" key="{{ 'PROTEGE_DETAILS.ID' | translate }}">
                {{ abusedPerson.subjectId }}</definition-item>

            <definition-item *ngIf="abusedPerson?.firstName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.FIRST_NAME' | translate }}">
                {{ abusedPerson.firstName }}</definition-item>

            <definition-item *ngIf="abusedPerson?.lastName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.LAST_NAME' | translate }}">
                {{ abusedPerson.lastName }}</definition-item>

            <definition-item *ngIf="abusedPerson?.maidenName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.MAIDEN_NAME' | translate }}">
                {{ abusedPerson.maidenName }}</definition-item>

            <definition-item *ngIf="abusedPerson?.nickName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.NICKNAME' | translate }}">
                {{ abusedPerson.nickName }}</definition-item>

            <definition-item *ngIf="abusedPerson?.parentFirstAndLastName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.PARENT_FULL_NAME' | translate }}">
                {{ abusedPerson.parentFirstAndLastName }}</definition-item>

            <definition-item *ngIf="abusedPerson?.motherMaidenName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.MOTHER_MAIDEN_NAME' | translate }}">
                {{ abusedPerson.motherMaidenName }}</definition-item>

            <definition-item *ngIf="abusedPerson?.gender"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.GENDER' | translate }}">
                {{ abusedPerson.gender.gender }}</definition-item>

            <definition-item *ngIf="abusedPerson?.jmbg"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.JMBG' | translate }}">
                {{ abusedPerson.jmbg }}</definition-item>

            <definition-item *ngIf="abusedPerson?.dateOfBirth"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DATE_OF_BIRTH' | translate }}">
                {{ abusedPerson.dateOfBirth }}</definition-item>

            <definition-item *ngIf="abusedPerson?.placeOfBirth"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ abusedPerson.placeOfBirth }}</definition-item>

            <definition-item *ngIf="abusedPerson?.countryOfBirth"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COUNTRY_OF_BIRTH' | translate }}">
                {{ abusedPerson.countryOfBirth }}</definition-item>

            <definition-item *ngIf="abusedPerson?.citizenship"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.CITIZENSHIP' | translate }}">
                {{ abusedPerson.citizenship.title }}</definition-item>

            <definition-item *ngIf="abusedPerson?.nationality"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.NATIONALITY' | translate }}">
                {{ abusedPerson.nationality.title }}</definition-item>

            <definition-item *ngIf="abusedPerson?.occupation"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.OCCUPATION' | translate }}">
                {{ abusedPerson.occupation.title }}</definition-item>

            <definition-item *ngIf="abusedPerson?.permanentResidence"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.RESIDENCE_ADDRESS' | translate }}">
                {{ abusedPerson.permanentResidence }}</definition-item>

            <card-subgroup-divider></card-subgroup-divider>

            <h2>{{
          'NEW_DOMESTIC_VIOLENCE_VICTIM.VIOLENCE_INFORMATION' | translate
        }}
            </h2>


            <definition-item *ngIf="abusedPerson?.commitedViolenceCount"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COMMITED_VIOLENCE_COUNT' | translate }}">
                {{ abusedPerson.commitedViolenceCount.title }}</definition-item>

            <definition-item *ngIf="abusedPerson?.count"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COMMITED_VIOLENCE_COUNT_META' | translate }}">
                {{ abusedPerson.count }}</definition-item>

            <definition-item *ngIf="abusedPerson?.protectionRequesterCodebook"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.PROTECTION_FROM_VIOLENCE_REQUESTER' | translate }}">
               {{ abusedPerson.protectionRequesterCodebook.title }}
               <span *ngIf="abusedPerson?.protectionRequesterNotes !== null && abusedPerson?.protectionRequesterNotes !== '' ">
                    - {{ abusedPerson?.protectionRequesterNotes }}
               </span> 
            </definition-item>

            <definition-item *ngIf="abusedPerson?.courtName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.COURT_NAME' | translate }}">
                {{ abusedPerson.courtName }}</definition-item>

            <definition-item *ngIf="abusedPerson?.judgmentNumber"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.VERDICT_NUMBER' | translate }}">
                {{ abusedPerson.judgmentNumber }}</definition-item>

            <definition-item *ngIf="abusedPerson?.judgmentDate"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DATE_OF_VERDICT' | translate }}">
                {{ abusedPerson.judgmentDate }}</definition-item>

            <definition-item *ngIf="abusedPerson?.entryForceDate"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DATE_OF_VERDICT_ENTRY_INTO_FORCE' | translate }}">
                {{ abusedPerson.entryForceDate }}</definition-item>

            <definition-item *ngIf="abusedPerson?.violenceKindCodebook.length != 0"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.COMMITTED_VIOLENCE_TYPE' | translate }}">
                <ul><li  *ngFor="let p of abusedPerson?.violenceKindCodebook" >{{p.title}}</li></ul>
            </definition-item>

            <definition-item *ngIf="abusedPerson?.familyMemberCommiter.length != 0"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.VIOLENCE_FAMILY_MEMBER_COMMITER' | translate }}">
                <ul> <li  *ngFor="let p of abusedPerson?.familyMemberCommiter" >
                    {{ p.name }} 
                    <span *ngIf="p.value !== null && p.value !== '' "> - {{ p.value }} </span>
                </li> </ul>
            </definition-item>

            <definition-item *ngIf="abusedPerson?.protectionMeasureCodebook.length != 0"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DEFINED_PROTECTION_MEASURE' | translate }}">
               <ul> <li  *ngFor="let p of abusedPerson?.protectionMeasureCodebook" >{{p.title}}</li> </ul>
            </definition-item>

             <definition-item *ngIf="abusedPerson?.protectionMeasureDuration"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_TO' | translate }}">
                {{ abusedPerson.protectionMeasureDuration }}</definition-item>
             <definition-item *ngIf="abusedPerson?.importedProtectionDuration"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_TO' | translate }}">
                {{ abusedPerson.importedProtectionDuration }}</definition-item>
            
            <definition-item *ngIf="!abusedPerson?.protectionMeasureDuration && abusedPerson?.measureDurationFrom"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_FROM' | translate }}">
                {{ abusedPerson.measureDurationFrom }}</definition-item>

   			<definition-item *ngIf="!abusedPerson?.protectionMeasureDuration && abusedPerson?.measureDurationTo"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_TO' | translate }}">
                {{ abusedPerson.measureDurationTo }}</definition-item>
            
            <definition-item *ngIf="abusedPerson?.terminationDate"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.TERMINATION_DATE' | translate }}">
                {{ abusedPerson.terminationDate }}</definition-item>

        </definition-list>



    </mat-card>
</container>
