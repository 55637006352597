<container>
  <mat-card class="card">
    <card-header title="{{ 'STATISTICS.GENERAL_RECORD_STATS.TITLE' | translate }}">
    </card-header>
    <form [formGroup]="generalRecordStatsForm" class="form">
      <field-holder kind="2-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
        <mat-form-field appearance="outline">
          <mat-label>
            {{'GLOBAL.CENTER' | translate}}
          </mat-label>
          <input type="text" matInput [matAutocomplete]="autoSubcenter" formControlName="center"
            (focusout)="autoSelectCenter()" required />
          <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let cent of filteredAllCenters | async" [value]="cent">
              {{ cent.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'STATISTICS.GENERAL_RECORD_STATS.CHOOSE_OPTION' | translate
            }}</mat-label>
          <mat-select formControlName="selectedRecordOption">
            <mat-option *ngFor="let record of recordOptions" [value]="record">
              {{ record.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <field-holder *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'STATISTICS.GENERAL_RECORD_STATS.CHOOSE_OPTION' | translate
            }}</mat-label>
          <mat-select formControlName="selectedRecordOption">
            <mat-option *ngFor="let record of recordOptions" [value]="record">
              {{ record.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
      <section formGroupName="1" *ngIf="groups[1]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.AGE_GROUP' | translate
              }}</mat-label>
            <mat-select formControlName="timeOption" [compareWith]="compareObjectsId"
              (selectionChange)="changeTimePeriod($event.value)">
              <mat-option *ngFor="let option of timeOptions" [value]="option">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline"
            *ngIf="(generalRecordStatsForm.get('1')['controls']).timeOption.value?.id == 1">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('1')['controls']).selectedYear.hasError('required')  ">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline"
            *ngIf="(generalRecordStatsForm.get('1')['controls']).timeOption.value?.id == 2">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off"
              [required]="(generalRecordStatsForm.get('1')['controls']).timeOption.value?.id == 2"
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('1')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.AGE_GROUP' | translate
              }}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.GENDER' | translate
              }}</mat-label>
            <mat-select formControlName="gender">
              <mat-option></mat-option>
              <mat-option *ngFor="let record of genderOptions" [value]="record">
                {{ record.gender }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>


      </section>
      <section formGroupName="2" *ngIf="groups[2]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('2')['controls']).selectedYear.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.AGE_GROUP' | translate
              }}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.SUBJECT_STATUS' | translate
              }}</mat-label>
            <mat-select formControlName="subjectStatus" multiple>
              <mat-option *ngFor="let record of subjectStatusOptions" [value]="record.id">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </field-holder>
      </section>
      <section formGroupName="3" *ngIf="groups[3]">
        <field-holder kind="4-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('3')['controls']).selectedYear.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.AGE_GROUP' | translate
              }}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.GENDER' | translate
              }}</mat-label>
            <mat-select formControlName="gender">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let record of genderOptions" [value]="record">
                {{ record.gender }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.RESIDENCE' | translate
              }}</mat-label>
            <mat-select formControlName="residence">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let record of residenceOptions" [value]="record.id">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </field-holder>
      </section>
      <section formGroupName="4" *ngIf="groups[4]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off" required
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('4')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.AGE_GROUP' | translate
              }}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </field-holder>
      </section>
      <section formGroupName="5" *ngIf="groups[5]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.SELECTED_YEAR' | translate }}</mat-label>
            <input matInput type="text" formControlName="selectedYear" autocomplete="off" required maxlength="4"
              onlyNumber />
            <mat-error *ngIf="(generalRecordStatsForm.get('5')['controls']).selectedYear.hasError('required')  ">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.AGE_GROUP' | translate
              }}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.GENDER' | translate
              }}</mat-label>
            <mat-select formControlName="gender">
              <mat-option></mat-option>
              <mat-option *ngFor="let record of genderOptions" [value]="record">
                {{ record.gender }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </field-holder>
      </section>
      <section formGroupName="6" *ngIf="groups[6]">
        <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="dateDay" formControlName="dateDay" autocomplete="off" required
              placeholder="{{ 'STATISTICS.GENERAL_RECORD_STATS.REPORTING_ON_DAY' | translate }}" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="dateDay"></mat-datepicker-toggle>
            <mat-datepicker #dateDay></mat-datepicker>
            <mat-error *ngIf="(generalRecordStatsForm.get('6')['controls']).dateDay.hasError('required')">
              {{ "STATISTICS.DATE_ERROR" | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{
              'STATISTICS.GENERAL_RECORD_STATS.AGE_GROUP' | translate
              }}</mat-label>
            <mat-select formControlName="ageGroup" multiple>
              <mat-option *ngFor="let record of ageGroupOne" [value]="record">
                {{ record.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </field-holder>
      </section>
      <field-holder kind="2-cols" *ngIf="shouldShowAgeGroupRange">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.FROM' | translate }}</mat-label>
          <input matInput type="text" formControlName="customAgeGroupFrom" autocomplete="off" maxlength="3"
            (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
            placeholder="{{ 'STATISTICS.FROM' | translate }}" />
          <mat-error *ngIf="generalRecordStatsForm.get('customAgeGroupFrom').hasError('outOfRange')">
            {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'STATISTICS.TO' | translate }}</mat-label>
          <input matInput type="text" formControlName="customAgeGroupTo" autocomplete="off" maxlength="3"
            (change)="checkCustomYears()" [required]="shouldShowAgeGroupRange" onlyNumber
            placeholder="{{ 'STATISTICS.TO' | translate }}" />
          <mat-error *ngIf="generalRecordStatsForm.get('customAgeGroupTo').hasError('incorect')">
            {{ "STATISTICS.NUMBER_OF_YEARS_INCORECT" | translate }}
          </mat-error>
          <mat-error *ngIf="generalRecordStatsForm.get('customAgeGroupTo').hasError('outOfRange')">
            {{ "STATISTICS.NUMBER_OF_YEARS_OUT_OF_RANGE" | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button [disabled]="!generalRecordStatsForm.valid || btnDisabled"
          class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
          (click)="sendRequest(generalRecordStatsForm)">
          {{ 'STATISTICS.SUBMIT' | translate }}
        </button>
        <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </form-footer>
    </form>
  </mat-card>
</container>