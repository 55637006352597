/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkloadCalculationDialogComponent } from '../../workload-calculation-dialog/workload-calculation-dialog.component';

@Component({
   selector: 'collapsible-worker',
   templateUrl: './collapsible-worker.component.html',
   styleUrls: ['./collapsible-worker.component.scss'],
})
export class CollapsibleWorkerComponent implements OnInit {
   @Input() groupExpanded: boolean = true;
   @Input() title: string;
   @Input() workload: string;
   @Input() meta;
   @Input() worker: any;
   @Output() renderCases = new EventEmitter();

   constructor(public dialog: MatDialog) {}

   ngOnInit(): void {}

   toggle() {
      this.groupExpanded = !this.groupExpanded;
      const body = { userId: this.worker.userId, groupExpanded: this.groupExpanded };
      this.renderCases.emit(body);
   }

   openWorkloadCalculation() {
      const dialogRef = this.dialog.open(WorkloadCalculationDialogComponent, {
         disableClose: true,
         width: '800px',
         data: this.worker,
         panelClass: 'overlay-panel',
      });

      //  dialogRef.afterClosed().subscribe(res => {
      //
      //  });
   }
}
