/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { AppService } from './app.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountingProceduresService {
  private btnDisabled: BehaviorSubject<boolean>;

  constructor(private httpClient: HttpClient, private app: AppService) {
    this.btnDisabled = new BehaviorSubject<boolean>(false);
   }
  getBtnStatus(): Observable<boolean>{
    return this.btnDisabled.asObservable();
  }
  setBtnStatus(value): void{
    this.btnDisabled.next(value);
  }
  exportCSV(filterValue: any) {
    const filterJSON = JSON.parse(JSON.stringify(filterValue));
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    this.setBtnStatus(true);
    this.httpClient
      .put(this.app.getUrl() + environment.getAccountingProceduresExportUrl, filterJSON, { responseType: 'blob', headers })
      .subscribe(data => {
        const parts = [];
        parts.push('\uFEFF');
        parts.push(data);
        const file = new Blob(parts, { type: 'text/csv' });
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = fileURL;
        a.download = 'Статистика - рачуноводствени поступци.csv';
        a.click();
        window.URL.revokeObjectURL(fileURL);
        a.remove();
        this.setBtnStatus(false);
      });
  }
}