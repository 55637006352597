<container kind="default">
    <page-intro-header back="/domestic-violence-committer" title="{{ 'DOMESTIC_VIOLENCE_COMMITTER.T' | translate }}">
  </page-intro-header>
</container>
<container kind="default">
    <mat-card class="card">
        <definition-list>
            <definition-item *ngIf="violenceCommiter?.ordinalNumber"
                key="{{ 'PROTEGE_DETAILS.R_BR' | translate }}">
                {{ violenceCommiter.ordinalNumber }}</definition-item>
            <definition-item *ngIf="violenceCommiter?.subjectId" key="{{ 'PROTEGE_DETAILS.ID' | translate }}">
                {{ violenceCommiter.subjectId }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.firstName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.FIRST_NAME' | translate }}">
                {{ violenceCommiter.firstName }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.lastName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.LAST_NAME' | translate }}">
                {{ violenceCommiter.lastName }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.maidenName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.MAIDEN_NAME' | translate }}">
                {{ violenceCommiter.maidenName }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.nickName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.NICKNAME' | translate }}">
                {{ violenceCommiter.nickName }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.parentFirstAndLastName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.PARENT_FULL_NAME' | translate }}">
                {{ violenceCommiter.parentFirstAndLastName }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.motherMaidenName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.MOTHER_MAIDEN_NAME' | translate }}">
                {{ violenceCommiter.motherMaidenName }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.gender"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.GENDER' | translate }}">
                {{ violenceCommiter.gender.gender }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.jmbg"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.JMBG' | translate }}">
                {{ violenceCommiter.jmbg }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.dateOfBirth"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.DATE_OF_BIRTH' | translate }}">
                {{ violenceCommiter.dateOfBirth }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.placeOfBirth"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.PLACE_OF_BIRTH' | translate }}">
                {{ violenceCommiter.placeOfBirth }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.countryOfBirth"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.COUNTRY_OF_BIRTH' | translate }}">
                {{ violenceCommiter.countryOfBirth }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.citizenship"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.CITIZENSHIP' | translate }}">
                {{ violenceCommiter.citizenship.title }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.nationality"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.NATIONALITY' | translate }}">
                {{ violenceCommiter.nationality.title }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.occupation"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.OCCUPATION' | translate }}">
                {{ violenceCommiter.occupation.title }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.permanentResidence"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.RESIDENCE_ADDRESS' | translate }}">
                {{ violenceCommiter.permanentResidence }}</definition-item>

            <card-subgroup-divider></card-subgroup-divider>

            <h2>{{
          'NEW_DOMESTIC_VIOLENCE_COMMITTER.VIOLENCE_INFORMATION' | translate
        }}
            </h2>

            <definition-item *ngIf="violenceCommiter?.criminallyLiable !== null"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.CRIMINALLY_PROSECUTED_IN_PAST' | translate }}">
                {{ (violenceCommiter?.criminallyLiable ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}</definition-item>
            <definition-item *ngIf="violenceCommiter?.earlierDelict !== null"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.MISDEMEANOR_PROSECUTED_IN_PAST' | translate }}">
                {{ (violenceCommiter?.earlierDelict ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.earlierMeasures !== null"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.PROSECUTED_FOR_DOMESTIC_VIOLENCE_IN_PAST' | translate }}">
                {{ (violenceCommiter?.earlierMeasures ?  'GLOBAL.YES' : 'GLOBAL.NO') | translate  }}</definition-item>


            <definition-item *ngIf="violenceCommiter?.commitedViolenceCount"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.COMMITED_VIOLENCE_COUNT' | translate }}">
                {{ violenceCommiter.commitedViolenceCount.title }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.count"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.COMMITED_VIOLENCE_COUNT_META' | translate }}">
                {{ violenceCommiter.count }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.protectionRequesterCodebook"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.PROTECTION_FROM_VIOLENCE_REQUESTER' | translate }}">
                {{ violenceCommiter.protectionRequesterCodebook.title }} 
                <span *ngIf="violenceCommiter?.protectionRequesterNotes !== null && violenceCommiter?.protectionRequesterNotes !== '' ">
                    - {{ violenceCommiter?.protectionRequesterNotes }}
                </span>
            </definition-item>

            <definition-item *ngIf="violenceCommiter?.courtName"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.COURT_NAME' | translate }}">
                {{ violenceCommiter.courtName }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.judgmentNumber"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.VERDICT_NUMBER' | translate }}">
                {{ violenceCommiter.judgmentNumber }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.judgmentDate"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.DATE_OF_VERDICT' | translate }}">
                {{ violenceCommiter.judgmentDate }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.entryForceDate"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.DATE_OF_VERDICT_ENTRY_INTO_FORCE' | translate }}">
                {{ violenceCommiter.entryForceDate }}</definition-item>

            <definition-item *ngIf="violenceCommiter?.violenceKindCodebook.length != 0"
                             key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.COMMITTED_VIOLENCE_TYPE' | translate }}">
              <ul><li  *ngFor="let p of violenceCommiter?.violenceKindCodebook" >{{p.title}}</li></ul>
            </definition-item>

            <definition-item *ngIf="violenceCommiter?.familyMemberCommiter.length !== 0"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_COMMITTER.FORM.FAMILY_MEMBER_VICTIM' | translate }}">
                <ul> <li  *ngFor="let p of violenceCommiter?.familyMemberCommiter" >
                    {{ p.name }}  
                    <span *ngIf="p.value !== null && p.value !== '' ">- {{ p.value }}</span>
                </li> </ul>
            </definition-item>

            <definition-item *ngIf="violenceCommiter?.protectionMeasureCodebook?.length != 0"
                             key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DEFINED_PROTECTION_MEASURE' | translate }}">
              <ul> <li  *ngFor="let p of violenceCommiter?.protectionMeasureCodebook" >{{p.title}}</li> </ul>
            </definition-item>
            
           <definition-item *ngIf="violenceCommiter?.protectionMeasureDuration"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_TO' | translate }}">
                {{ violenceCommiter.protectionMeasureDuration }}</definition-item>
           <definition-item *ngIf="violenceCommiter?.importedProtectionDuration"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_TO' | translate }}">
                {{ violenceCommiter.importedProtectionDuration }}</definition-item>
            
            <definition-item *ngIf="!violenceCommiter?.protectionMeasureDuration && violenceCommiter?.measureDurationFrom"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_FROM' | translate }}">
                {{ violenceCommiter.measureDurationFrom }}</definition-item>

            <definition-item *ngIf="!violenceCommiter?.protectionMeasureDuration && violenceCommiter?.measureDurationTo"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.DURATION_OF_PROTECTION_MEASURE_TO' | translate }}">
                {{ violenceCommiter.measureDurationTo }}</definition-item>
            
            <definition-item *ngIf="violenceCommiter?.terminationDate"
                key="{{ 'NEW_DOMESTIC_VIOLENCE_VICTIM.FORM.TERMINATION_DATE' | translate }}">
                {{ violenceCommiter.terminationDate }}</definition-item>
                
        </definition-list>

    </mat-card>
</container>
