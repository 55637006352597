<div class="socijalno-theme mat-typography">
  
  <ng-container *ngIf="!isLoggedIn">
    <app-login></app-login>
  </ng-container>

  <ng-container *ngIf="isLoggedIn && (!isSpecificUser)">
    <page>
      <router-outlet></router-outlet>
    </page>
  </ng-container>

  <ng-container *ngIf="isLoggedIn && isSpecificUser">
    <accounting-procedures></accounting-procedures>
  </ng-container>
  <ng-container *ngIf="isLoggedIn">
    <warning-session [isOpen]="moduleOpen" [time]="time" (closeWarning)="closeWarning()"></warning-session>
  </ng-container>
</div>
