/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-legal-subject',
  templateUrl: './new-legal-subject.component.html',
  styleUrls: ['./new-legal-subject.component.scss']
})
export class NewLegalSubjectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
