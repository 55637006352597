<form [formGroup]="personalInformationStep" class="form">

  <div class="button-add-holder">
    <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
      <mat-icon>person_add</mat-icon>
      {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
    </button>
  </div>
  <br>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.FIRST_NAME' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="firstName" required autocomplete="off" />
      <mat-error *ngIf="personalInformationStep.get('firstName').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.LAST_NAME' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="lastName" required autocomplete="off" />
      <mat-error *ngIf="personalInformationStep.get('lastName').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.GENDER' | translate
      }}</mat-label>
      <mat-select formControlName="gender" [compareWith]="compareObj"
                  (selectionChange)="updateJMBGOnGenderChange($event)" required>
        <mat-option *ngFor="let gender of genderOptions" [value]="gender">
          {{ gender.gender }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="personalInformationStep.get('gender').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf=" personalInformationStep.get('gender').hasError('incorrect')">
        {{ 'NEW_SUBJECT.PERSONAL_DETAILS.GENDER_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.DATE_OF_BIRTH' | translate
      }}</mat-label>
      <input matInput [matDatepicker]="picker" (dateChange)="updateJMBGOnDateOfBirthChange($event)"
             formControlName="dateOfBirth" required autocomplete="off" 
             [max]="maxDate" placeholder="{{ 'DATE_FORMAT' | translate }}" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="personalInformationStep.get('dateOfBirth').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('dateOfBirth').hasError('incorrect')">
        {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.PLACE_OF_BIRTH' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="placeOfBirth" required autocomplete="off" />
      <mat-error *ngIf="personalInformationStep.get('placeOfBirth').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.JMBG' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="jmbg" maxlength="13"
             (input)="updateJMBGOnDateOfBirthChange($event);updateJMBGOnGenderChange($event)" required
             autocomplete="off" />
      <mat-error *ngIf="personalInformationStep.get('jmbg').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('jmbg').hasError('minLength') ||
                  personalInformationStep.get('jmbg').hasError('maxLength') ||
                  personalInformationStep.get('jmbg').hasError('pattern')">
        {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols-2-1-1">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.PLACE_OF_ORIGIN' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="placeOfOrigin" required autocomplete="off" />
      <mat-error *ngIf="
          personalInformationStep.get('placeOfOrigin').hasError('required')
        ">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NEW_FOSTER_CHILD.FORM.CITIZENSHIP' | translate }}
      </mat-label>
      <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenship"
             (focusout)="checkCitizenship()" />
      <mat-autocomplete #autoCitizenship="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredCitizenshipOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="personalInformationStep.get('citizenship').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('citizenship').hasError('notValid')">
        {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{
          'NEW_FOSTER_CHILD.FORM.NATIONALITY' | translate
        }}</mat-label>
      <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationality"
             (focusout)="checkNationality()" />
      <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="displayCustomFormat">
        <mat-option *ngFor="let option of filteredNationalityOptions | async" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="personalInformationStep.get('nationality').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="personalInformationStep.get('nationality').hasError('notValid')">
        {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <field-holder kind="3-cols-2-1-1">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.FAMILY_STATUS' | translate
      }}</mat-label>
      <mat-select formControlName="familyStatus" required>
        <mat-option *ngFor="let status of familyStatusOptions" [value]="status">
          {{ status.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="personalInformationStep.get('familyStatus').hasError('required')">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.FOSTERING_STATUS' | translate
      }}</mat-label>
      <mat-select formControlName="fosteringStatus" (selectionChange)="fosteringStatusChanged()" required>
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">
          {{ option.viewValue | translate }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="
          personalInformationStep.get('fosteringStatus').hasError('required')
        ">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="personalInformationStep.get('fosteringStatus').value.value === true">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.FROM' | translate
      }}</mat-label>
      <input matInput [matDatepicker]="fosteringFrom" [max]="currentDate" formControlName="fosteringFrom" [required]="
          personalInformationStep.get('fosteringStatus').value.value === true 
        " autocomplete="off" />
      <mat-datepicker-toggle matSuffix [for]="fosteringFrom"></mat-datepicker-toggle>
      <mat-datepicker #fosteringFrom></mat-datepicker>
      <mat-error *ngIf="
          personalInformationStep.get('fosteringFrom').hasError('required')
        ">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="3-cols-2-1-1">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.EDUCATION_STATUS' | translate
      }}</mat-label>
      <mat-select formControlName="educationStatus" (selectionChange)="educationStatusChanged()" required>
        <mat-option *ngFor="let status of schoolStatusOptions" [value]="status">
          {{ status.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="
          personalInformationStep.get('educationStatus').hasError('required')
        ">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="personalInformationStep.get('educationStatus').value.id > 1">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.CLASS' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="class" autocomplete="off" />
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <h3 class="subtitle">{{ 'FOSTER_CHILD.HEALTH_STATUS_TITLE' | translate
      }}</h3>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.GENERAL_HEALTH_STATUS' | translate
      }}</mat-label>
      <mat-select formControlName="generalHealthStatus" required>
        <mat-option *ngFor="let status of generalHealthStatusOptions" [value]="status">
          {{ status.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="
          personalInformationStep.get('generalHealthStatus').hasError('required')
        ">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <field-holder *ngIf="personalInformationStep.get('generalHealthStatus').value.id === 2">

      <div formArrayName="healthDeviationItems">
        <div *ngFor="let item of healthDeviationItems().controls; let i = index" [formGroupName]="i">
          <mat-form-field appearance="outline">
            <mat-label>{{item.value.name}}</mat-label>
            <input matInput type="text" formControlName="value" autocomplete="off" />
          </mat-form-field>
        </div>
      </div>
    </field-holder>
  </field-holder>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_FOSTER_CHILD.FORM.PSYCHOPHYSICAL_DEVELOPMENT' | translate
      }}</mat-label>
      <mat-select formControlName="phychophysicalDevelopment" required>
        <mat-option *ngFor="let status of phychophysicalDevelopmentOptions" [value]="status">
          {{ status.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="
          personalInformationStep.get('phychophysicalDevelopment').hasError('required')
        ">
        {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <field-holder *ngIf="personalInformationStep.get('phychophysicalDevelopment').value.id === 2">
      <div formArrayName="phisicalDeviationItems">
        <div *ngFor="let item of phisicalDeviationItems().controls; let i = index" [formGroupName]="i">
          <mat-form-field appearance="outline">
            <mat-label>{{item.value.name}}</mat-label>
            <input matInput type="text" formControlName="value" autocomplete="off" />
          </mat-form-field>
        </div>
      </div>
    </field-holder>

  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NEW_FOSTER_CHILD.FORM.PSYCHOPHYSICAL_PROGNOSIS' | translate }}
      </mat-label>
      <textarea matInput type="text" formControlName="psychophysicalPrognosis" autocomplete="off"></textarea>
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!personalInformationStep.valid"
            class="form-footer__button" (click)="updatePersonalInformationStep()">
      {{ 'NEW_FOSTER_CHILD.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>
