/**
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaseService } from 'src/services/case.service';
import { SubjectsService } from 'src/services/subjects.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialCaseData } from '../assign-case-dialog/assign-case-dialog.component';
import { AuthenticationService } from 'src/services/authentication.service';
import { UserService } from 'src/services/user.service';
import { environment } from 'src/environments/environment';
import { ForwardCaseDialogComponent } from '../forward-case-dialog/forward-case-dialog.component';

@Component({
  selector: 'app-return-case-dialog',
  templateUrl: './return-case-dialog.component.html',
  styleUrls: ['./return-case-dialog.component.scss']
})
export class ReturnCaseDialogComponent implements OnInit {

  manageCaseForm: FormGroup;
  local_data: any;
  forwardingLawyerWorker = false;
  isLawyerAssigner = false;

  constructor(
    private formBuilder: FormBuilder,
    public caseService: CaseService,
    public subjectsService: SubjectsService,
    public dialogRef: MatDialogRef<ForwardCaseDialogComponent>,
    public authenticationService: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SocialCaseData,
    public userService: UserService
  ) {
    this.local_data = { ...data };
    if (userService.isUserAssigner) {
      this.isLawyerAssigner = true;
    }
    if (this.local_data.source === 'case-header') {
      this.forwardingLawyerWorker = this.local_data.forwardingLawyerWorker;
    }
  }

  ngOnInit(): void {
    this.manageCaseForm = this.formBuilder.group({
      selectedOption: ['', [Validators.required]],
      taskDescription: ['']
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  manageCase(submittedForm: FormGroup) {
    const option = submittedForm.get('selectedOption').value;
    if (option === 1) {
      this.local_data.taskDescription = submittedForm.get('taskDescription').value;
      if (this.userService.isUserWorker()) {
        this.local_data.role = environment.pravnik;
      } else {
        this.local_data.role = environment.strucni_radnik;
      }

      this.dialogRef.close({ event: 'SUCCESS', data: this.local_data });
    } else if (option === 2) {
      this.dialogRef.close({ event: 'CLOSE', data: this.local_data });
    }
  }

}
