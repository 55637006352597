/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  SubmitterData,
  YesOrNoEnum,
  YesOrNoStatuses,
} from 'src/app/entrance/types';
import { LocalStorageService } from 'src/app/local-storage.service';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { checkRelationshipAgeDifference } from 'src/app/utils/checkRelationshipAgeDifference';
import { CodebookService } from 'src/services/codebook.service';
import { NspRequestService } from 'src/services/nsp-request.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import * as myLetters from './../../letters';

enum AutocompleteFilterEnum {
  NATIONALITY = 'nationality',
  CITIZENSHIP = 'citizenship',
  OCCUPATION = 'occupation',
}

@Component({
  selector: 'app-nsp-family-request',
  templateUrl: './nsp-family-request.component.html',
  styleUrls: ['./nsp-family-request.component.scss']
})
export class NspFamilyRequestComponent implements OnInit {

  @ViewChild('submitButton') submitButton;
  firstStep: FormGroup;
  submitterData: SubmitterData;
  isSaved: boolean = false;
  currentDate = new Date();
  center: string;
  showDate: string;
  members = [];
  memberRegs = [];
  languages = [];
  nationalities: any = [];
  nationalitiesFiltered: Observable<any[]>;
  citizenships: any = [];
  citizenshipsFiltered: Observable<any[]>;
  educations = [];
  maritalStatuses = [];
  employments = [];
  relationships = [];
  occupations: any = [];
  occupationsFiltered: Observable<any[]>;
  subjectId;
  genderOptions = [];
  maxDate: Date = new Date(2999, 11, 31);

  public mask = {
    guide: false,
    showMask: false,
    mask: [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /\d/, /\d/, /\d/]
  };

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(
    public dialog: MatDialog,
    public nspRequestService: NspRequestService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private codebookService: CodebookService,
    @Optional() public dialogRef: MatDialogRef<NspFamilyRequestComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public subjectData: any,
    private subjectService: SubjectsService,
    private translate: TranslateService,
    private toastr: ToastrImplService) {

    this.center = (JSON.parse(this.localStorageService.get('loggedUser'))).center;
    this.showDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.codebookService.getLanguageCodebook().subscribe(
      result => {
        this.languages = result;
      }
    );
    this.codebookService.getGenderCodebook().subscribe(
      result => {
        this.genderOptions = result;
      }
    );
    this.codebookService.getNationalityCodebook().subscribe(
      result => {
        this.nationalities = result;
        this.nationalitiesFiltered = this.firstStep.controls.nationality.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value?.title),
            map(title => title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalities.slice())
          );
      }
    );
    this.codebookService.getCitizenshipCodebook().subscribe(
      result => {
        this.citizenships = result;
        this.citizenshipsFiltered = this.firstStep.controls.citizenship.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value?.title),
            map(title => title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenships.slice())
          );
      }
    );
    this.codebookService.getEducationCodebook().subscribe(
      result => {
        this.educations = result;
      }
    );
    this.codebookService.getMaritalStatusCodebook().subscribe(
      result => {
        this.maritalStatuses = result;
      }
    );

    this.codebookService.getEmploymentCodebook().subscribe(
      result => {
        this.employments = result;
      }
    );

    this.codebookService.getRelationshipCodebookWithoutUser().subscribe(
      result => {
        this.relationships = result;
      }
    );
    this.codebookService.getOccupationCodebook().subscribe(
      result => {
        this.occupations = result;
        this.occupationsFiltered = this.firstStep.controls.occupation.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value?.title),
            map(title => title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupations.slice())
          );
      }
    );
  }

  houseInfo2Statuses: YesOrNoStatuses[] = [
    { value: YesOrNoEnum.DA, viewValue: 'Да' },
    { value: YesOrNoEnum.NE, viewValue: 'Не' }
  ];

  ngOnInit(): void {
    this.firstStep = this.formBuilder.group({
      center: [
        this.center.length > 0 ? this.center : 'FIX ME',
        [Validators.required /*, Validators.pattern('[a-zA-Z ]*')*/],
      ],
      nspFamilyRequestIdentity: ['', []],
      dateOfSubmission: [this.showDate, [Validators.required]],
      firstName: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
      lastName: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
      parentName: ['', [/* Validators.required, */Validators.pattern(myLetters.letters)]],
      dateOfBirth: ['', [/* Validators.required, */]],
      jmbg: ['', [/* Validators.required, */Validators.pattern('^[0-9]*$')]],
      placeOfBirth: ['', [/* Validators.required, */Validators.pattern(myLetters.letters)]],
      gender: ['', [/*Validators.required*/]],
      citizenship: ['', [/* Validators.required, Validators.pattern(myLetters.letters) */]],
      nationality: ['', [/* Validators.required, Validators.pattern(myLetters.letters) */]],
      address: ['', [/* Validators.required ,*/Validators.pattern(myLetters.letters)]],
      addressNumber: ['', [/* Validators.required, */Validators.pattern(myLetters.num_letters)]],
      emailAddress: ['', [/* Validators.required, */ Validators.email]],
      phone: ['',
        [ /* Validators.minLength(9),
          Validators.maxLength(13),*/
          Validators.pattern('^[0-9/-]*$')]],
      mobile: ['',
        [ /* Validators.minLength(9),
          Validators.maxLength(13),*/
          Validators.pattern('^[0-9/-]*$')],],
      relationship: ['', [/* Validators.required */]],
      maritalStatus: ['', [/* Validators.required */]],
      education: ['', [/* Validators.required, */]],
      occupation: ['', [/* Validators.required, */]],
      employmentStatus: ['', [/* Validators.required */]],
      employmentStatusOther: ['', [/* Validators.required */]],
      employment: ['', [/* Validators.required */]],
      employmentOther: ['', [/* Validators.required */]],
      employerName: ['', [/* Validators.required */]],
      independentAgency: ['', [/* Validators.required */]],
      agriculturist: ['', [/* Validators.required */]],
      foreigner: [false]
    });

  }

  autoSelectItem(arr: any[], input:string){      
    return autoSelect(arr, input, 'title', this.firstStep);
 }

  compareGenderObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id && object1.gender === object2.gender && object1.code === object2.code;
  }

  createNspFamilyRequestObject() {
    const submitData = {
      center: this.firstStep.value.center,
      id: this.firstStep.value.nspFamilyRequestIdentity,
      subjectId: this.subjectId,
      dateOfSubmission: this.firstStep.value.dateOfSubmission.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3/$2/$1'),
      firstName: this.firstStep.value.firstName,
      lastName: this.firstStep.value.lastName,
      parentName: this.firstStep.value.parentName,
      dateOfBirth: this.datePipe.transform(this.firstStep.value.dateOfBirth, 'dd/MM/yyyy'),
      foreigner: this.firstStep.value.foreigner,
      jmbg: this.firstStep.value.jmbg,
      placeOfBirth: this.firstStep.value.placeOfBirth,
      gender: this.firstStep.value.gender !== '' ? this.firstStep.value.gender : null,
      citizenship: this.firstStep.value.citizenship !== '' ? this.firstStep.value.citizenship : null,
      nationality: this.firstStep.value.nationality !== '' ? this.firstStep.value.nationality : null,
      address: this.firstStep.value.address,
      addressNumber: this.firstStep.value.addressNumber,
      phone: this.firstStep.value.phone,
      mobile: this.firstStep.value.mobile,
      relationship: this.relationships.filter(x => x.title === this.firstStep.value.relationship)[0],
      maritalStatus: this.maritalStatuses.filter(x => x.title === this.firstStep.value.maritalStatus)[0],
      education: this.educations.filter(x => x.title === this.firstStep.value.education)[0],
      occupation: this.firstStep.value.occupation !== '' ? this.firstStep.value.occupation : null,
      employment: this.employments.filter(x => x.title === this.firstStep.value.employment)[0],
      employmentOther: this.firstStep.value.employmentOther,
      employerName: this.firstStep.value.employerName,
      independentAgency: this.firstStep.value.independentAgency,
      agriculturist: this.firstStep.value.agriculturist,
    };

    return submitData;
  }

  saveNSPFamilyRequest() {
    const submitData = this.createNspFamilyRequestObject();
    if (submitData.relationship !== null&&submitData.relationship !== undefined) {

      if (!checkRelationshipAgeDifference(this.firstStep.value, this.subjectData.subject, submitData.relationship.id,
     this.toastr)) {
        return;
      }
    }

    if (submitData.jmbg !== '' && submitData.foreigner !== true) {
      this.checkJMBGRule();
      this.updateJMBGOnGenderChange('');
      this.updateJMBGOnDateOfBirthChange('');
    }
    if (!this.firstStep.valid) {
      return;
    }
    this.nspRequestService.addNspFamilyRequest(submitData).subscribe(
      (result) => {
        var file = new Blob([result], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        var anchor = document.createElement('a');
        anchor.download = 'НСП-П.pdf';
        anchor.href = fileURL;
        anchor.click();
        this.isSaved = true;
        this.toastr.success('SNACKBAR.NSP_P_SAVED');
        this.dialogRef.close({ event: 'SUCCESS' });
      }, () => {
        this.submitButton.disabled = false;
      }
    );
  }

  importSubjectData(): void {
    const dialogRef = this.dialog.open(SubjectsComponent, {
      width: '1200px',
      panelClass:'overlay-panel',
      data: {
        origin: 'entrance',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        const obj = JSON.parse(JSON.stringify(result));
        this.subjectId = result.data.subjectIdentity.subjectId;
        if (obj.data.dateOfBirth) {
            obj.data.dateOfBirth = new Date(obj.data.dateOfBirth);
        }
        const {
          data: {
            firstName,
            lastName,
            parentName,
            dateOfBirth,
            foreigner,
            jmbg,
            placeOfBirth,
            gender,
            citizenship,
            nationality,
            permanentResidence,
            phone,
            mobile,
            maritalStatus,
            education,
            occupation,
            employment,
          },
        } = obj;

        this.firstStep.patchValue({
          firstName,
          lastName,
          parentName,
          dateOfBirth: dateOfBirth ? dateOfBirth : null,
          foreigner,
          jmbg,
          placeOfBirth,
          gender,
          citizenship,
          nationality,
          address: permanentResidence.street,
          addressNumber: permanentResidence.number,
          phone,
          mobile,
          maritalStatus: maritalStatus?.title,
          education: education?.title,
          occupation,
          employment: employment?.title,
        });
        this.firstStep.markAllAsTouched();
        this.firstStep.controls.center.markAsUntouched();
        if (!foreigner) {
            this.checkJMBG('');
        }
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'CANCEL' });
  }

  // Brisanje sadrzaja zavisnih polja od ZAPOSLENJA, u zavisnosti od izabrane vrijednosti iz sifrarnika.
  clearEmploymentFields(event: any) {
    if (event.value !== 'Запослен' && event.value !== 'Радно ангажован') {
      this.firstStep.controls.employerName.reset();
      this.firstStep.controls.agriculturist.reset();
      this.firstStep.controls.independentAgency.reset();
    } else if (event.value !== 'Остало') {
      this.firstStep.controls.employmentOther.reset();
    }
  }

  printPreview() {
    const submitData = this.createNspFamilyRequestObject();
    this.nspRequestService.printFamilyRequestPreview(submitData);
  }


  updateJMBGOnDateOfBirthChange(event: any) {
    if (event != null && this.firstStep.value.foreigner !== true) {
      if (this.firstStep.value.dateOfBirth !== null && this.firstStep.value.jmbg !== null) {
        const birthDate = new Date(this.firstStep.value.dateOfBirth).getTime();
        const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
        const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
        const jmbg = this.firstStep.value.jmbg.substring(0, 7);

        if (this.firstStep.value.jmbg.length === 13) {
          if (newBirthDate !== jmbg) {
            this.firstStep.get('dateOfBirth').setErrors({ incorrect: true });
          } else {
            this.firstStep.get('dateOfBirth').setErrors(null);
          }
        }
      }
    }
  }
  updateJMBGOnGenderChange(event: any) {
    if (this.firstStep.value.foreigner === true) {
       return;
    }
    if (this.firstStep.value.jmbg !== null && this.firstStep.value.jmbg.length >= 12) {
      const jmbg = this.firstStep.value.jmbg.substring(9, 12);
      if (this.firstStep.get('gender').value !== null && this.firstStep.get('gender').value !== undefined) {
        if (this.firstStep.value.jmbg.length === 13 && jmbg >= '000' && jmbg <= '499') {
          if (this.firstStep.value.gender.code === 'F') {
            this.firstStep.get('gender').setErrors({ incorrect: true });
          } else {
            this.firstStep.get('gender').setErrors(null);
          }
        } else if (jmbg >= '500' && jmbg <= '999') {
          if (this.firstStep.value.gender.code === 'M') {
            this.firstStep.get('gender').setErrors({ incorrect: true });
          } else {
            this.firstStep.get('gender').setErrors(null);
          }
        }
      }
    }
  }

  /* Ukoliko je korisnik mladji od 15 godina ili stariji od 63/65 potrebno je setovati radni status = Radno nesposoban.
    Radni status = radno nesposoban je upisan sa ID brojem 6, a kako indeksiranje krenje od 0 podeseno je da se uzima 5 element.
    U slucaju promjene sifrarnika potrebno izmjeniti i ovu metodu.*/
  updateEmploymentData() {
    const dateOfBirth = new Date(this.firstStep.value.dateOfBirth);
    const dateOfSubmission = new Date(this.firstStep.value.dateOfSubmission);
    const time = dateOfSubmission.getTime() - dateOfBirth.getTime();
    const genderCharacters = this.firstStep.value.jmbg.substring(9, 12);
    const lowerLimit = 15;
    let upperLimitByGender = null;

    if (genderCharacters >= '000' && genderCharacters <= '499') {
      upperLimitByGender = 65;
    } else if (genderCharacters >= '500' && genderCharacters <= '999') {
      upperLimitByGender = 63;
    }
    const age = Math.floor((time / (1000 * 3600 * 24)) / 365);
    if (age < lowerLimit || age > upperLimitByGender) {
      this.firstStep.controls.employment.setValue(this.employments[5].title);
    }
  }

  checkJMBG(event: any) {
    if (this.firstStep.value.foreigner === true) {
        return;
    }
    this.checkJMBGRule();
    this.updateJMBGOnDateOfBirthChange(event);
    if (this.firstStep.value.jmbg === 13) {
      const x = this.subjectService.getSubject(this.firstStep.value.jmbg);
      x.subscribe(
        (res) => {
          if (res !== null && res !== '') {
            let message = '';
            this.translate.get('SNACKBAR.RECORD_CENTER').subscribe((resp: string) => {
              message = resp;
            });
            let response = '';
            this.translate.get('SNACKBAR.USER_EXIST').subscribe((resp: string) => {
              response = resp;
            });
            this.toastr.showErrorTitle(message + res.city, response);
          } else {
            this.toastr.error('SNACKBAR.USER_NOT_EXIST');
          }
        },
        () => {
          this.toastr.error('SNACKBAR.ERROR_OCCURRED');
        }
      );
    }
    this.updateJMBGOnGenderChange(event);
  }

  /**
   * _filter
   * @param value input value
   * @param what which filter should be applied (check AutocompleteFilterEnum)
   */
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();

    switch (what) {
      case AutocompleteFilterEnum.NATIONALITY:
        return sortByFirstLetter(this.nationalities.filter((option) =>
          option.title.toLowerCase().includes(filterValue)
        ), filterValue, 'title');
      case AutocompleteFilterEnum.CITIZENSHIP:
        return sortByFirstLetter(this.citizenships.filter((option) =>
          option.title.toLowerCase().includes(filterValue)
        ), filterValue, 'title');
      case AutocompleteFilterEnum.OCCUPATION:
        return sortByFirstLetter(this.occupations.filter((option) =>
          option.title.toLowerCase().includes(filterValue)
        ), filterValue, 'title');
      default:
        break;
    }
  }


  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.title : '';
  }

  private checkJMBGRule() {
    if (this.firstStep.value.jmbg !== null && this.firstStep.value.jmbg !== '') {
      if (this.firstStep.value.jmbg.length < 13) {
        this.firstStep.get('jmbg').setErrors({ controlNumber: true });
      } else {
        const jmbg = this.firstStep.value.jmbg;
        const digits = jmbg.split('').map(Number);
        const delimiter = 11;
        let sum = 0;
        let start = 7;
        for (let index = 0; index < digits.length - 1; index++, start--) {
          if (start === 1) {
            start = 7;
          }
          sum += start * digits[index];
        }
        let controlNumber = sum % delimiter;
        if (controlNumber > 1) {
          controlNumber = delimiter - controlNumber;
        }
        if (controlNumber !== digits[digits.length - 1]) {
          this.firstStep.get('jmbg').setErrors({ controlNumber: true });
          setTimeout(() => {
            this.firstStep.get('jmbg').setErrors({ controlNumber: true });
            this.firstStep.controls.jmbg.markAsTouched();
          });
        }
      }
    }
  }
}
