<form [formGroup]="centerStep" class="form">
  <form-group title="{{
      'NEW_FOSTER_PARENT.CENTER.GUARDIANSHIP_AUTHORITY' | translate
    }}" isLast="true">
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.GUARDIANSHIP_NAME' | translate
        }}</mat-label>
          <input matInput type="text" formControlName="guardianshipName" required autocomplete="off" />
          <mat-error *ngIf="centerStep.get('guardianshipName').hasError('required')">
            {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.ADDRESS' | translate
        }}</mat-label>
          <input matInput type="text" formControlName="address" autocomplete="off" />
          <mat-error>
            {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.PHONE' | translate
        }}</mat-label>
          <input matInput type="text" formControlName="phone"  autocomplete="off" />
          <mat-error *ngIf="centerStep.get('phone').hasError('pattern')">
            {{ 'NEW_FOSTER_PARENT.FORM.PHONE_ERROR' | translate }}
          </mat-error>

          <mat-error >
            {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.EMAIL_ADDRESS' | translate
        }}</mat-label>
          <input matInput type="text" formControlName="emailAddress" autocomplete="off" />
          <mat-error *ngIf="centerStep.get('emailAddress').hasError('email')">
            {{ 'NEW_FOSTER_PARENT.FORM.EMAIL_PATTERN_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>

    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{'NEW_FOSTER_PARENT.FORM.QUESTIONNAIRE_NUMBER' | translate}}</mat-label>
        <input matInput type="text" formControlName="questionnaireNumber"  autocomplete="off" />
        <mat-error >
          {{ 'NEW_FOSTER_PARENT.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_FOSTER_PARENT.FORM.QUESTIONNAIRE_DATE' | translate
        }}</mat-label>
        <input matInput [matDatepicker]="questionnaireDate" [max]="currentDate" formControlName="questionnaireDate"
          autocomplete="off"  />
        <mat-datepicker-toggle matSuffix [for]="questionnaireDate"></mat-datepicker-toggle>
        <mat-datepicker #questionnaireDate></mat-datepicker>
        <mat-error>
          {{ 'NEW_FOSTER_PARENT.FORM.DATE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>

  </form-group>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_FOSTER_PARENT.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-flat-button color="primary"  [disabled]="!centerStep.valid || !suitabilityStep.valid || !personalInformationStep.valid " class="form-footer__button"
      (click)="updateCenterStep()">
      {{ 'NEW_FOSTER_PARENT.FINISH' | translate }}
    </button>
  </form-footer>
</form>