/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Pageable } from 'src/app/pageable';
import * as printJS from 'print-js';
/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 *
 */
@Injectable({
  providedIn: 'root'
})
export class AbusedPersonService {

  constructor(private httpClient: HttpClient, private app: AppService) { }

  createAbusedPerson(abusedPerson: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json;'
    });
    return this.httpClient.post(this.app.getUrl() + environment.addAbusedPerson, abusedPerson, { headers }) as Observable<any>;
  }


  getFilteredPage(pageable: Pageable, filterValue: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.findAllAbusedPerson + '/' + pageable.pageNumber
      + '/' + pageable.pageSize, filterValue, { headers }) as Observable<any>;

  }

  closeCollection(year: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.closeAbusedPersonCollection + '/' + year, { headers }) as Observable<any>;
  }

  exportCSV(filterValue: any) {
    const filterJSON = JSON.parse(JSON.stringify(filterValue));
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    this.httpClient
      .put(this.app.getUrl() + environment.exportAbusedPersonCsvUrl, filterJSON, { responseType: 'blob', headers }).subscribe(data => {
        const parts = [];
        parts.push('\uFEFF');
        parts.push(data);
        const file = new Blob(parts, { type: 'text/csv' });
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = fileURL;
        a.download = 'lica_prema_kojima_je_izvrseno_nasilje.csv';
        a.click();
        window.URL.revokeObjectURL(fileURL);
        a.remove();
      });
  }

  findByIdAndCsrId(id: any, year: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.get(this.app.getUrl() + environment.findAbusedPersonById + '/' + id, { headers }) as Observable<any>;
  }

  getPdf(filterValue: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    this.httpClient
      .put(this.app.getUrl() + environment.getAbusedPersonReportUrl, filterValue, { responseType: 'blob', headers })
      .subscribe(data => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        printJS(fileURL);
      });
  }

}
