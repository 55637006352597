<form [formGroup]="pocketMoneyPercentageForm" class="form">
    <field-holder kind="4-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'GLOBAL_ADMIN.FORM.NEW_VALUE' | translate
        }}</mat-label>
        <input matInput type="number" formControlName="value" autocomplete="off" required />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}</mat-label>
        <input matInput type="text" [matDatepicker]="pocketMoneyPercentageValidFrom" formControlName="validFrom"
          autocomplete="off" placeholder="{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}" required [max]="maxDate" />
        <mat-datepicker-toggle matSuffix [for]="pocketMoneyPercentageValidFrom"></mat-datepicker-toggle>
        <mat-datepicker #pocketMoneyPercentageValidFrom></mat-datepicker>
      </mat-form-field>
    </field-holder>
    <form-footer>
      <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
        (click)="submitPocketMoneyPercentageForm(pocketMoneyPercentageForm)"
        [disabled]="!pocketMoneyPercentageForm.valid">
        {{ 'GLOBAL_ADMIN.FORM.SUBMIT' | translate }}
      </button>
    </form-footer>
  </form>