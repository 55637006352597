/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalEventEnum } from 'src/types';

interface DocumentData {
  document: any;
  action: 'cancel' | 'confirm';
  message: string;
  title: string;
  saveData: boolean;
  singleButtonMode?: boolean;
}

@Component({
  selector: 'warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss']
})
export class WarningMessageComponent implements OnInit {
  action: string;
  data: any;
  message: string;
  title: string;
  saveData: boolean;
  singleButtonMode: boolean;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public document: DocumentData
    ) { }

  ngOnInit(): void {
    this.data = this.document.document;
    this.action = this.document.action;
    this.message = this.document.message;
    this.title = this.document.title;
    this.saveData = this.document.saveData;    
    this.singleButtonMode = this.document.singleButtonMode || false;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }

  applyAction() {
    if (this.action === ModalEventEnum.DELETE) {
      this.dialogRef.close({ event: this.action, data: { status: true, id: this.data?.id } });
    } else {
      this.dialogRef.close({ event: this.action, data: { status: true, id: null } });
    }
  }

}
