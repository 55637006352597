/**
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */


import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormGroup, FormBuilder, Validators, ValidationErrors, ValidatorFn,
  AbstractControl, FormControl, FormGroupDirective, NgForm
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { UserService } from 'src/services/user.service';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  fieldTextType = false;
  matcher = new MyErrorStateMatcher();

  constructor(public dialogRef: MatDialogRef<ResetPasswordComponent>,
    public userService: UserService,
    @Optional() @Inject(MAT_DIALOG_DATA) public userId: any,
    private formBuilder: FormBuilder) {

    this.resetPasswordForm = this.formBuilder.group({
      password1: [null, [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]],
      password2: [null, [Validators.required, Validators.pattern(/^(?!.*[\u0400-\u04FF\u0100-\u017F])/)]]
    }, { validators: this.checkPasswords });
  }

  ngOnInit(): void {
  }

  resetPassword(form) {
    this.userService.resetPassword(btoa(form.get('password1').value), this.userId).subscribe(
      () => {
        this.dialogRef.close({ event: 'SUCCESS' });
      });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password1').value;
    let confirmPass = group.get('password2').value
    return pass === confirmPass ? null : { notSame: true }
  }

}
