/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'new-adopted-child-other-information-step',
  templateUrl: './new-adopted-child-other-information-step.component.html',
  styleUrls: ['./new-adopted-child-other-information-step.component.scss']
})
export class NewAdoptedChildOtherInformationStepComponent implements OnInit {
  @Output() otherInformationStepEmitter = new EventEmitter();
  @Input() otherInformationStep: FormGroup;
  currentDate = new Date();
  constructor(
    private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.otherInformationStep = this.formBuilder.group({
      typeOfRelationship: ['', [Validators.required]],
      decisionNumber: ['', [Validators.required]],
      decisionDate: ['', [Validators.required]],
      decisionNumberNewBirthRegistration: ['', [Validators.required]],
      decisionDateNewBirthRegistration: ['', [Validators.required]],
      annulmentOfAdoption: [''],
      holderOfMaintenanceObligation: [''],
      note: ['']
    });
  }

  /**
   * Send step information to parent component
   */
  updateOtherInformationStep() {
    this.otherInformationStepEmitter.emit(this.otherInformationStep);
  }

}
