/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
   selector: 'document-item-evaluation',
   templateUrl: './document-item-evaluation.component.html',
   styleUrls: ['./document-item-evaluation.component.scss'],
})
export class DocumentItemEvaluationComponent implements OnInit {
   @Input() title: string;
   @Input() meta: string;

   @Output() handleClick = new EventEmitter();
   @Output() handleDownload = new EventEmitter();
   @Output() handlePrint = new EventEmitter();
   constructor() {}

   ngOnInit(): void {}
   handleItemClick(): void {
      // Open the document (handled by parent)
      this.handleClick.next();
   }
   handleDownloadClick(): void {
      // Open the document (handled by parent)
      this.handleDownload.next();
   }
   handlePrintItem() {
      this.handlePrint.next();
   }
}
