<container kind="mini">
    <page-intro-header title="{{ 'VS.CREATE_TEAM' | translate }}"></page-intro-header>
    <div>
        <field-holder>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ "VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.TARGETED_EVALUATION" | translate }}
            </mat-label>
            <textarea matInput type="text" [formControl]="explanation" required autocomplete="off"></textarea>
          </mat-form-field>
        </field-holder>
        <mat-dialog-actions align = "start">
          <footer class = "footer-controls">
            <button mat-stroked-button mat-dialog-close color="primary" (click)="closeDialog()" type="button"
            class="form-footer__button form-footer__button--secondary">
            {{ "VS.CANCEL" | translate }}
            </button>
            <button mat-flat-button color="primary" type="submit" (click)="sendRequest()" class="form-footer__button form-footer__button--secondary" [disabled]="explanation.hasError('required')">
              {{ "VS.INITIAL_ASSESSMENT.FORMS23.STEP_08.SEND_REQUEST" | translate }}
            </button>
          </footer>
        </mat-dialog-actions>
    </div>
</container>
