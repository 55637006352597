import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { SubmissionService } from 'src/services/submission.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.scss']
})
export class SubmissionsComponent implements OnInit {
  loggedIn: any;
  submissions: any = [];
  isUserEntrancer = false;
  sub: any = [];
  constructor(
    private localStorageService: LocalStorageService,
    private submissionService: SubmissionService,
    private dataSharing: DataSharingService,
    private cdr: ChangeDetectorRef,
    private userService: UserService) {

    this.isUserEntrancer = this.userService.isUserEntrancer();
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));

    this.dataSharing.newSubmission.subscribe(
      value => {
        if (value) {
          this.findSubmissions(this.loggedIn.id);
          this.cdr.detectChanges();
        }
      }
    );
    this.findSubmissions(this.loggedIn.id);

  }

  ngOnInit(): void {
  }

  findSubmissions(assignedUserId: any) {
    this.submissionService.findByCenter()
      .subscribe(res => {
        this.submissions = res;
        this.cdr.detectChanges();
      });
  }
}
