<container>
   <page-intro-header title="{{ 'SECOND_DEGREE_CASES.TITLE' | translate }}">
   </page-intro-header>
   <mat-card class="card">
      <card-header title="{{ 'GLOBAL_ADMIN.CASES.TITLE' | translate }}">
         <div class="card__aside">
            <form [formGroup]="filter">
               <mat-form-field appearance="outline" class="search centar-search">
                  <mat-label>
                     {{ 'GLOBAL.CENTER' | translate }}
                  </mat-label>
                  <input type="text" matInput [matAutocomplete]="autoSubcenter" formControlName="subcenter"
                     (input)="checkInput()" (focusout)="autoSelectCenter()"/>
                  <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete"
                     [displayWith]="displayCustomFormat" (optionSelected)="reloadCases($event.option.value,'center')">
                     <mat-option *ngFor="let cent of filteredSubcenters | async" [value]="cent">
                        {{ cent.name }}
                     </mat-option>
                  </mat-autocomplete>
               </mat-form-field>
               <mat-form-field appearance="outline" class="search centar-search">
                  <mat-label>
                     {{ 'SECOND_DEGREE_CASES.CLASSIFICATION' | translate }}
                  </mat-label>
                  <input type="text" matInput [matAutocomplete]="autoClassification" formControlName="classification"
                     (input)="checkInput()" (focusout)="autoSelectClassification()"/>
                  <mat-autocomplete panelWidth="auto" #autoClassification="matAutocomplete" class="classification"
                     [displayWith]="displayCustomFormatClassification"
                     (optionSelected)="reloadCases($event.option.value,'classification')">
                     <mat-option *ngFor="let cent of filteredcaseClassifications | async" [value]="cent">
                        {{ cent.code+' '+ cent.title }}
                     </mat-option>
                  </mat-autocomplete>
               </mat-form-field>
               <mat-form-field appearance="outline" class="search">
                  <mat-label>{{ 'SUBJECT_CASES.SEARCH' | translate }}</mat-label>
                  <input matInput title="{{ 'SUBJECT_CASES.TOOLTIP_TEXT_FILTER' | translate }}"
                     formControlName="searchValue" type="text" class="col-8 text-center"
                     (keyup.enter)="onEnterClickAddFilter()" />
               </mat-form-field>
            </form>
         </div>
      </card-header>
      <mat-chip-list #chipList aria-label="Applied filters" [class]="appliedTextFilters.length > 0 ? 'mb-30' : ''">
         <mat-chip *ngFor="let textFilter of appliedTextFilters" (removed)="removeTextFilter(textFilter)">
            {{ textFilter }}
            <mat-icon matChipRemove>cancel</mat-icon>
         </mat-chip>
      </mat-chip-list>
      <div class="table-responsive-wrapper" *ngIf="page.content.length > 0 && areLoaded">
         <table mat-table [dataSource]="page.content" #mytable
            class="subject-case-table">
            <ng-container matColumnDef="case">
               <th mat-header-cell *matHeaderCellDef>
                  {{ 'SUBJECT_CASES.TABLE.CASE_NUMBER' | translate }}
               </th>
               <td mat-cell *matCellDef="let element">
                  {{ element?.caseNumber }}
               </td>
            </ng-container>
            <ng-container matColumnDef="centerName">
               <th mat-header-cell *matHeaderCellDef>
                  {{ 'SUBJECT_CASES.TABLE.CENTER' | translate }}
               </th>
               <td mat-cell *matCellDef="let element">
                  {{ element?.centerName }}
               </td>
            </ng-container>
            <ng-container matColumnDef="subjectId">
               <th mat-header-cell *matHeaderCellDef>
                  {{ 'SUBJECT_CASES.TABLE.SUBJECT_ID' | translate }}
               </th>
               <td mat-cell *matCellDef="let element">
                  {{ element?.subjectId }}
               </td>
            </ng-container>
            <ng-container matColumnDef="subjectInfo">
               <th mat-header-cell *matHeaderCellDef>
                  {{ 'SUBJECT_CASES.TABLE.SUBJECT' | translate }}
               </th>
               <td mat-cell *matCellDef="let element">
                  {{ element?.subjectFirstName + ' ' + element?.subjectlastName }}
               </td>
            </ng-container>
            <ng-container matColumnDef="status">
               <th mat-header-cell *matHeaderCellDef>
                  {{ 'SUBJECT_CASES.TABLE.CASE_STATUS' | translate }}
               </th>
               <td mat-cell *matCellDef="let element">
                  <div *ngIf="
                element.intoPlanner === false &&
                element.newComplain === true &&
                element.secondDegree !== true
              ">
                     {{ translateStatus('COMPLAIN') }}
                  </div>
                  <div *ngIf="
                element.intoPlanner === false &&
                element.newComplain !== true &&
                element.secondDegree === true
              ">
                     {{ translateStatus('SECOND_DEGREE') }}
                  </div>
                  <div *ngIf="
                element.intoPlanner === false &&
                element.newComplain !== true &&
                element.secondDegree !== true
              ">
                     {{ translateStatus(element.caseStatus) }}
                  </div>
                  <div *ngIf="element.intoPlanner === true">
                     <status-label status="2">
                        {{ 'SEND_INTO_PLANNER.INTO_PLANNER' | translate }}
                     </status-label>
                  </div>
               </td>
            </ng-container>
            <ng-container matColumnDef="userInfo">
               <th mat-header-cell *matHeaderCellDef>
                  {{ 'SECOND_DEGREE_CASES.WORKER' | translate }}
               </th>
               <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element?.userFirstName || element?.userLastName">
                     {{ element?.userFirstName + ' ' + element?.userLastName }}
                  </ng-container>
               </td>
            </ng-container>
            <ng-container matColumnDef="actions">
               <th mat-header-cell *matHeaderCellDef></th>
               <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
                  <button mat-icon-button class="button-square table__row-button" color="primary"
                     matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
                     #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                     <mat-icon class="button-square__icon">more_horiz</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="filter-menu">
                     <button mat-menu-item (click)="checkSubject(element)">
                        <mat-icon class="button-square__icon">portrait</mat-icon>
                        {{ 'SUBJECT_CASES.TABLE.SUBJECT_DETAILS' | translate }}
                     </button>
                     <button mat-menu-item (click)="checkCase(element)">
                        <mat-icon class="button-square__icon">library_books</mat-icon>
                        {{ 'SUBJECT_CASES.TABLE.SUBJECT_CASE_DETAILS' | translate }}
                     </button>
                  </mat-menu>
               </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="subject-case-table__row"></tr>
         </table>
      </div>

      <div class="pagination" *ngIf="page.content.length > 0">
         <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()"
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()" (pageSizeEvent)="getPageInNewSize($event)">
         </app-custom-pagination>
      </div>

      <p class="subject-case-empty" *ngIf="page.content.length === 0 && areLoaded">
         <mat-icon class="subject-case-empty__icon subject-case-empty__icon--empty">info</mat-icon>
         {{ 'SUBJECT_CASES.NO_RESULTS' | translate }}
      </p>
      <mat-progress-spinner *ngIf="!areLoaded" color="primary" mode="indeterminate">
      </mat-progress-spinner>
   </mat-card>
</container>