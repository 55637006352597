/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalEventEnum } from 'src/types';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'new-dependent-table-six',
  templateUrl: './new-dependent-table-six.component.html',
  styleUrls: ['./new-dependent-table-six.component.scss']
})
export class NewDependentTableSixComponent implements OnInit {
  newRecord: FormGroup;
  currentDate = new Date();
  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.newRecord = this.formBuilder.group({
      courtName: ['', [Validators.required]],
      courtNumber: ['', [Validators.required]],
      courtDate: ['', [Validators.required]],
      dateOfVerdictTermination: ['', [Validators.required]],
      reasonsForVerdictTermination: ['', [Validators.required]],
    });
  }

  addNewRecord(trackingDocumentForm: FormGroup) {
    trackingDocumentForm.get('dateOfVerdictTermination').setValue(
      this.datePipe.transform(new Date(trackingDocumentForm.value.dateOfVerdictTermination).getTime(), 'yyyy-MM-dd'));
    trackingDocumentForm.get('courtDate').setValue(
          this.datePipe.transform(new Date(trackingDocumentForm.value.courtDate).getTime(), 'yyyy-MM-dd'));
    this.dialogRef.close({
      event: ModalEventEnum.SUCCESS,
      data: trackingDocumentForm.value,
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: ModalEventEnum.CANCEL });
  }
}
