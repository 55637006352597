import { ModalSizeEnum } from "src/types";
import { WarningMessageComponent } from "../warning-message/warning-message.component";
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export const fileCheck = (files: FileList,dialog: MatDialog,translate: TranslateService) => {
    let alertTypeMessage = '';
    let alertTypeTitle = '';

    translate.get('LAWYER.DOCUMENT_TYPE_ALERT').subscribe((res: string) => {
       alertTypeMessage = res;
    });

    translate.get('LAWYER.DOCUMENT_TYPE_ALERT_TITLE').subscribe((res: string) => {
       alertTypeTitle = res;
    });

    const validTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const validExtensions = ['.docx', '.pdf'];

    const modifiedFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
       const file = files[i];
       const fileExtension = file.name.split('.').pop().toLowerCase();

       if (!validTypes.includes(file.type) && !validExtensions.includes('.' + fileExtension)) {
          const object = {
             document: null,
             message: alertTypeMessage,
             title: alertTypeTitle,
             saveData: true,
             singleButtonMode: true,
          };

          const dialogRef = dialog.open(WarningMessageComponent, {
             data: object,
             width: ModalSizeEnum.MINI,
             panelClass: 'overlay-panel',
          });

          return;
       }
       if (fileExtension === 'docx') {
          const modifiedFile = new File([file], file.name, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          modifiedFiles.push(modifiedFile);
       } else {
          modifiedFiles.push(file);
       }
    }
    return modifiedFiles;
    
 };