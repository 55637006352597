/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { Pageable } from 'src/app/pageable';
import { Page } from 'src/app/page';
import { SocialCase } from 'src/models/socialCase.model';
import * as printJS from 'print-js';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ToastrImplService } from './toastr.service';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';

@Injectable({
   providedIn: 'root',
})
export class CaseService {
   constructor(private httpClient: HttpClient, private app: AppService, private localStorage: LocalStorageService, private toastr: ToastrImplService) {}

   acceptCase(caseId: number) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.acceptCaseByIdUrl, caseId, { headers }) as Observable<any>;
   }

   declineCase(caseId: number) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.getDeclineCaseByIdUrl, caseId, { headers }) as Observable<any>;
   }

   assignCase(caseId: string, assignedDto) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.assignCaseUrl + '/' + caseId, assignedDto, { headers }) as Observable<any>;
   }

   forwardCase(caseId: string, forwardDto) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.forwardCaseUrl + '/' + caseId, forwardDto, { headers }) as Observable<any>;
   }

   returnCase(caseId: string, forwardDto) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.returnCase + '/' + caseId, forwardDto, { headers }) as Observable<any>;
   }

   assignTeam(caseId: string, teamMembers: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.assignTeamUrl + caseId, teamMembers, { headers }) as Observable<any>;
   }

   getCases(primaryRole) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCasesUrl + '?primaryRole=' + primaryRole, { headers }) as Observable<any>;
   }

   findCaseByCaseNumber(pageable: Pageable, caseNumber: any, csrId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      let params = new HttpParams().set('caseNumber', caseNumber).set('csrId', csrId);

      return this.httpClient.get(this.app.getUrl() + environment.findCaseByCaseNumber + '/' + pageable.pageNumber + '/' + pageable.pageSize, {
         params,
      }) as Observable<any>;
   }
   findCaseForSecondDegree(pageable: Pageable, caseDetail: any, csrId: any, classification: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      const params = new HttpParams().set('caseDetail', caseDetail).set('csrId', csrId).set('classification', classification);

      return this.httpClient.get(this.app.getUrl() + environment.findCaseForSecondDegree + '/' + pageable.pageNumber + '/' + pageable.pageSize, {
         headers,
         params,
      }) as Observable<any>;
   }

   getUnassignedCases() {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getUnassignedCasesUrl, { headers }) as Observable<any>;
   }

   getCasesBySubjectId(subjectId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCasesBySubjectUrl + '/' + subjectId, { headers }) as Observable<any>;
   }
   getActiveCasesBySubjectId(subjectId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getActiveCasesBySubjectUrl + '/' + subjectId, { headers }) as Observable<any>;
   }
   getFilteredCasesByIdSubject(pageable: Pageable, subjectId: any, csrId: any, filter: any): Observable<Page<SocialCase>> {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(
         this.app.getUrl() + environment.getFilteredByUserId + '/' + subjectId + '/' + csrId + '/' + pageable.pageNumber + '/' + pageable.pageSize,
         filter,
         { headers }
      ) as Observable<any>;
   }

   addCaseRight(caseRights: any): Observable<object> {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.addCaseRights, caseRights, { headers }) as Observable<any>;
   }

   checkExistRightForCaseId(caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.checkExistForCaseId + '/' + caseId, { headers }) as Observable<any>;
   }

   addCaseProblem(caseProblems: any): Observable<object> {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.addCaseProblems, caseProblems, { headers }) as Observable<any>;
   }

   getAllProblemIds(subjectId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAllProblemIds + '/' + subjectId, { headers }) as Observable<any>;
   }
   getAllProblemIdsForCase(caseId) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getAllProblemIdsForCase + '?caseId=' + caseId, { headers }) as Observable<any>;
   }

   getSocialCase(socialCaseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getCaseByIdUrl + '/' + socialCaseId, { headers }) as Observable<any>;
   }

   getSocialCaseDetails(socialCaseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getDetailsByIdUrl + socialCaseId, { headers }) as Observable<any>;
   }

   assignedCasesStatus() {
      return this.httpClient.get(this.app.getUrl() + environment.assignedCasesStatus, { responseType: 'json' }) as Observable<any>;
   }

   submitNspCase(caseId: any, nsp: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.submitNspUrl + caseId, nsp, { headers }) as Observable<any>;
   }
   saveDataNspCase(caseId: any, nsp: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.saveDataNspUrl + caseId, nsp, { headers }) as Observable<any>;
   }

   getFamilyMembersCase(subjectId: any, memberId: any, csrId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.findAllFamilyMembers
         + subjectId + '/' + memberId + '/' + csrId, { headers }) as Observable<any>;
   }
   closeCase(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.closeCaseUrl + caseId) as Observable<any>;
   }

   findByCaseProblem(caseProblem: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.findByCaseProblemUrl, caseProblem, { headers }) as Observable<any>;
   }

   nspNmPreview(caseId: any, printObject: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });

      this.httpClient.put(this.app.getUrl() + environment.printPreviewNspNmCaseUrl + caseId, printObject, { responseType: 'blob', headers }).subscribe(
         data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         },
         error => {
            manageError(this.toastr, ErrorType.PREVIEW_DOC);
         }
      );
   }

   findAllWorkers() {
      return this.httpClient.get(this.app.getUrl() + environment.findEmployeesUrl) as Observable<any>;
   }

   addConclusionFinishCase(fileToUpload: File, subjectId: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      return this.httpClient.post(this.app.getUrl() + environment.addConclusionFinishCase + '/' + subjectId + '/' + caseId, formData, {
         responseType: 'text',
      }) as Observable<any>;
   }

   saveAssuranceCase(fileToUpload: File, subjectId: any, caseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      return this.httpClient.post(this.app.getUrl() + environment.createAssurance + '/' + subjectId + '/' + caseId, formData, {
         responseType: 'text',
      }) as Observable<any>;
   }

   return(caseId: any) {
      return this.httpClient.delete(this.app.getUrl() + environment.returnCaseUrl + '?caseId=' + caseId) as Observable<any>;
   }

   findNspFormById(socialCaseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.get(this.app.getUrl() + environment.getDataForNspFormUrl + '/' + socialCaseId, { headers }) as Observable<any>;
   }

   approveInitialAssesment(caseId) {
      return this.httpClient.get(this.app.getUrl() + environment.approveInitialAssesmentUrl + '?caseId=' + caseId) as Observable<any>;
   }

   submitPmnRequest(caseId, formData) {
      return this.httpClient.post(this.app.getUrl() + environment.submitPmnRequestUrl + caseId, formData, { responseType: 'text' }) as Observable<any>;
   }

   submitPmnAcceptance(caseId, formData) {
      return this.httpClient.post(this.app.getUrl() + environment.submitPmnAcceptanceUrl + caseId, formData, { responseType: 'text' }) as Observable<any>;
   }

   closePmn(closeDto) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.closePmnUrl, closeDto, { headers }) as Observable<any>;
   }

   checkIsActiveRight(subjectId) {
      return this.httpClient.get(this.app.getUrl() + environment.isActiveRightUrl + subjectId, { responseType: 'text' }) as Observable<any>;
   }

   sendIntoPlanner(caseId: any, plannerReason: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.sendIntoPlanner + '/' + caseId, plannerReason, { headers }) as Observable<any>;
   }

   teamMemberFinished(caseId: any, userId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.teamMemberFinished + caseId, userId, { headers }) as Observable<any>;
   }

   saveNspCase(nspObject: any) {
      return this.httpClient.post(this.app.getUrl() + environment.saveNspCase, nspObject) as Observable<any>;
   }

   previewNspDecision(caseId: any, nspObject: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      this.httpClient.put(this.app.getUrl() + environment.previewNspDecision + '/' + caseId, nspObject, { responseType: 'blob', headers }).subscribe(
         data => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            printJS(fileURL);
         },
         error => {
            manageError(this.toastr, ErrorType.PREVIEW_DOC);
         }
      );
   }
   saveLocalCase(localCaseCreateDto: any) {
      return this.httpClient.post(this.app.getUrl() + environment.saveLocalCase, localCaseCreateDto) as Observable<any>;
   }

   deleteCaseProblem(caseProblems: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.deleteCaseProblems, caseProblems, { headers }) as Observable<any>;
   }
   deleteCaseRights(caseRightId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.delete(this.app.getUrl() + environment.deleteCaseRights + '/' + caseRightId, { headers }) as Observable<any>;
   }

   saveNspConclusion(nspCaseId: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.saveNspConclusion + nspCaseId, null, { responseType: 'text', headers }) as Observable<any>;
   }

   getTeamMembersForCase(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.getTeamMembersNames + caseId) as Observable<any>;
   }

   checkIfHaveActiveRight(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.checkIfHaveActiveRight + '/' + caseId) as Observable<any>;
   }

   hasProblem(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.hasProblem + '?caseId=' + caseId) as Observable<any>;
   }

   findAsTeamMemberById(workerId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.findAsTeamMemberById + '/' + workerId) as Observable<any>;
   }

   findCounclersCases(workerId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.findCounclersCases + '/' + workerId) as Observable<any>;
   }

   findNspBySocialCaseId(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.findNspBySocialCaseId + caseId) as Observable<any>;
   }

   findLocalBySocialCaseId(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.findLocalCaseBySocialCaseId + caseId) as Observable<any>;
   }

   addCaseComplainResponse(caseId: any, complainDecision: any) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.post(this.app.getUrl() + environment.addCaseComplainResponse + caseId, complainDecision, { headers }) as Observable<any>;
   }

   getCaseTeamMembers(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.getCaseTeamMembers + caseId) as Observable<any>;
   }

   closeWithoutChange(caseId: number) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.closeWithoutChange + caseId, { headers }) as Observable<any>;
   }

   findAmountForNsp(caseId: any, date: any) {
      return this.httpClient.get(this.app.getUrl() + environment.findAmountForNsp + caseId + '/' + date) as Observable<any>;
   }

   getCaseManagerNameForCase(caseId: any) {
      return this.httpClient.get(this.app.getUrl() + environment.getCaseManagerNameForCase + caseId, { responseType: 'text' }) as Observable<any>;
   }

   declineTeamRequest(caseId: number) {
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.declineTeamRequest + caseId, { headers }) as Observable<any>;
   }

   markAsReadCase(caseId: number){
      const headers = new HttpHeaders({
         'Content-type': 'application/json',
      });
      return this.httpClient.put(this.app.getUrl() + environment.markAsReadCase + caseId, { headers }) as Observable<any>;
   }
}
