<nav class="mat-elevation-z3">
  <ul [ngClass]="{
      sidebar__list: true,
      'sidebar__list--hidden': sidebarExpanded
    }">
    <li>
      <button mat-icon-button class="button-square button-square--large button-sidebar button-sidebar--top"
        color="primary" matTooltip="{{ 'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.TITLE' | translate }}"
        (click)="handleSidebar('otherInstitutions')" [disabled]="disabledTabs.otherInstitutions">
        <mat-icon class="button-square__icon">account_balance</mat-icon>
      </button>
    </li>
    <li>
      <button mat-icon-button class="button-square button-square--large button-sidebar button-sidebar--top"
        color="primary" matTooltip="{{ 'SIDEBAR_WIDGET.DOCUMENTS' | translate }}" (click)="handleSidebar('documents')"
        [disabled]="disabledTabs.documents">
        <mat-icon class="button-square__icon">insert_drive_file</mat-icon>
      </button>
    </li>
    <li>
      <button mat-icon-button class="button-square button-square--large button-sidebar button-sidebar--middle"
        color="primary" matTooltip="{{ 'SIDEBAR_WIDGET.SUBJECTS' | translate }}" (click)="handleSidebar('subjects')"
        [disabled]="disabledTabs.subjects">
        <mat-icon class="button-square__icon">portrait</mat-icon>
      </button>
    </li>
    <li>
      <button mat-icon-button class="button-square button-square--large button-sidebar button-sidebar--bottom"
        color="primary" matTooltip="{{ 'SIDEBAR_WIDGET.CASES' | translate }}" (click)="handleSidebar('cases')"
        [disabled]="disabledTabs.cases">
        <mat-icon class="button-square__icon">dataset</mat-icon>
      </button>
    </li>
    <li>
      <button mat-icon-button class="button-square button-square--large button-sidebar button-sidebar--bottom"
        color="primary" matTooltip="{{ 'SIDEBAR_WIDGET.TRACKING' | translate }}" (click)="handleSidebar('tracking')"
        [disabled]="disabledTabs.tracking">
        <mat-icon class="button-square__icon">track_changes</mat-icon>
      </button>
    </li>
  </ul>
  <div [ngClass]="{
      'mat-elevation-z2': true,
      'sidebar__tabs-wrapper': true,
      'sidebar__tabs-wrapper--hidden': sidebarExpanded === false
    }">
    <button matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}" mat-icon-button (click)="closeSidebar()"
      class="sidebar__close">
      <mat-icon>close</mat-icon>
    </button>
    <mat-tab-group  [selectedIndex]="activeTab" animationDuration="0ms" mat-align-tabs="center">
      <mat-tab [disabled]="disabledTabs.otherInstitutions">
        <ng-template mat-tab-label>
          <mat-icon class="sidebar__tab-icon">account_balance</mat-icon>
          {{ 'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.TITLE' | translate }}
        </ng-template>
        <div class="sidebar__tab-content">
          <other-institutions></other-institutions>
        </div>
      </mat-tab>
      <mat-tab [disabled]="disabledTabs.documents">
        <ng-template mat-tab-label>
          <mat-icon class="sidebar__tab-icon">insert_drive_file</mat-icon>
          {{ 'SIDEBAR_WIDGET.DOCUMENTS' | translate }}
        </ng-template>
        <ng-template matTabContent>
          <div class="sidebar__tab-content">
            <subject-documents></subject-documents>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="disabledTabs.subjects">
        <ng-template mat-tab-label>
          <mat-icon class="sidebar__tab-icon">portrait</mat-icon>
          {{ 'SIDEBAR_WIDGET.SUBJECTS' | translate }}
        </ng-template>
        <ng-template matTabContent>
          <div class="sidebar__tab-content">
            <side-subjects></side-subjects>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="disabledTabs.cases">
        <ng-template mat-tab-label>
          <mat-icon class="sidebar__tab-icon">dataset</mat-icon>
          {{ 'SIDEBAR_WIDGET.CASES' | translate }}
        </ng-template>
        <ng-template matTabContent>
          <div class="sidebar__tab-content">
           <app-side-subject-cases></app-side-subject-cases>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="disabledTabs.tracking">
        <ng-template mat-tab-label>
          <mat-icon class="sidebar__tab-icon">track_changes</mat-icon>
          {{ 'SIDEBAR_WIDGET.TRACKING' | translate }}
        </ng-template>
        <ng-template matTabContent>
          <div class="sidebar__tab-content">
            <subject-tracking></subject-tracking>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</nav>
