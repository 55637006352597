/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 * SubmissionService - servis za dobavljanje podataka o podnescima
 */
@Injectable({
  providedIn: 'root'
})
export class SubmissionService {

  constructor(private http: HttpClient, private appService: AppService) { }

  findById(submissionId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.http.get(this.appService.getUrl() + environment.findById
    + '/' + submissionId, { headers }) as Observable<any>;
  }

  findByCenter(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.http.get(this.appService.getUrl() + environment.findSubmissionsByCenter
    , { headers }) as Observable<any>;
  }

  acceptSubmission(submissionId: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    });
    return this.http.post(this.appService.getUrl() + environment.acceptSubmission + '/' + submissionId
    , { headers }) as Observable<any>;
  }
}
