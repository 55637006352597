/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { filterByDateUtil, removeFilterByDateUtil, resetFilterByDateUtil } from 'src/app/utils/filterListByDate';
import { ManageTrackingComponent } from '../manage-tracking/manage-tracking.component';
import { DeleteTrackingComponent } from '../delete-tracking/delete-tracking.component';
import { ModalSizeEnum } from 'src/types';
import { Page } from 'src/app/page';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { TrackingListService } from 'src/services/tracking-list.service';
import { Activity } from 'src/models/activity.model';
import { CaseService } from 'src/services/case.service';
import { DatePipe } from '@angular/common';
import { DataSharingService } from 'src/services/data-sharing.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { UserService } from 'src/services/user.service';
import { LocalStorageService } from 'src/app/local-storage.service';

@Component({
   selector: 'subject-tracking',
   templateUrl: './subject-tracking.component.html',
   styleUrls: ['./subject-tracking.component.scss'],
})
export class SubjectTrackingComponent implements OnInit {
   @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
   filterForm: FormGroup;
   filterValue: {
      minDate: any;
      maxDate: any;
      text: any;
   };
   appliedFilters: {
      displayValue: string;
      value: Object | string;
      key: 'minDate' | 'maxDate';
   }[] = [];
   badgeCount: number;
   object: any = {
      trackingData: '',
      action: '',
      subjectId: '',
   };
   subjectId: string;
   caseId: any;
   isLoading: boolean = true;
   // tslint:disable-next-line: ban-types
   page: Page<Activity> = new Page();
   caseNumber: any;
   caseName: any;
   case: any;
   isUserDrugostepeni = false;
   addButtonDisabled = true;
   loggedIn: any;
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      public dialog: MatDialog,
      private caseService: CaseService,
      private paginationService: CustomPaginationService,
      private trackingListService: TrackingListService,
      private cdr: ChangeDetectorRef,
      private datepipe: DatePipe,
      private dataSharingService: DataSharingService,
      private userService: UserService,
      private localStorageService: LocalStorageService
   ) {
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      if (this.filterValue === undefined) {
         this.filterValue = {
            minDate: '',
            maxDate: '',
            text: [],
         };
      }
      this.dataSharingService.subjectStatusChange.subscribe(result => {
         if (result !== null) {
            this.addButtonDisabled = result;
         }
      });
      this.dataSharingService.caseStatusChange.subscribe(result => {
         if (result !== null) {
            this.addButtonDisabled = result;
         }
      });
      this.dataSharingService.supervisionChangeCase.subscribe(result => {
         if (result !== null) {
            this.caseId = this.localStorageService.get('supervisionCaseId');
            this.getDataForCase(this.filterValue);
         }
      });
   }

   ngOnInit(): void {
      this.filterForm = this.formBuilder.group({
         minDate: ['', []],
         maxDate: ['', []],
      });
      this.getTrackingData();
   }

   public getTrackingData() {
      const url = this.router.url;
      if (url.includes('/subjects/')) {
         this.addButtonDisabled = false;
         const [, id] = url.split('/subjects/');
         this.subjectId = id;
         this.caseId = null;
         this.getDataForSubject(this.filterValue);
      } else if (url.includes('/cases/')) {
         const [_, caseType] = url.split('/cases/');
         if (caseType !== '') {
            [this.caseName, this.caseId, this.subjectId] = caseType.split('/');
            this.getDataForCase(this.filterValue);
         }
      } else if (url.includes('/supervision/')) {
         this.caseId = this.localStorageService.get('supervisionCaseId');
         this.getDataForCase(this.filterValue);
      }
   }

   public getNextPage(): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.getData(this.filterValue);
   }

   public getPreviousPage(): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.getData(this.filterValue);
   }

   public getFirstPage(): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.getData(this.filterValue);
   }

   public getLastPage(): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.getData(this.filterValue);
   }

   public getPageInNewSize(pageSize: any): void {
      this.isLoading = true;
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.getData(this.filterValue);
   }

   getDataForSubject(filter: any) {
      if (!this.userService.isUserDrugostepeni() && !this.userService.isUserMainBelgrade()) {
         this.trackingListService.getSubjectFilteredPage(this.page.pageable, filter, this.subjectId).subscribe(page => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            this.isLoading = false;
            if (!this.cdr['destroyed']) {
               this.cdr.detectChanges();
            }
         });
      } else {
         this.trackingListService
            .getSubjectFilteredPageSecondDegree(this.page.pageable, filter, this.subjectId, this.localStorageService.get('localStorageCenterId'))
            .subscribe(page => {
               this.page = page;
               this.page.pageable.pagePerShow = page.number + 1;
               this.isLoading = false;
               if (!this.cdr['destroyed']) {
                  this.cdr.detectChanges();
               }
            });
      }
   }

   getDataForCase(filter: any) {
      if (!this.userService.isUserDrugostepeni() && !this.userService.isUserMainBelgrade()) {
         this.trackingListService.getCaseFilteredPage(this.page.pageable, filter, this.caseId).subscribe(page => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            this.isLoading = false;
            this.cdr.detectChanges();
            this.caseService.getSocialCaseDetails(this.caseId).subscribe(result => {
               this.caseNumber = result.caseNumber;
               if (result.caseStatus === 'ACCEPTED' && (result.assignedUserId === this.loggedIn.id || result.teamMembers.includes(this.loggedIn.id))) {
                  this.addButtonDisabled = false;
               } else {
                  this.addButtonDisabled = true;
               }
            });
         });
      } else {
         this.trackingListService.getCaseFilteredPageSecondDegree(this.page.pageable, filter, this.caseId).subscribe(page => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            this.isLoading = false;
            this.cdr.detectChanges();
            this.caseService.getSocialCaseDetails(this.caseId).subscribe(result => {
               this.caseNumber = result.caseNumber;
               if (result.caseStatus === 'ACCEPTED' && (result.assignedUserId === this.loggedIn.id || result.teamMembers.includes(this.loggedIn.id))) {
                  this.addButtonDisabled = false;
               } else {
                  this.addButtonDisabled = true;
               }
            });
         });
      }
   }

   // Filters (apply, remove, reset)
   applyFilter() {
      this.isLoading = true;
      const { appliedFilters, badgeCount } = filterByDateUtil({
         filterForm: this.filterForm,
         list: [],
         trigger: this.trigger,
      });

      this.appliedFilters = appliedFilters;
      this.badgeCount = badgeCount;

      if (this.filterForm.get('minDate').value !== '') {
         this.filterValue.minDate = this.datepipe.transform(new Date(this.filterForm.get('minDate').value), 'yyyy-MM-dd');
      }
      if (this.filterForm.get('maxDate').value !== '') {
         this.filterValue.maxDate = this.datepipe.transform(new Date(this.filterForm.get('maxDate').value), 'yyyy-MM-dd');
      }
      this.getData(this.filterValue);
   }

   removeFilter(filter: { displayValue: string; value: Object | string; key: 'minDate' | 'maxDate' }): void {
      this.isLoading = true;
      const { appliedFilters, badgeCount } = removeFilterByDateUtil({
         filter,
         appliedFilters: this.appliedFilters,
         filterForm: this.filterForm,
         list: [],
      });

      this.appliedFilters = appliedFilters;
      this.badgeCount = badgeCount;
      if (filter.key === 'minDate') {
         this.filterValue.minDate = '';
      }
      if (filter.key === 'maxDate') {
         this.filterValue.maxDate = '';
      }

      this.getData(this.filterValue);
   }

   resetFilter() {
      this.isLoading = true;
      const { appliedFilters, badgeCount } = resetFilterByDateUtil({
         trigger: this.trigger,
         list: [],
         filterForm: this.filterForm,
      });
      this.appliedFilters = appliedFilters;
      this.badgeCount = badgeCount;
      this.filterValue.minDate = '';
      this.filterValue.maxDate = '';
      this.getData(this.filterValue);
   }

   openDialog(action: 'view' | 'edit' | 'new' | 'delete', obj?: any) {
      this.object.subjectId = this.subjectId;
      this.object.action = action;

      let dialogRef: any;
      if (action === 'delete') {
         this.object.trackingData = obj;
         dialogRef = this.dialog.open(DeleteTrackingComponent, {
            panelClass: 'overlay-panel',
            disableClose: true,
            width: ModalSizeEnum.MINI,
            data: this.object,
         });
      } else if (action === 'new') {
         this.object.trackingData = obj;
         if (this.caseId !== null) {
            this.object.trackingData = new Activity(this.caseId, this.caseNumber, null, null, null, null, null);
         } else {
            this.object.trackingData = new Activity(null, null, null, null, null, null, null);
         }

         dialogRef = this.dialog.open(ManageTrackingComponent, {
            panelClass: 'overlay-panel',
            disableClose: true,
            width: ModalSizeEnum.MINI,
            data: this.object,
         });
      } else {
         this.object.trackingData = obj;
         dialogRef = this.dialog.open(ManageTrackingComponent, {
            panelClass: 'overlay-panel',
            disableClose: true,
            width: ModalSizeEnum.MINI,
            data: this.object,
         });
      }

      dialogRef.afterClosed().subscribe(() => {
         this.isLoading = true;
         this.getData(this.filterValue);
      });
   }

   getData(filter: { minDate: any; maxDate: any; text: any }) {
      if (this.page.numberOfElements === 1 && this.page.pageable.pageNumber !== 0) {
         this.page.pageable.pageNumber = this.page.pageable.pageNumber - 1;
      }
      if (this.caseId === null) {
         this.getDataForSubject(filter);
      } else {
         this.getDataForCase(filter);
      }
   }

   /*Metoda poziva akciju stampanja lista pracenja jednog korisnika na osnovu njegovo ID-a*/
   printTrackingList() {
      const body = {
         subjectId: this.subjectId,
         caseId: this.caseId,
      };
      if (!this.userService.isUserDrugostepeni() && !this.userService.isUserMainBelgrade()) {
         this.trackingListService.print(body, null);
      } else {
         this.trackingListService.print(body, this.localStorageService.get('localStorageCenterId'));
      }
   }
}

/**
 * TODO @gorandivovic @jelenaninkovic @oljaandjelovski
 *
 * 2. Provide proper translations (ćirilica/latinica)
 * 4. Improve sending document on "edit/new/delete tracking document" depending on API
 * 5. Improve error handling for showing snackBar (server error, after connecting BE)
 */
