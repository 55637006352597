<header class="header">
   <card-header title="{{ 'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.DESCRIPTION' | translate }}">
   </card-header>
   <div class="header__actions">
      <form class="form" [formGroup]="socialMapForm">
         <field-holder>
            <mat-form-field appearance="outline">
               <input matInput formControlName="selectedTypeOfData" readonly />
            </mat-form-field>
         </field-holder>
         <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.SUBJECT_JMBG' | translate}}
               </mat-label>
               <input matInput maxlength="13" type="text" formControlName="subjectJMBG" autocomplete="off" required />
               <mat-error *ngIf="
                socialMapForm.get('subjectJMBG').hasError('required') ||
                socialMapForm.get('subjectJMBG').hasError('minLength') ||
                socialMapForm.get('subjectJMBG').hasError('maxLength') ||
                socialMapForm.get('subjectJMBG').hasError('pattern')
              ">
                  {{ 'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.SUBJECT_JMBG_ERROR' | translate }}
               </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>{{ 'MANAGE_TRACKING.MANAGE_FORM.DATE' | translate }}</mat-label>
               <input matInput formControlName="dateOfSubmission" readonly />
            </mat-form-field>
         </field-holder>
         <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.FROM' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerValidFrom" formControlName="dateFrom" required autocomplete="off"
                  [max]="maxDate" />
               <mat-datepicker-toggle matSuffix [for]="pickerValidFrom">
               </mat-datepicker-toggle>
               <mat-datepicker #pickerValidFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.TO' | translate}}
               </mat-label>
               <input matInput [matDatepicker]="pickerValidTo" formControlName="dateTo" [max]="maxDate"
                  [min]="socialMapForm.get('dateFrom').value!=null?socialMapForm.get('dateFrom').value:null" required
                  autocomplete="off" />
               <mat-datepicker-toggle matSuffix [for]="pickerValidTo">
               </mat-datepicker-toggle>
               <mat-datepicker #pickerValidTo></mat-datepicker>
            </mat-form-field>
         </field-holder>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{
                  "SIDEBAR_WIDGET.OTHER_INSTITUTIONS.RIGHT" | translate
                  }}</mat-label>
               <mat-select formControlName="rightCode" required panelWidth="auto">
                  <mat-option *ngFor="let right of rightCodeOptions" [value]="right.value">
                     {{ right.viewValue }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <form-footer>
            <button mat-stroked-button color="primary" class="form-footer__button" (click)="sendRequest()"
               [disabled]="!socialMapForm.valid">
               {{ 'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.SUBMIT' | translate }}
            </button>
         </form-footer>
      </form>
   </div>
</header>