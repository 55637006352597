/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { FosterParentService } from 'src/services/foster-parent.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { NewFosterParentFamilyInformationStepComponent } from './new-foster-parent-family-information-step/new-foster-parent-family-information-step.component';
import { NewFosterParentPersonalInformationStepComponent } from './new-foster-parent-personal-information-step/new-foster-parent-personal-information-step.component';
import { NewFosterParentSuitabilityStepComponent } from './new-foster-parent-suitability-step/new-foster-parent-suitability-step.component';
@Component({
   selector: 'app-new-foster-parent',
   templateUrl: './new-foster-parent.component.html',
   styleUrls: ['./new-foster-parent.component.scss'],
})
export class NewFosterParentComponent implements OnInit {
   personalInformationStep: FormGroup;
   familyInformationStep: FormGroup;
   suitabilityStep: FormGroup;
   centerStep: FormGroup;
   @ViewChild(NewFosterParentPersonalInformationStepComponent) private personalChild: NewFosterParentPersonalInformationStepComponent;
   @ViewChild(NewFosterParentFamilyInformationStepComponent) private familylInformationChild: NewFosterParentFamilyInformationStepComponent;
   @ViewChild(NewFosterParentSuitabilityStepComponent) private suitibilityChild: NewFosterParentSuitabilityStepComponent;
   constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private fosterParentService: FosterParentService,
      private datePipe: DatePipe,
      private toastr: ToastrImplService
   ) {
      this.personalInformationStep = formBuilder.group({});
      this.familyInformationStep = formBuilder.group({});
      this.suitabilityStep = formBuilder.group({});
      this.centerStep = formBuilder.group({});
   }

   ngOnInit() {}

   /**
    * Take information from personal step
    */
   updatePersonalInformationStep(step: FormGroup) {
      this.personalInformationStep = step;
   }

   /**
    * Take information from family information step
    */
   updateFamilyInformationStep(step: FormGroup) {
      this.familyInformationStep = step;
   }

   /**
    * Take information from reason for suitability step
    */
   updateSuitabilityStep(step: FormGroup) {
      // This is the final step where, apart from validating the data from this step
      // we are supposed to submit the new record to dedicated service.
      this.suitabilityStep = step;
   }

   updateCenterStep(step: FormGroup) {
      // This is the final step where, apart from validating the data from this step
      // we are supposed to submit the new record to dedicated service.
      this.centerStep = step;
      this.createNewFosterParent();
   }

   /**
    * @author Olja Andjelovski olja.andjelovski@iten.rs
    *
    */
   createNewFosterParent() {
      const body = {
         dateOfCreation: new Date(),
         subjectId: this.personalInformationStep.value.subjectId,
         registrationId: this.personalInformationStep.value.registrationId,
         firstName: this.personalInformationStep.value.firstName,
         lastName: this.personalInformationStep.value.lastName,
         gender: this.personalInformationStep.value.gender,
         dateOfBirth: this.datePipe.transform(new Date(this.personalInformationStep.value.dateOfBirth).getTime(), 'dd/MM/yyyy'),
         placeOfBirth: this.personalInformationStep.value.placeOfBirth,
         permanentResidence: this.personalInformationStep.value.placeOfOrigin,
         jmbg: this.personalInformationStep.value.jmbg,
         nationality: this.personalInformationStep.value.nationality !== '' ? this.personalInformationStep.value.nationality : null,
         citizenship: this.personalInformationStep.value.citizenship !== '' ? this.personalInformationStep.value.citizenship : null,
         occupation: this.personalInformationStep.value.occupation !== '' ? this.personalInformationStep.value.occupation : null,
         employment: this.personalInformationStep.value.workStatus,
         education: this.personalInformationStep.value.education,
         maritalStatus: this.personalInformationStep.value.maritalStatus,
         healthStatus: this.personalInformationStep.value.healthStatus,
         residentialStatus: this.familyInformationStep.value.householdStatus,
         incomeFromEmployment: this.familyInformationStep.value.employmentIncome,
         incomeFromPrivateActivity: this.familyInformationStep.value.privateActivityIncome,
         averageMonthlyIncome: this.familyInformationStep.value.monthlyIncome,
         averageMonthlyIncomePerHousehold: this.familyInformationStep.value.perHouseholdMemberIncome,
         otherIncome: this.familyInformationStep.value.otherIncome,
         pension: this.familyInformationStep.value.pensionIncome,
         numberOfFamilyMembers: this.familyInformationStep.value.familyMemberCount,
         numberOfFamilyChildren: this.familyInformationStep.value.childrenCount,
         maxChildren: this.familyInformationStep.value.acceptFosterChildrenCount,
         childGender: this.familyInformationStep.value.fosterChildrenGender === '' ? null : this.familyInformationStep.value.fosterChildrenGender,
         ageOfChild: this.familyInformationStep.value.ageToAccept === '' ? null : this.familyInformationStep.value.ageToAccept,
         childOfAnotherNationality:
            this.familyInformationStep.value.acceptDifferentNationality === null ? null : this.familyInformationStep.value.acceptDifferentNationality.value,
         childWithHealthDisorders:
            this.familyInformationStep.value.acceptChildrenWithSpecialNeeds === null
               ? null
               : this.familyInformationStep.value.acceptChildrenWithSpecialNeeds.value,
         childWithBehavioralDisorder:
            this.familyInformationStep.value.acceptChildrenWithBehaviourDisorder === null
               ? null
               : this.familyInformationStep.value.acceptChildrenWithBehaviourDisorder.value,
         professionalOpinion: {
            psychologistDate: this.suitabilityStep.value.reportDates.psychologist,
            pedagogueDate: this.suitabilityStep.value.reportDates.pedagogue,
            lawyerDate: this.suitabilityStep.value.reportDates.lawyer,
            doctorDate: this.suitabilityStep.value.reportDates.doctor,
            socialWorkerDate: this.suitabilityStep.value.reportDates.socialWorker,
            institutionDate: this.suitabilityStep.value.reportDates.otherInstitutions,
            numberOfDecision: this.suitabilityStep.value.decisionNumber,
            decisionDate: this.suitabilityStep.value.decisionDate,
            guardianshipName: this.centerStep.value.guardianshipName,
            guardianshipPhone: this.centerStep.value.phone,
            guardianshipEmail: this.centerStep.value.emailAddress,
            guardianshipAddress: this.centerStep.value.address,
            questionnaireNumber: this.centerStep.value.questionnaireNumber,
            questionnaireDate: this.centerStep.value.questionnaireDate,
         },
      };

      this.fosterParentService.createFosterParent(body).subscribe(res => {
         if (res != null) {
            this.toastr.success('SNACKBAR.ADDED_FOSTER_PARENT');
            this.router.navigateByUrl('/foster-parent');
         } else {
            this.toastr.error('SNACKBAR.ADD_FOSTER_PARENT_ERROR');
            this.router.navigateByUrl('/foster-parent');
         }
      });
   }

   /**
    * Review if all form steps are valid
    */
   checkDocumentValidation(): boolean {
      const isPersonalInformationStepValid = this.personalInformationStep && this.personalInformationStep.valid;
      const isFamilyInformationStepValid = this.familyInformationStep && this.familyInformationStep.valid;
      const isSuitabilityStepValid = this.suitabilityStep && this.suitabilityStep.valid;

      return [isPersonalInformationStepValid, isFamilyInformationStepValid, isSuitabilityStepValid].every(val => val === true);
   }
   public onStepChange(stepper: MatStepper) {
      if (stepper.selectedIndex === 3) {
         this.personalChild.updatePersonalInformationStep();
         this.familylInformationChild.updateFamilyInformationStep();
         this.suitibilityChild.updateSuitabilityStep();

         if (!this.personalInformationStep.valid) {
            this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_FIRST');
         } else if (!this.familyInformationStep.valid) {
            this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_SECOND_STEP');
         } else if (!this.suitabilityStep.valid) {
            this.toastr.error('NEW_FOSTER_CHILD.FORM.BACK_TO_TIRHT_STEP');
         }
      }
   }
}
