/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 *
*/
/**
 * @author Goran Divovic goran.divovic@iten.rs
 */
export class FormsSOOtherRevenue {

  revenueKind: any;
  branchesPioFundCodebook: any;
  checkNumber: string;
  revenueAmount: number;
  startDate: Date
  constructor(revenueKind: any, pio: any, checkNumber: string, revenueAmount: number, startDate: Date) {
    this.revenueKind = revenueKind;
    this.branchesPioFundCodebook = pio;
    this.checkNumber = checkNumber;
    this.revenueAmount = revenueAmount;
    this.startDate = startDate;

  }
}
