<mat-card class="card">
    <card-header title="{{ 'LEGAL_SUBJECT_HISTORY.TITLE' | translate }}"></card-header>
    <div class="table-responsive-wrapper">
        <table mat-table #myTable [dataSource]="page.content" class="subject-history__table">
            <ng-container matColumnDef="dateOfModification">
                <th mat-header-cell *matHeaderCellDef> {{ 'SUBJECTS.DATEOFMODIFICATION' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.dateOfModification }}
                </td>
            </ng-container>
            <ng-container matColumnDef="modifiedBy">
                <th mat-header-cell *matHeaderCellDef> {{ 'SUBJECTS.MODIFIEDBY' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.modifiedBy }}
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.NAME' | translate }}
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    {{ element.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="institutionType">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.INSTITUTION_TYPE' | translate }}
                </th>
                <td mat-cell *matCellDef="let element; let i = index"> {{ element.institutionType.name }} </td>
            </ng-container>
            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.ADDRESS' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.address?.street }} {{ element?.address?.number }} /
                    {{ element?.address?.subnumber }}</td>
            </ng-container>
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.CITY' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.address?.town }}</td>
            </ng-container>
            <ng-container matColumnDef="ptt">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.PTT' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.address?.ptt }}</td>
            </ng-container>
            <ng-container matColumnDef="region">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.REGION' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.address?.region }} </td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.PHONE' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.phone }} </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.EMAIL' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
            </ng-container>
            <ng-container matColumnDef="accountNumber">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.ACCOUNT_NUMBER' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.accountNumber }} </td>
            </ng-container>
            <ng-container matColumnDef="jbkbs">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.JBKBS' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.jbkbs }} </td>
            </ng-container>
            <ng-container matColumnDef="pib">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.PIB' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.pib }} </td>
            </ng-container>
            <ng-container matColumnDef="identificationNumber">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'LEGAL_SUBJECTS.IDENTIFICATION_NUMBER' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.identificationNumber }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; let element; columns: displayedColumns" class="subject-history__row"></tr>
        </table>
    </div>
    <div class="pagination">
        <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()"
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()" (pageSizeEvent)="getPageInNewSize($event)">
        </app-custom-pagination>
    </div>
</mat-card>