/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SubjectsService } from 'src/services/subjects.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'family-member-card',
  templateUrl: './family-member-card.component.html',
  styleUrls: ['./family-member-card.component.scss'],
})
export class FamilyMemberCardComponent implements OnInit {
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() regId: number;
  @Input() jmbg: string;
  @Input() originalFamilyDossier = '';
  @Input() relationToDossierHolder?: string;
  @Input() isEditDisabled: boolean;
  @Input() subjectId: any;
  @Output() editedUser = new EventEmitter();
  @Output() removedFamilyMember = new EventEmitter();

  familyDossier = '';
  fullName: string;
  status: string;
  subjectDetailsLink: string;
  isAdmin: boolean;

  object: any = {
    document: '',
    action: '',
    subjectId: '',
    message: '',
    title: ''
  };
  constructor(
    private translate: TranslateService,
    private subjectsService: SubjectsService,
    public dialog: MatDialog,
    private userService: UserService) {
      this.isAdmin = this.userService.isGlobalAdmin();
     }

  ngOnInit(): void {
    this.translate
      .get('SUBJECTS.FAMILY_DOSSIER.' + this.originalFamilyDossier)
      .subscribe((result: string) => {
        this.familyDossier = result;
      });
    this.fullName = `${this.firstName} ${this.lastName}`;
    if (this.originalFamilyDossier !== 'CARRIER') {
        this.status = `${this.familyDossier} ${
          this.relationToDossierHolder ? '/ ' + this.relationToDossierHolder : ''
          }`;
    }
    else {
        this.status = `${this.familyDossier}`;
    }
  }

  openDetailsNewTab() {
    window.open(environment.getFamilyMemberTab + this.subjectId, '_blank');
  }

  setNewCarrier() {
    this.translate.get('WARNING_DIALOG.SUBJECT_CARRIER.MESSAGE').subscribe((res: string) => {
      this.object.message = res + this.fullName;
    });
    this.translate.get('WARNING_DIALOG.SUBJECT_CARRIER.TITLE').subscribe((res: string) => {
      this.object.title = res;
    });
    this.object.action =  ModalEventEnum.CONFIRM;
    const dialogRef = this.dialog.open(WarningMessageComponent, {
      disableClose: true,
      panelClass:'overlay-panel',
      width: ModalSizeEnum.MINI,
      data: this.object,
    });

    dialogRef.afterClosed().subscribe((result) => {
      const { event, data } = result;
      if (event === ModalEventEnum.CONFIRM && data.status) {
        this.subjectsService.setFamilyMemberCarrier(this.subjectId).subscribe((res) => {
          this.editedUser.emit();
        });
      }
    });
  }

  removeUserFromFamily() {
    this.translate.get('WARNING_DIALOG.SUBJECT_DELETE.MESSAGE').subscribe((res: string) => {
      this.object.message = res + this.fullName;
    });
    this.translate.get('WARNING_DIALOG.SUBJECT_DELETE.TITLE').subscribe((res: string) => {
      this.object.title = res;
    });
    this.object.action = ModalEventEnum.DELETE;
    const dialogRef = this.dialog.open(WarningMessageComponent, {
      disableClose: true,
      panelClass:'overlay-panel',
      width: ModalSizeEnum.MINI,
      data: this.object,
    });

    dialogRef.afterClosed().subscribe((result) => {
      const { event, data } = result;
      if (event === ModalEventEnum.DELETE && data.status) {
        this.subjectsService.removeFamilyMember(this.subjectId).subscribe(() => {
          this.removedFamilyMember.emit();
        });
      }
    });
  }
}

/**
 * TODO @gorandivovic @jelenaninkovic @danijelgrabez
 *
 * 1. Handle editing - related to <subject-family> component @gorandivovic @danijelgrabez
 * 2. Pass tooltip text from translations
 * 3. Translate inputs in proper format (ćirilica/latinica)
 */
