import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdoptedChildService } from 'src/services/adopted-child.service';

@Component({
    selector: 'app-adopted-children-details',
    templateUrl: './adopted-children-details.component.html',
    styleUrls: ['./adopted-children-details.component.scss']
})
export class AdoptedChildrenDetailsComponent implements OnInit {
    adoptChildId: any;
    adoptChild: any;
    year: any;

    constructor(
        private route: ActivatedRoute,
        private adoptChildService: AdoptedChildService) {
        this.route.params.subscribe((params) => {
            this.adoptChildId = params.id;
            this.year = params.year;
        });
        this.getAdoptChild();
    }

    ngOnInit(): void {
    }

    getAdoptChild() {
        this.adoptChildService.findById(this.adoptChildId).subscribe((res) => {
            this.adoptChild = res;
            if (this.adoptChild.adoptiveFather !== null) {
                this.adoptChild.adoptiveFather.dateOfBirth =
                    this.adoptChild.adoptiveFather?.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
            }
            if (this.adoptChild.adoptiveMother !== null) {
                this.adoptChild.adoptiveMother.dateOfBirth =
                    this.adoptChild.adoptiveMother?.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
            }
            if (this.adoptChild.father !== null) {
                this.adoptChild.father.dateOfBirth =
                    this.adoptChild.father?.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
            }
            if (this.adoptChild.mother !== null) {
                this.adoptChild.mother.dateOfBirth =
                    this.adoptChild.mother?.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
            }
            if (this.adoptChild.afterAdoption !== null) {
                this.adoptChild.afterAdoption.dateOfBirth =
                    this.adoptChild.afterAdoption?.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
            }
            if (this.adoptChild.decisionDate !== null) {
                this.adoptChild.decisionDate =
                    this.adoptChild?.decisionDate?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
            }
            if (this.adoptChild.decisionDateNewBirthRegistration !== null) {
                this.adoptChild.decisionDateNewBirthRegistration =
                    this.adoptChild?.decisionDateNewBirthRegistration?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
            }
            this.adoptChild.beforeAdoptionDateOfBirth =
                this.adoptChild.beforeAdoptionDateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');


        });
    }

}
