import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { MatDialog } from '@angular/material/dialog';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';

@Component({
    selector: 'app-family-data',
    templateUrl: './family-data.component.html',
    styleUrls: ['./family-data.component.scss']
})
export class FamilyDataComponent implements OnInit {
    
    familyData: FormGroup;
    isBrothersAndSisters: any;
    isGuardian: any;
     relationshipOptions: any = [];
      
    constructor(
        public dialog: MatDialog,
        private codebookService: CodebookService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<FamilyDataComponent>,
        private formBuilder: FormBuilder) {
   

        this.familyData = this.formBuilder.group({
            fosterFamilyId: [''],
            firstName: [''],
            lastName: [''],
            address: [''],
            phone: [''],
            birthYear: [''],
            relationshipCodebook: [null, []],
            dateOfBirth: [''],
            jmbg: [''],
            information: ['']
        });

        this.isBrothersAndSisters = this.data.brothersAndSisters;
        this.isGuardian = this.data.guardian;
        this.getRelationshipCodebook();

    }

    ngOnInit(): void {
    }

    addNewFamily() {
        this.dialogRef.close({ event: 'Create', data: this.familyData.value });

    }

    closeDialog() {
        this.dialogRef.close({ event: 'Cancel' });
    }

    getRelationshipCodebook() {
        this.codebookService.getRelationshipCodebookWithoutUser().subscribe(res => {
            this.relationshipOptions = res;
        });
    }
    compareObj(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }


    importSubjectData() {
        const dialogRef = this.dialog.open(SubjectsComponent, {
            width: '1200px',
            panelClass:'overlay-panel',
            data: {
                origin: 'entrance',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                const address = String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                    String(result.data.permanentResidence.number ? ' ' : '') +
                    String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                    String(result.data.permanentResidence.subnumber ? '/' : '') +
                    String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : '');
                if (this.isBrothersAndSisters) {
                    this.familyData.patchValue({
                        fosterFamilyId: result.data.subjectIdentity.subjectId,
                        information: ' ' + String(result.data.firstName ? result.data.firstName : '') + String(result.data.lastName ? ' ' + result.data.lastName : '') + String(result.data.yearOfBirth ? ', ' + result.data.yearOfBirth : '') + String(address ? ', ' + address : ''),
                        firstName: result.data.firstName,
                        lastName: result.data.lastName,
                        birthYear: result.data.yearOfBirth,
                        address: String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                            String(result.data.permanentResidence.number ? ' ' : '') +
                            String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                            String(result.data.permanentResidence.subnumber ? '/' : '') +
                            String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
                        jmbg: result.data.jmbg,
                        phone: result.data.phone,
                        dateOfBirth: result.data.dateOfBirth,


                    });
                }
            }

            if (this.isGuardian) {
                const address = String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                    String(result.data.permanentResidence.number ? ' ' : '') +
                    String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                    String(result.data.permanentResidence.subnumber ? '/' : '') +
                    String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : '');
                this.familyData.patchValue({
                    fosterFamilyId: result.data.subjectIdentity.subjectId,
                    information: ' ' + String(result.data.firstName ? result.data.firstName : '')
                        + String(result.data.lastName ? ' ' + result.data.lastName : '')
                        + String(result.data.yearOfBirth ? ', ' + result.data.yearOfBirth : '')
                        + String(address ? ', ' + address : '') + String(result.data.phone ? ', ' + result.data.phone : '' + String(result.data.firstName ? result.data.firstName : '')),
                    firstName: result.data.firstName,
                    lastName: result.data.lastName,
                    address: String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                        String(result.data.permanentResidence.number ? ' ' : '') +
                        String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                        String(result.data.permanentResidence.subnumber ? '/' : '') +
                        String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
                    phone: result.data.phone,
                    birthYear: result.data.yearOfBirth,
                    jmbg: result.data.jmbg,
                    dateOfBirth: result.data.dateOfBirth,
                    education: result.data.education,


                });
            }
        });
    }

    isEmptyFields() {

        if (this.familyData.value.information === '') {
            return true;
        }

        return false;
    }
}
