<mat-dialog-content>
  <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
          [mat-dialog-close]="true"><span class="material-icons">close</span>
  </button>
  <div class="container">
    <form flex [formGroup]="costsParticipantForm" (ngSubmit)="add()">
      <h2>{{ 'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING_TITLE' | translate }}</h2>
      <div class="mb-16">
        <button mat-flat-button color="primary" type="button" (click)="importSubjectData()">
          <mat-icon>person_add</mat-icon>
          {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.IMPORT_SUBJECT' | translate }}
        </button>
      </div>
      <br>
      <form-subgroup-divider></form-subgroup-divider>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.FULLNAME'| translate}}
          </mat-label>
          <input matInput type="text" required formControlName="fullName" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.JMBG'| translate}}
          </mat-label>
          <input matInput type="text" formControlName="jmbg" maxlength="13" onlyNumber autocomplete="off" />
        </mat-form-field>
      </field-holder>
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.PLACE'| translate}}
          </mat-label>
          <input matInput type="text" formControlName="place" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.PTT_NUMBER'| translate}}
          </mat-label>
          <input matInput type="text" formControlName="pttNumber" maxlength="5" onlyNumber autocomplete="off" />
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.ADDRESS'| translate}}
          </mat-label>
          <input matInput type="text" formControlName="address" autocomplete="off" />
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.TYPE_OF_PARTICIPATION'| translate}}
          </mat-label>
          <input matInput type="text" required formControlName="typeOfParticipation" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.START_DATE'| translate}}
          </mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="startDate" autocomplete="off" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{'VS.ACCOMMODATION.FORMSO.STEP_04.COST_SHARING.PARTICIPATION_AMOUNT'| translate}}
          </mat-label>
          <input matInput type="text" required formControlName="participationAmount" (change)="checkAmount()" autocomplete="off" />
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
                class="form-footer__button form-footer__button--secondary">
          {{ 'GLOBAL.CANCEL' | translate }}
        </button>
      <button mat-flat-button color="primary" type="submit" [disabled]="!costsParticipantForm.valid"
                class="form-footer__button form-footer__button--primary">
          {{ 'GLOBAL.SAVE' | translate }}
        </button>
      </form-footer>
    </form>
    <br />
  </div>
</mat-dialog-content>
