<div class="card">
  <header class="card__header">
    <main>
      <h4 class="card__title">{{ fullName }}, {{ 'FAMILY_MEMBER_CARD.REG_BR' | translate }}
        <span *ngIf="regId">{{regId}}</span>
        <span *ngIf="!regId"> /</span>
      </h4>
      <small class="card__status">{{ status }}</small>
    </main>
    <aside class="card__header-actions">
      <span matTooltip="{{ 'FAMILY_MEMBER_CARD.TOOLTIP_EDIT_DISABLED' | translate}}"
        [matTooltipDisabled]="!isEditDisabled">
        <button mat-icon-button class="button-square card__button" color="primary" (click)="setNewCarrier()"
          *ngIf="!isAdmin" [disabled]="isEditDisabled"
          matTooltip="{{ 'FAMILY_MEMBER_CARD.TOOLTIP_EDIT_ENABLED' | translate}}" [matTooltipDisabled]="isEditDisabled">
          <mat-icon class="button-square__icon">edit</mat-icon>
        </button>
      </span>
      <button [routerLink]="subjectDetailsLink" (click)="openDetailsNewTab()" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" mat-icon-button class="button-square card__button" color="primary"
        matTooltip="{{ 'FAMILY_MEMBER_CARD.TOOLTIP_VIEW' | translate }}">
        <mat-icon class="button-square__icon">visibility</mat-icon>
      </button>
      <span matTooltip="{{ 'FAMILY_MEMBER_CARD.REMOVE_FAMILY_MEMBER_DISABLED' | translate}}"
        [matTooltipDisabled]="!isEditDisabled">
        <button mat-icon-button class="button-square card__button" color="primary" (click)="removeUserFromFamily()"
          [disabled]="isEditDisabled" matTooltip="{{ 'FAMILY_MEMBER_CARD.REMOVE_FAMILY_MEMBER' | translate}}"
          *ngIf="!isAdmin" [matTooltipDisabled]="isEditDisabled">
          <mat-icon class="button-square__icon">delete</mat-icon>
        </button>
      </span>
    </aside>
  </header>
</div>