/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 *
*/
/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */
export class Form5ParticipationUser {

  firstAndLastName: string;
  relationship: string;
  phone: string;

  constructor(firstAndLastName: string, relationship: string, phone: string) {
    this.firstAndLastName = firstAndLastName;
    this.relationship = relationship;
    this.phone = phone;
  }

}