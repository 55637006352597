/**
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/services/authentication.service';
import { CodebookService } from 'src/services/codebook.service';
import { GlobalAdminService } from 'src/services/global-admin.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { LocalStorageService } from '../local-storage.service';
import { getNumberForShow, getNumberFromString, patchForDecimalNumber } from '../utils/formatLocalNumber';
import { ManageCipherComponent } from './manage-cipher/manage-cipher.component';

@Component({
   selector: 'global-admin',
   templateUrl: './global-admin.component.html',
   styleUrls: ['./global-admin.component.scss'],
})
export class GlobalAdminComponent implements OnInit {
   panelOpenState = false;
   legalProcedureForm: FormGroup;
   profesionalProcedureForm: FormGroup;
   rightCodebookForm: FormGroup;
   servicesForm: FormGroup;
   accommodationFosterForm: FormGroup;
   cipherNationalityForm: FormGroup;
   cipherCitinzenshipForm: FormGroup;
   cipherBankForm: FormGroup;

   isManageButtonVisible = {
      citinzenship: false,
      nationality: false,
      bank: false,
      rightCodebook: false,
      rights: false,
      services: false,
      legalProcedure: false,
      professionalProcedure: false,
   };
   accomodationData: any = [];
   cipherNationalityData: any = [];
   cipherCitinzenshipData: any = [];
   cipherBankData: any = [];
   rightsData = [];
   servicesData = [];
   legalProcedureData = [];
   professionalProcedureData = [];

   currentAccomodation = {
      id: '',
      category: '',
      subCategory: '',
      dailyAmount: 0,
      monthlyAmount: 0,
      validFrom: '',
   };
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      private authentication: AuthenticationService,
      private codebookService: CodebookService,
      private globalAdminService: GlobalAdminService,
      private translate: TranslateService,
      private localStorage: LocalStorageService,
      private toastr: ToastrImplService,
      private cdr: ChangeDetectorRef
   ) {}
   ngOnInit(): void {
      this.accommodationFosterForm = this.formBuilder.group({
         id: ['', []],
         category: ['', []],
         subCategory: ['', []],
         monthlyAmount: ['', [Validators.pattern('^[0-9.,]*$')]],
         dailyAmount: ['', [Validators.pattern('^[0-9.,]*$')]],
         validFrom: ['', []],
         choiceAccomodation: ['', []],
      });

      this.cipherCitinzenshipForm = this.formBuilder.group({
         activeOption: ['', []],
      });
      this.cipherNationalityForm = this.formBuilder.group({
         activeOption: ['', []],
      });
      this.cipherBankForm = this.formBuilder.group({
         activeOption: ['', []],
      });
      this.legalProcedureForm = this.formBuilder.group({
         format: [''],
         title: [''],
         id: null,
         activeOption: ['', []],
      });
      this.profesionalProcedureForm = this.formBuilder.group({
         format: [''],
         title: [''],
         code: [''],
         id: null,
         activeOption: ['', []],
      });

      this.rightCodebookForm = this.formBuilder.group({
         format: [''],
         title: [''],
         id: null,
         activeOption: ['', []],
      });
      this.servicesForm = this.formBuilder.group({
         format: [''],
         title: [''],
         id: null,
         activeOption: ['', []],
      });

      this.getCitinzeship();
      this.getNationality();
      this.getBanks();
      this.getAccommodations();
      this.getRightsCodebook();
      this.getServicesCodebook();
      this.getLegalProcedures();
      this.getProfessionalProcedures();
   }

   checkIfCipherValueExists(value: string): boolean {
      return this.cipherBankData.some(item => item.id === value);
   }

   manageCipher(
      type: 'edit' | 'new' | 'delete',
      kind: 'citinzenship' | 'nationality' | 'legalProcedure' | 'professionalProcedure' | 'services' | 'rightsCodebook' | 'bank',
      data: { id: number; value: string }
   ) {
      const dialogRef = this.dialog.open(ManageCipherComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: '800px',
         data: {
            type,
            kind,
            data,
            cipherBankData: this.cipherBankData,
         },
      });

      dialogRef.afterClosed().subscribe(({ event, kind, ...rest }) => {
         if (event === 'edit') {
            const { id } = rest;
            const newObject = {
               id: rest.id,
               title: rest.value,
               format: rest.format,
               active: rest.active,
            };
            if (kind === 'nationality') {
               this.globalAdminService.updateNationalityCodebook(newObject).subscribe(
                  res => {
                     this.getNationality();
                     this.cdr.detectChanges();
                     this.cipherNationalityForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.isManageButtonVisible.nationality = false;
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'rightsCodebook') {
               this.globalAdminService.updateRightsCodebook(newObject).subscribe(
                  res => {
                     this.getRightsCodebook();
                     this.cdr.detectChanges();
                     this.isManageButtonVisible.rights = false;
                     this.rightCodebookForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'services') {
               this.globalAdminService.updateServicesCodebook(newObject).subscribe(
                  res => {
                     this.getServicesCodebook();
                     this.cdr.detectChanges();
                     this.isManageButtonVisible.services = false;
                     this.servicesForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'legalProcedure') {
               this.globalAdminService.updateLegalProceduresCodebook(newObject).subscribe(
                  res => {
                     this.getLegalProcedures();
                     this.cdr.detectChanges();
                     this.isManageButtonVisible.legalProcedure = false;
                     this.legalProcedureForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'professionalProcedure') {
               this.globalAdminService.updateProfessionalProceduresCodebook(newObject).subscribe(
                  res => {
                     this.getProfessionalProcedures();
                     this.cdr.detectChanges();
                     this.isManageButtonVisible.professionalProcedure = false;
                     this.profesionalProcedureForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'citizenship') {
               this.globalAdminService.updateCitizenshipCodebook(newObject).subscribe(
                  res => {
                     this.getCitinzeship();
                     this.cdr.detectChanges();
                     this.isManageButtonVisible.citinzenship = false;
                     this.cipherCitinzenshipForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'bank') {
               this.globalAdminService.updateBankCodebook(newObject).subscribe(
                  res => {
                     this.getBanks();
                     this.cdr.detectChanges();
                     this.isManageButtonVisible.bank = false;
                     this.cipherBankForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            }
         } else if (event === 'new') {
            if (kind === 'citinzenship') {
               this.globalAdminService.addNewCitizenshipCodebook(rest.value).subscribe(
                  res => {
                     this.cipherCitinzenshipData.push(res);
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'nationality') {
               this.globalAdminService.addNewNationalityCodebook(rest.value).subscribe(
                  res => {
                     this.cipherNationalityData.push(res);
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'bank') {
               this.globalAdminService.addNewBankCodebook(rest.cipherValue, rest.value).subscribe(
                  res => {
                     this.cipherBankData.push(res);
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'legalProcedure') {
               this.legalProcedureForm.controls.title.setValue(rest.value);
               this.legalProcedureForm.controls.format.setValue(rest.format);
               const newObject = this.legalProcedureForm.value;
               this.globalAdminService.createLegalProcedures(newObject).subscribe(
                  (res: any) => {
                     this.successfulChange();
                     this.legalProcedureForm.controls.title.setValue('');
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'profesionalProcedure') {
               this.profesionalProcedureForm.controls.title.setValue(rest.value);
               this.profesionalProcedureForm.controls.format.setValue(rest.format);
               const newObject = this.profesionalProcedureForm.value;
               this.globalAdminService.createProfessionalProcedures(newObject).subscribe(
                  (res: any) => {
                     this.legalProcedureData.push(res);
                     this.successfulChange();
                     this.profesionalProcedureForm.controls.title.setValue('');
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'services') {
               this.servicesForm.controls.title.setValue(rest.value);
               this.servicesForm.controls.format.setValue(rest.format);
               const newObject = this.servicesForm.value;
               this.globalAdminService.createServicesCodebook(newObject).subscribe(
                  (res: any) => {
                     this.servicesData.push(res);
                     this.successfulChange();
                     this.servicesForm.controls.title.setValue('');
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'rightsCodebook') {
               this.rightCodebookForm.controls.title.setValue(rest.value);
               this.rightCodebookForm.controls.format.setValue(rest.format);
               const newObject = this.rightCodebookForm.value;
               this.globalAdminService.createRightsCodebook(newObject).subscribe(
                  (res: any) => {
                     this.rightsData.push(res);
                     this.successfulChange();
                     this.rightCodebookForm.controls.title.setValue('');
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            }
         }

         if (event === 'delete') {
            const { id } = rest;
            if (kind === 'rightsCodebook') {
               this.globalAdminService.deleteRightsCodebook(id).subscribe(
                  res => {
                     this.getRightsCodebook();
                     this.isManageButtonVisible.rights = false;
                     this.rightCodebookForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'services') {
               this.globalAdminService.deleteServicesCodebook(id).subscribe(
                  res => {
                     this.getServicesCodebook();
                     this.isManageButtonVisible.services = false;
                     this.servicesForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'legalProcedure') {
               this.globalAdminService.deleteLegalProceduresCodebook(id).subscribe(
                  res => {
                     this.getLegalProcedures();
                     this.isManageButtonVisible.legalProcedure = false;
                     this.legalProcedureForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            } else if (kind === 'professionalProcedure') {
               this.globalAdminService.deleteProfessionalProceduresCodebook(id).subscribe(
                  res => {
                     this.isManageButtonVisible.professionalProcedure = false;
                     this.profesionalProcedureForm = this.formBuilder.group({
                        activeOption: ['', []],
                     });
                     this.successfulChange();
                  },
                  () => {
                     this.errorOccured();
                  }
               );
            }
         }
      });
   }

   showManageButton(kind: 'citinzenship' | 'nationality' | 'rights' | 'services' | 'legalProcedure' | 'professionalProcedure' | 'bank') {
      this.isManageButtonVisible[kind] = true;
   }

   errorOccured() {
      this.toastr.error('SNACKBAR.ERROR_OCCURRED');
   }

   successfulChange() {
      this.toastr.success('SNACKBAR.CHANGE_SAVED');
   }
   checkAccommodationAmounts(form) {
      const amount = getNumberForShow(form.value);
      if (amount) form.setValue(amount);
   }
   // Send accomodation updates
   submitAccomodationForm(data: any) {
      data.value.validFrom = new Date(data.value.validFrom);
      if (data.value.validFrom.toISOString() !== new Date(this.currentAccomodation.validFrom).toISOString()) {
         data.value.validFrom.setDate(data.value.validFrom.getDate() + 1);
      }

      const newObject = this.currentAccomodation;
      newObject.dailyAmount = getNumberFromString(data.value.dailyAmount);
      newObject.monthlyAmount = getNumberFromString(data.value.monthlyAmount);
      newObject.validFrom = data.value.validFrom;
      newObject.id = null;
      this.globalAdminService.createAccommodationCodebook(newObject).subscribe(res => {
         this.toastr.success('SNACKBAR.CHANGE_SAVED');
         this.getAccommodations();
         this.currentAccomodation.id = '';
         this.accommodationFosterForm = this.formBuilder.group({
            id: ['', []],
            category: ['', []],
            subCategory: ['', []],
            monthlyAmount: ['', []],
            dailyAmount: ['', []],
            validFrom: ['', []],
            choiceAccomodation: ['', []],
         });
      });
      // If successful show snackbar
   }

   logout() {
      this.authentication.logout();
   }

   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }

   getNationality() {
      this.codebookService.getNationalityCodebook().subscribe(res => {
         this.cipherNationalityData = res;
      });
   }

   getCitinzeship() {
      this.codebookService.getCitizenshipCodebook().subscribe(res => {
         this.cipherCitinzenshipData = res;
      });
   }

   getBanks() {
      this.codebookService.getBankCodebook().subscribe(res => {
         this.cipherBankData = res;
      });
   }

   getAccommodations() {
      this.codebookService.getFamilyAccommodationPriceCodebook().subscribe(res => {
         this.accomodationData = res;
      });
   }

   getRightsCodebook() {
      this.codebookService.getRightsCodebook().subscribe(res => {
         this.rightsData = res;
      });
   }

   getServicesCodebook() {
      this.codebookService.getServicesCodebook().subscribe(res => {
         this.servicesData = res;
      });
   }

   getLegalProcedures() {
      this.codebookService.getLegalProceduresCodebook().subscribe(res => {
         this.legalProcedureData = res;
      });
   }

   getProfessionalProcedures() {
      this.codebookService.getProfessionalProceduresCodebook().subscribe(res => {
         this.professionalProcedureData = res;
      });
   }

   selectAccomodation(value: any) {
      this.currentAccomodation = value;
      this.accommodationFosterForm.controls.id.setValue(this.currentAccomodation.id);
      this.accommodationFosterForm.controls.validFrom.setValue(this.currentAccomodation.validFrom);
      this.accommodationFosterForm.controls.dailyAmount.setValue(patchForDecimalNumber(this.currentAccomodation.dailyAmount));
      this.accommodationFosterForm.controls.monthlyAmount.setValue(patchForDecimalNumber(this.currentAccomodation.monthlyAmount));
   }

   updateBankStatus(id: number, status: boolean) {
      this.globalAdminService.updateBankStatus(id, status).subscribe(
         res => {
            this.getBanks();
            this.cdr.detectChanges();
            this.isManageButtonVisible.bank = false;
            this.cipherBankForm = this.formBuilder.group({
               activeOption: ['', []],
            });
            this.successfulChange();
         },
         () => {
            this.errorOccured();
         }
      );
   }
}

