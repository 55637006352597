import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEnum } from 'src/app/entrance/types';
import { Subject } from 'src/models/subject.model';
import * as myLetters from '../../../../letters';

@Component({
    selector: 'app-brother-sister-form',
    templateUrl: './brother-sister-form.component.html',
    styleUrls: ['./brother-sister-form.component.scss']
})
export class BrotherSisterFormComponent implements OnInit {
    familyMembers: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<BrotherSisterFormComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public familyData: any,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.familyMembers = this.formBuilder.group({
            firstName: [
                '',
                [Validators.required, Validators.pattern(myLetters.letters)],
            ],
            lastName: [
                '',
                [Validators.required, Validators.pattern(myLetters.letters)],
            ],
            jmbg: [''],
            note: ['']
        });
        if (this.familyData.memberData) {
            this.patchForm(this.familyData.memberData);
        }
    }
    closeDialog() {
        this.dialogRef.close({ event: EventEnum.CANCEL });
    }

    addFamilyMembers() {
        this.dialogRef.close({ event: EventEnum.SUBMIT, data: this.familyMembers.value });
    }

    patchForm(data: Partial<Subject>) {
        const {
            firstName,
            lastName,
            jmbg,
        } = data;
        this.familyMembers.patchValue({
            firstName, 
            lastName,
            jmbg
        });
    }
}
