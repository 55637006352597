<container kind="mini">
  <div class="wrapper">
    <mat-card class="card">
      <mat-icon class="icon">warning</mat-icon>
      <header class="card__header">
        <h3 class="card__title">{{ 'NOT_FOUND.TITLE' | translate }}</h3>
      </header>
      <div class="card__description">
        <p>{{ 'NOT_FOUND.DESCRIPTION_ONE' | translate }}</p>
        <p>{{ 'NOT_FOUND.DESCRIPTION_TWO' | translate }}</p>
      </div>
      <button routerLink="/" mat-flat-button color="primary">
        {{ 'NOT_FOUND.BUTTON' | translate }}
      </button>
    </mat-card>
  </div>
</container>
