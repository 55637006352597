import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from 'src/app/utils/formatDate';
@Component({
  selector: 'app-submission-item',
  templateUrl: './submission-item.component.html',
  styleUrls: ['./submission-item.component.scss']
})
export class SubmissionItemComponent implements OnInit {
  dateFormat: string = '';

  @Input() link: string;
  @Input() title: string;
  @Input() date: Date;
  @Input() isExternal: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.dateFormat = formatDate(this.date);
  }

}
