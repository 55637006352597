/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'label-step-status',
  templateUrl: './label-step-status.component.html',
  styleUrls: ['./label-step-status.component.scss']
})
export class LabelStepStatusComponent implements OnInit {

  constructor() { }
  @Input() status: 'INITIAL' | 'STARTED' | 'FINISHED';
  
  ngOnInit(): void {
  }

}
