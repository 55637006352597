/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LegalSubject } from 'src/models/legalSubject.model';
import { User } from 'src/models/user.model';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { LegalSubjectsService } from 'src/services/legal-subjects.service';
import { SharedCaseService } from 'src/services/shared-case.service';
import { UserService } from 'src/services/user.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { PrintComponent } from '../components/print/print.component';
import { Page } from '../page';

export interface SearchItem {
   name: string;
}

@Component({
   selector: 'app-legal-subjects',
   templateUrl: './legal-subjects.component.html',
   styleUrls: ['./legal-subjects.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class LegalSubjectsComponent implements OnInit {
   selectable = true;
   removable = true;
   readonly separatorKeysCodes: number[] = [COMMA, ENTER];
   public searchTerms: string[] = [];
   areLoaded = false;
   public globalFilter: any = '';
   public searchItems: SearchItem[] = [];
   page: Page<LegalSubject> = new Page();
   public reset_courses = this.page;
   loggedIn: User = new User([], '', '', '', '', '', '', '', '');
   object: { origin: string };
   isUserDrugostepeni = false;
   displayedColumns: string[] = ['id', 'name', 'institutionType', 'address', 'city', 'phone', 'accountNumber', 'jbkbs', 'pib', 'identificationNumber'];
   fileUrl: any;
   paramsObject: any;
   loadParams = true;
   @ViewChild('pdfTable', { static: false }) legal: ElementRef;

   constructor(
      private sharedCaseService: SharedCaseService,
      private legalSubjectsService: LegalSubjectsService,
      public dialog: MatDialog,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      @Optional() public dialogRef: MatDialogRef<LegalSubjectsComponent>,
      private paginationService: CustomPaginationService,
      public router: Router,
      private route: ActivatedRoute,
      private userService: UserService
   ) {
      this.clearSharedCaseService(); // Treba da se obrise da ne bi pamtio tabove o detaljima predmeta
      this.object = { ...data };
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();

      this.getQueryParams();
      this.doFilter();
   }

   ngOnInit(): void {}

   public getNextPage(): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   public getPreviousPage(): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }
   public getFirstPage(): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter();
   }

   public getLastPage(): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter();
   }

   public getPageInNewSize(pageSize: any): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter();
   }

   onRowClicked(element: any) {
      if (this.object.origin === 'entrance') {
         this.dialogRef.close({ data: element });
      } else if (this.object.origin === 'nsp') {
         this.dialogRef.close({ data: element });
      } else {
         this.router.navigate(['/legal_subjects/', element.id]);
      }
   }

   doFilter() {
      this.setQueryParams();
      this.legalSubjectsService
         .getFilteredPage(this.page.pageable, this.globalFilter === '' ? null : this.globalFilter, this.globalFilter === '' ? null : true)
         .subscribe(page => {
            this.page = page;
            this.page.pageable.pagePerShow = page.number + 1;
            this.areLoaded = true;
         });
   }

   public printPDF(): void {
      const dialogRef = this.dialog.open(PrintComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: {
            maxNumber: this.page.totalElements,
            whole: null,
            partial: {
               from: null,
               to: null,
            },
            src: 'ls',
         },
      });

      dialogRef.afterClosed().subscribe(res => {
         if (res.event === ModalEventEnum.SUCCESS) {
            const printDto = {
               whole: res.data.whole,
               from: res.data.partial.from,
               to: res.data.partial.to,
            };
            this.legalSubjectsService.getPdf(printDto);
         }
      });
   }

   public exportCsv(): void {
      this.legalSubjectsService.exportCSV(this.searchTerms);
   }

   add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;

      // Add new search term
      if ((value || '').trim()) {
         this.searchItems.push({ name: value.trim() });
      }
      // Reset the input value
      if (input) {
         input.value = '';
      }
      this.searchTerms = this.searchItems.map(function (searchItem) {
         return searchItem.name;
      });
      this.globalFilter = '';
      this.applyFilter(this.searchTerms);
   }

   remove(item: SearchItem): void {
      const index = this.searchItems.indexOf(item);
      if (index >= 0) {
         this.searchItems.splice(index, 1);
         this.searchTerms = this.searchItems.map(function (searchItem) {
            return searchItem.name;
         });
         this.applyFilter(this.searchTerms);
      }
   }

   applyFilter(filterValue) {
      this.globalFilter = filterValue;
      this.page.pageable.pageNumber = 0;
      this.doFilter();
   }

   clearSharedCaseService() {
      this.sharedCaseService.tabs = [];
      this.sharedCaseService.activeTab = null;
   }

   setQueryParams() {
      let params = {
         pageNum: null,
         size: null,
         filter: this.globalFilter === '' ? null : this.globalFilter,
      };
      if (this.object.origin === undefined) {
         if (this.page.pageable.pageNumber !== 0) {
            params = {
               pageNum: this.page.pageable.pageNumber,
               size: this.page.pageable.pageSize,
               filter: this.globalFilter === '' ? null : this.globalFilter,
            };
         }
         this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
         });
      }
   }

   getQueryParams() {
      this.route.queryParamMap.subscribe(params => {
         if (this.loadParams === true) {
            this.paramsObject = { ...params.keys, ...params };
            const param = this.paramsObject.params;
            this.page.pageable.pageNumber = param?.pageNum ? param?.pageNum : this.page.pageable.pageNumber;
            this.page.pageable.pageSize = param?.size ? param?.size : this.page.pageable.pageSize;

            if (param?.filter) {
               this.searchItems = [];
               this.globalFilter = Array.isArray(param?.filter) ? param?.filter : [param?.filter];
               for (const filter of this.globalFilter) {
                  this.searchItems.push({ name: filter });
               }
            }
         }
      });
   }
}
