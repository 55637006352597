/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, empty } from 'rxjs';
import { AuthenticationService } from 'src/services/authentication.service';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public authService: AuthenticationService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.authService.getJwtToken()) {
            request = this.addToken(request, this.authService.getJwtToken());
        }

        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
            } else if (error instanceof HttpErrorResponse && error.status === 404) {
                this.router.navigateByUrl('**', {skipLocationChange: true});
                return empty();
            } else if (error instanceof HttpErrorResponse
                && error.status === 500
                && request.url.includes('users/refresh')) {
                    this.authService.cleanStorage();
            } else {
                return throwError(error);
            }
        }));
    }

    private addToken(request: HttpRequest<any>, token: string) {
        let cloned;
        if (request.url.endsWith('/users/refresh')) {
            cloned = request;
        } else {
            cloned = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return cloned;
    }


    public handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshing) {
            this.isRefreshing = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.refreshTokenSubject.next(null);
            // get a new token via userService.refreshToken
            return this.authService.refreshToken()
                .pipe(switchMap((newToken) => {
                    // did we get a new token retry previous request
                    if (newToken) {
                        this.refreshTokenSubject.next(newToken.access_token);
                        return next.handle(this.addToken(request, newToken.access_token));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    this.authService.logout();
                    return throwError('');
                })
                    , catchError(error => {
                        // If there is an exception calling 'refreshToken', bad news so logout.
                        console.log(error)
                        this.authService.logout();
                        return throwError('');
                    })
                    , finalize(() => {
                        this.isRefreshing = false;
                    })
                );
        } else {
            return this.refreshTokenSubject
                .pipe(
                    filter(token => token != null)
                    , take(1)
                    , switchMap(token => {
                        return next.handle(this.addToken(request, token));
                    })
                );
        }

    }

}
