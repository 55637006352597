/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';
import * as myLetters from './../../../../letters';

@Component({
  selector: 'new-foster-parent-family-information-step',
  templateUrl: './new-foster-parent-family-information-step.component.html',
  styleUrls: ['./new-foster-parent-family-information-step.component.scss']
})
export class NewFosterParentFamilyInformationStepComponent implements OnInit {
  @Output() familyInformationStepEmitter = new EventEmitter();
  @Input() familyInformationStep: FormGroup;

  ageChildOptions: any = [];
  residentalStatusOptions: any = [];
  genderOptions: any = [];
  yesNoOptions = [
    {value: true, viewValue: 'GLOBAL.YES'},
    {value: false, viewValue: 'GLOBAL.NO'}
 ];
  constructor(private formBuilder: FormBuilder, private codebookService: CodebookService) {
    this.getAgeChild();
    this.getResidentalStatus();
    this.getGender();
  }


  ngOnInit(): void {
    this.familyInformationStep = this.formBuilder.group({
      householdStatus: ['', [Validators.required]],
      employmentIncome: ['', [Validators.pattern(myLetters.number)]],
      privateActivityIncome: ['', [Validators.pattern(myLetters.number)]],
      pensionIncome: ['', [Validators.pattern(myLetters.number)]],
      otherIncome: ['', [Validators.pattern(myLetters.number)]],
      monthlyIncome: ['', [Validators.pattern(myLetters.number)]],
      perHouseholdMemberIncome: ['', [Validators.pattern(myLetters.number)]],
      familyMemberCount: ['', [Validators.required, Validators.pattern(myLetters.number)]],
      childrenCount: ['', [Validators.required, Validators.pattern(myLetters.number)]],
      acceptFosterChildrenCount: ['', [Validators.required, Validators.pattern(myLetters.number)]],
      fosterChildrenGender: ['', []],
      ageToAccept: ['', []],
      acceptDifferentNationality: [null, []],
      acceptChildrenWithSpecialNeeds: [null, []],
      acceptChildrenWithBehaviourDisorder: [null, []]
    });
  }

  /**
   * Send step information to parent component
   */
  updateFamilyInformationStep() {
    this.familyInformationStepEmitter.emit(this.familyInformationStep);
  }

  getAgeChild() {
    this.codebookService.getAgeChildCodebook().subscribe((res) => { this.ageChildOptions = res; }
    );
  }


  getResidentalStatus() {
    this.codebookService.getResidentalStatusCodebook().subscribe((res) => { this.residentalStatusOptions = res; }
    )
  }

  getGender() {
    this.codebookService.getGenderCodebook().subscribe((res) => { this.genderOptions = res; }
    );
  }

}
