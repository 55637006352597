/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'new-foster-child-suitability-step',
  templateUrl: './new-foster-child-suitability-step.component.html',
  styleUrls: ['./new-foster-child-suitability-step.component.scss']
})
export class NewFosterChildSuitabilityStepComponent implements OnInit {
  @Output() suitabilityStepEmitter = new EventEmitter();
  @Input() suitabilityStep: FormGroup;
  currentDate = new Date();
  selectedReportIds: Array<number> = [];

  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder) { }

  reportOptions = [{ id: 1, view: 'Muški' }, { id: 2, view: 'Ženski' }];

  ngOnInit(): void {
    this.suitabilityStep = this.formBuilder.group({
      reportDates: this.formBuilder.group({
        psychologist: ['', [Validators.required]],
        pedagogue: ['', [Validators.required]],
        socialWorker: ['', [Validators.required]],
        lawyer: ['', [Validators.required]],
        doctor: ['', [Validators.required]],
        otherInstitutions: ['', [Validators.required]],
      }),
      decisionNumber: ['', [Validators.required]],
      decisionDate: ['', [Validators.required]],
    });
  }

  /**
   * Send step information to parent component
   */
  updateSuitabilityStep() {
    this.suitabilityStep.get('reportDates').get('psychologist').setValue(
      this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('psychologist').value).getTime(), 'yyyy-MM-dd'));
    this.suitabilityStep.get('reportDates').get('pedagogue').setValue(
      this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('pedagogue').value).getTime(), 'yyyy-MM-dd'));
    this.suitabilityStep.get('reportDates').get('socialWorker').setValue(
      this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('socialWorker').value).getTime(), 'yyyy-MM-dd'));
    this.suitabilityStep.get('reportDates').get('lawyer').setValue(
      this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('lawyer').value).getTime(), 'yyyy-MM-dd'));
    this.suitabilityStep.get('reportDates').get('doctor').setValue(
      this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('doctor').value).getTime(), 'yyyy-MM-dd'));
    this.suitabilityStep.get('reportDates').get('otherInstitutions').setValue(
      this.datePipe.transform(new Date(this.suitabilityStep.get('reportDates').get('otherInstitutions').value).getTime(), 'yyyy-MM-dd'));
    this.suitabilityStepEmitter.emit(
      this.suitabilityStep
    );
  }
}
