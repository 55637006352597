/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { DependentPersonService } from 'src/services/dependent-person.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import * as myLetters from './../../../letters';
import { NewDependentTableFiveComponent } from './new-dependent-table-five/new-dependent-table-five.component';
import { NewDependentTableOneComponent } from './new-dependent-table-one/new-dependent-table-one.component';
import { NewDependentTableSixComponent } from './new-dependent-table-six/new-dependent-table-six.component';
import { NewDependentTableTwoComponent } from './new-dependent-table-two/new-dependent-table-two.component';

type TableCountType = 'tableOne' | 'tableTwo' | 'tableThree' | 'tableFour' | 'tableFive' | 'tableSix';

interface TableOne {
  id: number;
  courtName: string;
  courtNumber: string;
  courtDate: string;
  courtValidityDate: string;
  dateOfVerdictDelivery: string;
  dateOfCommencment: string;
}

interface TableTwo {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  placeOfBirth: string;
  jmbg: string;
  address: string;
  legalRelation: string;
}

interface TableFive {
  id: number;
  courtName: string;
  courtNumberAndDate: string;
  dateOfVerdictDelivery: string;
  dateOfCommencment: string;
}

interface TableSix {
  id: number;
  courtName: string;
  courtNumberAndDate: string;
  dateOfVerdictDelivery: string;
  dateOfCommencment: string;
}

@Component({
  selector: 'new-dependent',
  templateUrl: './new-dependent.component.html',
  styleUrls: ['./new-dependent.component.scss']
})
export class NewDependentComponent implements OnInit {
  currentDate = new Date();
  newRecord: FormGroup;
  displayedColumns: {
    tableOne: string[],
    tableTwo: string[],
    tableThree: string[],
    tableFour: string[],
    tableFive: string[],
    tableSix: string[],
  } = {
      tableOne: [
        'courtName',
        'courtNumber',
        'courtDate',
        'courtValidityDate',
        'dateOfVerdictDelivery',
        'dateOfCommencment',
      ],
      tableTwo: [
        'firstAndLastName',
        'dateAndPlaceOfBirth',
        'jmbg',
        'address',
        'legalRelation',
      ],
      tableThree: [
        'firstAndLastName',
        'dateAndPlaceOfBirth',
        'jmbg',
        'address',
        'legalRelation',
      ],
      tableFour: [
        'firstAndLastName',
        'dateAndPlaceOfBirth',
        'jmbg',
        'address',
        'legalRelation',
      ],
      tableFive: [
        'supportMannerMoney',
        'supportMannerOther',
        'supportAmmountFiscal',
        'supportAmmountPercentage',
        'supportDurationLimited',
        'supportDurationUnlimited',
      ],
      tableSix: [
        'courtName',
        'courtNumber',
        'courtDate',
        'dateOfVerdictTermination',
        'reasonsForVerdictTermination',
      ],
    };
  table: {
    tableOne: TableOne[];
    tableTwo: TableTwo[];
    tableThree: TableTwo[];
    tableFour: TableTwo[];
    tableFive: TableFive[];
    tableSix: TableSix[];
  } = {
      tableOne: [],
      tableTwo: [],
      tableThree: [],
      tableFour: [],
      tableFive: [],
      tableSix: [],
    };
  isTableRecordSelected = {
    tableOne: false,
    tableTwo: false,
    tableThree: false,
    tableFour: false,
    tableFive: false,
    tableSix: false,
  };
  selectedTableRecord = {
    tableOne: null,
    tableTwo: null,
    tableThree: null,
    tableFour: null,
    tableFive: null,
    tableSix: null,
  };
  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private dependentPersonService: DependentPersonService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrImplService) { }

  ngOnInit(): void {
    this.newRecord = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
      lastName: ['', [Validators.required, Validators.pattern(myLetters.letters)]],
      subjectId: ['', [Validators.required, Validators.pattern(myLetters.number)]],
      registrationId: [''],
      dateOfBirth: [null],
      jmbg: ['']
    });
  }

  createRecord(newRecord: any) {
    // Submit new record
    this.createNewDependentPerson();
  }

  createNewDependentPerson() {
    // olja.andjelovski@iten.rs
    // Moraju id-jevi da se postave na null zato sto se kod nas u bazi automatski generisu, ukoliko bi imao
    // kreiran id uzeo bi taj sto bi prouzrokovalo gresku ukoliko kod nas u bazi ima vec neko sa tim id-jem
    this.table.tableOne.forEach((x => { x.id = null; }));
    this.table.tableTwo.forEach((x => { x.id = null; }));
    this.table.tableThree.forEach((x => { x.id = null; }));
    this.table.tableFour.forEach((x => { x.id = null; }));
    this.table.tableFive.forEach((x => { x.id = null; }));
    this.table.tableSix.forEach((x => { x.id = null; }));

    const body = {
      firstName: this.newRecord.value.firstName,
      lastName: this.newRecord.value.lastName,
      jmbg: this.newRecord.value.jmbg,
      dateOfBirth: this.newRecord.value.dateOfBirth !== null ? this.datePipe.transform(new Date(this.newRecord.value.dateOfBirth).getTime(), 'dd/MM/yyyy') : null,
      subjectId: this.newRecord.value.subjectId,
      registrationId: this.newRecord.value.registrationId,
      courtJudgementInfo: this.table.tableOne,
      creditorInfo: this.table.tableTwo,
      representativeCreditorInfo: this.table.tableThree,
      debtorInfo: this.table.tableFour,
      determinedSupportInfo: this.table.tableFive,
      terminationSupportInfo: this.table.tableSix,
    };
    this.dependentPersonService.createDependentPerson(body).subscribe((res) => {
      if (res != null) {
        this.toastr.success('SNACKBAR.ADDED_DEPEDENT_PERSON');
        this.router.navigateByUrl('/dependents');
      } else {
        this.toastr.error('SNACKBAR.ADD_DEPEDENT_PERSON_ERROR');
        this.router.navigateByUrl('/dependents');
      }
    });

  }

  addTableRecord(tableCount: TableCountType): void {
    let dialogRef: any;
    if (tableCount === 'tableOne') {
      dialogRef = this.dialog.open(NewDependentTableOneComponent, {
        width: ModalSizeEnum.EXTRA_LARGE,
        panelClass:'overlay-panel',
      });
    }

    if (tableCount === 'tableTwo' || tableCount === 'tableThree' || tableCount === 'tableFour') {
      dialogRef = this.dialog.open(NewDependentTableTwoComponent, {
        width: ModalSizeEnum.EXTRA_LARGE,
        panelClass:'overlay-panel',
      });
    }

    if (tableCount === 'tableFive') {
      dialogRef = this.dialog.open(NewDependentTableFiveComponent, {
        width: ModalSizeEnum.EXTRA_LARGE,
        panelClass:'overlay-panel',
      });
    }

    if (tableCount === 'tableSix') {
      dialogRef = this.dialog.open(NewDependentTableSixComponent, {
        width: ModalSizeEnum.EXTRA_LARGE,
        panelClass:'overlay-panel',
      });
    }

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.event === ModalEventEnum.SUCCESS) {
        const newRecord = {
          id: this.table[tableCount].length,
          ...result.data,
        };

        this.table[tableCount] = [...this.table[tableCount], newRecord];
        this.toastr.success('SNACKBAR.INPUT_ADDED');
      }
    });
  }

  onRowSelected(tableCount: TableCountType, row: any) {
    if (this.selectedTableRecord[tableCount] !== null) {
      this.selectedTableRecord[tableCount].highlighted =
        !this.selectedTableRecord[tableCount].highlighted;
    }
    row.highlighted = !row.highlighted;
    this.selectedTableRecord[tableCount] = row;
    this.isTableRecordSelected[tableCount] = true;
  }

  removeTableRecord(tableCount: TableCountType) {
    // @ts-ignore // todo: type me better
    const updatedState = this.table[tableCount].filter(
      (child: any) => child.id !== this.selectedTableRecord[tableCount].id
    );
    this.table[tableCount] = updatedState;
    this.isTableRecordSelected[tableCount] = false;
    this.toastr.success('SNACKBAR.DELETED_ELEMENT');
  }

  importSubjectData() {
    const dialogRef = this.dialog.open(SubjectsComponent, {
      width: '1200px',
      panelClass:'overlay-panel',
      data: {
        origin: 'entrance',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.newRecord.patchValue({
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          subjectId: result.data.subjectIdentity.subjectId,
          registrationId: result.data.registrationId,
          jmbg: result.data.jmbg,
          dateOfBirth: result.data.dateOfBirth,
        });
        const obj = JSON.parse(JSON.stringify(result));

      }
    });
  }
}
