/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})
export class AppService {
   authenticated = false;

   constructor() {}

   getUrl() {
      let url: string;

      if (environment.deployed) {
         url = window.location.protocol + '//' + window.location.hostname;
         // if port is specified in the url then it will be shown to you
         if (window.location.port) {
            url += ':' + window.location.port;
         }
      } else {
         url = 'http://localhost:8080';
      }

    return url;
  }

  getUrlNotifications() {
    let url: string;

    if (environment.deployed) {
      url = window.location.protocol + '//' + window.location.hostname;
      // if port is specified in the url then it will be shown to you
      if (window.location.port) {
        url += ':' + window.location.port;
      }
    } else {
      url = 'http://localhost:8085';
    }

    return url;
  }

   getDocManagerUrl() {
      let url: string;
      url = window.location.protocol + '//' + window.location.hostname + environment.documentManagerPort ;
      return url;
   }
}
