import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
   providedIn: 'root',
})
export class ToastrImplService {
   constructor(private toastr: ToastrService, private translate: TranslateService) {}

   success(text: string, title: string = null) {
      this.translate.get(text).subscribe((translatedText: string) => {
         if (title) {
            this.translate.get(title).subscribe((translatedTitle: string) => {
               this.toastr.success(translatedText, translatedTitle, { timeOut: 15000 });
            });
         } else {
            this.toastr.success(translatedText, null, { timeOut: 15000 });
         }
      });
   }

   warning(text: string, title: string = null) {
      this.translate.get(text).subscribe((translatedText: string) => {
         if (title) {
            this.translate.get(title).subscribe((translatedTitle: string) => {
               this.toastr.warning(translatedText, translatedTitle, { timeOut: 15000 });
            });
         } else {
            this.toastr.warning(translatedText, null, { timeOut: 15000 });
         }
      });
   }

   error(text: string, title: string = null) {
      this.translate.get(text).subscribe((translatedText: string) => {
         if (title) {
            this.translate.get(title).subscribe((translatedTitle: string) => {
               this.toastr.error(translatedText, translatedTitle, { timeOut: 15000 });
            });
         } else {
            this.toastr.error(translatedText, null, { timeOut: 15000 });
         }
      });
   }

   info(text: string, title: string = null) {
      this.translate.get(text).subscribe((translatedText: string) => {
         if (title) {
            this.translate.get(title).subscribe((translatedTitle: string) => {
               this.toastr.info(translatedText, translatedTitle, { timeOut: 15000 });
            });
         } else {
            this.toastr.info(translatedText, null, { timeOut: 15000 });
         }
      });
   }

   showSuccess(message: string, timeOut?: number) {
      this.toastr.success(message, null, { timeOut: timeOut || 15000 });
   }

   showInfo(message: string, timeOut?: number) {
      this.toastr.info(message, null, { timeOut: timeOut || 15000 });
   }
   showWarning(message: string, timeOut?: number) {
      this.toastr.warning(message, null, { timeOut: timeOut || 15000 });
   }

   showError(message: string, timeOut?: number) {
      this.toastr.error(message, null, { timeOut: timeOut || 15000 });
   }
   showErrorTitle(message: string, title: string, timeOut?: number) {
      this.toastr.error(message, title, { timeOut: timeOut || 15000 });
   }
   showWarningTitle(message: string, title: string, timeOut?: number) {
      this.toastr.warning(message, title, { timeOut: timeOut || 15000 });
   }

   errorOnlyTitle(title: string, timeOut?: number){
      this.toastr.error('', title, { timeOut: timeOut || 15000 });

   }
   successOnlyTitle(title: string, timeOut?: number){
      this.toastr.success('', title, { timeOut: timeOut || 15000 });

   }
}
