/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

/**
 * formatDate — render date in dd.mm.yyyy format
 * @param date - Date
 */
export const formatDate = (date: number | Date | string): string => {
  if ((typeof date) === 'string' && date.toString().includes('T')) {
    date = date.toString().split('T')[0];
  }
  // else if (date instanceof Date) {
  //   date = date.toString();
  //   if (date.includes('T')) {
  //     date = this.date.split('T')[0];
  //   }
  // }
  const d = new Date(date);
  const dd = String(d.getDate()).padStart(2, '0');
  const mm = String(d.getMonth() + 1).padStart(2, '0');
  const yyyy = d.getFullYear();

  return `${dd}.${mm}.${yyyy}.`;
};
