import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'warning-session',
  templateUrl: './warning-session.component.html',
  styleUrls: ['./warning-session.component.scss']
})
export class WarningSessionComponent{
  @Output() closeWarning: EventEmitter<any> = new EventEmitter();
  @Input() time:number
  @Input() isOpen:boolean=false

  close(){
    this.closeWarning.emit(false)
  }
}