import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodebookService } from 'src/services/codebook.service';
import { ModalEventEnum } from 'src/types';

@Component({
  selector: 'termination-of-foster-care',
  templateUrl: './termination-of-foster-care.component.html',
  styleUrls: ['./termination-of-foster-care.component.scss']
})
export class TerminationOfFosterCareComponent implements OnInit {
  terminationForm: FormGroup;
  currentDate = new Date();
  reasonsForCustodyTerminations = [];
  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    private codebookService: CodebookService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getReasonsForTerminationOfFosterCare();

  }


  ngOnInit(): void {
    this.terminationForm = this.formBuilder.group({
      courtName: [this.data.child.courtName],
      courtNumber: [this.data.child.courtNumber],
      dateOfCustodyTermination: [this.data.child.dateOfCustodyTermination, [Validators.required]],
      reasonsForCustodyTermination: [this.data.child.reasonsForCustodyTermination, [Validators.required]],
    });
  }

  getReasonsForTerminationOfFosterCare() {
    this.codebookService.getReasonsForTerminationOfFosterCareCodebook().subscribe(
      result => {
        this.reasonsForCustodyTerminations = result;
      });
  }

  addNewRecord(trackingDocumentForm: FormGroup) {
    trackingDocumentForm.get('dateOfCustodyTermination').setValue(
          this.datePipe.transform(new Date(trackingDocumentForm.value.dateOfCustodyTermination).getTime(), 'yyyy-MM-dd'));
    this.dialogRef.close({
      event: ModalEventEnum.SUCCESS,
      data: trackingDocumentForm.value,
    });
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id && object1.title === object2.title;
  }

  closeDialog() {
    this.dialogRef.close({ event: ModalEventEnum.CANCEL });
  }
}
