/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { SubjectsComponent } from '../subjects/subjects.component';

import {
   BreadWinnerStatuses,
   BreadWinnerStatusesEnum,
   ChildSchoolEnum,
   ChildSchoolStatuses,
   DependentStatuses,
   DependentStatusesEnum,
   EmploymentOptions,
   EmploymentOptionsEnum,
   EngagementStatuses,
   EngagementStatusesEnum,
   FamilyRevenues,
   HouseInfo1Enum,
   HouseInfo1Statuses,
   HousingStatuses,
   HousingStatusesEnum,
   RevenueEnum,
   SubmitterData,
   YesOrNoEnum,
   YesOrNoStatuses,
} from '../entrance/types';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CodebookService } from 'src/services/codebook.service';
import { NspRequestService } from 'src/services/nsp-request.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum } from 'src/types';
import { LocalStorageService } from '../local-storage.service';
import { autoSelect, sortByFirstLetter } from '../utils/autoSelect';
import { getNumberFromString, patchForDecimalNumber } from '../utils/formatLocalNumber';
import * as myLetters from './../letters';
import { NspFamilyRequestComponent } from './nsp-family-request/nsp-family-request.component';

enum AutocompleteFilterEnum {
   CITIZENSHIP = 'citizenship',
   NATIONALITY = 'nationality',
   OCCUPATION = 'occupation',
   RELATIONSHIP = 'relationship'
}

@Component({
   selector: 'app-nsp-request',
   templateUrl: './nsp-request.component.html',
   styleUrls: ['./nsp-request.component.scss'],
   providers: [DatePipe],
})
export class NspRequestComponent implements OnInit {

   @Output() nspReqEmmiter = new EventEmitter();
   @Input() editable: boolean;
   @ViewChild('submitButton') submitButton;
   @ViewChild ('multiSearch') private multiSearch: ElementRef;
   relationships = [];
   relationshipsFiltered: Observable<any[]>;
   firstStep: FormGroup;
   secondStep: FormGroup;
   thirdStep: FormGroup;
   fourthStep: FormGroup;
   submitterData: SubmitterData;
   isSaved = false;
   currentDate = new Date();
   center: string;
   showDate: string;
   members = [];
   subjectIdentity = {
      subjectId: null,
      csrId: null
   };
   memberRegs = [];
   numberOfNSPF = 0;
   numberOfNSPFMade = 0;
   isRevSelectionClose: boolean;
   revenueSelected: Array<string> = [];
   revenues = new FormControl();
   languages = [];
   educations = [];
   maritalStatuses = [];
   employments = [];
   genderOptions = [];

   citizenships = [];
   filteredCitizenshipOptions: Observable<any[]>;

   nationalities = [];
   filteredNationalityOptions: Observable<any[]>;

   occupations = [];
   filteredOccupationOptions: Observable<any[]>;

   nspRightCodebook = [];
   nspAmount = 0;

   notMadeEnoughNspPMessage = '';
   maxDate: Date = new Date(2999, 11, 31);
   @ViewChild(MatTable, { static: true }) table: MatTable<any>;

   constructor(
      public dialog: MatDialog,
      public nspRequestService: NspRequestService,
      private datePipe: DatePipe,
      private formBuilder: FormBuilder,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private router: Router,
      private codebookService: CodebookService,
      private subjectService: SubjectsService,
      private toastr: ToastrImplService
   ) {
      this.center = (JSON.parse(this.localStorageService.get('loggedUser'))).center;
      this.showDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
      this.codebookService.getGenderCodebook().subscribe(
         result => {
            this.genderOptions = result;
         }
      );
      this.codebookService.getLanguageCodebook().subscribe(
         result => {
            this.languages = result;
         }
      );
      this.codebookService.getNationalityCodebook().subscribe(
         result => {
            this.nationalities = result;
            this.filteredNationalityOptions = this.firstStep.controls.nationality.valueChanges.pipe(
               startWith(''),
               map(value => typeof value === 'string' ? value : value?.title),
               map(title => title ? this._filter(title, AutocompleteFilterEnum.NATIONALITY) : this.nationalities.slice())
            );
         }
      );
      this.codebookService.getCitizenshipCodebook().subscribe(
         result => {
            this.citizenships = result;
            this.filteredCitizenshipOptions = this.firstStep.controls.citizenship.valueChanges.pipe(
               startWith(''),
               map(value => typeof value === 'string' ? value : value?.title),
               map(title => title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenships.slice())
            );
         }
      );
      this.codebookService.getEducationCodebook().subscribe(
         result => {
            this.educations = result;
         }
      );
      this.codebookService.getMaritalStatusCodebook().subscribe(
         result => {
            this.maritalStatuses = result;
         }
      );
      this.codebookService.getEmploymentCodebook().subscribe(
         result => {
            this.employments = result;
         }
      );
      this.codebookService.getOccupationCodebook().subscribe(
         result => {
            this.occupations = result;
            this.filteredOccupationOptions = this.firstStep.controls.occupation.valueChanges.pipe(
               startWith(''),
               map(value => typeof value === 'string' ? value : value?.title),
               map(title => title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupations.slice())
            );
         }
      );
      this.codebookService.getRelationshipCodebookWithoutUser().subscribe(
         result => {
            this.relationships = result;
            this.relationshipsFiltered = this.fourthStep.controls.relationship.valueChanges.pipe(
               startWith(''),
               map(value => typeof value === 'string' ? value : value?.title),
               map(title => title ? this._filter(title, AutocompleteFilterEnum.RELATIONSHIP) : this.relationships.slice())
            );
         }
      );

      this.codebookService.getNspRightCodebookForDate(new Date())
         .subscribe((res) => {
            this.nspRightCodebook = res;
         });
   }

   childSchoolStatuses: ChildSchoolStatuses[] = [
      { value: ChildSchoolEnum.IDU_U_SKOLU, viewValue: 'Редовно иду у школу' },
      { value: ChildSchoolEnum.NE_IDU_U_SKOLU, viewValue: 'Не иду у школу' }
   ];

   housingStatuses: HousingStatuses[] = [
      { value: HousingStatusesEnum.VLASNIK_SUVLASNIK, viewValue: 'Власник/сувласник' },
      { value: HousingStatusesEnum.NOSILAC_PRAVA, viewValue: 'Носилац станарског права' },
      { value: HousingStatusesEnum.SUSTANAR, viewValue: 'Сустанар' },
      { value: HousingStatusesEnum.ZAKUPAC_STANA, viewValue: 'Закупац стана' },
      { value: HousingStatusesEnum.ZASTICENI_STANAR, viewValue: 'Заштићени станар' },
      { value: HousingStatusesEnum.OSTALO, viewValue: 'Остало' }
   ];

   houseInfo1Statuses: HouseInfo1Statuses[] = [
      { value: HouseInfo1Enum.NE_ODGOVARA_POTREBAMA, viewValue: 'Не одговара потребама' },
      { value: HouseInfo1Enum.ODGOVARA_POTREBAMA, viewValue: 'Одговара потребама' },
      { value: HouseInfo1Enum.PREVAZILAZI_POTREBE, viewValue: 'Превазилази потребе породице' }
   ];

   houseInfo2Statuses: YesOrNoStatuses[] = [
      { value: YesOrNoEnum.DA, viewValue: 'Да' },
      { value: YesOrNoEnum.NE, viewValue: 'Не' }
   ];

   dependentStatuses: DependentStatuses[] = [
      { value: DependentStatusesEnum.SRODNICI_UCESTVUJU, viewValue: 'Има сроднике који учествују у његовом издржавању' },
      { value: DependentStatusesEnum.SRODNICI_NE_UCESTVUJU, viewValue: 'Има сроднике који не учествују у његовом издржавању' },
      { value: DependentStatusesEnum.NEMA_SRODNIKE, viewValue: 'Нема сроднике који су обавезни да га издржавају' }
   ];

   breadWinnerStatuses: BreadWinnerStatuses[] = [
      { value: BreadWinnerStatusesEnum.NEMA_OBAVEZU, viewValue: 'Нема обавезу издржавања' },
      { value: BreadWinnerStatusesEnum.IMA_OBAVEZU, viewValue: 'Има обавезу издржавања' }
   ];

   engagementStatuses: EngagementStatuses[] = [
      { value: EngagementStatusesEnum.UKLJUCIVANJE_U_SKOLOVANJE, viewValue: 'Укључивање у школовање' },
      { value: EngagementStatusesEnum.OSPOSOBLJAVANJE_ZA_RAD, viewValue: 'Оспособљавање за рад' },
      { value: EngagementStatusesEnum.RADNO_ANGAZOVANJE, viewValue: 'Радно ангажовање' },
      { value: EngagementStatusesEnum.DRUSTVENO_ANGAZOVANJE, viewValue: 'Друштвено ангажовање' }
   ];

   employmentOptions: EmploymentOptions[] = [
      { value: EmploymentOptionsEnum.POSLODAVAC, viewValue: 'Код послодавца' },
      { value: EmploymentOptionsEnum.POLJOPRIVREDNIK, viewValue: 'Пољопривредник' },
      { value: EmploymentOptionsEnum.SAMOSTALNA_DELATNOST, viewValue: 'Самостална делатоност' }
   ];

   // Prihodi korisnika u trecem koraku za NSP-Z
   familyRevenues: FamilyRevenues[] = [
      { value: RevenueEnum.PO_OSNOVU_RADNOG_ODNOSA, viewValue: 'Приход по основу радног односа' },
      { value: RevenueEnum.OD_POVREMENIH_POSLOVA, viewValue: 'Приход од повремених и привремених послова' },
      { value: RevenueEnum.PENZIJA_INVALIDNINA, viewValue: 'Приход пензија, инвалиднина и примања ратних инвалида' },
      { value: RevenueEnum.POLJOPRIVREDNE_DELATNOSTI, viewValue: 'Приход од пољопривредне делатности ' },
      { value: RevenueEnum.PO_OSNOVU_SAMOSTALNE_DELATNOSTI, viewValue: 'Приход по основу обављања самосталне делатности' },
      { value: RevenueEnum.ZA_VREME_NEZAPOSLENOSTI, viewValue: 'Накнада за време незапослености' },
      { value: RevenueEnum.PO_OSNOVU_SOCIJALNIH_PROGRAMA, viewValue: 'Отпремнина и накнада по основу социјалних програма' },
      { value: RevenueEnum.IZDAVANJE_U_ZAKUP, viewValue: 'Приход од издавања у закуп непокретне имовине' },
      { value: RevenueEnum.IMOVINSKA_PRAVA_I_AKCIJE, viewValue: 'Приход од имовинских права и акција' },
      { value: RevenueEnum.STEDNJA_I_GOTOVINA, viewValue: 'Улози дати на штедњу и готов новац' },
      { value: RevenueEnum.SEZONSKI_POSLOVI, viewValue: 'Приход од сезонских и других послова' },
      { value: RevenueEnum.IZDRAVANJE_SRODNIKA, viewValue: 'Приход од издржавања сродника и других правних основа' }
   ];
   filteredFamilyRevenues = this.familyRevenues;
   compareGenderObjects(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id && object1.gender === object2.gender && object1.code === object2.code;
   }

   ngOnInit(): void {
      this.firstStep = this.formBuilder.group({
         center: [
            this.center.length > 0 ? this.center : 'FIX ME',
         ],
         nspRequestIdentity: ['', []],
         dateOfSubmission: [this.showDate, [Validators.required]],
         firstName: ['', [Validators.pattern(myLetters.letters)]],
         lastName: ['', [Validators.pattern(myLetters.letters)]],
         parentName: ['', [Validators.pattern(myLetters.letters)]],
         dateOfBirth: ['', []],
         jmbg: ['', [Validators.pattern('^[0-9]*$')]],
         placeOfBirth: ['', [, Validators.pattern(myLetters.letters)]],
         gender: ['', []],
         citizenship: ['', []],
         nationality: ['', []],
         address: ['', [Validators.pattern(myLetters.letters)]],
         addressNumber: ['', [Validators.pattern(myLetters.addressSubnumber)]],
         emailAddress: ['', [Validators.email]],
         phone: ['',
            [Validators.minLength(9),
            Validators.maxLength(13),
            Validators.pattern('^[0-9/-]*$')]],
         mobile: ['',
            [Validators.minLength(9),
            Validators.maxLength(13),
            Validators.pattern('^[0-9-/]*$')]],
         maritalStatus: ['', []],
         education: ['', []],
         occupation: ['', []],
         employmentStatus: ['', [/* Validators.required */]],
         employmentStatusOther: ['', [/* Validators.required */]],
         employment: ['', [/* Validators.required */]],
         employmentOther: ['', [/* Validators.required */]],
         employerName: ['', [/* Validators.required */]],
         independentAgency: ['', [/* Validators.required */]],
         agriculturist: ['', [/* Validators.required */]],
         yearOfBirth: [''],
         foreigner: [false]
      });

      this.secondStep = this.formBuilder.group({
         familyMembersNumb: ['', [Validators.pattern('^[0-9]*$')]],
         minorChildrenNumb: ['', [Validators.pattern('^[0-9]*$')]],
         childSchoolStatus: ['', []],
         housingStatus: ['', []],
         housingStatusOther: ['', []],
         houseConcept: ['', [Validators.pattern('^[0-9.,]*$')]],
         houseArea: ['', [Validators.pattern('^[0-9.,]*$')]],
         houseInfo1: ['', []],
         houseInfo2: ['', []],
         totalLandArea: ['', [Validators.pattern('^[0-9.,]*$')]],
      });

      this.thirdStep = this.formBuilder.group({
         familyRevenue: ['', []],
         revenue01: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue02: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue03: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue04: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue05: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue06: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue07: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue08: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue09: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue10: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue11: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue12: ['', [Validators.pattern('^[0-9.,]*$')]],
         revenue13: ['', [Validators.pattern('^[0-9.,]*$')]],
      });

      this.fourthStep = this.formBuilder.group({
         dependentStatus: ['', [/* Validators.required */]],
         breadWinnerStatus: ['', [/* Validators.required */]],
         engagementStatus: ['', [/* Validators.required */]],
         firstLastName: ['', [/* Validators.required */]],
         relationship: ['', [Validators.required]],
         importantInfo: ['', [/* Validators.required */]],
         familyMembersNumber: ['', [Validators.pattern('^[0-9]*$')]],
         evidence01: ['', [/* Validators.required */]],
         evidence02: ['', [/* Validators.required */]],
         evidence03: ['', [/* Validators.required */]],
         evidence04: ['', [/* Validators.required */]],
         evidence05: ['', [/* Validators.required */]],
         evidence06: ['', [/* Validators.required */]],
      });


   }

   // Prikupljanje selektovanih prihoda iz liste potencijalnih prihoda za korisnika.
   selectChangeHandler(event: any) {
      this.cleanDynamicRevenueFiealds(event.value);
      this.revenueSelected = event.value;
   }

   saveNSPRequest() {
      const submitData = this.createNspRequestObject();
      if (this.firstStep.value.jmbg !== '' && this.firstStep.value.foreigner !== true) {
         this.checkJMBGRule();
         this.updateJMBGOnGenderChange('');
         this.updateJMBGOnDateOfBirthChange('');
      }
      if (!this.firstStep.valid) {
         return;
      }
      this.nspRequestService.addNspRequest(submitData).subscribe(
         (result) => {
            const file = new Blob([result], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const anchor = document.createElement('a');
            anchor.download = 'Захтев за НСП.pdf';
            anchor.href = fileURL;
            anchor.click();
            // window.open(fileURL);
            this.isSaved = true;
            this.toastr.success('SNACKBAR.NSP_REQUEST_SAVED');
            this.router.navigateByUrl('/');
         }, () => {
            this.submitButton.disabled = false;
         }
      );
   }

   autoSelectItem(arr: any[], input:string, form: any){
      return autoSelect(arr, input, 'title', form);
   }

   importSubjectData(): void {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         panelClass: 'overlay-panel',
         // width: ModalSizeEnum.DEFAULT,
         width: '1200px',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe((result) => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            this.subjectIdentity = result.data.subjectIdentity;
            if (obj.data.dateOfBirth) {
               obj.data.dateOfBirth = new Date(obj.data.dateOfBirth);
            }
            const {
               data: {
                  firstName,
                  lastName,
                  parentName,
                  dateOfBirth,
                  foreigner,
                  jmbg,
                  placeOfBirth,
                  gender,
                  citizenship,
                  nationality,
                  permanentResidence,
                  phone,
                  mobile,
                  maritalStatus,
                  education,
                  occupation,
                  employment,
                  yearOfBirth
               },
            } = obj;

            this.firstStep.patchValue({
               firstName,
               lastName,
               parentName,
               dateOfBirth: dateOfBirth ? dateOfBirth : null,
               foreigner,
               jmbg,
               placeOfBirth,
               gender,
               citizenship,
               nationality,
               address: permanentResidence.street,
               addressNumber: permanentResidence.number,
               emailAddress: obj.data.email,
               phone,
               mobile,
               maritalStatus: maritalStatus?.title,
               education: education?.title,
               occupation,
               employment: employment?.title,
               yearOfBirth
            });
            this.firstStep.markAllAsTouched();
            this.checkJMBG('');
         }
      });
   }

   openNSPFamilyRequest(): void {
      let dialogRef = null;
      dialogRef = this.dialog.open(NspFamilyRequestComponent, {
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         // width: '1200px',
         data: {
            origin: 'nsp-request',
            subject: this.firstStep.value
         },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
         if (result?.event === 'SUCCESS') {
            this.numberOfNSPFMade++;
         }
      });
   }

   // Brisanje sadrzaja zavisnih polja od ZAPOSLENJA, u zavisnosti od izabrane vrijednosti iz sifrarnika.
   clearEmploymentFields(event: any) {
      if (event.value !== 'Запослен' && event.value !== 'Радно ангажован') {
         this.firstStep.controls.employerName.reset();
         this.firstStep.controls.agriculturist.reset();
         this.firstStep.controls.independentAgency.reset();
      } else if (event.value !== 'Остало') {
         this.firstStep.controls.employmentOther.reset();
      }
   }

   printPreview() {
      const nspRequest = this.createNspRequestObject();
      this.nspRequestService.printPreview(nspRequest);
   }

   createNspRequestObject() {
      const submitData = {
         center: this.firstStep.value.center,
         id: this.firstStep.value.nspRequestIdentity,
         subjectId: this.subjectIdentity.subjectId,
         dateOfSubmission: this.firstStep.value.dateOfSubmission.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$3/$2/$1'),
         firstName: this.firstStep.value.firstName,
         lastName: this.firstStep.value.lastName,
         parentName: this.firstStep.value.parentName,
         dateOfBirth: this.datePipe.transform(this.firstStep.value.dateOfBirth, 'dd/MM/yyyy'),
         foreigner: this.firstStep.value.foreigner,
         jmbg: this.firstStep.value.jmbg,
         placeOfBirth: this.firstStep.value.placeOfBirth,
         gender: this.firstStep.value.gender !== '' ? this.firstStep.value.gender : null,
         citizenship: this.firstStep.value.citizenship !== '' ? this.firstStep.value.citizenship : null,
         nationality: this.firstStep.value.nationality !== '' ? this.firstStep.value.nationality : null,
         address: this.firstStep.value.address,
         addressNumber: this.firstStep.value.addressNumber,
         emailAddress: this.firstStep.value.emailAddress,
         phone: this.firstStep.value.phone,
         mobile: this.firstStep.value.mobile,
         maritalStatus: this.maritalStatuses.filter(x => x.title === this.firstStep.value.maritalStatus)[0],
         education: this.educations.filter(x => x.title === this.firstStep.value.education)[0],
         occupation: this.firstStep.value.occupation !== '' ? this.firstStep.value.occupation : null,
         employment: this.employments.filter(x => x.title === this.firstStep.value.employment)[0],
         employmentOther: this.firstStep.value.employmentOther,
         employerName: this.firstStep.value.employerName,
         independentAgency: this.firstStep.value.independentAgency,
         agriculturist: this.firstStep.value.agriculturist,

         familyMembersNumb: this.secondStep.value.familyMembersNumb,
         minorChildrenNumb: this.secondStep.value.minorChildrenNumb,
         childSchoolStatus: this.secondStep.value.childSchoolStatus,
         housingStatus: this.secondStep.value.housingStatus,
         housingStatusOther: this.secondStep.value.housingStatusOther,
         houseConcept: this.secondStep.value.houseConcept,
         houseArea: this.secondStep.value.houseArea,
         houseInfo1: this.secondStep.value.houseInfo1,
         houseInfo2: this.secondStep.value.houseInfo2,
         totalLandArea: this.secondStep.value.totalLandArea,

         familyRevenue: this.thirdStep.value.familyRevenue,
         revenue01: getNumberFromString(this.thirdStep.value.revenue01),
         revenue02: getNumberFromString(this.thirdStep.value.revenue02),
         revenue03: getNumberFromString(this.thirdStep.value.revenue03),
         revenue04: getNumberFromString(this.thirdStep.value.revenue04),
         revenue05: getNumberFromString(this.thirdStep.value.revenue05),
         revenue06: getNumberFromString(this.thirdStep.value.revenue06),
         revenue07: getNumberFromString(this.thirdStep.value.revenue07),
         revenue08: getNumberFromString(this.thirdStep.value.revenue08),
         revenue09: getNumberFromString(this.thirdStep.value.revenue09),
         revenue10: getNumberFromString(this.thirdStep.value.revenue10),
         revenue11: getNumberFromString(this.thirdStep.value.revenue11),
         revenue12: getNumberFromString(this.thirdStep.value.revenue12),
         revenue13: getNumberFromString(this.thirdStep.value.revenue13),

         dependentStatus: this.fourthStep.value.dependentStatus,
         breadWinnerStatus: this.fourthStep.value.breadWinnerStatus,
         engagementStatus: this.fourthStep.value.engagementStatus,
         firstLastName: this.fourthStep.value.firstLastName,
         relationship: this.fourthStep.value.relationship?.title === undefined ? '' : this.fourthStep.value.relationship.title,
         importantInfo: this.fourthStep.value.importantInfo,
         familyMembersNumber: this.fourthStep.value.familyMembersNumber,
         evidence01: this.fourthStep.value.evidence01,
         evidence02: this.fourthStep.value.evidence02,
         evidence03: this.fourthStep.value.evidence03,
         evidence04: this.fourthStep.value.evidence04,
         evidence05: this.fourthStep.value.evidence05,
         evidence06: this.fourthStep.value.evidence06,

      };

      return submitData;
   }

   /* Metoda omogucava popunjavanje autocomplete polja putem filtriranja odgovarajucioh sifrarnika.
   Parametar what predstavlja vrste sifrarnika. */
   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.CITIZENSHIP:
            return sortByFirstLetter(this.citizenships.filter((option) =>
               option.title.toLowerCase().includes(filterValue)
            ), filterValue, 'title');
         case AutocompleteFilterEnum.NATIONALITY:
            return sortByFirstLetter(this.nationalities.filter((option) =>
               option.title.toLowerCase().includes(filterValue)
            ), filterValue, 'title');
         case AutocompleteFilterEnum.OCCUPATION:
            return sortByFirstLetter(this.occupations.filter((option) =>
               option.title.toLowerCase().includes(filterValue)
            ), filterValue, 'title');
         case AutocompleteFilterEnum.RELATIONSHIP:
            return sortByFirstLetter(this.relationships.filter((option) =>
               option.title.toLowerCase().includes(filterValue)
            ), filterValue, 'title');
         default:
            break;
      }
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.title : '';
   }
   displayCustomFormatRelationship(objectValue: any): string {
      return objectValue ? objectValue : '';
   }

   selectMulti(){
      this.multiSearch.nativeElement.focus();
      this.multiSearch.nativeElement.value = null;
      this.filteredFamilyRevenues = this.familyRevenues;
   }

   onInputChange(inputValue: string){
      const input = inputValue.toLowerCase();
      this.filteredFamilyRevenues = this.familyRevenues.filter((revenue: any) => 
         revenue.viewValue.toLowerCase().includes(input) || this.revenueSelected.includes(revenue.value));
   }

   updateJMBGOnDateOfBirthChange(event: any) {
      if (event != null && this.firstStep.value.foreigner !== true) {
         if (this.firstStep.value.dateOfBirth && this.firstStep.value.jmbg) {
            const birthDate = new Date(this.firstStep.value.dateOfBirth).getTime();
            const newDate = this.datePipe.transform(birthDate, 'ddMMyyyy');
            const newBirthDate = newDate.substring(0, 4) + newDate.substring(5);
            const jmbg = this.firstStep.value.jmbg.substring(0, 7);

            if (this.firstStep.value.jmbg.length === 13) {
               if (newBirthDate !== jmbg) {
                  this.firstStep.get('dateOfBirth').setErrors({ incorrect: true });
               } else {
                  this.firstStep.get('dateOfBirth').setErrors(null);
               }
            }
         }
      }
   }

   /* Ukoliko je korisnik mladji od 15 godina ili stariji od 63/65 potrebno je setovati radni status = Radno nesposoban.
     Radni status = radno nesposoban je upisan sa ID brojem 6, a kako indeksiranje krenje od 0 podeseno je da se uzima 5 element.
     U slucaju promjene sifrarnika potrebno izmjeniti i ovu metodu.*/
   updateEmploymentData() {
      const dateOfBirth = new Date(this.firstStep.value.dateOfBirth);
      const dateOfSubmission = new Date(this.firstStep.value.dateOfSubmission);
      const time = dateOfSubmission.getTime() - dateOfBirth.getTime();
      const genderCharacters = this.firstStep.value.jmbg.substring(9, 12);
      const lowerLimit = 15;
      let upperLimitByGender = null;

      if (genderCharacters >= '000' && genderCharacters <= '499') {
         upperLimitByGender = 65;
      } else if (genderCharacters >= '500' && genderCharacters <= '999') {
         upperLimitByGender = 63;
      }
      const age = Math.floor((time / (1000 * 3600 * 24)) / 365);
      if (age < lowerLimit || age > upperLimitByGender) {
         this.firstStep.controls.employment.setValue(this.employments[5].title);
      }
   }

   updateJMBGOnGenderChange(event: any) {
      if (this.firstStep.get('foreigner').value === true) {
        return;
      }
      if (this.firstStep.value.jmbg !== null && this.firstStep.value.jmbg.length === 13) {
         const jmbg = this.firstStep.value.jmbg.substring(9, 12);
         if (this.firstStep.get('gender').value !== null && this.firstStep.get('gender').value !== undefined) {
            if (this.firstStep.value.jmbg.length === 13 && jmbg >= '000' && jmbg <= '499') {
               if (this.firstStep.value.gender.code === 'F') {
                  this.firstStep.get('gender').setErrors({ incorrect: true });
               } else {
                  this.firstStep.get('gender').setErrors(null);
               }
            } else if (jmbg >= '500' && jmbg <= '999') {
               if (this.firstStep.value.gender.code === 'M') {
                  this.firstStep.get('gender').setErrors({ incorrect: true });
               } else {
                  this.firstStep.get('gender').setErrors(null);
               }
            }
         }
      }
   }

   checkJMBG(event: any) {
      if (this.firstStep.get('foreigner').value === true) {
        return;
      }
      this.checkJMBGRule();
      this.updateJMBGOnDateOfBirthChange(event);
      if (this.firstStep.value.jmbg === 13) {
         const x = this.subjectService.getSubject(this.firstStep.value.jmbg);
         x.subscribe(
            (res) => {
               if (res !== null && res !== '') {
                  let message = '';
                  this.translate.get('SNACKBAR.USER_EXIST').subscribe((resp: string) => {
                     message = resp;
                  });
                  let recordcenter = '';
                  this.translate.get('SNACKBAR.RECORD_CENTER').subscribe((resp: string) => {
                     recordcenter = resp;
                  });

                  this.toastr.showErrorTitle(message, recordcenter + res.city);
               } else {
                  this.toastr.error('SNACKBAR.USER_NOT_EXIST');
               }
            },
            () => {
               this.toastr.error('SNACKBAR.ERROR_OCCURRED');
            }
         );
      }
      this.updateJMBGOnGenderChange(event);
   }

   updateTotalRevenue(event: any) {
      let sumValue = 0;
      for (let index = 1; index <= 12; index++) {
         const revenueId = index.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
         if (this.thirdStep.get('revenue' + revenueId).value) {
            const num = getNumberFromString(this.thirdStep.get('revenue' + revenueId).value);
            this.thirdStep.get('revenue' + revenueId).setValue(patchForDecimalNumber(num));
            sumValue += num ? num : 0;
         }
      }
      this.thirdStep.get('revenue13').setValue(patchForDecimalNumber(sumValue % 1 ? sumValue.toFixed(2) : sumValue));
      if (sumValue > this.nspAmount) {
         this.toastr.warning('NSP_REQUEST.STEP_03.NSP_NOT_QUALIFIED');
      }
   }

   cleanDynamicRevenueFiealds(list: any) {
      for (let index = 1; index <= 12; index++) {
         const revenueId = index.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
         if (this.revenueSelected.includes(revenueId) && !list.includes(revenueId)) {
            this.thirdStep.controls['revenue' + revenueId].reset();
            this.thirdStep.controls['revenue' + revenueId].setErrors(null);
            this.updateTotalRevenue('');
         }
      }
   }

   notifyForFamilyRequest() {
      if (Number(this.secondStep.value.familyMembersNumb) > 1) {
         this.numberOfNSPF = Number(this.secondStep.value.familyMembersNumb) - 1;
         let messagePart1 = '';
         this.translate.get('NSP_REQUEST.STEP_02.FAMILY_MEMBERS_NUMB_MESSAGE_PART1').subscribe((res: string) => {
            messagePart1 = res;
         });
         let messagePart2 = '';
         this.translate.get('NSP_REQUEST.STEP_02.FAMILY_MEMBERS_NUMB_MESSAGE_PART2').subscribe((res: string) => {
            messagePart2 = res;
         });
         this.notMadeEnoughNspPMessage = messagePart1 + this.numberOfNSPF + messagePart2;
         this.toastr.showInfo(this.notMadeEnoughNspPMessage);
      }
   }
   optionalFiealdStatusSet() {
      if (this.fourthStep.value.breadWinnerStatus === 'IMA_OBAVEZU') {
         this.fourthStep.controls.firstLastName.enable();
         this.fourthStep.controls.relationship.enable();
      } else {
         this.fourthStep.controls.firstLastName.disable();
         this.fourthStep.controls.relationship.disable();
      }
   }

   calculateNspAmount() {
      this.nspAmount = this.nspRightCodebook[0].amount;
      const familyCount = Number(this.secondStep.controls.familyMembersNumb.value);
      if (familyCount > 1) {
         const minorCount = Number(this.secondStep.controls.minorChildrenNumb.value);
         this.nspAmount += minorCount * this.nspRightCodebook[2].amount;
         this.nspAmount += (familyCount - minorCount) * this.nspRightCodebook[1].amount;
      }
   }

   private checkJMBGRule() {
      if (this.firstStep.value.jmbg !== null && this.firstStep.value.jmbg !== '') {
         if (this.firstStep.value.jmbg.length < 13) {
            this.firstStep.get('jmbg').setErrors({ controlNumber: true });
         } else {
            const jmbg = this.firstStep.value.jmbg;
            const digits = jmbg.split('').map(Number);
            const delimiter = 11;
            let sum = 0;
            let start = 7;
            for (let index = 0; index < digits.length - 1; index++, start--) {
               if (start === 1) {
                  start = 7;
               }
               sum += start * digits[index];
            }
            let controlNumber = sum % delimiter;
            if (controlNumber > 1) {
               controlNumber = delimiter - controlNumber;
            }
            if (controlNumber !== digits[digits.length - 1]) {
               this.firstStep.get('jmbg').setErrors({ controlNumber: true });
               setTimeout(() => {
                  this.firstStep.get('jmbg').setErrors({ controlNumber: true });
                  this.firstStep.controls.jmbg.markAsTouched();
               });
            }
         }
      }
   }

   housingStatusChange(option: any) {
      if (option === 'Ostalo') {
         this.secondStep.controls.housingStatusOther.setValidators([Validators.required]);
         this.secondStep.controls.housingStatusOther.updateValueAndValidity();
      } else {
         this.secondStep.controls.housingStatusOther.setValidators(null);
         this.secondStep.controls.housingStatusOther.updateValueAndValidity();
      }
   }
}
