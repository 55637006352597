import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

export enum TypeOfDocument {
  DOPIS = 1,
  POZIV = 2,
  IZJAVA_STRANKE = 3,
  ZAPISNIK_O_SASLUSANJU = 4,
  NALAZ_I_STRUCNO_MISLJENJE = 5,
  PLAN_POCETNE_PROCENE = 6,
  PLAN_USMERENE_PROCENE = 8,
  USMERENA_PROCENA = 9,
  PLAN_USLUGA_I_MERA = 10,
  ZAKLJUCAK = 12,
  RESENJE = 13,
  PODNESAK_DRUGOM_ORGANU = 14,
  TUZBA = 15,
  PRIJAVA = 16,
  PREDLOG = 17,
  UVERENJE = 18,
  OVLASCENJE = 19,
  ZAHTEV = 20,
  ODLUKA = 23,
  OBAVESTENJE_O_PRIJAVI_NASILJA = 27,
  OSTALO = 29
}

interface SelectOptions {
  value: any;
  viewValue: string;
}

@Component({
  selector: 'app-subject-documents-dialog',
  templateUrl: './subject-documents-dialog.component.html',
  styleUrls: ['./subject-documents-dialog.component.scss']
})

export class SubjectDocumentsDialogComponent implements OnInit {
  typeOfDocumentForm: FormGroup;
  selectOptions: SelectOptions[] = [

    {value: TypeOfDocument.DOPIS, viewValue: 'Допис'},
    {value: TypeOfDocument.POZIV, viewValue: 'Позив'},
    {value: TypeOfDocument.IZJAVA_STRANKE, viewValue: 'Изјава странке'},
    {value: TypeOfDocument.ZAPISNIK_O_SASLUSANJU, viewValue: 'Записник о саслушању'},
    {value: TypeOfDocument.NALAZ_I_STRUCNO_MISLJENJE, viewValue: 'Налаз и стручно мишљење'},
    {value: TypeOfDocument.PLAN_POCETNE_PROCENE, viewValue: 'План почетне процене'},
    {value: TypeOfDocument.PLAN_USMERENE_PROCENE, viewValue: 'План усмерене процене'},
    {value: TypeOfDocument.USMERENA_PROCENA, viewValue: 'Усмерена процена'},
    {value: TypeOfDocument.PLAN_USLUGA_I_MERA, viewValue: 'План услуга и мера'},
    {value: TypeOfDocument.ZAKLJUCAK, viewValue: 'Закључак'},
    {value: TypeOfDocument.RESENJE, viewValue: 'Решење'},
    {value: TypeOfDocument.ZAKLJUCAK, viewValue: 'Поднесак другом органу'},
    {value: TypeOfDocument.TUZBA, viewValue: 'Тужба'},
    {value: TypeOfDocument.PRIJAVA, viewValue: 'Пријава'},
    {value: TypeOfDocument.PREDLOG, viewValue: 'Предлог'},
    {value: TypeOfDocument.UVERENJE, viewValue: 'Уверење'},
    {value: TypeOfDocument.OVLASCENJE, viewValue: 'Овлашћење'},
    {value: TypeOfDocument.ZAHTEV, viewValue: 'Захтев'},
    {value: TypeOfDocument.ODLUKA, viewValue: 'Одлука'},
    {value: TypeOfDocument.OBAVESTENJE_O_PRIJAVI_NASILJA, viewValue: 'Обавештење о пријави насиља'},
    {value: TypeOfDocument.OSTALO, viewValue: 'Остало'}
  ];

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<SubjectDocumentsDialogComponent>) {
              }

  ngOnInit(): void {
     this.typeOfDocumentForm = this.formBuilder.group({
      value: ['', []]
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }

  handleUpdate() {
    const data = this.typeOfDocumentForm.value.value;
    this.dialogRef.close({ event: 'new', data });
  }


}

