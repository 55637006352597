import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamMemberFindingService {

  constructor(private httpClient: HttpClient, private app: AppService) { }
  createTeamMemberFinding(teamMemberFinding: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.post(this.app.getUrl() + environment.createTeamMemberFinding, teamMemberFinding, { headers }) as Observable<any>;
  }

}
