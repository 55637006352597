/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 *
*/
/**
 * @author Goran Divovic goran.divovic@iten.rs
 */
export class FormsSOPension {

  typesOfPensionCodebook: any;
  branchesPioFundCodebook: any;
  checkNumber: string;
  pensionAmount: number;
  startDate: Date

  constructor(pensionKind: any, pio: any, checkNumber: string, pensionAmount: number, startDate: Date) {
    this.typesOfPensionCodebook = pensionKind;
    this.branchesPioFundCodebook = pio;
    this.checkNumber = checkNumber;
    this.pensionAmount = pensionAmount;
    this.startDate = startDate;
  }
}
