<form [formGroup]="pocketMoneyAmountForm" class="form">
    <div class="table-responsive-wrapper" *ngIf="pocketMoneyAmoutCodebook.length>0">
        <table mat-table id="myTable" class="category__table" #myTable [dataSource]="pocketMoneyAmoutCodebook">
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'GLOBAL_ADMIN.POCKET_MONEY_AMOUNT.CATEGORY' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.category}}
                </td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'GLOBAL_ADMIN.POCKET_MONEY_AMOUNT.AMOUNT' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.amount}} {{ 'GLOBAL.RSD' | translate }}
                </td>
            </ng-container>
            <ng-container matColumnDef="validFrom">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.VALID_FROM' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.validFrom | date : 'dd.MM.yyyy.'}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="category__row--header"></tr>
            <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" (click)="updateDataInRow(element)"
                class="category__row">
            </tr>
        </table>
    </div>

    <field-holder>
        <mat-form-field appearance="outline">
            <mat-label>{{
                'GLOBAL_ADMIN.POCKET_MONEY_AMOUNT.CATEGORY' | translate
                }}</mat-label>
            <mat-select formControlName="category" [compareWith]="compareObj" required>
                <mat-option *ngFor="let option of categoryOptions" [value]="option">
                    {{ option.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
            <mat-label>{{
                'GLOBAL_ADMIN.FORM.NEW_VALUE' | translate
                }}</mat-label>
            <input matInput type="text" (change)="checkPockentMoneyAmount()" formControlName="amount" autocomplete="off"
                required />
            <span matSuffix>{{ 'GLOBAL_ADMIN.FORM.RSD' | translate }}</span>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}</mat-label>
            <input matInput type="text" [matDatepicker]="pocketMoneyAmountValidFrom" formControlName="validFrom"
                autocomplete="off" placeholder="{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}" required [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="pocketMoneyAmountValidFrom"></mat-datepicker-toggle>
            <mat-datepicker #pocketMoneyAmountValidFrom></mat-datepicker>
        </mat-form-field>

    </field-holder>
    <form-footer>
        <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
            (click)="submitPocketMoneyAmountForm(pocketMoneyAmountForm)" [disabled]="!pocketMoneyAmountForm.valid">
            {{ 'GLOBAL_ADMIN.FORM.SUBMIT' | translate }}
        </button>
    </form-footer>
</form>