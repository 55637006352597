import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Page } from 'src/app/page';
import { getNumberForShow, getNumberFromString, patchForDecimalNumber } from 'src/app/utils/formatLocalNumber';
import { CodebookService } from 'src/services/codebook.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { GlobalAdminService } from 'src/services/global-admin.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
   selector: 'nsp-admin',
   templateUrl: './nsp-admin.component.html',
   styleUrls: ['./nsp-admin.component.scss'],
})
export class NspAdminComponent implements OnInit {
  nspForm: FormGroup;
  page: Page<any> = new Page();
  nspAllRightsTemp = [];
  nspDataSource = new MatTableDataSource();
  nspDisplayedColumns: string[] = ['amount1', 'amount2', 'amount3', 'date'];
  nspRightCodebooks: any = [];
  nspCurrentValues = {
    value: '30.000,00 РСД',
    validFrom: '',
  };
  maxDate: Date = new Date(2999, 11, 31);
  @Input() panelOpenState: boolean;
  constructor(
    private paginationService: CustomPaginationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrImplService,
    private codebookService: CodebookService,
    private translate: TranslateService,
    private globalAdminService: GlobalAdminService
  ) {}

   ngOnInit(): void {
      this.nspForm = this.formBuilder.group({
         value0: ['', [Validators.pattern('^[0-9.,]*$')]],
         value1: ['', [Validators.pattern('^[0-9.,]*$')]],
         value2: ['', [Validators.pattern('^[0-9.,]*$')]],
         validFrom: ['', []],
      });
      this.getNSPRightCodebook();
   }

   public getNextPage(): void {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.getAllNSPRightCodebook();
   }

   public getPreviousPage(): void {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.getAllNSPRightCodebook();
   }
   public getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.getAllNSPRightCodebook();
   }

   public getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.getAllNSPRightCodebook();
   }

   public getPageInNewSize(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.getAllNSPRightCodebook();
   }

   checkNspAmount() {
      for (let index = 0; index < this.nspRightCodebooks.length; index++) {
         const formValue = getNumberForShow(this.nspForm.controls['value' + index].value);
         if (formValue) this.nspForm.controls['value' + index].setValue(formValue);
      }
   }

   // Send NSP updates
   submitNSPForm(data: any) {
      const newObject = this.nspRightCodebooks;
      for (let index = 0; index < this.nspRightCodebooks.length; index++) {
         newObject[index].amount = getNumberFromString(data.value['value' + index]);
         const date = data.value.validFrom;
         if (date.toISOString() !== new Date(this.nspRightCodebooks[0].validFrom).toISOString()) {
            date.setDate(data.value.validFrom.getDate() + 1);
         }
         newObject[index].validFrom = date.toISOString();
         newObject[index].id = null;
      }
      if (this.nspAllRightsTemp.filter(right => right.validFrom.substring(0, 10) === newObject[0].validFrom.substring(0, 10)).length === 0) {
         this.globalAdminService.createNspRightCodebooks(JSON.stringify(newObject)).subscribe(res => {
            this.toastr.success('SNACKBAR.CHANGE_SAVED');
            this.getNSPRightCodebook();
         });
      } else {
         this.toastr.error('GLOBAL_ADMIN.NSP.ERROR');
      }
   }

   getNSPRightCodebook() {
      this.codebookService.getNspRightCodebook().subscribe(res => {
         this.nspRightCodebooks = res;
         for (let index = 0; index < this.nspRightCodebooks.length; index++) {
            this.nspForm.controls['value' + index].setValue(patchForDecimalNumber(this.nspRightCodebooks[index].amount));
         }
         this.nspForm.controls.validFrom.setValue(new Date(this.nspRightCodebooks[0].validFrom));
         this.nspCurrentValues.validFrom = this.nspRightCodebooks[0].validFrom;
         this.nspCurrentValues.value = this.nspRightCodebooks[0].amount;
      });

      this.getAllNSPRightCodebook();
   }
   getAllNSPRightCodebook() {
      this.codebookService.getAllNspRightCodebook(this.page.pageable).subscribe(res => {
         this.nspAllRightsTemp = res.content;
         this.page = res;
         this.page.pageable.pagePerShow = res.number + 1;
         this.nspDataSource.data = this.nspAllRightsTemp.map(item => {
            const date = new Date(item.validFrom);
            return {
               ...item,
               amount1: patchForDecimalNumber(item.amount1),
               amount2: patchForDecimalNumber(item.amount2),
               amount3: patchForDecimalNumber(item.amount3),
               validFrom: date,
            };
         });
      });
   }
}
