<span
  class="step-status"
  [ngClass]="{
    'stepstatus': true,
    'stepstatus--initial': status === 'INITIAL',
    'stepstatus--started': status === 'STARTED',
    'stepstatus--finished': status === 'FINISHED'
  }"
>
  {{ 'VS.STEP_STATUSES.' + status | translate }}
</span>