<mat-dialog-content>
    <button class="buttonClose" color="white" matTooltip="{{ 'SIDEBAR_WIDGET.CLOSE' | translate }}"
        [mat-dialog-close]="true"><span class="material-icons">close</span>
    </button>
    <div class="container">
        <form flex [formGroup]="taskForm">
            <h2>{{ 'VS.FORM5.TASKS.TITLE' | translate }}</h2>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'VS.FORM5.TASKS.OUTCOME' | translate }}</mat-label>
                    <textarea matInput type="text" formControlName="outcome" autocomplete="off"></textarea>
                </mat-form-field>
            </field-holder>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'VS.FORM5.TASKS.TASK' | translate }}</mat-label>
                    <textarea matInput type="text" formControlName="task" autocomplete="off"></textarea>
                </mat-form-field>
            </field-holder>
            <field-holder>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'VS.FORM5.TASKS.RESPONSIBLE_PERSON' | translate
                        }}</mat-label>
                    <input matInput type="text" formControlName="responsiblePerson" maxlength="250"
                        autocomplete="off" />
                </mat-form-field>
            </field-holder>
            <field-holder kind="2-cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'VS.FORM5.TASKS.FROM_DATE' | translate
                        }}</mat-label>
                    <input matInput [matDatepicker]="pickerStartDate" [min]="minFromDate" formControlName="fromDate"
                        (dateChange)="checkDate('fromDate',$event)" autocomplete="off" [max]="maxDate" />
                    <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{
                        'VS.FORM5.TASKS.TO_DATE' | translate
                        }}</mat-label>
                    <input matInput [matDatepicker]="pickerEndDate" (dateChange)="checkDate('toDate',$event)"
                        [min]="minToDate" formControlName="toDate" autocomplete="off" [max]="maxDate" />
                    <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDate></mat-datepicker>
                    <mat-error *ngIf="taskForm.get('toDate').hasError('errorToDate')">
                        {{ 'VS.FORM5.TASKS.DATE_ERROR' | translate }}</mat-error>
                </mat-form-field>
            </field-holder>
            <form-footer>
                <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
                    class="form-footer__button form-footer__button--secondary">
                    {{ 'VS.FORM5.TASKS.CANCEL' | translate }}
                </button>
                <div matTooltip="{{ 'VS.FORM5.ALL_FIELDS_EMPTY' | translate }}" [matTooltipDisabled]="!(
                taskForm.get('outcome').value==='' &&
                taskForm.get('task').value==='' &&
                taskForm.get('responsiblePerson').value==='')">
                    <button *ngIf="!isEdit" mat-flat-button color="primary" type="button" (click)="addNewTask()"
                        [disabled]="(
                        taskForm.get('outcome').value==='' &&
                        taskForm.get('task').value==='' &&
                        taskForm.get('responsiblePerson').value==='') ||
                        !taskForm.valid" class="form-footer__button form-footer__button--primary">
                        {{ 'VS.FORM5.TASKS.SUBMIT' | translate }}
                    </button>
                    <button *ngIf="isEdit" mat-flat-button color="primary" type="button" (click)="editTask()"
                        [disabled]="(
                        taskForm.get('outcome').value==='' &&
                        taskForm.get('task').value==='' &&
                        taskForm.get('responsiblePerson').value==='') ||
                        !taskForm.valid" class="form-footer__button form-footer__button--primary">
                        {{ 'VS.EDIT' | translate }}
                    </button>
                </div>
            </form-footer>
        </form>
        <br />
    </div>
</mat-dialog-content>