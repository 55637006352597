/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalEventEnum } from 'src/types';
import * as myLetters from './../../../../letters';

@Component({
    selector: 'new-dependent-table-five',
    templateUrl: './new-dependent-table-five.component.html',
    styleUrls: ['./new-dependent-table-five.component.scss']
})
export class NewDependentTableFiveComponent implements OnInit {
    newRecord: FormGroup;
    currentDate = new Date();
    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<any>
    ) { }

    ngOnInit(): void {
        this.newRecord = this.formBuilder.group({
            supportMannerMoney: ['', [Validators.pattern(myLetters.number)]],
            supportMannerOther: [''],
            supportAmmountFiscal: ['', [Validators.pattern(myLetters.number)]],
            supportAmmountPercentage: ['', [Validators.pattern(myLetters.percentValidator)]],
            supportDurationLimited: [''],
            supportDurationUnlimited: [''],
        });
        this.newRecord.controls.supportAmmountPercentage.markAsTouched();
    }

    addNewRecord(trackingDocumentForm: FormGroup) {
        this.dialogRef.close({
            event: ModalEventEnum.SUCCESS,
            data: trackingDocumentForm.value,
        });
    }

    closeDialog() {
        this.dialogRef.close({ event: ModalEventEnum.CANCEL });
    }

    isEnabled() {
        if (this.newRecord.value.supportMannerMoney === '' && this.newRecord.value.supportMannerOther === ''
            && this.newRecord.value.supportAmmountFiscal === '' && this.newRecord.value.supportAmmountPercentage === ''
            && this.newRecord.value.supportDurationLimited === '' && this.newRecord.value.supportDurationUnlimited === '') {
            return false;
        }
        return true;
    }

    checkPercent() {
        const valueOfPercent = this.newRecord.value.supportAmmountPercentage;
        if (valueOfPercent.includes('%') && valueOfPercent.indexOf('%') === (valueOfPercent.length - 1)) {
            const percent = valueOfPercent.substr(0, this.newRecord.value.supportAmmountPercentage.indexOf('%'));
            if (!isNaN(Number(percent)) && (Number(percent) < 15 || Number(percent) > 50)) {
                this.newRecord.controls.supportAmmountPercentage.setErrors({ pattern: true });
            } else if (!isNaN(Number(percent)) && (Number(percent) >= 15 || Number(percent) <= 50)) {
                this.newRecord.controls.supportAmmountPercentage.setErrors(null);
            }
        }
    }
}
