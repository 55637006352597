/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { AppService } from './app.service';
import { Observable } from 'rxjs';
import * as printJS from 'print-js'

@Injectable({
  providedIn: 'root'
})
export class EntranceService {

  constructor(private httpClient: HttpClient, private app: AppService) { }

  addEntrancePaper(entrance: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.put(this.app.getUrl() + environment.addEntrancePaperUrl,
      entrance, { responseType: 'blob', headers }) as Observable<any>;
  }

  printPreview(entrance) {

    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    this.httpClient.put(this.app.getUrl() + environment.printPreviewEntranceUrl,
      entrance, { responseType: 'blob', headers }).subscribe(data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        printJS(fileURL);
      });
  }
}