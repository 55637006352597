/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as myLetters from './../../../letters';
import { TranslateService } from '@ngx-translate/core';
import { patchForDecimalNumber, getNumberForShow, getNumberFromString } from 'src/app/utils/formatLocalNumber';
import { ToastrImplService } from 'src/services/toastr.service';

interface SelectOptions {
   value: any;
   viewValue: string;
}

enum YesNoEnum {
   YES = 'true',
   NO = 'false'
}

enum CircumstanceEnum {
   SUBMITTER_SICKNESS = 1,
   FAMILY_MEMBER_SICKNESS = 2,
   FAMILY_MEMBER_CHILD_CARE = 3,
   SERIOUS_FAMILY_CRISIS = 4,
   DEATH_OF_FAMILY_MEMBER = 5,
   ALCOHOL_OR_DRUG_ABUSE = 6,
   PARTICIPATION_IN_REHABILITATION_PROGRAMME = 7,
   TRAIN_FOR_WORK = 8,
   PROPERTY_LOSSES = 9,
   NATURAL_DISASTER = 10,
   OTHER = 11,
}

@Component({
   selector: 'nsp-property-step',
   templateUrl: './nsp-property-step.component.html',
   styleUrls: ['./nsp-property-step.component.scss']
})
export class NspPropertyStepComponent implements OnInit {
   @Output() propertyStepEmitter = new EventEmitter();
   @Input() propertyStep: FormGroup;
   @Input() editable: boolean;
   @Input() customData: any;
   @Input() familyMembersData: any;
   @Input() subjectData: any;
   @Input() caseCreated: any;
   @Input() nspAmount: any;
   checkSum = 0;
   currentSum = 0;
   setDates = false;
   regIDNumberVisible = false;
   realestateSizeVisible = false;
   movablePropertyVisible = false;
   earningAmountVisible = false;
   missedEarningAmountVisible = false;
   otherCircumstancesVisible = false;
   familyMembersInfo: any;
   regNumberSet = false;
   regNumbers = '';
   loading = false;
   maxDate: Date = new Date(2999, 11, 31);

   constructor(
      private formBuilder: FormBuilder,
      private translate: TranslateService,
      private toastr: ToastrImplService) {
   }

   // List of options
   isRegisteredCSRList: SelectOptions[] = [
      { value: YesNoEnum.YES, viewValue: 'Jeste' },
      { value: YesNoEnum.NO, viewValue: 'Nije' },
   ];

   yesNoOptions: SelectOptions[] = [
      { value: YesNoEnum.YES, viewValue: 'Да' },
      { value: YesNoEnum.NO, viewValue: 'Не' },
   ];

   circumstanceList: SelectOptions[] = [
      { value: CircumstanceEnum.SUBMITTER_SICKNESS, viewValue: 'Болест подносиоца' },
      { value: CircumstanceEnum.FAMILY_MEMBER_SICKNESS, viewValue: 'Болест члана породице' },
      { value: CircumstanceEnum.FAMILY_MEMBER_CHILD_CARE, viewValue: 'Нега и чување деце/члана породице' },
      { value: CircumstanceEnum.SERIOUS_FAMILY_CRISIS, viewValue: 'Озбиљна породична криза' },
      { value: CircumstanceEnum.DEATH_OF_FAMILY_MEMBER, viewValue: 'Смрт члана породице' },
      { value: CircumstanceEnum.ALCOHOL_OR_DRUG_ABUSE, viewValue: 'Злоупотреба алкохола или дрога' },
      { value: CircumstanceEnum.PARTICIPATION_IN_REHABILITATION_PROGRAMME, viewValue: 'Учешће у програму рехабилитације' },
      { value: CircumstanceEnum.TRAIN_FOR_WORK, viewValue: 'Школвање/оспособљавање за рад' },
      { value: CircumstanceEnum.PROPERTY_LOSSES, viewValue: 'Губици и оштећења имовине' },
      { value: CircumstanceEnum.NATURAL_DISASTER, viewValue: 'Елементарна непогода' },
      { value: CircumstanceEnum.OTHER, viewValue: 'Друго' },
   ];

   ngOnChanges(changes: any) {
      if (changes.customData !== undefined && changes.customData !== null && changes.customData.previousValue !== null
         && changes.customData.previousValue !== undefined && changes.customData.currentValue === null) {
         this.regNumberSet = false;
         this.ngOnInit();
      }
      if (changes.customData !== undefined && changes.customData !== null) {
         if (changes.customData.currentValue !== null) {
            if (Object.keys(changes.customData.currentValue).length !== 0) {
               if (changes.customData && changes.customData.currentValue) {
                  this.patchValue(null, false);
               }
            }
         }
      }
      if (this.caseCreated !== undefined && this.caseCreated !== null && this.setDates === false) {
         this.setDatesForEarnedAmount();
         this.setDates = true;
      }
      if (this.regNumberSet === false && this.subjectData !== undefined && this.familyMembersData !== undefined) {
         if (this.subjectData.registrationId !== null) {
            this.regNumbers = this.subjectData.registrationId;
         }
         for (const element of this.familyMembersData.familyMembers) {
            if (element.regNumber !== null && element.regNumber !== undefined) {
               this.regNumbers = this.regNumbers + (this.regNumbers === '' ? '' : ', ') + element.regNumber;
            }
            if (element.regId !== null && element.regId !== undefined) {
               this.regNumbers = this.regNumbers + (this.regNumbers === '' ? '' : ', ') + element.regId;
            }
         }
         this.propertyStep.controls.regIDNumber.setValue(this.regNumbers);
         if (this.propertyStep.value.regIDNumber !== '') {
            this.propertyStep.controls.isRegisteredCSR.setValue(YesNoEnum.YES);

         }
         this.regNumberSet = true;
      }
   }
   refreshRegNumberSet() {
      this.regNumberSet = false;
   }
   compareCircumstancesObjects(object1: any, object2: any) {
      return object1.value === object2;
   }

   ngOnInit(): void {
      this.propertyStep = this.formBuilder.group({
         isRegisteredCSR: ['', Validators.required],
         regIDNumber: ['', []],
         roomsNumber: ['', [Validators.required, Validators.pattern('^[0-9.,]*$')]],
         surplusHousing: ['', Validators.required],
         ownsRealestate: ['', Validators.required],
         realestateSize: ['', [Validators.pattern('^[0-9.,]*$')]],
         ownsMovableProperty: ['', Validators.required],
         movableProperty: ['', []],
         canProvide: ['', []],
         lease: ['', Validators.required],
         ableToLease: ['', Validators.required],
         earning: ['', Validators.required],
         earningAmount: ['', [Validators.pattern('^[0-9.,]*$')]],
         earningAmountFrom: ['', [Validators.required]],
         earningAmountTo: ['', [Validators.required]],
         earningWorkArrangement: ['', []],
         earningWorkArrangementAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         earningRenting: ['', []],
         earningRentingWhat: [{ value: '', disabled: true }, []],
         earningRentingAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],

         missedEarning: ['', Validators.required],
         missedEarningAmount: ['', [Validators.pattern('^[0-9.,]*$')]],
         missedEarningAmountFrom: ['', [Validators.required]],
         missedEarningAmountTo: ['', [Validators.required]],
         missedEarningWorkArrangement: ['', []],
         missedEarningWorkArrangementAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         missedEarningRenting: ['', []],

         missedEarningRentingWhat: [{ value: '', disabled: true }, []],
         missedEarningRentingAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         circumstances: [[], []],
         otherCircumstances: ['', []],

         earningFromEmployment: ['', []],
         earningFromEmploymentAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         earningFromTemporaryJobs: ['', []],
         earningFromTemporaryJobsAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         earningFromRetirements: ['', []],
         earningFromRetirementsAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         earningFromAgriculture: ['', []],
         earningFromAgricultureAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         earningFromIndipendentActivity: ['', []],
         earningFromIndipendentActivityAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         compensationDuringUnemployment: ['', []],
         compensationDuringUnemploymentAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         severancePayAndCompensation: ['', []],
         severancePayAndCompensationAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         earningFromGoods: ['', []],
         earningFromGoodsAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         earningFromSavingAndCash: ['', []],
         earningFromSavingAndCashAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],
         earningFromMaintenanceOfRelativies: ['', []],
         earningFromMaintenanceOfRelativiesAmount: [{ value: '', disabled: true }, [Validators.pattern('^[0-9.,]*$')]],

      },
         { validator: this.checkDates }
      );

      this.setPropertyStepConditionalFields();
   }

   checkDates(group: FormGroup) {
      if (new Date(group.controls.earningAmountTo.value).getTime() < new Date(group.controls.earningAmountFrom.value).getTime()) {
         return { notValidEarningFromTo: true };
      }
      if (new Date(group.controls.missedEarningAmountTo.value).getTime() < new Date(group.controls.missedEarningAmountFrom.value).getTime()) {
         return { notValidMissedEarningFromTo: true };
      }
      return null;
   }

   /**
    * Render dynamic fields depending on the selected option from the `observationReason` field
    */
   setPropertyStepConditionalFields() {
      const earningAmountFrom = this.propertyStep.get('earningAmountFrom');
      const earningAmountTo = this.propertyStep.get('earningAmountTo');
      this.setDatesForEarnedAmount();

      const missedEarningAmountFrom = this.propertyStep.get('missedEarningAmountFrom');
      const missedEarningAmountTo = this.propertyStep.get('missedEarningAmountTo');
      this.setDatesForMissedAmount();

      this.propertyStep
         .get('isRegisteredCSR')
         .valueChanges.subscribe((value: YesNoEnum) => {
            const regIDNumber = this.propertyStep.get('regIDNumber');

            if (value === YesNoEnum.YES) {
               regIDNumber.setValidators([Validators.required]);
               this.regIDNumberVisible = true;
            } else {
               regIDNumber.setValidators([]);
               // regIDNumber.setValue(null);
               this.regIDNumberVisible = false;
            }

            regIDNumber.updateValueAndValidity();
         });


      this.propertyStep
         .get('ownsRealestate')
         .valueChanges.subscribe((value: YesNoEnum) => {
            const realestateSize = this.propertyStep.get('realestateSize');

            if (value === YesNoEnum.YES) {
               realestateSize.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
               this.realestateSizeVisible = true;
            } else {
               realestateSize.setValidators([]);
               realestateSize.setValue(null);
               this.realestateSizeVisible = false;
            }

            realestateSize.updateValueAndValidity();
         });

      this.propertyStep
         .get('ownsMovableProperty')
         .valueChanges.subscribe((value: YesNoEnum) => {
            const movableProperty = this.propertyStep.get('movableProperty');

            if (value === YesNoEnum.YES) {
               movableProperty.setValidators([Validators.required, Validators.pattern(myLetters.letters)]);
               this.movablePropertyVisible = true;
               this.propertyStep.get('canProvide').setValidators([Validators.required]);
            } else {
               movableProperty.setValidators([]);
               movableProperty.setValue(null);
               this.movablePropertyVisible = false;
               this.propertyStep.get('canProvide').setValidators([]);
               this.propertyStep.get('canProvide').updateValueAndValidity();
            }

            movableProperty.updateValueAndValidity();
         });

      this.propertyStep
         .get('earning')
         .valueChanges.subscribe((value: YesNoEnum) => {
            const earningAmount = this.propertyStep.get('earningAmount');

            if (value === YesNoEnum.YES) {
               earningAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
               this.earningAmountVisible = true;
            } else {
               earningAmount.setValidators([]);
               earningAmount.setValue(null);
               this.earningAmountVisible = false;
               this.loading = true;
               this.clearEarningAmountFields();
               this.loading = false;
            }

            earningAmount.updateValueAndValidity();
         });


      this.propertyStep
         .get('missedEarning')
         .valueChanges.subscribe((value: YesNoEnum) => {
            const missedEarningAmount = this.propertyStep.get('missedEarningAmount');

            if (value === YesNoEnum.YES) {
               missedEarningAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
               this.missedEarningAmountVisible = true;
            } else {
               missedEarningAmount.setValidators([]);
               missedEarningAmount.setValue(null);
               this.missedEarningAmountVisible = false;
               this.loading = true;
               this.clearMissedEarningAmountFields();
               this.loading = false;
            }

            missedEarningAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('missedEarningRenting')
         .valueChanges.subscribe((value: boolean) => {
            const missedEarningRentingAmount = this.propertyStep.get('missedEarningRentingAmount');
            const missedEarningRentingWhat = this.propertyStep.get('missedEarningRentingWhat');

            if (value === true) {
               missedEarningRentingAmount.enable();
               missedEarningRentingAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
               missedEarningRentingWhat.enable();
               missedEarningRentingWhat.setValidators([Validators.required]);
            } else {
               missedEarningRentingAmount.disable();
               missedEarningRentingAmount.setValidators([]);
               missedEarningRentingAmount.setValue(null);
               missedEarningRentingWhat.disable();
               missedEarningRentingWhat.setValidators([]);
               missedEarningRentingWhat.setValue(null);
            }

            missedEarningRentingAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('missedEarningWorkArrangement')
         .valueChanges.subscribe((value: boolean) => {
            const missedEarningWorkArrangementAmount = this.propertyStep.get('missedEarningWorkArrangementAmount');

            if (value === true) {
               missedEarningWorkArrangementAmount.enable();
               missedEarningWorkArrangementAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               missedEarningWorkArrangementAmount.disable();
               missedEarningWorkArrangementAmount.setValidators([]);
               missedEarningWorkArrangementAmount.setValue(null);
            }

            missedEarningWorkArrangementAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningRenting')
         .valueChanges.subscribe((value: boolean) => {
            const earningRentingAmount = this.propertyStep.get('earningRentingAmount');
            const earningRentingWhat = this.propertyStep.get('earningRentingWhat');

            if (value === true) {
               earningRentingAmount.enable();
               earningRentingAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
               earningRentingWhat.enable();
               earningRentingWhat.setValidators([Validators.required]);
            } else {
               earningRentingAmount.disable();
               earningRentingAmount.setValidators([]);
               earningRentingAmount.setValue(null);
               earningRentingWhat.disable();
               earningRentingWhat.setValidators([]);
               earningRentingWhat.setValue(null);
               this.checkEarningAmount();
            }

            earningRentingAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningWorkArrangement')
         .valueChanges.subscribe((value: boolean) => {
            const earningWorkArrangementAmount = this.propertyStep.get('earningWorkArrangementAmount');

            if (value === true) {
               earningWorkArrangementAmount.enable();
               earningWorkArrangementAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningWorkArrangementAmount.disable();
               earningWorkArrangementAmount.setValidators([]);
               earningWorkArrangementAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningWorkArrangementAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningFromEmployment')
         .valueChanges.subscribe((value: boolean) => {
            const earningFromEmploymentAmount = this.propertyStep.get('earningFromEmploymentAmount');

            if (value === true) {
               earningFromEmploymentAmount.enable();
               earningFromEmploymentAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningFromEmploymentAmount.disable();
               earningFromEmploymentAmount.setValidators([]);
               earningFromEmploymentAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningFromEmploymentAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningFromTemporaryJobs')
         .valueChanges.subscribe((value: boolean) => {
            const earningFromTemporaryJobsAmount = this.propertyStep.get('earningFromTemporaryJobsAmount');

            if (value === true) {
               earningFromTemporaryJobsAmount.enable();
               earningFromTemporaryJobsAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningFromTemporaryJobsAmount.disable();
               earningFromTemporaryJobsAmount.setValidators([]);
               earningFromTemporaryJobsAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningFromTemporaryJobsAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningFromRetirements')
         .valueChanges.subscribe((value: boolean) => {
            const earningFromRetirementsAmount = this.propertyStep.get('earningFromRetirementsAmount');

            if (value === true) {
               earningFromRetirementsAmount.enable();
               earningFromRetirementsAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningFromRetirementsAmount.disable();
               earningFromRetirementsAmount.setValidators([]);
               earningFromRetirementsAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningFromRetirementsAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningFromAgriculture')
         .valueChanges.subscribe((value: boolean) => {
            const earningFromAgricultureAmount = this.propertyStep.get('earningFromAgricultureAmount');

            if (value === true) {
               earningFromAgricultureAmount.enable();
               earningFromAgricultureAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningFromAgricultureAmount.disable();
               earningFromAgricultureAmount.setValidators([]);
               earningFromAgricultureAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningFromAgricultureAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningFromIndipendentActivity')
         .valueChanges.subscribe((value: boolean) => {
            const earningFromIndipendentActivityAmount = this.propertyStep.get('earningFromIndipendentActivityAmount');

            if (value === true) {
               earningFromIndipendentActivityAmount.enable();
               earningFromIndipendentActivityAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningFromIndipendentActivityAmount.disable();
               earningFromIndipendentActivityAmount.setValidators([]);
               earningFromIndipendentActivityAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningFromIndipendentActivityAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('compensationDuringUnemployment')
         .valueChanges.subscribe((value: boolean) => {
            const compensationDuringUnemploymentAmount = this.propertyStep.get('compensationDuringUnemploymentAmount');

            if (value === true) {
               compensationDuringUnemploymentAmount.enable();
               compensationDuringUnemploymentAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               compensationDuringUnemploymentAmount.disable();
               compensationDuringUnemploymentAmount.setValidators([]);
               compensationDuringUnemploymentAmount.setValue(null);
               this.checkEarningAmount();
            }

            compensationDuringUnemploymentAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('severancePayAndCompensation')
         .valueChanges.subscribe((value: boolean) => {
            const severancePayAndCompensationAmount = this.propertyStep.get('severancePayAndCompensationAmount');

            if (value === true) {
               severancePayAndCompensationAmount.enable();
               severancePayAndCompensationAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               severancePayAndCompensationAmount.disable();
               severancePayAndCompensationAmount.setValidators([]);
               severancePayAndCompensationAmount.setValue(null);
               this.checkEarningAmount();
            }

            severancePayAndCompensationAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningFromGoods')
         .valueChanges.subscribe((value: boolean) => {
            const earningFromGoodsAmount = this.propertyStep.get('earningFromGoodsAmount');

            if (value === true) {
               earningFromGoodsAmount.enable();
               earningFromGoodsAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningFromGoodsAmount.disable();
               earningFromGoodsAmount.setValidators([]);
               earningFromGoodsAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningFromGoodsAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('earningFromSavingAndCash')
         .valueChanges.subscribe((value: boolean) => {
            const earningFromSavingAndCashAmount = this.propertyStep.get('earningFromSavingAndCashAmount');

            if (value === true) {
               earningFromSavingAndCashAmount.enable();
               earningFromSavingAndCashAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningFromSavingAndCashAmount.disable();
               earningFromSavingAndCashAmount.setValidators([]);
               earningFromSavingAndCashAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningFromSavingAndCashAmount.updateValueAndValidity();
         });


      this.propertyStep
         .get('earningFromMaintenanceOfRelativies')
         .valueChanges.subscribe((value: boolean) => {
            const earningFromMaintenanceOfRelativiesAmount = this.propertyStep.get('earningFromMaintenanceOfRelativiesAmount');

            if (value === true) {
               earningFromMaintenanceOfRelativiesAmount.enable();
               earningFromMaintenanceOfRelativiesAmount.setValidators([Validators.required, Validators.pattern('^[0-9.,]*$')]);
            } else {
               earningFromMaintenanceOfRelativiesAmount.disable();
               earningFromMaintenanceOfRelativiesAmount.setValidators([]);
               earningFromMaintenanceOfRelativiesAmount.setValue(null);
               this.checkEarningAmount();
            }

            earningFromMaintenanceOfRelativiesAmount.updateValueAndValidity();
         });

      this.propertyStep
         .get('circumstances')
         .valueChanges.subscribe((value: Array<any>) => {
            const otherCircumstances = this.propertyStep.get('otherCircumstances');

            if (value?.some(element => element.value === CircumstanceEnum.OTHER)) {
               otherCircumstances.setValidators([Validators.required]);
               this.otherCircumstancesVisible = true;
            } else {
               this.otherCircumstancesVisible = false;
               otherCircumstances.setValidators([]);
               otherCircumstances.setValue(null);
            }
         });
   }


   /**
    * Send step information to parent component
    */
   updatePropertyStep() {
      // todo: pass family members
      if (this.propertyStep.value.circumstances === '') {
         this.propertyStep.value.circumstances = [];
      }
      if (this.propertyStep.controls.earningAmountFrom.value === null && this.propertyStep.controls.earningAmountTo.value === null) {
         this.setDatesForEarnedAmount();
      }
      if (this.propertyStep.controls.missedEarningAmountFrom.value === null &&
         this.propertyStep.controls.missedEarningAmountTo.value === null) {
         this.setDatesForMissedAmount();
      }
      this.propertyStepEmitter.emit(this.propertyStep);
   }

   patchValue(data, outsidePatch) {
      if (outsidePatch) {
         this.customData = data;
      }
      this.propertyStep.patchValue({
         ableToLease: this.customData.ableToLease === false ? YesNoEnum.NO : YesNoEnum.YES,
         otherCircumstances: this.customData.otherCircumstances,
         ownsMovableProperty: this.customData.ownsMovableProperty === false ? YesNoEnum.NO : YesNoEnum.YES,
         regIDNumber: this.customData.regIDNumber,
         circumstances: this.customData.circumstances,
         movableProperty: this.customData.movableProperty,
         earning: this.customData.earningAmount === 0 ? YesNoEnum.NO : YesNoEnum.YES,
         earningAmount: patchForDecimalNumber(this.customData.earningAmount),
         earningAmountFrom: this.customData.earningAmountFrom !== null ?
            new Date(this.customData.earningAmountFrom?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) :
            this.customData.earningAmountFrom,
         earningAmountTo: this.customData.earningAmountTo !== null ?
            new Date(this.customData.earningAmountTo?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) :
            this.customData.earningAmountTo,
         earningRenting: this.customData.earningRenting,
         earningRentingAmount: patchForDecimalNumber(this.customData.earningRentingAmount),
         earningRentingWhat: this.customData.earningRentingWhat,
         earningWorkArrangement: this.customData.earningWorkArrangement,
         earningWorkArrangementAmount: patchForDecimalNumber(this.customData.earningWorkArrangementAmount),
         isRegisteredCSR: this.customData.isRegisteredCsr === false ? YesNoEnum.NO : YesNoEnum.YES,
         lease: this.customData.lease === false ? YesNoEnum.NO : YesNoEnum.YES,
         canProvide: this.customData.canProvide === false ? YesNoEnum.NO : YesNoEnum.YES,
         missedEarning: this.customData.missedEarning === false ? YesNoEnum.NO : YesNoEnum.YES,
         missedEarningAmount: patchForDecimalNumber(this.customData.missedEarningAmount),
         missedEarningAmountFrom: this.customData.missedEarningAmountFrom !== null ?
            new Date(this.customData.missedEarningAmountFrom?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) :
            this.customData.missedEarningAmountFrom,
         missedEarningAmountTo: this.customData.missedEarningAmountTo !== null ?
            new Date(this.customData.missedEarningAmountTo?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) :
            this.customData.missedEarningAmountTo,
         missedEarningRenting: this.customData.missedEarningRenting,
         missedEarningRentingWhat: this.customData.missedEarningRentingWhat,
         missedEarningRentingAmount: patchForDecimalNumber(this.customData.missedEarningRentingAmount),
         missedEarningWorkArrangement: this.customData.missedEarningWorkArrangement,
         missedEarningWorkArrangementAmount: patchForDecimalNumber(this.customData.missedEarningWorkArrangementAmount),
         ownsRealestate: this.customData.ownsRealEstate === false ? YesNoEnum.NO : YesNoEnum.YES,
         realestateSize: this.customData.realestateSize,
         roomsNumber: this.customData.roomsNumber,
         surplusHousing: this.customData.surplusHousing === false ? YesNoEnum.NO : YesNoEnum.YES,
         earningFromEmployment: this.customData.earningFromEmployment,
         earningFromEmploymentAmount: patchForDecimalNumber(this.customData.earningFromEmploymentAmount),
         earningFromTemporaryJobs: this.customData.earningFromTemporaryJobs,
         earningFromTemporaryJobsAmount: patchForDecimalNumber(this.customData.earningFromTemporaryJobsAmount),
         earningFromRetirements: this.customData.earningFromRetirements,
         earningFromRetirementsAmount: patchForDecimalNumber(this.customData.earningFromRetirementsAmount),
         earningFromAgriculture: this.customData.earningFromAgriculture,
         earningFromAgricultureAmount: patchForDecimalNumber(this.customData.earningFromAgricultureAmount),
         earningFromIndipendentActivity: this.customData.earningFromIndipendentActivity,
         earningFromIndipendentActivityAmount: patchForDecimalNumber(this.customData.earningFromIndipendentActivityAmount),
         compensationDuringUnemployment: this.customData.compensationDuringUnemployment,
         compensationDuringUnemploymentAmount: patchForDecimalNumber(this.customData.compensationDuringUnemploymentAmount),
         severancePayAndCompensation: this.customData.severancePayAndCompensation,
         severancePayAndCompensationAmount: patchForDecimalNumber(this.customData.severancePayAndCompensationAmount),
         earningFromGoods: this.customData.earningFromGoods,
         earningFromGoodsAmount: patchForDecimalNumber(this.customData.earningFromGoodsAmount),
         earningFromSavingAndCash: this.customData.earningFromSavingAndCash,
         earningFromSavingAndCashAmount: patchForDecimalNumber(this.customData.earningFromSavingAndCashAmount),
         earningFromMaintenanceOfRelativies: this.customData.earningFromMaintenanceOfRelativies,
         earningFromMaintenanceOfRelativiesAmount: patchForDecimalNumber(this.customData.earningFromMaintenanceOfRelativiesAmount)
      });
   }


   checkEarningAmount() {
      this.checkSum = 0;
      if (this.loading) {
         return;
      }
      this.currentSum = this.propertyStep.value.earningAmount;
      this.propertyStep.controls.earningAmount.setValue(getNumberForShow(this.currentSum));
      let count = 0;
      if (this.propertyStep.controls.earningRentingAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningRentingAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningRentingAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningRentingAmount));
         count++;
      } else if (this.propertyStep.controls.earningRentingAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningWorkArrangementAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningWorkArrangementAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningWorkArrangementAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningWorkArrangementAmount));
         count++;
      } else if (this.propertyStep.controls.earningWorkArrangementAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningFromEmploymentAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningFromEmploymentAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningFromEmploymentAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningFromEmploymentAmount));
         count++;
      } else if (this.propertyStep.controls.earningFromEmploymentAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningFromTemporaryJobsAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningFromTemporaryJobsAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningFromTemporaryJobsAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningFromTemporaryJobsAmount));
         count++;
      } else if (this.propertyStep.controls.earningFromTemporaryJobsAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningFromRetirementsAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningFromRetirementsAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningFromRetirementsAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningFromRetirementsAmount));
         count++;
      } else if (this.propertyStep.controls.earningFromRetirementsAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningFromAgricultureAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningFromAgricultureAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningFromAgricultureAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningFromAgricultureAmount));
         count++;
      } else if (this.propertyStep.controls.earningFromAgricultureAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningFromIndipendentActivityAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningFromIndipendentActivityAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningFromIndipendentActivityAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningFromIndipendentActivityAmount));
         count++;
      } else if (this.propertyStep.controls.earningFromIndipendentActivityAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.compensationDuringUnemploymentAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.compensationDuringUnemploymentAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.compensationDuringUnemploymentAmount
            .setValue(getNumberForShow(this.propertyStep.value.compensationDuringUnemploymentAmount));
         count++;
      } else if (this.propertyStep.controls.compensationDuringUnemploymentAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.severancePayAndCompensationAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.severancePayAndCompensationAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.severancePayAndCompensationAmount
            .setValue(getNumberForShow(this.propertyStep.value.severancePayAndCompensationAmount));
         count++;
      } else if (this.propertyStep.controls.severancePayAndCompensationAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningFromGoodsAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningFromGoodsAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningFromGoodsAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningFromGoodsAmount));
         count++;
      } else if (this.propertyStep.controls.earningFromGoodsAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningFromSavingAndCashAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningFromSavingAndCashAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningFromSavingAndCashAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningFromSavingAndCashAmount));
         count++;
      } else if (this.propertyStep.controls.earningFromSavingAndCashAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.earningFromMaintenanceOfRelativiesAmount.valid) {
         const formatAmount = getNumberFromString(this.propertyStep.value.earningFromMaintenanceOfRelativiesAmount);
         this.checkSum = parseFloat((this.checkSum + formatAmount).toFixed(2));
         this.propertyStep.controls.earningFromMaintenanceOfRelativiesAmount
            .setValue(getNumberForShow(this.propertyStep.value.earningFromMaintenanceOfRelativiesAmount));
         count++;
      } else if (this.propertyStep.controls.earningFromMaintenanceOfRelativiesAmount.invalid) {
         return;
      }

      if (count !== 0 && this.checkSum !== getNumberFromString(this.currentSum)) {
         this.propertyStep.controls.earningAmount.setErrors({ missing: true });
         this.toastr.error('SNACKBAR.NSP_EARNING_AMOUNT_ERROR');
      } else {
         this.propertyStep.controls.earningAmount.setErrors(null);
         this.propertyStep.controls.earningAmount.updateValueAndValidity();
      }
   }

   roomsNumberValidation() {
      // TODO: treba dodati provjeru dodatnih prostorija u zavisnosti od OSI
      // Ako je broj soba veci od ukupnog broja clanova domacinstva
      if (this.propertyStep.value.roomsNumber !== '') {
         if (!isNaN(parseFloat(this.propertyStep.value.roomsNumber)) && isFinite(this.propertyStep.value.roomsNumber)) {
            let messageRooms = '';
            this.translate.get('NSP_REQUEST.STEP_03.BY_NUMBER_OF_ROOMS').subscribe((res: string) => {
               messageRooms = res;
            });
            if (this.propertyStep.value.roomsNumber > this.familyMembersData.familyMembersCount) {
               this.toastr.error('NSP_REQUEST.STEP_03.BY_NUMBER_OF_ROOMS', 'NSP_REQUEST.STEP_03.NSP_NOT_QUALIFIED');
               this.toastr.info('NSP_REQUEST.STEP_03.ERROR_NUMBER_OF_ROOMS_MESSAGE', 'NSP_REQUEST.STEP_03.CHECK');
            } else {
               this.toastr.success('NSP_REQUEST.STEP_03.BY_NUMBER_OF_ROOMS', 'NSP_REQUEST.STEP_03.NSP_QUALIFIED');
            }
         } else {
            this.propertyStep.controls.roomsNumber.setErrors({ required: true });
         }
      }
   }
   // firstDate - datum pre 3 meseca od dana kreiranja case-a
   // setDate(1) - postavi dan na prvi dan meseca
   // secondDate - datum prethodnog meseca od dana kreiranja case-a
   // setDate(0) - postavi na zadnji dan prethodnog meseca
   setDatesForEarnedAmount() {
      const caseCreated = new Date(this.caseCreated);
      const firstDate = new Date(caseCreated);
      firstDate.setMonth(firstDate.getMonth() - 3);
      firstDate.setDate(1);
      const secondDate = new Date(caseCreated);
      secondDate.setDate(0);
      this.propertyStep.controls.earningAmountFrom.setValue(firstDate.toISOString());
      this.propertyStep.controls.earningAmountTo.setValue(secondDate.toISOString());
   }
   setDatesForMissedAmount() {
      const caseCreated = new Date(this.caseCreated);
      const firstDate = new Date(caseCreated);
      firstDate.setMonth(firstDate.getMonth() - 3);
      firstDate.setDate(1);
      const secondDate = new Date(caseCreated);
      secondDate.setDate(0);
      this.propertyStep.controls.missedEarningAmountFrom.setValue(firstDate.toISOString());
      this.propertyStep.controls.missedEarningAmountTo.setValue(secondDate.toISOString());
   }
   checkNspAmountOverflow() {
      let sum = 0;
      if (this.propertyStep.controls.earningAmount.valid) {
         sum += getNumberFromString(this.propertyStep.value.earningAmount);
      }
      if (this.propertyStep.controls.missedEarningAmount.valid) {
         sum += getNumberFromString(this.propertyStep.value.missedEarningAmount);
      }
      if (this.nspAmount - sum <= 0) {
         this.toastr.info('NSP_REQUEST.STEP_03.NSP_NOT_QUALIFIED_MONEY', 'NSP_REQUEST.STEP_03.CHECK');
      }
   }

   checkMissedEarningAmount() {
      this.checkSum = 0;
      this.currentSum = this.propertyStep.value.missedEarningAmount;
      this.propertyStep.controls.missedEarningAmount.setValue(getNumberForShow(this.currentSum));
      let count = 0;
      if (this.propertyStep.controls.missedEarningWorkArrangementAmount.valid) {
         const valueMissedEarning = getNumberFromString(this.propertyStep.value.missedEarningWorkArrangementAmount);
         this.checkSum = parseFloat((this.checkSum + valueMissedEarning).toFixed(2));
         this.propertyStep.controls.missedEarningWorkArrangementAmount
            .setValue(getNumberForShow(this.propertyStep.value.missedEarningWorkArrangementAmount));
         count++;
      } else if (this.propertyStep.controls.missedEarningWorkArrangementAmount.invalid) {
         return;
      }
      if (this.propertyStep.controls.missedEarningRentingAmount.valid) {
         const valueMissedRent = getNumberFromString(this.propertyStep.value.missedEarningRentingAmount);
         this.checkSum = parseFloat((this.checkSum + valueMissedRent).toFixed(2));
         this.propertyStep.controls.missedEarningRentingAmount
            .setValue(getNumberForShow(this.propertyStep.value.missedEarningRentingAmount));
         count++;
      } else if (this.propertyStep.controls.missedEarningRentingAmount.invalid) {
         return;
      }

      if (count !== 0 && this.checkSum !== getNumberFromString(this.currentSum)) {
         this.propertyStep.controls.missedEarningAmount.setErrors({ missing: true });
         this.toastr.error('SNACKBAR.NSP_EARNING_AMOUNT_ERROR');
      } else {
         this.propertyStep.controls.missedEarningAmount.setErrors(null);
         this.propertyStep.controls.missedEarningAmount.updateValueAndValidity();
      }
   }

   warningForMovableProperty(value: any) {
      if (value === YesNoEnum.YES) {
         this.toastr.warning('NSP_REQUEST.STEP_03.NSP_NOT_QUALIFIED', 'NSP_REQUEST.STEP_03.CHECK');
      }
   }

   clearEarningAmountFields() {
      this.propertyStep.get('earningRenting').setValue(false);
      this.propertyStep.get('earningWorkArrangement').setValue(false);
      this.propertyStep.get('earningFromEmployment').setValue(false);
      this.propertyStep.get('earningFromTemporaryJobs').setValue(false);
      this.propertyStep.get('earningFromRetirements').setValue(false);
      this.propertyStep.get('earningFromAgriculture').setValue(false);
      this.propertyStep.get('earningFromIndipendentActivity').setValue(false);
      this.propertyStep.get('compensationDuringUnemployment').setValue(false);
      this.propertyStep.get('severancePayAndCompensation').setValue(false);
      this.propertyStep.get('earningFromGoods').setValue(false);
      this.propertyStep.get('earningFromSavingAndCash').setValue(false);
      this.propertyStep.get('earningFromMaintenanceOfRelativies').setValue(false);
   }

   clearMissedEarningAmountFields() {
      this.propertyStep.get('missedEarningWorkArrangement').setValue(false);
      this.propertyStep.get('missedEarningRenting').setValue(false);
   }

}
