/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { VsService } from 'src/services/vs.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { TranslateService } from '@ngx-translate/core';
import { documentSigningNotification } from 'src/models/documentsigningnotification';
import { environment } from 'src/environments/environment';
import { Forms2_3Component } from './forms2_3/forms2_3.component';
import { CaseService } from 'src/services/case.service';
import { LocalStorageService } from 'src/app/local-storage.service';

@Component({
   selector: 'initial-assessment',
   templateUrl: './initial-assessment.component.html',
   styleUrls: ['./initial-assessment.component.scss'],
})
export class InitialAssessmentComponent implements OnInit {
   @Input() data: any = {}; // TODO: Type me
   @Input() editEnabled: boolean;
   @Input() isCaseClosed: boolean;
   @Input() documentSigned: boolean;
   @Input() formNumber: number;
   @Input() caseId: any;
   @Input() subject: any;
   @Input() remainingTime: string;
   @Input() waitingForApproval: boolean;
   @Output() handleSubmitData = new EventEmitter();
   @Input() hasTeamRequest: boolean;
   @Output() handleNewTeamRequest = new EventEmitter<any>();
   @Input() noRegistrationId: boolean;
   @Input() members: any = [];
   @ViewChild(Forms2_3Component) from2_3: Forms2_3Component;

   isEditMode = false;
   stepStatus: 'INITIAL' | 'STARTED' | 'FINISHED' = 'INITIAL';
   initialAssesment: FormGroup;
   showDocumentFlow = false;
   documentUploadStatus = false; // TODO: Check note at the bottom of file
   submittedData: any; // TODO: Type me
   isChecked: boolean;
   acceptCaseMissingPermitionsTooltip = '';
   constructor(
      private formBuilder: FormBuilder,
      private vsService: VsService,
      private dataSharingService: DataSharingService,
      private cdr: ChangeDetectorRef,
      private translate: TranslateService,
      private caseService: CaseService,
      private localStorageService: LocalStorageService
   ) {
      this.dataSharingService.documentSigned.subscribe((data: documentSigningNotification) => {
         if (this.caseId !== undefined && data !== null) {
            if (data.caseId === this.caseId && data.document === environment.vsDocumentPP) {
               this.documentSigned = true;
               this.cdr.detectChanges();
            }
         }
      });
      this.translate.get('GLOBAL.TOOLTIPS.ACCEPT_CASE_MISSING_PERMITIONS').subscribe((res: string) => {
         this.acceptCaseMissingPermitionsTooltip = res;
      });
   }

   ngOnInit(): void {
      if (this.formNumber === 2) {
         this.isChecked = false;
      } else {
         this.isChecked = true;
         this.formNumber = 3;
      }

      if (this.data.length !== 0) {
         this.documentSigned = this.data[this.data.length - 1].approved;
         if (this.data[this.data.length - 1].approvedAndFinished) {
            this.stepStatus = 'INITIAL';
         } else {
            if (this.data[this.data.length - 1].finished) {
               this.stepStatus = 'FINISHED';
            } else {
               this.stepStatus = 'STARTED';
            }
         }
      } else {
         this.stepStatus = 'INITIAL';
      }

      this.initialAssesment = this.formBuilder.group({
         type: ['', []],
         note: ['', []],
      });

      this.setConditionalValidators();

      if (this.stepStatus === 'INITIAL') {
         this.getSubjectsFamilyMembers();
      }
   }

   setConditionalValidators() {
      this.initialAssesment.get('type').valueChanges.subscribe(item => {
         this.showDocumentFlow = true;
      });
   }

   toggle(event: MatSlideToggleChange) {
      if (event.checked === true) {
         this.formNumber = 3;
      } else {
         this.formNumber = 2;
      }
      this.vsService.changeFormNumber(this.caseId, this.formNumber).subscribe();
   }

   handleFormNumberChange(newFormNumber: number) {
      this.formNumber = newFormNumber;
        this.isChecked = (newFormNumber === 3);
   }

   handleInitStep() {
      this.isEditMode = true;
   }

   handleSaveChanges({ step, isFinished, saved }) {
      if (isFinished && saved) {
         this.stepStatus = 'FINISHED';
      } else if (!isFinished && saved) {
         this.stepStatus = 'STARTED';
      }
      this.isEditMode = false;
      this.documentSigned = false;

   }

   handleSubmitStep() {
      this.isEditMode = false;
      this.stepStatus = 'INITIAL';
      this.handleSubmitData.emit({ step: 'STEP_02', data: this.initialAssesment });
   }

   toggleEdit() {
      this.isEditMode = !this.isEditMode;

      if (this.isEditMode === false) {
         this.initialAssesment.reset();
      }
   }

   handleNewTeamRequestLocal() {
      this.handleNewTeamRequest.emit();
   }

   getSubjectsFamilyMembers() {
      const subjectId = this.subject?.subjectIdentity.subjectId;
      const familyDossierId = this.subject?.familyDossierId;
      const loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      let members = null;
      if (familyDossierId !== null) {
         members = this.caseService.getFamilyMembersCase(subjectId, familyDossierId, loggedIn?.csrId);
         members.subscribe((resultMembers: any) => {
            this.members = resultMembers;
         });
      }
   }
}

/**
 * TODO @gorandivovic @jelenaninkovic @danijelgrabez
 * 1. Backend treba da vrati info o statusu uploadovanog obraasca.
 *    Ukliko je obrazac upload-ovan, text statusa treba da se promeni (documentUploadStatus).
 * 2. Ukoliko je opcija za obrazac izabrana u prošlosti, sekcija za info o dokumentu treba da bude prikazana
 *    (trenutno se ova sekcija sakriivena i prikazuje se kada se obrazac izabere)
 */

