/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'definition-item',
  templateUrl: './definition-item.component.html',
  styleUrls: ['./definition-item.component.scss'],
})
export class DefinitionItemComponent implements OnInit {
  @Input() key: string;
  @Input() valueOffset: string = '580px';
  @Input() fontSize: 'default' | 'mini' = 'default';
  constructor() {}

  ngOnInit(): void {
  }
}
