/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 *
*/

/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */

export class Form5AddUserComment {

  comment: string;
  commentDate: string;
  relationship: string;

  constructor(comment: string, commentDate: string, relationship: string) {
    this.comment = comment;
    this.commentDate = commentDate;
    this.relationship = relationship;
  }
}