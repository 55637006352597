/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { Page } from 'src/app/page';
import printJS from 'print-js';

@Injectable({
  providedIn: 'root'
})
export class VsService {

  constructor(private httpClient: HttpClient, private app: AppService) { }

  submitForms23(caseId: any, forms23Data: any, isTeamRequest) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.post(this.app.getUrl() + environment.submitForms23Url +
      caseId + '?isTeamRequest=' + isTeamRequest, forms23Data, { headers }) as Observable<any>;
  }

  getForms23(socialCaseId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.httpClient.get(this.app.getUrl() + environment.getForms23ByIdUrl + '/' + socialCaseId,
      { headers }) as Observable<any>;
  }

  previewForm23(form23Data: any, caseId: number){
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    this.httpClient
    .put(this.app.getUrl() + environment.previewForms23 + caseId,  form23Data, { responseType: 'blob', headers })
    .subscribe(data => {
      const file = new Blob([data], { type: 'application/pdf' });      
      const fileURL = URL.createObjectURL(file);      
      printJS(fileURL);
    });
  }

  previewForm5(form5Data: any, caseId: number){
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    this.httpClient
    .put(this.app.getUrl() + environment.previewForm5 + caseId,  form5Data, { responseType: 'blob', headers })
    .subscribe(data => {
      const file = new Blob([data], { type: 'application/pdf' });      
      const fileURL = URL.createObjectURL(file);      
      printJS(fileURL);
    });
  }

  changeFormNumber(caseId, formNumber) {
    return this.httpClient.get(this.app.getUrl() + environment.changeFormNumber + caseId + '/'
      + '?formNumber=' + formNumber) as Observable<any>;
  }

  getForm5(caseId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.get(this.app.getUrl() + environment.findForm5Url + caseId,
      { headers }) as Observable<any>;
  }

  submitForm5(caseId: any, form5Data: any): Observable<Page<any>> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.post(this.app.getUrl() + environment.submitForm5Url + caseId, form5Data, { headers }) as Observable<any>;
  }

  saveForm5(caseId: any, form5Data: any): Observable<Page<any>> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.post(this.app.getUrl() + environment.saveForm5Url + caseId, form5Data, { headers }) as Observable<any>;
  }

  closeCaseVs(body: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.post(this.app.getUrl() + environment.closeCaseVs, body,
        { responseType: 'text', headers }) as Observable<any>;
  }

  supervisorDecisionMake(decision: boolean, caseId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.post(this.app.getUrl() + environment.supervisorDecisionMake + '/' + caseId,
      decision, { headers }) as Observable<any>;
  }

  saveLawyerDecision(body: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.post(this.app.getUrl() + environment.saveLawyerDecision, body, { headers }) as Observable<any>;
  }

  updateEvaluationOfPlan(body: any, caseId: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.put(this.app.getUrl() + environment.updateEvaluationOfPlan + '/' + caseId, body,
            { headers }) as Observable<any>;
  }
  
  addAssesment(body: any) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.httpClient.post(this.app.getUrl() + environment.addAssesment , body, { headers }) as Observable<any>;
  }
}
