<form [formGroup]="supportCategoryPriceForm" class="form">
    <form-group title="{{'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.SUPPORT_CATEGORY_PRICING' | translate }}">
        <field-holder *ngIf="!isEdit">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.SUPPORT_DEGREE' | translate }}</mat-label>
            <mat-select formControlName="supportCategory" [compareWith]="compareObj">
              <mat-option *ngFor="let option of supportCategoryCodebook" [value]="option" [disabled]="option.disabled" required>
                ({{ option?.code}}) {{ option?.title}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="supportCategoryPriceForm.get('supportCategory').hasError('required')">
              {{'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.CATEGORY_ERROR' | translate}}
            </mat-error>
          </mat-form-field>
          
        </field-holder>
        
        <field-holder *ngIf="isEdit">
          <mat-form-field appearance="outline">
            <input matInput formControlName="supportCategory" readonly required />
            <mat-label>{{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.SUPPORT_DEGREE' | translate }}</mat-label>
          </mat-form-field>
        </field-holder>
        
         <field-holder kind="3-cols">
          <mat-form-field appearance="outline">
            <input matInput type="text" (change)="checkPrice(supportCategoryPriceForm.controls.amountMonth)" formControlName="amountMonth" min="0" required/>
            <mat-label>{{ 'GLOBAL_ADMIN.ACCOMMODATION_FACILITY.PRICE.MONTHLY' | translate }}</mat-label>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>
               {{'DPN.FROM_DATE' | translate}}
            </mat-label>
            <input matInput [matDatepicker]="pickerFromDate" formControlName="validFrom" autocomplete="off" 
            [max]="maxDate" required />
            <mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerFromDate></mat-datepicker>
         </mat-form-field>
         <mat-form-field appearance="outline">
            <mat-label>
               {{'DPN.TO_DATE' | translate}}
            </mat-label>
            <input matInput [matDatepicker]="pickerToDate" formControlName="validTo" autocomplete="off" [min]="minToDate" [max]="maxDate"/>
            <mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerToDate></mat-datepicker>
         </mat-form-field>
        </field-holder>
    </form-group>
    <form-footer>
          <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary" type="submit" 
              (click)="submitForm()" [disabled]="!supportCategoryPriceForm.valid">
              {{ 'GLOBAL.SAVE' | translate }}
          </button>
          <button  mat-flat-button color="primary"  class="form-footer__button form-footer__button--primary" mat-dialog-close 
              type="button" (click)="closeDialog()">
              {{ 'GLOBAL.CANCEL' | translate }}
          </button>
    </form-footer>
  </form>
  
