<mat-card class="card">
  <card-header title="{{ 'SUBJECT_RECORDS.TITLE' | translate }}">
    <span matTooltip="{{'NEW_SUBJECT_RECORD.DISABLED_BUTTON' | translate }}" [matTooltipDisabled]="!status">
      <button mat-flat-button (click)="addNewRecord()" color="primary" [disabled]="status"
        *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni && !isAdmin">
        {{ 'NEW_SUBJECT_RECORD.TITLE' | translate }}
      </button>
    </span>
  </card-header>
  <div class="table-responsive-wrapper" *ngIf="page.content.length > 0">
    <table mat-table [dataSource]="page.content" #mytable class="records-table">

      <ng-container matColumnDef="caseNumber">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_RECORDS.TABLE.CASE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.caseNumber}}

        </td>
      </ng-container>
      <ng-container matColumnDef="problemId">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_RECORDS.TABLE.PROBLEM_ID' | translate }}
        </th>

        <td mat-cell *matCellDef="let element">
          {{ element.problemId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_RECORDS.TABLE.START_DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fromDate | date: 'dd.MM.yyyy.' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_RECORDS.TABLE.END_DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.toDate | date: 'dd.MM.yyyy.' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reimbursment">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_RECORDS.TABLE.REIMBURSMENT' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element?.price">
          {{ formatNumber(element.price.toFixed(2)) }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="reimbursmentProvider">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_RECORDS.TABLE.REIMBURSMENT_PROVIDER' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.serviceProviderTitle }}
        </td>
      </ng-container>
      <ng-container matColumnDef="procedure">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SUBJECT_RECORDS.TABLE.PROCEDURE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="records-table__cell records-table__cell--procedure">
          {{ element.caseRightTitle }}
          {{ element.servicesTitle }}
          {{ element.professionalProceduresTitle }}
          {{ element.otherProceduresAcitvitiesTitle }}
          {{ element.legalProceduresTitle }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
          <button mat-icon-button class="button-square table__row-button" color="primary"
            matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
            <mat-icon class="button-square__icon">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="filter-menu">
            <button mat-menu-item (click)="handleEditDialog(element)" [disabled]="status">
              <mat-icon class="button-square__icon">edit</mat-icon>
              {{ 'SUBJECT_PROBLEMS.TABLE.EDIT_PROBLEM' | translate }}
            </button>
            <button mat-menu-item (click)="handleDeleteDialog(element)" [disabled]="status">
              <mat-icon class="button-square__icon">delete</mat-icon>
              {{ 'SUBJECT_PROBLEMS.TABLE.DELETE_PROBLEM' | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" 
        class="records-table__row"></tr>
    </table>
  </div>
  <div class="pagination" *ngIf="page.content.length > 0">
    <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
    </app-custom-pagination>
  </div>
  <p class="records-empty" *ngIf="page.content.length === 0">
    <mat-icon class="records-empty__icon">info</mat-icon>
    {{ 'SUBJECT_RECORDS.NO_RESULTS' | translate }}
  </p>
</mat-card>