/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EntranceComponent } from './entrance/entrance.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { SubjectDetailsComponent } from './subjects/subject-details/subject-details.component';
import { NewSubjectComponent } from './subjects/new-subject/new-subject.component';
import { ProfileComponent } from './profile/profile.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NotificationContentComponent } from './notification-content/notification-content.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SubjectCaseComponent } from './subjects/subject-details/subject-case/subject-case.component';
import { NspComponent } from './cases/nsp/nsp.component';
import { VsComponent } from './cases/vs/vs.component';
import { DpnComponent } from './cases/dpn/dpn.component';
import { NspRequestComponent } from './nsp-request/nsp-request.component';
import { ScheduleCasesComponent } from './schedule-cases/schedule-cases.component';
import { Form5Component } from './cases/vs/form5/form5.component';
import { LegalSubjectsComponent } from './legal-subjects/legal-subjects.component';
import { NewLegalSubjectComponent } from './legal-subjects/new-legal-subject/new-legal-subject.component';
import { LegalSubjectDetailsComponent } from './legal-subjects/legal-subject-details/legal-subject-details.component';
import { AdministrativeProcedureComponent } from './cases/administrative-procedure/administrative-procedure.component';
import { SupervisionComponent } from './supervision/supervision.component';
import { CollectionsComponent } from './collections/collections.component';
import { FosterChildComponent } from './collections/foster-child/foster-child.component';
import { NewFosterChildRecordComponent } from './collections/foster-child/new-foster-child-record/new-foster-child-record.component';
import { FosterParentComponent } from './collections/foster-parent/foster-parent.component';
import { NewFosterParentComponent } from './collections/foster-parent/new-foster-parent/new-foster-parent.component';
import { EstablishedFosterCareComponent } from './collections/established-foster-care/established-foster-care.component';
import { NewEstablishedFosterCareComponent } from './collections/established-foster-care/new-established-foster-care/new-established-foster-care.component';
import { DomesticViolenceVictimComponent } from './collections/domestic-violence-victim/domestic-violence-victim.component';
import { NewDomesticViolenceVictimComponent } from './collections/domestic-violence-victim/new-domestic-violence-victim/new-domestic-violence-victim.component';
import { DomesticViolenceCommitterComponent } from './collections/domestic-violence-committer/domestic-violence-committer.component';
import { NewDomesticViolenceCommitterComponent } from './collections/domestic-violence-committer/new-domestic-violence-committer/new-domestic-violence-committer.component';
import { PnnComponent } from './cases/pnn/pnn.component';
import { OzrComponent } from './cases/ozr/ozr.component';
import { AssuranceComponent } from './cases/assurance/assurance.component';
import { PmnComponent } from './cases/pmn/pmn.component';
import { AdoptedChildrenComponent } from './collections/adopted-children/adopted-children.component';
import { NewAdoptedChildComponent } from './collections/adopted-children/new-adopted-child/new-adopted-child.component';
import { DependentsComponent } from './collections/dependents/dependents.component';
import { NewDependentComponent } from './collections/dependents/new-dependent/new-dependent.component';
import { ProtegesComponent } from './collections/proteges/proteges.component';
import { NewProtegeComponent } from './collections/proteges/new-protege/new-protege.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ProtegeDetailComponent } from './collections/proteges/protege-detail/protege-detail.component';
import { AccountingProceduresComponent } from './accounting-procedures/accounting-procedures.component';
import { GlobalAdminComponent } from './global-admin/global-admin.component';
// tslint:disable-next-line: max-line-length
import { DomesticViolenceCommiterDetailsComponent } from './collections/domestic-violence-committer/domestic-violence-commiter-details/domestic-violence-commiter-details.component';
import { DomesticViolenceVictimDetailsComponent } from './collections/domestic-violence-victim/domestic-violence-victim-details/domestic-violence-victim-details.component';
import { DependentsDetailsComponent } from './collections/dependents/dependents-details/dependents-details.component';
import { EstablishedFosterCareDetailsComponent } from './collections/established-foster-care/established-foster-care-details/established-foster-care-details.component';
import { AdoptedChildrenDetailsComponent } from './collections/adopted-children/adopted-children-details/adopted-children-details.component';
import { FosterParentDetailsComponent } from './collections/foster-parent/foster-parent-details/foster-parent-details.component';
import { FosterChildDetailsComponent } from './collections/foster-child/foster-child-details/foster-child-details.component';
import { LocalComponent } from './cases/local/local.component';
import { UsersComponent } from './global-admin/users/users.component';
import { NewUserComponent } from './global-admin/users/new-user/new-user.component';
import { UserDetailsComponent } from './global-admin/users/user-details/user-details.component';
import { ImportDataComponent } from './global-admin/import-data/import-data.component';
import { ImportCollectionsComponent } from './global-admin/import-collections/import-collections.component';
import { ImportDecisionsComponent } from './global-admin/import-decisions/import-decisions.component';
import { AdminCasesComponent } from './global-admin/admin-cases/admin-cases.component';
import { ChangeRegIdComponent } from './global-admin/change-reg-id/change-reg-id.component';
import { CaseSearchByClassificationComponent } from './global-admin/case-search-by-classification/case-search-by-classification.component';
import { DeactivateGuardService } from 'src/services/deactivate-guard.service';
import { GlobalAdminGuard } from 'src/guards/global-admin.guard';
import { GlobalAndCenterAdmin } from 'src/guards/global-and-center-admin.guard';
import { SecondDegreeGuard } from 'src/guards/second-degree.guard';

const appRoutes: Routes = [
   { path: '', component: HomepageComponent },
   { path: 'entrancePapers', component: EntranceComponent },
   { path: 'form5', component: Form5Component },
   { path: 'subjects', component: SubjectsComponent },
   { path: 'subjects/:id', component: SubjectDetailsComponent },
   { path: 'legal_subjects', component: LegalSubjectsComponent },
   { path: 'legal_subjects/:id', component: LegalSubjectDetailsComponent },
   { path: 'new-subject', component: NewSubjectComponent },
   { path: 'new_legal_subject', component: NewLegalSubjectComponent },
   { path: 'new_legal_subject/:id', component: NewLegalSubjectComponent },
   { path: 'new-subject/:id', component: NewSubjectComponent },
   { path: 'profile', component: ProfileComponent },
   { path: 'schedule-cases', component: ScheduleCasesComponent },
   { path: 'notifications', component: NotificationContentComponent },
   {
      path: 'cases',
      children: [
         { path: 'NSP/:id/:subjectId', component: NspComponent, canDeactivate: [DeactivateGuardService] },
         { path: 'VS/:id/:subjectId', component: VsComponent, canDeactivate: [DeactivateGuardService] },
         { path: 'STAR/:id/:subjectId', component: VsComponent, canDeactivate: [DeactivateGuardService] },
         { path: 'DZP/:id/:subjectId', component: DpnComponent, canDeactivate: [DeactivateGuardService] },
         { path: 'DPN/:id/:subjectId', component: DpnComponent, canDeactivate: [DeactivateGuardService] },
         { path: 'OZR/:id/:subjectId', component: OzrComponent, canDeactivate: [DeactivateGuardService] },
         { path: 'PNN/:id/:subjectId', component: PnnComponent, canDeactivate: [DeactivateGuardService] },
         { path: 'AP/:id/:subjectId', component: AdministrativeProcedureComponent },
         { path: 'details/:id/:subjectId/:csrId', component: SubjectCaseComponent },
         { path: 'UV/:id/:subjectId', component: AssuranceComponent },
         { path: 'PMN/:id/:subjectId', component: PmnComponent },
         { path: 'L/:id/:subjectId', component: LocalComponent, canDeactivate: [DeactivateGuardService] },
      ],
   },
   {
      path: 'submissions',
      children: [{ path: 'entrancePaper/:id', component: EntranceComponent }],
   },
   {
      path: 'supervision',
      component: SupervisionComponent,
      children: [
         { path: ':workerId/:caseId', component: SupervisionComponent },
         { path: ':workerId', component: SupervisionComponent },
         { path: '', component: SupervisionComponent , pathMatch: 'full'},
      ],
   },
   { path: 'nspRequest', component: NspRequestComponent },
   { path: 'collections', component: CollectionsComponent },
   { path: 'foster-child', component: FosterChildComponent },
   { path: 'foster-child/new', component: NewFosterChildRecordComponent },
   { path: 'foster-child/:id', component: FosterChildDetailsComponent },
   { path: 'foster-parent', component: FosterParentComponent },
   { path: 'foster-parent/new', component: NewFosterParentComponent },
   { path: 'foster-parent/:id', component: FosterParentDetailsComponent },
   { path: 'established-foster-care', component: EstablishedFosterCareComponent },
   { path: 'established-foster-care/new', component: NewEstablishedFosterCareComponent },
   { path: 'established-foster-care/:id', component: EstablishedFosterCareDetailsComponent },
   { path: 'domestic-violence-victim', component: DomesticViolenceVictimComponent },
   { path: 'domestic-violence-victim/new', component: NewDomesticViolenceVictimComponent },
   { path: 'domestic-violence-victim/:id', component: DomesticViolenceVictimDetailsComponent },
   { path: 'domestic-violence-committer', component: DomesticViolenceCommitterComponent },
   { path: 'domestic-violence-committer/new', component: NewDomesticViolenceCommitterComponent },
   { path: 'domestic-violence-committer/:id', component: DomesticViolenceCommiterDetailsComponent },
   { path: 'adopted-children', component: AdoptedChildrenComponent },
   { path: 'adopted-children/new', component: NewAdoptedChildComponent },
   { path: 'adopted-children/:id', component: AdoptedChildrenDetailsComponent },
   { path: 'dependents', component: DependentsComponent },
   { path: 'dependents/new', component: NewDependentComponent },
   { path: 'dependents/:id', component: DependentsDetailsComponent },
   { path: 'proteges', component: ProtegesComponent },
   { path: 'proteges/new', component: NewProtegeComponent },
   { path: 'statistics', component: StatisticsComponent },
   { path: 'proteges/:id', component: ProtegeDetailComponent },
   { path: 'accounting-procedures', component: AccountingProceduresComponent },
   { path: 'global-admin', component: GlobalAdminComponent, canActivate: [GlobalAdminGuard] },
   { path: 'users', component: UsersComponent, canActivate: [GlobalAndCenterAdmin] },
   { path: 'users/:id', component: UserDetailsComponent, canActivate: [GlobalAndCenterAdmin] },
   { path: 'new-user', component: NewUserComponent, canActivate: [GlobalAndCenterAdmin] },
   { path: 'import-data', component: ImportDataComponent, canActivate: [GlobalAdminGuard] },
   { path: 'import-collections', component: ImportCollectionsComponent, canActivate: [GlobalAdminGuard] },
   { path: 'import-decisions', component: ImportDecisionsComponent, canActivate: [GlobalAdminGuard] },
   { path: 'admin-cases', component: AdminCasesComponent, canActivate: [GlobalAdminGuard] },
   { path: 'change-reg-id', component: ChangeRegIdComponent, canActivate: [GlobalAdminGuard] },
   { path: 'cases-by-classification', component: CaseSearchByClassificationComponent, canActivate: [SecondDegreeGuard] },
   { path: '**', component: PageNotFoundComponent }, // mora da bude poslednji u nizu ruta
];

@NgModule({
   imports: [RouterModule.forRoot(appRoutes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
