import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SubjectsService } from 'src/services/subjects.service';
@Component({
   selector: 'import-subject-from-provider',
   templateUrl: './import-subject-from-provider.component.html',
   styleUrls: ['./import-subject-from-provider.component.scss']
})
export class ImportSubjectFromProviderComponent implements OnInit {
   importSubjectFromProviderForm: FormGroup;

   constructor(private subjectsService: SubjectsService, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ImportSubjectFromProviderComponent>,) { }

   ngOnInit(): void {
      this.importSubjectFromProviderForm = this.formBuilder.group({
         jmbg: ['', [Validators.required]], // TODO: Improve validation
      });
   }

   closeDialog() {
      this.dialogRef.close({ event: 'cancel' });
   }
}
