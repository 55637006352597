<container>
  <mat-card class="card">
    <card-header title="{{ 'STATISTICS.RECORD_CONTROL_STATS.TITLE' | translate }}">
    </card-header>
    <form [formGroup]="recordControlStatsForm" class="form">
      <field-holder kind="2-cols" *ngIf="isMainCenterBelgrade || isUserDrugostepeni">
        <mat-form-field appearance="outline">
          <mat-label>
            {{'GLOBAL.CENTER' | translate}}
          </mat-label>
          <input type="text" matInput [matAutocomplete]="autoSubcenter" formControlName="center"
            (focusout)="autoSelectCenter()" required/>
          <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete"
            [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let cent of filteredAllCenters | async" [value]="cent">
              {{ cent.name }}
            </mat-option>
         </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'STATISTICS.RECORD_CONTROL_STATS.RECORD' | translate
          }}</mat-label>
          <mat-select formControlName="record" required>
          <mat-option *ngFor="let record of recordOptions" [value]="record">
            {{ record.name }}
          </mat-option>
        </mat-select>
        </mat-form-field>
      </field-holder>
      <field-holder *ngIf="!isMainCenterBelgrade && !isUserDrugostepeni">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'STATISTICS.RECORD_CONTROL_STATS.RECORD' | translate
          }}</mat-label>
          <mat-select formControlName="record" required>
          <mat-option *ngFor="let record of recordOptions" [value]="record">
            {{ record.name }}
          </mat-option>
        </mat-select>
        </mat-form-field>
      </field-holder>
      <form-footer>
        <button
        [disabled]="!recordControlStatsForm.valid || btnDisabled"
          class="form-footer__button form-footer__button--primary"
          mat-flat-button
          color="primary"
          (click)="sendRequest(recordControlStatsForm)"
        >
          {{ 'STATISTICS.SUBMIT' | translate  }}
        </button>
        <mat-progress-spinner [diameter]="50" *ngIf="btnDisabled" color="primary" mode="indeterminate">
        </mat-progress-spinner>
        </form-footer>
    </form>
  </mat-card>
</container>
  