<mat-card>
  <form [formGroup]="lawyerFormVS" class="form">
    <field-holder kind="3-cols-1-1-2">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'DPN.DECISION_TYPE' | translate }}
        </mat-label>
        <mat-select formControlName="decisionType" [compareWith]="compareLoadedObjects" required
          (selectionChange)="changeDecision($event.value)">
          <mat-option *ngFor="let decType of decisionTypes" [value]="decType">
            {{ decType.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'DPN.DATE_CASE_CREATION' | translate }}
        </mat-label>
        <input matInput [matDatepicker]="pickerDateOfCaseCreation" formControlName="dateOfCaseCreation"
          autocomplete="off" [max]="maxDate" />
        <mat-datepicker-toggle matSuffix [for]="pickerDateOfCaseCreation"></mat-datepicker-toggle>
        <mat-datepicker #pickerDateOfCaseCreation></mat-datepicker>
      </mat-form-field>
      <mat-radio-group aria-label="Select an option" color="primary" formControlName="decisionMaker"
        class="form__radio-group form__radio-group--2-cols">
        <span class="form__radio-group-label">
          {{ 'LAWYER.DECISION_MAKER' | translate }} </span><br />
        <mat-radio-button *ngFor="let option of decisionMakerOptions" [value]="option"
          (change)="decisionMakerSelected($event)">
          {{ option.title }}
        </mat-radio-button>
      </mat-radio-group>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline" *ngIf="lawyerFormVS.value.decisionMaker?.id === 2">
        <mat-label>{{
          'LAWYER.DECISION_MAKER_DESCRIPTION' | translate
          }}</mat-label>
        <textarea matInput type="text" formControlName="decisionMakerDescription" autocomplete="off" matTooltip="{{
            'LAWYER.DECISION_MAKER_DESCRIPTION_TOOLTIP' | translate
          }}">
        </textarea>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LAWYER.DECISION_MAKER_TITLE' | translate }}</mat-label>
        <input matInput type="text" formControlName="decisionMakerTitle" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LAWYER.DECISION_MAKER_NAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="decisionMakerName" autocomplete="off" required />
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <ng-container *ngIf="lawyerFormVS.get('decisionType').value !== null">
      <field-holder>
        <mat-form-field appearance="outline" *ngIf="
            lawyerFormVS.get('decisionType').value.id !== undefined &&(
            lawyerFormVS.get('decisionType').value.id === 2 || lawyerFormVS.get('decisionType').value.id === 4 ||
            lawyerFormVS.get('decisionType').value.id === 5 ||
            (lawyerFormVS.get('decisionType').value.id === 3 && caseData.socialCaseClassificationCodebook.oldKindMark!=='STAR'))
          ">
          <mat-label>
            {{
            'GLOBAL.DECISION_FOR_CASE_' +
            lawyerFormVS.get('decisionType').value.id | translate
            }}
          </mat-label>
          <input matInput type="text" formControlName="terminationOfRights" autocomplete="off"
            id="terminationOfRights" />
        </mat-form-field>
      </field-holder>
      <field-holder>
        <mat-form-field appearance="outline"
          *ngIf="lawyerFormVS.get('decisionType').value.id===3 && caseData.socialCaseClassificationCodebook.oldKindMark==='STAR'">
          <mat-label>
            {{ 'GLOBAL.DECISION_FOR_CASE_3'| translate }}
          </mat-label>
          <mat-select formControlName="terminationReason" [compareWith]="compareLoadedObjects" required>
            <mat-option *ngFor="let reason of terminationReasons" [value]="reason">
              {{ reason.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </field-holder>
    </ng-container>
    <ng-container class="aside">
      <div>
        <button mat-flat-button color="primary" type="button" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
          (click)="$event.stopPropagation()">
          {{ 'LAWYER.DOCUMENT' | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="filter-menu">
          <ng-container *ngFor="let docOptions of documentOptions">
            <button mat-menu-item (click)="handleNewEvaluation(docOptions)">
              {{ docOptions.name }}
            </button>
          </ng-container>
        </mat-menu>

        <input type="file" #file accept=".docx, .pdf" (change)="handleFileInput($event.target.files)" hidden />
        <button class="header__add-document" style="margin-left: 15px" mat-flat-button color="primary" type="submit"
          (click)="file.click()">
          {{ 'LAWYER.CREATE' | translate }}
        </button>
      </div>
      <br />
      <field-holder class="field">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'LAWYER.SOLUTION' | translate }}</mat-label>
          <input matInput type="text" formControlName="fileName" readonly required autocomplete="off" />
        </mat-form-field>
      </field-holder>
    </ng-container>
    <form-footer class="footer">
      <span matTooltip="{{ 'CASES.NO_REGISTER_NUMBER' | translate }}" [matTooltipDisabled]="!noRegistrationId">
        <span matTooltip="{{ 'CASES.COMPLAIN_RESPOND_NEEDED' | translate }}"
          [matTooltipDisabled]="!caseData?.newComplain">
          <button mat-flat-button color="primary" (click)="submitConclusion(); submitButton.disabled = true"
            #submitButton class="form-footer__button form-footer__button--secondary" [disabled]="
              fileToUpload === null ||
              !lawyerFormVS.valid ||
              caseData?.caseStatus !== 'ACCEPTED' ||
              noRegistrationId ||
              caseData?.newComplain ||
              !hasProblem
            ">
            {{ 'DPN.SEND' | translate }}
          </button>
        </span>
      </span>
      <mat-progress-spinner [diameter]="45" *ngIf="isSubmited" color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </form-footer>
  </form>
</mat-card>