/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { fileCheck } from 'src/app/utils/fileInputUtil';
import { ErrorType, manageError } from 'src/app/utils/warningMessage';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { environment } from 'src/environments/environment';
import { AlfrescoService } from 'src/services/alfresco.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { CaseService } from 'src/services/case.service';
import { ExpeditionService } from 'src/services/expedition.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { ModalSizeEnum } from 'src/types';

/**
 * AdministrativeProcedureComponent
 *
 * @author Olja Andjelovski olja.adjelovski@iten.rs
 * @since 2021-03-24
 */


@Component({
  selector: 'app-administrative-procedure',
  templateUrl: './administrative-procedure.component.html',
  styleUrls: ['./administrative-procedure.component.scss']
})
export class AdministrativeProcedureComponent implements OnInit {
  fileToUpload: File = null;
  administrativeProcedureForm: FormGroup;
  dateOfCaseCreation: any;
  caseName: any;
  caseId: any;
  subjectId: any;
  isValid: any;
  caseData: any;
  subject: any;
  minDate = new Date();
  child: any;
  base: any;
  subCaseKind: any;
  documentsKind: any;
  title: string;
  documentOptions: any;
  loggedIn: any;
  teamMember = false;
  object = {
    document: {
      documentId: '',
      caseId: '',
      caseName: '',
    },
    action: '',
    subjectId: ''
  };
  @ViewChild('file') file: ElementRef<HTMLInputElement>;
  @ViewChild('submitButton') submitButton;

  constructor(
    private alfrescoService: AlfrescoService,
    private formBuilder: FormBuilder,
    private router: Router,
    private caseService: CaseService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private translate: TranslateService,
    private authentication: AuthenticationService,
    private localStorageService: LocalStorageService,
    private toastr: ToastrImplService,
    private route: ActivatedRoute,
    private expedition: ExpeditionService,
  ) {
    this.route.params.subscribe(params => {
      this.subjectId = params.subjectId;
      this.caseId = params.id;
      const [_, caseType] = this.router.url.split('/cases/');
      if (caseType !== '') {
        this.caseName = caseType.split('/')[0];
      }
      this.isValid = this.subjectId !== undefined || this.subjectId !== null;
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.localStorageService.set('localStorageCenterId', this.loggedIn?.csrId);
      if (this.isValid) {
        this.caseService.getSocialCase(this.caseId).subscribe(
          (result) => {
            this.caseData = result;
            if (this.loggedIn?.id !== this.caseData.assignedUserId) {
              this.teamMember = true;
            } else {
              this.teamMember = false;
            }
            this.title = this.caseData.socialCaseClassificationCodebook.title;
                  this.getDocumentsByTemplate();

          });
      }
    });
  }

  ngOnInit(): void {
    this.createAdministrativeProcedureForm();
  }

  createAdministrativeProcedureForm() {
    this.administrativeProcedureForm = this.formBuilder.group({
      requestDate: new Date(),
      lawyerFile: [''],
      fileName: ['', [Validators.required]],
      lawyerComment: [''],
      subjectId: this.subjectId,
      caseId: this.caseId,
    });
  }

  updateStatus(newStatus: string) {
    this.caseData.caseStatus = newStatus;
    this.cdr.detectChanges();
  }

  submitConclusion() {
    this.caseService.addConclusionFinishCase(
      this.administrativeProcedureForm.get('lawyerFile').value,
      this.administrativeProcedureForm.get('subjectId').value,
      this.administrativeProcedureForm.get('caseId').value).subscribe((documentId: string) => {
        this.toastr.success('SNACKBAR.DOCUMENT_ADDED');
        this.expedition.send(this.object, { documentId, caseId: this.caseId, caseName: this.caseData.caseName, subjectId: this.subjectId }, 'adm')
        this.localStorageService.set('case', true);

      }, error => {
        this.submitButton.disabled = false;
        manageError(this.toastr, ErrorType.GENERATE_SOLUTION);
      });
      
  }

  handleNewEvaluation(doc: any) {
      //    window.location.href = 'ms-word:ofe|u|' + url;
      const body = {
        documentId: doc.documentId,
        fileName: doc.name,
        caseId: this.caseId === undefined ? null : this.caseId,
     };
     this.alfrescoService.downloadDocumentByUrl(body);
  }

  handleFileInput(files: FileList) {
    const modifiedFiles = fileCheck(files, this.dialog, this.translate);
      if (modifiedFiles.length > 0) {
         this.fileToUpload = modifiedFiles[0];
         this.administrativeProcedureForm.get('lawyerFile').setValue(this.fileToUpload);
         this.administrativeProcedureForm.get('fileName').setValue(this.fileToUpload.name);
      }
      this.file.nativeElement.value = null;
   }

  cancelCase() {
    this.router.navigate(['/cases']);
  }

  getDocumentsByTemplate() {
      this.alfrescoService.getDocumentTemplates(this.caseData.socialCaseClassificationCodebook.oldKindMark).subscribe(result => {
      this.documentOptions = result;
    });
  }

   acceptedComplain() {
      this.caseData.newComplain = false;
   }
}

