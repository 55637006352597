<mat-card class="step__card" [ngClass]="{
    step__card: true,
    'step__card--initial': stepStatus === 'INITIAL',
    'step__card--started': stepStatus === 'STARTED',
    'step__card--finished': stepStatus === 'FINISHED'
  }">
    <header class="step__header">
        <main>
            <h4 class="step__title">{{ 'VS.NEW_EVALUATION_AND_ASSESSMENT.TITLE' | translate }}</h4>
            <label-step-status [status]="stepStatus"></label-step-status>
        </main>
        <aside class="step__action" *ngIf="isEditMode !== true && stepStatus !== 'FINISHED'">
            <span [matTooltip]="!editEnabled ? acceptCaseMissingPermitionsTooltip : '' ">
                <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}"
                    [matTooltipDisabled]="editEnabled===false||noRegistrationId===false">
                    <button mat-stroked-button color="primary" (click)="handleInitStep()"
                        *ngIf="stepStatus === 'INITIAL'"
                        [disabled]="!editEnabled || waitingForApproval||noRegistrationId">
                        {{ 'VS.NEW_EVALUATION_AND_ASSESSMENT.CREATE' | translate }}
                    </button>
                </span>
            </span>
            <button mat-stroked-button color="primary" (click)="toggleEdit()" *ngIf="stepStatus === 'STARTED'"
                [disabled]="noRegistrationId||waitingForApproval" class="step__action--edit">
                {{ 'VS.EDIT' | translate }}
            </button>

        </aside>
    </header>

    <form class="form" [formGroup]="newEvaluationAndAssessment" *ngIf="isEditMode === true">
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.NEW_EVALUATION_AND_ASSESSMENT.NOTE' | translate }}
                </mat-label>
                <textarea matInput type="text" formControlName="note" autocomplete="off"></textarea>
                <mat-error *ngIf="newEvaluationAndAssessment.get('note').hasError('required')">
                    {{ 'VS.NEW_EVALUATION_AND_ASSESSMENT.NOTE_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </field-holder>
        <form-footer>
            <button mat-stroked-button color="primary" type="button" (click)="toggleEdit()"
                class="form-footer__button form-footer__button--secondary">
                {{ 'VS.CANCEL' | translate }}
            </button>
            <button mat-stroked-button color="primary" type="button" (click)="handleSaveChanges()" [disabled]="newEvaluationAndAssessment.get('note').value === '' || newEvaluationAndAssessment.get('note').value === null ||
                    newEvaluationAndAssessment.get('note').value === undefined"
                class="form-footer__button form-footer__button--secondary">
                {{ 'VS.SAVE' | translate }}
            </button>

        </form-footer>
    </form>

    <section class="step__saved-data" *ngIf="notes.length > 0 && isEditMode === true">
        <definition-list *ngFor="let note of notes">
            <evaluation-of-plan-item valueOffset="120px" key="{{ 'VS.NEW_EVALUATION_AND_ASSESSMENT.NOTE' | translate }}"
                meta="{{ note.dateOfCreation }}   - Аутор  {{note.author}}">

                {{ note.note }}
            </evaluation-of-plan-item>
        </definition-list>
    </section>
</mat-card>