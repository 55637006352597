<ng-container>
    <form [formGroup]="teamMemberForm" class="form">

        <br>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'TEAM_MEMBER.FINDING' | translate }}
                </mat-label>
                <textarea matInput type="text" formControlName="finding" autocomplete="off"></textarea>
            </mat-form-field>
        </field-holder>
        <form-footer>
            <button mat-flat-button color="primary" (click)="submitConclusion(); submitButton.disabled = true" #submitButton [disabled]="!editable"
                class="form-footer__button form-footer__button--secondary">
                {{ 'TEAM_MEMBER.SEND' | translate }}
            </button>
        </form-footer>
    </form>
</ng-container>