<form class="form" [formGroup]="complainDecisionForm" (ngSubmit)="makeDecision(complainDecisionForm)">
    <form-group title="{{ ('CASES.COMPLAIN_RESPOND' | translate) }}" isLast="true">
                <mat-radio-group aria-label="Select an option" color="primary" formControlName="decision"
                         class="form__radio-group form__radio-group--4-cols radio-group-one-column">
          <mat-radio-button *ngFor="let option of complainResponses;let i = index"  [value]="option" 
                         (change)="radioChange($event.value)"   >
            {{ option.title }}
          </mat-radio-button>
        </mat-radio-group>
    </form-group>
    <form-footer>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
            class="form-footer__button form-footer__button--secondary">
            {{ 'DIALOG.CANCEL' | translate }} 
        </button>
        <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
            [disabled]="!complainDecisionForm.valid">
            {{ 'DIALOG.CONFIRM' | translate }}
        </button>
    </form-footer>
</form>