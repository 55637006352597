<header class="header">
   <card-header title="{{ 'SUBJECT_SK.INPUT_SUBJECT_TITLE' | translate }}">
   </card-header>
   <div class="header__actions">
      <form class="form" [formGroup]="socialMapSubjectForm" style="width: 100%;">
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label>{{
                  "SUBJECT_SK.RIGHT" | translate
                  }}</mat-label>
               <mat-select formControlName="rightCode" required>
                  <mat-option *ngFor="let right of externalOptions" [value]="right.value" panelWidth="auto">
                     {{ right.viewValue }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <h4 *ngIf="participations.length > 0">
            {{'SUBJECT_SK.PARTICIPATIONS' | translate}}
         </h4>
         <button mat-stroked-button color="primary" class="form-footer__button" (click)="addParticipations()">
            {{ 'SUBJECT_SK.ADD_PARTICIPATION' | translate }}
         </button>
         <div class="table-responsive-wrapper" *ngIf="participations.length > 0">
            <br />
            <table mat-table id="myTable" #myTable [dataSource]="dataSource">
               <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SUBJECT_SK.FIRST_NAME' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                     {{ element?.firstName }}
                  </td>
               </ng-container>
               <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SUBJECT_SK.LAST_NAME' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                     {{ element?.lastName }}
                  </td>
               </ng-container>
               <ng-container matColumnDef="jmbg">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SUBJECT_SK.JMBG' | translate }}</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                     <span>
                        {{element?.jmbg}}
                     </span>
                  </td>
               </ng-container>
               <ng-container matColumnDef="relationship">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SUBJECT_SK.RELATIONSHIP' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                     <span>
                        {{ element?.relationship?.viewValue}}
                     </span>
                  </td>
               </ng-container>
               <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index" class="table__cell table__cell--align-right">
                     <button mat-icon-button class="button-square table__row-button" color="primary" type="button"
                        matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
                        #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                        <mat-icon class="button-square__icon">more_horiz</mat-icon>
                     </button>
                     <mat-menu #menu="matMenu" class="filter-menu">
                        <button mat-menu-item (click)="removeParticipations(i)" type="button">
                           <mat-icon class="button-square__icon">delete</mat-icon>
                           {{ 'SUBJECT_SK.REMOVE_PARTICIPATION' | translate }}
                        </button>
                     </mat-menu>
                  </td>
               </ng-container>
               <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
               <tr mat-row *matRowDef="let element; let row;let index; columns: displayedColumns" class="subjects__row">
               </tr>
            </table>
         </div>
         <h4 *ngIf="participates.length > 0">
            {{'SUBJECT_SK.PARTICIPATES' | translate}}
         </h4>
         <button mat-stroked-button color="primary" class="form-footer__button" (click)="addParticipates()">
            {{ 'SUBJECT_SK.ADD_PARTIPATES' | translate }}
         </button>
         <div class="table-responsive-wrapper" *ngIf="participates.length > 0">
            <br />
            <table mat-table id="myTable1" #myTable [dataSource]="dataSource1">
               <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SUBJECT_SK.FIRST_NAME' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                     {{ element?.firstName }}
                  </td>
               </ng-container>
               <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SUBJECT_SK.LAST_NAME' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                     {{ element?.lastName }}
                  </td>
               </ng-container>
               <ng-container matColumnDef="jmbg">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SUBJECT_SK.JMBG' | translate }}</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                     <span>
                        {{element?.jmbg}}
                     </span>
                  </td>
               </ng-container>
               <ng-container matColumnDef="relationship">
                  <th mat-header-cell *matHeaderCellDef>
                     {{ 'SUBJECT_SK.RELATIONSHIP' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                     <span>
                        {{ element?.relationship?.viewValue}}
                     </span>
                  </td>
               </ng-container>
               <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element; let i = index" class="table__cell table__cell--align-right">
                     <button mat-icon-button class="button-square table__row-button" color="primary" type="button"
                        matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
                        #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                        <mat-icon class="button-square__icon">more_horiz</mat-icon>
                     </button>
                     <mat-menu #menu="matMenu" class="filter-menu">
                        <button mat-menu-item (click)="removeParticipates(i)" type="button">
                           <mat-icon class="button-square__icon">delete</mat-icon>
                           {{ 'SUBJECT_SK.REMOVE_PARTIPATES' | translate }}
                        </button>
                     </mat-menu>
                  </td>
               </ng-container>
               <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
               <tr mat-row *matRowDef="let element; let row;let index; columns: displayedColumns" class="subjects__row">
               </tr>
            </table>
         </div>
         <form-footer>
            <button mat-stroked-button color="primary" class="form-footer__button" (click)="sendRequest()"
               [disabled]="!socialMapSubjectForm.valid">
               {{ 'SIDEBAR_WIDGET.OTHER_INSTITUTIONS.SUBMIT' | translate }}
            </button>
            <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()"
               class="modal-footer__button modal-footer__button--secondary">
               {{ 'WARNING_DIALOG.CANCEL' | translate }}
            </button>
         </form-footer>
      </form>
   </div>
</header>