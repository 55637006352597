import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViolenceCommiterService } from 'src/services/violence-commiter.service';

@Component({
  selector: 'app-domestic-violence-commiter-details',
  templateUrl: './domestic-violence-commiter-details.component.html',
  styleUrls: ['./domestic-violence-commiter-details.component.scss']
})
export class DomesticViolenceCommiterDetailsComponent implements OnInit {

  violenceCommiterId: any;
  violenceCommiter: any;
  constructor(
    private route: ActivatedRoute,
    private violenceCommiterService: ViolenceCommiterService) {
    this.route.params.subscribe((params) => {
      this.violenceCommiterId = params.id;
    });
    this.getViolenceCommiter();
  }

  ngOnInit(): void {
  }

  getViolenceCommiter() {
    this.violenceCommiterService.findById(this.violenceCommiterId).subscribe((res) => {
      res.dateOfBirth =
      res.dateOfBirth?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      res.judgmentDate =
      res.judgmentDate?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      res.entryForceDate =
      res.entryForceDate?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      res.measureDurationFrom =
	  res.measureDurationFrom?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
	  res.measureDurationTo =
	  res.measureDurationTo?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
      res.terminationDate =
      res.terminationDate?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');

      res.violenceKindCodebook.sort((a, b) =>  a.id - b.id);
      res.familyMemberCommiter.sort((a, b) =>  a.key - b.key);

      this.violenceCommiter = res;
    });
  }

}
