import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/services/app.service';
import { UserService } from 'src/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class SecondDegreeGuard implements CanActivate {
  constructor(
    public appService: AppService, 
    public userService: UserService
  ) {}
  canActivate(): boolean {
    const isSecondDegree = this.userService.isUserDrugostepeni();
    if (isSecondDegree) {
      return true;
    } else {
      if (environment.deployed) {
        window.location.href = this.appService.getUrl() + environment.startUrl;
      } else {
        window.location.href = environment.startUrl;
      }
      return false;
    }
  }
}
