/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from 'src/models/user.model';
import { AuthenticationService } from 'src/services/authentication.service';
import { CaseService } from 'src/services/case.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { LocalStorageService } from '../local-storage.service';

export interface SocialCaseData {
  user: any;
  holdCaseStartDate: any;
  holdCaseEndDate: any;
  members: any;
}
enum AutocompleteFilterEnum {
  USER = 'user',
}

@Component({
  selector: 'app-assign-case-dialog',
  templateUrl: './assign-case-dialog.component.html',
  styleUrls: ['./assign-case-dialog.component.scss'],
})
export class AssignCaseDialogComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  local_data: any;
  users: any = [];
  filteredUsers: Observable<any[]>;
  team = [];
  teamFiltered = [];
  disableBtn:boolean;
  loggedIn: User;
  assignCaseForm: FormGroup;
  //  membersCount: number = 0;
  source: any;
  dateOfCreation = null;
  isTeamRequired = false;
  forwardingLawyerWorker = false;
  isLawyerAssigner = false;
  isWorkerAndLawyer = false;
  isLawyer = false;
  maxDate: Date = new Date(2999, 11, 31);
  roles = [{ role: environment.strucni_radnik, roleName: 'Стручни радник' }, { role: environment.pravnik, roleName: 'Правник' }];
  minToDate: Date;

  constructor(
    private formBuilder: FormBuilder,
    public caseService: CaseService,
    public subjectsService: SubjectsService,
    public dialogRef: MatDialogRef<AssignCaseDialogComponent>,
    public authenticationService: AuthenticationService,
    private translate: TranslateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SocialCaseData,
    private toastr: ToastrImplService,
    private localStorageService: LocalStorageService,
    public userService: UserService
  ) {
    this.local_data = { ...data };
    if (this.local_data.oldKindMark === 'STAR' || this.local_data.oldKindMark === 'USVO') {
        this.isTeamRequired = true;
    }

    this.dateOfCreation = new Date(this.local_data.dateOfCreation.replace(/(\d{2}).(\d{2}).(\d{4})/, '$2/$1/$3'));

    if (this.local_data.forLawyer) {
      this.source = 'pmn';
    } else {
      this.source = this.local_data.source;
    }

    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    this.userService.getAssignableUsersInCenter(this.loggedIn.csrId).subscribe((res) => {
      this.team = res;
      this.teamFiltered = this.team.filter(user => user.id !== this.local_data.assignedUserId);

      this.users = res;
      this.filteredUsers = this.assignCaseForm.controls.user.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name),
          map(title => title ? this._filter(title, AutocompleteFilterEnum.USER) : this.users.slice())
        );

      this.checkRoles(this.users.filter(user => user.id === this.local_data.assignedUserId)[0], this.local_data.asLawyer);

      this.assignCaseForm.patchValue({
        user: this.users.filter(user => user.id === this.local_data.assignedUserId)[0],
        team: this.local_data.team,
        holdCaseStartDate: this.local_data.dateAssignedFrom !== undefined && this.local_data.dateAssignedFrom !== null ?
          new Date(this.local_data.dateAssignedFrom.replace(/(\d{2}).(\d{2}).(\d{4})/, '$2/$1/$3')) : new Date(),
        holdCaseStartTo: this.local_data.dateAssignedTo !== undefined && this.local_data.dateAssignedTo !== null ?
          new Date(this.local_data.dateAssignedTo.replace(/(\d{2}).(\d{2}).(\d{4})/, '$2/$1/$3')) : null,
        role: this.isWorkerAndLawyer ? (this.local_data.asLawyer ? this.roles[1] : this.roles[0]) : null
      });
    });
  }

  compareRoles(object1: any, object2: any) {
    if (object2 !== null) {
      return object1.role === object2.role;
    }

  }

  checkRoles(value: any, isForLawyer) {

    this.isLawyer = false;
    this.isWorkerAndLawyer = false;
    this.assignCaseForm.get('role').clearValidators();
    this.assignCaseForm.get('role').setErrors(null);
    this.assignCaseForm.get('role').setValue(null);

    if (value !== undefined && value.roleCode.includes(environment.pravnik)) {
      this.isLawyer = true;
      if (value.roleCode.includes(environment.strucni_radnik)) {
        this.assignCaseForm.get('role').setValidators(Validators.required);
        this.isWorkerAndLawyer = true;
        this.assignCaseForm.get('role').setErrors({required: true});
      } else {

      }
    }
  }
  focusUserInput(){
    this.disableBtn = true;
  }
  autoSelectUser(){
    setTimeout(()=>{
      const form = this.assignCaseForm;
      let value = form.value.user;
      let user: any;
      if(value === undefined || value === null){
        return;
      }
      if( value?.name === undefined || value?.name === null ){
        if(value === ''){
          return;
        }
        if(typeof value === 'string'){
          value = value.split(' ');
          let index;
          value.map((item: string, i: number) => item.includes('(')? index = i : index);
          value = value.slice(0, index).join(' ').trim().toLowerCase();
        }
          user = this.sortFirstName(this.users.filter((user:any) => user.name.toLowerCase().includes(value)), value)[0];
      } else {
        value = value.name.trim().toLowerCase();
        user = this.sortFirstName(this.users.filter((user:any) => user.name.toLowerCase().includes(value)), value)[0];
      }
      if(this.assignCaseForm.get('user').value.id === undefined){
        this.assignCaseForm.get('user').setValue(user);
      }
      this.filterTeam(user?.id, true);
      this.checkRoles(user, false);
      this.disableBtn = false;
    },500);
  }
  
  filterTeam(event: any, isAuto: boolean) {
    this.teamFiltered = this.team.filter(user => user.id !== this.assignCaseForm.get('user').value?.id);
    this.teamFiltered = this.teamFiltered.filter(xx => xx.id !== ( isAuto? event : event.value.id ));
  }

  compareTeamMembers(object1: any, object2: any) {
    if (object2 !== null) {
      return object1 === object2;
    }
  }

  ngOnInit(): void {
    this.assignCaseForm = this.formBuilder.group({
      user: ['', [Validators.required]],
      assignee: [this.local_data.assignee],
      request: [this.local_data.request],
      holdCaseStartDate: [new Date(), []],
      holdCaseEndDate: ['', []],
      workload: [this.local_data.workload],
      team: [],
      taskDescription: [''],
      role: []
    });

    if (this.local_data.dateAssignedFrom !== null && this.local_data.dateAssignedFrom !== undefined) {
      this.assignCaseForm.patchValue({
        holdCaseStartDate: new Date(this.local_data.dateAssignedFrom.replace(/(\d{2}).(\d{2}).(\d{4})/, '$2/$1/$3'))

      });
    }

    if (this.local_data.dateAssignedTo !== null && this.local_data.dateAssignedTo !== undefined) {
      this.assignCaseForm.patchValue({
        holdCaseStartTo: new Date(this.local_data.dateAssignedTo.replace(/(\d{2}).(\d{2}).(\d{4})/, '$2/$1/$3'))
      });
    }

    if (this.local_data.isTeamRequest) {
      this.setDynamicTeamValidators();
    } else if (this.local_data.source !== 'nsp-nm' && this.local_data.source !== 'case-header') {
      this.removeDynamicTeamValidators();
    }

    this.assignCaseForm.get('holdCaseStartDate').valueChanges.subscribe((fromDate: Date) => {
      this.minToDate = fromDate;
    });
  }

  setDynamicValidators() {
    this.assignCaseForm.controls.holdCaseStartDate.setValidators(Validators.required);
    this.assignCaseForm.controls.workload.setValidators(Validators.required);
  }

  removeDynamicValidators() {
    this.assignCaseForm.controls.holdCaseEndDate.clearValidators();
    this.assignCaseForm.controls.holdCaseStartDate.clearValidators();
    this.assignCaseForm.controls.workload.clearValidators();
  }

  setDynamicTeamValidators() {
    this.assignCaseForm.controls.user.clearValidators();
    this.assignCaseForm.controls.holdCaseEndDate.clearValidators();
    this.assignCaseForm.controls.holdCaseStartDate.clearValidators();
    this.assignCaseForm.controls.workload.clearValidators();
    this.assignCaseForm.controls.team.setValidators(Validators.required);
  }

  removeDynamicTeamValidators() {
    this.assignCaseForm.controls.holdCaseStartDate.setValidators(Validators.required);
    this.assignCaseForm.controls.workload.setValidators(Validators.required);
    this.assignCaseForm.controls.team.clearValidators();
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  assignCase(submittedForm: FormGroup) {
    if (submittedForm.get('user').value.id === undefined) {
      this.local_data.user = submittedForm.get('user').value;
    } else {
      this.local_data.user = submittedForm.get('user').value.id;
    }
    this.local_data.holdCaseStartDate = submittedForm.get('holdCaseStartDate').value;
    this.local_data.holdCaseEndDate = submittedForm.get('holdCaseEndDate').value;
    this.local_data.team = submittedForm.get('team').value;
    this.local_data.workload = submittedForm.get('workload').value;
    this.local_data.taskDescription = submittedForm.get('taskDescription').value;
    this.local_data.role = this.isWorkerAndLawyer ?
      submittedForm.get('role').value.role : this.isLawyer ? environment.pravnik : environment.strucni_radnik;
    this.local_data.name = submittedForm.get('user').value.name;

    if (!this.local_data.isTeamRequest) {
      this.dialogRef.close({ event: 'SUCCESS', data: this.local_data });
    } else {
      this.dialogRef.close({ event: 'SUCCESS_TEAM', data: this.local_data });
    }

    // this.router.navigateByUrl('/');
  }

  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.name + ' (' + objectValue.role + ')' : '';
  }

  /**
   * _filter
   * @param value input value
   * @param what which filter should be applied (check AutocompleteFilterEnum)
   */
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();

    switch (what) {
      case AutocompleteFilterEnum.USER: {
        return this.sortFirstName(this.users.filter((option) => option.name.toLowerCase().includes(filterValue)), filterValue);
      }
      default:
        break;

    }
  }

  sortFirstName(arr:any,value:string){
    return arr.sort((a:any ,b:any) => {
      const aFirstName = a.name.split(' ')[0].toLowerCase();
      const bFirstName = b.name.split(' ')[0].toLowerCase();
      const aFirstNameMatch= aFirstName.includes(value) && aFirstName[0] === value[0];
      const bFirstNameMatch= bFirstName.includes(value) && bFirstName[0] === value[0];
      return aFirstNameMatch === bFirstNameMatch ? 0 : aFirstNameMatch?  -1 : 1;
    });
  }

  filterOptions() {

    this.filteredUsers = this.assignCaseForm.controls.user.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value, AutocompleteFilterEnum.USER))
    );
  }

  checkValueOfWorkload() {
    if (Number(this.assignCaseForm.value.workload) > 5) {
      this.assignCaseForm.controls.workload.setValue(5);
      this.toastr.info('ASSIGN_CASE.WORKLOAD_INFO');
    } else if (Number(this.assignCaseForm.value.workload) < 1) {
      this.assignCaseForm.controls.workload.setValue(1);
      this.toastr.info('ASSIGN_CASE.WORKLOAD_INFO');
    } else {
      this.assignCaseForm.controls.workload.setValue(Math.floor(Number(this.assignCaseForm.value.workload)));
    }
  }
  declineTeamReaquest(){
    this.dialogRef.close({ event: 'DECLINE_TEAM'});
  }
}
