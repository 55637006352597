<mat-vertical-stepper #stepper linear="true" class="stepper">
   <mat-step [stepControl]="firstStep">
      <form class="form" [formGroup]="firstStep">
         <ng-template matStepLabel>
            {{ 'VS.ACCOMMODATION.FORMSO.STEP_01.TITLE' | translate }}
         </ng-template>
         <h6 class="subgroup-title">
            {{ 'VS.ACCOMMODATION.FORMSO.STEP_01.GENERAL_TITLE' | translate }}
         </h6>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_01.NIC_NUMBER' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="nicNumber" autocomplete="off" maxlength="10" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.BASIC_HEALTH_INSURANCE'| translate}}
               </mat-label>
               <input matInput type="text" formControlName="basicHealthInsurance" autocomplete="off" maxlength="50" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.HEALTH_INSURANCE_CARD'| translate}}
               </mat-label>
               <input matInput type="text" formControlName="healtInsuranceCard" autocomplete="off" maxlength="13" />
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.FAMILY_MEMBERS_NUMBER'| translate}}
               </mat-label>
               <input matInput type="text" formControlName="familyMembersNumber" onlyNumber autocomplete="off"
                  maxlength="2" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.HOUSE_STATUS'| translate}}
               </mat-label>
               <input matInput type="text" formControlName="houseStatus" autocomplete="off" maxlength="30" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.HOUSE_CONCEPT'| translate}}
               </mat-label>
               <input matInput type="text" formControlName="houseConcept" autocomplete="off" maxlength="3" />
            </mat-form-field>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>
         <h6 class="subgroup-title">
            {{ 'VS.ACCOMMODATION.FORMSO.STEP_01.ADDITIONAL_INFO_TITLE' | translate }}
         </h6>
         <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.RELATIVE_PERSON_INFO'| translate}}
               </mat-label>
               <input matInput type="text" formControlName="relativePersonInfo" autocomplete="off" maxlength="120" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.CONTACT_PERSON_INFO'| translate}}
               </mat-label>
               <input matInput type="text" formControlName="contactPersonInfo" autocomplete="off" maxlength="120" />
            </mat-form-field>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>
         <field-holder>
            <mat-form-field appearance="outline">
               <mat-label> {{ 'VS.ACCOMMODATION.FORMSO.STEP_01.REASON_REQUEST' | translate }}
               </mat-label>
               <mat-select formControlName="accommodationReason" [compareWith]="compareObjectsWithId">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let reason of accommodationReasons" [value]="reason">
                     {{ reason.title }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>
         <h6 class="subgroup-title">
            {{ 'VS.ACCOMMODATION.FORMSO.STEP_01.REQUEST_DATA_TITLE' | translate }}
         </h6>
         <div class="mb-16">
            <button mat-flat-button color="primary" type="button" [disabled]="isSavedS1"
               (click)="importRequesterData()">
               <mat-icon>person_add</mat-icon>
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.IMPORT_SUBJECT' | translate }}
            </button>
         </div>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.REQUESTER_FIRST_NAME' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="requesterFirstName" autocomplete="off" maxlength="250" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.REQUESTER_LAST_NAME' | translate}}
               </mat-label>
               <input matInput type="text" formControlName="requesterLastName" autocomplete="off" maxlength="250" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.REQUESTER_NIC_NUMBER' | translate}}
               </mat-label>
               <input matInput type="text" formControlName="requesterNicNumber" autocomplete="off" maxlength="10" />
            </mat-form-field>
         </field-holder>
         <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{'VS.ACCOMMODATION.FORMSO.STEP_01.REQUESTER_ADDRESS' | translate}}
               </mat-label>
               <input matInput type="text" formControlName="requesterAddress" autocomplete="off" maxlength="250" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_01.REQUESTER_PHONE' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="requesterPhone" autocomplete="off" maxlength="15" />
            </mat-form-field>
         </field-holder>
      </form>
      <form-footer>
         <button *ngIf="caseData?.socialCaseClassificationCodebook?.oldKindMark === 'PS'" matStepperNext
            mat-stroked-button color="primary" type="button" class="form-footer__button form-footer__button--secondary">
            {{ 'VS.NEXT_STEP' | translate }}
         </button>
         <button mat-flat-button mat-stroked-button (click)="previewS1()"
            class="form-footer__button form-footer__button--secondary">
            {{ 'VS.PREVIEW' | translate }}
         </button>
         <button mat-flat-button mat-stroked-button color="primary" [disabled]="isSavedS1" (click)="saveFormS1(false)"
            class="form-footer__button form-footer__button--secondary">
            {{ 'VS.SAVE' | translate }}
         </button>
         <button mat-flat-button color="primary" (click)="saveFormS1(true)" [disabled]='isSavedS1'
            class="form-footer__button form-footer__button--secondary">
            {{ 'VS.ACCOMMODATION.GENERATE_BTN' | translate }}
         </button>
         <button *ngIf="caseData?.socialCaseClassificationCodebook?.oldKindMark === 'DS'" mat-flat-button
            color="primary" (click)="openDialog()" [disabled]='isSavedS1'
            class="form-footer__button form-footer__button--secondary">
            {{ 'VS.ACCOMMODATION.SEND_TO_LAWYER_BTN' | translate }}
         </button>
         <mat-progress-spinner *ngIf="isSavedS1" color="primary" diameter="35" class="small-spinner"
            mode="indeterminate">
         </mat-progress-spinner>
      </form-footer>
   </mat-step>
   <!-- Drugi korak je potreban samo kada se radi o porodicnom smjestaju -->
   <mat-step [stepControl]="secondStep" *ngIf="caseData?.socialCaseClassificationCodebook?.oldKindMark === 'PS'">
      <form class="form" [formGroup]="secondStep">
         <ng-template matStepLabel>
            {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.TITLE' | translate }}
         </ng-template>
         <div class="mb-16">
            <button mat-flat-button color="primary" [disabled]="isSavedS5" (click)="importSubjectData()">
               <mat-icon>person_add</mat-icon>
               {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.IMPORT_SUBJECT' | translate }}
            </button>
         </div>
         <field-holder kind="4-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.FIRST_NAME' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="firstName" autocomplete="off" maxlength="250" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.LAST_NAME' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="lastName" autocomplete="off" maxlength="250" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.JMBG' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="jmbg" autocomplete="off" maxlength="13" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.JMBP' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="jmbp" autocomplete="off" maxlength="13" />
            </mat-form-field>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>
         <field-holder kind="4-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.PTT_NUMBER' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="pttNumber" autocomplete="off" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.MUNICIPALITY' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="municipality" autocomplete="off" maxlength="250" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.PLACE' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="place" autocomplete="off" maxlength="250" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.PERMANENT_RESIDENCE_AND_NUMBER'| translate }}
               </mat-label>
               <input matInput type="text" formControlName="permanentResidence" autocomplete="off" maxlength="250" />
            </mat-form-field>
         </field-holder>
         <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.PHONE' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="phone" autocomplete="off" maxlength="15" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.EDUCATION' | translate }}
               </mat-label>
               <input type="text" matInput formControlName="education" [matAutocomplete]="autoEducation"
                  (focusout)="autoSelectItem(educationOptions, 'education')" />
               <mat-autocomplete #autoEducation="matAutocomplete" [displayWith]="displayCustomFormat">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let option of filteredEducationOptions | async" [value]="option">
                     {{ option.title }}
                  </mat-option>
               </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.OCCUPATION' | translate }}
               </mat-label>
               <input type="text" matInput formControlName="occupation" [matAutocomplete]="autoOccupation"
                  (focusout)="autoSelectItem(occupationOptions, 'occupation')" />
               <mat-autocomplete #autoOccupation="matAutocomplete" [displayWith]="displayCustomFormat"
                  panelWidth="auto">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let option of filteredOccupationOptions | async" [value]="option">
                     {{ option.title }}
                  </mat-option>
               </mat-autocomplete>
            </mat-form-field>
         </field-holder>
         <form-subgroup-divider></form-subgroup-divider>
         <field-holder kind="3-cols-2-1-1">
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.BANK_ACCOUNT' | translate }}
               </mat-label>
               <input matInput type="text" formControlName="bankAccount" autocomplete="off"
                  placeholder="{{ 'ACCOUNT_NUMBER_FORMAT' | translate }}" [textMask]="mask" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.RIGHT_TO_INSURANCE' | translate}}
               </mat-label>
               <input matInput type="text" formControlName="rightToInsurance" autocomplete="off" maxlength="250" />
            </mat-form-field>
            <mat-form-field appearance="outline">
               <mat-label>
                  {{ 'VS.ACCOMMODATION.FORMSO.STEP_02.PLACEMENT_IN_RELATIVE_FAMILY' | translate}}
               </mat-label>
               <mat-select formControlName="placementRelativeFamily" [compareWith]="compareObjects">
                  <mat-option></mat-option>
                  <mat-option [value]="true">Да</mat-option>
                  <mat-option [value]="false">Не</mat-option>
               </mat-select>
            </mat-form-field>
         </field-holder>
      </form>
      <form-footer>
         <button matStepperPrevious mat-stroked-button color="primary"
            class="form-footer__button form-footer__button--secondary">
            {{ "VS.PREVIOUS_STEP" | translate }}
         </button>
         <button mat-flat-button mat-stroked-button (click)="previewS5()"
            class="form-footer__button form-footer__button--secondary">
            {{ 'VS.PREVIEW' | translate }}
         </button>
         <button mat-flat-button color="primary" [disabled]="isSavedS5" (click)="saveFormS5(false)"
            class="form-footer__button form-footer__button--secondary">
            {{ 'VS.SAVE' | translate }}
         </button>
         <button mat-flat-button color="primary" [disabled]="isSavedS5" (click)="saveFormS5(true)"
            class="form-footer__button form-footer__button--secondary">
            {{ 'VS.ACCOMMODATION.GENERATE_BTN' | translate }}
         </button>
         <button mat-flat-button color="primary" [disabled]="isSavedS5" (click)="openDialog()"
            class="form-footer__button form-footer__button--secondary">
            {{ 'VS.ACCOMMODATION.SEND_TO_LAWYER_BTN' | translate }}
         </button>
         <mat-progress-spinner *ngIf="isSavedS5" color="primary" diameter="35" class="small-spinner"
            mode="indeterminate">
         </mat-progress-spinner>
      </form-footer>
   </mat-step>
</mat-vertical-stepper>
<div class="action-footer">
   <button mat-stroked-button color="primary" (click)="closeForm()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ 'DIALOG.CANCEL' | translate }}
   </button>
</div>