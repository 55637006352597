<form class="form" [formGroup]="printForm" (ngSubmit)="print(printForm)">
    <form-group title="{{ 'PRINT.TITLE' | translate }}" isLast="true">
        <field-holder>
            <mat-label>
                {{ 'PRINT.PRINT_SIZE' | translate }}
            </mat-label>
            <mat-radio-group aria-label="Select an option" color="primary" formControlName="printSize"
                class="form__radio-group">
                <mat-radio-button [value]="'whole'">
                    {{ 'PRINT.WHOLE' | translate }}
                </mat-radio-button>
                <mat-radio-button *ngIf="data.src!=='s'" [value]="'partial'">
                    {{ 'PRINT.PARTIAL' | translate }}
                </mat-radio-button>
                <mat-radio-button *ngIf="data.src==='s'" [value]="'partialById'">
                    {{ 'PRINT.PARTIAL_ID' | translate }}
                </mat-radio-button>
                <mat-radio-button *ngIf="data.src==='s'" [value]="'partialByRegId'">
                    {{ 'PRINT.PARTIAL_REG' | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </field-holder>
        <field-holder kind="2-cols">
            <mat-form-field appearance="outline" *ngIf="conditionVisible">
                <mat-label
                    *ngIf="data.src === 'ls'||(data.src ===  's' && printForm.get('printSize').value==='partialById')">
                    {{ 'PRINT.FROM_ID' | translate }}
                </mat-label>
                <mat-label *ngIf="data.src === 's' && printForm.get('printSize').value === 'partialByRegId'">
                    {{ 'PRINT.FROM_REG_ID' | translate }}
                </mat-label>
                <input matInput type="text" formControlName="conditionFrom" autocomplete="off"
                    [required]="conditionVisible" />
                <mat-error *ngIf="printForm.get('conditionFrom').hasError('required')">
                    {{ 'PRINT.FROM_REQUIRED_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="printForm.get('conditionFrom').hasError('min') && (data.src === 'ls' || (data.src === 's' && printForm.get('printSize').value === 'partialById'))">
                    {{ 'PRINT.FROM_ID_MIN_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="printForm.get('conditionFrom').hasError('max') && (data.src === 'ls' || (data.src === 's' && printForm.get('printSize').value === 'partialById'))">
                    {{ 'PRINT.FROM_ID_MAX_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="printForm.get('conditionFrom').hasError('min') && data.src === 's' && printForm.get('printSize').value === 'partialByRegId'">
                    {{ 'PRINT.FROM_REG_ID_MIN_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="printForm.get('conditionFrom').hasError('max') && data.src === 's' && printForm.get('printSize').value === 'partialByRegId'">
                    {{ 'PRINT.FROM_REG_ID_MAX_ERROR' | translate }}
                </mat-error>
                <mat-error *ngIf="printForm.get('conditionFrom').hasError('pattern')">
                    {{ 'PRINT.FROM_PATTERN_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="conditionVisible">
                <mat-label
                    *ngIf="data.src === 'ls' || (data.src === 's' && printForm.get('printSize').value === 'partialById')">
                    {{ 'PRINT.TO_ID' | translate }}
                </mat-label>
                <mat-label *ngIf="data.src === 's' && printForm.get('printSize').value === 'partialByRegId'">
                    {{ 'PRINT.TO_REG_ID' | translate }}
                </mat-label>
                <input matInput type="text" formControlName="conditionTo" autocomplete="off"
                    [required]="conditionVisible" />
                <mat-error *ngIf="printForm.get('conditionTo').hasError('required')">
                    {{ 'PRINT.TO_REQUIRED_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="printForm.get('conditionTo').hasError('min') && (data.src === 'ls' || (data.src === 's' && printForm.get('printSize').value === 'partialById'))">
                    {{ 'PRINT.TO_ID_MIN_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="printForm.get('conditionTo').hasError('max') && (data.src === 'ls' || (data.src === 's' && printForm.get('printSize').value === 'partialById'))">
                    {{ 'PRINT.TO_ID_MAX_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="printForm.get('conditionTo').hasError('min') && data.src === 's' && printForm.get('printSize').value === 'partialByRegId'">
                    {{ 'PRINT.TO_REG_ID_MIN_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="printForm.get('conditionTo').hasError('max') && data.src === 's' && printForm.get('printSize').value === 'partialByRegId'">
                    {{ 'PRINT.TO_REG_ID_MAX_ERROR' | translate }}
                </mat-error>
                <mat-error *ngIf="printForm.get('conditionTo').hasError('pattern')">
                    {{ 'PRINT.TO_PATTERN_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </field-holder>
    </form-group>


    <form-footer>
        <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
            class="form-footer__button form-footer__button--secondary">
            {{ 'DIALOG.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
            [disabled]="!printForm.valid">
            {{ 'DIALOG.CONFIRM' | translate }}
        </button>
    </form-footer>
</form>