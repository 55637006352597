<form [formGroup]="fatherInformationStep" class="form">
  <form-group title="{{ 'NEW_FOSTER_CHILD.PARENT_INFORMATION.FATHER' | translate }}">
    <div class="button-add-holder">
      <button mat-flat-button color="primary" type="button" (click)="importFatherData()">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>

    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.FIRST_NAME' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="firstName" autocomplete="off" />
        <mat-error *ngIf="fatherInformationStep.get('firstName').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.LAST_NAME' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="lastName" autocomplete="off" />
        <mat-error *ngIf="fatherInformationStep.get('lastName').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.JMBG' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="jmbg" maxlength="13"
          (input)="updateJMBGOnDateOfBirthChangeFather($event)" autocomplete="off" />
        <mat-error *ngIf="fatherInformationStep.get('jmbg').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="fatherInformationStep.get('jmbg').hasError('minLength') ||
                  fatherInformationStep.get('jmbg').hasError('maxLength') ||
                  fatherInformationStep.get('jmbg').hasError('pattern')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.DATE_OF_BIRTH' | translate
          }}</mat-label>
        <input matInput [matDatepicker]="father" [max]="currentDate"
          (dateChange)="updateJMBGOnDateOfBirthChangeFather($event)" formControlName="dateOfBirth" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="father"></mat-datepicker-toggle>
        <mat-datepicker #father></mat-datepicker>
        <mat-error *ngIf="fatherInformationStep.get('dateOfBirth').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="fatherInformationStep.get('dateOfBirth').hasError('incorrect')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.PLACE_OF_BIRTH' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" />
        <mat-error *ngIf="fatherInformationStep.get('placeOfBirth').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_FOSTER_CHILD.FORM.CITIZENSHIP' | translate }}
        </mat-label>
        <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenshipFather"
          (focusout)="checkCitizenship('father')" />
        <mat-autocomplete #autoCitizenshipFather="matAutocomplete" [displayWith]="displayCustomFormat">
          <mat-option *ngFor="let option of filteredCitizenshipOptionsFather | async" [value]="option">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="fatherInformationStep.get('citizenship').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="fatherInformationStep.get('citizenship').hasError('notValid')">
          {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.PLACE_OF_ORIGIN' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="permanentResidence" autocomplete="off" />
        <mat-error *ngIf="
              fatherInformationStep.get('permanentResidence').hasError('required')
            ">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.NATIONALITY' | translate
          }}</mat-label>
        <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationalityFather"
          (focusout)="checkNationality('father')" />
        <mat-autocomplete #autoNationalityFather="matAutocomplete" [displayWith]="displayCustomFormat">
          <mat-option *ngFor="let option of filteredNationalityOptionsFather | async" [value]="option">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="fatherInformationStep.get('nationality').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="fatherInformationStep.get('nationality').hasError('notValid')">
          {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.EDUCATION' | translate
          }}</mat-label>
        <mat-select formControlName="education" [compareWith]="compareObj">
          <mat-option *ngFor="let status of educationOptions" [value]="status">
            {{ status.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="fatherInformationStep.get('education').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.WORK_STATUS' | translate
          }}</mat-label>
        <mat-select formControlName="employment" [compareWith]="compareObj">
          <mat-option *ngFor="let status of employmentOptions" [value]="status">
            {{ status.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="fatherInformationStep.get('employment').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.HOUSING_STATUS' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="residentalStatus" autocomplete="off" />
        <mat-error *ngIf="fatherInformationStep.get('residentalStatus').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.HEALTH_STATUS' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="healthStatus" autocomplete="off" />
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <h3 class="subtitle">{{ 'FOSTER_CHILD.ADDICTIONS_TITLE' | translate
      }}</h3>
    <field-holder kind="1-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'FOSTER_CHILD.ADDICTIONS_TITLE' | translate
          }}</mat-label>
        <mat-select formControlName="addictionCodebook" multiple (selectionChange)="onAddictionChange('father')"
          [compareWith]="compareObj">
          <mat-option *ngFor="let addiction of addictionOptions" [value]="addiction">
            {{ addiction.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline" *ngIf="showFatherAddictionId1">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.ADDICTION_1' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="addictionId1" autocomplete="off" />
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="showFatherAddictionId2">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.ADDICTION_2' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="addictionId2" autocomplete="off" />
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="showFatherAddictionId3">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.ADDICTION_3' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="addictionId3" autocomplete="off" />
      </mat-form-field>
    </field-holder>

  </form-group>
</form>
<form [formGroup]="motherInformationStep" class="form">

  <form-group title="{{ 'NEW_FOSTER_CHILD.PARENT_INFORMATION.MOTHER' | translate }}" isLast="true">
    <div class="button-add-holder">
      <button mat-flat-button color="primary" type="button" (click)="importMotherData()">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.FIRST_NAME' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="firstName" autocomplete="off" />
        <mat-error *ngIf="motherInformationStep.get('firstName').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.LAST_NAME' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="lastName" autocomplete="off" />
        <mat-error *ngIf="motherInformationStep.get('lastName').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.JMBG' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="jmbg" maxlength="13"
          (input)="updateJMBGOnDateOfBirthChangeMother($event)" autocomplete="off" />
        <mat-error *ngIf="motherInformationStep.get('jmbg').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="motherInformationStep.get('jmbg').hasError('minLength') ||
                  motherInformationStep.get('jmbg').hasError('maxLength') ||
                  motherInformationStep.get('jmbg').hasError('pattern')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.JMBG_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.DATE_OF_BIRTH' | translate
          }}</mat-label>
        <input matInput [matDatepicker]="mother" [max]="currentDate"
          (dateChange)="updateJMBGOnDateOfBirthChangeMother($event)" formControlName="dateOfBirth" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="mother"></mat-datepicker-toggle>
        <mat-datepicker #mother></mat-datepicker>
        <mat-error *ngIf="motherInformationStep.get('dateOfBirth').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="motherInformationStep.get('dateOfBirth').hasError('incorrect')">
          {{ 'NEW_SUBJECT.PERSONAL_DETAILS.DATE_OF_BIRTH_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.PLACE_OF_BIRTH' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="placeOfBirth" autocomplete="off" />
        <mat-error *ngIf="motherInformationStep.get('placeOfBirth').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="3-cols">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NEW_FOSTER_CHILD.FORM.CITIZENSHIP' | translate }}
        </mat-label>

        <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenshipMother"
          (focusout)="checkCitizenship('mother')" />
        <mat-autocomplete #autoCitizenshipMother="matAutocomplete" [displayWith]="displayCustomFormat">
          <mat-option *ngFor="let option of filteredCitizenshipOptionsMother | async" [value]="option">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="motherInformationStep.get('citizenship').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="motherInformationStep.get('citizenship').hasError('notValid')">
          {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.PLACE_OF_ORIGIN' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="permanentResidence" autocomplete="off" />
        <mat-error *ngIf="
              motherInformationStep.get('permanentResidence').hasError('required')
            ">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.NATIONALITY' | translate
          }}</mat-label>
        <input type="text" matInput formControlName="nationality" [matAutocomplete]="autoNationalityMother"
          (focusout)="checkNationality('mother')" />
        <mat-autocomplete #autoNationalityMother="matAutocomplete" [displayWith]="displayCustomFormat">
          <mat-option *ngFor="let option of filteredNationalityOptionsMother | async" [value]="option">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="motherInformationStep.get('nationality').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="motherInformationStep.get('nationality').hasError('notValid')">
          {{ 'COLLECTIONS.CODEBOOK_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.EDUCATION' | translate
          }}</mat-label>
        <mat-select formControlName="education" [compareWith]="compareObj">
          <mat-option *ngFor="let status of educationOptions" [value]="status">
            {{ status.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="motherInformationStep.get('education').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.WORK_STATUS' | translate
          }}</mat-label>
        <mat-select formControlName="employment" [compareWith]="compareObj">
          <mat-option *ngFor="let status of employmentOptions" [value]="status">
            {{ status.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="motherInformationStep.get('employment').hasError('required')">
          {{ 'NEW_FOSTER_CHILD.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.HOUSING_STATUS' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="residentalStatus" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.HEALTH_STATUS' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="healthStatus" autocomplete="off" />

      </mat-form-field>
    </field-holder>
    <form-subgroup-divider></form-subgroup-divider>
    <h3 class="subtitle">{{ 'FOSTER_CHILD.ADDICTIONS_TITLE' | translate
      }}</h3>
    <field-holder kind="1-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{
            'FOSTER_CHILD.ADDICTIONS_TITLE' | translate
          }}</mat-label>
        <mat-select formControlName="addictionCodebook" multiple (selectionChange)="onAddictionChange('mother')"
          [compareWith]="compareObj">
          <mat-option *ngFor="let addiction of addictionOptions" [value]="addiction">
            {{ addiction.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </field-holder>

    <field-holder kind="3-cols">
      <mat-form-field appearance="outline" *ngIf="showMotherAddictionId1">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.ADDICTION_1' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="addictionId1" autocomplete="off" />
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="showMotherAddictionId2">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.ADDICTION_2' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="addictionId2" autocomplete="off" />
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="showMotherAddictionId3">
        <mat-label>{{
            'NEW_FOSTER_CHILD.FORM.ADDICTION_3' | translate
          }}</mat-label>
        <input matInput type="text" formControlName="addictionId3" autocomplete="off" />
      </mat-form-field>
    </field-holder>


  </form-group>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_FOSTER_CHILD.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-stroked-button matStepperNext color="primary"
      [disabled]="!motherInformationStep.valid || !fatherInformationStep.valid" class="form-footer__button"
      (click)="updateParentInformationStep()">
      {{ 'NEW_FOSTER_CHILD.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>