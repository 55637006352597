<container>
  <div id="pdfTable" #pdfTable>
    <page-intro-header title="{{ 'WORKERS.TITLE' | translate }}">
      <button mat-flat-button color="primary" routerLink="/new-user">
        {{ 'WORKERS.NEW_WORKER' | translate }}
      </button>
    </page-intro-header>
    <mat-card *ngIf="areLoaded" class="card" style="align-content: center;">
      <card-header title="{{ 'WORKERS.CARD_TITLE' | translate }}">
        <div class="card__aside">
          <mat-form-field appearance="outline" class="search centar-search" *ngIf="loggedInCsr===null || loggedInCsr===undefined">
            <mat-label>
              {{'GLOBAL.CENTER' | translate}}
            </mat-label>
            <input type="text" matInput [formControl]="subcenter" [matAutocomplete]="autoSubcenter" (input)="checkInput()" />
            <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete" [displayWith]="displayCustomFormat"
              (optionSelected)="reloadWorkers($event.option.value)">
              <mat-option *ngFor="let cent of filteredSubcenters | async" [value]="cent">
                {{ cent.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="outline" class="search">
            <mat-label>{{
              'GLOBAL.SEARCH' | translate
            }}</mat-label>
            <input matInput type="text" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
              placeholder="{{'GLOBAL.SEARCH' | translate}}">
          </mat-form-field>
        </div>
      </card-header>
      <mat-chip-list #chipList class="chip-list" [ngStyle]="{ 'display': searchItems.length > 0 ? 'block' : 'none' }">
        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable" [removable]="removable"
          (removed)="remove(item)">
          {{item.name}}
          <mat-icon matChipRemove *ngIf="removable">
            cancel
          </mat-icon>
        </mat-chip>
      </mat-chip-list>
      <div class="table-responsive-wrapper">
        <table mat-table id="myTable" class="workers__table" #myTable [dataSource]="page.content">
           <ng-container matColumnDef="center">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'WORKERS.CENTER' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.center}}
            </td>
          </ng-container>
           <ng-container matColumnDef="office">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'WORKERS.OFFICE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.office}}
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'WORKERS.NAME' | translate }}
            </th>
          <td mat-cell *matCellDef="let element">{{ element?.name }} </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'WORKERS.ROLE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.role }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'WORKERS.STATUS.TITLE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <status-label *ngIf="element?.enabled" status="1">
               {{ 'WORKERS.STATUS.ACTIVE' | translate }}
              </status-label>
              <status-label *ngIf="!element?.enabled" status="2">
               {{ 'WORKERS.STATUS.INACTIVE' | translate }}
              </status-label>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
          <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" (click)="onRowClicked(element)"
            class="subjects__row">
          </tr>
        </table>
      </div>

      <div class="paginator-wrapper">
        <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
            (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
        </app-custom-pagination>
      </div>

    </mat-card>
    <mat-card *ngIf="!areLoaded" class="card">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
  </div>
</container>
