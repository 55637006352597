/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/local-storage.service';


@Component({
    selector: 'page-intro-header',
    templateUrl: './page-intro-header.component.html',
    styleUrls: ['./page-intro-header.component.scss'],
})
export class PageIntroHeaderComponent implements OnInit {
    @Input() back: string | null = null;
    @Input() title: string;
    @Input() isAdmin: boolean;
    constructor(
        private location: Location,
        public router: Router,
        private localStorageService: LocalStorageService
    ) { }

    ngOnInit(): void { }

    backClicked() {
        if ((this.back === '/subjects' || this.back === '/legal_subjects') &&
            (this.localStorageService.get('case') === undefined || this.localStorageService.get('case') === null 
               )) {
            this.location.back();
        }
        else {
            this.router.navigate([this.back]);
        }
    }
}
