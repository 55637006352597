<container kind="default">
    <page-intro-header back="/dependents" title="{{ 'DEPENDENTS.T' | translate }}">
  </page-intro-header>
</container>
<container kind="default">
    <mat-card class="card">
        <definition-list>
            <definition-item *ngIf="dependentPerson?.ordinalNumber"
                key="{{ 'PROTEGE_DETAILS.R_BR' | translate }}">
                {{ dependentPerson.ordinalNumber}}</definition-item>
            <definition-item *ngIf="dependentPerson?.subjectId" key="{{ 'PROTEGE_DETAILS.ID' | translate }}">
                {{ dependentPerson.subjectId }}</definition-item>

            <definition-item *ngIf="dependentPerson?.firstName"
                key="{{ 'NEW_DEPENDENT.FORM.FIRST_NAME_MAIN' | translate }}">
                {{ dependentPerson.firstName }}</definition-item>

            <definition-item *ngIf="dependentPerson?.lastName"
                key="{{ 'NEW_DEPENDENT.FORM.LAST_NAME_MAIN' | translate }}">
                {{ dependentPerson.lastName }}</definition-item>

        </definition-list>
        <br>

        <h2 *ngIf="dependentPerson?.courtJudgementInfo.length > 0">{{
          'NEW_DEPENDENT.TABLE_ONE_TITLE' | translate
        }}
        </h2>
        <div class="records__table-wrapper table-responsive-wrapper"
            *ngIf="dependentPerson?.courtJudgementInfo.length > 0">
            <table mat-table [dataSource]="dependentPerson?.courtJudgementInfo" #mytable class="records__table">
                <ng-container matColumnDef="courtName">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.COURT_NAME' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.courtName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="courtNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.COURT_NUMBER' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.courtNumber }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="courtDate">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'NEW_DEPENDENT.TABLE.COURT_DATE' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.courtDate }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="courtValidityDate">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'NEW_DEPENDENT.TABLE.COURT_VALIDITY_DATE' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.courtValidityDate }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="dateOfVerdictDelivery">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.DATE_OF_VERDICT_DELIVERY' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.dateOfVerdictDelivery }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="dateOfCommencment">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.DATE_OF_COMMENCMENT' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.dateOfCommencment }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="tableOne"></tr>
                <tr mat-row *matRowDef="let row; columns: tableOne" class="problems-table__row"></tr>
            </table>
        </div>

        <h2 *ngIf="dependentPerson?.creditorInfo.length > 0">{{
          'NEW_DEPENDENT.TABLE_TWO_TITLE' | translate
        }}
        </h2>
        <div class="records__table-wrapper table-responsive-wrapper" *ngIf="dependentPerson?.creditorInfo.length > 0">
            <table mat-table [dataSource]="dependentPerson?.creditorInfo" #mytable class="records__table">
                <ng-container matColumnDef="firstAndLastName">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.FIRST_AND_LAST_NAME_MAIN' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.firstName + ' ' + element.lastName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="dateAndPlaceOfBirth">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.DATE_AND_PLACE_OF_BIRTH' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element?.dateOfBirth + ' ' + element?.placeOfBirth }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="jmbg">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.JMBG' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.jmbg }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.ADDRESS' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.address }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="legalRelation">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.LEGAL_RELATION_TWO' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.legalRelation }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="tableTwo"></tr>
                <tr mat-row *matRowDef="let row; columns: tableTwo" class="problems-table__row"></tr>
            </table>
        </div>

        <h2 *ngIf="dependentPerson?.representativeCreditorInfo.length > 0">{{
          'NEW_DEPENDENT.TABLE_THREE_TITLE' | translate
        }}
        </h2>

        <div class="records__table-wrapper table-responsive-wrapper"
            *ngIf="dependentPerson?.representativeCreditorInfo.length > 0">
            <table mat-table [dataSource]="dependentPerson?.representativeCreditorInfo" #mytable class="records__table">
                <ng-container matColumnDef="firstAndLastName">
                    <th mat-header-cell *matHeaderCellDef>
                        {{
                  'NEW_DEPENDENT.TABLE.FIRST_AND_LAST_NAME_THREE' | translate
                }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.firstName + ' ' + element.lastName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="dateAndPlaceOfBirth">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.DATE_AND_PLACE_OF_BIRTH' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.dateOfBirth + ' ' + element.placeOfBirth }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="jmbg">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.JMBG' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.jmbg }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.ADDRESS' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.address }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="legalRelation">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.LEGAL_RELATION_THREE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.legalRelation }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="tableThree"></tr>
                <tr mat-row *matRowDef="let row; columns: tableThree" class="problems-table__row"></tr>
            </table>
        </div>

        <h2 *ngIf="dependentPerson?.debtorInfo.length > 0">{{
          'NEW_DEPENDENT.TABLE_FOUR_TITLE' | translate
        }}
        </h2>

        <div class="records__table-wrapper table-responsive-wrapper" *ngIf="dependentPerson?.debtorInfo.length > 0">
            <table mat-table [dataSource]="dependentPerson?.debtorInfo" #mytable class="records__table">
                <ng-container matColumnDef="firstAndLastName">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.FIRST_AND_LAST_NAME_FOUR' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.firstName + ' ' + element.lastName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="dateAndPlaceOfBirth">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.DATE_AND_PLACE_OF_BIRTH' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.dateOfBirth + ' ' + element.placeOfBirth }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="jmbg">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.JMBG' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.jmbg }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.ADDRESS' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.address }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="legalRelation">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.LEGAL_RELATION_THREE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.legalRelation }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="tableFour"></tr>
                <tr mat-row *matRowDef="let row; columns: tableFour" class="problems-table__row"></tr>
            </table>
        </div>

        <h2 *ngIf="dependentPerson?.determinedSupportInfo.length > 0">{{
          'NEW_DEPENDENT.TABLE_FIVE_TITLE' | translate
        }}
        </h2>

        <div class="records__table-wrapper table-responsive-wrapper"
            *ngIf="dependentPerson?.determinedSupportInfo.length > 0">
            <table mat-table [dataSource]="dependentPerson?.determinedSupportInfo" #mytable class="records__table">
                <ng-container matColumnDef="supportMannerMoney">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_MANNER_TOOLTIP' | translate
                }}">
                        {{ 'NEW_DEPENDENT.TABLE.SUPPORT_MANNER_MONEY' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.supportMannerMoney }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="supportMannerOther">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_MANNER_TOOLTIP' | translate
                }}">
                        {{ 'NEW_DEPENDENT.TABLE.SUPPORT_MANNER_OTHER' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.supportMannerOther }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="supportAmmountFiscal">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_AMMOUNT_TOOLTIP' | translate
                }}">
                        {{ 'NEW_DEPENDENT.TABLE.SUPPORT_AMMOUNT_FISCAL' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.supportAmmountFiscal }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="supportAmmountPercentage">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_AMMOUNT_TOOLTIP' | translate
                }}">
                        {{
                  'NEW_DEPENDENT.TABLE.SUPPORT_AMMOUNT_PERCENTAGE' | translate
                }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.supportAmmountPercentage }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="supportDurationLimited">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_DURATION_TOOLTIP' | translate
                }}">
                        {{ 'NEW_DEPENDENT.TABLE.SUPPORT_DURATION_LIMITED' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.supportDurationLimited }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="supportDurationUnlimited">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{
                  'NEW_DEPENDENT.TABLE.SUPPORT_DURATION_TOOLTIP' | translate
                }}">
                        {{
                  'NEW_DEPENDENT.TABLE.SUPPORT_DURATION_UNLIMITED' | translate
                }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.supportDurationUnlimited }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="tableFive"></tr>
                <tr mat-row *matRowDef="let row; columns: tableFive" class="problems-table__row"></tr>
            </table>
        </div>

        <h2 *ngIf="dependentPerson?.terminationSupportInfo.length > 0">{{
          'NEW_DEPENDENT.TABLE_SIX_TITLE' | translate
        }}
        </h2>

        <div class="records__table-wrapper table-responsive-wrapper"
            *ngIf="dependentPerson?.terminationSupportInfo.length > 0">
            <table mat-table [dataSource]="dependentPerson?.terminationSupportInfo" #mytable class="records__table">
                <ng-container matColumnDef="courtName">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.COURT_NAME' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.courtName }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="courtNumber">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'NEW_DEPENDENT.TABLE.COURT_NUMBER' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.courtNumber }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="courtDate">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'NEW_DEPENDENT.TABLE.COURT_DATE' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element?.courtDate }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="dateOfVerdictTermination">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.DATE_OF_VERDICT_TERMINATION' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.dateOfVerdictTermination }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="reasonsForVerdictTermination">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'NEW_DEPENDENT.TABLE.REASONS_FOR_VERDICT_TERMINATON' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.reasonsForVerdictTermination }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="tableSix"></tr>
                <tr mat-row *matRowDef="let row; columns: tableSix" class="problems-table__row"></tr>
            </table>
        </div>


    </mat-card>
</container>
