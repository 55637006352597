<form [formGroup]="resetPasswordForm" class="modal-new-worker">
    <field-holder>
        <mat-form-field appearance="outline">
            <input matInput [type]="fieldTextType ? 'text' : 'password'" formControlName="password1" required />
            <mat-label>{{ 'USER_DETAILS.CREATE_USER.RESET_PASSWORD1' | translate }}</mat-label>
            <mat-error *ngIf="resetPasswordForm.get('password1').hasError('required')">
                {{'USER_DETAILS.CREATE_USER.RESET_PASSWORD1_ERROR' | translate}}
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.get('password1').hasError('pattern')">
                {{'USER_DETAILS.CREATE_USER.LETTER_ERROR' | translate}}
            </mat-error>
        </mat-form-field>
    </field-holder>
    <field-holder>
        <mat-form-field appearance="outline">
            <input matInput [type]="fieldTextType ? 'text' : 'password'" formControlName="password2" required
                [errorStateMatcher]="matcher" />
            <mat-label>{{ 'USER_DETAILS.CREATE_USER.RESET_PASSWORD2' | translate }}</mat-label>
            <mat-error *ngIf="resetPasswordForm.get('password2').hasError('required')">
                {{'USER_DETAILS.CREATE_USER.RESET_PASSWORD2_ERROR' | translate}}
            </mat-error>
            <mat-error
                *ngIf="resetPasswordForm.hasError('notSame') && !resetPasswordForm.get('password2').hasError('required')">
                {{'USER_DETAILS.CREATE_USER.RESET_PASSWORD2_ERROR_NOT_SAME' | translate}}
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.get('password2').hasError('pattern')">
                {{'USER_DETAILS.CREATE_USER.LETTER_ERROR' | translate}}
            </mat-error>
        </mat-form-field>
    </field-holder>
</form>
<form-footer>
    <button class="form-footer__button form-footer__button--secondary" (click)="closeDialog()" mat-flat-button
        color="secondary">
        {{ 'GLOBAL_ADMIN.CREATE_USER.CANCEL' | translate }}
    </button>
    <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary"
        (click)="resetPassword(resetPasswordForm)" type="submit" [disabled]="!resetPasswordForm.valid">
        {{ 'GLOBAL_ADMIN.CREATE_USER.SUBMIT' | translate }}
    </button>
</form-footer>