<!-- Forma za pravno lice -->
<form class="form" [formGroup]="manageCaseForm" (ngSubmit)="manageCase(manageCaseForm)">
  <form-group title="{{ 'ASSIGN_CASE.MANAGE_TITLE' | translate }}" isLast="true">
    <field-holder>
      <mat-radio-group aria-label="Select an option" formControlName="selectedOption" color="primary"
        class="form__radio-group">
        <mat-radio-button [value]="1">{{ 'ASSIGN_CASE.RETURN_CASE' | translate }}</mat-radio-button>
      </mat-radio-group>
    </field-holder>
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NEW_SUBJECT.OTHER.NOTE' | translate }}</mat-label>
        <textarea matInput type="text" formControlName="taskDescription" maxlength="250" required
          autocomplete="off"></textarea>
      </mat-form-field>
    </field-holder>
  </form-group>
  <form-footer>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="closeDialog()" type="button"
      class="form-footer__button form-footer__button--secondary">
      {{ 'DIALOG.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
      [disabled]="!manageCaseForm.valid">
      {{ 'DIALOG.CONFIRM' | translate }}
    </button>
  </form-footer>
</form>