/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackingListService } from 'src/services/tracking-list.service';

interface TrackingData {
  trackingData: any;
  action: 'cancel' | 'delete';
}

@Component({
  selector: 'delete-tracking',
  templateUrl: './delete-tracking.component.html',
  styleUrls: ['./delete-tracking.component.scss']
})
export class DeleteTrackingComponent implements OnInit {
  action: string;
  data: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public document: TrackingData,
    private trackingListService: TrackingListService
    ) { }

  ngOnInit(): void {
    this.data = this.document.trackingData;
    this.action = this.document.action;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' });
  }

  deleteTracking() {
    // todo: delete and check if it is deleted

      this.trackingListService.removeItem(this.data.id).subscribe((res: any) => {
      this.dialogRef.close({ event: 'DELETE' });
    },
      (err: string) => {
        console.log('Error occured' + err);
      }
    );
   // this.dialogRef.close({ event: this.action, data: { status: true, id: this.data.id } });
  }
}

/**
 * TODO @gorandivovic @jelenaninkovic @danijelgrabez @oljaandjelovski
 *
 * 1. Connect to API and remove document
 * 2. Depending on status return success state (true, false)
 * 3. Add proper type for Tracking shape
 */
