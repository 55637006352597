<form [formGroup]="adoptersStep" class="form">
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.CHOOSE_ADOPTER' | translate
          }}</mat-label>
      <mat-select formControlName="adopter" multiple (selectionChange)="adoptersChange()" required>
        <mat-option *ngFor="let adopter of adopters" [value]="adopter">
          {{ adopter }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="adoptersStep.get('adopter').hasError('required')">
        {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <br>
  <form-group title="{{ 'NEW_ADOPTED_CHILDREN.ADOPTERS.ADOPTIVE_MOTHER_TITLE' | translate }}" *ngIf="isAdoptiveMother">
    <div class="button-add-holder" *ngIf="isAdoptiveMother">
      <button mat-flat-button color="primary" type="button" (click)="importSubjectData(true, false)">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>
    <section formGroupName="adoptiveMother">
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="firstName" [required]="isAdoptiveMother" autocomplete="off" />
          <mat-error *ngIf="adoptersStep.get('adoptiveMother.firstName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="lastName" [required]="isAdoptiveMother" autocomplete="off" />
          <mat-error *ngIf="adoptersStep.get('adoptiveMother.lastName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="dateOfBirthAdoptiveMother" formControlName="dateOfBirth"
            [required]="isAdoptiveMother" autocomplete="off" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dateOfBirthAdoptiveMother"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthAdoptiveMother></mat-datepicker>
          <mat-error *ngIf="adoptersStep.get('adoptiveMother.dateOfBirth').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="residence" [required]="isAdoptiveMother" autocomplete="off" />
          <mat-error *ngIf="adoptersStep.get('adoptiveMother.residence').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate
          }}</mat-label>
          <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenshipAdoptiveMother"
            (focusout)="checkCitizenship('adoptiveMother')" />
          <mat-autocomplete #autoCitizenshipAdoptiveMother="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredCitizenshipOptionsAdoptiveMother | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="adoptersStep.get('adoptiveMother').get('citizenship').hasError('notValid')">
            {{ 'COLLECTIONS.CODEBOOK_ERROR'  | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-group title="{{ 'NEW_ADOPTED_CHILDREN.ADOPTERS.ADOPTIVE_FATHER_TITLE' | translate }}" *ngIf="isAdoptiveFather">
    <div class="button-add-holder">
      <button mat-flat-button color="primary" type="button" (click)="importSubjectData(false, true)">
        <mat-icon>person_add</mat-icon>
        {{ "GLOBAL.IMPORT_SUBJECT" | translate }}
      </button>
    </div>
    <br>
    <section formGroupName="adoptiveFather">
      <field-holder kind="2-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.FIRST_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="firstName" [required]="isAdoptiveFather" autocomplete="off" />
          <mat-error *ngIf="adoptersStep.get('adoptiveFather.firstName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.LAST_NAME' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="lastName" [required]="isAdoptiveFather" autocomplete="off" />
          <mat-error *ngIf="adoptersStep.get('adoptiveFather.lastName').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
      <field-holder kind="3-cols">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.DATE_OF_BIRTH' | translate
          }}</mat-label>
          <input matInput [matDatepicker]="dateOfBirthAdoptiveFather" formControlName="dateOfBirth"
            [required]="isAdoptiveFather" autocomplete="off" placeholder="{{ 'DATE_FORMAT' | translate }}" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dateOfBirthAdoptiveFather"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthAdoptiveFather></mat-datepicker>
          <mat-error *ngIf="adoptersStep.get('adoptiveFather.dateOfBirth').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.RESIDENCE' | translate
          }}</mat-label>
          <input matInput type="text" formControlName="residence" [required]="isAdoptiveFather" autocomplete="off" />
          <mat-error *ngIf="adoptersStep.get('adoptiveFather.residence').hasError('required')">
            {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{
            'NEW_ADOPTED_CHILDREN.FORM.CITIZENSHIP' | translate
          }}</mat-label>
          <input type="text" matInput formControlName="citizenship" [matAutocomplete]="autoCitizenshipAdoptiveFather"
            (focusout)="checkCitizenship('adoptiveFather')" />
          <mat-autocomplete #autoCitizenshipAdoptiveFather="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let option of filteredCitizenshipOptionsAdoptiveFather | async" [value]="option">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="adoptersStep.get('adoptiveFather').get('citizenship').hasError('notValid')">
            {{ 'COLLECTIONS.CODEBOOK_ERROR'  | translate }}
          </mat-error>
        </mat-form-field>
      </field-holder>
    </section>
  </form-group>
  <form-group title="{{ 'NEW_ADOPTED_CHILDREN.ADOPTERS.ADOPTS_TITLE' | translate }}" isLast="true">

    <section formGroupName="adoptsInfo">
      <mat-form-field appearance="outline">
        <mat-label>{{
          'NEW_ADOPTED_CHILDREN.FORM.RELATIONSHIP_RELATION' | translate
          }}</mat-label>
        <mat-select required formControlName="adoptersCodebook">
          <mat-option *ngFor="let adopter of adoptersOptions" [value]="adopter">
            {{ adopter.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="adoptersStep.get('adoptsInfo.adoptersCodebook').hasError('required')">
          {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </section>
  </form-group>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_ADOPTED_CHILDREN.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-flat-button matStepperNext color="primary" [disabled]="!adoptersStep.valid" class="form-footer__button"
      (click)="updateAdoptersStep()">
      {{ 'NEW_ADOPTED_CHILDREN.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>