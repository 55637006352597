<mat-card class="card__wrapper">
  <header class="card__image-wrapper">
    <figure class="card__figure">
      <img class="card__image" src="assets/images/serbiaFlag.png" alt="flag" />
    </figure>
  </header>
  <ul class="card__details">
    <li class="card__details-item card__name">
      {{ loggedIn.firstName }} {{ loggedIn.lastName }}
    </li>
    <li class="card__details-item">{{ loggedIn.center }}</li>
    <li class="card__details-item" *ngIf="!showProfileLink && !loggedIn.jmbg">
      {{ 'PROFILE.NO_JMBG' | translate }}
    </li>
  </ul>
  <div *ngIf="showLanguageSwitcher">
    <mat-form-field appearance="outline" class="card__select">
      <mat-label>{{ 'PROFILE.LANGUAGE' | translate }}</mat-label>
      <mat-select (selectionChange)="onLanguageChange($event)" [formControl]="currentLanguage">
        <mat-option *ngFor="let lang of languages" [value]="lang.id">
          {{ lang.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="roleChooserNeeded">
    <mat-form-field appearance="outline" class="card__select">
      <mat-label>{{ 'PROFILE.ROLE' | translate }}</mat-label>
      <mat-select (selectionChange)="onRoleChange($event)" [formControl]="primaryRole">
        <mat-option *ngFor="let role of roles" [value]="role">
          {{ role }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </ng-container>
  </div>
  <footer class="card__footer">
    <button color="primary" mat-flat-button routerLink="/profile" class="card__button" *ngIf="showProfileLink"
      (click)="toggleDropdown()">
      {{ 'PROFILE.MY_PROFILE' | translate }}
    </button>
     <button mat-stroked-button class="card__button" *ngIf="!showProfileLink"
      (click)="changePassword()">
      {{ 'PROFILE.CHANGE_PASSWORD' | translate }}
    </button>
    <button mat-stroked-button class="card__button" *ngIf="!showProfileLink"
      (click)="addJmbg()">
      <span *ngIf="!loggedIn.jmbg">{{ 'PROFILE.ADD_JMBG' | translate }}</span>
      <span *ngIf="loggedIn.jmbg">{{ 'PROFILE.CHANGE_JMBG' | translate }}</span>
    </button>
    <button mat-stroked-button (click)="logout()" class="card__button">
      {{ 'PROFILE.LOGOUT' | translate }}
    </button>
  </footer>
</mat-card>
