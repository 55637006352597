<form [formGroup]="otherInformationStep" class="form">
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_ADOPTED_CHILDREN.FORM.TYPE_OF_RELATIONSHIP' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="typeOfRelationship" required autocomplete="off" />
      <mat-error *ngIf="otherInformationStep.get('typeOfRelationship').hasError('required')">
        {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_ADOPTED_CHILDREN.FORM.DECISION_NUMBER' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="decisionNumber" required autocomplete="off" />
      <mat-error *ngIf="otherInformationStep.get('decisionNumber').hasError('required')">
        {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_ADOPTED_CHILDREN.FORM.DECISION_DATE' | translate
      }}</mat-label>
      <input matInput [matDatepicker]="dateOfDecision" [max]="currentDate" formControlName="decisionDate" required
        autocomplete="off" />
      <mat-datepicker-toggle matSuffix [for]="dateOfDecision"></mat-datepicker-toggle>
      <mat-datepicker #dateOfDecision></mat-datepicker>
      <mat-error *ngIf="otherInformationStep.get('decisionDate').hasError('required')">
        {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_ADOPTED_CHILDREN.FORM.DECISION_NUMBER_NEW_BIRTH_REGISTRATION' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="decisionNumberNewBirthRegistration" required autocomplete="off" />
      <mat-error *ngIf="otherInformationStep.get('decisionNumberNewBirthRegistration').hasError('required')">
        {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_ADOPTED_CHILDREN.FORM.DECISION_DATE_NEW_BIRTH_REGISTRATION' | translate
      }}</mat-label>
      <input matInput [matDatepicker]="dateOfDecisionNewBirthRegistration" [max]="currentDate"
        formControlName="decisionDateNewBirthRegistration" required autocomplete="off" />
      <mat-datepicker-toggle matSuffix [for]="dateOfDecisionNewBirthRegistration"></mat-datepicker-toggle>
      <mat-datepicker #dateOfDecisionNewBirthRegistration></mat-datepicker>
      <mat-error *ngIf="otherInformationStep.get('decisionDateNewBirthRegistration').hasError('required')">
        {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_ADOPTED_CHILDREN.FORM.ANNULMENT_OF_ADOPTION' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="annulmentOfAdoption" autocomplete="off" />
      <mat-error *ngIf="otherInformationStep.get('annulmentOfAdoption').hasError('required')">
        {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_ADOPTED_CHILDREN.FORM.HOLDER_OF_MAINTENANCE_OBLIGATION' | translate
      }}</mat-label>
      <input matInput type="text" formControlName="holderOfMaintenanceObligation" autocomplete="off" />
      <mat-error *ngIf="otherInformationStep.get('holderOfMaintenanceObligation').hasError('required')">
        {{ 'NEW_ADOPTED_CHILDREN.FORM.INPUT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NEW_ADOPTED_CHILDREN.FORM.NOTE' | translate
      }}</mat-label>
      <textarea matInput type="text" formControlName="note" autocomplete="off"></textarea>

    </mat-form-field>
  </field-holder>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NEW_ADOPTED_CHILDREN.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-flat-button color="primary" [disabled]="!otherInformationStep.valid" class="form-footer__button"
      (click)="updateOtherInformationStep()">
      {{ 'NEW_ADOPTED_CHILDREN.FINISH' | translate }}
    </button>
  </form-footer>
</form>
