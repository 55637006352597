<container>
  <page-intro-header title="{{ 'NEW_FOSTER_PARENT.TITLE' | translate }}" back="/foster-parent">
  </page-intro-header>
  <mat-card class="card new-foster-parent">
    <mat-vertical-stepper  (selectionChange)="onStepChange($event)" linear="true" #stepper>
      <mat-step [stepControl]="personalInformationStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_PARENT.PERSONAL_INFORMATION.TITLE' | translate
        }}</ng-template>
        <new-foster-parent-personal-information-step [personalInformationStep]="personalInformationStep"
          (personalInformationStepEmitter)="
            updatePersonalInformationStep($event)
          " #personalChild></new-foster-parent-personal-information-step>
      </mat-step>
      <mat-step [stepControl]="familyInformationStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_PARENT.FAMILY_INFORMATION.TITLE' | translate
        }}</ng-template>
        <new-foster-parent-family-information-step [familyInformationStep]="familyInformationStep"
          (familyInformationStepEmitter)="updateFamilyInformationStep($event)">
        </new-foster-parent-family-information-step>
      </mat-step>
      <mat-step [stepControl]="suitabilityStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_PARENT.SUITABILITY.TITLE' | translate
        }}</ng-template>
        <new-foster-parent-suitability-step [suitabilityStep]="suitabilityStep"
          (suitabilityStepEmitter)="updateSuitabilityStep($event)"></new-foster-parent-suitability-step>
      </mat-step>

      <mat-step [stepControl]="centerStep">
        <ng-template matStepLabel>{{
          'NEW_FOSTER_PARENT.CENTER.TITLE' | translate
        }}</ng-template>
        <new-foster-parent-center-step   [centerStep]="centerStep" [suitabilityStep]="suitabilityStep"  [familyInformationStep]="familyInformationStep" [personalInformationStep]="personalInformationStep"
          (centerStepEmitter)="updateCenterStep($event)"></new-foster-parent-center-step>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card>
</container>