<page-intro-header title="{{ 'MAIN_NAVIGATION.IMPORT_DECISIONS' | translate }}">
</page-intro-header>

<mat-card class="card-import">
  <div>
    <form class="drop-zone drop-zone-slide" [formGroup]="importDataForm" file-drop (files)="dropSuccess($event)">
      <div>
        <mat-form-field appearance="outline" class="search centar-search">
          <mat-label>
            {{'GLOBAL.CENTER' | translate}}
          </mat-label>
          <input type="text" matInput formControlName="subcenter" [matAutocomplete]="autoSubcenter"
            (click)="resetFields()" required />
          <mat-autocomplete panelWidth="auto" #autoSubcenter="matAutocomplete" [displayWith]="displayCustomFormat">
            <mat-option *ngFor="let cent of filteredSubcenters | async" [value]="cent">
              {{ cent.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
        <input id="file" type="file" formControlName="files" (change)="submit($event)">
        <label for="file"><strong>{{ 'IMPORT_DATA.CHOOSE_FILE' | translate }}</strong>
          <span class="dragndrop"> {{ 'IMPORT_DATA.DRAG_AND_DROP' | translate }}</span>.
        </label>
      </div>
      <br>
      <div>
        <label *ngIf="uploadStatus">{{uploadMessage | translate }}</label>
      </div>
    </form>
    <form-footer>

      <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary"
        (click)="submitImport()" type="submit" [disabled]="!importDataForm.valid || fileToUpload === null || uploadStatus === 'IMPORTING'"
        #submitButton>
        {{ 'IMPORT_DATA.IMPORT' | translate }}
      </button>
      <button class="form-footer__button form-footer__button--secondary" mat-flat-button color="primary"
        (click)="stopImport()" type="submit" [disabled]="uploadStatus != 'IMPORTING'" #submitButton>
        {{ 'IMPORT_DATA.STOP_IMPORT' | translate }}
      </button>
    
    </form-footer>
  </div>
  <div class="drop-zone"><textarea rows="11" cols="70" readonly style="resize: none;"
      [(ngModel)]="traceImportProcess"></textarea></div>
      
  </mat-card>
<mat-chip-list #chipList class="chip-list" >
      </mat-chip-list>
      <div class="table-responsive-wrapper">
        <table mat-table id="myTable" class="subjects__table" #myTable [dataSource]="socialCasesImport">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>{{ 'ID' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ element?.id }}
            </td>
          </ng-container>
          <ng-container matColumnDef="center">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'IMPORT_DATA.IMPORTS.CENTER' | translate }}</th>
          <td mat-cell *matCellDef="let element">
              {{ element?.center?.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'IMPORT_DATA.IMPORTS.BEGIN' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.startDate }}</td>
          </ng-container>
          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'IMPORT_DATA.IMPORTS.END' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.endDate }}</td>
          </ng-container>
          <ng-container matColumnDef="nameCSV">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'IMPORT_DATA.IMPORTS.CSV_NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element?.nameCSV }}</td>
          </ng-container>
          <ng-container matColumnDef="decisionCount">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'IMPORT_DATA.IMPORTS.DECISION_COUNT' | translate }}
            </th>
           <td mat-cell *matCellDef="let element">{{ element?.subjectCount }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'IMPORT_DATA.IMPORTS.STATUS' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ (element?.status=='FINISHED'?'IMPORT_DATA.IMPORTS.SUCCESS':(element?.status=='STOPPED'?'IMPORT_DATA.IMPORTS.STOP':'IMPORT_DATA.IMPORTS.FAIL')) | translate  }}
            </td>
          </ng-container>
         
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="table__cell table__cell--align-right">
              <button mat-icon-button class="button-square table__row-button" color="primary"
                matTooltip="{{ 'SUBJECTS.MORE_TOOLTIP' | translate }}" [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()" >
                <mat-icon class="button-square__icon">more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="filter-menu">
                <button mat-menu-item (click)="handleOpenDialog(element?.subjectsNotImported,element?.id)"
                 [disabled]="element?.subjectsNotImported===null||element?.subjectsNotImported?.length===0" >
                  <mat-icon>arrow_downward</mat-icon>
                  {{ 'IMPORT_DECISIONS.NOT_IMPORTED_SUBJECTS' | translate }}
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
          <tr mat-row *matRowDef="let element; let row; columns: displayedColumns" 
          (click)="onRowClicked(element)"  class="subjects__row">
          </tr>
        </table>
      </div>
