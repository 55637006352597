import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ageGroupData } from 'src/app/utils/ageGroupData';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { CodebookService } from 'src/services/codebook.service';
import { StatisticsService } from 'src/services/statistics.service';
import { UserService } from 'src/services/user.service';
enum AutocompleteFilterEnum {
   CENTERS = 'centers',
}

interface SelectOptions {
   value: any;
   viewValue: string;
}
@Component({
   selector: 'app-termination-reason-stats',
   templateUrl: './termination-reason-stats.component.html',
   styleUrls: ['./termination-reason-stats.component.scss'],
})
export class TerminationReasonStatsComponent implements OnInit {
   genderOptions = [];
   shouldShowAgeGroupRange = false;
   ageGroup = ageGroupData;
   centers = [];
   filteredAllCenters: Observable<any>;
   loggedIn: any;
   isMainCenterBelgrade = false;
   btnDisabled: boolean;
   isUserDrugostepeni = false;
   reasonStatsForm: FormGroup;
   terminationReasonList = [];
   opstiReasonList = [];
   nspReasonList = [];
   dsReasonList = [];
   psReasonList = [];
   starReasonList = [];

   typeOfReason: SelectOptions[] = [
      { value: 'OPSTI', viewValue: 'Прекид додатка за помоћ и негу, посебне новчане помоћи или оспособљавања за рад' },
      { value: 'NSP', viewValue: 'Прекид новчано социјалне помоћи' },
      { value: 'DS', viewValue: 'Прекид домског смештаја' },
      { value: 'PS', viewValue: 'Прекид хранитељства и породичног смештаја' },
      { value: 'STAR', viewValue: 'Прекид старатељске заштите' },
   ];

   typeOfStatistics: SelectOptions[] = [
      { value: false, viewValue: 'Решења' },
      { value: true, viewValue: 'Физичка лица' },
   ];
   constructor(
      private formBuilder: FormBuilder,
      private codebookService: CodebookService,
      private statisticsService: StatisticsService,
      private localStorage: LocalStorageService,
      private userService: UserService,
      private translate: TranslateService
   ) {
      this.loggedIn = JSON.parse(this.localStorage.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      if (this.isMainCenterBelgrade) {
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.reasonStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      if (this.isUserDrugostepeni) {
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.centers = result;
            this.filteredAllCenters = this.reasonStatsForm.controls.center.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value?.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.CENTERS) : this.centers.slice()))
            );
         });
      }
      this.statisticsService.getBtnStatus().subscribe(status => {
         this.btnDisabled = status;
      });
      this.btnDisabled = false;
   }

   ngOnInit(): void {
      this.codebookService.getGenderCodebook().subscribe(result => {
         this.genderOptions = result;
      });
      this.reasonStatsForm = this.formBuilder.group({
         center: [''],
         ageGroup: ['', []],
         gender: ['', []],
         selectedYear: ['', [Validators.required]],
         customAgeGroupTo: [''],
         customAgeGroupFrom: [''],
         type: [null],
         terminationReason: [null],
         onlyOne:[this.typeOfStatistics[1],[Validators.required]]
      });
      this.dynamicFormUpdate();
   }

   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();
      switch (what) {
         case AutocompleteFilterEnum.CENTERS:
            return sortByFirstLetter(
               this.centers.filter(option => option.name.toLowerCase().includes(filterValue)),
               filterValue,
               'name'
            );
         default:
            break;
      }
   }

   dynamicFormUpdate() {
      this.reasonStatsForm.get('ageGroup').valueChanges.subscribe(ageArray => {
         if (ageArray.some(age => age.id === 6)) {
            this.shouldShowAgeGroupRange = true;
            this.reasonStatsForm.get('customAgeGroupFrom').setValidators(Validators.required);
            this.reasonStatsForm.get('customAgeGroupTo').setValidators(Validators.required);
         } else {
            this.shouldShowAgeGroupRange = false;
            this.reasonStatsForm.get('customAgeGroupFrom').clearValidators();
            this.reasonStatsForm.get('customAgeGroupFrom').setValue(null);
            this.reasonStatsForm.get('customAgeGroupTo').clearValidators();
            this.reasonStatsForm.get('customAgeGroupTo').setValue(null);
         }
      });
   }
   checkCustomYears() {
      if (this.reasonStatsForm.controls.customAgeGroupTo.touched && this.reasonStatsForm.controls.customAgeGroupFrom.touched) {
         if (
            this.reasonStatsForm.value.customAgeGroupTo !== null &&
            this.reasonStatsForm.value.customAgeGroupTo !== '' &&
            this.reasonStatsForm.value.customAgeGroupFrom !== null &&
            this.reasonStatsForm.value.customAgeGroupFrom !== ''
         ) {
            if (this.reasonStatsForm.value.customAgeGroupTo < this.reasonStatsForm.value.customAgeGroupFrom) {
               this.reasonStatsForm.controls.customAgeGroupTo.setErrors({
                  incorect: true,
               });
            } else {
               this.reasonStatsForm.controls.customAgeGroupTo.setErrors(null);
            }
         }
      }
      if (this.reasonStatsForm.value.customAgeGroupTo !== null && this.reasonStatsForm.value.customAgeGroupTo !== '') {
         if (!this.reasonStatsForm.controls.customAgeGroupTo.hasError('incorect') && Number(this.reasonStatsForm.value.customAgeGroupTo) > 150) {
            this.reasonStatsForm.controls.customAgeGroupTo.setErrors({
               outOfRange: true,
            });
         } else if (!this.reasonStatsForm.controls.customAgeGroupTo.hasError('incorect')) {
            this.reasonStatsForm.controls.customAgeGroupTo.setErrors(null);
         }
      }
      if (this.reasonStatsForm.value.customAgeGroupFrom !== null && this.reasonStatsForm.value.customAgeGroupFrom !== '') {
         if (Number(this.reasonStatsForm.value.customAgeGroupFrom) > 150) {
            this.reasonStatsForm.controls.customAgeGroupFrom.setErrors({
               outOfRange: true,
            });
         } else {
            this.reasonStatsForm.controls.customAgeGroupFrom.setErrors(null);
         }
      }
   }
   autoSelectCenter() {
      autoSelect(this.centers, 'center', 'name', this.reasonStatsForm);
   }

   changeType(type: SelectOptions) {
      if (type.value == 'OPSTI') {
         if (this.opstiReasonList.length == 0) {
            this.codebookService.getTerminationReasonByType(type.value).subscribe(res => {
               this.opstiReasonList = res;
               this.terminationReasonList = this.opstiReasonList;
            });
         } else {
            this.terminationReasonList = this.opstiReasonList;
         }
      } else if (type.value == 'NSP') {
         if (this.nspReasonList.length == 0) {
            this.codebookService.getTerminationReasonByType(type.value).subscribe(res => {
               this.nspReasonList = res;
               this.terminationReasonList = this.nspReasonList;
            });
         } else {
            this.terminationReasonList = this.nspReasonList;
         }
      } else if (type.value == 'DS') {
         if (this.dsReasonList.length == 0) {
            this.codebookService.getTerminationReasonByType(type.value).subscribe(res => {
               this.dsReasonList = res;
               this.terminationReasonList = this.dsReasonList;
            });
         } else {
            this.terminationReasonList = this.dsReasonList;
         }
      } else if (type.value == 'PS') {
         if (this.psReasonList.length == 0) {
            this.codebookService.getTerminationReasonByType(type.value).subscribe(res => {
               this.psReasonList = res;
               this.terminationReasonList = this.psReasonList;
            });
         } else {
            this.terminationReasonList = this.psReasonList;
         }
      } else if (type.value == 'STAR') {
         if (this.psReasonList.length == 0) {
            this.codebookService.getTerminationReasonByType(type.value).subscribe(res => {
               this.starReasonList = res;
               this.terminationReasonList = this.starReasonList;
            });
         } else {
            this.terminationReasonList = this.starReasonList;
         }
      }
      this.reasonStatsForm.controls.terminationReason.setValue(null);

   }

   sendRequest() {
      if (!this.reasonStatsForm.valid) {
         return;
      }
      var form = this.reasonStatsForm;
      let ageGroups: { id: number; name: string; from: number; to: number }[] = [];

      ageGroups = form.value.ageGroup === null || form.value.ageGroup.length === 0 ? null : form.value.ageGroup;
      const gender = form.value.gender === null || form.value.gender === undefined || form.value.gender.length === 0 ? null : form.value.gender;
      const customAgeFrom = form.value.customAgeGroupFrom === null || form.value.customAgeGroupFrom.length === 0 ? null : form.value.customAgeGroupFrom;
      const customAgeTo = form.value.customAgeGroupTo === null || form.value.customAgeGroupTo.length === 0 ? null : form.value.customAgeGroupTo;
      if (ageGroups != null) {
         const indexToUpdate = ageGroups.findIndex(group => group.id === 6);
         if (indexToUpdate != -1) {
            const newAgeGroup = {
               id: 6,
               name: 'Слободан унос опсега година (' + customAgeFrom + ' - ' + customAgeTo + ')',
               from: customAgeFrom,
               to: customAgeTo,
            };
            ageGroups[indexToUpdate] = newAgeGroup;
         }
      }
      let csrId = this.loggedIn.csrId;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         csrId = form.value.center.id;
      }

      const submitDto = {
         csrId,
         year: form.value.selectedYear,
         ageGroups,
         gender,
         terminationReason: form.value.terminationReason,
         onlyOne: form.value.onlyOne.value
      };

      let filename = '';
      this.translate.get('STATISTICS.REASON_TERMINATION_STATS.TITLE').subscribe((res: string) => {
         filename = res;
      });
      this.statisticsService.getTerminationReasonStatistics(submitDto, filename);
   }

   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }
   compareTypeStatistics(object1: any, object2: any) {
      return object1 && object2 && object1.value === object2.value ;
   }
}
