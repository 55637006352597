/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EventEnum } from 'src/app/entrance/types';
import { LocalStorageService } from 'src/app/local-storage.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { Subject } from 'src/types';
import { SubjectsComponent } from '../../subjects.component';
import { FamilyDossierComponent } from './family-dossier/family-dossier.component';

interface FamilyMember {
   firstName: string;
   lastName: string;
   familyDossier: string;
   relationship?: any;
   subjectIdentity: any;
}

@Component({
   selector: 'subject-family',
   templateUrl: './subject-family.component.html',
   styleUrls: ['./subject-family.component.scss'],
})
export class SubjectFamilyComponent implements OnInit {
   @Input() subject: any;

   members: FamilyMember[] = [];
   loggedIn: any;
   isMainCenterBelgrade = false;
   isUserDrugostepeni = false;
   addMemberText = '';
   addMemberTextError = '';
   localStorageCenterId: any;
   isAdmin: boolean;

   @Input() isModificationDisabled: boolean;
   @Input() subjectId: any;
   @Input() jmbp: any;
   @Input() familyDossierId: any;
   @Output() emitEditUser = new EventEmitter();

   passedSubjectId: any;
   passedFamDossierId: any;

   constructor(
      private subjectService: SubjectsService,
      public dialog: MatDialog,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private toastr: ToastrImplService,
      private userService: UserService
   ) {
      this.isAdmin = this.userService.isGlobalAdmin();
      this.localStorageCenterId = this.localStorageService.get('localStorageCenterId');

      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();

      this.translate.get('SUBJECT_FAMILY.TOOLTIP_EDIT_DISABLED').subscribe((resp: string) => {
         this.addMemberText = resp;
      });

      this.translate.get('SUBJECT_FAMILY.TOOLTIP_EDIT_ENABLED').subscribe((resp: string) => {
         this.addMemberTextError = resp;
      });
   }

   ngOnInit(): void {}

   ngOnChanges(changes: any) {
      if (changes.familyDossierId !== undefined && changes.familyDossierId !== null) {
         if (changes.familyDossierId.currentValue !== null) {
            if (changes.familyDossierId && changes.familyDossierId.currentValue) {
               this.passedSubjectId = this.subjectId;
               this.passedFamDossierId = this.familyDossierId;
               this.doFilter();
            }
         }
      }
   }

   doFilter() {
      let members = null;
      if (this.familyDossierId !== null) {
         if (this.isMainCenterBelgrade || this.isUserDrugostepeni || this.isAdmin) {
            members = this.subjectService.getFamilyMembersDossierDto(this.passedSubjectId, this.familyDossierId, this.localStorageCenterId);
         } else {
            members = this.subjectService.getFamilyMembersDossierDto(this.passedSubjectId, this.familyDossierId, this.loggedIn?.csrId);
         }
         members.subscribe(resultMembers => {
            this.members = resultMembers;
         });
      }
   }

   // Dodavanje novog clana porodicnog dosijea
   addMember() {
      const dialogRef = this.dialog.open(SubjectsComponent, {
         panelClass: 'overlay-panel',
         width: '1200px',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            const obj = JSON.parse(JSON.stringify(result));
            const {
               data: { firstName, lastName, registrationId, subjectIdentity, jmbp, familyDossierId, familyDossier, dateOfBirth, jmbg, yearOfBirth, foreigner },
            } = obj;
            if (familyDossierId === null || familyDossier !== 'CARRIER') {
               const fdId = familyDossierId;
               const memberData = {
                  firstName,
                  lastName,
                  registrationId,
                  subjectIdentity,
                  familyDossierId: this.familyDossierId,
                  jmbp,
                  dateOfBirth,
                  jmbg,
                  yearOfBirth,
                  foreigner,
               };
               if (fdId !== null) {
                  this.toastr.warning('SUBJECT_FAMILY.ADD_FROM_ANOTHER_DOSSIER_WARNING');
               }
               if (memberData?.subjectIdentity?.subjectId !== null) {
                  this.generateFamilyDossierDialog(memberData);
               } else {
                  this.toastr.error('SNACKBAR.FAMILY_MEMBER_NOT_REGISTERED');
               }
            } else {
               this.toastr.error('SUBJECT_FAMILY.ADD_FROM_ANOTHER_DOSSIER_ERROR');
            }
         }
      });
   }

   generateFamilyDossierDialog(data: Partial<Subject>) {
      const familyData = {
         origin: 'familyDossier',
         data,
         subject: this.subject,
      };
      const dialogRef = this.dialog.open(FamilyDossierComponent, {
         panelClass: 'overlay-panel',
         disableClose: true,
         width: '480px',
         data: familyData,
      });

      dialogRef.afterClosed().subscribe(result => {
         const res = result;
         if (res.event === EventEnum.SUBMIT) {
            this.editedUser();
            this.toastr.success('SNACKBAR.NEW_MEMBER_FAMILY_DOSSIER');
         }
      });
   }

   editedUser() {
      this.emitEditUser.emit();
      this.doFilter();
   }
}

/**
 * TODO @gorandivovic @jelenaninkovic
 *
 * 1. Pass tooltip text from translations
 */
