<div class="appWrapper">
  <nav class="pageNav">
    <ul class="pageNav__list">
      <li class="pageNav__item">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.HOME_TOOLTIP' | translate }}" routerLink="/"
          class="pageNav__link" routerLinkActive="pageNav__link--active" (click)="sidenav.close()"
          [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>home</mat-icon>
          <span class="label">{{ 'MAIN_NAVIGATION.HOME' | translate }}</span>
        </button>
      </li>
      <li class="pageNav__item"
        *ngIf="(isUserWorker || isLegalCounselor) && !isMainCenterBelgrade">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.CASES_TOOLTIP' | translate }}"
          (click)="sidenav.toggle() && hideNotifications('cases')" routerLink="/cases" class="pageNav__link"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon [matBadge]="cases.length > 0 ? cases.length : null" matBadgePosition="after" matBadgeColor="accent"
            matBadgeSize="small">inbox</mat-icon>
          <span class="label">{{ 'MAIN_NAVIGATION.CASES' | translate }}</span>
        </button>
      </li>
      <li class="pageNav__item" *ngIf="isUserEntrancer && !isMainCenterBelgrade">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.SUBMISSION_TOOLTIP' | translate }}"
          (click)="sidenav.toggle() && hideNotifications('submissions')" routerLink="/submissions" class="pageNav__link"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon [matBadge]="submissions.length > 0 ? submissions.length : null" matBadgePosition="after"
            matBadgeColor="accent" matBadgeSize="small">inbox</mat-icon>
          <span class="label">{{ 'MAIN_NAVIGATION.CASES' | translate }}</span>
        </button>
      </li>
      <li class="pageNav__item" *ngIf="isUserAssigner && !isMainCenterBelgrade">
        <button mat-button color="basic" matTooltip="{{
            'MAIN_NAVIGATION.SCHEDULE_CASES_TOOLTIP' | translate
          }}" (click)="sidenav.close() && hideNotifications('unassignedCases')" routerLink="/schedule-cases"
          class="pageNav__link" routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon [matBadge]="
              unassignedCases.length > 0 ? unassignedCases.length : null
            " matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small">swap_horiz</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.SCHEDULE_CASES' | translate
          }}</span>
        </button>
      </li>
      <li class="pageNav__item" *ngIf="isUserSupervisor">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.SUPERVISION_TOOLTIP' | translate }}"
          (click)="sidenav.close() && hideNotifications('supervision')" routerLink="/supervision" class="pageNav__link"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon [matBadge]="supervision.length > 0 ? supervision.length : null" matBadgePosition="after"
            matBadgeColor="accent" matBadgeSize="small">supervisor_account</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.SUPERVISION' | translate
          }}</span>
        </button>
      </li>
      <li class="pageNav__item" *ngIf=" !isGlobalAdmin && !isCenterAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.SUBJECTS_TOOLTIP' | translate }}"
          class="pageNav__link" [matMenuTriggerFor]="subjectsMenu">
          <mat-icon>portrait</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.SUBJECTS' | translate
          }}</span>
        </button>
        <mat-menu #subjectsMenu="matMenu" xPosition="after" class="pageNav__dropdown">
          <button mat-menu-item color="basic" (click)="onSubjectsClick()" routerLink="/subjects">
            {{ 'MAIN_NAVIGATION.NON_LEGAL_SUBJECTS' | translate }}
          </button>
          <button mat-menu-item color="basic" (click)="sidenav.close()" routerLink="/legal_subjects">
            {{ 'MAIN_NAVIGATION.LEGAL_SUBJECTS' | translate }}
          </button>
        </mat-menu>
      </li>
      <li class="pageNav__item" *ngIf="!isUserEntrancer && !isUserDrugostepeni && !isGlobalAdmin && !isCenterAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.COLLECTIONS_TOOLTIP' | translate }}"
          (click)="sidenav.close()" routerLink="/collections" class="pageNav__link"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>grid_view</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.COLLECTIONS' | translate
          }}</span>
        </button>
      </li>
      <li class="pageNav__item" *ngIf="!isGlobalAdmin && !isCenterAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.STATISTICS_TOOLTIP' | translate }}"
          (click)="sidenav.close()" routerLink="/statistics" class="pageNav__link"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>insights</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.STATISTICS' | translate
          }}</span>
        </button>
      </li>
      <li class="pageNav__item"
        *ngIf="!isLegalCounselor && !isMainCenterBelgrade && !isUserDrugostepeni && !isGlobalAdmin && !isCenterAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.OTHER_TOOLTIP' | translate }}"
          class="pageNav__link" [matMenuTriggerFor]="otherMenu">
          <mat-icon>more_horiz</mat-icon>
          <span class="label">{{ 'MAIN_NAVIGATION.OTHER' | translate }}</span>
        </button>
        <mat-menu #otherMenu="matMenu" xPosition="after" class="pageNav__dropdown">
          <button mat-menu-item color="basic" (click)="sidenav.close()" routerLink="/nspRequest">
            {{ 'MAIN_NAVIGATION.NSP_REQUEST' | translate }}
          </button>
          <button mat-menu-item color="basic" (click)="sidenav.close()" routerLink="/entrancePapers">
            {{ 'MAIN_NAVIGATION.ENTRANCE' | translate }}
          </button>
        </mat-menu>
      </li>
      <li class="pageNav__item" *ngIf="isGlobalAdmin || isCenterAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.WORKERS_TOOLTIP' | translate }}"
          (click)="sidenav.close()" routerLink="/users" class="pageNav__link" routerLinkActive="pageNav__link--active"
          [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>people</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.WORKERS' | translate
          }}</span>
        </button>
      </li>
       <li class="pageNav__item" *ngIf="isGlobalAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.LEGAL_SUBJECTS' | translate }}"
          (click)="sidenav.close()" routerLink="/legal_subjects" class="pageNav__link"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>portrait</mat-icon>
          <span class="label">{{
            'LEGAL_SUBJECTS.CARD_TITLE' | translate
          }}</span>
        </button>
      </li>
      <li class="pageNav__item" *ngIf="isGlobalAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.GLOBAL_ADMIN_TOOLTIP' | translate }}"
          (click)="sidenav.close()" routerLink="/global-admin" class="pageNav__link"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>settings</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.GLOBAL_ADMIN' | translate
          }}</span>
        </button>
      </li>
      <li class="pageNav__item" *ngIf="isGlobalAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.IMPORT_DATA' | translate }}"
          class="pageNav__link" (click)="sidenav.close()" routerLink="/import-data"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>person_add</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.IMPORT_DATA' | translate
          }}</span>
        </button>
      </li>
            <li class="pageNav__item" *ngIf="isGlobalAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.IMPORT_COLLECTIONS' | translate }}"
          class="pageNav__link" (click)="sidenav.close()" routerLink="/import-collections"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>grid_view</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.IMPORT_COLLECTIONS' | translate
          }}</span>
        </button>
      </li>
       <li class="pageNav__item" *ngIf="isGlobalAdmin">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.IMPORT_DECISIONS' | translate }}"
          class="pageNav__link" (click)="sidenav.close()" routerLink="/import-decisions"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>receipt</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.IMPORT_DECISIONS' | translate
          }}</span>
        </button>
      </li>
      <li class="pageNav__item" *ngIf="isUserDrugostepeni">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.SECOND_DEGREE_CASES' | translate }}"
          class="pageNav__link" (click)="sidenav.close()" routerLink="/cases-by-classification"
          routerLinkActive="pageNav__link--active" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon>receipt</mat-icon>
          <span class="label">{{
            'MAIN_NAVIGATION.SECOND_DEGREE_CASES' | translate
          }}</span>
        </button>
      </li>
      <li class="pageNav__item--second_last">
        <button mat-button color="basic" matTooltip="{{ 'MAIN_NAVIGATION.SUPPORT' | translate }}"
          class="pageNav__link" (click)="getSupport()">
          <mat-icon>contact_support</mat-icon>
          <span class="label">{{ 'MAIN_NAVIGATION.SUPPORT' | translate }}</span>
        </button>
      </li>
    </ul>
  </nav>
  <mat-sidenav-container>
    <mat-sidenav #sidenav class="subjectsMatSidenav" mode="side" position="start" (closed)="onClose()">
      <app-cases *ngIf="!isUserEntrancer && !isUserDrugostepeni" [events]="eventSidenav.asObservable()"></app-cases>
      <app-submissions *ngIf="isUserEntrancer"></app-submissions>
    </mat-sidenav>
    <mat-sidenav-content>
      <nav class="pageNavSecondary">
        <ul class="pageNavSecondary__list">
          <li class="pageNavSecondary__item">
            <button *ngIf="!isUserDrugostepeni" mat-icon-button color="basic" matTooltip="{{
                'SECONDARY_NAVIGATION.NOTIFICATIONS_TOOLTIP' | translate
              }}" (click)="handleNotificationDropdown()" class="pageNavSecondary__link">
              <mat-icon [matBadge]="
                  notifications.length > 0 ? notifications.length : null
                " matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small">notifications</mat-icon>
            </button>
          </li>
          <li class="pageNavSecondary__item">
            <button mat-icon-button color="basic" matTooltip="{{
                'SECONDARY_NAVIGATION.PROFILE_TOOLTIP' | translate
              }}" class="pageNavSecondary__link" (click)="handleProfileDropdown()">
              <mat-icon>person</mat-icon>
            </button>
            <profile-dropdown (toggleDropdownHandler)="handleProfileDropdown()" *ngIf="isProfileDropdownOpen">
            </profile-dropdown>
          </li>
        </ul>
        <notifications-dropdown (toggleDropdownHandler)="handleNotificationDropdown()"
          *ngIf="isNotificationDropdownOpen"></notifications-dropdown>
      </nav>
      <ng-content></ng-content>
      <sidebar-widget></sidebar-widget>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
