/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject } from 'src/models/subject.model';
import { AuthenticationService } from 'src/services/authentication.service';
import { CodebookService } from 'src/services/codebook.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { SharedCaseService } from 'src/services/shared-case.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalEventEnum } from 'src/types';
import { PrintComponent } from '../components/print/print.component';
import { LocalStorageService } from '../local-storage.service';
import { Page } from '../page';
import { SubjectsService } from './../../services/subjects.service';
import { AssigneDossierComponent } from './assigne-dossier/assigne-dossier.component';

export interface SearchItem {
   name: string;
}

enum AutocompleteFilterEnum {
   SUBCENTER = 'subcenter',
}

@Component({
   selector: 'app-subjects',
   templateUrl: './subjects.component.html',
   styleUrls: ['./subjects.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class SubjectsComponent {
   selectable = true;
   removable = true;
   readonly separatorKeysCodes: number[] = [COMMA, ENTER];
   public searchTerms: string[] = [];

   public globalFilter: any = '';
   public searchItems: SearchItem[] = [];
   page: Page<Subject> = new Page();
   public reset_courses = this.page;
   myInput = '';
   object: { origin: string };
   isUserManager = false;
   displayedColumns: string[] = [
      'registrationId',
      'id',
      'dateOfCreation',
      'lastName',
      'firstName',
      'parentName',
      'dateOfBirth',
      'yearOfBirth',
      'gender',
      'jmbg',
      'jmbp',
      'status',
      'familyDossier',
   ];
   fileUrl: any;
   subcenter = new FormControl();
   subcenterCsrId: any;
   subcenters = [];
   loggedIn: any;
   areLoaded = false;
   isMainCenterBelgrade = false;
   isUserDrugostepeni = false;
   allCsrCodebooks = [];
   filteredSubcenters: Observable<any>;
   paramsObject: any;
   loadParams = true;
   @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;

   constructor(
      private sharedCaseService: SharedCaseService,
      private subjectsService: SubjectsService,
      public dialog: MatDialog,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      @Optional() public dialogRef: MatDialogRef<SubjectsComponent>,
      private paginationService: CustomPaginationService,
      public router: Router,
      private authenticationService: AuthenticationService,
      private translate: TranslateService,
      private codebookService: CodebookService,
      private localStorageService: LocalStorageService,
      private toastr: ToastrImplService,
      private route: ActivatedRoute,
      private userService: UserService
   ) {
      this.CheckAuthentication();
      this.localStorageService.remove('case');

      this.clearSharedCaseService(); // Treba da se obrise da ne bi pamtio tabove o detaljima predmeta

      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isUserDrugostepeni = userService.isUserDrugostepeni();
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;

      if (this.isUserDrugostepeni || this.isMainCenterBelgrade) {
         /*
            Olja Andjelovski olja.andjelovski@iten.rs
            Ukoliko je u localStorage sacuvan podcentar ucitaj ga u polja i ucitaj korisnike za taj podcentar.
            Ukoliko smo na formi za detalje kornika i kliknemo na dugme nazad sada ce biti ucitani podaci o podcentru koji je izabran.
            Prema trazenoj funkcionalnosti.
            Ukoliko smo na nekoj drugoj komponenti pa klikenemo registar obrisan je localStorageSubcenter.
           */
         if (this.localStorageService.get('localStorageSubcenter') !== null) {
            const localStorageSubcenter = JSON.parse(this.localStorageService.get('localStorageSubcenter'));
            this.subcenterCsrId = localStorageSubcenter.id;
            this.subcenter.setValue(localStorageSubcenter);
         } else {
            this.subcenterCsrId = 0;
         }
      }
      if (this.isUserDrugostepeni) {
         // Drugostepeni - svi centri se dobavljaju
         this.codebookService.getCsrCodebook().subscribe(result => {
            this.subcenters = result;
            this.filteredSubcenters = this.subcenter.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice()))
            );
         });
      } else {
         // Glavni centar Beograd - podcentri
         this.codebookService.getAllSubcenters().subscribe(result => {
            this.subcenters = result;
            this.filteredSubcenters = this.subcenter.valueChanges.pipe(
               startWith(''),
               map(value => (typeof value === 'string' ? value : value.name)),
               map(title => (title ? this._filter(title, AutocompleteFilterEnum.SUBCENTER) : this.subcenters.slice()))
            );
         });
      }
      this.getQueryParams();
      this.object = { ...data };
      this.doFilter();
   }

   public getNextPage(): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   public getPreviousPage(): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }

   public getFirstPage(): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter();
   }

   public getLastPage(): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter();
   }

   public getPageInNewSize(pageSize: any): void {
      this.areLoaded = false;
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter();
   }

   onRowClicked(element: any) {
      if (this.object.origin === 'entrance') {
         this.dialogRef.close({ data: element });
      } else if (this.object.origin === 'accommodation') {
         this.dialogRef.close({ data: element });
      } else if (this.object.origin === 'nsp') {
         this.dialogRef.close({ data: element });
      } else if (this.object.origin === 'forms23') {
         if (
            this.data.members.some(
               (member: any) =>
                  member.subjectIdentity.csrId === element.subjectIdentity.csrId && member.subjectIdentity.subjectId === element.subjectIdentity.subjectId
            )
         ) {
            this.toastr.error('SNACKBAR.ALREADY_MEMBER_OF_FAMILY');
         } else {
            this.dialogRef.close({ data: element });
         }
      } else {
         /*
            Klikom na korisnika treba se redirektujemo na dosije - stranicu o detaljima.
            Osim subjectId koji se prosledjuje kroz url, potreban nam je i csrId
            njega treba da prosledimo kroz localStorage, umesto kroz dataSharingService.
            LocalStorage ce sacuvati vrednost, iako uradimo refresh, dok kod dataSaringService-a to ne funkcionise.
            */
         // Na osnovu kliknutog subjecta uzimamo njegov csrId i smestamo ga u localStorage
         this.localStorageService.set('localStorageCenterId', element.subjectIdentity.csrId);
         this.router.navigate(['/subjects/', element.subjectIdentity.subjectId]);
      }
   }

   doFilter() {
      this.setQueryParams();
      // Ako se radi o glavnom centru u Beogradu -> GCBG ili drugostepenomPostupku
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         if (this.subcenterCsrId !== undefined) {
            this.subjectsService
               .getFilteredPage(
                  this.page.pageable,
                  this.globalFilter === '' ? this.myInput : this.globalFilter,
                  this.subcenterCsrId,
                  this.globalFilter === '' ? null : true
               )
               .subscribe(page => {
                  this.page = page;
                  this.page.pageable.pagePerShow = page.number + 1;
                  for (const element of this.page.content) {
                     if (element.dateOfBirth !== null) {
                        element.dateOfBirth = (element.dateOfBirth as any).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
                     }
                     element.dateOfCreation = (element.dateOfCreation as any).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
                  }
                  this.areLoaded = true;
               });
         }
      } else {
         this.subjectsService
            .getFilteredPage(
               this.page.pageable,
               this.globalFilter === '' ? this.myInput : this.globalFilter,
               this.loggedIn?.csrId,
               this.globalFilter === '' ? null : true
            )
            .subscribe(page => {
               this.page = page;
               this.page.pageable.pagePerShow = page.number + 1;
               for (const element of this.page.content) {
                  if (element.dateOfBirth !== null) {
                     element.dateOfBirth = (element.dateOfBirth as any).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
                  }
                  element.dateOfCreation = (element.dateOfCreation as any).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
               }
               this.areLoaded = true;
            });
      }
   }

   public printPDF(): void {
      let centerId = null;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         if (this.subcenterCsrId !== undefined) {
            centerId = this.subcenterCsrId;
         } else {
            this.toastr.info('SUBJECTS.WARMING_CENTAR');
         }
      } else {
         centerId = this.loggedIn?.csrId;
      }

      if (centerId !== null) {
         const dialogRef = this.dialog.open(PrintComponent, {
            panelClass: 'overlay-panel',
            disableClose: true,
            width: '45%',
            data: {
               maxNumber: this.page.totalElements,
               whole: null,
               partial: {
                  from: null,
                  to: null,
                  type: null,
               },
               src: 's',
               csr: centerId,
            },
         });

         dialogRef.afterClosed().subscribe(res => {
            if (res.event === ModalEventEnum.SUCCESS) {
               const printDto = {
                  csrId: centerId,
                  whole: res.data.whole,
                  from: res.data.partial.from,
                  to: res.data.partial.to,
                  type: res.data.partial.type,
               };
               this.subjectsService.printPdf(printDto);
            }
         });
      }
   }

   public exportCsv(): void {
      let filename = '';
      this.translate.get('SUBJECTS.TITLE').subscribe((res: string) => {
         filename = res;
      });
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni) {
         if (this.subcenterCsrId !== undefined) {
            this.subjectsService.exportCSV(this.searchTerms, this.subcenterCsrId, filename);
         } else {
            this.toastr.info('SUBJECTS.WARMING_CENTAR');
         }
      } else {
         this.subjectsService.exportCSV(this.searchTerms, this.loggedIn?.csrId, filename);
      }
   }

   add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;
      // Add new search term
      if ((value || '').trim()) {
         this.searchItems.push({ name: value.trim() });
      }
      // Reset the input value
      if (input) {
         input.value = '';
      }
      this.searchTerms = this.searchItems.map(function (searchItem) {
         return searchItem.name;
      });
      this.globalFilter = '';
      this.applyFilter(this.searchTerms);
   }

   remove(item: SearchItem): void {
      const index = this.searchItems.indexOf(item);
      if (index >= 0) {
         this.searchItems.splice(index, 1);
         this.searchTerms = this.searchItems.map(function (searchItem) {
            return searchItem.name;
         });
         this.applyFilter(this.searchTerms);
      }
   }

   applyFilter(filterValue: any) {
      this.globalFilter = filterValue;
      this.page.pageable.pageNumber = 0;
      this.doFilter();
   }

   clearSharedCaseService() {
      this.sharedCaseService.tabs = [];
      this.sharedCaseService.activeTab = null;
   }

   CheckAuthentication() {
      this.isUserManager = this.userService.isUserManager();
      if (this.isUserManager) {
         this.displayedColumns.push('actions');
      }
   }
   handleOpenDialog(element: any) {
      const dialogRef = this.dialog.open(AssigneDossierComponent, {
         panelClass: 'overlay-panel',
         width: '800px',
         data: element,
      });
      dialogRef.afterClosed().subscribe(
         result => {
            if (result.event === 'Cancel') {
               this.toastr.info('SNACKBAR.CANCEL_BORROWING_DOSSIER');
            } else {
               this.toastr.success('SNACKBAR.BORROWED_DOSSIER');
            }
         },
         error => {
            console.log('Дошло је до грешке');
         }
      );
   }

   reloadSubjects(event: any) {
      this.subcenterCsrId = event?.id;
      this.localStorageService.set('localStorageSubcenter', JSON.stringify(event));
      this.doFilter();
   }

   private _filter(value: string, what: AutocompleteFilterEnum): any[] {
      const filterValue = value.toLowerCase();

      switch (what) {
         case AutocompleteFilterEnum.SUBCENTER:
            return this.subcenters.filter(option => option.name.toLowerCase().includes(filterValue));
         default:
            break;
      }
   }
   displayCustomFormat(objectValue: any): string {
      return objectValue ? objectValue.name : objectValue;
   }

   setQueryParams() {
      let params = {
         pageNum: null,
         size: null,
         filter: this.globalFilter === '' ? null : this.globalFilter,
      };
      if (this.object.origin === undefined) {
         if (this.page.pageable.pageNumber !== 0) {
            params = {
               pageNum: this.page.pageable.pageNumber,
               size: this.page.pageable.pageSize,
               filter: this.globalFilter === '' ? null : this.globalFilter,
            };
         }
         this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
         });
      }
   }

   getQueryParams() {
      this.route.queryParamMap.subscribe(params => {
         if (this.loadParams === true) {
            this.paramsObject = { ...params.keys, ...params };
            const param = this.paramsObject.params;
            this.page.pageable.pageNumber = param?.pageNum ? param?.pageNum : this.page.pageable.pageNumber;
            this.page.pageable.pageSize = param?.size ? param?.size : this.page.pageable.pageSize;

            if (param?.filter) {
               this.searchItems = [];
               this.globalFilter = Array.isArray(param?.filter) ? param?.filter : [param?.filter];
               for (const filter of this.globalFilter) {
                  this.searchItems.push({ name: filter });
               }
            }
            this.loadParams = false;
         }
      });
   }
}
