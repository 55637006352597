<div class="widget-subjects-wrapper">
    <card-header title="{{ 'SUBJECT_CASES.TITLE' | translate }}">
        <div class="aside">
            <form [formGroup]="extendedFilter">
                <mat-form-field appearance="outline" class="search">
                    <mat-label>{{ 'SUBJECT_CASES.SEARCH' | translate }}</mat-label>
                    <input matInput title="{{ 'SUBJECT_CASES.TOOLTIP_TEXT_FILTER' | translate }}"
                        formControlName="filtervalue" type="text" class="col-8 text-center"
                        (keyup.enter)="onEnterClickAddFilter()" />
                </mat-form-field>
            </form>
            <button mat-icon-button class="button-square button-square--large" color="primary"
                [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" [matBadge]="badgeCount > 0 ? badgeCount : null"
                matTooltip="{{ 'SUBJECT_CASES.TOOLTIP_FILTER' | translate }}">
                <mat-icon class="button-square__icon">filter_list</mat-icon>
            </button>

            <mat-menu #menu="matMenu" class="filter-menu">
                <form [formGroup]="filterForm" class="filter-menu__content" (click)="$event.stopPropagation()">
                    <field-holder>
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'SUBJECT_CASES.FILTER.FROM' | translate }}</mat-label>
                            <input matInput [matDatepicker]="minDatepicker" formControlName="minDate" [max]="maxDate" />
                            <mat-datepicker-toggle matSuffix [for]="minDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #minDatepicker></mat-datepicker>
                        </mat-form-field>
                    </field-holder>
                    <field-holder>
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'SUBJECT_CASES.FILTER.TO' | translate }}</mat-label>
                            <input matInput [matDatepicker]="maxDatepicker" formControlName="maxDate" [max]="maxDate" />
                            <mat-datepicker-toggle matSuffix [for]="maxDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #maxDatepicker></mat-datepicker>
                        </mat-form-field>
                    </field-holder>
                    <form-footer>
                        <button class="form-footer__button form-footer__button--secondary" mat-stroked-button
                            color="primary" type="button" (click)="resetFilter()">
                            {{ 'SUBJECT_CASES.FILTER.RESET' | translate }}
                        </button>
                        <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
                            type="button" (click)="applyFilter(false)">
                            {{ 'SUBJECT_CASES.FILTER.APPLY' | translate }}
                        </button>
                    </form-footer>
                </form>
            </mat-menu>

        </div>

    </card-header>
    <mat-chip-list #chipList aria-label="Applied filters" [class]="appliedFilters.length > 0 ? 'mb-30' : ''">
        <mat-chip *ngFor="let filter of appliedFilters" (removed)="removeFilter(filter)">
            {{ filter }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <mat-chip *ngFor="let textFilter of appliedTextFilters" (removed)="removeTextFilter(textFilter)">
            {{ textFilter }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

    </mat-chip-list>
    <div *ngIf="areLoaded">


        <div class="table-responsive-wrapper">
            <table *ngIf="page.content.length > 0" mat-table [dataSource]="page.content" #mytable
                class="subject-case-table">
                <ng-container matColumnDef="case">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_CASES.TABLE.CASE_NUMBER' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.caseNumber }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="classification">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_CASES.TABLE.CLASSIFICATION' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.socialCaseClassificationCodebook.title }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef class="title-column">
                        {{ 'SUBJECT_CASES.TABLE.CASE_TITLE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="title-column">
                        <div class="title-column-content">
                            {{ element.caseKindCodebook.mark }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_CASES.TABLE.FROM_DATE' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.dateOfCreation | date: 'dd.MM.yyyy. h:mm:ss' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="status-column">
                        {{ 'SUBJECT_CASES.TABLE.CASE_STATUS' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="status-column">
                        <div class="status-column-content">
                            <div
                                *ngIf="element.intoPlanner === false && element.newComplain===true&&element.secondDegree!==true">
                                {{translateStatus('COMPLAIN')}} </div>
                            <div
                                *ngIf="element.intoPlanner === false && element.newComplain!==true&&element.secondDegree===true">
                                {{translateStatus('SECOND_DEGREE')}} </div>
                            <div
                                *ngIf="element.intoPlanner === false && element.newComplain!==true&&element.secondDegree!==true">
                                {{translateStatus(element.caseStatus)}} </div>
                            <div *ngIf="element.intoPlanner === true">
                                <status-label status="2">
                                    {{ 'SEND_INTO_PLANNER.INTO_PLANNER' | translate }}
                                </status-label>
                                <h5>{{'SUBJECT_DOCUMENTS.FILTER.FROM' | translate}}
                                    {{element.dateIntoPlanner | date: 'dd.MM.yyyy.'}}</h5>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="imported">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'SUBJECT_CASES.TABLE.IMPORTED' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.imported">
                            {{ 'GLOBAL.YES' | translate }}
                        </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="clickCase(row)"
                    class="subject-case-table__row"></tr>
            </table>
        </div>
        <div class="pagination" *ngIf="page.content.length > 0">
            <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage()"
                (previousPageEvent)="getPreviousPage()" (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"
                (pageSizeEvent)="getPageInNewSize($event)">
            </app-custom-pagination>
        </div>

        <p class="subject-case-empty" *ngIf="page.content.length === 0">
            <mat-icon class="subject-case-empty__icon subject-case-empty__icon--empty">info</mat-icon>
            {{ 'SUBJECT_CASES.NO_RESULTS' | translate }}
        </p>
    </div>
    <mat-card *ngIf="!areLoaded" class="card">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>
</div>