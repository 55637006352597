/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
   EngagementStatusesEnum,
   EngagementStatuses
} from '../../../entrance/types';
import { patchForDecimalNumber } from 'src/app/utils/formatLocalNumber';

// Compare object exquality
function areEqual(a, b) {
   // Create arrays of property names
   var aProps = Object.getOwnPropertyNames(a);
   var bProps = Object.getOwnPropertyNames(b);

   // If number of properties is different,
   // objects are not equivalent
   if (aProps.length != bProps.length) {
      return false;
   }

   for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
         return false;
      }
   }
   // If we made it this far, objects
   // are considered equivalent
   return true;
}

interface SelectOptions {
   value: any;
   viewValue: string;
}

enum YesNoEnum {
   YES = 'true',
   NO = 'false'
}

type PreviousStepReadOnlyData = {
   earningAmountFrom: Date,
   earningAmountTo: Date,
   earningAmount: string,
   earningWorkArrangementAmount: string,
   earningRentingAmount: string,
   missedEarningAmountFrom: Date,
   missedEarningAmountTo: Date,
   missedEarningAmount: string,
   missedEarningWorkArrangementAmount: string,
   missedEarningRentingAmount: string,
};

type DataFromFamilyForConclusion = {
   familyMembersCount: number;
   familyMembersAbleToWorkCount: number;
   familyMembersUnableToWorkCount: number;
};

type CurrentData = {
   earningAmountFrom: Date,
   earningAmountTo: Date,
   earningAmount: any,
   missedEarningAmount: any,
   missedEarningAmountFrom: Date,
   missedEarningAmountTo: Date,
};

@Component({
   selector: 'nsp-conclusion-step',
   templateUrl: './nsp-conclusion-step.component.html',
   styleUrls: ['./nsp-conclusion-step.component.scss']
})
export class NspConclusionStepComponent implements OnInit {
   @Output() conclusionStepEmitter = new EventEmitter();
   @Output() signatureEmitter = new EventEmitter();
   @Output() printPreviewEmitter = new EventEmitter();
   @Output() saveDataForConclusionEmitter = new EventEmitter();
   @Input() conclusionStep: FormGroup;
   @Input() editable: boolean;
   @Input() readOnlyData: PreviousStepReadOnlyData;
   @Input() dataFromFamily: DataFromFamilyForConclusion;
   @Input() isFormFinished: boolean;
   @Input() customData: any;
   @Input() subjectData: any;
   @Input() startDate: any;
   @Input() sendClicked: boolean
   shouldInitiallyCheck = true;
   currentData: CurrentData;
   maxDate: Date = new Date(2999, 11, 31);

   constructor(private formBuilder: FormBuilder) { }

   yesNoOptions: SelectOptions[] = [
      { value: YesNoEnum.YES, viewValue: 'Да' },
      { value: YesNoEnum.NO, viewValue: 'Не' },
   ];

   engagementStatuses: EngagementStatuses[] = [
      { value: EngagementStatusesEnum.UKLJUCIVANJE_U_SKOLOVANJE, viewValue: 'Укључивање у школовање' },
      { value: EngagementStatusesEnum.OSPOSOBLJAVANJE_ZA_RAD, viewValue: 'Оспособљавање за рад' },
      { value: EngagementStatusesEnum.RADNO_ANGAZOVANJE, viewValue: 'Радно ангажовање' },
      { value: EngagementStatusesEnum.DRUSTVENO_ANGAZOVANJE, viewValue: 'Друштвено ангажовање' }
   ];

   ngOnInit(): void {
      this.conclusionStep = this.formBuilder.group({
         dateStart: [this.startDate, Validators.required],
         familyMembersCount: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
         familyMembersAbleToWorkCount: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
         familyMembersUnableToWorkCount: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
         earningAmount: ['', [Validators.pattern('^[0-9.,]*$')]],
         earningAmountFrom: [{ value: '', disabled: true }, []],
         earningAmountTo: [{ value: '', disabled: true }, []],
         missedEarningAmount: ['', [Validators.pattern('^[0-9.,]*$')]],
         missedEarningAmountFrom: [{ value: '', disabled: true }, []],
         missedEarningAmountTo: [{ value: '', disabled: true }, []],
         ableToLearnJobSkillsCount: ['', [Validators.pattern('^[0-9]*$')]],
         readyToLearnJobSkillsCount: ['', [Validators.pattern('^[0-9]*$')]],
         requiresAdditionalServices: ['', Validators.required],
         engagementStatus: ['', []],
         conclusion: ['', Validators.required],
         advice: ['', Validators.required],
         send: ['', []]
      });
   }

   ngOnChanges(changes: any) {
      if (changes.customData !== undefined && changes.customData !== null && changes.customData.previousValue !== null
         && changes.customData.previousValue !== undefined && changes.customData.currentValue === null) {
         this.ngOnInit();
      }
      if (changes.customData !== undefined && changes.customData !== null) {
         if (changes.customData.currentValue !== null) {
            if (Object.keys(changes.customData.currentValue).length !== 0) {
               if (changes.customData && changes.customData.currentValue) {

                  this.patchValues(changes.customData.currentValue);
               }
            }
         }
      }
      if (changes.startDate !== undefined && changes.startDate !== null && this.conclusionStep.controls.dateStart !== undefined) {
         this.conclusionStep.controls.dateStart.setValue(this.startDate);
      }

   }


   /**
    * Conditionally update readonly fields
    * - initially, when the data arrives for the first time
    * - also, after user returns to previous step and update some value
    */
   ngAfterContentChecked(): void {
      if (this.readOnlyData && this.shouldInitiallyCheck) {
         const {
            earningAmountFrom,
            earningAmountTo,
            earningAmount,
            missedEarningAmount,
            missedEarningAmountFrom,
            missedEarningAmountTo,
         } = this.readOnlyData;
         this.currentData = {
            earningAmountFrom,
            earningAmountTo,
            earningAmount: Number(earningAmount),
            missedEarningAmount: Number(missedEarningAmount),
            missedEarningAmountFrom,
            missedEarningAmountTo,
         };
         this.setConditionalFields();
      }

      if (!this.shouldInitiallyCheck && !areEqual(this.readOnlyData, this.currentData)) {
         const {
            earningAmountFrom,
            earningAmountTo,
            earningAmount,
            missedEarningAmount,
            missedEarningAmountFrom,
            missedEarningAmountTo,
         } = this.readOnlyData;
         this.currentData = {
            earningAmountFrom,
            earningAmountTo,
            earningAmount: patchForDecimalNumber(earningAmount),
            missedEarningAmount: patchForDecimalNumber(missedEarningAmount),
            missedEarningAmountFrom,
            missedEarningAmountTo,
         };
         this.setConditionalFields();
      }
   }

   /**
    * Render dynamic fields depending on the selected option from the `observationReason` field
    */
   setConditionalFields() {
      const earning = this.conclusionStep.get('earningAmount');
      const earningFrom = this.conclusionStep.get('earningAmountFrom');
      const earningTo = this.conclusionStep.get('earningAmountTo');
      const missedEarning = this.conclusionStep.get('missedEarningAmount');
      const missedEarningFrom = this.conclusionStep.get('missedEarningAmountFrom');
      const missedEarningTo = this.conclusionStep.get('missedEarningAmountTo');

      const {
         earningAmount,
         earningAmountFrom,
         earningAmountTo,
         missedEarningAmount,
         missedEarningAmountFrom,
         missedEarningAmountTo,
      } = this.currentData;

      earning.setValue(earningAmount);
      earningFrom.setValue(earningAmountFrom);
      earningTo.setValue(earningAmountTo);
      missedEarning.setValue(missedEarningAmount);
      missedEarningFrom.setValue(missedEarningAmountFrom);
      missedEarningTo.setValue(missedEarningAmountTo);

      // podaci iz koraka o clanovima porodice
      this.conclusionStep.get('familyMembersCount').setValue(this.dataFromFamily.familyMembersCount);
      this.conclusionStep.get('familyMembersAbleToWorkCount').setValue(this.dataFromFamily.familyMembersAbleToWorkCount);
      this.conclusionStep.get('familyMembersUnableToWorkCount').setValue(this.dataFromFamily.familyMembersUnableToWorkCount);

      this.shouldInitiallyCheck = false;
   }

   /**
    * Send step information to parent component
    */
   handleSignature() {
      this.signatureEmitter.emit();
   }

   /**
    * Send step information to parent component
    */
   updateConclusionStep() {
      this.conclusionStep.get('send').patchValue('false');
      this.conclusionStepEmitter.emit(this.conclusionStep);
   }

   saveConclusionStep() {
      // this.updateConclusionStep();
      this.conclusionStep.get('send').patchValue('true');
      this.conclusionStepEmitter.emit(this.conclusionStep);
   }

   saveDataForConclusion() {
      this.saveDataForConclusionEmitter.emit(this.conclusionStep);
   }

   printPreview() {
      this.printPreviewEmitter.emit(this.conclusionStep);
   }

   patchValues(data: any) {
      this.conclusionStep.patchValue({
         dateStart: this.startDate,
         earningAmount: patchForDecimalNumber(data.earningAmount),
         earningAmountFrom: data.earningAmountFrom !== null ?
            new Date(data.earningAmountFrom?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) :
            data.earningAmountFrom,
         earningAmountTo: data.earningAmountTo !== null ?
            new Date(data.earningAmountTo?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) :
            data.earningAmountTo,
         missedEarningAmount: patchForDecimalNumber(data.missedEarningAmount),
         missedEarningAmountFrom: data.missedEarningAmountFrom !== null ?
            new Date(data.missedEarningAmountFrom?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) :
            data.missedEarningAmountFrom,
         missedEarningAmountTo: data.missedEarningAmountTo !== null ?
            new Date(data.missedEarningAmountTo?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')) :
            data.missedEarningAmountTo,
         familyMembersCount: data.familyMembersCount,
         familyMembersAbleToWorkCount: data.familyMembersAbleToWorkCount,
         familyMembersUnableToWorkCount: data.familyMembersUnableToWorkCount,
         ableToLearnJobSkillsCount: data.ableToLearnJobSkillsCount,
         readyToLearnJobSkillsCount: data.readyToLearnJobSkillsCount,
         requiresAdditionalServices: data.requiresAdditionalServices === false ? YesNoEnum.NO : YesNoEnum.YES,
         conclusion: data.conclusion,
         advice: data.advice // nedostaje
      });
   }
   changeDate() {
      //      this.startDate = this.familyMembersStep.value.dateStart;
      //      this.countMinorsAndAdults();
   }
}

/**
 * TODO @gorandivovic
 *
 * 1. Improve validation for the first 3 fields (update final field by calculating first two)
 *    e.g. family members cunt is 10, unable to work is 2 → calculate 8 for able to work field
 */
