<form [formGroup]="propertyStep" class="form">
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.PROPERTY.REGISTER_IN_CSR_TITLE' | translate }}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="isRegisteredCSR"
      class="form__radio-group">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value"
        [disabled]="(option.viewValue==='Да' && regNumbers==='')||(option.viewValue=='Не' && regNumbers!=='')">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="regIDNumberVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.REG_ID_NUMBER' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="regIDNumber" autocomplete="off" [required]="regIDNumberVisible"
        readonly />
      <mat-error *ngIf="propertyStep.get('regIDNumber').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.REG_ID_NUMBER_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <h6 class="subgroup-title">
    {{ 'NSP_MOCK.PROPERTY.GROUP_TITLE_REALESTATE' | translate }}
  </h6>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.ROOMS_NUMBER' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="roomsNumber" autocomplete="off" required
        (change)="roomsNumberValidation()" />
      <mat-error *ngIf="propertyStep.get('roomsNumber').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.ROOMS_NUMBER_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.PROPERTY.SURPLUS_HOUSING' | translate }}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="surplusHousing"
      class="form__radio-group form__radio-group--2-cols">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.PROPERTY.OWNS_REALSTATE' | translate }}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="ownsRealestate"
      class="form__radio-group">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="realestateSizeVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.REALESTATE_SIZE' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="realestateSize" autocomplete="off"
        [required]="realestateSizeVisible" />
      <mat-error *ngIf="propertyStep.get('realestateSize').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.REALESTATE_SIZE_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.PROPERTY.LEASE' | translate}}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="lease" class="form__radio-group">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.PROPERTY.ABLE_TO_LEASE' | translate }}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="ableToLease"
      class="form__radio-group">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <h6 class="subgroup-title">
    {{ 'NSP_MOCK.PROPERTY.GROUP_MOVABLE_PROPERTY' | translate }}
  </h6>
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.PROPERTY.OWN_MOVABLE_PROPERTY' | translate }}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="ownsMovableProperty"
      class="form__radio-group">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="movablePropertyVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.MOVABLE_PROPERTY' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="movableProperty" autocomplete="off"
        [required]="movablePropertyVisible" />
      <mat-error *ngIf="propertyStep.get('movableProperty').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.MOVABLE_PROPERTY_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>


  <ng-container *ngIf="movablePropertyVisible">
    <field-holder>
      <span class="form__radio-group-label">
        {{ 'NSP_MOCK.PROPERTY.ALIENATION' | translate }}, {{ 'NSP_MOCK.PROPERTY.CAN_PROVIDE' | translate }}*
      </span>
      <mat-radio-group aria-label="Select an option" color="primary" formControlName="canProvide"
        class="form__radio-group">
        <mat-radio-button *ngFor="let option of yesNoOptions" (change)="warningForMovableProperty($event.value)"
          [value]="option.value">
          {{ option.viewValue }}
        </mat-radio-button>
      </mat-radio-group>
    </field-holder>
  </ng-container>


  <form-subgroup-divider></form-subgroup-divider>
  <h6 class="subgroup-title">
    {{ 'NSP_MOCK.PROPERTY.GROUP_TITLE_EARNING' | translate }}
  </h6>
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.PROPERTY.EARNING' | translate }}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="earning" class="form__radio-group">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <field-holder kind="3-cols-1-1-2">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NSP_MOCK.PROPERTY.EARNING_FROM' | translate }}</mat-label>
      <input matInput [matDatepicker]="earningPickerFrom" formControlName="earningAmountFrom" [max]="maxDate"
        autocomplete="off" required/>
      <mat-datepicker-toggle matSuffix [for]="earningPickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #earningPickerFrom></mat-datepicker>
      <mat-error *ngIf="propertyStep.get('earningAmountFrom').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_ERROR' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'NSP_MOCK.PROPERTY.EARNING_TO' | translate }}</mat-label>
      <input matInput [matDatepicker]="earningPickerTo" formControlName="earningAmountTo" [max]="maxDate"
        autocomplete="off" required/>
      <mat-datepicker-toggle matSuffix [for]="earningPickerTo"></mat-datepicker-toggle>
      <mat-datepicker #earningPickerTo></mat-datepicker>
      <mat-error *ngIf="propertyStep.get('earningAmountTo').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_TO_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <ng-container *ngIf="earningAmountVisible">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="earningAmount" autocomplete="off" (change)="checkEarningAmount()"
          [required]="earningAmountVisible" />
        <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
        <mat-error *ngIf="propertyStep.get('earningAmount').hasError('required')">
          {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </field-holder>

  <mat-error *ngIf="propertyStep.hasError('notValidEarningFromTo')">
    {{ 'NSP_MOCK.PROPERTY.DATES_ERROR' | translate }}
  </mat-error>

  <field-holder>
    <mat-checkbox color="primary" class="form__checkbox" formControlName="earningRenting" *ngIf="earningAmountVisible">
      {{ 'NSP_MOCK.PROPERTY.EARNING_RENTING' | translate }}</mat-checkbox>
    <field-holder kind="2-cols">
      <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
        <mat-label>
          {{ 'NSP_MOCK.PROPERTY.EARNING_RENTING_WHAT' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="earningRentingWhat" autocomplete="off"
          [required]="propertyStep.get('earningRenting').value === true" />
        <mat-error *ngIf="propertyStep.get('earningRentingWhat').hasError('required')">
          {{ 'NSP_MOCK.PROPERTY.EARNING_RENTING_WHAT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
        <mat-label>
          {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="earningRentingAmount" autocomplete="off"
          (change)="checkEarningAmount()" [required]="propertyStep.get('earningRenting').value === true" />
        <span matSuffix>
          {{ 'NSP_MOCK.PROPERTY.RSD' | translate }}
        </span>
        <mat-error *ngIf="propertyStep.get('earningRentingAmount').hasError('required')">
          {{ 'NSP_MOCK.PROPERTY.EARNING_RENTING_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </field-holder>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningWorkArrangement"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningWorkArrangementAmount" autocomplete="off"
        (change)="checkEarningAmount()" [required]="propertyStep.get('earningWorkArrangement').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningWorkArrangementAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningFromEmployment"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_EMPLOYMENT' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningFromEmploymentAmount" autocomplete="off"
        (change)="checkEarningAmount()" [required]="propertyStep.get('earningFromEmployment').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningFromEmploymentAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningFromTemporaryJobs"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_TEMPORARY_JOBS' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningFromTemporaryJobsAmount" autocomplete="off"
        (change)="checkEarningAmount()" [required]="propertyStep.get('earningFromTemporaryJobs').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningFromTemporaryJobsAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningFromRetirements"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_RETIREMENTS' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningFromRetirementsAmount" autocomplete="off"
        (change)="checkEarningAmount()" [required]="propertyStep.get('earningFromRetirements').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningFromRetirementsAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningFromAgriculture"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_AGRICULTURE' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningFromAgricultureAmount" autocomplete="off"
        (change)="checkEarningAmount()" [required]="propertyStep.get('earningFromAgriculture').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningFromAgricultureAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningFromIndipendentActivity"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_INDIPENDENT_ACTIVITY' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningFromIndipendentActivityAmount" autocomplete="off"
        (change)="checkEarningAmount()"
        [required]="propertyStep.get('earningFromIndipendentActivity').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningFromIndipendentActivityAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="compensationDuringUnemployment"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.COMPENSATION_DURING_UNEMPLOYMENT' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="compensationDuringUnemploymentAmount" (change)="checkEarningAmount()"
        autocomplete="off" [required]="propertyStep.get('compensationDuringUnemployment').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('compensationDuringUnemploymentAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="severancePayAndCompensation"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.SEVERANCE_PAY_AND_COMPENSATION' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="severancePayAndCompensationAmount" (change)="checkEarningAmount()"
        autocomplete="off" [required]="propertyStep.get('severancePayAndCompensation').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('severancePayAndCompensationAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningFromGoods" *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_GOODS' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningFromGoodsAmount" (change)="checkEarningAmount()"
        autocomplete="off" [required]="propertyStep.get('earningFromGoods').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningFromGoodsAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningFromSavingAndCash"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_SAVING_AND_CASH' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningFromSavingAndCashAmount" (change)="checkEarningAmount()"
        autocomplete="off" [required]="propertyStep.get('earningFromSavingAndCash').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningFromSavingAndCashAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="earningFromMaintenanceOfRelativies"
    *ngIf="earningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.EARNING_FROM_MAINTENANCE_OF_RELATIVES' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="earningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="earningFromMaintenanceOfRelativiesAmount"
        (change)="checkEarningAmount()" autocomplete="off"
        [required]="propertyStep.get('earningFromMaintenanceOfRelativies').value === true" />
      <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
      <mat-error *ngIf="propertyStep.get('earningFromMaintenanceOfRelativiesAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>

  <form-subgroup-divider></form-subgroup-divider>
  <h6 class="subgroup-title">
    {{ 'NSP_MOCK.PROPERTY.GROUP_TITLE_MISSED_EARNING' | translate }}
  </h6>
  <field-holder>
    <span class="form__radio-group-label">
      {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING' | translate }}*
    </span>
    <mat-radio-group aria-label="Select an option" color="primary" formControlName="missedEarning"
      class="form__radio-group">
      <mat-radio-button *ngFor="let option of yesNoOptions" [value]="option.value">
        {{ option.viewValue }}
      </mat-radio-button>
    </mat-radio-group>
  </field-holder>
  <field-holder kind="3-cols-1-1-2">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_FROM' | translate }}
      </mat-label>
      <input matInput [matDatepicker]="pickerFrom" formControlName="missedEarningAmountFrom"
        autocomplete="off" [max]="maxDate" required/>
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
      <mat-error *ngIf="propertyStep.get('missedEarningAmountFrom').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_FROM_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_TO' | translate }}
      </mat-label>
      <input matInput [matDatepicker]="pickerTo" formControlName="missedEarningAmountTo"
        autocomplete="off" [max]="maxDate" required/>
      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
      <mat-datepicker #pickerTo></mat-datepicker>
      <mat-error *ngIf="propertyStep.get('missedEarningAmountTo').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_TO_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <ng-container *ngIf="missedEarningAmountVisible">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_AMOUNT' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="missedEarningAmount" autocomplete="off"
          [required]="missedEarningAmountVisible" (change)="checkMissedEarningAmount()" />
        <span matSuffix>{{ 'NSP_MOCK.PROPERTY.RSD' | translate }}</span>
        <mat-error *ngIf="propertyStep.get('missedEarningAmount').hasError('required')">
          {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_AMOUNT_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </field-holder>
  <mat-error *ngIf="propertyStep.hasError('notValidMissedEarningFromTo')">
    {{ 'NSP_MOCK.PROPERTY.DATES_ERROR' | translate }}
  </mat-error>

  <mat-checkbox color="primary" class="form__checkbox" formControlName="missedEarningWorkArrangement"
    *ngIf="missedEarningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_WORK_ARRANGEMENT' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="missedEarningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="missedEarningWorkArrangementAmount" autocomplete="off"
        [required]="missedEarningAmountVisible && propertyStep.get('missedEarningWorkArrangement').value === true"
        (change)="checkMissedEarningAmount()" />
      <span matSuffix>
        {{ 'NSP_MOCK.PROPERTY.RSD' | translate }}
      </span>
      <mat-error *ngIf="propertyStep.get('missedEarningWorkArrangementAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_WORK_ARRANGEMENT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <mat-checkbox color="primary" class="form__checkbox" formControlName="missedEarningRenting"
    *ngIf="missedEarningAmountVisible">
    {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_RENTING' | translate }}
  </mat-checkbox>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="missedEarningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.EARNING_RENTING_WHAT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="missedEarningRentingWhat" autocomplete="off"
        [required]="propertyStep.get('missedEarningRenting').value === true" />
      <mat-error *ngIf="propertyStep.get('missedEarningRentingWhat').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.EARNING_RENTING_WHAT_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="missedEarningAmountVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_AMOUNT' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="missedEarningRentingAmount" autocomplete="off"
        [required]="missedEarningAmountVisible && propertyStep.get('missedEarningRenting').value === true"
        (change)="checkMissedEarningAmount()" />
      <span matSuffix>
        {{ 'NSP_MOCK.PROPERTY.RSD' | translate }}
      </span>
      <mat-error *ngIf="propertyStep.get('missedEarningRentingAmount').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.MISSED_EARNING_RENTING_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-subgroup-divider></form-subgroup-divider>
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NSP_MOCK.PROPERTY.CIRCUMSTANCES' | translate }}</mat-label>
      <mat-select formControlName="circumstances" multiple [compareWith]="compareCircumstancesObjects">
        <mat-option *ngFor="let circumsance of circumstanceList" [value]="circumsance">
          {{ circumsance.viewValue }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="propertyStep.get('circumstances').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.CIRCUMSTANCES_ERROR' | translate }}
      </mat-error>
      <mat-hint>
        {{ 'NSP_MOCK.PROPERTY.CIRCUMSTANCES_HINT' | translate }}
      </mat-hint>
    </mat-form-field>
  </field-holder>
  <field-holder kind="2-cols">
    <mat-form-field appearance="outline" *ngIf="otherCircumstancesVisible">
      <mat-label>
        {{ 'NSP_MOCK.PROPERTY.CIRCUMSTANCES_OTHER' | translate }}
      </mat-label>
      <input matInput type="text" maxlength="108" formControlName="otherCircumstances" autocomplete="off"
        [required]="otherCircumstancesVisible" />
      <mat-error *ngIf="propertyStep.get('otherCircumstances').hasError('required')">
        {{ 'NSP_MOCK.PROPERTY.CIRCUMSTANCES_OTHER_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <form-footer>
    <button matStepperPrevious mat-stroked-button color="primary"
      class="form-footer__button form-footer__button--secondary">
      {{ 'NSP_MOCK.PREVIOUS_STEP' | translate }}
    </button>
    <button mat-stroked-button matStepperNext color="primary" [disabled]="!propertyStep.valid"
      class="form-footer__button" (click)="updatePropertyStep()">
      {{ 'NSP_MOCK.NEXT_STEP' | translate }}
    </button>
  </form-footer>
</form>