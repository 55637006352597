<header class="app-card-header">
  <div class="app-card-header__left">
    <h3 class="app-card-header__title">
      {{ title }}
    </h3>
  </div>
  <div class="app-card-header__right">
    <ng-content></ng-content>
  </div>
</header>
