/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

/**
 * AppDateAdapter
 */
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {

    if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
      const str = value.split('.');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  // retirar quando for feito o merge da data por mmalerba
  format(date: Date, displayFormat: Object): string {
    date = new Date(Date.UTC(
      date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
      date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    displayFormat = Object.assign({}, displayFormat, { timeZone: 'utc' });


    const dtf = new Intl.DateTimeFormat('sr-Cyrl', displayFormat);
    return dtf.format(date).replace(/[\u200e\u200f]/g, '');
  }

}

