/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Input } from "@angular/core";

@Component({
  selector: "icon-file-pdf",
  templateUrl: "./icon.component.svg",
  styleUrls: ["./icon.component.scss"],
})
export class IconFilePDFComponent {
  @Input() fill: string;
  @Input() size: number = 16;
  @Input() class: string | string[] | Set<string>;
}
