/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodebookService } from 'src/services/codebook.service';

@Component({
  selector: 'new-protege-termination-of-guardianship-step',
  templateUrl: './new-protege-termination-of-guardianship-step.component.html',
  styleUrls: ['./new-protege-termination-of-guardianship-step.component.scss']
})

export class NewProtegeTerminationOfGuardianshipStepComponent implements OnInit {
  @Output() terminationOfGuardianshipStepEmitter = new EventEmitter();
  @Input() terminationOfGuardianshipStep: FormGroup;
  currentDate = new Date();
  terminationOptions: any = [];

  constructor(private formBuilder: FormBuilder,
    private codebookService: CodebookService) { }

  ngOnInit(): void {
    this.terminationOfGuardianshipStep = this.formBuilder.group({
      decisionNumber: [''],
      decisionDate: [''],
      decisionName: [''],
      reasonForTermination: [''],
      terminationReasonCodebook: ['']
    });

    this.getTerminationReasonCodebook();
  }

  getTerminationReasonCodebook() {
    this.codebookService.getTerminationReasonByType('STAR').subscribe(res => {
      this.terminationOptions = [null, ...res];
    });
  }

  /**
   * Send step information to parent component
   */
  updateTerminationOfGuardianshipStep() {
    console.log('termination of guardianship information step emmiter');
    this.terminationOfGuardianshipStepEmitter.emit(this.terminationOfGuardianshipStep);
  }
}
