import { Injectable } from '@angular/core';
import { Tab } from 'src/models/tab.model';

@Injectable({
  providedIn: 'root'
})
export class SharedCaseService {

  activeTab: Tab;
  tabs: Array<Tab> = [];
  constructor() {
  }
}
