<form [formGroup]="observationStep" class="form">
  <field-holder>
    <mat-form-field appearance="outline">
      <mat-label>{{
        'NSP_MOCK.OBSERVATION.OBSERVATION_REASON' | translate
      }}</mat-label>
      <mat-select formControlName="observationReason" multiple [compareWith]="compareObservationsObjects">
        <mat-option *ngFor="let observation of observationList" [value]="observation.value">
          {{ observation.viewValue }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="observationStep.get('observationReason').hasError('required')">
        {{ 'NSP_MOCK.OBSERVATION.OBSERVATION_REASON_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </field-holder>
  <article [class]="shouldAddUIBorderToObservationGroup(1)" *ngIf="selectedObservations.includes(1)">
    <h6 class="observation__title">
      {{ 'NSP_MOCK.OBSERVATION.STATEMENT_TITLE' | translate }}
    </h6>
    <field-holder kind="2-cols-with-action" [formGroup]="observations" *ngFor="
        let observations of statementObservationsGetter.controls;
        let i = index
      ">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NSP_MOCK.OBSERVATION.STATEMENT_TITLE' | translate }}
        </mat-label>
        <input matInput type="text" maxlength="56" formControlName="name" [required]="selectedObservations.includes(1)"
          autocomplete="off" />
        <mat-error *ngIf="observations.get('name').hasError('required')">
          {{ 'NSP_MOCK.OBSERVATION.STATEMENT_TITLE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NSP_MOCK.OBSERVATION.DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" [max]="currentDate"
          [required]="selectedObservations.includes(1)" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="observations.get('date').hasError('required')">
          {{ 'NSP_MOCK.OBSERVATION.DATE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="removeFormControl('STATEMENT', i)"
        matTooltip="{{ 'NSP_MOCK.OBSERVATION.REMOVE' | translate }}"
        *ngIf="statementObservationsGetter.controls.length > 1">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </field-holder>
    <button mat-flat-button color="primary" type="button" (click)="addFormControl('STATEMENT', true)"
      [disabled]="statementObservationsGetter.controls.length > 2">
      {{ 'NSP_MOCK.OBSERVATION.ADD' | translate }}
    </button>
  </article>
  <article [class]="shouldAddUIBorderToObservationGroup(2)" *ngIf="selectedObservations.includes(2)">
    <h6 class="observation__title">
      {{ 'NSP_MOCK.OBSERVATION.FIELD_VISIT_TITLE' | translate }}
    </h6>
    <field-holder kind="2-cols-with-action" [formGroup]="observations" *ngFor="
        let observations of fieldVisitObservationsGetter.controls;
        let i = index
      ">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NSP_MOCK.OBSERVATION.DATE' | translate }} </mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" [max]="currentDate"
          [required]="selectedObservations.includes(2)" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="observations.get('date').hasError('required')">
          {{ 'NSP_MOCK.OBSERVATION.DATE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="removeFormControl('FIELD_VISIT', i)"
        matTooltip="{{ 'NSP_MOCK.OBSERVATION.REMOVE' | translate }}"
        *ngIf="fieldVisitObservationsGetter.controls.length > 1">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </field-holder>
    <button mat-flat-button color="primary" type="button" (click)="addFormControl('FIELD_VISIT', true)"
      [disabled]="fieldVisitObservationsGetter.controls.length > 2">
      {{ 'NSP_MOCK.OBSERVATION.ADD' | translate }}
    </button>
  </article>
  <article [class]="shouldAddUIBorderToObservationGroup(3)" *ngIf="selectedObservations.includes(3)">
    <h6 class="observation__title">
      {{ 'NSP_MOCK.OBSERVATION.ON_SIGHT_INVESTIGATION_TITLE' | translate }}
    </h6>
    <field-holder kind="2-cols-with-action" [formGroup]="observations" *ngFor="
        let observations of onSightInvestigationObservationsGetter.controls;
        let i = index
      ">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NSP_MOCK.OBSERVATION.ON_SIGHT_INVESTIGATION_TITLE' | translate }}
        </mat-label>
        <input matInput type="text" maxlength="51" formControlName="name" [required]="selectedObservations.includes(3)"
          autocomplete="off" />
        <mat-error *ngIf="observations.get('name').hasError('required')">
          {{
            'NSP_MOCK.OBSERVATION.ON_SIGHT_INVESTIGATION_TITLE_ERROR'
              | translate
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NSP_MOCK.OBSERVATION.DATE' | translate }} </mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" [max]="currentDate"
          [required]="selectedObservations.includes(3)" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="observations.get('date').hasError('required')">
          {{ 'NSP_MOCK.OBSERVATION.DATE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="removeFormControl('ON_SIGHT_INVESTIGATION', i)"
        matTooltip="{{ 'NSP_MOCK.OBSERVATION.REMOVE' | translate }}"
        *ngIf="onSightInvestigationObservationsGetter.controls.length > 1">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </field-holder>
    <button mat-flat-button color="primary" type="button" (click)="addFormControl('ON_SIGHT_INVESTIGATION', true)"
      [disabled]="onSightInvestigationObservationsGetter.controls.length > 2"> 
      {{ 'NSP_MOCK.OBSERVATION.ADD' | translate }}
    </button>
  </article>
  <article [class]="shouldAddUIBorderToObservationGroup(4)" *ngIf="selectedObservations.includes(4)">
    <h6 class="observation__title">
      {{ 'NSP_MOCK.OBSERVATION.WITNESS_HEARING_TITLE' | translate }}
    </h6>
    <field-holder kind="2-cols-with-action" [formGroup]="observations" *ngFor="
        let observations of witnessHearingObservationsGetter.controls;
        let i = index
      ">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NSP_MOCK.OBSERVATION.WITNESS_HEARING_TITLE' | translate }}
        </mat-label>
        <input matInput type="text" maxlength="64" formControlName="name" [required]="selectedObservations.includes(4)"
          autocomplete="off" />
        <mat-error *ngIf="observations.get('name').hasError('required')">
          {{ 'NSP_MOCK.OBSERVATION.WITNESS_HEARING_TITLE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NSP_MOCK.OBSERVATION.DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" [max]="currentDate"
          [required]="selectedObservations.includes(4)" autocomplete="off" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="observations.get('date').hasError('required')">
          {{ 'NSP_MOCK.OBSERVATION.DATE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="removeFormControl('WITNESS_HEARING', i)"
        matTooltip="{{ 'NSP_MOCK.OBSERVATION.REMOVE' | translate }}"
        *ngIf="witnessHearingObservationsGetter.controls.length > 1">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </field-holder>
    <button mat-flat-button color="primary" type="button" (click)="addFormControl('WITNESS_HEARING', true)"
      [disabled]="witnessHearingObservationsGetter.controls.length > 2">
      {{ 'NSP_MOCK.OBSERVATION.ADD' | translate }}
    </button>
  </article>
  <article class="observation__group observation__group--is-last" *ngIf="selectedObservations.includes(5)">
    <h6 class="observation__title">
      {{ 'NSP_MOCK.OBSERVATION.OTHER_EVIDENCE_TITLE' | translate }}
    </h6>
    <field-holder kind="1-col-with-action" [formGroup]="observations" *ngFor="
        let observations of otherEvidenceObservationsGetter.controls;
        let i = index
      ">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NSP_MOCK.OBSERVATION.OTHER_EVIDENCE_TITLE' | translate }}
        </mat-label>
        <textarea matInput type="text" maxlength="210" formControlName="name" [required]="selectedObservations.includes(5)"
          autocomplete="off"></textarea>
        <mat-error *ngIf="observations.get('name').hasError('required')">
          {{ 'NSP_MOCK.OBSERVATION.OTHER_EVIDENCE_TITLE_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="removeFormControl('OTHER_EVIDENCE', i)"
        matTooltip="{{ 'NSP_MOCK.OBSERVATION.REMOVE' | translate }}"
        *ngIf="otherEvidenceObservationsGetter.controls.length > 1">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </field-holder>
    <button mat-flat-button color="primary" type="button" (click)="addFormControl('OTHER_EVIDENCE', true)"
      [disabled]="otherEvidenceObservationsGetter.controls.length > 2">
      {{ 'NSP_MOCK.OBSERVATION.ADD' | translate }}
    </button>
  </article>
  <form-footer>
    <span matTooltip="{{'NSP_MOCK.NO_JBMP' | translate }}" [matTooltipDisabled]="noJmbp===false">
        <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}" [matTooltipDisabled]="(noJmbp === false && noRegistrationId === false) || (noJmbp === true)">
          <span matTooltip="{{'CASES.NO_PROBLEM' | translate }}" [matTooltipDisabled]="!((noJmbp === false && noRegistrationId === false) || (noJmbp === true)) || (hasProblem && (noJmbp === false && noRegistrationId === false))">
            <button mat-stroked-button matStepperNext color="primary"
              [disabled]="!observationStep.valid || editable!==true || noJmbp!==false || noRegistrationId!==false || noJmbg||noAbleToWorkId|| !hasProblem" class="form-footer__button"
              (click)="updateObservationStep()">
              {{ 'NSP_MOCK.NEXT_STEP' | translate }}
            </button>
        </span>
      </span>
    </span>
  </form-footer>
</form>