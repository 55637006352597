/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Injectable, OnInit } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';
import { DataSharingService } from './data-sharing.service';
import { LocalStorageService } from 'src/app/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnInit {
  private stompClient;
  private loggedIn;

  constructor(private appService: AppService,
    private dataService: DataSharingService,
    private localStorageService: LocalStorageService) {
  }


  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit(): void {
  }

  initializeWebSocketConnection(user) {
    const ws = new SockJS(this.appService.getUrlNotifications() + environment.websocketUrl);
    this.stompClient = Stomp.over(ws);
    this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
    let that = this;
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe('/user/' + that.loggedIn.id + '/msg', (message: { body: string; }) => {
        if (message.body) {
          const body = JSON.parse(message.body);
          if (body.type === 'NEW_DOCUMENT') {
            that.dataService.newDocument.next(true);
          } else {
            if (body.type === 'UNASSIGNED') {
              that.dataService.newCase.next(true);
            } else {
              that.dataService.messages.next(true);
            }
            if (body.type === 'GENERAL') {

              let documentName: string;
              if (body.message.includes(environment.vsDocumentPP)) {
                documentName = environment.vsDocumentPP;
              } else if (body.message.includes(environment.vsDocumentPU)) {
                documentName = environment.vsDocumentPU;
              } else if (body.message.includes(environment.vsDocumentZakljucak)) {
                documentName = environment.vsDocumentZakljucak;
              }
              const data = { caseId: body.socialCaseId, document: documentName };
              that.dataService.documentSigned.next(data);
            }
            if (body.type === 'SUBMISSIONS') {
              that.dataService.newSubmission.next(true);
            }
          }
        }
      });
    });
  }
}
