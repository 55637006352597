/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { ToastrImplService } from 'src/services/toastr.service';


export const checkRelationshipAgeDifference = (
    userSubject: any,
    userToCompare: any,
    relationship: any,
    toastr: any): boolean => {
    const userSubjectDate = generateDate(userSubject);
    const userToCompareDate = generateDate(userToCompare);
    if (userSubjectDate !== null && userToCompareDate !== null) {

        if (relationship === 3 || relationship === 7) {
            // Deca ili unuci
            if (userSubjectDate < userToCompareDate) {
                showMessage(toastr);
                return false;
            }
        } else if (relationship === 4 || relationship === 8) {
            // Roditelji ili babe i dede
            if (userToCompareDate < userSubjectDate) {
                showMessage( toastr);
                return false;
            }
        }
    }
    return true;
};


export const generateDate = (user: any): number => {
    if (user.dateOfBirth !== undefined && user.dateOfBirth !== null && user.dateOfBirth !== '') {
        return (new Date(user.dateOfBirth.toString().replace(/(\d{2})\.(\d{2})\.(\d{4})\./, '$2/$1/$3'))).getFullYear();
    } else if (!user.foreigner && user.jmbg !== undefined && user.jmbg !== null && user.jmbg !== '' && user.jmbg.toString().length >= 7) {
        const jmbgDate = Number(user.jmbg.substring(4, 7));
        return Number(jmbgDate <= 200 ? '2' + jmbgDate.toString() : '1' + jmbgDate.toString());
    } else if (user.yearOfBirth !== undefined && user.yearOfBirth !== null && user.yearOfBirth !== '') {
        return Number(user.yearOfBirth);
    } else {
        return null;
    }
};

export function showMessage(toastr: ToastrImplService): void {
    toastr.error('FAMILY_MEMBER_CARD.ADD_NOT_VALID');
    toastr.info('FAMILY_MEMBER_CARD.CHECK_DATE_OF_BIRTH');
}
