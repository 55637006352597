<form class="form" [formGroup]="familyMembers" (ngSubmit)="addFamilyMembers()">
    <form-group title="{{ 'FAMILY_MEMBERS.TITLE' | translate }}" isLast="true">
        <field-holder kind="3-cols">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ "VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS_FIELDS.FIRST_NAME" | translate }}
                </mat-label>
                <input matInput type="text" formControlName="firstName" required autocomplete="off" readonly />
                
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ "VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS_FIELDS.LAST_NAME" | translate }}
                </mat-label>
                <input matInput type="text" formControlName="lastName" required autocomplete="off" readonly />
               
            </mat-form-field>
             <mat-form-field appearance="outline">
                <mat-label>
                    {{ "VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS_FIELDS.JMBG" | translate }}
                </mat-label>
                <input matInput type="text" formControlName="jmbg" autocomplete="off" readonly />
            </mat-form-field>
        </field-holder>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ "VS.ACCOMMODATION.FORMSO.STEP_04.FAMILY_MEMBERS_FIELDS.NOTE" | translate }}
                </mat-label>
                <textarea matInput type="textarea" formControlName="note" rows="3" autocomplete="off"  >
                </textarea>
            </mat-form-field>

        </field-holder>
    </form-group>
    <form-footer>
        <button mat-stroked-button mat-dialog-close color="primary" (click)="closeDialog()" type="button"
            class="form-footer__button form-footer__button--secondary">
            {{ "FAMILY_MEMBERS.CANCEL" | translate }}
        </button>
        <button mat-flat-button color="primary" type="submit" class="form-footer__button form-footer__button--primary"
            [disabled]="!familyMembers.valid">
            {{ "FAMILY_MEMBERS.ADD" | translate }}
        </button>
    </form-footer>
</form>