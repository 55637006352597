/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { checkRelationshipAgeDifference } from 'src/app/utils/checkRelationshipAgeDifference';
import { CodebookService } from 'src/services/codebook.service';
import { ToastrImplService } from 'src/services/toastr.service';

enum AutocompleteFilterEnum {
    OCCUPATION = 'occupation',
    CITIZENSHIP = 'citizenship'
}

@Component({
    selector: 'new-protege-guardian-step',
    templateUrl: './new-protege-guardian-step.component.html',
    styleUrls: ['./new-protege-guardian-step.component.scss']
})

export class NewProtegeGuardianStepComponent implements OnInit {
    @Output() guardianStepEmitter = new EventEmitter();
    @Input() guardianStep: FormGroup;
    @Input() guardianshipType: any;
    @Input() protege: any;

    currentDate = new Date();
    relationshipOptions: any = [];
    occupationOptions: any = [];
    filteredOccupationOptions: Observable<any[]>;
    filteredOccupationDirectOptions: Observable<any[]>;
    relationshipError = false;
    citizenshipOptions: any = [];
    filteredCitizenshipOptions: Observable<any[]>;

    constructor(
        public dialog: MatDialog,
        private codebookService: CodebookService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private toastr: ToastrImplService) {
        this.getOccupation();
        this.getCitizenship();
    }

    ngOnInit(): void {
        this.getRelationshipCodebook();
        this.guardianStep = this.formBuilder.group({
            guardian: this.formBuilder.group({
                firstName: [''],
                lastName: [''],
                foreigner: [false],
                jmbg: [''],
                residence: [''],
                occupation: [''],
                relationship: [''],
                dateOfBirth: [''],
                yearOfBirth: [''],
                citizenship: [''],
                phone: ['']
            }),
            collectiveGuardian: this.formBuilder.group({
                firstName: [''],
                lastName: [''],
                typeOfJob: [''],
                socialProtectionName: [''],
            }),
            directGuardian: this.formBuilder.group({
                firstName: [''],
                lastName: [''],
                occupation: [''],
            }),
        });
    }

    /**
     * Send step information to parent component
     */
    updateGuardianStep() {
        this.guardianStepEmitter.emit(this.guardianStep);
    }

    getRelationshipCodebook() {
        this.codebookService.getRelationshipCodebookWithoutUser().subscribe(res => {
            this.relationshipOptions = res;
            if (this.guardianshipType === 'minor') {
                this.relationshipOptions.splice(5, 1);
                this.relationshipOptions.splice(2, 1);
            }
        });
    }

    getOccupation() {
        this.codebookService.getOccupationCodebook().subscribe((res) => {
            this.occupationOptions = res;
            this.filteredOccupationOptions = (this.guardianStep.get('guardian') as FormGroup).controls.occupation.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value?.title),
                    map(title => title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupationOptions.slice())
                );

            this.filteredOccupationDirectOptions = (this.guardianStep.get('directGuardian') as FormGroup).controls.occupation.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value?.title),
                    map(title => title ? this._filter(title, AutocompleteFilterEnum.OCCUPATION) : this.occupationOptions.slice())
                );
        }
        );
    }

    private _filter(value: string, what: AutocompleteFilterEnum): any[] {
        const filterValue = value.toLowerCase();
        switch (what) {
            case AutocompleteFilterEnum.OCCUPATION:
                return this.occupationOptions.filter((option: any) =>
                    option.title.toLowerCase().includes(filterValue)
                );
            case AutocompleteFilterEnum.CITIZENSHIP:
                return this.citizenshipOptions.filter((option: any) =>
                    option.title.toLowerCase().includes(filterValue)
                );
            default:
                break;
        }
    }

    displayCustomFormat(objectValue: any): string {
        return objectValue ? objectValue.title : '';
    }

    importSubjectData(type: any) {
        const dialogRef = this.dialog.open(SubjectsComponent, {
            width: '1200px',
            panelClass:'overlay-panel',
            data: {
                origin: 'entrance',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (type === 'guardian') {
                    this.guardianStep.patchValue({
                        guardian: {
                            firstName: result.data.firstName,
                            lastName: result.data.lastName,
                            foreigner: result.data.foreigner,
                            jmbg: result.data.jmbg,
                            residence: String(result.data.permanentResidence.street ? result.data.permanentResidence.street : '') +
                                String(result.data.permanentResidence.number ? ' ' : '') +
                                String(result.data.permanentResidence.number ? result.data.permanentResidence.number : '') +
                                String(result.data.permanentResidence.subnumber ? '/' : '') +
                                String(result.data.permanentResidence.subnumber ? result.data.permanentResidence.subnumber : ''),
                            occupation: result.data.occupation,
                            citizenship: result.data.citizenship,
                            dateOfBirth: result.data.dateOfBirth,
                            yearOfBirth: result.data.yearOfBirth,
                            phone: (result.data.mobile !== null && result.data.mobile !== '') ? result.data.mobile :
                                ((result.data.phone !== null && result.data.phone !== '') ? result.data.phone : null)

                        }
                    });
                    if (this.guardianStep.controls.guardian.value.relationship !== null &&
                        this.guardianStep.controls.guardian.value.relationship !== '') {
                        this.checkRelations();
                    }
                } else if (type === 'directGuardian') {
                    this.guardianStep.patchValue({
                        directGuardian: {
                            firstName: result.data.firstName,
                            lastName: result.data.lastName,
                            occupation: result.data.occupation
                        }
                    });
                } else {
                    this.guardianStep.patchValue({
                        collectiveGuardian: {
                            firstName: result.data.firstName,
                            lastName: result.data.lastName,
                        }
                    });
                }
            }
        });
    }

    getCitizenship() {
        this.codebookService.getCitizenshipCodebook().subscribe((res) => {
            this.citizenshipOptions = res;
            this.filteredCitizenshipOptions = (this.guardianStep.get('guardian') as FormGroup).controls.citizenship.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value?.title),
                    map(title => title ? this._filter(title, AutocompleteFilterEnum.CITIZENSHIP) : this.citizenshipOptions.slice())
                );
        }
        );
    }


    checkRelations() {

        const tempSubject = { ...this.protege };
        const tempGuardian = { ...this.guardianStep.controls.guardian.value };
        tempSubject.dateOfBirth = tempSubject.dateOfBirth?.toString().replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
        if (!checkRelationshipAgeDifference(tempGuardian, tempSubject,
            this.guardianStep.controls.guardian.value.relationship.id, this.toastr)) {
            this.relationshipError = true;
        } else {
            this.relationshipError = false;
        }
    }

    checkOccupation(type: string) {
        let step;

        if (type === 'guardian') {
            step = this.guardianStep.value.guardian;
        } else {
            step = this.guardianStep.value.directGuardian;
        }
        if (step.occupation !== undefined && step.occupation !== null
            && step.occupation !== '') {
            const choosedValue = step.occupation;

            var result = this.occupationOptions.find(value => {
                if (choosedValue.title === undefined) {
                    if (value.title === choosedValue) {
                        return value;
                    }
                } else {
                    if (value.title === choosedValue.title) {
                        return value;
                    }
                }
            });

            if (result === undefined) {
                (this.guardianStep.get(type) as FormGroup).controls.occupation.setErrors({ notValid: true });
            } else {
                (this.guardianStep.get(type) as FormGroup).controls.occupation.setValue(result);
                (this.guardianStep.get(type) as FormGroup).controls.occupation.setErrors(null);
            }
        }
    }
}
