<container>
    <div id="legal" #legal>
        <page-intro-header title="{{ 'LEGAL_SUBJECTS.TITLE' | translate }}">
            <button *ngIf="!isUserDrugostepeni" mat-flat-button color="primary" routerLink="/new_legal_subject">
                {{ 'LEGAL_SUBJECTS.NEW_SUBJECT' | translate }}
            </button>
        </page-intro-header>
        <mat-card class="card" *ngIf="areLoaded">
            <card-header title="{{ 'LEGAL_SUBJECTS.CARD_TITLE' | translate }}">
                <div class="card__aside">
                    <mat-form-field appearance="outline" class="search">
                        <mat-label>
                            {{ 'GLOBAL.SEARCH' | translate }}
                        </mat-label>
                        <input matInput type="text" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
                            placeholder="{{'GLOBAL.SEARCH' | translate}}">
                    </mat-form-field>
                    <button mat-button color="primary" (click)="exportCsv()"
                        class="button-square button-square--large button-export">
                        <icon-file-csv matTooltip="{{ 'CSV' | translate }}" class="button-square__icon" size="24">
                        </icon-file-csv>
                    </button>
                    <button mat-button color="primary" (click)="printPDF()"
                        class="button-square button-square--large button-export">
                        <icon-file-pdf matTooltip="{{ 'PDF' | translate }}" class="button-square__icon" size="24">
                        </icon-file-pdf>
                    </button>
                </div>
            </card-header>
            <mat-chip-list #chipList class="chip-list">
                <mat-chip *ngFor="let item of searchItems" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(item)">
                    {{item.name}}
                    <mat-icon matChipRemove *ngIf="removable">
                        cancel
                    </mat-icon>
                </mat-chip>
            </mat-chip-list>
            <div class="table-responsive-wrapper">
                <table mat-table id="myTable" class="subjects__table" #myTable [dataSource]="page.content">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.ID' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.NAME' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            {{ element?.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="institutionType">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.INSTITUTION_TYPE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index"> {{ element?.institutionType?.name }} </td>
                    </ng-container>
                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.ADDRESS' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element?.address?.street }} {{ element?.address?.number }}
                            / {{ element?.address?.subnumber }}</td>
                    </ng-container>
                    <ng-container matColumnDef="city">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.CITY' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element?.address?.town }}</td>
                    </ng-container>
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.PHONE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element?.phone }} </td>
                    </ng-container>
                    <ng-container matColumnDef="accountNumber">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.ACCOUNT_NUMBER' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element?.accountNumber }} </td>
                    </ng-container>
                    <ng-container matColumnDef="jbkbs">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.JBKBS' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element?.jbkbs }} </td>
                    </ng-container>
                    <ng-container matColumnDef="pib">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.PIB' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element?.pib }} </td>
                    </ng-container>
                    <ng-container matColumnDef="identificationNumber">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LEGAL_SUBJECTS.IDENTIFICATION_NUMBER' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element?.identificationNumber }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="subjects__row--header"></tr>
                    <tr mat-row *matRowDef="let element; let row; columns: displayedColumns"
                        (click)="onRowClicked(element)" class="subjects__row">
                    </tr>
                </table>
            </div>
            <div class="paginator-wrapper">
                <app-custom-pagination [page]="page"  (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()" 
                    (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()"  (pageSizeEvent)="getPageInNewSize($event)">
                </app-custom-pagination>    
            </div>

        </mat-card>
        <mat-card *ngIf="!areLoaded" class="card">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </mat-card>
    </div>
</container>
