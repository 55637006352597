<div (click)="toggleDropdown()" class="notifications-dropdown__backdrop"></div>
<div class="notifications-dropdown__wrapper">
  <main class="notifications-dropdown__main">
    <div
      class="notifications-dropdown__no-results"
      *ngIf="notifications.length === 0"
    >
      <no-result-item>{{
        'HOME.CARDS.NOTIFICATIONS.NO_NOTIFICATIONS' | translate
      }}</no-result-item>
    </div>
    <mat-list *ngIf="notifications.length > 0">
      <mat-list-item *ngFor="let notification of notifications; last as last">
        <h4 mat-line>
          <mat-label>
            <b>{{ notification.fromWho }}</b></mat-label
          >
        </h4>
        <h2 mat-line>{{ notification.message }}</h2>
        <span class="date">{{
          notification.date | date: 'dd.MM.yyyy. h:mm:ss'
        }}</span>
        <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
      </mat-list-item>
    </mat-list>
  </main>
  <footer class="notifications-dropdown__footer">
    <a
      class="notifications-dropdown__footer-link"
      (click)="toggleDropdown()"
      routerLink="/notifications"
    >
      {{ 'SECONDARY_NAVIGATION.SEE_ALL' | translate }}</a
    >
  </footer>
</div>
