/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { Router } from '@angular/router';
import * as myLetters from '../../letters';
import { SubjectsComponent } from 'src/app/subjects/subjects.component';
import { LegalSubjectsComponent } from 'src/app/legal-subjects/legal-subjects.component';
import { CodebookService } from 'src/services/codebook.service';
import { AlfrescoService } from 'src/services/alfresco.service';
import { MatTableDataSource } from '@angular/material/table';
import { error } from 'console';
import { title } from 'process';
/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */
interface shipingInterface {
   id: Number;
   title: string;
}

@Component({
   selector: 'sending-documents',
   templateUrl: './sending-documents.component.html',
   styleUrls: ['./sending-documents.component.scss'],
})
export class SendingDocumentsComponent implements OnInit {
   visible: boolean;

   sendingDocumentsForm: FormGroup;
   choosedShippingMethod = '';
   choosedShippingChannel = '';
   subjectId = null;
   dataSource = new MatTableDataSource<any>([]);
   receivers: any = [];
   displayedColumns: string[] = ['shippingChannel', 'shippingMethod', 'name', 'email', 'pib', 'address', 'actions'];
   /* Kanal slanja */
   shippingChannel: shipingInterface[] = [
      { id: 1, title: 'Пошта' },
      { id: 2, title: 'Е-маил' },
   ];

   /* Nacin slanja */
   shippingMethod: shipingInterface[] = [
      { id: 1, title: 'Физичко лице' },
      { id: 2, title: 'Правно лице' },
   ];
   isSubjectChosen: boolean;
   chosenSubject: any;
   sendDissabled = false;
   isSending = false;
   constructor(
      private alfrescoService: AlfrescoService,
      private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public router: Router,
      public dialogRef: MatDialogRef<SendingDocumentsComponent>,
      private fb: FormBuilder,
      private codebookService: CodebookService
   ) {
      this.visible = false;
      this.sendingDocumentsForm = this.fb.group({
         sendingMethod: ['', [Validators.required]],
         recipientType: ['', [Validators.required]],
         receiverDetails: this.fb.array([]),
      });
   }
   receiverDetails(): FormArray {
      return this.sendingDocumentsForm.get('receiverDetails') as FormArray;
   }

   ngOnInit() {}

   onChangeSendingMethod(event: any) {
      this.receiverDetails().clear();

      if (event.value.id === 1) {
         this.choosedShippingChannel = 'POST';

         if (this.isSubjectChosen) {
            this.sendingDocumentsForm.removeControl('email');
            this.choosedShippingMethod === 'INDIVIDUAL' ? this.addSubjectAddressControls() : this.addLegalSubjectAddressControls();
         }
      } else {
         this.choosedShippingChannel = 'E_MAIL';

         if (this.isSubjectChosen) {
            this.removeAddressControls();
            this.addEmailControls();
         }
      }
   }

   onChangeReceiverType(event: any) {
      if (this.isSubjectChosen) {
         this.removeControls();
      }
      if (event.value.id === 1) {
         this.choosedShippingMethod = 'INDIVIDUAL';
      } else {
         this.choosedShippingMethod = 'LEGAL';
      }
   }

   sendDocument() {
      this.isSending = true;
      const receiverDto = this.receivers;
      for (const receiver of receiverDto) {
         receiver.shippingChannel = receiver.shippingChannel.id;
         receiver.shippingMethod = receiver.shippingMethod.id;
      }

      const body = {
         caseId: this.data.document.caseId,
         caseName: this.data.document.caseName,
         documentId: this.data.document.documentId,
         receivers: receiverDto,
      };
      this.alfrescoService.documentSendExpedition(body).subscribe(
         res => {
            if (res != null) {
               this.sendDissabled = false;
               this.isSending = false;
               this.dialogRef.close({ event: 'Create', data: res });
            } else {
               this.sendDissabled = false;
               this.isSending = false;
               this.dialogRef.close({ event: 'Unsuccessful', data: res });
            }
         },
         error => {
            this.sendDissabled = false;
            this.isSending = false;
            this.dialogRef.close({ event: 'Unsuccessful' });
         }
      );
   }

   closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
   }
   importSubjectData() {
      this.isSubjectChosen = false;
      const dialogRef = this.dialog.open(SubjectsComponent, {
         panelClass: 'overlay-panel',
         width: '1200px',
         data: {
            origin: 'entrance',
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            this.isSubjectChosen = true;
            this.chosenSubject = result.data;

            this.subjectId = result.data.subjectIdentity.subjectId;

            this.sendingDocumentsForm.addControl('firstName', new FormControl('', Validators.compose([Validators.pattern(myLetters.letters)])));
            this.sendingDocumentsForm.addControl('lastName', new FormControl('', Validators.compose([Validators.pattern(myLetters.letters)])));

            this.sendingDocumentsForm.patchValue({
               firstName: result.data.firstName,
               lastName: result.data.lastName,
            });

            this.choosedShippingChannel === 'E_MAIL' ? this.addEmailControls() : this.addSubjectAddressControls();

            this.sendingDocumentsForm.markAllAsTouched();
         }
      });
   }

   importLegalSubject() {
      this.isSubjectChosen = false;
      const dialogRef = this.dialog.open(LegalSubjectsComponent, {
         panelClass: 'overlay-panel',
         width: '1200px',
         data: {
            origin: 'entrance',
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result !== undefined) {
            this.isSubjectChosen = true;
            this.chosenSubject = result.data;

            this.subjectId = result.data.id;

            this.sendingDocumentsForm.addControl('name', new FormControl(''));
            this.sendingDocumentsForm.addControl('pib', new FormControl(''));

            this.sendingDocumentsForm.patchValue({
               name: result.data.name,
               pib: result.data.pib,
            });

            this.choosedShippingChannel === 'E_MAIL' ? this.addEmailControls() : this.addLegalSubjectAddressControls();

            this.sendingDocumentsForm.markAllAsTouched();
         }
      });
   }

   addReciverToList() {
      this.isSubjectChosen = false;
      const receiver = {
         shippingChannel: this.sendingDocumentsForm.get('sendingMethod').value,
         shippingMethod: this.sendingDocumentsForm.get('recipientType').value,
         lastName: this.sendingDocumentsForm.get('lastName') === null ? '' : this.sendingDocumentsForm.get('lastName').value,
         firstName: this.sendingDocumentsForm.get('firstName') === null ? '' : this.sendingDocumentsForm.get('firstName').value,
         name: this.sendingDocumentsForm.get('name') === null ? '' : this.sendingDocumentsForm.get('name').value,
         pib: this.sendingDocumentsForm.get('pib') === null ? '' : this.sendingDocumentsForm.get('pib').value,
         subjectId: this.subjectId,
         email: this.sendingDocumentsForm.get('email') === null ? '' : this.sendingDocumentsForm.get('email').value,
         ptt: this.sendingDocumentsForm.get('ptt') === null ? '' : this.sendingDocumentsForm.get('ptt').value,
         street: this.sendingDocumentsForm.get('street') === null ? '' : this.sendingDocumentsForm.get('street').value,
         number: this.sendingDocumentsForm.get('number') === null ? '' : this.sendingDocumentsForm.get('number').value,
         subnumber: this.sendingDocumentsForm.get('subnumber') === null ? '' : this.sendingDocumentsForm.get('subnumber').value,
         city: this.sendingDocumentsForm.get('city') === null ? '' : this.sendingDocumentsForm.get('city').value,
         settlement: this.sendingDocumentsForm.get('settlement') === null ? '' : this.sendingDocumentsForm.get('settlement').value,
      };
      this.receivers.push(receiver);
      this.dataSource.data = this.receivers;
      this.choosedShippingChannel = '';
      this.choosedShippingMethod = '';
      this.sendingDocumentsForm = this.fb.group({
         sendingMethod: ['', [Validators.required]],
         recipientType: ['', [Validators.required]],
         receiverDetails: this.fb.array([]),
      });
      this.sendingDocumentsForm.controls.sendingMethod.markAsUntouched();
      this.sendingDocumentsForm.controls.recipientType.markAsUntouched();
      this.sendingDocumentsForm.controls.sendingMethod.setErrors(null);
      this.sendingDocumentsForm.controls.recipientType.setErrors(null);
   }

   removeReceiver(index: number) {
      this.receivers.splice(index, 1);
      this.dataSource.data = this.receivers;
   }

   setPrimaryReceiver(index: number) {
      const element = this.receivers[index];
      this.receivers.splice(index, 1);
      this.receivers.splice(0, 0, element);
      this.dataSource.data = this.receivers;
   }

   removeControls() {
      this.sendingDocumentsForm.removeControl('email');

      this.removeAddressControls();

      this.sendingDocumentsForm.removeControl('name');
      this.sendingDocumentsForm.removeControl('pib');

      this.sendingDocumentsForm.removeControl('firstName');
      this.sendingDocumentsForm.removeControl('lastName');

      this.isSubjectChosen = false;
   }

   addLegalSubjectAddressControls() {
      this.addAddressControls();

      this.sendingDocumentsForm.patchValue({
         ptt: this.chosenSubject.address.ptt,
         street: this.chosenSubject.address?.street,
         number: this.chosenSubject.address?.number,
         subnumber: this.chosenSubject.address?.subnumber,
         city: this.chosenSubject.address?.town,
         settlement: this.chosenSubject.address?.populatedPlace?.title,
      });
   }

   addSubjectAddressControls() {
      this.addAddressControls();

      this.sendingDocumentsForm.patchValue({
         ptt: this.chosenSubject.permanentResidence?.ptt,
         street: this.chosenSubject.permanentResidence?.street,
         number: this.chosenSubject.permanentResidence?.number,
         subnumber: this.chosenSubject.permanentResidence?.subnumber,
         city: this.chosenSubject.permanentResidence?.town,
         settlement: this.chosenSubject.permanentResidence?.populatedPlace?.title,
      });
   }

   addAddressControls() {
      this.sendingDocumentsForm.addControl('ptt', new FormControl('', Validators.compose([Validators.pattern(myLetters.pttValidator)])));
      this.sendingDocumentsForm.addControl('street', new FormControl(''));
      this.sendingDocumentsForm.addControl('number', new FormControl(''));
      this.sendingDocumentsForm.addControl('subnumber', new FormControl(''));
      this.sendingDocumentsForm.addControl('city', new FormControl(''));
      this.sendingDocumentsForm.addControl('settlement', new FormControl(''));
   }

   removeAddressControls() {
      this.sendingDocumentsForm.removeControl('ptt');
      this.sendingDocumentsForm.removeControl('street');
      this.sendingDocumentsForm.removeControl('number');
      this.sendingDocumentsForm.removeControl('subnumber');
      this.sendingDocumentsForm.removeControl('city');
      this.sendingDocumentsForm.removeControl('settlement');
   }

   addEmailControls() {
      this.sendingDocumentsForm.addControl('email', new FormControl('', Validators.compose([Validators.required, Validators.email])));

      this.sendingDocumentsForm.patchValue({
         email: this.chosenSubject.email,
      });
   }
}
