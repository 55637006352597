<header class="collapsible-case__header">
    <span [ngClass]="{
    'collapsible-case__kind': true,
    'collapsible-case__kind--red': kind === 'C',
    'collapsible-case__kind--yellow': kind === 'Ž',
    'collapsible-case__kind--white': kind === 'O',
    'collapsible-case__kind--blue': kind === 'P',
    'collapsible-case__kind--purple': kind === 'LJ',
    'collapsible-case__kind--dashedred': kind === 'IC',
    'collapsible-case__kind--dashedblue': kind === 'IP'
    }"></span>
    <div class="collapsible-case__header-text">
        <h4 class="collapsible-case__title" *ngIf="title">
            {{ title }}
        </h4>
        <time class="collapsible-case__date">
            <mat-icon class="collapsible-case__meta-icon">calendar_today</mat-icon>{{ date }}
        </time>
    </div>
    <span class="subject_name">
        {{firstName}} {{lastName}}
    </span>
    <span *ngIf="newApproving" class="circle">
    </span>
</header>