<mat-expansion-panel (opened)="(panelOpenState)" (closed)="(!panelOpenState)">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ 'GLOBAL_ADMIN.DPN.TITLE' | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <form [formGroup]="dpnForm" class="form">
    <field-holder>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'GLOBAL_ADMIN.DPN.CATEGORY' | translate }}
        </mat-label>
        <mat-select formControlName="choiceDpn" [compareWith]="compareObj" required
          (selectionChange)="selectDpn($event.value)">
          <mat-option *ngFor="let option of dpnData" [value]="option">
            {{ option.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </field-holder>
    <field-holder kind="4-cols">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'GLOBAL_ADMIN.FORM.NEW_VALUE' | translate }}</mat-label>
        <input matInput type="text" formControlName="price" autocomplete="off" required />
        <span matSuffix>{{ 'GLOBAL_ADMIN.FORM.RSD' | translate }}</span>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}</mat-label>
        <input matInput type="text" [matDatepicker]="dpnValidFrom" formControlName="validFrom" autocomplete="off"
          placeholder="{{ 'GLOBAL_ADMIN.FORM.VALID_FROM' | translate }}" required [max]="maxDate" />
        <mat-datepicker-toggle matSuffix [for]="dpnValidFrom"></mat-datepicker-toggle>
        <mat-datepicker #dpnValidFrom></mat-datepicker>
      </mat-form-field>
    </field-holder>
    <div class="table-responsive-wrapper" *ngIf="dpnCurrentValues.type !== ''">
      <table mat-table id="myTable" class="table" #myTable [dataSource]="dpnDataSource">
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'GLOBAL_ADMIN.DPN.VALUE' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.price }} {{ 'GLOBAL.RSD' | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'GLOBAL_ADMIN.DPN.VALID_FROM' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.validFrom | date : 'dd.MM.yyyy.' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="dpnDisplayedColumns" class="category__row--header"></tr>
        <tr mat-row *matRowDef="let element; let row; columns: dpnDisplayedColumns" class="category__row"></tr>
      </table>
    </div>
    <div class="pagination" *ngIf="page.content.length > 0">
      <app-custom-pagination [page]="page" (firstPageEvent)="getFirstPage()" (previousPageEvent)="getPreviousPage()"
        (nextPageEvent)="getNextPage()" (lastPageEvent)="getLastPage()" (pageSizeEvent)="getPageInNewSize($event)">
      </app-custom-pagination>
    </div>
    <form-footer>
      <button class="form-footer__button form-footer__button--primary" mat-flat-button color="primary"
        (click)="submitDPNForm(dpnForm)" [disabled]="!dpnForm.valid">
        {{ 'GLOBAL_ADMIN.FORM.SUBMIT' | translate }}
      </button>
    </form-footer>
  </form>
</mat-expansion-panel>