/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { WarningMessageComponent } from 'src/app/warning-message/warning-message.component';
import { Problem } from 'src/models/problem.model';
import { CaseService } from 'src/services/case.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { SubjectsService } from 'src/services/subjects.service';
import { ToastrImplService } from 'src/services/toastr.service';
import { UserService } from 'src/services/user.service';
import { ModalEventEnum, ModalSizeEnum } from 'src/types';
import { Page } from '../../../page';
import { SubjectNewProblemComponent } from '../subject-new-problem/subject-new-problem.component';
/**
 * @author Olja Andjelovski olja.andjelovski@iten.rs
 */
@Component({
   selector: 'subject-problems',
   templateUrl: './subject-problems.component.html',
   styleUrls: ['./subject-problems.component.scss'],
})
export class SubjectProblemsComponent implements OnInit {
   page: Page<Problem> = new Page();
   subjectId: string;
   myInput = '';
   @Input() status: any;
   displayedColumns: string[] = ['case', 'problemId', 'description', 'startDate', 'endDate', 'duration', 'levelOfSeverity', 'actions'];

   loggedIn: any;
   isMainCenterBelgrade = false;
   isUserDrugostepeni = false;

   object: any = {
      document: '',
      action: '',
      subjectId: '',
      message: '',
      title: '',
   };

   localStorageCenterId: any;
   isAdmin: boolean;
   constructor(
      public router: Router,
      private paginationService: CustomPaginationService,
      private route: ActivatedRoute,
      private subjectService: SubjectsService,
      public dialog: MatDialog,
      private localStorageService: LocalStorageService,
      private translate: TranslateService,
      private caseService: CaseService,
      private toastr: ToastrImplService,
      private userService: UserService
   ) {
      this.localStorageCenterId = this.localStorageService.get('localStorageCenterId');
      this.loggedIn = JSON.parse(this.localStorageService.get('loggedUser'));
      this.isMainCenterBelgrade = this.loggedIn?.csrId === '1' ? true : false;
      this.isUserDrugostepeni = this.userService.isUserDrugostepeni();
      this.isAdmin = this.userService.isGlobalAdmin();

      this.route.params.subscribe(params => {
         this.subjectId = params.id;
      });
   }

   ngOnInit(): void {
      this.doFilter();
   }

   getNextPage() {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.doFilter();
   }

   getPreviousPage() {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.doFilter();
   }

   getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.doFilter();
   }

   getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.doFilter();
   }

   getPageInNewSize(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.doFilter();
   }

   doFilter() {
      let x = null;
      if (this.isMainCenterBelgrade || this.isUserDrugostepeni || this.isAdmin) {
         x = this.subjectService.getFilteredProblemPage(this.page.pageable, this.subjectId, '', this.localStorageCenterId);
      } else {
         x = this.subjectService.getFilteredProblemPage(this.page.pageable, this.subjectId, '', this.loggedIn?.csrId);
      }
      x.subscribe(page => {
         this.page = page;
         this.page.pageable.pagePerShow = page.number + 1;
      });
   }

   addNewProblem() {
      const dialogRef = this.dialog.open(SubjectNewProblemComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: {
            subjectId: this.subjectId,
            problem: null,
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.toastr.success('SNACKBAR.ADDED_PROBLEM');
            this.doFilter();
         }
      });
   }

   handleOpenDialog(element: any) {
      const dialogRef = this.dialog.open(SubjectNewProblemComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.DEFAULT,
         data: {
            subjectId: this.subjectId,
            problem: element,
         },
      });

      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === 'Create') {
            this.toastr.success('SUBJECT_PROBLEMS.PROBLEM_EDITED');
            this.doFilter();
         }
      });
   }

   handleDeleteDialog(element: any) {
      this.translate.get('WARNING_DIALOG.SUBJECT_PROBLEM_DELETE.MESSAGE').subscribe((res: string) => {
         this.object.message = res;
      });
      this.translate.get('WARNING_DIALOG.SUBJECT_PROBLEM_DELETE.TITLE').subscribe((res: string) => {
         this.object.title = res;
      });
      this.object.action = ModalEventEnum.DELETE;
      const dialogRef = this.dialog.open(WarningMessageComponent, {
         disableClose: true,
         panelClass: 'overlay-panel',
         width: ModalSizeEnum.MINI,
         data: this.object,
      });
      dialogRef.afterClosed().subscribe(result => {
         const { event, data } = result;
         if (event === ModalEventEnum.DELETE && data.status) {
            const body = {
               id: element.problemId,
               subjectId: element.subjectId,
               csrId: element.csrId,
            };
            this.caseService.deleteCaseProblem(body).subscribe(res => {
               if (res === true) {
                  this.toastr.success('SUBJECT_PROBLEMS.PROBLEM_DELETED');
                  this.doFilter();
               } else {
                  this.toastr.showWarningTitle('SUBJECT_PROBLEMS.PROBLEM_NOT_DELETED_DESCRIPTION', 'SUBJECT_PROBLEMS.PROBLEM_NOT_DELETED_TITLE');
                  this.doFilter();
               }
            });
         }
      });
   }
}
