import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FosterParentService } from 'src/services/foster-parent.service';

@Component({
  selector: 'app-foster-parent-details',
  templateUrl: './foster-parent-details.component.html',
  styleUrls: ['./foster-parent-details.component.scss']
})
export class FosterParentDetailsComponent implements OnInit {
  fosterParent: any;
  fosterParentId: any;
  year: any;
  constructor(
    private route: ActivatedRoute,
    private fosterParentService: FosterParentService) {
    this.route.params.subscribe((params) => {
      this.fosterParentId = params.id;
      this.year = params.year;
    });
    this.getFosterParent();
  }

  ngOnInit(): void {
  }

  getFosterParent() {
    this.fosterParentService.findById(this.fosterParentId).subscribe((res) => {
      this.fosterParent = res;
      this.fosterParent.dateOfBirth = this.fosterParent.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
    });
  }
}
