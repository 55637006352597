<div
  [ngClass]="{
    wrapper: true,
    'wrapper--expanded': groupExpanded
  }"
>
  <h4 class="title" *ngIf="title" (click)="toggle()" matTooltip="{{ title }}">
    <mat-icon [ngClass]="{ icon: true, 'icon--expanded': groupExpanded }"
      >chevron_right</mat-icon
    >
    <span>
      {{ title }}
    </span>
  </h4>
  <ng-content></ng-content>
</div>
