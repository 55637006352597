<container kind="mini">
  <div class="homepage-wrapper">
    <header>
      <h2 class="title">{{ 'GLOBAL.WELCOME' | translate }} {{loggedUserRole}}</h2>
      <ul class="stat__list"
        *ngIf="!isMainCenterBelgrade && !userService.isUserDrugostepeni() && !userService.isGlobalAdmin()  && !userService.isCenterAdmin()">
        <li class="stat__item">
          {{ 'HOMEPAGE.HAVE' | translate }} <strong class="stat__label"> {{ open }}</strong>
          <ng-container *ngIf="open === 1; then oone; else oother">
          </ng-container>
          <ng-template #oone>
            {{ 'HOMEPAGE.OPEN1' | translate }} {{ 'HOMEPAGE.SUBJECT' | translate }}
          </ng-template>
          <ng-template #oother>
            <ng-container *ngIf="open === 0 || open > 4; then ozeroFive; else otwoFour">
            </ng-container>
            <ng-template #ozeroFive>
              {{ 'HOMEPAGE.OPEN3' | translate }} {{ 'HOMEPAGE.SUBJECTS' | translate }}
            </ng-template>
            <ng-template #otwoFour>
              {{ 'HOMEPAGE.OPEN2' | translate }} {{ 'HOMEPAGE.SUBJECTS' | translate }}
            </ng-template>
          </ng-template>
        </li>
        <li class="stat__item">
          {{ 'HOMEPAGE.HAVE' | translate }} <strong class="stat__label stat__label--urgent"> {{ unaccepted }}</strong>
          <ng-container *ngIf="unaccepted === 1; then one; else other">
          </ng-container>
          <ng-template #one>
            {{ 'HOMEPAGE.UNACCEPTED1' | translate }} {{ 'HOMEPAGE.SUBJECT' | translate }}
          </ng-template>
          <ng-template #other>
            <ng-container *ngIf="unaccepted === 0 || unaccepted > 4; then zeroFive; else twoFour">
            </ng-container>
            <ng-template #zeroFive>
              {{ 'HOMEPAGE.UNACCEPTED3' | translate }} {{ 'HOMEPAGE.SUBJECTS' | translate }}
            </ng-template>
            <ng-template #twoFour>
              {{ 'HOMEPAGE.UNACCEPTED2' | translate }} {{ 'HOMEPAGE.SUBJECTS' | translate }}
            </ng-template>
          </ng-template>
        </li>
        <ng-container *ngIf="userService.isUserManager()">
          <li class="stat__item">
            {{ 'HOMEPAGE.HAVE' | translate }} <strong class="stat__label stat__label--urgent"> {{ unassigned }}</strong>
            <ng-container *ngIf="unassigned === 1; then tone; else tother">
            </ng-container>
            <ng-template #tone>
              {{ 'HOMEPAGE.UNASSIGNED1' | translate }} {{ 'HOMEPAGE.SUBJECT' | translate }}
            </ng-template>
            <ng-template #tother>
              <ng-container *ngIf="unassigned === 0 || unassigned > 4; then tzeroFive; else ttwoFour">
              </ng-container>
              <ng-template #tzeroFive>
                {{ 'HOMEPAGE.UNASSIGNED3' | translate }} {{ 'HOMEPAGE.SUBJECTS' | translate }}
              </ng-template>
              <ng-template #ttwoFour>
                {{ 'HOMEPAGE.UNASSIGNED2' | translate }} {{ 'HOMEPAGE.SUBJECTS' | translate }}
              </ng-template>
            </ng-template>
          </li>
        </ng-container>
      </ul>
    </header>
    <hr class="separator" />
    <ng-container *ngIf="!userService.isGlobalAdmin() && !userService.isCenterAdmin()">
      <nav class="nav">
        <ul class="nav__list">
          <li class="nav__item">
            <a routerLink="/statistics" class="card__link">
              <div class="card" matRipple>
                <div class="card__icon-wrapper card__icon-wrapper--statistics">
                  <mat-icon class="card__icon">insights</mat-icon>
                </div>
                <h3 class="card__title">
                  {{ 'HOME.CARDS.STATISTICS.TITLE' | translate }}
                </h3>
                <p class="card__description">
                  {{ 'HOME.CARDS.STATISTICS.DESCRIPTION' | translate }}
                </p>
              </div>
            </a>
          </li>
          <li class="nav__item">
            <a routerLink="/subjects" (click)="onSubjectsClick()" class="card__link">
              <div class="card" matRipple>
                <div class="card__icon-wrapper card__icon-wrapper--subjects">
                  <mat-icon class="card__icon">portrait</mat-icon>
                </div>
                <h3 class="card__title">
                  {{ 'HOME.CARDS.SUBJECTS.TITLE' | translate }}
                </h3>
                <p class="card__description">
                  {{ 'HOME.CARDS.SUBJECTS.DESCRIPTION' | translate }}
                </p>
              </div>
            </a>
          </li>
          <li class="nav__item" *ngIf="!isMainCenterBelgrade && !userService.isUserDrugostepeni()&&primaryRoleStrucniRadnik">
            <a routerLink="/cases" class="card__link" (click)="openSidenav()">
              <div class="card" matRipple>
                <div class="card__icon-wrapper card__icon-wrapper--cases">
                  <mat-icon class="card__icon">inbox</mat-icon>
                </div>
                <h3 class="card__title">
                  {{ 'HOME.CARDS.CASES.TITLE' | translate }}
                </h3>
                <p class="card__description">
                  {{ 'HOME.CARDS.CASES.DESCRIPTION' | translate }}
                </p>
              </div>
            </a>
          </li>
          <li class="nav__item" *ngIf="userService.isUserDrugostepeni()">
            <a routerLink="/cases-by-classification" class="card__link">
              <div class="card" matRipple>
                <div class="card__icon-wrapper card__icon-wrapper--cases">
                  <mat-icon class="card__icon">inbox</mat-icon>
                </div>
                <h3 class="card__title">
                  {{ 'HOME.CARDS.CASES_CLASSIFICATION.TITLE' | translate }}
                </h3>
                <p class="card__description">
                  {{ 'HOME.CARDS.CASES_CLASSIFICATION.DESCRIPTION' | translate }}
                </p>
              </div>
            </a>
          </li>
          <li class="nav__item" *ngIf="!isMainCenterBelgrade && !userService.isUserDrugostepeni()">
            <a routerLink="/entrancePapers" class="card__link">
              <div class="card" matRipple>
                <div class="card__icon-wrapper card__icon-wrapper--entrance">
                  <mat-icon class="card__icon">folder_open</mat-icon>
                </div>
                <h3 class="card__title">
                  {{ 'HOME.CARDS.ENTRANCE.TITLE' | translate }}
                </h3>
                <p class="card__description">
                  {{ 'HOME.CARDS.ENTRANCE.DESCRIPTION' | translate }}
                </p>
              </div>
            </a>
          </li>
        </ul>
      </nav>
    </ng-container>
  </div>
</container>
