<mat-card class="step__card" [ngClass]="{
    step__card: true,
    'step__card--initial': stepStatus === 'INITIAL',
    'step__card--started': stepStatus === 'STARTED',
    'step__card--finished': stepStatus === 'FINISHED'
  }">
    <header class="step__header">
        <main>
            <h4 class="step__title"> {{ 'VS.VIOLENCE.TITLE' | translate }}</h4>
            <label-step-status [status]="stepStatus">

            </label-step-status>
        </main>
        <aside class="step__action" *ngIf="isEditMode !== true">
            <span matTooltip="{{'CASES.NO_REGISTER_NUMBER' | translate }}"
                [matTooltipDisabled]="editEnabled===false||noRegistrationId===false">

                <span [matTooltip]="!editEnabled ? acceptCaseMissingPermitionsTooltip : ''">
                    <button mat-stroked-button color="primary" (click)="handleInitStep()"
                        *ngIf="stepStatus === 'INITIAL'"
                        [disabled]="!editEnabled || waitingForApproval|| noRegistrationId">
                        {{ 'VS.VIOLENCE.CREATE' | translate }}
                    </button>
                </span>
            </span>
            <button mat-stroked-button color="primary" (click)="toggleEdit()" *ngIf="stepStatus === 'STARTED'"
                class="step__action--edit">
                {{ 'VS.EDIT' | translate }}
            </button>
            <button mat-flat-button color="primary" (click)="handleSaveChanges()" *ngIf="stepStatus === 'FINISHED'">
                {{ 'VS.NEW_EVALUATION_AND_ASSESSMENT.FINISH' | translate }}
            </button>
        </aside>
    </header>

    <form class="form" [formGroup]="newViolence" *ngIf="isEditMode === true">
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.ENDAGED_NETVWORK' | translate }}
                </mat-label>
                <mat-select formControlName="engagedCollaborativeNetwork" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of engagedCollaborativeNetworkCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.USEFUL_DATA_PER_PERSON' | translate }}
                </mat-label>
                <textarea matInput type="text" formControlName="usefulDataPerPerson" autocomplete="off"></textarea>
            </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.MIGRANT_STATUS' | translate }}
                </mat-label>
                <mat-select formControlName="migrantStatus" [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of migrantStatusCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.HOUSING_STATUS' | translate }}
                </mat-label>
                <mat-select formControlName="housingStatus" [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of housingStatusCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.HOUSEHOLD_FAMILY_TYPE' | translate }}
                </mat-label>
                <mat-select formControlName="householdOrFamilyType" [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of householdOrFamilyTypeCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <form-subgroup-divider></form-subgroup-divider>
        <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.VIOLENCE_CONTEXT' | translate }}
                </mat-label>
                <mat-select formControlName="violenceHappenedContext" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of violenceHappenedContextCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.RELATIONSHIP' | translate }}
                </mat-label>
                <mat-select formControlName="relationshipWithViolenceCommiter"  [compareWith]="compareLoadedObjects">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let element of relationshipViolence" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <h6 class="subgroup-title">
            {{ 'VS.VIOLENCE.CHARACTERISTICS_GENERAL_DESCRIPTION' | translate }}
        </h6>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.CHARACTERISTIC_OF_SITUATION' | translate }}
                </mat-label>
                <mat-select formControlName="situationCharacteristic" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of situationCharacteristicCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.CHARACTERISTIC_OF_PERPETRATOR' | translate }}
                </mat-label>
                <mat-select formControlName="perpetratorCharacteristic" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of perpetratorCharacteristicCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.CHARACTERISTIC_OF_VICTIM' | translate }}
                </mat-label>
                <mat-select formControlName="victimCharacteristic" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of victimCharacteristicCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <field-holder kind="2-cols">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.RISK' | translate }}
                </mat-label>
                <mat-select formControlName="riskExistence" [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of riskExistenceOptions" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.RISK_LEVEL' | translate }}
                </mat-label>
                <mat-select formControlName="riskCharacteristic"
                    [required]="this.newViolence.value.riskExistence?.exists" [compareWith]="compareLoadedObjects">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let element of riskCharacteristicCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <h6 class="subgroup-title">
            {{ 'VS.VIOLENCE.NEGLECT_DESCRIPTION' | translate }}
        </h6>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.NEGLECT_DESCRIPTION' | translate }}
                </mat-label>
                <mat-select formControlName="formOfNeglect" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of formsOfNeglectCodebook" [value]="element">
                        {{ element.name + (element.title !== null ? ', ' + element.title : '') + (element.subtitle !==
                        null ? ', ' + element.subtitle : '')}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <h6 class="subgroup-title">
            {{ 'VS.VIOLENCE.FORMS_OF_VIOLENCE_DESCRIPTION' | translate }}
        </h6>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.FORMS_OF_VIOLENCE_DESCRIPTION' | translate }}
                </mat-label>
                <mat-select formControlName="formOfViolence" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of formsOfViolenceCodebook" [value]="element">
                        {{ element.title + (element.subtitle !== null ? ', ' + element.subtitle : '')}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <h6 class="subgroup-title">
            {{ 'VS.VIOLENCE.FORMS_OF_SEXUAL_VIOLENCE_DESCRIPTION' | translate }}
        </h6>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.FORMS_OF_SEXUAL_VIOLENCE_DESCRIPTION' | translate }}
                </mat-label>
                <mat-select formControlName="formOfSexualViolence" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of formsOfSexualViolence" [value]="element">
                        {{ element.name + (element.title !== null ? ', ' + element.title : '') + (element.subtitle !==
                        null ? ', ' + element.subtitle : '') +
                        (element.description !== null ? ', ' + element.description : '') }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <h6 class="subgroup-title">
            {{ 'VS.VIOLENCE.FORMS_OF_EMOTIONAL_VIOLENCE_DESCRIPTION' | translate }}
        </h6>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.FORMS_OF_EMOTIONAL_VIOLENCE_DESCRIPTION' | translate }}
                </mat-label>
                <mat-select formControlName="formOfEmotionalViolence" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of formsOfEmotionalCodebook" [value]="element">
                        {{ element.name + (element.title !== null ? ', ' + element.title : '') + (element.subtitle !==
                        null ? ', ' + element.subtitle : '') }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <h6 class="subgroup-title">
            {{ 'VS.VIOLENCE.EXPLOITATION_CHILD_DESCRIPTION' | translate }}
        </h6>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.EXPLOITATION_CHILD_DESCRIPTION' | translate }}
                </mat-label>
                <mat-select formControlName="exploitationChild" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of exploitationChildCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <h6 class="subgroup-title">
            {{ 'VS.VIOLENCE.ECONOMIC_VIOLENCE_DESCRIPTION' | translate }}
        </h6>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.ECONOMIC_VIOLENCE_DESCRIPTION' | translate }}
                </mat-label>
                <mat-select formControlName="economicViolence" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of economicViolenceCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>
        <h6 class="subgroup-title">
            {{ 'VS.VIOLENCE.BULLYING_DESCRIPTION' | translate }}
        </h6>
        <field-holder>
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'VS.VIOLENCE.BULLYING_DESCRIPTION' | translate }}
                </mat-label>
                <mat-select formControlName="bullying" multiple [compareWith]="compareLoadedObjects">
                    <mat-option *ngFor="let element of bullyingCodebook" [value]="element">
                        {{ element.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </field-holder>

        <form-footer>
            <button mat-stroked-button color="primary" type="button" (click)="closeForm()"
                class="form-footer__button form-footer__button--secondary">
                {{ 'VS.CANCEL' | translate }}
            </button>
            <button mat-flat-button color="primary" type="button" (click)="printPreview()"
                class="form-footer__button form-footer__button--secondary">
                {{ 'VS.PREVIEW' | translate }}
            </button>
            <button mat-flat-button color="primary" (click)="saveCurrentData()" [disabled]="!newViolence.valid"
                type="button" class="form-footer__button form-footer__button--secondary">
                {{ 'VS.SAVE' | translate }}
            </button>
            <button mat-flat-button color="primary" (click)="saveViolenceForm()" [disabled]="!newViolence.valid"
                type="button" class="form-footer__button form-footer__button--primary">
                {{ 'VS.SAVE_AND_FINISH' | translate }}
            </button>
        </form-footer>
    </form>
</mat-card>