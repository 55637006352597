/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tracking-list-item',
  templateUrl: './tracking-list-item.component.html',
  styleUrls: ['./tracking-list-item.component.scss']
})
export class TrackingListItemComponent implements OnInit {
  @Input() id: string;
  @Input() caseId: string;
  @Input() caseName: string;
  @Input() activity: string;
  @Input() description: string;
  @Input() date: Date;
  @Input() author: string;
  @Input() isEditable: boolean;
  @Input() activityId: string;
  @Output() handleClick = new EventEmitter();
  @Output() handleEdit = new EventEmitter();
  @Output() handleDelete = new EventEmitter();

  constructor() { }

  handleItemClick(): void {
    // Open tracking item (handled by parent)
    this.handleClick.next();
  }

  editCallback(): void {
    // Edit tracking item (handled by parent)
    this.handleEdit.next();
  }

 deleteCallback(): void {
    // Delete tracking item (handled by parent)
    this.handleDelete.next();
  }

  ngOnInit(): void {
  }

}
