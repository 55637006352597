<div>
  <collapsible-group title="{{
          'SUMISSIONS.TITLE' | translate
        }}" [hidden]="!isUserEntrancer">
    <div *ngFor="let submission of submissions">
      <app-submission-item
        [link]="'submissions/entrancePaper/' + submission.id"
        [title]="submission.documentNumber" [date]="submission.dateOfCreation" [isExternal]="">
      </app-submission-item>
    </div>
  </collapsible-group>
</div>
