/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PreviewDocumentComponent } from 'src/app/sidebar-widget/preview-document/preview-document.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ModalEventEnum } from 'src/types';
import { AlfrescoService } from 'src/services/alfresco.service';

@Component({
   selector: 'initial-assessment-plan',
   templateUrl: './initial-assessment-plan.component.html',
   styleUrls: ['./initial-assessment-plan.component.scss'],
})
export class InitialAssessmentPlanComponent implements OnInit {
   @Input() data: any = {}; // TODO: Type me
   @Input() editEnabled: boolean;
   @Input() isCaseClosed: boolean;
   @Input() waitingForApproval: boolean;
   @Input() noRegistrationId: boolean;

   isEditMode = false;
   @Output() handleSubmitData = new EventEmitter();
   @Output() handleCloseCaseData = new EventEmitter();
   stepStatus: 'INITIAL' | 'STARTED' | 'FINISHED' = 'INITIAL';
   initialAssesmentPlan: FormGroup;
   @Input() initialAssesmentPlans: any = [];
   showDocumentFlow = false;
   documentUploadStatus = false; // TODO: Check note at the bottom of file
   acceptCaseMissingPermitionsTooltip = '';
   object: any = {
      document: '',
      action: '',
      subjectId: '',
      message: '',
      title: '',
   };
   @ViewChild('file') file: ElementRef<HTMLInputElement>;

   constructor(private formBuilder: FormBuilder, private translate: TranslateService, public dialog: MatDialog, private alfrescoService: AlfrescoService) {
      this.translate.get('GLOBAL.TOOLTIPS.ACCEPT_CASE_MISSING_PERMITIONS').subscribe((res: string) => {
         this.acceptCaseMissingPermitionsTooltip = res;
      });
   }

   ngOnInit(): void {
      this.initialAssesmentPlan = this.formBuilder.group({
         type: ['', []],
         assesmentText: ['', [Validators.required]],
      });
      this.setConditionalValidators();
   }

   ngOnChanges(changes: any): void {
      if (changes.initialAssesmentPlans && changes.initialAssesmentPlans.currentValue) {
         if (this.initialAssesmentPlans.length !== 0) {
            this.stepStatus = 'STARTED';
         }
      }
   }
   setConditionalValidators() {
      this.initialAssesmentPlan.get('type').valueChanges.subscribe(item => {
         this.showDocumentFlow = true;
      });
   }

   handleInitStep() {
      this.isEditMode = true;
   }

   handleSaveChanges() {
      if (
         this.initialAssesmentPlan.controls.assesmentText.value !== undefined &&
         this.initialAssesmentPlan.controls.assesmentText.value !== null &&
         this.initialAssesmentPlan.controls.assesmentText.value !== ''
      ) {
         this.stepStatus = 'STARTED';
         this.isEditMode = false;
         this.initialAssesmentPlan.controls.type.setValue(environment.vsDocumentInitialAssessmentPlan);
         this.handleSubmitData.emit({ step: 'STEP_01', data: this.initialAssesmentPlan.value });
         this.initialAssesmentPlan.reset();
      }
   }
   handleCloseCase() {
      this.handleCloseCaseData.emit({ step: 'STEP_01', status: true });
   }

   toggleEdit() {
      this.isEditMode = !this.isEditMode;
      if (this.isEditMode === false) {
         this.initialAssesmentPlan.reset();
      }
   }

   openDialog(action: 'view' | 'cancel' | 'download' | 'print', obj: any) {
      this.object.document = obj;
      this.object.action = action;

      if (action === ModalEventEnum.VIEW) {
         const dialogRef = this.dialog.open(PreviewDocumentComponent, {
            panelClass: 'overlay-panel',
            disableClose: true,
            data: this.object,
         });

         dialogRef.afterClosed().subscribe(() => {});
      } else if (action === ModalEventEnum.DOWNLOAD) {
         this.alfrescoService.downloadDocument(obj.documentId, obj.name, obj.format).subscribe(result => {
            const file = new Blob([result], { type: result.type });
            const fileURL = URL.createObjectURL(file);
            const anchor = document.createElement('a');
            anchor.download = obj.name;
            anchor.href = fileURL;
            anchor.click();
         });
      } else if (action === ModalEventEnum.PRINT) {
         this.alfrescoService.printDocument(obj.documentId, obj.name);
      }
   }
}
