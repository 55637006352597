import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEnum } from 'src/app/entrance/types';
import { getNumberForShow, getNumberFromString } from 'src/app/utils/formatLocalNumber';
import { CodebookService } from 'src/services/codebook.service';

@Component({
  selector: 'app-add-category-price',
  templateUrl: './add-category-price.component.html',
  styleUrls: ['./add-category-price.component.scss']
})
export class AddCategoryPriceComponent implements OnInit {

  categoryPricesForm: FormGroup;
  accommodationCategoryCodebook: any;
  isEdit: boolean;
  editRowData: any;
  minToDate: Date;
  maxDate: Date = new Date(2999, 11, 31);

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddCategoryPriceComponent>,
    private codebookService: CodebookService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.isEdit = this.data.isEdit;
    this.editRowData = this.data.rowData;

    this.createForm();
    this.getAccommodationCategoryCodebook();

    this.categoryPricesForm.get('validFrom').valueChanges.subscribe((fromDate: Date) => {
      this.minToDate = fromDate;
    });
  }
  private createForm() {
    this.categoryPricesForm = this.formBuilder.group({
      accommodationCategory: ['', []],
      price: ['', [Validators.pattern('^[0-9.,]*$')]],
      pricePerDay: ['', [Validators.pattern('^[0-9.,]*$')]],
      validFrom: ['', [Validators.required]],
      validTo: ['', []]
    });

    if (this.editRowData) {
        const categoryTxt =
            `(${this.editRowData?.accommodationCategoryCodebook?.code}) ${this.editRowData?.accommodationCategoryCodebook?.title}`;
        this.categoryPricesForm.controls.price.setValue(this.editRowData.price);
        this.categoryPricesForm.controls.pricePerDay.setValue(this.editRowData.pricePerDay);
        this.categoryPricesForm.controls.accommodationCategory.setValue(categoryTxt);
        this.categoryPricesForm.controls.validFrom.setValue(this.editRowData.validFrom);
        this.categoryPricesForm.controls.validTo.setValue(this.editRowData.validTo);
    }
  }

  closeDialog() {
      this.dialogRef.close({ event: EventEnum.CANCEL });
  }
  checkPrice(form){
    const amount = getNumberForShow(form.value); 
    if(amount) form.setValue(amount);
  }
  submitForm() {
      let formValue = this.categoryPricesForm;
      formValue.setValue({
        ...formValue.value, 
        price: getNumberFromString(formValue.value.price), 
        pricePerDay: getNumberFromString(formValue.value.pricePerDay)
      }
      );
      this.dialogRef.close({ event: EventEnum.SUBMIT, data: formValue.value });
  }

  getAccommodationCategoryCodebook() {
    this.codebookService.getAccommodationCategoryCodebookNotSupport().subscribe((res) => {
      this.accommodationCategoryCodebook = res;
    });
  }

  compareObj(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id;
  }
}
