import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TasksComponent } from '../../../form5/tasks/tasks.component';
import { CodebookService } from 'src/services/codebook.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { getNumberForShow } from 'src/app/utils/formatLocalNumber';

enum AutocompleteFilterEnum {
  PIOUNITS = 'pioUnits',
}

@Component({
  selector: 'app-liabilities-per-other-revenue',
  templateUrl: './liabilities-per-other-revenue.component.html',
  styleUrls: ['./liabilities-per-other-revenue.component.scss']
})
export class LiabilitiesPerOtherRevenueComponent implements OnInit {

  otherRevenuesForm: FormGroup;
  pioUnits: any = [];
  pioUnitsFiltered: Observable<any[]>;
  incomeTypes: any = [];
  maxDate: Date = new Date(2999, 11, 31);

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<TasksComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private codebookService: CodebookService) {
                this.getPioUnits();
                this.getIncomeTypes();
  }

  ngOnInit(): void {
    this.otherRevenuesForm = this.formBuilder.group({
      revenueKind: [''],
      branchesPioFundCodebook: [null],
      checkNumber: [''],
      revenueAmount: [''],
      startDate:[null]
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  add() {
    this.dialogRef.close({ event: 'Create', data: this.otherRevenuesForm.value });
  }

  
  autoSelectItem(){
    return autoSelect(this.pioUnits, 'branchesPioFundCodebook', 'name', this.otherRevenuesForm);
  }

  getPioUnits() {
    this.codebookService.getBranchesPioFundCodebook().subscribe((res) => {
      this.pioUnits = res;
      this.pioUnitsFiltered = this.otherRevenuesForm.controls.branchesPioFundCodebook.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name),
          map(name => name ? this._filter(name, AutocompleteFilterEnum.PIOUNITS) : this.pioUnits.slice())
        );
    });
  }

  getIncomeTypes() {
    this.codebookService.getTypesOfIncomeCodebook().subscribe( result => {
      this.incomeTypes = result;
    });
  }

  /**
   * _filter
   * @param value input value
   * @param what which filter should be applied (check AutocompleteFilterEnum)
   */
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
      case AutocompleteFilterEnum.PIOUNITS:
        return sortByFirstLetter(this.pioUnits.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
          ),filterValue, 'name');
      default:
        break;

    }
  }

  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.branchTypeCodebook.title + ' ' + objectValue.name : '';
  }

  checkAmount() {
    this.otherRevenuesForm.controls.revenueAmount.setValue(getNumberForShow(this.otherRevenuesForm.controls.revenueAmount.value));
 }
}
