<container kind="default">
    <page-intro-header back="/proteges" title="{{ 'PROTEGE_DETAILS.TITLE' | translate }}">
    </page-intro-header>
</container>
<container kind="default">
    <mat-card class="card">
        <definition-list>
            <definition-item *ngIf="protege?.ordinalNumber"
                key="{{ 'PROTEGE_DETAILS.R_BR' | translate }}">
                {{ protege.ordinalNumber }}</definition-item>
            <definition-item *ngIf="protege?.subjectId" key="{{ 'PROTEGE_DETAILS.ID' | translate }}">
                {{ protege.subjectId }}</definition-item>
            <definition-item *ngIf="protege?.firstName" key="{{ 'PROTEGE_DETAILS.FIRST_NAME' | translate }}">
                {{ protege.firstName }}</definition-item>
            <definition-item *ngIf="protege?.lastName" key="{{ 'PROTEGE_DETAILS.LAST_NAME' | translate }}">
                {{ protege.lastName }}</definition-item>
            <definition-item *ngIf="protege?.fatherFirstName" key="{{ 'PROTEGE_DETAILS.PARENT_NAME' | translate }}">
                {{ protege.fatherFirstName }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.parentLastName"
                key="{{ 'PROTEGE_DETAILS.PARENT_LAST_NAME' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.parentLastName }}</definition-item>
            <definition-item *ngIf="protege?.guardianshipId" key="{{ 'PROTEGE_DETAILS.GUARDIANSHIP_ID' | translate }}">
                {{ protege.guardianshipId }}</definition-item>
            <definition-item *ngIf="protege?.guardianshipType?.title"
                key="{{ 'PROTEGE_DETAILS.GUARDIANSHIP_TYPE' | translate }}">
                {{ protege.guardianshipType.title }}</definition-item>
            <definition-item *ngIf="protege?.jmbg" key="{{ 'PROTEGE_DETAILS.JMBG' | translate }}">
                {{ protege.jmbg }}</definition-item>
            <definition-item *ngIf="protege?.dateOfBirth" key="{{ 'NEW_PROTEGE.FORM.DATE_OF_BIRTH' | translate }}">
                {{ protege.dateOfBirth }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.placeOfBirth"
                key="{{ 'PROTEGE_DETAILS.PLACE_OF_BIRTH' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.placeOfBirth }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.municipality"
                key="{{ 'PROTEGE_DETAILS.MUNICIPALITY' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.municipality }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.placeOfOrigin"
                key="{{ 'PROTEGE_DETAILS.PLACE_OF_ORIGIN' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.placeOfOrigin }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.nationality"
                key="{{ 'PROTEGE_DETAILS.NATIONALITY' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.nationality.title }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.citizenship"
                key="{{ 'PROTEGE_DETAILS.CITIZENSHIP' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.citizenship.title }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.occupation"
                key="{{ 'PROTEGE_DETAILS.OCCUPATION' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.occupation.title }}</definition-item>

            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.accomodationInfo"
                key="{{ 'PROTEGE_DETAILS.ACCOMODATION_INFO' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.accomodationInfo }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.decisionNumberDeprivation"
                key="{{ 'PROTEGE_DETAILS.DECISION_NUMBER_DEPRIVATION' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.decisionNumberDeprivation }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.decisionDateDeprivation"
                key="{{ 'PROTEGE_DETAILS.DECISION_DATE_DEPRIVATION' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.decisionDateDeprivation | date:"dd.MM.yyyy." }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.decisionMakerNameDeprivation"
                key="{{ 'PROTEGE_DETAILS.DECISION_MAKER_NAME_DEPRIVATION' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.decisionMakerNameDeprivation }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.courtDecisionContent"
                key="{{ 'PROTEGE_DETAILS.COURT_DECISION_CONTENT' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.courtDecisionContent }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.decisionNumberGuardianship"
                key="{{ 'PROTEGE_DETAILS.DECISION_NUMBER_GUARDIANSHIP' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.decisionNumberGuardianship }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.decisionDateGuardianship"
                key="{{ 'PROTEGE_DETAILS.DECISION_DATE_GUARDIANSHIP' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.decisionDateGuardianship  | date:"dd.MM.yyyy." }}</definition-item>
            <definition-item *ngIf="protege?.protegePersonCollection?.protegePersonDetails?.decisionMakerNameGuardianship"
                key="{{ 'PROTEGE_DETAILS.DECISION_MAKER_NAME_GUARDIANSHIP' | translate }}">
                {{ protege.protegePersonCollection.protegePersonDetails.decisionMakerNameGuardianship }}</definition-item>

        </definition-list>


        <card-subgroup-divider *ngIf="protege?.protegePersonCollection"></card-subgroup-divider>

        <card-header *ngIf="protege?.protegePersonCollection" title="{{ 'PROTEGE_DETAILS.PROPERTY_INFO' | translate }}">
            <span>
                <button mat-flat-button color="primary" (click)="editProtegeProperty()"> {{ 'GLOBAL.EDIT' | translate }}
                </button> </span> </card-header>
        <definition-item *ngIf="protege?.protegePersonCollection?.assetValueOnCensusDay"
            key="{{ 'NEW_PROTEGE.FORM.ASSET_VALUE_ON_CENSUS_DAY' | translate }}">
            {{ protege.protegePersonCollection.assetValueOnCensusDay }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.assetValueOnTheDayOfTerminationOfCustody"
            key="{{ 'NEW_PROTEGE.FORM.ASSET_VALUE_ON_THE_DAY_OF_TERMINATION_OF_CUSTODY' | translate }}">
            {{ protege.protegePersonCollection.assetValueOnTheDayOfTerminationOfCustody }}</definition-item>


        <card-subgroup-divider *ngIf="protege?.protegePersonCollection"></card-subgroup-divider>

        <card-header *ngIf="protege?.protegePersonCollection"
            title="{{ 'PROTEGE_DETAILS.GUARDIANSHIP_INFO' | translate }}">
            <span>
                <button mat-flat-button color="primary" (click)="editGuardianshipDetails()">
                    {{ 'GLOBAL.EDIT' | translate }}
                </button> </span> </card-header>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianFirstName"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_FIRST_NAME' | translate }}">
            {{ protege.protegePersonCollection.guardianFirstName }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianLastName"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_LAST_NAME' | translate }}">
            {{ protege.protegePersonCollection.guardianLastName }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianJmbg"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_JMBG' | translate }}">
            {{ protege.protegePersonCollection.guardianJmbg }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianAddress"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_ADDRESS' | translate }}">
            {{ protege.protegePersonCollection.guardianAddress }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianCitizenship?.title"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_CITIZENSHIP' | translate }}">
            {{ protege.protegePersonCollection.guardianCitizenship.title }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianNationality?.title"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_NATIONALITY' | translate }}">
            {{ protege.protegePersonCollection.guardianNationality.title }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianOccupation?.title"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_OCCUPATION' | translate }}">
            {{ protege.protegePersonCollection.guardianOccupation.title }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianRelationship?.title"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_RELATIONSHIP' | translate }}">
            {{ protege.protegePersonCollection.guardianRelationship.title }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.guardianPhone"
            key="{{ 'PROTEGE_DETAILS.GUARDIAN_PHONE' | translate }}">
            {{ protege.protegePersonCollection.guardianPhone }}</definition-item>


        <definition-item *ngIf="protege?.protegePersonCollection?.collectiveGuardianFirstName"
            key="{{ 'PROTEGE_DETAILS.COLLECTIVE_GUARDIAN_FIRST_NAME' | translate }}">
            {{ protege.protegePersonCollection.collectiveGuardianFirstName }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.collectiveGuardianLastName"
            key="{{ 'PROTEGE_DETAILS.COLLECTIVE_GUARDIAN_LAST_NAME' | translate }}">
            {{ protege.protegePersonCollection.collectiveGuardianLastName }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.collectiveGuardianTypeOfJob"
            key="{{ 'PROTEGE_DETAILS.COLLECTIVE_GUARDIAN_TYPE_OF_JOB' | translate }}">
            {{ protege.protegePersonCollection.collectiveGuardianTypeOfJob }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.collectiveGuardianProtectionName"
            key="{{ 'PROTEGE_DETAILS.COLLECTIVE_GUARDIAN_PROTECTION_NAME' | translate }}">
            {{ protege.protegePersonCollection.collectiveGuardianProtectionName }}</definition-item>

        <definition-item *ngIf="protege?.protegePersonCollection?.directGuardianFirstName"
            key="{{ 'PROTEGE_DETAILS.DIRECT_GUARDIAN_FIRST_NAME' | translate }}">
            {{ protege.protegePersonCollection.directGuardianFirstName }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.directGuardianLastName"
            key="{{ 'PROTEGE_DETAILS.DIRECT_GUARDIAN_LAST_NAME' | translate }}">
            {{ protege.protegePersonCollection.directGuardianLastName }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.directGuardianOccupation"
            key="{{ 'PROTEGE_DETAILS.DIRECT_GUARDIAN_OCCUPATION' | translate }}">
            {{ protege.protegePersonCollection.directGuardianOccupation.title }}</definition-item>

        <card-subgroup-divider></card-subgroup-divider>

        <card-header *ngIf="protege?.protegePersonCollection"
            title="{{ 'NEW_PROTEGE.TERMINATION_OF_GUARDIANSHIP.TITLE' | translate }}">
            <span>
                <button mat-flat-button color="primary" (click)="editTerminationOfGuardianship()"
                *ngIf="!(protege?.protegePersonCollection?.decisionName && protege?.protegePersonCollection?.decisionNumber &&
                        protege?.protegePersonCollection?.decisionDate && protege?.protegePersonCollection?.reasonForTermination )" >
                    {{ 'GLOBAL.EDIT' | translate }}
                </button> 
            </span>
        </card-header>
        <definition-item *ngIf="protege?.protegePersonCollection?.decisionName"
            key="{{ 'NEW_PROTEGE.FORM.DECISION_NAME' | translate }}">
            {{ protege.protegePersonCollection.decisionName }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.decisionNumber"
            key="{{ 'NEW_PROTEGE.FORM.DECISION_NUMBER_GUARDIANSHIP_TERMINATION' | translate }}">
            {{ protege.protegePersonCollection.decisionNumber }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.decisionDate"
            key="{{ 'NEW_PROTEGE.FORM.DECISION_DATE_GUARDIANSHIP_TERMINATION' | translate }}">
            {{ protege.protegePersonCollection.decisionDate | date: 'dd.MM.yyyy.' }}</definition-item>
        <definition-item *ngIf="protege?.protegePersonCollection?.terminationReasonCodebook"
            key="{{ 'NEW_PROTEGE.FORM.REASON_FOR_GUARDIANSHIP_TERMINATION' | translate }}">
            {{ protege.protegePersonCollection.terminationReasonCodebook.title }}</definition-item>

    </mat-card>




</container>