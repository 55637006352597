/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

interface ObservationOptions {
   value: any;
   viewValue: string;
}

enum ObservationEnum {
   STATEMENT = 1,
   FIELD_VISIT = 2,
   ON_SIGHT_INVESTIGATION = 3,
   WITNESS_HEARING = 4,
   OTHER_EVIDENCE = 5,
}

enum ObservationKindEnum {
   STATEMENT = 'STATEMENT',
   FIELD_VISIT = 'FIELD_VISIT',
   ON_SIGHT_INVESTIGATION = 'ON_SIGHT_INVESTIGATION',
   WITNESS_HEARING = 'WITNESS_HEARING',
   OTHER_EVIDENCE = 'OTHER_EVIDENCE',
}

interface ControlsPerObservation {
   STATEMENT: number;
   FIELD_VISIT: number;
   ON_SIGHT_INVESTIGATION: number;
   WITNESS_HEARING: number;
   OTHER_EVIDENCE: number;
}

/**
 * Return observation name via respective id
 */
const getObservationReasonById = (value: ObservationEnum): keyof typeof ObservationKindEnum => {
   switch (value) {
      case ObservationEnum.STATEMENT:
         return 'STATEMENT';
      case ObservationEnum.FIELD_VISIT:
         return 'FIELD_VISIT';
      case ObservationEnum.ON_SIGHT_INVESTIGATION:
         return 'ON_SIGHT_INVESTIGATION';
      case ObservationEnum.WITNESS_HEARING:
         return 'WITNESS_HEARING';
      case ObservationEnum.OTHER_EVIDENCE:
         return 'OTHER_EVIDENCE';
      default:
         break;
   }
};

@Component({
   selector: 'nsp-observation-step',
   templateUrl: './nsp-observation-step.component.html',
   styleUrls: ['./nsp-observation-step.component.scss']
})
export class NspObservationStepComponent implements OnInit {
   @Output() observationStepEmitter = new EventEmitter();
   @Input() observationStep: FormGroup;
   @Input() editable: boolean;
   @Input() noJmbp: boolean;
   @Input() customData: any;
   @Input() noRegistrationId: boolean;
   @Input() noAbleToWorkId: boolean;
   @Input() noJmbg: boolean;
   @Input() hasProblem: boolean;

   reasons = [];

   selectedObservations: ObservationEnum[] = [];
   controlsPerObservation: ControlsPerObservation = {
      STATEMENT: 0,
      FIELD_VISIT: 0,
      ON_SIGHT_INVESTIGATION: 0,
      WITNESS_HEARING: 0,
      OTHER_EVIDENCE: 0,
   };
   currentDate = new Date();
   maxDate: Date = new Date(2999, 11, 31);
   constructor(private formBuilder: FormBuilder) {
   }

   // List of options for 1st step
   observationList: ObservationOptions[] = [
      { value: ObservationEnum.STATEMENT, viewValue: 'Изјава странке' },
      { value: ObservationEnum.FIELD_VISIT, viewValue: 'Теренска посета породици' },
      { value: ObservationEnum.ON_SIGHT_INVESTIGATION, viewValue: 'Увиђај на лицу места' },
      { value: ObservationEnum.WITNESS_HEARING, viewValue: 'Саслушања сведока' },
      { value: ObservationEnum.OTHER_EVIDENCE, viewValue: 'Других доказа' },
   ];

   ngOnInit(): void {
      this.initializeAll();

   }

   ngOnChanges(changes: any) {
      if (changes.customData !== undefined && changes.customData !== null) {
         if (changes.customData.currentValue !== null) {
            if (Object.keys(changes.customData.currentValue).length !== 0) {
               if (changes.customData && changes.customData.currentValue) {
                  this.patchValues(null);
               }
            }
         }
      }

   }

   initializeAll() {
      this.controlsPerObservation = {
         STATEMENT: 0,
         FIELD_VISIT: 0,
         ON_SIGHT_INVESTIGATION: 0,
         WITNESS_HEARING: 0,
         OTHER_EVIDENCE: 0,
      };

      this.selectedObservations = [];
      this.reasons = [];
      this.currentDate = new Date();
      this.observationStep = this.formBuilder.group({
         observationReason: [''],
         statementObservations: new FormArray([]),
         fieldVisitObservations: new FormArray([]),
         onSightInvestigationObservations: new FormArray([]),
         witnessHearingObservations: new FormArray([]),
         otherEvidenceObservations: new FormArray([]),
      });
      this.setObservationStepConditionalFields();
   }

   compareObservationsObjects(object1: any, object2: any) {
      return object1 === object2;
   }

   /**
    * Render dynamic fields depending on the selected option from the `observationReason` field
    */
   setObservationStepConditionalFields() {
      this.observationStep
         .get('observationReason')
         .valueChanges.subscribe((reasons: ObservationEnum[]) => {
            // 1. Establish which options are selected
            // 2. Compare the values with previously selected options and distinguish removed and new items
            const removedReasonIds = this.selectedObservations.filter((id: ObservationEnum) => !reasons.includes(id));
            const newReasonIds = reasons.filter((id: ObservationEnum) => !this.selectedObservations.includes(id));

            // If there are removed options from the current state:
            // 1. Reset controls count in internal state
            // 2. Update selected observations in internal state
            // 3. Reset form control
            if (removedReasonIds.length > 0) {
               removedReasonIds.forEach((id: ObservationEnum) => {
                  this.controlsPerObservation[getObservationReasonById(id)] = 0;
                  this.selectedObservations = this.selectedObservations.filter(reason => reason !== id);

                  this.resetFormControlById(id);
               });
            }

            // If there are new selected options:
            // 1. Push selected observations to internal state
            // 2. Add new form control for respective observation
            if (newReasonIds.length > 0) {
               newReasonIds.forEach((id: ObservationEnum) => {
                  this.selectedObservations = [...new Set([...this.selectedObservations, id])]; // push unique array (removed duplicates)
                  this.addFormControl(getObservationReasonById(id), false);
               });
            }
         });
   }

   /**
    * Convenience getters for easy access to form fields
    */
   get f() { return this.observationStep.controls; }
   get statementObservationsGetter() { return this.f.statementObservations as FormArray; }
   get fieldVisitObservationsGetter() { return this.f.fieldVisitObservations as FormArray; }
   get onSightInvestigationObservationsGetter() { return this.f.onSightInvestigationObservations as FormArray; }
   get witnessHearingObservationsGetter() { return this.f.witnessHearingObservations as FormArray; }
   get otherEvidenceObservationsGetter() { return this.f.otherEvidenceObservations as FormArray; }

   /**
    * Introduce new form controls depending on observation kind
    */
   addFormControl(kind: keyof typeof ObservationKindEnum, increment) {

      if (this.customData !== undefined && this.customData !== null) {
         if (kind === ObservationKindEnum.FIELD_VISIT) {
            if (this.controlsPerObservation[kind] === this.customData.fieldVisits.length) {
               this.controlsPerObservation[kind] = this.observationStep.get('fieldVisitObservations').value.length;
               if (this.controlsPerObservation[kind] < 3 ||
                  this.controlsPerObservation[kind] < this.observationStep.get('fieldVisitObservations').value.length) {
                  this.controlsPerObservation[kind]++;
               }
            } else {
               if (this.observationStep.get('fieldVisitObservations').value.length < this.customData.fieldVisits.length) {
                  this.controlsPerObservation[kind] = this.customData.fieldVisits.length;
               } else {
                  this.controlsPerObservation[kind] = this.observationStep.get('fieldVisitObservations').value.length + 1;
               }
            }
         } else if (kind === ObservationKindEnum.ON_SIGHT_INVESTIGATION) {
            if (this.controlsPerObservation[kind] === this.customData.onSightInvestigation.length) {
               this.controlsPerObservation[kind] = this.observationStep.get('onSightInvestigationObservations').value.length;
               if (this.controlsPerObservation[kind] < 3 ||
                  this.controlsPerObservation[kind] < this.observationStep.get('onSightInvestigationObservations').value.length) {
                  this.controlsPerObservation[kind]++;
               }
            } else {
               if (this.observationStep.get('onSightInvestigationObservations').value.length < this.customData.onSightInvestigation.length) {
                  this.controlsPerObservation[kind] = this.customData.onSightInvestigation.length;
               } else {
                  this.controlsPerObservation[kind] = this.observationStep.get('onSightInvestigationObservations').value.length + 1;
               }
            }
         } else if (kind === ObservationKindEnum.OTHER_EVIDENCE) {
            if (this.controlsPerObservation[kind] === this.customData.otherEvidence.length) {
               this.controlsPerObservation[kind] = this.observationStep.get('otherEvidenceObservations').value.length;
               if (this.controlsPerObservation[kind] < 3 ||
                  this.controlsPerObservation[kind] < this.observationStep.get('otherEvidenceObservations').value.length) {
                  this.controlsPerObservation[kind]++;
               }
            } else {
               if (this.observationStep.get('otherEvidenceObservations').value.length < this.customData.otherEvidence.length) {
                  this.controlsPerObservation[kind] = this.customData.otherEvidence.length;
               } else {
                  this.controlsPerObservation[kind] = this.observationStep.get('otherEvidenceObservations').value.length + 1;
               }
            }
         } else if (kind === ObservationKindEnum.STATEMENT) {
            if (this.controlsPerObservation[kind] === this.customData.statements.length) {
               this.controlsPerObservation[kind] = this.observationStep.get('statementObservations').value.length;
               if (this.controlsPerObservation[kind] < 3 ||
                  this.controlsPerObservation[kind] < this.observationStep.get('statementObservations').value.length) {
                  this.controlsPerObservation[kind]++;
               }
            } else {
               if (this.observationStep.get('statementObservations').value.length < this.customData.statements.length) {
                  this.controlsPerObservation[kind] = this.customData.statements.length;
               } else {
                  this.controlsPerObservation[kind] = this.observationStep.get('statementObservations').value.length + 1;
               }
            }
         } else if (kind === ObservationKindEnum.WITNESS_HEARING) {
            if (this.controlsPerObservation[kind] === this.customData.witnessHearing.length) {
               this.controlsPerObservation[kind] = this.observationStep.get('witnessHearingObservations').value.length;
               if (this.controlsPerObservation[kind] < 3 ||
                  this.controlsPerObservation[kind] < this.observationStep.get('witnessHearingObservations').value.length) {
                  this.controlsPerObservation[kind]++;
               }
            } else {
               if (this.observationStep.get('witnessHearingObservations').value.length < this.customData.witnessHearing.length) {
                  this.controlsPerObservation[kind] = this.customData.witnessHearing.length;
               } else {
                  this.controlsPerObservation[kind] = this.observationStep.get('witnessHearingObservations').value.length + 1;
               }
            }
         }
      } else {
         this.controlsPerObservation[kind]++;
      }

      switch (kind) {
         case ObservationKindEnum.STATEMENT:
            for (let i = this.statementObservationsGetter.length; i < this.controlsPerObservation[kind]; i++) {
               if (this.customData !== null && this.customData.statements.length > i) {
                  if (this.customData.statements.length === 0) {
                     this.statementObservationsGetter.push(this.formBuilder.group({
                        id: [],
                        name: ['', Validators.required],
                        date: ['', [Validators.required]]
                     }));
                  } else {
                     this.statementObservationsGetter.push(this.formBuilder.group({
                        id: [this.customData.statements[i].id],
                        name: [this.customData.statements[i].name, Validators.required],
                        date: [new Date(this.customData.statements[i].date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')), [Validators.required]]
                     }));
                  }
               } else {
                  this.statementObservationsGetter.push(this.formBuilder.group({
                     id: [],
                     name: ['', Validators.required],
                     date: ['', [Validators.required]]
                  }));
               }
            }
            break;
         case ObservationKindEnum.FIELD_VISIT:
            for (let i = this.fieldVisitObservationsGetter.length; i < this.controlsPerObservation[kind]; i++) {
               if (this.customData !== null && this.customData.fieldVisits.length > i) {
                  if (this.customData.fieldVisits.length === 0) {
                     this.fieldVisitObservationsGetter.push(this.formBuilder.group({
                        id: [],
                        date: ['', [Validators.required]]
                     }));
                  } else {
                     this.fieldVisitObservationsGetter.push(this.formBuilder.group({
                        id: [this.customData.fieldVisits[i].id],
                        date: [new Date(this.customData.fieldVisits[i]
                           .date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')), [Validators.required]]
                     }));
                  }
               } else {
                  this.fieldVisitObservationsGetter.push(this.formBuilder.group({
                     id: [],
                     date: ['', [Validators.required]]
                  }));
               }
            }
            break;
         case ObservationKindEnum.ON_SIGHT_INVESTIGATION:
            for (let i = this.onSightInvestigationObservationsGetter.length; i < this.controlsPerObservation[kind]; i++) {
               if (this.customData !== null && this.customData.onSightInvestigation.length > i) {
                  if (this.customData.onSightInvestigation.length === 0) {
                     this.onSightInvestigationObservationsGetter.push(this.formBuilder.group({
                        id: [],
                        name: ['', [Validators.required]],
                        date: ['', [Validators.required]]
                     }));
                  } else {
                     this.onSightInvestigationObservationsGetter.push(this.formBuilder.group({
                        id: [this.customData.onSightInvestigation[i].id],
                        name: [this.customData.onSightInvestigation[i].name, [Validators.required]],
                        date: [new Date(this.customData.onSightInvestigation[i].date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')),
                        [Validators.required]]
                     }));
                  }
               } else {
                  this.onSightInvestigationObservationsGetter.push(this.formBuilder.group({
                     id: [],
                     name: ['', [Validators.required]],
                     date: ['', [Validators.required]]
                  }));
               }
            }
            break;
         case ObservationKindEnum.WITNESS_HEARING:
            for (let i = this.witnessHearingObservationsGetter.length; i < this.controlsPerObservation[kind]; i++) {
               if (this.customData !== null && this.customData.witnessHearing.length > i) {
                  if (this.customData.witnessHearing.length === 0) {
                     this.witnessHearingObservationsGetter.push(this.formBuilder.group({
                        id: [],
                        name: ['', [Validators.required]],
                        date: ['', [Validators.required]]
                     }));
                  } else {
                     this.witnessHearingObservationsGetter.push(this.formBuilder.group({
                        id: [this.customData.witnessHearing[i].id],
                        name: [this.customData.witnessHearing[i].name, [Validators.required]],
                        date: [new Date(this.customData.witnessHearing[i].date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')),
                        [Validators.required]]
                     }));
                  }
               } else {
                  this.witnessHearingObservationsGetter.push(this.formBuilder.group({
                     id: [],
                     name: ['', [Validators.required]],
                     date: ['', [Validators.required]]
                  }));
               }
            }
            break;
         case ObservationKindEnum.OTHER_EVIDENCE:
            for (let i = this.otherEvidenceObservationsGetter.length; i < this.controlsPerObservation[kind]; i++) {
               if (this.customData !== null && this.customData.otherEvidence.length > i) {
                  if (this.customData.otherEvidence.length === 0) {
                     this.otherEvidenceObservationsGetter.push(this.formBuilder.group({
                        id: [],
                        name: ['', [Validators.required]],
                     }));
                  } else {
                     this.otherEvidenceObservationsGetter.push(this.formBuilder.group({
                        id: [this.customData.otherEvidence[i].id],
                        name: [this.customData.otherEvidence[i].name, [Validators.required]],
                     }));
                  }
               } else {
                  this.otherEvidenceObservationsGetter.push(this.formBuilder.group({
                     id: [],
                     name: ['', [Validators.required]],
                  }));
               }
            }
            break;
         default:
            break;
      }
   }

   /**
    * Reset form controls for related observation kind
    */
   resetFormControlById = (id: ObservationEnum) => {
      switch (id) {
         case ObservationEnum.STATEMENT:
            this.statementObservationsGetter.clear();
            break;
         case ObservationEnum.FIELD_VISIT:
            this.fieldVisitObservationsGetter.clear();
            break;
         case ObservationEnum.ON_SIGHT_INVESTIGATION:
            this.onSightInvestigationObservationsGetter.clear();
            break;
         case ObservationEnum.WITNESS_HEARING:
            this.witnessHearingObservationsGetter.clear();
            break;
         case ObservationEnum.OTHER_EVIDENCE:
            this.otherEvidenceObservationsGetter.clear();
            break;
         default:
            break;
      }
   }

   /**
    * Remove form control from the form builder
    */
   removeFormControl(kind: keyof typeof ObservationKindEnum, index: ObservationEnum) {
      // Reduce control count per observation kind
      this.controlsPerObservation[kind] = this.controlsPerObservation[kind] - 1;

      switch (kind) {
         case ObservationKindEnum.STATEMENT:
            this.statementObservationsGetter.removeAt(index);
            break;
         case ObservationKindEnum.FIELD_VISIT:
            this.fieldVisitObservationsGetter.removeAt(index);
            break;
         case ObservationKindEnum.ON_SIGHT_INVESTIGATION:
            this.onSightInvestigationObservationsGetter.removeAt(index);
            break;
         case ObservationKindEnum.WITNESS_HEARING:
            this.witnessHearingObservationsGetter.removeAt(index);
            break;
         case ObservationKindEnum.OTHER_EVIDENCE:
            this.otherEvidenceObservationsGetter.removeAt(index);
            break;
         default:
            break;
      }
   }

   /**
    * Add extra class to observation reason sub section on 1st step
    */
   shouldAddUIBorderToObservationGroup(index: number): string {
      if (this.observationStep.get('observationReason').value.some((val: number) => val > index)) {
         return 'observation__group';
      } else {
         return 'observation__group observation__group--is-last';
      }
   }

   /**
    * Send step information to parent component
    */
   updateObservationStep() {
      if (this.observationStep.value.fieldVisitsObservations === undefined) {
         this.observationStep.value.fieldVisitsObservations = [];
      }
      if (this.observationStep.value.onSightInvestigationObervations === undefined) {
         this.observationStep.value.onSightInvestigationObervations = [];
      }
      if (this.observationStep.value.statementObservations === undefined) {
         this.observationStep.value.statementObservations = [];
      }
      if (this.observationStep.value.witnessHearingObservations === undefined) {
         this.observationStep.value.witnessHearingObservations = [];
      }
      if (this.observationStep.value.otherEvidenceObservations === undefined) {
         this.observationStep.value.otherEvidenceObservations = [];
      }

      this.observationStepEmitter.emit(this.observationStep);
   }


   patchValues(data, saveData?) {
      if (data !== null) {
         this.customData = data;
      }
      if (this.customData.fieldVisits?.length > 0) {
         this.reasons.push(2);
         for (const element of this.customData.fieldVisits) {
            element.name = element.name === null ? '' : element.name;
            element.date = element.date === null ? '' : element.date;
         }
         // this.addFormControl(getObservationReasonById(2));
      }

      if (this.customData.onSightInvestigation?.length > 0) {
         this.reasons.push(3);
         for (const element of this.customData.onSightInvestigation) {
            element.name = element.name === null ? '' : element.name;
            element.date = element.date === null ? '' : element.date;
         }
         // this.addFormControl(getObservationReasonById(3));
      }

      if (this.customData.statements?.length > 0) {
         this.reasons.push(1);
         for (const element of this.customData.statements) {
            element.name = element.name === null ? '' : element.name;
            element.date = element.date === null ? '' : element.date;
         }
         // this.addFormControl(getObservationReasonById(0));
      }

      if (this.customData.witnessHearing?.length > 0) {
         this.reasons.push(4);
         for (const element of this.customData.witnessHearing) {
            element.name = element.name === null ? '' : element.name;
            element.date = element.date === null ? '' : element.date;
         }
         // this.addFormControl(getObservationReasonById(4));
      }

      if (this.customData.otherEvidence?.length > 0) {
         this.reasons.push(5);
         for (const element of this.customData.otherEvidence) {
            element.name = element.name === null ? '' : element.name;
            element.date = element.date === null ? '' : element.date;
         }
         // this.addFormControl(getObservationReasonById(5));
      }
      this.observationStep.patchValue({
         observationReason: this.reasons
      });
      if (saveData !== null) {
         this.setIds();
      }

   }
   setIds() {
      for (let i = 0; i < this.customData.statements?.length; i++) {
         (this.observationStep.controls.statementObservations as any).controls[i].controls.id.setValue(this.customData.statements[i].id);
      }
      for (let i = 0; i < this.customData.fieldVisits?.length; i++) {
         (this.observationStep.controls.fieldVisitObservations as any).controls[i].controls.id.setValue(this.customData.fieldVisits[i].id);
      }
      for (let i = 0; i < this.customData.onSightInvestigation?.length; i++) {
         (this.observationStep.controls.onSightInvestigationObservations as any).controls[i]
            .controls.id.setValue(this.customData.onSightInvestigation[i].id);
      }
      for (let i = 0; i < this.customData.witnessHearing?.length; i++) {
         (this.observationStep.controls.witnessHearingObservations as any).controls[i]
            .controls.id.setValue(this.customData.witnessHearing[i].id);
      }
      for (let i = 0; i < this.customData.otherEvidence?.length; i++) {
         (this.observationStep.controls.otherEvidenceObservations as any).controls[i]
            .controls.id.setValue(this.customData.otherEvidence[i].id);
      }
   }
}