import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TasksComponent } from '../../../form5/tasks/tasks.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CodebookService } from 'src/services/codebook.service';
import { startWith, map } from 'rxjs/operators';
import { autoSelect, sortByFirstLetter } from 'src/app/utils/autoSelect';
import { getNumberForShow } from 'src/app/utils/formatLocalNumber';

enum AutocompleteFilterEnum {
  PIOUNITS = 'pioUnits',
}

@Component({
  selector: 'app-pension-liabilities',
  templateUrl: './pension-liabilities.component.html',
  styleUrls: ['./pension-liabilities.component.scss']
})
export class PensionLiabilitiesComponent implements OnInit {

  pensionForm: FormGroup;
  pioUnits: any = [];
  pioUnitsFiltered: Observable<any[]>;
  pensionTypes: any = [];
  maxDate: Date = new Date(2999, 11, 31);

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<TasksComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private codebookService: CodebookService) {
                this.getPioUnits();
                this.getPensionTypes();
  }

  ngOnInit(): void {
    this.pensionForm = this.formBuilder.group({
      typesOfPensionCodebook: [''],
      branchesPioFundCodebook: [null],
      checkNumber: [],
      pensionAmount: [],
      startDate:[null]
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  addNewPension() {
    this.dialogRef.close({ event: 'Create', data: this.pensionForm.value });
  }

  getPioUnits() {
    this.codebookService.getBranchesPioFundCodebook().subscribe((res) => {
      this.pioUnits = res;
      this.pioUnitsFiltered = this.pensionForm.controls.branchesPioFundCodebook.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.name),
          map(name => name ? this._filter(name, AutocompleteFilterEnum.PIOUNITS) : this.pioUnits.slice())
        );
    });
  }

  getPensionTypes() {
    this.codebookService.getTypesOfPensionCodebook().subscribe(result => {
      this.pensionTypes = result;
    });
  }
  autoSelectItem(){
    return autoSelect(this.pioUnits, 'branchesPioFundCodebook', 'name', this.pensionForm);
  }
  /**
   * _filter
   * @param value input value
   * @param what which filter should be applied (check AutocompleteFilterEnum)
   */
  private _filter(value: string, what: AutocompleteFilterEnum): any[] {
    const filterValue = value.toLowerCase();
    switch (what) {
      case AutocompleteFilterEnum.PIOUNITS:
        return sortByFirstLetter(this.pioUnits.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
          ),filterValue, 'name');
      default:
        break;

    }
  }

  displayCustomFormat(objectValue: any): string {
    return objectValue ? objectValue.branchTypeCodebook.title + ' ' + objectValue.name : '';
  }

  checkAmount() {
    this.pensionForm.controls.pensionAmount.setValue(getNumberForShow(this.pensionForm.controls.pensionAmount.value));
 }
}
