import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseService } from 'src/services/case.service';
import { DataSharingService } from 'src/services/data-sharing.service';
import { UserService } from 'src/services/user.service';

@Component({
    selector: 'workload-calculation-dialog',
    templateUrl: './workload-calculation-dialog.component.html',
    styleUrls: ['./workload-calculation-dialog.component.scss']
})
export class WorkloadCalculationDialogComponent implements OnInit {

    teamMemberCases: any = [];

    teamTableData: any = [];
    assginedTableData: any = [];
    counclerTableData: any = [];

    assginedTableColumns: string[] = [
        'caseNumber',
        'priority',
        'workload',
        'sum',
    ];
    teamTableColumns: string[] = [
        'caseNumber',
        'assigned'
    ];
    counclerTableColumns: string[] = [
        'caseNumber',
        'assigned',
        'priority',
        'workload',
        'sum',
    ];

    assignedCasesSum: any = 0;
    teamCasesPoints: any = 0;
    assignedCasesPoints: any = 0;

    constructor(
        public dialogRef: MatDialogRef<WorkloadCalculationDialogComponent>,
        public caseService: CaseService,
        public userService: UserService,
        private dataSharingService: DataSharingService,
        @Optional() @Inject(MAT_DIALOG_DATA) public worker: any) {
        this.addAssignedData(worker);
        this.addTeamData(worker);
        this.addCounslerData(worker);
    }

    addAssignedData(worker: any) {
        this.assginedTableData = this.worker.scInfo;
        this.getAssignedCasesPoints();
        for (const scase of this.worker.scInfo) {
            this.assignedCasesSum += this.calculateSumPerCase(scase);
        }
        for (let scase of this.assginedTableData) {
            scase.sumForCase = this.calculateSumPerCase(scase);
        }
    }
    addTeamData(worker: any) {
        this.caseService.findAsTeamMemberById(worker.userId).subscribe(
            res => {
                this.teamMemberCases = res;
                for (var team of res) {
                    this.teamTableData.push({ caseNumber: team.caseNumber, caseManager: team.caseManagerName });
                }
                this.getTeamMemberCasesPoints();
            }
        );
    }
    addCounslerData(worker: any) {
        var counslerData = [];
        if (this.isWorkerLegalCouncler(worker)) {
            this.caseService.findCounclersCases(worker.userId).subscribe(
                cases => {
                    for (const counclerCase of cases) {
                        if (counclerCase.caseManagerId != null && counclerCase.legalCounselorId != null &&
                            counclerCase.caseManagerId !== counclerCase.legalCounselorId) {
                            counslerData.push({
                                caseNumber: counclerCase.caseNumber, caseManager: counclerCase.caseManagerName,
                                priority: counclerCase.priority, workload: counclerCase.workload
                            });
                            this.getLegalCasesPoints();
                            this.assignedCasesSum += this.calculateSumPerCase(counclerCase);
                        }
                    }
                });
        }
        this.counclerTableData = counslerData;
    }


    ngOnInit(): void {
    }


    closeDialog() {
        this.dialogRef.close({ event: 'ok' });
    }

    calculateSumPerCase(element: any) {
        if (element.priority === 1) {
            return 3 + element.workload;
        }
        if (element.priority === 2) {
            return 2 + element.workload;
        }
        if (element.priority === 3) {
            return 1 + element.workload;
        }
        return element.workload;
    }

    getPriorityText(priority: any) {
        if (priority === 1) {
            return 'WORKLOAD_DETAILS.PRIORITY1';
        }
        if (priority === 2) {
            return 'WORKLOAD_DETAILS.PRIORITY2';
        }
        if (priority === 3) {
            return 'WORKLOAD_DETAILS.PRIORITY3';
        }
        return '';
    }

    getAssignedCasesPoints() {
        const numberOfCases = this.assginedTableData.length;
        if (numberOfCases >= 1 && numberOfCases <= 10) {
            this.assignedCasesPoints = 1;
        } else if (numberOfCases >= 11 && numberOfCases <= 20) {
            this.assignedCasesPoints = 2;
        } else if (numberOfCases >= 21 && numberOfCases <= 30) {
            this.assignedCasesPoints = 3;
        } else if (numberOfCases >= 31) {
            this.assignedCasesPoints = 4;
        } else {
            this.assignedCasesPoints = 0;
        }
    }
    getLegalCasesPoints() {
        const numberOfCases = this.assginedTableData.length + this.counclerTableData.length;
        if (numberOfCases >= 1 && numberOfCases <= 10) {
            this.assignedCasesPoints = 1;
        } else if (numberOfCases >= 11 && numberOfCases <= 20) {
            this.assignedCasesPoints = 2;
        } else if (numberOfCases >= 21 && numberOfCases <= 30) {
            this.assignedCasesPoints = 3;
        } else if (numberOfCases >= 31) {
            this.assignedCasesPoints = 4;
        } else {
            this.assignedCasesPoints = 0;
        }
    }

    getTeamMemberCasesPoints() {
        const numberOfCases = this.teamMemberCases.length;
        if (numberOfCases >= 1 && numberOfCases <= 5) {
            this.teamCasesPoints = 1;
        } else if (numberOfCases >= 6 && numberOfCases <= 10) {
            this.teamCasesPoints = 2;
        } else if (numberOfCases >= 11 && numberOfCases <= 20) {
            this.teamCasesPoints = 3;
        } else if (numberOfCases >= 21) {
            this.teamCasesPoints = 4;
        } else {
            this.teamCasesPoints = 0;
        }
    }

    isWorkerLegalCouncler(worker: any) {
        for (var role of worker.roles) {
            if (role.id === 3) {
                return true;
            }
        }
        return false;
    }

    add1() {
        this.counclerTableData.push({ caseNumber: 1, caseManager: 1,
                                        priority: 1, workload: 1});
    }
}

