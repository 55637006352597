import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Page } from 'src/app/page';
import { formatDate } from 'src/app/utils/formatDate';
import { CodebookService } from 'src/services/codebook.service';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { GlobalAdminService } from 'src/services/global-admin.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
   selector: 'dpn-admin',
   templateUrl: './dpn-admin.component.html',
   styleUrls: ['./dpn-admin.component.scss'],
})
export class DpnAdminComponent implements OnInit {
  dpnForm: FormGroup;
  dpnDisplayedColumns: string[] = ['amount', 'date'];
  dpnCurrentValues = {
    id: '',
    price: '',
    validFrom: '',
    type: '',
  };
  dpnData: any = [];
  dpnAllRights = [];
  dpnDataSource = new MatTableDataSource();
  page: Page<any> = new Page();
  maxDate: Date = new Date(2999, 11, 31);
  @Input() panelOpenState: boolean;

   constructor(
      private formBuilder: FormBuilder,
      private paginationService: CustomPaginationService,
      private codebookService: CodebookService,
      private toastr: ToastrImplService,
      private globalAdminService: GlobalAdminService
   ) {}

   ngOnInit(): void {
      this.dpnForm = this.formBuilder.group({
         price: ['', [Validators.pattern('^[0-9.,]*$')]],
         validFrom: ['', []],
         choiceDpn: ['', []],
      });
      this.getAllDPN();
   }
   public getNextPage(): void {
      this.page.pageable = this.paginationService.getNextPage(this.page);
      this.getAllDpnByType();
   }

   public getPreviousPage(): void {
      this.page.pageable = this.paginationService.getPreviousPage(this.page);
      this.getAllDpnByType();
   }
   public getFirstPage(): void {
      this.page.pageable = this.paginationService.getFirstPage(this.page);
      this.getAllDpnByType();
   }

   public getLastPage(): void {
      this.page.pageable = this.paginationService.getLastPage(this.page);
      this.getAllDpnByType();
   }

   public getPageInNewSize(pageSize: number): void {
      this.page.pageable = this.paginationService.getPageInNewSize(this.page, pageSize);
      this.getAllDpnByType();
   }

   // Send DPN updates
   submitDPNForm(data: any) {
      data.value.validFrom = new Date(data.value.validFrom);
      if (data.value.validFrom.toISOString() !== new Date(this.dpnCurrentValues.validFrom).toISOString()) {
         data.value.validFrom.setDate(data.value.validFrom.getDate() + 1);
      }
      const newObject = this.dpnCurrentValues;
      newObject.price = data.value.price;
      newObject.validFrom = data.value.validFrom;
      newObject.id = null;
      if (this.dpnAllRights.filter(right => formatDate(right.validFrom).substring(0, 10) === formatDate(newObject.validFrom).substring(0, 10)).length === 0) {
         this.globalAdminService.createDpnRightCodebook(newObject).subscribe(res => {
            this.dpnForm = this.formBuilder.group({
               price: ['', []],
               validFrom: ['', []],
               choiceDpn: ['', []],
            });
            this.dpnCurrentValues = {
               price: '',
               validFrom: '',
               id: '',
               type: '',
            };
            this.getAllDPN();
            this.toastr.success('SNACKBAR.CHANGE_SAVED');
         });
      } else {
         this.toastr.error('GLOBAL_ADMIN.DPN.ERROR');
      }
   }
   compareObj(object1: any, object2: any) {
      return object1 && object2 && object1.id === object2.id;
   }
   selectDpn(value: any) {
      this.dpnCurrentValues = value;
      this.getAllDpnByType();
      this.dpnForm.controls.validFrom.setValue(this.dpnCurrentValues.validFrom);
      this.dpnForm.controls.price.setValue(this.dpnCurrentValues.price);
   }
   getAllDpnByType() {
      this.codebookService.getAllByTypeDpnRightCodebook(this.dpnCurrentValues.type, this.page.pageable).subscribe(res => {
         this.dpnAllRights = res.content;
         this.page = res;
         this.page.pageable.pagePerShow = res.number + 1;
         this.dpnDataSource.data = this.dpnAllRights;
      });
   }
   getAllDPN() {
      this.codebookService.getLatestDpnRightCodebook().subscribe(res => {
         this.dpnData = res;
      });
   }
}
