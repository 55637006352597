/*
 * Copyright ©2021. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdoptedChildService } from 'src/services/adopted-child.service';
import { ToastrImplService } from 'src/services/toastr.service';

@Component({
    selector: 'new-adopted-child',
    templateUrl: './new-adopted-child.component.html',
    styleUrls: ['./new-adopted-child.component.scss']
})
export class NewAdoptedChildComponent implements OnInit {
    adopteeStep: FormGroup;
    biologicalParentsStep: FormGroup;
    adoptersStep: FormGroup;
    otherInformationStep: FormGroup;

    constructor(
        private router: Router,
        private adoptedChildService: AdoptedChildService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private toastr: ToastrImplService) {
        this.adopteeStep = formBuilder.group({});
        this.biologicalParentsStep = formBuilder.group({});
        this.adoptersStep = formBuilder.group({});
        this.otherInformationStep = formBuilder.group({});
    }

    ngOnInit() { }

    /**
     * Take information from adoptee step
     */
    updateAdopteeStep(step: FormGroup) {
        this.adopteeStep = step;
    }

    /**
     * Take information from biological parents step
     */
    updateBiologicalParentsStep(step: FormGroup) {
        this.biologicalParentsStep = step;
    }

    /**
     * Take information from adopters step
     */
    updateAdoptersStep(step: FormGroup) {
        this.adoptersStep = step;
    }

    /**
     * Take information from other information step
     */
    updateOtherInformationStep(step: FormGroup) {
        // This is the final step where, apart from validating the data from this step
        // we are supposed to submit the new record to dedicated service.
        this.otherInformationStep = step;
        this.createNewAdoptedChild();
    }

    /**
     * Review if all form steps are valid
     */
    checkDocumentValidation(): boolean {
        const isAdopteeStepValid =
            this.adopteeStep && this.adopteeStep.valid;
        const isBiologicalParentsStepValid =
            this.biologicalParentsStep && this.biologicalParentsStep.valid;
        const isAdoptersStepValid =
            this.adoptersStep && this.adoptersStep.valid;
        const isOtherInformationStepValid =
            this.otherInformationStep && this.otherInformationStep.valid;

        return [
            isAdopteeStepValid,
            isBiologicalParentsStepValid,
            isAdoptersStepValid,
            isOtherInformationStepValid
        ].every((val) => val === true);
    }

    createNewAdoptedChild() {
        const body = {
            subjectId: ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.subjectId.value),
            registrationId: ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.registrationId.value),
            beforeAdoptionFirstName: ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.firstName.value),
            beforeAdoptionLastName: ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.lastName.value),
            beforeAdoptionDateOfBirth: this.datePipe.transform(
                new Date((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.dateOfBirth.value).getTime(), 'dd/MM/yyyy'),
            beforeAdoptionPlaceOfBirth: ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.placeOfBirth.value),
            beforeAdoptionJmbg: ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.jmbg.value),
            beforeAdoptionCitizenship: ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.citizenship.value) !== '' ?
                ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.citizenship.value) : null,
            beforeAdoptionPermanentResidence: ((this.adopteeStep.controls.beforeAdoption as FormGroup).controls.permanentResidence.value),
            afterAdoption: {
                firstName: this.adopteeStep.value.afterAdoption.firstName !== '' ? this.adopteeStep.value.afterAdoption.firstName : null,
                lastName: this.adopteeStep.value.afterAdoption.lastName !== '' ? this.adopteeStep.value.afterAdoption.lastName : null,
                dateOfBirth: this.adopteeStep.value.afterAdoption.dateOfBirth !== '' ? this.datePipe.transform(
                    new Date((this.adopteeStep.controls.afterAdoption as FormGroup).controls.dateOfBirth.value).getTime(), 'dd/MM/yyyy')
                    : null,
                placeOfBirth: ((this.adopteeStep.controls.afterAdoption as FormGroup).controls.placeOfBirth.value) !== '' ?
                    ((this.adopteeStep.controls.afterAdoption as FormGroup).controls.placeOfBirth.value) : null,
                jmbg: ((this.adopteeStep.controls.afterAdoption as FormGroup).controls.jmbg.value) !== '' ?
                    ((this.adopteeStep.controls.afterAdoption as FormGroup).controls.jmbg.value) : null,
                nationality: this.adopteeStep.value.afterAdoption.nationality !== '' ?
                    this.adopteeStep.value.afterAdoption.nationality : null,
                citizenship: this.adopteeStep.value.afterAdoption.citizenship !== '' ?
                    this.adopteeStep.value.afterAdoption.citizenship : null,
                residence: this.adopteeStep.value.afterAdoption.permanentResidence !== '' ?
                    this.adopteeStep.value.afterAdoption.permanentResidence : null,
            },
            mother: {
                firstName: this.biologicalParentsStep.value.mother.firstName !== '' ?
                    this.biologicalParentsStep.value.mother.firstName : null,
                lastName: this.biologicalParentsStep.value.mother.lastName !== '' ? this.biologicalParentsStep.value.mother.lastName : null,
                dateOfBirth: this.biologicalParentsStep.value.mother.dateOfBirth !== '' ? this.datePipe.transform(
                    new Date(this.biologicalParentsStep.value.mother.dateOfBirth).getTime(), 'dd/MM/yyyy') : null,
                residence: this.biologicalParentsStep.value.mother.residence !== '' ?
                    this.biologicalParentsStep.value.mother.residence : null,
                citizenship: this.biologicalParentsStep.value.mother.citizenship !== '' ?
                    this.biologicalParentsStep.value.mother.citizenship : null
            },
            father: {
                firstName: this.biologicalParentsStep.value.father.firstName !== '' ?
                    this.biologicalParentsStep.value.father.firstName : null,
                lastName: this.biologicalParentsStep.value.father.lastName !== '' ?
                    this.biologicalParentsStep.value.father.lastName : null,
                dateOfBirth: this.biologicalParentsStep.value.father.dateOfBirth !== '' ?
                    this.datePipe.transform(
                        new Date(this.biologicalParentsStep.value.father.dateOfBirth).getTime(), 'dd/MM/yyyy') : null,
                residence: this.biologicalParentsStep.value.father.residence !== '' ?
                    this.biologicalParentsStep.value.father.residence : null,
                citizenship: this.biologicalParentsStep.value.father.citizenship !== '' ?
                    this.biologicalParentsStep.value.father.citizenship : null
            },
            adoptiveMother: this.adoptersStep.value.adopter.includes('усвојитељка') ? {
                firstName: this.adoptersStep.value.adoptiveMother.firstName !== '' ?
                    this.adoptersStep.value.adoptiveMother.firstName : null,
                lastName: this.adoptersStep.value.adoptiveMother.lastName !== '' ? this.adoptersStep.value.adoptiveMother.lastName : null,
                dateOfBirth: this.adoptersStep.value.adoptiveMother.dateOfBirth !== '' ? this.datePipe.transform(
                    new Date(this.adoptersStep.value.adoptiveMother.dateOfBirth).getTime(), 'dd/MM/yyyy') : null,
                residence: this.adoptersStep.value.adoptiveMother.residence !== '' ?
                    this.adoptersStep.value.adoptiveMother.residence : null,
                citizenship: this.adoptersStep.value.adoptiveMother.citizenship !== '' ?
                    this.adoptersStep.value.adoptiveMother.citizenship : null
            } : null,
            adoptiveFather: this.adoptersStep.value.adopter.includes('усвојитељ') ? {
                firstName: this.adoptersStep.value.adoptiveFather.firstName !== '' ?
                    this.adoptersStep.value.adoptiveFather.firstName : null,
                lastName: this.adoptersStep.value.adoptiveFather.lastName !== '' ? this.adoptersStep.value.adoptiveFather.lastName : null,
                dateOfBirth: this.adoptersStep.value.adoptiveFather.dateOfBirth !== '' ? this.datePipe.transform(
                    new Date(this.adoptersStep.value.adoptiveFather.dateOfBirth).getTime(), 'dd/MM/yyyy') : null,
                residence: this.adoptersStep.value.adoptiveFather.residence !== '' ?
                    this.adoptersStep.value.adoptiveFather.residence : null,
                citizenship: this.adoptersStep.value.adoptiveFather.citizenship !== '' ?
                    this.adoptersStep.value.adoptiveFather.citizenship : null
            } : null,
            adoptersCodebook: this.adoptersStep.value.adoptsInfo.adoptersCodebook,
            typeOfRelationship: this.otherInformationStep.value.typeOfRelationship,
            decisionNumber: this.otherInformationStep.controls.decisionNumber.value,
            decisionDate: this.otherInformationStep.value.decisionDate !== '' ? this.datePipe.transform(
                new Date(this.otherInformationStep.value.decisionDate).getTime(), 'dd/MM/yyyy') : null,
            decisionNumberNewBirthRegistration: this.otherInformationStep.value.decisionNumberNewBirthRegistration,
            decisionDateNewBirthRegistration: this.otherInformationStep.value.decisionDateNewBirthRegistration !== '' ?
                this.datePipe.transform(
                    new Date(this.otherInformationStep.value.decisionDateNewBirthRegistration).getTime(), 'dd/MM/yyyy') : null,
            annulmentOfAdoption: this.otherInformationStep.value.annulmentOfAdoption,
            holderOfMaintenanceObligation: this.otherInformationStep.value.holderOfMaintenanceObligation,
            note: this.otherInformationStep.value.note
        };

        this.adoptedChildService.createAdoptedChild(body).subscribe((res) => {
            if (res != null) {
                this.toastr.success('SNACKBAR.ADDED_ADOPTED_CHILD');
                this.router.navigateByUrl('/adopted-children');
            } else {
                this.toastr.error('SNACKBAR.ADD_ADOPTED_CHILD_ERROR');
            }
        });

    }
}
