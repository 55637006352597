import { ToastrImplService } from 'src/services/toastr.service';

// upozorenje ukoliko ima vise od 5 clanova porodice
export const tooManyMembersForBillingWarn = (familyMembers: number, toastr: ToastrImplService) => {
   if (familyMembers > 5) {
      return toastr.warning('NSP_MOCK.FAMILY.TOO_MANY_FOR_BILLING');
   }
};

export enum ErrorType {
   GENERATE_SOLUTION,
   SAVE_DATA,
   PREVIEW_DOC
};

export const manageError = (toastr: ToastrImplService,type: ErrorType) => {
   switch(type){
      case ErrorType.GENERATE_SOLUTION:{
         toastr.error('GLOBAL.ERRORS.GENERATE_SOLUTION');
         break;
      }
      case ErrorType.SAVE_DATA:{
         toastr.error('GLOBAL.ERRORS.SAVE_DATA');
         break;
      }
      case ErrorType.PREVIEW_DOC:{
         toastr.error('GLOBAL.ERRORS.PREVIEW');
         break;
      }
      default:{
         toastr.error('GLOBAL.ERRORS.ERROR');
         break;
      }
   }
  };
