/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component } from '@angular/core';
import { Page } from 'src/app/page';
import { ActivatedRoute } from '@angular/router';
import { CustomPaginationService } from 'src/services/custom-pagination.service';
import { LegalSubjectsService } from 'src/services/legal-subjects.service';

@Component({
  selector: 'legal-subject-history',
  templateUrl: './legal-subject-history.component.html',
  styleUrls: ['./legal-subject-history.component.scss']
})
export class LegalSubjectHistoryComponent {

  page: Page<Object> = new Page();
  selectedRow: { id: number };
  id: string;

  displayedColumns: string[] = [
    'dateOfModification',
    'modifiedBy',
    'name',
    'institutionType',
    'address',
    'ptt',
    'city',
    'region',
    'phone',
    'email',
    'accountNumber',
    'jbkbs',
    'pib',
    'identificationNumber'
  ];

  constructor(
    private route: ActivatedRoute,
    private legalSubjectsService: LegalSubjectsService,
    private paginationService: CustomPaginationService,
    ) {

    this.route.params.subscribe((params) => {
      this.id = params.id;
    });

    this.getData();
  }

  getData() {
    this.legalSubjectsService
      .getHistoryPage(this.page.pageable, this.id)
      .subscribe((page) => {
        this.page = page;
        this.page.pageable.pagePerShow = page.number + 1;
        for (const element of page.content) {
          (element as any).dateOfModification = (element as any).dateOfModification?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3.');
        }
      });
  }

  public getNextPage(): void {
    this.page.pageable = this.paginationService.getNextPage(this.page);
    this.getData();
  }

  public getPreviousPage(): void {
    this.page.pageable = this.paginationService.getPreviousPage(this.page);
    this.getData();
  }
  public getFirstPage(): void {
    this.page.pageable = this.paginationService.getFirstPage(this.page);
    this.getData();
  }

  public getLastPage(): void {
    this.page.pageable = this.paginationService.getLastPage(this.page);
    this.getData();
  }


  public getPageInNewSize(pageSize: number): void {
    this.page.pageable = this.paginationService.getPageInNewSize(
      this.page,
      pageSize
    );
    this.getData();
  }

}
