/*
 * Copyright ©2020. Open Digital Solutions, Novi Sad. Sva prava zadržana.
 * Pravo da se koristi, kopira, modifikuje i distribuira ovaj softver i njegova dokumentacija
 * u bilo koje svrhe, bez naknade ili bez potpisanog sporazuma sa vlasnikom softvera, nije dozvoljeno.
 */

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "form-subgroup-divider",
  templateUrl: "./form-subgroup-divider.component.html",
  styleUrls: ["./form-subgroup-divider.component.scss"],
})
export class FormSubgroupDividerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
